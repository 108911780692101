import { WHITEBOARD_BOARD } from '@confluence/named-routes';

const BASE_URL_META_SELECTOR = "meta[name='confluence-base-url']";

const getBaseUrlForLocalhost = () => {
	const baseUrlFromMeta =
		window.document.querySelector(BASE_URL_META_SELECTOR)?.getAttribute('content') ??
		'https://hello.atlassian.net/wiki';

	return baseUrlFromMeta.substring(0, baseUrlFromMeta.length - 5);
};

export const getBaseUrl = () => {
	if (typeof window.location === 'undefined') {
		return '';
	}

	const baseUrl = window.location.origin;

	// If we are in localhost, we use the base URL from the meta tag
	if (new URL(baseUrl).hostname === 'localhost') {
		return getBaseUrlForLocalhost();
	}

	return baseUrl;
};

export const getFullWhiteboardUrl = (contentId: string, spaceKey: string) =>
	`${getBaseUrl()}${WHITEBOARD_BOARD.toUrl({
		spaceKey,
		contentId,
	})}`;
