import { productEmbedType } from './analyticsWebTypes';
import type {
	FirstPartyProductEmbedContext,
	ProductEmbeddedContext,
	ThirdPartyProductEmbedContext,
} from './types';

export const isFirstParty = (
	input: ProductEmbeddedContext,
): input is FirstPartyProductEmbedContext => {
	return (
		input.embeddedType === productEmbedType.FIRST_PARTY && input.embeddedFromProduct !== undefined
	);
};

export const isThirdParty = (
	input: ProductEmbeddedContext,
): input is ThirdPartyProductEmbedContext => {
	return (
		input.embeddedType === productEmbedType.THIRD_PARTY &&
		input.embeddedFromTopLevelDomain !== undefined
	);
};
