/**
 * @jsxRuntime classic
 * @jsx jsx
 */
import { fg } from '@atlaskit/platform-feature-flags';
import { Fragment } from 'react';
import { jsx, cx, css } from '@compiled/react';
import { cssMap } from '@atlaskit/css';
import { token } from '@atlaskit/tokens';

import { InlineContext } from '../../../../context/inline';
import { type ComponentPropsConfig } from '../../../utils/codegen';

export const componentPropsConfig: ComponentPropsConfig = {
	align: true,
	as: {
		excludeFromDeconstruct: false,
		value: 'as === "strike" ? "span" : as',
	},
	color: true,
	maxLines: true,
	size: true,
	weight: true,
	children: true,
	testId: true,
	xcss: {
		excludeFromDeconstruct: true,
		value: 'cx(styles.regular, as === "strike" && styles.lineThrough)',
	}
};

const styles = cssMap({
	// Restore overflow-wrap and add margin-top for backwards compatibility with the legacy component
	regular: {
		overflowWrap: 'normal'
	},
	lineThrough: {
		textDecorationLine: 'line-through'
	}
});

// If we're displaying a paragraph, unfortunately we still need to bring back the styles to add a top margin
// (unless the wrapper is the first one) to be fully backwards compatible with the legacy component.
const wrapperStyles = css({
	// eslint-disable-next-line
	'&:not(:first-of-type) > p': {
		marginTop: token('space.150')
	}
});

type PlatformTextProps = React.ComponentProps<typeof PlatformText>;

type OriginalPlatformProps = Pick<
	PlatformTextProps,
	'align' | 'as' | 'color' | 'maxLines' | 'size' | 'weight' | 'children' | 'testId'
>;

export type TextProps = Omit<OriginalPlatformProps, 'as'> & { as?: OriginalPlatformProps['as'] | 'strike' };

/**
 * THIS SECTION WAS CREATED VIA CODEGEN DO NOT MODIFY {@see http://go/af-codegen}
 *
 * This component was generated against an existing platform component.
 * If you are seeing lint errors in this file, it means that this file's dependencies have been modified and the component will need to be regenerated.
 *
 * Please view https://hello.atlassian.net/wiki/spaces/ECO/pages/3358130330/How+to+Regenerate+UI+Kit+Components for more information on how to proceed.
 *
 * For further questions, please ping the #help-forge-ui channel.
 *
 * @codegen <<SignedSource::b8da4e0812a1b415125f45827e6bf040>>
 * @codegenId #text
 * @codegenCommand yarn workspace @atlassian/forge-ui codegen text
 * @codegenParams { "defineOwnType": true }
 * @codegenDependency ../../../../../../../design-system/primitives/src/compiled/components/text.tsx <<SignedSource::d2437aff9f5237e1eee54fbc0065a3d2>>
 * @codegenDependency ../../../../../../../design-system/primitives/src/compiled/components/types.tsx <<SignedSource::94d2e663ab1e896b15baac38f8e400aa>>
 * @codegenDependency ../../../../../../../design-system/primitives/src/utils/has-text-ancestor-context.tsx <<SignedSource::2c32107758c17743345dbf792b42fccc>>
 * @codegenDependency ../../../../../../../design-system/primitives/src/utils/surface-provider.tsx <<SignedSource::b2f47f7dfba2a18d1e5f39f5b93f4959>>
 */
/* eslint @repo/internal/codegen/signed-source-integrity: "warn" */
import React from 'react';
import type { RenderFn } from '@atlassian/forge-ui-types';
import { Text as PlatformText } from '@atlaskit/primitives/compiled';

// Define the type for Text as TextProps at the top of this file

export const Text = (props: Parameters<RenderFn>[0]) => {
  const {
    align,
    as,
    color,
    maxLines,
    size,
    weight,
    testId,
  } = props.forgeDoc.props as TextProps;
  return (
    <PlatformText
      align={align}
      as={as === "strike" ? "span" : as}
      color={color}
      maxLines={maxLines}
      size={size}
      weight={weight}
      children={props.forgeDoc.children.map(c => <React.Fragment key={c.key}>{props.render(c)}</React.Fragment>)}
      testId={testId}
      xcss={cx(styles.regular, as === "strike" && styles.lineThrough)}
    />
  );
};
/**
 * @codegenEnd
 */

// Add support for "strike" as a value for the "as" prop to have a migration path for the legacy <Strike> component
export type PatchedTextProps = Omit<TextProps, 'as'> & { as?: TextProps['as'] | 'strike' };

export const PatchedText = (props: Parameters<RenderFn>[0]) => {
	const textProps = props.forgeDoc.props as PatchedTextProps;

	// Remove the else case when the feature gate is removed
	// https://hello.jira.atlassian.cloud/browse/FD-88404
	// eslint-disable-next-line @atlaskit/platform/ensure-feature-flag-prefix
	if (fg('forge-ui-use-ads-text-component')) {
		const componentProps = { ...props.forgeDoc.props } as PatchedTextProps;

		// ADS Text will set value for "color" if none is provided. Since the only way to adjust the color of texts in
		// Forge UI was by using the `xcss` prop on a <Box>, we need to make sure that inheritance of that color
		// is still possible, otherwise we would not be backwards compatible.
		// We can achieve this by setting the default value for "color" to "inherit" if it is not provided.
		componentProps.color = textProps.color === undefined ? 'inherit' : textProps.color;

		const needsWrapper = componentProps.as === 'p' || componentProps.as === undefined;

		// TODO: remove the InlineContext.Provider once the following UI Kit 1 components have been removed
		// * User
		// * UserGroup
		// * Code
		// See https://ecosystem-platform.atlassian.net/browse/EXT-2937

		// If we don't have to worry about any margins (all inline elements), we can use the platform Text component directly
		if(!needsWrapper) {
			return (
				<InlineContext.Provider value={{ inline: true }}>
					<Text
						render={props.render}
						dispatch={props.dispatch}
						forgeDoc={{
							children: props.forgeDoc.children,
							type: props.forgeDoc.type,
							props: componentProps,
						}}
					/>
				</InlineContext.Provider>
			);
		}

		// Default to <p> for backwards compatibility
		if (textProps.as === undefined) {
			componentProps.as = 'p';
		}

		// Use the wrapper to add the margin-top for all paragraphs except the first one
		return (
			<div css={wrapperStyles}>
				<InlineContext.Provider value={{ inline: true }}>
					<Text
						render={props.render}
						dispatch={props.dispatch}
						forgeDoc={{
							children: props.forgeDoc.children,
							type: props.forgeDoc.type,
							props: componentProps,
						}}
					/>
				</InlineContext.Provider>
			</div>
		);
	} else {
		const { forgeDoc, render } = props;
		return (
			<InlineContext.Provider value={{ inline: true }}>
				{/* eslint-disable-next-line @atlaskit/design-system/use-primitives-text */}
				<p>
					{forgeDoc.children.map((child) => {
						// React expects a key on each child when rendering an array
						return <Fragment key={child.key}>{render(child)}</Fragment>;
					})}
				</p>
			</InlineContext.Provider>
		);
	}
};
