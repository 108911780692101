import React, { useCallback, useEffect, useRef, useState } from 'react';

import Spinner from '@atlaskit/spinner';

import { getBaseUrl } from '..';

export const getDatabaseIframeUrl = (spaceKey: string, cloudId: string): string => {
	const baseUrl = getBaseUrl(false);

	const fullUrl = `${baseUrl}/databases${
		process.env.NODE_ENV === 'development' ? '-dev' : ''
	}/embed/?spaceKey=${spaceKey}&cloudId=${cloudId}`;

	return fullUrl;
};

export type DatabaseValueContainerProps = {
	contentId: string;
	entryId: string;
	fieldId: string;
	spaceKey: string;
	cloudId: string;
	isEdit?: boolean;
};

export const DatabaseValueContainer = React.memo(
	({ contentId, entryId, fieldId, spaceKey, cloudId, isEdit }: DatabaseValueContainerProps) => {
		const iframeRef = useRef<HTMLIFrameElement>(null);

		const [width, setWidth] = useState(0);
		const [height, setHeight] = useState(0);

		const databaseUrl = new URL(getDatabaseIframeUrl(spaceKey, cloudId));

		const messageCallback = useCallback((event: MessageEvent) => {
			const isFromExpectedIframe =
				iframeRef.current && event.source === iframeRef.current.contentWindow;

			if (isFromExpectedIframe) {
				try {
					const data = JSON.parse(event.data);
					if (data.method === 'resize' && 'width' in data && 'height' in data) {
						setWidth(data.width);
						setHeight(data.height);
					}
				} catch (error) {}
			}
		}, []);

		useEffect(() => {
			window.addEventListener('message', messageCallback);

			return () => {
				window.removeEventListener('message', messageCallback);
			};
		}, [messageCallback]);

		if (entryId && fieldId && contentId) {
			databaseUrl.searchParams.set('contentId', contentId);
			databaseUrl.searchParams.set('entryId', entryId);
			databaseUrl.searchParams.set('fieldId', fieldId);
			return (
				<>
					<iframe
						style={{
							width: `${width}px`,
							height: `${height}px`,
							// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
							border: '0px',
							pointerEvents: isEdit ? 'none' : 'auto',
							// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
							verticalAlign: 'middle',
						}}
						src={databaseUrl.toString()}
						title="database-value-frame"
						ref={iframeRef}
					/>
					{width === 0 || height === 0 ? <Spinner testId="database-value-spinner" /> : null}
				</>
			);
		}

		return null;
	},
);
