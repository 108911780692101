import gql from 'graphql-tag';

export const TopNavigationQuery = gql`
	query TopNavigationQuery($includeEmails: Boolean!, $includeNav4AdminOptIn: Boolean!) {
		siteConfiguration {
			customSiteLogo
		}
		siteSettings {
			siteTitle
			showApplicationTitle
			isNav4OptedIn @include(if: $includeNav4AdminOptIn)
		}
		siteDescription {
			logoUrl
		}
		user(current: true) {
			id
			displayName
			emails @include(if: $includeEmails) {
				value
				isPrimary
			}
			photos {
				value
			}
		}
	}
`;
