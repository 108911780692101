/**
 * Helper methods for feature discovery plugin.
 * @module confluence-rest-api/feature-discovery
 */
import { redirectToLoginPage } from '@confluence-classic/confluence-authentication';
import { FEATURE_DISCOVERY } from './endpoints';

import requestHelper from './request-helper';

/**
 * Mark a feature in a plugin as already discovered.
 * @param {Object} options
 * @param {String} options.pluginKey - The key of discovered plugin
 * @param {String} options.featureKey - The key of discovered feature
 * @param callback
 */
export function markDiscovered(options, callback) {
	const params = {
		pluginKey: options.pluginKey,
		featureKey: options.featureKey,
	};
	const url = requestHelper.getFullPath(FEATURE_DISCOVERY, params);
	requestHelper.postLegacyResource(url, {
		body: {
			atl_token: requestHelper.getAtlToken(),
		},
		httpCodeHandlers: { 401: () => redirectToLoginPage() },
		callback,
		routeName: 'FEATURE_DISCOVERY',
	});
}
