import React, { useState } from 'react';
import { useUID } from 'react-uid';
import { type NativeUserPickerProps } from '@atlassian/forge-ui-types';
import { fg } from '@atlaskit/platform-feature-flags';

import AKUserPicker from '@atlaskit/user-picker';
import AKSmartUserPicker from '@atlaskit/smart-user-picker';
import { HelperMessage as AKHelperMessage } from '@atlaskit/form';

import { PortalConsumer } from '../../../context';

import { type UserPickerOption } from '../../UIKit1/form/transformFormData';
import { type UserQuery } from '../../UIKit1/userPicker/providers/useMentionResource';
import type { Dependencies } from './types';
import { Label } from '../label';

const BITBUCKET_FIELD_ID = 'BitbucketMentions';

type InnerUserPickerProps = Omit<NativeUserPickerProps, 'defaultValue'> &
	Omit<Dependencies, 'client'> & {
		id: string;
		width: string;
		query?: UserQuery;
		defaultValue?: UserPickerOption | UserPickerOption[];
	};

// the value prop in UserPicker component can only take undefined for empty value.
// null is not allowed and will resulted in unexpected behavior.
const ensureUserPickerValue: <T>(value: T) => T | undefined = (value) => {
	if (!value || (Array.isArray(value) && value.length === 0)) {
		return undefined;
	}
	return value;
};

// by forcing a key update when defaultValue is present, we force the SmartUserPicker to re-render.
// this is because the SmartUserPicker does not re-render when the defaultValue changes in its
// current implementation.
const makeUserPickerKey = (id: string, defaultValue?: UserPickerOption | UserPickerOption[]) => {
	if (!defaultValue || (Array.isArray(defaultValue) && defaultValue.length === 0)) {
		return id;
	}
	return `${id}-with-default`;
};

const RawUserPicker = ({
	id,
	name,
	description,
	placeholder,
	isMulti,
	includeUsers = true,
	includeGroups = false,
	accountId: currentAccountId,
	cloudId: suppliedCloudId,
	productKey,
	baseUrl,
	onChange,
	query,
	defaultValue,
	value: providedValue,
	width,
	productAttributes,
}: InnerUserPickerProps & {
	value?: UserPickerOption | UserPickerOption[];
}) => {
	const [value, setValue] = useState<any>(defaultValue);

	return (
		<PortalConsumer>
			{(portal) => (
				<>
					{suppliedCloudId && productKey ? (
						<AKSmartUserPicker
							key={makeUserPickerKey(id, defaultValue)}
							value={
								fg('platform_fix_userpicker_clear_values')
									? undefined
									: ensureUserPickerValue(providedValue ?? value)
							}
							onChange={(user: any) => {
								if (fg('platform_fix_userpicker_clear_values')) {
									onChange?.(user);
								} else {
									setValue(user);
									onChange?.(user);
								}
							}}
							isMulti={isMulti}
							menuPortalTarget={portal || undefined}
							noOptionsMessage={() => null}
							placeholder={placeholder}
							width={width}
							fieldId={
								// Bitbucket User Recommendations does not support generic contextType
								productKey === 'bitbucket' ? BITBUCKET_FIELD_ID : id
							}
							debounceTime={400}
							includeUsers={includeUsers}
							includeGroups={includeGroups}
							siteId={suppliedCloudId}
							productAttributes={productAttributes}
							// If principalId === 'Context', upstream attempts to use other context
							// to discover the principal ID
							principalId={currentAccountId ?? 'Context'}
							productKey={productKey}
							baseUrl={baseUrl}
							inputId={name || id}
							defaultValue={defaultValue}
						/>
					) : (
						<AKUserPicker
							value={ensureUserPickerValue(providedValue ?? value)}
							onChange={(user: any) => {
								setValue(user);
								onChange?.(user);
							}}
							isMulti={isMulti}
							menuPortalTarget={portal || undefined}
							noOptionsMessage={() => null}
							placeholder={placeholder}
							width={width}
							loadOptions={query}
							fieldId={null}
							inputId={id}
							defaultValue={defaultValue}
						/>
					)}
					{description && <AKHelperMessage>{description}</AKHelperMessage>}
				</>
			)}
		</PortalConsumer>
	);
};

// This is a wrapper containing the core logic of user picker
// It has been separated to take query as props so that it can be testable in Jest and rendered in Storybook
export const UserPicker = (props: Omit<InnerUserPickerProps, 'id' | 'width'>) => {
	const uid = useUID();
	const { name, isRequired, label } = props;

	return (
		<>
			{label && <Label name={name} isRequired={isRequired} label={label} />}
			<RawUserPicker id={name || uid} width="100%" {...props} />
		</>
	);
};
