import type { MediaClientConfig } from '@atlaskit/media-core';
import type { UploadRejectionData } from '@atlaskit/media-picker';

export const DEFAULT_DYNAMIC_CARDS_COUNT = 3;

export type LinkCardsParameters = {
	size: CardSizes;
	segmentationTarget?: SegmentationTargets;
	cards: Card[];
	extensionTitle: string;
	version: string;
	renderVariant: RenderVariant;
	cardCount?: number;
	filters?: Filters;
	isPublishDateShown: boolean;
	isAvatarShown: boolean;
	isSegmentationShown: boolean;
	orientation?: Orientation;
	alignment?: Alignment;
};

export enum SegmentationTargets {
	ALL = 'all',
	EACH = 'each',
}

export enum CardSizes {
	EXTRA_SMALL = 'extraSmall',
	SMALL = 'small',
	MEDIUM = 'medium',
	LARGE = 'large',
	HERO = 'hero',
}

export enum Orientation {
	ROW = 'row',
	STACKED = 'stacked',
}

export enum Alignment {
	LEFT = 'left',
	CENTER = 'center',
	RIGHT = 'right',
}

export enum RenderVariant {
	MANUAL = 'manual',
	DYNAMIC = 'dynamic',
}

export type Filters = {
	[filter: string]: any;
};

export enum ImagePosition {
	TOP = 'top',
	MIDDLE = 'middle',
	BOTTOM = 'bottom',
}

export type Card = {
	cardId: string;
	link: string;
	isLinkUserEdited?: boolean;
	title?: string;
	isTitleUserEdited?: boolean;
	description?: string;
	isDescriptionUserEdited?: boolean;
	imageSrc?: string;
	imagePosition?: ImagePosition;
	imageAltText?: string;
	confluencePageId?: string;
	audienceSegmentationGroupIds?: string[];
	linkResourceType?: string;
};

export type MediaConfigType = {
	collection: string | undefined;
	authProvider: MediaClientConfig['authProvider'];
	onUploadRejection: ((data: UploadRejectionData) => boolean) | undefined;
} | null;

export type ResolvedLinkDataObject = {
	data: {
		summary?: string;
		name?: string;
		image?: {
			url?: string;
		};
	};
	meta: {
		objectId?: string;
		resourceType?: string;
	};
};
