/**
 * @jsxRuntime classic
 * @jsx jsx
 */
// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import { jsx } from '@emotion/react';
import { Fragment, lazy } from 'react';
import { FormattedMessage, defineMessages } from 'react-intl-next';
import { FieldContainer } from '../utils/FieldContainer';
import {
	type SelectProps,
	type Rendered,
	type AKOption,
	type OptionProps,
	type FieldProps,
} from '@atlassian/forge-ui-types';
import { PortalConsumer } from '../../../context';
import { type Props } from '../..';
import { FormStateChangeNotifier } from '../form';

const AKFormField = lazy(
	() =>
		import(
			/* webpackChunkName: '@atlaskit-internal_.form' */
			'@atlaskit/form/Field'
		),
);

const AKSelect = lazy(
	() =>
		import(
			/* webpackChunkName: '@atlaskit-internal_.select' */
			'@atlaskit/select'
		),
);

const AKErrorMessage = lazy(() =>
	import(
		/* webpackChunkName: '@atlaskit-internal_.form' */
		'@atlaskit/form'
	).then((module) => ({ default: module.ErrorMessage })),
);

const AKHelperMessage = lazy(() =>
	import(
		/* webpackChunkName: '@atlaskit-internal_.form' */
		'@atlaskit/form'
	).then((module) => ({
		default: module.HelperMessage,
	})),
);

interface AKSelectProps {
	options: AKOption[];
	defaultValue?: AKOption | AKOption[];
}

const validate = (value: any) => (!value ? 'EMPTY' : undefined);

const Select = ({
	options,
	isMulti,
	defaultValue,
	label,
	name,
	description,
	isRequired,
	placeholder,
}: Omit<Rendered<SelectProps>, 'children'> & AKSelectProps) => {
	return (
		<PortalConsumer>
			{(portal) => (
				<FieldContainer>
					<AKFormField
						defaultValue={defaultValue}
						label={label}
						name={name}
						isRequired={isRequired}
						validate={isRequired ? validate : undefined}
					>
						{({ fieldProps, error }: { fieldProps: FieldProps; error?: string }) => {
							const {
								id,
								isRequired,
								isDisabled,
								isInvalid,
								onChange,
								onBlur,
								onFocus,
								value,
								'aria-labelledby': ariaLabelledBy,
							} = fieldProps;
							return (
								<Fragment>
									<FormStateChangeNotifier name={name} value={fieldProps.value} />
									<AKSelect
										id={id}
										isRequired={isRequired}
										isDisabled={isDisabled}
										isInvalid={isInvalid}
										onChange={onChange}
										onBlur={onBlur}
										onFocus={onFocus}
										value={value}
										aria-labelledby={ariaLabelledBy}
										isMulti={isMulti}
										menuPortalTarget={portal}
										options={options}
										placeholder={placeholder}
										validationState={error ? 'error' : 'default'}
									/>
									{error === 'EMPTY' && (
										<AKErrorMessage>
											<FormattedMessage {...messages.requiredFieldMessage} />
										</AKErrorMessage>
									)}
									{description && <AKHelperMessage>{description}</AKHelperMessage>}
								</Fragment>
							);
						}}
					</AKFormField>
				</FieldContainer>
			)}
		</PortalConsumer>
	);
};

const SelectFn = ({ props, children }: Props) => {
	const { description, label, name, isMulti, placeholder, isRequired } = props as SelectProps;

	let defaultValue: AKOption[] = [];
	const options: AKOption[] = children
		.map((child) => {
			const { props, type } = child;

			if (type === 'Option') {
				const { defaultSelected, label, value } = props as OptionProps;
				const option = { label, value };

				if (defaultSelected) {
					if (isMulti) {
						defaultValue.push(option);
					} else {
						defaultValue = [option];
					}
				}
				return option;
			}
		})
		.filter((option): option is AKOption => typeof option !== 'undefined');

	const formattedDefaultValue = isMulti ? defaultValue : defaultValue[0];

	return (
		<Select
			label={label}
			name={name}
			options={options}
			isMulti={isMulti}
			isRequired={isRequired}
			defaultValue={formattedDefaultValue}
			description={description}
			placeholder={placeholder}
		/>
	);
};

const messages = defineMessages({
	requiredFieldMessage: {
		id: 'confluence.select.required.field.message',
		defaultMessage: 'This field is required.',
		description: 'This message alerts the user that a field is required.',
	},
});

export { Select, SelectFn };
