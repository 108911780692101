import gql from 'graphql-tag';
import { print } from 'graphql';
import { useEffect, useRef, useCallback } from 'react';
import { subscribe, type Subscription } from './lib/aggSubscription';
import { fg } from '@atlaskit/platform-feature-flags';

const appClientEventsSubscription = gql`
	subscription onAppClientEvent(
		$appId: ID!
		$channel: String!
		$contextAri: ID!
		$eventName: String
	) {
		ecosystem {
			onAppClientEvent(
				appId: $appId
				channel: $channel
				contextAri: $contextAri
				eventName: $eventName
			) {
				payload
			}
		}
	}
`;

type ClientSubscribeContext = {
	channel: string;
	eventName?: string;
	appId?: string;
	contextAri?: string;
};

type SubscribeClientEventPayload = {
	data: {
		context: ClientSubscribeContext;
		onClientEvent: (payload: unknown, error?: Error) => void;
	};
};

export type SubscribeClientEvent = (payload: SubscribeClientEventPayload) => Promise<{
	unsubscribe: () => void;
}>;

//data.ecosystem.onAppClientEvent
type SubscriptionResult = {
	data: {
		ecosystem: {
			onAppClientEvent?: {
				payload?: string;
			};
		};
	};
};

const getContextKey = (context: ClientSubscribeContext) => {
	return Object.entries(context)
		.sort((a, b) => a[0].localeCompare(b[0]))
		.toString();
};

const unsubscribeAllSubscriptions = (activeSubscriptions: Map<string, Subscription>) => {
	activeSubscriptions.forEach((subscription) => {
		if (!subscription.closed) {
			subscription.unsubscribe();
		}
	});
	activeSubscriptions.clear();
};

/**
 * This hook is used to subscribe to client events from the extension.
 * It will return a function that can be called to unsubscribe from the client events.
 *
 * This hook has no side effect (i.e. does not actively trigger any network calls or actively making any subscription.
 * It is because everything inside this hook is lazily initialized.
 * Importantly:
 * - It only has impact when the subscribeClientEvent is called by the client (e.g. @forge/bridge)
 * - The backend service (AGG subscription) is feature flagged and is not currently live in production, hence calling this hook in production or without
 *   enabled flag will have no impact.
 *
 * @param appId The app id
 * @param contextAri The context ari
 * @returns The subscribeClientEvent function that can be called to subscribe to client events
 */
export const useSubscribeClientEventsBridge = (
	// only supports a single context id, must be ARI
	appId: string,
	contextAri: string,
) => {
	const activeSubscriptions = useRef<Map<string, Subscription>>(new Map());

	useEffect(() => {
		const currentSubscriptions = activeSubscriptions.current;
		return () => {
			unsubscribeAllSubscriptions(currentSubscriptions);
		};
	}, []);

	// this is the bridge method that will be called from the extension
	const subscribeClientEvent: SubscribeClientEvent = useCallback(
		async (payload: SubscribeClientEventPayload) => {
			const { context, onClientEvent } = payload.data;

			const subscription = subscribe(
				{
					operationName: 'onAppClientEvent',
					query: print(appClientEventsSubscription),
					variables: {
						appId,
						contextAri,
						...context, // TODO: We are allowing the client to override appId and contextAri currently for experimentation, but this should be removed in the future.
					},
				},
				{
					next: (result: SubscriptionResult) => {
						const { payload } = result.data.ecosystem.onAppClientEvent ?? {};
						onClientEvent(payload ?? null);
					},
					error: (err) => {
						onClientEvent(null, err as Error);
					},
					complete: () => {},
				},
			);

			const contextKey = getContextKey(context);
			const existingSubscription = activeSubscriptions.current.get(contextKey);
			if (existingSubscription && !existingSubscription.closed) {
				existingSubscription.unsubscribe();
			}
			activeSubscriptions.current.set(contextKey, subscription);

			return {
				unsubscribe: () => {
					// close the subscription
					if (!subscription.closed) {
						subscription.unsubscribe();
					}
					activeSubscriptions.current.delete(contextKey);
				},
			};
		},
		[appId, contextAri],
	);

	return {
		// eslint-disable-next-line @atlaskit/platform/ensure-feature-flag-prefix
		subscribeClientEvent: fg('forge-ui-enable-websocket-support')
			? subscribeClientEvent
			: undefined,
	};
};
