import { type ForgeUIExtensionType, type ForgeExtensionPoints } from '@atlassian/forge-ui-types';
import {
	confluenceExtensionURLHelpers,
	confluenceNavigationTargetURLHelpers,
} from './navigation-targets/confluence';
import { type TargetURLUtils, getModuleByKey, NavigationError } from './navigation-targets/common';
import { jiraExtensionURLHelpers, jiraNavigationTargetHandlers } from './navigation-targets/jira';
import { ensureURLPath } from './createMakeProductURLPath';

type FullForgeUIExtensionType = ForgeUIExtensionType & {
	properties: ForgeUIExtensionType['properties'] & {
		route?: string;
	};
};

const extensionURLHelpers = {
	...confluenceExtensionURLHelpers,
	...jiraExtensionURLHelpers,
} as const;

const navigationTargetHandlers = {
	...jiraNavigationTargetHandlers,
	...confluenceNavigationTargetURLHelpers,
	userProfile: async ({ accountId }: { accountId: string }) =>
		ensureURLPath(`/people/${accountId}`),
	module: async (
		{ moduleKey, ...params }: { moduleKey?: string } & Record<string, unknown>,
		{ getAppForgeUIExtensions }: TargetURLUtils,
	) => {
		if (!moduleKey) {
			throw NavigationError.MissingProperty('moduleKey');
		}

		const module = await getModuleByKey(getAppForgeUIExtensions, moduleKey);
		if (!module || !module.appId || !module.environmentId) {
			throw NavigationError.ModuleNotFound(moduleKey);
		}

		const moduleUrlHelper = extensionURLHelpers[module.type as ForgeExtensionPoints];
		if (!moduleUrlHelper) {
			throw NavigationError.NotSupportedExtensionType(module.type as ForgeExtensionPoints);
		}
		return moduleUrlHelper(module as FullForgeUIExtensionType, params);
	},
} as const;

type NavigationTargetHandlers = typeof navigationTargetHandlers;
type NavigationTargetType = keyof NavigationTargetHandlers;

type FirstParameterType<T> = T extends (...args: infer P) => unknown
	? P extends [infer U, ...rest: unknown[]]
		? U
		: unknown
	: never;

type NavigateTargetInfo<T extends NavigationTargetType> = FirstParameterType<
	NavigationTargetHandlers[T]
> & {
	target: T;
};

type NavigationTargetHandler<T extends NavigationTargetType> = (
	data: Omit<NavigateTargetInfo<T>, 'target'>,
	utils: TargetURLUtils,
) => Promise<string | null>;

export const createNavigateTargetUrl = async <T extends NavigationTargetType>(
	targetInfo: NavigateTargetInfo<T>,
	utils: TargetURLUtils,
) => {
	const { target, ...payload } = targetInfo;
	const handler = navigationTargetHandlers[target] as unknown as NavigationTargetHandler<T>;

	if (!handler) {
		return null;
	}
	try {
		return await handler(payload, utils);
	} catch (e) {
		// eslint-disable-next-line no-console
		console.error((e as Error).message);
		return null;
	}
};
