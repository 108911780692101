import React, { Fragment } from 'react';

import { Box } from '@atlaskit/primitives';
import { isEmbeddedConfluence_DO_NOT_USE } from '@atlassian/embedded-confluence/isEmbeddedConfluence';

import { usePageContentId } from '@confluence/page-context';
import { SpaceOverviewLoader } from '@confluence/space-overview';
import { EmbeddedViewPageLoader } from '@confluence/embedded-confluence';

import { CommentsSectionLoader } from '../../CommentsSection';
import { ClassicEditorContextProviderLoader } from '../../ClassicEditorContextProvider';
import { ContentExtensionsProvider } from '../../ContentExtensionsProvider';
import SpaceBase from '../../SpaceBase';
import { LazyDwellTime } from '../lazyComponents';
import { CommentContextWrapper } from '../commentContextWrapper';

// Currently SSR renders SpaceOverviewLoader directly
// TODO: We should render everything on the server side to unify the route files

type EmbeddedSpaceOverviewProps = {
	spaceKey: string;
	contentId: string;
};

const EmbeddedSpaceOverview = ({ spaceKey, contentId }: EmbeddedSpaceOverviewProps) => {
	return (
		<Box padding="space.200">
			<EmbeddedViewPageLoader
				contentId={contentId}
				spaceKey={spaceKey}
				classicComments={CommentsSectionLoader}
				ClassicEditorContextProviderLoader={ClassicEditorContextProviderLoader}
				showHeaderButtons={false}
			/>
		</Box>
	);
};

type SpaceOverviewProps = {
	spaceKey: string;
};

const SpaceOverview = ({ spaceKey }: SpaceOverviewProps) => {
	return <SpaceOverviewLoader spaceKey={spaceKey} />;
};

type SpaceOverviewRouteComponentProps = {
	spaceKey: string;
};

export const SpaceOverviewRouteComponent = ({ spaceKey }: SpaceOverviewRouteComponentProps) => {
	const [contextContentId] = usePageContentId();
	const contentId: string = contextContentId ?? '';
	const component = isEmbeddedConfluence_DO_NOT_USE() ? (
		<EmbeddedSpaceOverview contentId={contentId} spaceKey={spaceKey} />
	) : (
		<SpaceOverview spaceKey={spaceKey} />
	);
	return (
		<Fragment>
			<SpaceBase spaceKey={spaceKey}>
				<ContentExtensionsProvider>
					<CommentContextWrapper>{component}</CommentContextWrapper>
				</ContentExtensionsProvider>
			</SpaceBase>
			<LazyDwellTime />
		</Fragment>
	);
};
export default SpaceOverviewRouteComponent;
