import { query } from '@confluence/query-preloader-tools';

import type {
	ContentPrerequisitesQuery as QP,
	ContentPrerequisitesQueryVariables as VP,
} from './__types__/ContentPrerequisitesQuery';
import { ContentPrerequisitesQuery } from './ContentPrerequisitesQuery.graphql';
import type {
	ContentRedirectQuery as QR,
	ContentRedirectQueryVariables as VR,
} from './__types__/ContentRedirectQuery';
import { ContentRedirectQuery } from './ContentRedirectQuery.graphql';

const preloadContentPrerequisitesQuery = (contentId: string, spaceKey: string | null | undefined) =>
	query<QP, VP>({
		query: ContentPrerequisitesQuery,
		errorPolicy: 'all',
		variables: {
			contentId,
			missingSpaceKey: !spaceKey,
			spaceKey,
		},
	});

const preloadContentRedirectQuery = ({
	contentId,
	versionOverride,
}: PreloadContentRedirectQueryArgs) =>
	query<QR, VR>({
		query: ContentRedirectQuery,
		errorPolicy: 'all',
		variables: {
			contentId,
			versionOverride,
		},
	});

type PreloadContentRedirectQueryArgs = {
	contentId: string;
} & Pick<PreloadContentPrerequisitesNamedArgs, 'versionOverride'>;

export const preloadContentPrerequisites = (
	contentId: string,
	spaceKey: string | null | undefined,
	// Note: Add new function parameters/arguments to namedArgs below please! More than 3-4 unnamed function arguments makes it noticeably harder to comprehend the function calls.
	namedArgs?: PreloadContentPrerequisitesNamedArgs,
) =>
	Promise.all([
		preloadContentPrerequisitesQuery(contentId, spaceKey),
		preloadContentRedirectQuery({ contentId, ...namedArgs }),
	]);

type PreloadContentPrerequisitesNamedArgs = {
	versionOverride?: number | null;
};
