import { useIsCurrentPageLiveRaw } from './LivePagesStore';
import { useLivePageSSRStatus } from './useLivePageSSRStatus';

/**
 * Returns if the current page is of subtype `live`. We trust that BE only returns `live` subtype only if the feature is enabled.
 * To check if live pages as a feature is enabled, use `isLivePagesFeatureEnabled`.
 * @returns { boolean } if the current page is of subtype `live`
 */
export const useIsCurrentPageLive = (): boolean => {
	const [isLiveClientside] = useIsCurrentPageLiveRaw();
	const { isTypeKnown: isSSRKnown, isLive: isLiveSSR } = useLivePageSSRStatus();

	// Use SSR value if we know we're in SSR mode, otherwise fall back to client-side store value
	return isSSRKnown ? !!isLiveSSR : isLiveClientside;
};
