// Extension
export const REDACTION_EXTENSION_TYPE = 'com.atlassian.guard-premium';

export interface RedactionExtensionParams {
	redactionId: string;
}

// Tour
export const IS_DISMISSED_FLAG = 'false';

export enum MessageType {
	Engagement = 'engagement',
	Transactional = 'transactional',
}

export enum RedactionsTourSteps {
	FIRST,
	SECOND,
}

const REDACTIONS_TOUR_TARGET_PREFIX = 'redactions-tour:';
const OBJECT_SIDEBAR_TOUR_TARGET_PREFIX = 'object-sidebar-tour-target:';
export const REDACTIONS_TOUR_TARGETS = {
	REDACTION_EXTENSION: `${REDACTIONS_TOUR_TARGET_PREFIX}redaction-extension`,
	TITLE_REDACTION: `${REDACTIONS_TOUR_TARGET_PREFIX}title-redaction`,
	// Note: SpotlightTarget already exist on this element, so we must target that one
	SIDEBAR_DETAILS_BUTTON: `${OBJECT_SIDEBAR_TOUR_TARGET_PREFIX}sidebar-details-button`,
};

// Misc.
export const READ_MORE_URL =
	'https://support.atlassian.com/confluence-cloud/docs/how-redaction-works/';
