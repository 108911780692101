/**
 * @jsxRuntime classic
 * @jsx jsx
 */
import React from 'react';
// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import { css, jsx } from '@emotion/react';
import { type ToggleProps, type FieldProps } from '@atlassian/forge-ui-types';

import { type Props } from '../..';
import { FormStateChangeNotifier } from '../form';

const AKToggle = React.lazy(() =>
	import(
		/* webpackChunkName: '@atlaskit-internal_.toggle' */
		'@atlaskit/toggle'
	).then((module) => ({
		default: module.default,
	})),
);

const AKField = React.lazy(() =>
	import(
		/* webpackChunkName: '@atlaskit-internal_.form' */
		'@atlaskit/form'
	).then((module) => ({ default: module.Field })),
);

const wrapperStyles = css({
	display: 'flex',
	alignItems: 'center',
});

const labelStyles = css({
	cursor: 'pointer',
});

const Toggle: React.FunctionComponent<ToggleProps & { testId?: string }> = ({
	name,
	defaultChecked,
	testId,
	label,
}) => (
	<AKField defaultValue={defaultChecked} name={name}>
		{({ fieldProps }: { fieldProps: FieldProps }) => {
			const { onChange, value, id, isDisabled, onBlur, onFocus, name: toggleName } = fieldProps;

			return (
				<div css={wrapperStyles}>
					<FormStateChangeNotifier name={name} value={value} />
					<AKToggle
						onChange={onChange}
						isChecked={value}
						size="large"
						label={label}
						testId={testId}
						id={id}
						isDisabled={isDisabled}
						onBlur={onBlur}
						onFocus={onFocus}
						name={toggleName}
					/>
					{/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
					<span css={labelStyles} onClick={() => onChange(!value)}>
						{label}
					</span>
				</div>
			);
		}}
	</AKField>
);

export default Toggle;

export const ToggleFn: React.FunctionComponent<Props> = ({ props }) => {
	const { name, label, defaultChecked } = props as ToggleProps;
	return <Toggle name={name} label={label} defaultChecked={defaultChecked} />;
};
