import { type ComponentPropsConfig } from '../../../utils/codegen';

/**
 * `Tab` represents an individual tab displayed in a TabList.
 */
export const componentPropsConfig: ComponentPropsConfig = {
	children: true,
	testId: true,
};

/**
 * THIS SECTION WAS CREATED VIA CODEGEN DO NOT MODIFY {@see http://go/af-codegen}
 *
 * This component was generated against an existing platform component.
 * If you are seeing lint errors in this file, it means that this file's dependencies have been modified and the component will need to be regenerated.
 *
 * Please view https://hello.atlassian.net/wiki/spaces/ECO/pages/3358130330/How+to+Regenerate+UI+Kit+Components for more information on how to proceed.
 *
 * For further questions, please ping the #help-forge-ui channel.
 *
 * @codegen <<SignedSource::35be921eccd963e6900b7719e87a9764>>
 * @codegenId #tab
 * @codegenCommand yarn workspace @atlassian/forge-ui codegen tab
 * @codegenDependency ../../../../../../../design-system/tabs/src/types.tsx <<SignedSource::f2168d42d44e8f2d02748d5db80bad13>>
 * @codegenDependency ../../../../../../../design-system/tabs/src/hooks.tsx <<SignedSource::a657d685e36a99316aa148260b467552>>
 * @codegenDependency ../../../../../../../design-system/tabs/src/components/tab.tsx <<SignedSource::582ca750003868ada81f48413fda2de6>>
 */
/* eslint @repo/internal/codegen/signed-source-integrity: "warn" */
import React from 'react';
import type { RenderFn } from '@atlassian/forge-ui-types';
import { Tab as PlatformTab } from '@atlaskit/tabs';

type PlatformTabProps = React.ComponentProps<typeof PlatformTab>;

export type TabProps = Pick<
  PlatformTabProps,
  'children' | 'testId'
>;

/**
 * `Tab` represents an individual tab displayed in a TabList.
 */
export const Tab = (props: Parameters<RenderFn>[0]) => {
  const {
    testId,
  } = props.forgeDoc.props as TabProps;
  return (
    <PlatformTab
      children={props.forgeDoc.children.map(c => <React.Fragment key={c.key}>{props.render(c)}</React.Fragment>)}
      testId={testId}
    />
  );
};
/**
 * @codegenEnd
 */
