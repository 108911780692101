import { UFOv2 } from '@atlassian/post-office-frontend-performance-tracking';
import { type FunctionComponent, type ReactNode } from 'react';

interface FeatureFlaggedUfoWrapperProps {
	isEnabled: boolean;
	messageTemplateId: string; // Adjust the type according to your actual context structure
	children: ReactNode;
}

export const FeatureFlaggedUfoWrapper: FunctionComponent<FeatureFlaggedUfoWrapperProps> = ({
	isEnabled,
	messageTemplateId,
	children,
}) => {
	if (isEnabled) {
		return (
			<UFOv2.UFOSegment name={UFOv2.SegmentNames.MESSAGE}>
				<UFOv2.UFOSegment name={`${UFOv2.SegmentNames.MESSAGE}.${messageTemplateId}`}>
					{children}
				</UFOv2.UFOSegment>
			</UFOv2.UFOSegment>
		);
	}
	return <>{children}</>;
};
