import { useMemo } from 'react';
import { EgressFilteringService } from '@forge/egress';
import { type Extension } from '../web-client';

type PermissionType = 'IMAGES' | 'MEDIA' | 'FETCH_CLIENT_SIDE';

export const useEgressPermissionService = (
	permissionType: PermissionType,
	egress: Extension['egress'],
): EgressFilteringService => {
	return useMemo(() => {
		const egressUrls = (egress || []).find((permission) => permission.type === permissionType);

		const allowedUrls = egressUrls ? egressUrls.addresses : [];

		return new EgressFilteringService(allowedUrls);
	}, [permissionType, egress]);
};
