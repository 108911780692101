import type { PropsWithChildren } from 'react';
import React, { useCallback } from 'react';
import type { MessageDescriptor } from 'react-intl-next';
import { FormattedMessage } from 'react-intl-next';

import { Inline, Flex, Stack, Text, Pressable, Box } from '@atlaskit/primitives/compiled';
import { token } from '@atlaskit/tokens';
import { cssMap, cx } from '@atlaskit/css';
import { Label } from '@atlaskit/form';
import DropdownMenu, { DropdownItem, DropdownItemGroup } from '@atlaskit/dropdown-menu';
import ChevronDownIcon from '@atlaskit/icon/utility/chevron-down';
import { useAnalyticsEvents } from '@atlaskit/analytics-next';

import type { RenderVariant } from '../../../linkCardsTypes';
import { useIsDisabledContext } from '../../../../shared-components';

const styles = cssMap({
	dropdownTrigger: {
		color: token('color.text.subtle'),
		paddingInline: token('space.150'),
		paddingBlock: token('space.075'),
		backgroundColor: token('color.background.neutral.subtle'),
		'&:hover': {
			backgroundColor: token('color.background.neutral.subtle.hovered'),
		},
	},
	dropdownFullWidthStyles: {
		width: '100%',
	},
	border: {
		borderWidth: token('border.width'),
		borderColor: token('color.border'),
		borderStyle: 'solid',
		borderRadius: token('border.radius.100'),
	},
	iconBackgroundStyles: {
		backgroundColor: token('color.background.accent.gray.subtler'),
		width: '32px',
		height: '32px',
		borderRadius: token('border.radius.050'),
		alignItems: 'center',
		justifyContent: 'center',
	},
	dropdownTriggerInnerStyles: {
		gap: token('space.050'),
	},
	dropdownTriggerTextStyles: {
		display: 'inline-block',
		flex: '1 0 0',
		overflow: 'hidden',
		textAlign: 'left',
		textOverflow: 'ellipsis',
		whiteSpace: 'nowrap',
	},
	iconContainerStyles: {
		flexShrink: 0,
	},
	selected: {
		color: token('color.text.selected'),
		backgroundColor: token('color.background.selected'),
		borderColor: token('color.border.accent.blue'),
	},
	fieldStyles: {
		width: '100%',
	},
});

const FieldWrapper = ({ useInline, children }) =>
	useInline ? (
		<Inline alignBlock="center" spread="space-between">
			{children}
		</Inline>
	) : (
		<Stack>{children}</Stack>
	);

export type FieldOption<TOptionValue> = {
	icon?: React.ElementType;
	label: MessageDescriptor;
	description?: MessageDescriptor;
	value: TOptionValue;
};

type FieldDropdownProps<TOptionValue> = {
	id: string;
	isDisabled: boolean;
	onSelect: (option: FieldOption<TOptionValue>) => void;
	selectedOption: FieldOption<TOptionValue>;
	showUpdatedStyles: boolean;
	options: FieldOption<TOptionValue>[];
};

const FieldDropdown = <TOptionValue,>({
	id,
	isDisabled,
	onSelect,
	selectedOption,
	showUpdatedStyles,
	options,
}: FieldDropdownProps<TOptionValue>) => (
	<DropdownMenu
		trigger={({ triggerRef, ...props }) => (
			<Pressable
				{...props}
				id={id}
				isDisabled={isDisabled}
				ref={triggerRef}
				xcss={cx(
					styles.dropdownTrigger,
					styles.border,
					!showUpdatedStyles && styles.dropdownFullWidthStyles,
					props.isSelected && styles.selected,
				)}
			>
				<Inline
					alignBlock="center"
					xcss={cx(styles.dropdownTriggerInnerStyles, props.isSelected && styles.selected)}
				>
					<Inline xcss={styles.dropdownTriggerTextStyles}>
						<Text color="inherit" weight="medium">
							<FormattedMessage {...selectedOption.label} />
						</Text>
					</Inline>
					<Box xcss={styles.iconContainerStyles}>
						<ChevronDownIcon spacing="none" label="" />
					</Box>
				</Inline>
			</Pressable>
		)}
		shouldFitContainer={!showUpdatedStyles}
	>
		<DropdownItemGroup>
			{options.map((option) => {
				return (
					<DropdownItem
						isSelected={selectedOption.value === option.value}
						key={`${option.value}-key`}
						{...(option.icon && {
							elemBefore: (
								<Flex xcss={styles.iconBackgroundStyles}>
									<option.icon label={`${option.value}-option-icon`} color={token('color.icon')} />
								</Flex>
							),
						})}
						{...(option.description && {
							description: <FormattedMessage {...option.description} />,
						})}
						onClick={() => onSelect(option)}
					>
						<FormattedMessage {...option.label} />
					</DropdownItem>
				);
			})}
		</DropdownItemGroup>
	</DropdownMenu>
);

type ConfigFieldDropdownProps<TOptionValue> = PropsWithChildren<{
	fieldType: string;
	label: MessageDescriptor;
	onSelect: (option: FieldOption<TOptionValue>) => void;
	options: FieldOption<TOptionValue>[];
	renderVariant: RenderVariant;
	selectedOption: FieldOption<TOptionValue>;
}>;

export const ConfigFieldDropdown = <TOptionValue,>({
	children,
	fieldType,
	label,
	onSelect,
	options,
	renderVariant,
	selectedOption,
}: ConfigFieldDropdownProps<TOptionValue>) => {
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const isDisabled = useIsDisabledContext();

	// @TODO: set to true when config panel styles are updated and ready for use
	const showUpdatedStyles = false;

	const handleSelect = useCallback(
		(selectedLabel: FieldOption<TOptionValue>) => {
			onSelect(selectedLabel);

			createAnalyticsEvent({
				type: 'sendUIEvent',
				data: {
					action: 'clicked',
					actionSubject: 'dropdownItem',
					actionSubjectId: `${fieldType}Dropdown`,
					source: 'cardsExtensionConfig',
					attributes: {
						renderVariant,
					},
				},
			}).fire();
		},
		[createAnalyticsEvent, fieldType, onSelect, renderVariant],
	);

	return (
		<Flex direction="column" xcss={cx(styles.fieldStyles)}>
			<Stack space="space.200">
				<FieldWrapper useInline={showUpdatedStyles}>
					<Label htmlFor={`${fieldType}-dropdown`}>
						<Text
							size={showUpdatedStyles ? 'medium' : 'small'}
							color={showUpdatedStyles ? 'color.text' : 'color.text.subtlest'}
							weight={showUpdatedStyles ? 'regular' : 'bold'}
						>
							<FormattedMessage {...label} />
						</Text>
					</Label>
					<FieldDropdown
						id={`${fieldType}-dropdown`}
						isDisabled={isDisabled}
						onSelect={handleSelect}
						selectedOption={selectedOption}
						showUpdatedStyles={showUpdatedStyles}
						options={options}
					/>
				</FieldWrapper>
			</Stack>
			{children}
		</Flex>
	);
};
