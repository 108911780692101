import type { Render } from '@atlassian/forge-ui-types';
import { elementToForgeDoc } from '../../../../utils/elementToForgeDoc';
import { type ComponentPropsConfig } from '../../../utils/codegen';

export const componentPropsConfig: ComponentPropsConfig = {
	children: true,
	appearance: true,
	title: true,
	actions: {
		value: 'actions ? renderActions(props.render, actions) as React.ReactElement[] : undefined',
		comment: 'pre-render actions before inserting as props',
	},
	icon: false,
	testId: true,
};

const renderActions = (render: Render, actions: React.ReactElement | React.ReactElement[]) =>
	Array.isArray(actions)
		? actions.map((action: React.ReactElement) => render(elementToForgeDoc(action)))
		: [render(elementToForgeDoc(actions))];

/**
 * THIS SECTION WAS CREATED VIA CODEGEN DO NOT MODIFY {@see http://go/af-codegen}
 *
 * This component was generated against an existing platform component.
 * If you are seeing lint errors in this file, it means that this file's dependencies have been modified and the component will need to be regenerated.
 *
 * Please view https://hello.atlassian.net/wiki/spaces/ECO/pages/3358130330/How+to+Regenerate+UI+Kit+Components for more information on how to proceed.
 *
 * For further questions, please ping the #help-forge-ui channel.
 *
 * @codegen <<SignedSource::4c55b28a99d7ce9e2b2ccf5f9cf1c359>>
 * @codegenId #section-message
 * @codegenCommand yarn workspace @atlassian/forge-ui codegen section-message
 * @codegenDependency ../../../../../../../design-system/section-message/src/types.tsx <<SignedSource::b21d73664ad9dc3f6d85413e39ff5771>>
 * @codegenDependency ../../../../../../../design-system/section-message/src/internal/appearance-icon.tsx <<SignedSource::ee5e1d57f6da2d7a2b84cc10633c6d18>>
 * @codegenDependency ../../../../../../../design-system/section-message/src/section-message.tsx <<SignedSource::55d693303bb5f7b96aa69957488d91ef>>
 */
/* eslint @repo/internal/codegen/signed-source-integrity: "warn" */
import React from 'react';
import type { RenderFn } from '@atlassian/forge-ui-types';
import PlatformSectionMessage from '@atlaskit/section-message';

type PlatformSectionMessageProps = React.ComponentProps<typeof PlatformSectionMessage>;

export type SectionMessageProps = Pick<
  PlatformSectionMessageProps,
  'children' | 'appearance' | 'title' | 'actions' | 'testId'
>;

/**
 * A section message is used to alert users to a particular section of the screen.
 */
export const SectionMessage = (props: Parameters<RenderFn>[0]) => {
  const {
    appearance,
    title,
    actions,
    testId,
  } = props.forgeDoc.props as SectionMessageProps;
  return (
    <PlatformSectionMessage
      children={props.forgeDoc.children.map(c => <React.Fragment key={c.key}>{props.render(c)}</React.Fragment>)}
      appearance={appearance}
      title={title}
      // pre-render actions before inserting as props
      actions={actions ? renderActions(props.render, actions) as React.ReactElement[] : undefined}
      testId={testId}
    />
  );
};
/**
 * @codegenEnd
 */
