import React from 'react';
import uuid from 'uuid/v4';
import { defineMessages } from 'react-intl-next';

import type { ExtensionManifest } from '@atlaskit/editor-common/extensions';
import { DefaultExtensionProvider } from '@atlaskit/editor-common/extensions';

import { fg } from '@confluence/feature-gating';
import { LoadableAfterPaint } from '@confluence/loadable';

import type { LinkCardsParameters } from '../linkCardsTypes';
import { RenderVariant, ImagePosition, Orientation, Alignment } from '../linkCardsTypes';
import { CARDS_VERSION_2, CARDS_VERSION_3 } from '../linkCardsVersion';
import { linkCardsExtensionKey, linkCardsExtensionType } from '../linkCardsExtensionType';
import { DEFAULT_CARDS_SIZE } from '../LinkCardsConfigPanel/components/Fields/SizeField';
import type { CustomSitesExtensionProviderProps } from '../../shared-components/customSitesManifestBuilder';

const LinkCardsExtension = LoadableAfterPaint({
	loader: async () =>
		(await import(/* webpackChunkName: "loadable-LinkCardsExtension" */ './LinkCardsExtension'))
			.LinkCardsExtension,
});

const cardImages = [
	{
		src: 'https://images.unsplash.com/photo-1464822759023-fed622ff2c3b?auto=format&fit=crop&q=80&w=1080',
		alt: 'green mountain across body of water',
	},
	{
		src: 'https://images.unsplash.com/photo-1501436513145-30f24e19fcc8?auto=format&fit=crop&q=80&w=1080',
		alt: 'calm body of water during golden hour',
	},
	{
		src: 'https://images.unsplash.com/photo-1580100586938-02822d99c4a8?auto=format&fit=crop&q=80&w=1080',
		alt: 'body of water near mountain during daytime',
	},
];

const i18n = defineMessages({
	linkCardsMacroTitle: {
		id: 'custom-sites-extensions.link-cards.manifest.macro-title',
		defaultMessage: 'Cards',
		description: 'Title of link cards macro to be displayed in toolbar/shortcut macros dropdown.',
	},
	linkCardsMacroDescription: {
		id: 'custom-sites-extensions.link-cards.manifest.macro-description',
		defaultMessage: 'Display links as cards',
		description:
			'Description of link cards macro to be displayed in toolbar/shortcut macros dropdown',
	},
	linkCardsMacroTitleNew: {
		id: 'custom-sites-extensions.link-cards.manifest.macro-title.new',
		defaultMessage: 'Cards [New]',
		description:
			'Title of link cards macro to be displayed in toolbar/shortcut macros dropdown. The [New] text is a text label indicating that this feature is new',
	},
});

export const createCard = (imageSrc: string, imageAltText: string) => ({
	cardId: uuid(),
	link: '',
	isLinkUserEdited: false,
	title: '',
	isTitleUserEdited: false,
	description: '',
	isDescriptionUserEdited: false,
	imageSrc,
	imagePosition: ImagePosition.MIDDLE,
	imageAltText,
});

let cards: ReturnType<typeof createCard>[] | null = null;
export const getDefaultCards = () => {
	if (cards) {
		return cards;
	}
	return (cards = cardImages.map(({ src, alt }) => createCard(src, alt)));
};

export const linkCardsParameters = (
	extensionTitle: string,
	isCardsConfigUpdatesEnabled: boolean,
): LinkCardsParameters => {
	const parameters: LinkCardsParameters = {
		size: DEFAULT_CARDS_SIZE,
		cards: getDefaultCards(),
		isAvatarShown: true,
		isPublishDateShown: false,
		extensionTitle,
		version: CARDS_VERSION_2,
		renderVariant: RenderVariant.MANUAL,
		isSegmentationShown: false,
	};

	if (isCardsConfigUpdatesEnabled) {
		parameters.version = CARDS_VERSION_3;
		parameters.orientation = Orientation.ROW;
		parameters.alignment = Alignment.LEFT;
	}

	return parameters;
};

export const getLinkCardsExtensionManifest = ({
	editorActions,
	openConfigPanel,
	intl,
	setUpsellModalSource,
	contentId,
}): ExtensionManifest<LinkCardsParameters> => {
	return {
		title: intl.formatMessage(i18n.linkCardsMacroTitleNew),
		type: linkCardsExtensionType,
		key: linkCardsExtensionKey,
		description: intl.formatMessage(i18n.linkCardsMacroDescription),
		icons: {
			'48': () =>
				import(
					/* webpackChunkName: "loadable-CardMacroIcon" */
					'@confluence/icons/entry-points/CardMacroIcon'
				).then((mod) => mod.CardMacroIcon),
		},
		modules: {
			quickInsert: setUpsellModalSource
				? [
						{
							key: 'cards-upsell',
							action: async () => setUpsellModalSource('premiumCardsInsert'),
						},
					]
				: [
						{
							key: 'default-cards',
							action: {
								type: 'node',
								key: 'default',
								parameters: linkCardsParameters(
									intl.formatMessage(i18n.linkCardsMacroTitle),
									fg('company_hub_cards_config_updates'),
								),
							},
						},
					],
			nodes: {
				default: {
					type: 'extension',
					render:
						async () =>
						({ node }) => {
							return <LinkCardsExtension extensionNode={node} contentId={contentId} />;
						},
					update: async () => {
						const selectedNode = editorActions?.getSelectedNode()?.toJSON();
						const selectedLocalId = selectedNode?.attrs?.localId;

						if (!!selectedLocalId && openConfigPanel) {
							openConfigPanel(selectedLocalId);
						}
					},
				},
			},
		},
	};
};

export const linkCardsExtensionProvider = async ({
	contentId,
	editorActions,
	openConfigPanel,
	intl,
	setUpsellModalSource,
}: CustomSitesExtensionProviderProps) =>
	new DefaultExtensionProvider<any>([
		getLinkCardsExtensionManifest({
			contentId,
			editorActions,
			openConfigPanel,
			intl,
			setUpsellModalSource,
		}),
	]);
