import { type OnOpen, type OptionsV0, type CompletionStatus } from '../types';

/**
 * Version 0 of public Cross Flow API
 */
export interface API {
	open(options: OptionsV0): Promise<CompletionStatus>;
}

export const VERSION = 0;

export const createAPIv0 = (onOpen: OnOpen): API => ({
	open(options) {
		return onOpen(options);
	},
});
