import React from 'react';

import { aiConfigItemMessages } from '@atlaskit/editor-common/ai-messages';
import BulletedListIcon from '@atlaskit/icon/core/list-bulleted';

import { createInsertBelow, createReplace } from '../../actions/create-actions';
import { CONFIG_ITEM_KEYS } from '../../config-item-keys';
import type {
	EditorPluginAIConfigItemWithOptions,
	EditorPluginAIConfigItem,
} from '../../config-items';

const intentSchemaId = 'convert_to_bullet_points.json';

export const convertToBulletList: EditorPluginAIConfigItem = {
	key: CONFIG_ITEM_KEYS.CONVERT_TO_BULLETED_LIST,
	title: aiConfigItemMessages.convertToBulletListTitle,
	description: aiConfigItemMessages.convertToBulletListDescription,
	icon: () => <BulletedListIcon label="" spacing="spacious" />,
	statusLozengeType: 'beta',
	intentSchemaId,
	actions: {
		selection: [
			createInsertBelow({ appearance: 'secondary' }),
			createReplace({ appearance: 'primary' }),
		],
	},
};

export const convertToBulletListWithOptions: EditorPluginAIConfigItemWithOptions = {
	config: convertToBulletList,
	triggers: { empty: { enabled: false }, selection: { enabled: true } },
};
