import { fg } from '@atlaskit/platform-feature-flags';

import { getAnalyticsClient, isSSR, Logger } from '../../../common/utils';
import { getBscGlobalState, initializeGlobalState } from '../../../common/utils/bsc-global-state';
import { WebStorageType } from '../../../services/web-storage-service';
import type { CommonConsentBannerProps } from '../../../types';

import { createDocumentCookiePassthrough } from './create-document-cookie-passthrough';
import { createWebStoragePassthrough } from './create-web-storage-passthrough';

export type InitializeControlsArgs = CommonConsentBannerProps & {
	product?: string;
	flagEnabled?: boolean;
	webStorageEnabled?: boolean;

	/**
	 * If set to true, cookies and web storage items that are not categorized will
	 * be allowed to be set through overridden functions (such as document.cookie,
	 * localStorage.setItem, etc).
	 */
	allowUncategorizedCookiesInOverrides?: boolean;
};

export const initializeControls = async (
	args: InitializeControlsArgs = {
		analyticsEnabled: true,
	},
) => {
	if (fg('platform_moonjelly_bsc_master_killswitch')) {
		// Exit if the master killswitch is enabled
		return;
	}

	try {
		Logger.debug('initializeControls invoked');
		initializeGlobalState(args);

		// Exit silently if in SSR context
		if (isSSR()) {
			return;
		}

		// Ensures that the AWC is constructed before overwriting any native methods.
		const analyticsWebClientInstance = getAnalyticsClient();

		// If AWC is not initialized, it will be undefined
		const isAnalyticsInitialized: boolean = !!analyticsWebClientInstance;

		if (args.analyticsEnabled && !isAnalyticsInitialized) {
			throw new Error(
				`Failed to initialize AnalyticsWebClient. Analytics logging will not be set for native method overrides.`,
			);
		}

		await createDocumentCookiePassthrough();

		const isWebStorageControlsEnabled = getBscGlobalState().webStorageEnabled;
		if (!isWebStorageControlsEnabled) {
			return;
		}

		await createWebStoragePassthrough(WebStorageType.LocalStorage);
		await createWebStoragePassthrough(WebStorageType.SessionStorage);

		Logger.debug('initiizeControls finished');
	} catch (e: any) {
		Logger.errorWithOperationalEvent({
			action: 'initializeControlsError',
			message: `Failed to initialize browser storage controls. ${e.message || ''}`,
		});
	}
};

export { createDocumentCookiePassthrough, createWebStoragePassthrough };
