import React from 'react';

import {
	CONTAINER_ADMIN_HOME_ID,
	CONTAINER_HOME_ID,
} from '@confluence/app-navigation/entry-points/navigationIDs';
import { SPACE_ANALYTICS_PERMISSIONS_EVENTS_SOURCE } from '@confluence/confluence-analytics/entry-points/analyticsConstants';
import { SPACE_PERMISSIONS_SETTINGS } from '@confluence/named-routes';
import type { RouteMatch } from '@confluence/route';
import { Redirection } from '@confluence/route-manager';

export const SpaceAnalyticsPermissionsRoute = ({ params: { spaceKey } }: RouteMatch) => (
	<Redirection href={SPACE_PERMISSIONS_SETTINGS.toUrl({ spaceKey, tab: 'analytics' })} />
);

SpaceAnalyticsPermissionsRoute.NAVIGATION_PARAMS = (
	{ name, params: { spaceKey } }: RouteMatch,
	isNav4Enabled: boolean,
) => ({
	Screen: {
		screenEvent: { name: SPACE_ANALYTICS_PERMISSIONS_EVENTS_SOURCE, id: spaceKey },
		pageState: { routeName: name, spaceKey },
	},
	MainLayout: {
		spaceKey,
		navView: isNav4Enabled ? CONTAINER_ADMIN_HOME_ID : CONTAINER_HOME_ID,
	},
});
