/* eslint-disable no-dupe-class-members */
import type { ActionMeasure } from './types';

export class BaseMeasures {
	protected measures: Record<string, ActionMeasure> = {};

	public run<T>(name: string | string[], fn: () => T): T;
	public run<T>(name: string | string[], fn: () => Promise<T>): Promise<T>;
	public run<T>(name: string | string[], fn: () => T | Promise<T>): T | Promise<T> {
		if (process.env.NODE_ENV === 'testing') {
			return fn();
		}

		const names = Array.isArray(name) ? name : [name];
		names.forEach((name) => this.markMeasureStart(name));
		const result = fn();
		if (typeof (result as Promise<T>)?.then === 'function') {
			// eslint-disable-next-line @typescript-eslint/no-floating-promises
			(result as Promise<T>).finally(() => {
				names.forEach((name) => this.markMeasureEnd(name));
			});
		} else {
			names.forEach((name) => this.markMeasureEnd(name));
		}
		return result;
	}

	public markMeasureStart(name: string, timestamp?: number) {
		//if startTime already marked, don't mark again.
		if (this.measures[name]?.startTime) {
			return;
		}
		this.measures[name] = {
			startTime: Math.floor(timestamp ?? performance.now()),
			duration: 0,
		};
	}

	public markMeasureEnd(name: string, timestamp?: number, allowEndOnly = false) {
		const entry = this.measures[name];
		if (!entry?.duration && entry?.startTime) {
			entry.duration = Math.floor((timestamp ?? performance.now()) - entry.startTime);
		} else if (!entry && allowEndOnly) {
			// In allowEndOnly mode we take FIRST end time as duration
			this.measures[name] = {
				startTime: 0,
				duration: timestamp ?? performance.now(),
			};
		}
	}

	public getMeasures(): Record<string, ActionMeasure> {
		return this.measures;
	}

	public clearMeasures(): void {
		this.measures = {};
	}
}
