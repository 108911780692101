import React from 'react';
import type { RenderFn } from '@atlassian/forge-ui-types';
import { default as PlatformComment } from '@atlaskit/comment';

// Public API
// Omit for props we don't want to implement yet
export type CommentProps = Omit<
	React.ComponentProps<typeof PlatformComment>,
	'time' | 'author' | 'edited' | 'errorActions'
>;

// Omit for overriding props that are React components
export type InternalCommentProps = Omit<CommentProps, 'avatar'> & {
	children: React.ReactNode;
};

export const Comment = (props: Parameters<RenderFn>[0]) => {
	const {
		actions,
		afterContent,
		content,
		errorIconLabel,
		headingLevel,
		highlighted,
		id,
		isError,
		isSaving,
		restrictedTo,
		savingText,
		shouldRenderNestedCommentsInline,
		testId,
		type,
	} = props.forgeDoc.props as InternalCommentProps;

	const avatarForgeDoc = props.forgeDoc.children.find((child) => {
		if (child.type === 'ContentWrapper' && child.props?.name === 'avatar') {
			return child;
		}
	});

	return (
		<PlatformComment
			actions={actions}
			afterContent={afterContent}
			avatar={avatarForgeDoc?.children.map(props.render)}
			children={props.forgeDoc.children
				.filter((child) => child.type !== 'ContentWrapper')
				.map((c) => (
					<React.Fragment key={c.key}>{props.render(c)}</React.Fragment>
				))}
			content={content}
			errorIconLabel={errorIconLabel}
			headingLevel={headingLevel}
			highlighted={highlighted}
			id={id}
			isError={isError}
			isSaving={isSaving}
			restrictedTo={restrictedTo}
			savingText={savingText}
			shouldRenderNestedCommentsInline={shouldRenderNestedCommentsInline}
			testId={testId}
			type={type}
		/>
	);
};
