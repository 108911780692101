import { useCallback, useEffect } from 'react';
import { useMutation, useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';

import { useAnalyticsEvents } from '@atlaskit/analytics-next';

import { confluenceLocalStorageInstance as localStorage, keys } from '@confluence/storage-manager';
import { fg } from '@confluence/feature-gating';
import { useRouteActions } from '@confluence/route-manager';
import { markErrorAsHandled } from '@confluence/graphql-error-processor';

import { useShouldSkipOptOutQuery } from './useShouldSkipOptOutQuery';

const OPT_OUT_CACHE_EXPIRATION_SECONDS = 365 * 24 * 60 * 60; // 1 year

export const useNav4OptOut = () => {
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const { shouldSkipOptOutQuery } = useShouldSkipOptOutQuery();

	const {
		loading,
		data,
		error: queryError,
	} = useQuery(
		gql`
			query useNav4OptOutQuery {
				userPreferences {
					nav4OptOut
				}
			}
		`,
		{
			skip: shouldSkipOptOutQuery,
		},
	);

	// Expected error - query will fail for anonymous and unlicensed users
	if (queryError?.message.includes('Cannot find authenticated user.')) {
		markErrorAsHandled(queryError);
	}

	const [updateNav4OptOutServer] = useMutation(gql`
		mutation useNav4OptOutMutation($nav4OptOut: Boolean!) {
			updateUserPreferences(userPreferences: { nav4OptOut: $nav4OptOut }) {
				nav4OptOut
			}
		}
	`);

	const neverOptedOut = !loading && data?.userPreferences?.nav4OptOut === null;
	const { setQueryParams } = useRouteActions();
	const updateNav4OptOut = useCallback(
		async (newNav4OptOut: boolean) => {
			updateNav4OptOutServer({ variables: { nav4OptOut: newNav4OptOut } })
				.then(() => {
					updateNav4OptOutCache(newNav4OptOut);

					createAnalyticsEvent({
						type: 'sendTrackEvent',
						data: {
							action: 'changed',
							actionSubject: 'navigationVersion',
							source: 'nav4OptOut',
							attributes: {
								originalNavVersion: newNav4OptOut ? '4' : '3',
								newNavVersion: newNav4OptOut ? '3' : '4',
							},
						},
					}).fire();

					if (newNav4OptOut && neverOptedOut && !fg('confluence_nav4_ga_phase_1')) {
						// If this is the first time opting out, reload with query param to trigger Nav4OptOutSpotlight
						setQueryParams({ navOptOut: 'true' });
						window.location.reload();
					} else {
						window.location.reload();
					}
				})
				.catch((error) => {
					createAnalyticsEvent({
						type: 'sendOperationalEvent',
						data: {
							action: 'changeFailed',
							actionSubject: 'navigationVersion',
							source: 'nav4OptOut',
							attributes: {
								error,
							},
						},
					}).fire();
				});
		},
		[updateNav4OptOutServer, createAnalyticsEvent, neverOptedOut, setQueryParams],
	);

	const optOutCache = getNav4OptOutCache();
	const optOutServer = data?.userPreferences?.nav4OptOut;
	const nav4OptOut =
		(fg('confluence_nav_4_opt') || fg('confluence_nav4_ga_phase_1')) &&
		(loading ? optOutCache : optOutServer);

	useEffect(() => {
		if (!loading && optOutServer !== optOutCache) {
			updateNav4OptOutCache(optOutServer);
		}
	}, [loading, optOutCache, optOutServer]);

	return {
		nav4OptOut,
		updateNav4OptOut,
	};
};

export const getNav4OptOutCache = () => {
	return localStorage?.getItem(keys.NAV4_OPT_OUT_CACHE) === 'true';
};

export const getNav4GAOptInCache = () => {
	return localStorage?.getItem(keys.NAV4_OPT_OUT_CACHE) === 'false';
};

const updateNav4OptOutCache = (nav4OptOut: boolean) => {
	if (nav4OptOut) {
		localStorage.setItem(keys.NAV4_OPT_OUT_CACHE, true, OPT_OUT_CACHE_EXPIRATION_SECONDS);
	} else if (nav4OptOut === false) {
		//if explicitly set to false, we need to save to check in phase_1 since false means "opt-in"
		localStorage.setItem(keys.NAV4_OPT_OUT_CACHE, false, OPT_OUT_CACHE_EXPIRATION_SECONDS);
	} else {
		//the value is null and has never been set
		localStorage?.removeItem(keys.NAV4_OPT_OUT_CACHE);
	}
};
