import { createContainer, createHook, createStore } from 'react-sweet-state';
import { v4 as uuid } from 'uuid';

export const NativeCollabPresenceStateContainer = createContainer();

const Store = createStore({
	containedBy: NativeCollabPresenceStateContainer,
	initialState: {
		presenceId: '',
	},
	actions: {},
	handlers: {
		onInit:
			() =>
			({ setState }) => {
				setState({
					presenceId: uuid(),
				});
			},
	},
});

export const useNativeCollabPresenceState = createHook(Store);
