import React, { useState } from 'react';
import { defineMessages, useIntl } from 'react-intl-next';

import FeedbackCollector from '@atlaskit/feedback-collector';
import Button from '@atlaskit/button/new';
import FeedbackIcon from '@atlaskit/icon/core/feedback';

import type { FlagsStateContainer } from '@confluence/flags';
import { withFlags } from '@confluence/flags';
import { fg } from '@confluence/feature-gating';
import { useSessionData } from '@confluence/session-data';

const i18n = defineMessages({
	buttonText: {
		id: 'space-roles.feedback-collector.button-text',
		defaultMessage: 'Give feedback',
		description: 'Text for a button that opens a feedback collector',
	},
	feedbackTitle: {
		id: 'space-roles.feedback-collector.title',
		defaultMessage: 'Give feedback',
		description:
			'Title for a feedback collector that asks for feedback on a new permissions experience',
	},
	feedbackTitleDetails: {
		id: 'space-roles.feedback-collector.title-details',
		defaultMessage:
			'How satisfied or dissatisfied are you overall with your experience using roles to manage access in Confluence?',
		description:
			'Title details for a feedback collector that asks for feedback on a new permissions experience',
	},
	flagTitle: {
		id: 'space-roles.feedback-collector.flag-title',
		defaultMessage: 'Feedback submitted, thank you!',
		description: 'Title for a flag that appears after feedback is submitted',
	},
});

const FEEDBACK_CONTEXT_ID = 'customfield_10047';
const ENTRYPOINT_ID = 'ba8149f4-b7d4-4f55-9e72-683a444d3b27';

const FeedbackCollectorButtonComponent = ({
	flags,
	source,
	appearance,
}: {
	flags: FlagsStateContainer;
	source: string;
	appearance?: 'default' | 'subtle';
}) => {
	const [isOpen, setIsOpen] = useState(false);
	const { formatMessage, locale } = useIntl();
	const { displayName, userId, cloudId, edition, environment } = useSessionData();

	const getFeedbackMetadata = (): string => {
		const feedbackMetadata = {
			userId,
			cloudId,
			edition,
			environment,
			source,
		};
		return JSON.stringify(feedbackMetadata, null, 2);
	};

	return fg('cc_perms_exp_rbac_fe_milestone_2') ? (
		<>
			<Button iconBefore={FeedbackIcon} appearance={appearance} onClick={() => setIsOpen(true)}>
				{formatMessage(i18n.buttonText)}
			</Button>
			{isOpen && (
				<FeedbackCollector
					onClose={() => {
						setIsOpen(false);
					}}
					onSubmit={() => {
						void flags.showSuccessFlag({
							title: formatMessage(i18n.flagTitle),
							isAutoDismiss: true,
						});
					}}
					entrypointId={ENTRYPOINT_ID}
					locale={locale}
					name={displayName ?? ''}
					atlassianAccountId={userId ?? undefined}
					showTypeField={false}
					feedbackTitle={formatMessage(i18n.feedbackTitle)}
					feedbackTitleDetails={formatMessage(i18n.feedbackTitleDetails)}
					additionalFields={[
						{
							id: FEEDBACK_CONTEXT_ID,
							value: getFeedbackMetadata(),
						},
					]}
				/>
			)}
		</>
	) : null;
};

export const FeedbackCollectorButton = withFlags(FeedbackCollectorButtonComponent);
