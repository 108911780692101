import { HomeStarredPagesQuery } from './HomeStarredPagesQuery.graphql';

const PAGE_SIZE = 24;

export const getStarredQueryOptions = (_: {}) => ({
	query: HomeStarredPagesQuery,
	variables: {
		limit: PAGE_SIZE,
		start: 0,
	},
});
