import { query } from '@confluence/query-preloader-tools';

import { FrontendResourceRenderQuery } from '../src/helpers/FrontendResourceRenderQuery.graphql';

export async function preloadSuperBatchData() {
	// The data required for SuperBatch is not currently needed on the server,
	// so we don't preload it there.
	//
	// However it is possible to load this and embed superbatch resources into
	// HTML as long as we know we need these (on View Page / Space Overview) and this
	// would improve macros loading.
	// This is TODO for https://product-fabric.atlassian.net/browse/PCC-1668
	// eslint-disable-next-line check-react-ssr-usage/no-react-ssr
	if (!process.env.REACT_SSR && !window.__SUPERBATCH_REQUESTED__) {
		return await query({ query: FrontendResourceRenderQuery });
	}

	return Promise.resolve();
}
