export const EXAMPLE_SPOTLIGHT = 'example.spotlight';
export const HELP_MENU_SPOTLIGHT = 'editor.help.center.spotlight.on.help.menu';

export const SPOTLIGHTS_FLOWS = [
	[
		// DEFAULT FLOW (aka last flow).
		// ------------
		// Important: Leave this array empty.
		// In this default flow, any spotlights that aren't specifically
		// mentioned in any of the flows above, will be grouped and shown
		// to the user as a set.
	],
];

export const SPOTLIGHT_CALLBACKS = {};
