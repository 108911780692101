import React from 'react';

import type { RouteMatch } from '@confluence/route';
import { CALENDAR_CONTEXT } from '@confluence/team-calendar/entry-points/TeamCalendars';

import { LazyTeamCalendars } from '../lazyComponents';

export const SpaceEmbeddedSubcalendarRoute = ({ params: { subCalendarId } }: RouteMatch) => {
	return (
		<LazyTeamCalendars
			calendarContext={CALENDAR_CONTEXT.EMBEDDED_SUBCALENDAR}
			subCalendarId={subCalendarId}
		/>
	);
};

SpaceEmbeddedSubcalendarRoute.NAVIGATION_PARAMS = ({
	params: { spaceKey, subCalendarId },
}: RouteMatch) => ({
	Screen: {
		screenEvent: {
			name: 'spaceEmbeddedSubcalendarScreen',
			id: spaceKey,
			attributes: {
				subCalendarId,
			},
		},
		pageState: { spaceKey },
	},
	MainLayout: {
		enableNavigation: false,
		spaceKey,
	},
});
