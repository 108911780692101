import React, { useState, useEffect, useContext } from 'react';
import { defineMessages, useIntl } from 'react-intl-next';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';

import { Box, xcss, Text } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import { Label } from '@atlaskit/form';

import { ExperienceTrackerContext } from '@confluence/experience-tracker';
import { RoleSelector } from '@confluence/space-roles/entry-points/TableRow';
import type { SpaceRole } from '@confluence/space-roles/entry-points/space-role-types';
import type { SpaceRolesByCriteriaQueryResult } from '@confluence/space-roles/entry-points/graphql';

import { RoleSelectInfoButton } from './info-popup/RoleSelectInfoButton';

const i18n = defineMessages({
	roleSelectionHeader: {
		id: 'bulk-permissions.space.role-selection-header',
		defaultMessage: 'With what access<asterisk>*</asterisk>',
		description: 'Header text of the role selection section for the bulk access page',
	},
	roleSelectionSubHeader: {
		id: 'bulk-permissions.space.role-selection-sub-header',
		defaultMessage: 'Choose the access role they should have.',
		description: 'Subheading text of the role selection section for the bulk access page',
	},
	roleSelectionWarning: {
		id: 'bulk-permissions.space.role-selection-warning',
		defaultMessage:
			'This will overwrite any existing access they have. <b>This can’t be undone</b> so make sure to double check your selections.',
		description: 'Warning text for changing the role in the bulk access page',
	},
	roleSelectLabel: {
		id: 'bulk-permissions.space.role-select-label',
		defaultMessage: 'Role',
		description: 'Label for the role select dropdown',
	},
	roleSelectPlaceholder: {
		id: 'bulk-permissions.space-role.select-placeholder',
		defaultMessage: 'Select a role',
		description: 'Placeholder for the role select dropdown',
	},
});

const roleSelectionHeaderStyle = xcss({
	font: token('font.body.large'),
	fontWeight: token('font.weight.medium'),
	display: 'flex',
	flexDirection: 'row',
	paddingTop: 'space.300',
	paddingBottom: 'space.100',
});

const asteriskStyle = xcss({
	color: 'color.text.accent.red',
});

const roleSelectionContainer = xcss({
	marginTop: 'space.150',
	marginBottom: 'space.300',
});

const sectionContainer = xcss({
	marginBottom: 'space.300',
});

const roleSelectInfoButtonStyle = xcss({
	display: 'flex',
	minWidth: '500px',
	alignItems: 'center',
});

const roleSelectStyle = xcss({
	minWidth: '300px',
});

const roleSelectionSectionRolesQuery = gql`
	query RoleSelectionSectionRolesQuery {
		spaceRolesByCriteria {
			nodes {
				roleId
				roleDisplayName
				roleDescription
				roleType
				spacePermissionList {
					id
					priority
					displayName
					description
					requiredSpacePermissions
				}
			}
		}
	}
`;

export const RoleSelectionSection = () => {
	const { formatMessage } = useIntl();
	const experienceTracker = useContext(ExperienceTrackerContext);

	const [rolePickerValue, setRolePickerValue] = useState<SpaceRole | null>(null);
	const [experienceStarted, setExperienceStarted] = useState(false);

	//TODO: update this and add it to experience tracker once the experience is created
	const BULK_ROLES_VIEW_EXPERIENCE = 'bulk-roles-view-experience';

	const placeholder = <Text color="color.text">{formatMessage(i18n.roleSelectPlaceholder)}</Text>;

	const { data, loading } = useQuery<SpaceRolesByCriteriaQueryResult>(
		roleSelectionSectionRolesQuery,
		{
			onCompleted: () => {
				setExperienceStarted(false);
				experienceTracker.succeed({ name: BULK_ROLES_VIEW_EXPERIENCE });
			},
			onError: (error) => {
				experienceTracker.stopOnError({ name: BULK_ROLES_VIEW_EXPERIENCE, error });
			},
		},
	);

	const roleOptions =
		data?.spaceRolesByCriteria?.nodes.map((role) => ({
			id: role?.roleId || '',
			name: role?.roleDisplayName || '',
			permissions: role?.spacePermissionList.map((permission) => permission.id) || [],
			description: role?.roleDescription || '',
		})) || [];

	useEffect(() => {
		if (loading && !experienceStarted) {
			setExperienceStarted(true);
		}
	}, [loading, experienceStarted]);

	return (
		<Box xcss={sectionContainer}>
			<Box xcss={roleSelectionHeaderStyle}>
				{formatMessage(i18n.roleSelectionHeader, {
					asterisk: (chunks: React.ReactNode[]) => <Box xcss={asteriskStyle}>{chunks}</Box>,
				})}
			</Box>
			<Text>{formatMessage(i18n.roleSelectionSubHeader)}</Text>
			<Box xcss={roleSelectionContainer}>
				<Label htmlFor="role-selector">{formatMessage(i18n.roleSelectLabel)}</Label>
				<Box xcss={roleSelectInfoButtonStyle}>
					<Box xcss={roleSelectStyle}>
						<RoleSelector
							value={rolePickerValue?.id || null}
							onChange={setRolePickerValue}
							placeholder={placeholder}
							isLoading={loading}
							spaceRoleOptions={roleOptions}
							appearance="default"
						/>
					</Box>
					<RoleSelectInfoButton currentRole={rolePickerValue} roleLookup={roleOptions} />
				</Box>
			</Box>
			<Text>
				{formatMessage(i18n.roleSelectionWarning, {
					b: (chunks: React.ReactNode[]) => <b>{chunks}</b>,
				})}
			</Text>
		</Box>
	);
};
