import React from 'react';
import { type RenderFn } from '@atlassian/forge-ui-types';
import { Text } from '@atlaskit/primitives';

import warnOnce from '../../../utils/warn-once';
import { RendererContext } from '../../../context';

// eslint-disable-next-line @repo/internal/deprecations/deprecation-ticket-required
/**
 * @deprecated The "Strong" component is deprecated and will be removed on the 28th of August 2025. Please use the "Text" component instead. For more information, visit: https://developer.atlassian.com/platform/forge/ui-kit/components/text/.
 */
export const Strong = (props: Parameters<RenderFn>[0]) => {
	const { extensionType, forgeEnvironment } = React.useContext(RendererContext);

	const isMacro = extensionType === 'xen:macro';
	const isProduction = forgeEnvironment === 'PRODUCTION';

	if (!isProduction) {
		warnOnce(
			`[@forge/react: Strong] Warning: The "Strong" component is deprecated and will be removed on the 28th of August 2025. Please use the "Text" component instead. For more information, visit: https://developer.atlassian.com/platform/forge/ui-kit/components/text/.`,
		);
	}

	return (
		// `p` tag in editor/page is set to 16px. The strong element is using the Text component which sets it to 14px. Resetting to large to match with the rest of the editor/page styles. In other modules, this would default to 14px to match with the rest of the typography styles.
		<Text size={isMacro ? 'large' : undefined} as="strong">
			{props.forgeDoc.children.map((c) => (
				<React.Fragment key={c.key}>{props.render(c)}</React.Fragment>
			))}
		</Text>
	);
};
