import { confluenceLocalStorageInstance as localStorage, keys } from '@confluence/storage-manager';

const OPT_OUT_CACHE_EXPIRATION_SECONDS = 365 * 24 * 60 * 60; // 1 year

export const updateNav4AdminOptInCache = (nav4AdminOptIn: boolean) => {
	if (nav4AdminOptIn) {
		localStorage.setItem(keys.NAV4_ADMIN_OPT_IN_CACHE, true, OPT_OUT_CACHE_EXPIRATION_SECONDS);
	} else {
		localStorage.removeItem(keys.NAV4_ADMIN_OPT_IN_CACHE);
	}
};

export const getNav4AdminOptInCache = () => {
	return localStorage?.getItem(keys.NAV4_ADMIN_OPT_IN_CACHE) === 'true';
};
