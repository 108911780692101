import { query, writeQuery } from '@confluence/query-preloader-tools';

import { getWatchDialogQuery } from './getWatchDialogQuery';
import { WatchDialogWithSpaceKeyQuery } from './WatchDialogWithSpaceKeyQuery.graphql';
import type {
	WatchDialogWithSpaceKeyQuery as WatchDialogWithSpaceKeyQueryType,
	WatchDialogWithSpaceKeyQueryVariables,
} from './__types__/WatchDialogWithSpaceKeyQuery';
import type {
	WatchDialogQuery as WatchDialogQueryType,
	WatchDialogQueryVariables,
} from './__types__/WatchDialogQuery';

export type preloadWatchDialogProps = {
	spaceKey?: string;
	isLicensed: boolean;
	userId: string | null;
	contentId?: string;
};

export function preloadWatchDialog({
	spaceKey,
	isLicensed = false,
	userId,
	contentId,
}: preloadWatchDialogProps) {
	// do nothing if user is unlicensed, no userId is provided, or spaceKey and contentId are BOTH undefined
	if (!isLicensed || !userId || (!spaceKey && !contentId)) {
		return Promise.resolve({});
	}
	// the only scenario in which spaceKey is passed but contentId is not is in Space Overview via preloadGroupedButtons
	if (spaceKey && !contentId) {
		return query<WatchDialogWithSpaceKeyQueryType, WatchDialogWithSpaceKeyQueryVariables>({
			query: WatchDialogWithSpaceKeyQuery,
			variables: { spaceKey },
			fetchPolicy: 'network-only',
		}).then(({ data }): Promise<any> | void => {
			if (data?.spaceHomepage?.id) {
				writeQuery<WatchDialogQueryType, WatchDialogQueryVariables>({
					query: getWatchDialogQuery(),
					variables: {
						contentId: data?.spaceHomepage?.id,
					},
					data: {
						singleContent:
							data?.spaceHomepage && data?.space
								? {
										currentUserIsWatching: data?.spaceHomepage?.currentUserIsWatching,
										id: data?.spaceHomepage?.id,
										space: data?.space,
										type: data?.spaceHomepage?.type,
										// @ts-ignore
										__typename: 'Content',
									}
								: null,
					},
				});
			}
		});
	}

	return query({
		query: getWatchDialogQuery(),
		variables: { contentId },
		// Must be preloaded with network only to get fresh data
		fetchPolicy: 'network-only',
	});
}
