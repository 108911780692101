import React from 'react';
import { FormattedMessage } from 'react-intl-next';

import SectionMessage from '@atlaskit/section-message';
import { Box } from '@atlaskit/primitives';

export const RemovalWarning = () => (
	<Box paddingBlockEnd="space.300">
		<SectionMessage appearance="warning">
			<FormattedMessage
				{...{
					id: 'bulk-permissions.space.removal-warning.non-final',
					defaultMessage:
						'<b>Permission to view is not selected. This will remove the user or group from all spaces.</b>',
					description:
						'Warning text telling the user that they are removing permissions for a user or group.',
					values: {
						b: (chunks: React.ReactNode[]) => <b>{chunks}</b>,
					},
				}}
			/>
		</SectionMessage>
	</Box>
);
