import type { ApolloError } from 'apollo-client';
import type { GraphQLError } from 'graphql';

type ExperimentalGraphqlErrorResponse = {
	status?: number | string | null;
};
// the experimental error doesnt fit the standard ApolloError type.
type ExperimentalGraphqlError = {
	response?: ExperimentalGraphqlErrorResponse | null;
	traceId?: string | null;
	message?: string | null;
};

const _isBadThirdPartyBlueprintError = (error: GraphQLError): boolean => {
	const { originalError } = error;
	return (
		String((originalError as ExperimentalGraphqlError)?.message) ===
			'third party blueprint has an error' &&
		String((originalError as ExperimentalGraphqlError)?.response?.status) === '500'
	);
};

const _isBadEditedFirstPartyBlueprintError = (error: GraphQLError): boolean => {
	const { originalError } = error;
	return (
		String((originalError as ExperimentalGraphqlError)?.message) ===
			'Edited 1st party blueprint has an error' &&
		String((originalError as ExperimentalGraphqlError)?.response?.status) === '500'
	);
};

const _isInvalidSpaceKeyError = (error: GraphQLError): boolean => {
	const { originalError } = error;
	return (
		String((originalError as ExperimentalGraphqlError)?.message) ===
			'Content created or updated with invalid arguments: Invalid space key specified.' &&
		String((originalError as ExperimentalGraphqlError)?.response?.status) === '500'
	);
};

const _isExceededRateError = (error: GraphQLError): boolean => {
	const { originalError } = error;
	return String((originalError as ExperimentalGraphqlError)?.message).includes(
		'rate limit exceeded',
	);
};

export const isBadThirdPartyBlueprintError = (error: ApolloError | null | undefined): boolean => {
	return !!error?.graphQLErrors?.some(_isBadThirdPartyBlueprintError);
};

export const isBadEditedFirstPartyBlueprintError = (
	error: ApolloError | null | undefined,
): boolean => {
	return !!error?.graphQLErrors?.some(_isBadEditedFirstPartyBlueprintError);
};

export const isInvalidSpaceKeyError = (error: ApolloError | null | undefined): boolean => {
	return !!error?.graphQLErrors?.some(_isInvalidSpaceKeyError);
};

export const isRateExceededError = (error: ApolloError | null | undefined): boolean => {
	return !!error?.graphQLErrors?.some(_isExceededRateError);
};

export const getTraceIdFromApolloError = (
	error: ApolloError | Error | null | undefined,
): string => {
	if (error && 'graphQLErrors' in error) {
		const originalError = error.graphQLErrors?.[0]?.originalError;
		return (originalError as ExperimentalGraphqlError)?.traceId || '';
	}
	return '';
};
