import { envType } from '@atlassian/ipm-choreographer';

import { type SessionDataType, useSessionData } from '@confluence/session-data';

export const determineAnalyticsEnvironment = (
	environment?: SessionDataType['environment'] | string,
) => {
	let analyticsEnvironment: envType;

	switch (environment) {
		case 'PRODUCTION':
		case 'prod':
			analyticsEnvironment = envType.PROD;
			break;
		case 'STAGING':
		case 'staging':
			analyticsEnvironment = envType.STAGING;
			break;
		default:
			analyticsEnvironment = envType.DEV;
	}

	return analyticsEnvironment;
};

/**
 * Transforms an environment string into a more strongly typed envType from AnalyticsWebClient
 * @param rawEnvironment an environment from SessionDataType or SSR (cc-frontend-ssr/src/common/constants.ts:MicrosEnvironmentType)
 */
export const useAnalyticsEnvironment = (rawEnvironment?: string) => {
	const { environment } = useSessionData();

	return determineAnalyticsEnvironment(rawEnvironment ?? environment);
};
