import React from 'react';

import ModalDialog, { ModalTransition } from '@atlaskit/modal-dialog';

import type { SpaceSelection } from '../page-components/SpaceSelectionRadioButtons';
import { LoadingDialog } from '../space-permissions-dialog/LoadingDialog';

import { ChangeConfirmationDialog } from './ChangeConfirmationDialog';

export const BulkAccessModal = ({
	principalName,
	isLoading,
	isRemoval,
	spaceSelection,
	onCancel,
	onConfirm,
}: {
	principalName: string;
	isLoading: boolean;
	isRemoval: boolean;
	spaceSelection: SpaceSelection;
	onCancel: () => void;
	onConfirm: () => void;
}) => (
	<ModalTransition>
		<ModalDialog
			testId="bulk-access-confirmation"
			onClose={
				// Don't allow the dialog to close while mutation is loading
				isLoading ? undefined : onCancel
			}
		>
			{isLoading ? (
				<LoadingDialog />
			) : (
				<ChangeConfirmationDialog
					principalName={principalName}
					isRemoval={isRemoval}
					spaceSelection={spaceSelection}
					onCancel={onCancel}
					onConfirm={onConfirm}
				/>
			)}
		</ModalDialog>
	</ModalTransition>
);
