import React from 'react';
import type { RenderFn } from '@atlassian/forge-ui-types';
import { withMacroStyleWrapper } from '../macroStyleWrapper';

const PlatformBarChart = React.lazy(() =>
	// eslint-disable-next-line import/dynamic-import-chunkname
	import(
		/* webpackChunkName: '@forge-ui_barchart' */
		'@atlassian/viz-platform-charts/charts'
	).then((module) => ({ default: module.BarChart })),
);

const Title = React.lazy(() =>
	// eslint-disable-next-line import/dynamic-import-chunkname
	import(
		/* webpackChunkName: '@forge-ui_barchart' */
		'@atlassian/viz-platform-charts/charts'
	).then((module) => ({ default: module.BarChart.elements.Title })),
);

const SubTitle = React.lazy(() =>
	// eslint-disable-next-line import/dynamic-import-chunkname
	import(
		/* webpackChunkName: '@forge-ui_barchart' */
		'@atlassian/viz-platform-charts/charts'
	).then((module) => ({ default: module.BarChart.elements.SubTitle })),
);

type PlatformBarChartProps = React.ComponentProps<typeof PlatformBarChart>;
/**
 * Excluded props:
 * - children - we will not be supporting primitives/elements to compose the chart
 *
 * Limited props:
 * - xAccessor - only allow non function types
 * - yAccessor - only allow non function types
 * - colorAccessor - only allow non function types
 * - colors - only allow chart color tokens (color.chart.*)
 */
export type BarChartProps = Pick<PlatformBarChartProps, 'width' | 'height' | 'data'> & {
	xAccessor: number | string;
	yAccessor: number | string;
	colorAccessor?: number | string;
	title?: string;
	subtitle?: string;
};
const BaseBarChart = (props: Parameters<RenderFn>[0]) => {
	const { width, height, data, xAccessor, yAccessor, colorAccessor, title, subtitle } = props
		.forgeDoc.props as BarChartProps;

	return (
		<PlatformBarChart
			width={width}
			height={height ? height : 400}
			data={data}
			xAccessor={xAccessor}
			yAccessor={yAccessor}
			colorAccessor={colorAccessor}
		>
			{title && <Title>{title}</Title>}
			{subtitle && <SubTitle>{subtitle}</SubTitle>}
		</PlatformBarChart>
	);
};

export const BarChart = withMacroStyleWrapper(BaseBarChart);
