import type { CustomPluginArgs, CustomValues } from '@atlassian/browser-metrics/types';

import { getAssetCacheStatus } from '@confluence/cached-assets';

import { collectDurationFromPerfMarkers } from '../collectFromPerfMarkers';
import {
	browserMetricsState,
	getHTTPProtocol,
	transformObjectToFlattenCustomValuesType,
	getSmartLinksCount,
	convertHydrationErrors,
} from '../utils';

import { getCacheAttributes } from './getCacheAttributes';

declare global {
	interface Window {
		__HYDRATION_FAILED__?: boolean;
	}
}

export function filterObjectValue<T extends Record<string, any>>(
	obj: T,
	filter: (v: T['value']) => boolean,
	map: (v: T['value']) => T['value'],
): T {
	if (!obj) return {} as T;
	return Object.keys(obj)
		.filter((key) => filter(obj[key]))
		.reduce(
			(res, key) => {
				res[key] = map(obj[key]);
				return res;
			},
			{} as Record<string, any>,
		) as T;
}

// pageLoadEventPlugin provides customized Confluence specific attributes that will be added to all PAGE_LOAD events.
export const pageLoadEventPlugin = ({ stop }: CustomPluginArgs): CustomValues => {
	const customAttributes: CustomValues = {
		transitionId: browserMetricsState.transitionId,
		// HTTP protocol used to fetch resources
		// Use multiple domains for http/1.1 users
		// Set in packages/confluence-frontend-server/templates/generator.js
		domainSharding: Boolean(window.__GET_CC_ASSET_URL),
		// HTTP protocol used to fetch resources
		httpProtocol: getHTTPProtocol(performance.getEntriesByType('resource')),
		// Add the critical path JS assets size stats
		criticalAssets: getCacheAttributes(stop),
	};
	Object.entries(collectDurationFromPerfMarkers('CFP-63.')).forEach(([fieldName, value]) => {
		customAttributes[`cfp63Durations:${fieldName}`] = value as number;
	});

	if (browserMetricsState.transitionId === 0) {
		const navigationTimings = window.performance.getEntriesByType('navigation');

		if (navigationTimings.length) {
			// TypeScript isn't returning the proper type for this, so some
			// casting here
			const firstTiming = navigationTimings[0] as PerformanceNavigationTiming;

			if (firstTiming.transferSize != null) {
				customAttributes.pageLoadedFromCache = firstTiming.transferSize === 0;
			}
		}

		// Record the current version of jQuery
		customAttributes.jqVer = (window as any).jQuery?.fn?.jquery;

		// Record if page is rendered by React SSR service
		customAttributes.ssr = Boolean(window.__SSR_RENDERED__);
		customAttributes.ssrTraceId = window.__SSR_TRACE_ID__ ?? '';

		// only send ssr macro metrics if there is at least one macro on the SSRd page
		if (window?.__SSR_MACROS_INFO__) {
			customAttributes.ssrMacrosInfo = window.__SSR_MACROS_INFO__;
		}

		// Record user clicks and intent to click on SSR placeholder elements
		customAttributes.ssrInteractions = window?.__SSR_INTERACTIONS__ || {};
		// @ts-ignore `__SSR_INTERACTIONS_DURATIONS__` maintains an array of numbers, but browser-metrics doesn't really accept arrays. Might make sense to comma-join the values
		customAttributes.ssrInteractionsDurations = window?.__SSR_INTERACTIONS_DURATIONS__ || {};
		customAttributes.ssrQueryTimeouts = window?.__SSR_QUERY_TIMEOUTS__ || {};
		customAttributes.ssrPartialSuccess = window?.__SSR_PARTIAL_SUCCESS__ || {};

		// Record loadable ids that timed out
		customAttributes.loadableTimeouts = Array.from(
			new Set(window?.__LOADABLE_TIMEOUTS__ || []),
		).join(',');

		// Records the number of SmartLinks on the page (even ones that are not successfully rendered)
		customAttributes.ssrNumberOfSmartLinks = window?.__SSR_NUMBER_OF_SMARTLINKS__ || 0;
		customAttributes.ssrNumberOfConfluenceSmartLinks =
			window?.__SSR_NUMBER_OF_CONFLUENCE_SMARTLINKS__ || 0;
		customAttributes.ssrNumberOfJiraSmartLinks = window?.__SSR_NUMBER_OF_JIRA_SMARTLINKS__ || 0;

		// Records the number of successfully ssrd smartlinks
		if (window?.__SSR_SMARTLINKS__) {
			const resolvedSmartLinks = getSmartLinksCount(window.__SSR_SMARTLINKS__);
			customAttributes.ssrResolvedSmartLinksCount = resolvedSmartLinks.total;
			customAttributes.ssrResolvedConfluenceSmartLinksCount = resolvedSmartLinks.confluence;
			customAttributes.ssrResolvedJiraSmartLinksCount = resolvedSmartLinks.jira;
		}

		if (window?.__HYDRATION_ERRORS__) {
			customAttributes.hydrationErrors = convertHydrationErrors(window.__HYDRATION_ERRORS__);
		}

		customAttributes.hydrationFailed = window?.__HYDRATION_FAILED__ || false;
		customAttributes.hydrationDisabled = window?.__SSR_DISABLE_HYDRATION__ || false;
		// Record the number of SSRed elements
		customAttributes.totalSSRedElements = window?.__TOTAL_SSR_ELEMENTS__ || 0;

		// Calculates which query is the long pole, as well as its duration
		customAttributes.longPoleQuery = window?.__SSR_LONG_POLE_QUERY__ || {};

		// Send stats on expensive queries and unnecessary watches
		customAttributes.apolloBroadcastStats = filterObjectValue(
			window?.__APOLLO_STATS__?.broadcast,
			(value) => value.duration >= 10,
			(value) => ({
				count: value.count,
				duration: Math.round(value.duration),
			}),
		);

		// Node counts from AK renderer, available in fabric only
		customAttributes.rendererNodesCount = window?.__RENDERER_NODES_COUNT__ || {};

		// Add the caching status of each asset
		const cacheStatus = transformObjectToFlattenCustomValuesType({
			cacheStatus: getAssetCacheStatus(stop),
		});
		for (const [key, value] of Object.entries(cacheStatus)) {
			customAttributes[key] = value;
		}
	}

	return customAttributes;
};
