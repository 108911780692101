import gql from 'graphql-tag';

export const CreateCompanyHubSpaceMutation = gql`
	mutation CreateCompanyHubSpaceMutation($input: SystemSpaceHomepageInput!) {
		createSystemSpace(input: $input) {
			homepageId
			id
			name
			type
		}
	}
`;
