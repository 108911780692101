import PropTypes from 'prop-types';
import React, { Component } from 'react';

const screenLayerStyles = {
	display: 'block',
	position: 'absolute',
	width: '100%',
	top: 0,
	left: 0,
	height: 250,
};

export class ContentScreenBackgroundLayer extends Component {
	render() {
		const { lookAndFeel } = this.props;

		if (!lookAndFeel || !lookAndFeel.content) {
			return null;
		}

		const customScreenLayerStyles = lookAndFeel?.content?.screen?.layer || {};

		return (
			<div
				data-testid="content-screen-background-layer"
				// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
				style={{ ...screenLayerStyles, ...customScreenLayerStyles }}
			/>
		);
	}
}

ContentScreenBackgroundLayer.propTypes = {
	lookAndFeel: PropTypes.object,
};
