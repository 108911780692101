import React from 'react';
import type { RouteMatch } from '@confluence/route';
import { PRODUCT_HOME_ID } from '@confluence/app-navigation/entry-points/navigationIDs';

import { LoadablePaint } from '@confluence/loadable';

const QuestionsRouteComponent = LoadablePaint({
	loader: () =>
		import(/* webpackChunkName: "loadable-QuestionsRouteComponent" */ './QuestionsRouteComponent'),
});

export const GlobalQuestionRoute = ({ params: { questionId } }: RouteMatch) => {
	return <QuestionsRouteComponent questionId={questionId} isGlobalPage />;
};

GlobalQuestionRoute.NAVIGATION_PARAMS = ({ params: { questionId } }: RouteMatch) => ({
	Screen: {
		screenEvent: {
			name: 'globalQuestionScreen',
			questionId,
		},
	},

	MainLayout: {
		navView: PRODUCT_HOME_ID,
	},
});
