import gql from 'graphql-tag';

export const SiteConfigurationQuery = gql`
	query SiteConfigurationQuery {
		siteSettings {
			companyHubName
			frontCover {
				frontCoverState
			}
		}
	}
`;
