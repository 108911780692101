import { LoadableLazy } from '@confluence/loadable';

export const LivePagesOpenBetaPromptModalLoader = LoadableLazy({
	loader: async () =>
		(
			await import(
				/* webpackChunkName: "loadable-LivePagesOpenBetaPromptModal" */ './LivePagesOpenBetaPromptModal'
			)
		).LivePagesOpenBetaPromptModal,
});
