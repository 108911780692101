import React from 'react';

import { fg } from '@confluence/feature-gating';
import { CompanyHubMainLayout } from '@confluence/main-layout/entry-points/CompanyHubMainLayout';
import type { RouteMatch } from '@confluence/route';

import type { RouteWithNavParamsType } from '../RouteType';

/**
 * Company Hub is starting its decoupling from Confluence at Team '25 US:
 * - It replaces the top and side product nav to appear as its own app (though technically it's still part of the Confluence app).
 * - App Switcher and Atlassian Home refer to `/wiki/company-hub` by the name "Company Hub".
 * - Atlassian Studio refers to `/wiki/company-hub/edit` by the name "Company Hub".
 *
 * @see {@link https://hello.atlassian.net/wiki/spaces/~lmarinov/pages/4898626154/WM4A+Eng+Review+Company+Hub+-+February+2025}
 * @see {@link https://www.figma.com/design/MBdUXhdmgbsIkMkOhQGu5e/Hub-App?node-id=101-52260}
 * @see {@link https://hello.atlassian.net/wiki/spaces/CLI/pages/4856024337/Confluence-less+Team+25+US+Experience}
 */

type RouteMatchPredicate = (route: RouteMatch) => boolean;

export function getCompanyHubPseudoAppRoute(
	Wrapped: RouteWithNavParamsType,
	routeMatchPredicate: RouteMatchPredicate = () => true,
): RouteWithNavParamsType {
	const CompanyHubPseudoAppRoute: RouteWithNavParamsType = (props) => {
		const wrapped = <Wrapped {...props} />;
		if (routeMatchPredicate(props) && fg('company-hub-pseudo-app')) {
			// The Company Hub app-like experience uses its own, doesn't use Confluence's `MainLayout`:
			return <CompanyHubMainLayout>{wrapped}</CompanyHubMainLayout>;
		}

		return wrapped;
	};

	CompanyHubPseudoAppRoute.NAVIGATION_PARAMS = (...args) => {
		const wrappedNavParams = Wrapped.NAVIGATION_PARAMS(...args);
		if (routeMatchPredicate(args[0]) && fg('company-hub-pseudo-app')) {
			// The Company Hub app-like experience uses its own, doesn't use Confluence's `MainLayout`:
			return typeof wrappedNavParams === 'object'
				? {
						...wrappedNavParams,
						MainLayout: false,
					}
				: false;
		}

		return wrappedNavParams;
	};

	return CompanyHubPseudoAppRoute;
}
