import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { useCallback, useEffect } from 'react';

import { fg } from '@confluence/feature-gating';

import { updateNav4AdminOptInCache, getNav4AdminOptInCache } from './nav4AdminOptInCache';

export const useGetNav4OptIn = () => {
	const { loading, data } = useQuery(
		gql`
			query useGetNav4OptInQuery {
				siteSettings {
					isNav4OptedIn
				}
			}
		`,
		{
			skip: !fg('confluence_nav4_ga_phase_1'),
		},
	);

	const updateNav4AdminOptIn = useCallback(async (isNav4OptedIn: boolean) => {
		updateNav4AdminOptInCache(isNav4OptedIn);
		window.location.reload();
	}, []);

	const optInCache = getNav4AdminOptInCache();
	const optInServer = Boolean(data?.siteSettings?.isNav4OptedIn);
	const nav4AdminOptedIn = fg('confluence_nav4_ga_phase_1') && (loading ? optInCache : optInServer);

	useEffect(() => {
		if (!loading && optInServer !== optInCache) {
			updateNav4AdminOptInCache(optInServer);
		}
	}, [loading, optInCache, optInServer]);

	return {
		nav4AdminOptedIn,
		updateNav4AdminOptIn,
	};
};
