import { defineMessages } from 'react-intl-next';

export const messages = defineMessages({
	blockedMacroTitle: {
		id: 'xen-editor-provider.app-access-blocked-macro.title',
		defaultMessage: 'An administrator blocked this app',
		description: 'Title of info panel displayed over macros blocked due to app access policies',
	},
	blockedMacroBodyWithAppName: {
		id: 'xen-editor-provider.app-access-blocked-macro.bodyWithAppName',
		defaultMessage:
			'<strong>{extensionName}</strong> has been blocked by your administrator in this space. For any questions, reach out to your organization admin.',
		description:
			'Body of info panel displayed over macros blocked due to app access policies and app name is present',
	},
	blockedMacroBodyWithoutAppName: {
		id: 'xen-editor-provider.app-access-blocked-macro.bodyWithoutAppName',
		defaultMessage:
			'<strong>This app</strong> has been blocked by your administrator in this space. For any questions, reach out to your organization admin.',
		description:
			'Body of info panel displayed over macros blocked due to app access policies and app name is not present',
	},
	blockedMacroLearnMore: {
		id: 'xen-editor-provider.app-access-blocked-macro.learnMoreAction',
		defaultMessage: 'Learn more about which apps are blocked',
		description: 'Text for link that takes user to page with more info about app access rule',
	},
	exportMacroError: {
		id: 'xen-editor-provider.export-macro.error',
		defaultMessage:
			"We've encountered an issue exporting this macro. Please try exporting this page again later",
		description: 'Error message shown when macro export fails',
	},
});
