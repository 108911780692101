import { HomeDraftsQuery } from './HomeDraftsQuery.graphql';

const PAGE_SIZE = 24;

export const getDraftsQueryOptions = (_: {}) => ({
	query: HomeDraftsQuery,
	variables: {
		cqlcontext: `{"contentStatuses":["draft"]}`,
		first: PAGE_SIZE,
	},
});
