import { adaptEventHandler } from '../../../utils';
import type { OnCloseHandler } from '@atlaskit/modal-dialog';
import { type ComponentPropsConfig } from '../../../utils/codegen';

export const componentPropsConfig: ComponentPropsConfig = {
	autoFocus: true,
	children: true,
	height: true,
	width: true,
	onClose: {
		value: 'adaptEventHandler(onClose) as OnCloseHandler',
	},
	onCloseComplete: false,
	onOpenComplete: false,
	onStackChange: false,
	shouldScrollInViewport: true,
	shouldCloseOnOverlayClick: false,
	shouldCloseOnEscapePress: false,
	shouldReturnFocus: false,
	isBlanketHidden: false,
	stackIndex: false,
	label: true,
	testId: true,
};

/**
 * To simplify API surface and maintain consistent behaviour, the following props are excluded:
 * - onStackChange
 * - stackIndex
 * - isBlanketHidden
 * - shouldCloseOnOverlayClick
 * - shouldCloseOnEscapePress
 * - shouldReturnFocus
 * - onCloseComplete - behaviour can be replicated using `useEffect`
 * - onOpenComplete - behaviour can be replicated using `useEffect`
 */

/**
 * THIS SECTION WAS CREATED VIA CODEGEN DO NOT MODIFY {@see http://go/af-codegen}
 *
 * This component was generated against an existing platform component.
 * If you are seeing lint errors in this file, it means that this file's dependencies have been modified and the component will need to be regenerated.
 *
 * Please view https://hello.atlassian.net/wiki/spaces/ECO/pages/3358130330/How+to+Regenerate+UI+Kit+Components for more information on how to proceed.
 *
 * For further questions, please ping the #help-forge-ui channel.
 *
 * @codegen <<SignedSource::342d5de57bc443d6ed7d0e76a9c8b72e>>
 * @codegenId #modal
 * @codegenCommand yarn workspace @atlassian/forge-ui codegen modal
 * @codegenDependency ../../../../../../../design-system/modal-dialog/src/types.tsx <<SignedSource::56a08f53d6eb931343ded881245e172c>>
 * @codegenDependency ../../../../../../../design-system/modal-dialog/src/internal/components/modal-dialog.tsx <<SignedSource::0fc956b9715205ef4802c91d097bba5f>>
 * @codegenDependency ../../../../../../../design-system/modal-dialog/src/internal/hooks/use-modal-stack.tsx <<SignedSource::ef090bda5b7875d2cf71be71c2c98019>>
 * @codegenDependency ../../../../../../../design-system/modal-dialog/src/internal/hooks/use-prevent-programmatic-scroll.tsx <<SignedSource::e0033ebc64e78f409cb09b3a08146715>>
 * @codegenDependency ../../../../../../../design-system/modal-dialog/src/modal-wrapper.tsx <<SignedSource::c161321ea8a407eafa515a0ef5204488>>
 */
/* eslint @repo/internal/codegen/signed-source-integrity: "warn" */
import React from 'react';
import type { RenderFn } from '@atlassian/forge-ui-types';
import PlatformModalDialog from '@atlaskit/modal-dialog';

type PlatformModalDialogProps = React.ComponentProps<typeof PlatformModalDialog>;

export type ModalProps = Pick<
  PlatformModalDialogProps,
  'autoFocus' | 'children' | 'height' | 'width' | 'onClose' | 'shouldScrollInViewport' | 'label' | 'testId'
>;

/**
 * A modal dialog displays content that requires user interaction, in a layer above the page.
 */
export const Modal = (props: Parameters<RenderFn>[0]) => {
  const {
    autoFocus,
    height,
    width,
    onClose,
    shouldScrollInViewport,
    label,
    testId,
  } = props.forgeDoc.props as ModalProps;
  return (
    <PlatformModalDialog
      autoFocus={autoFocus}
      children={props.forgeDoc.children.map(c => <React.Fragment key={c.key}>{props.render(c)}</React.Fragment>)}
      height={height}
      width={width}
      onClose={adaptEventHandler(onClose) as OnCloseHandler}
      shouldScrollInViewport={shouldScrollInViewport}
      label={label}
      testId={testId}
    />
  );
};
/**
 * @codegenEnd
 */
