import { useState, useMemo } from 'react';
import { parse } from '@atlassian/cs-ari';
import type ApolloClient from 'apollo-client';
import { type CoreData, type ExtensionData } from '@atlassian/forge-ui-types';
import type { DocNode } from '@atlaskit/adf-schema';

import { useInvokeExtension } from '@atlassian/forge-ui';
import { useEffect } from 'react';

export interface useInvokeAdfExportExtensionProps {
	apolloClient: ApolloClient<object>;
	accountId: string;
	contextIds: string[];
	extensionId: string;
	coreData: CoreData;
	extensionData: ExtensionData;
	extensionPayload?: Record<string, any>;
}

export interface useInvokeAdfExportExtensionResponse {
	adfDoc: DocNode | undefined;
	isLoading: boolean;
	error: string | undefined;
}

function parseExtensionId(extensionId: string) {
	const ari = parse(extensionId);
	const [appId, environmentId, groupId, moduleKey] = ari.resourceId!.split('/');
	return { appId, environmentId, groupId, moduleKey };
}

export const useInvokeAdfExportExtension = ({
	accountId,
	apolloClient,
	contextIds,
	extensionId,
	coreData,
	extensionData,
	extensionPayload,
}: useInvokeAdfExportExtensionProps): useInvokeAdfExportExtensionResponse => {
	const [adfDoc, setAdfDoc] = useState<DocNode | undefined>(undefined);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [error, setError] = useState<string | undefined>(undefined);

	const memoedContextIds = useMemo(() => contextIds.sort().join(), [contextIds]);

	const { moduleKey } = parseExtensionId(extensionId);
	const siteUrl = window.location.origin;
	const memoedCoreData = useMemo(
		() => ({
			accountId,
			...coreData,
			...extensionData,
			moduleKey,
			siteUrl,
		}),
		[coreData, extensionData, moduleKey, siteUrl, accountId],
	);

	const invoker = useInvokeExtension(memoedContextIds, extensionId, {
		client: apolloClient,
	});

	useEffect(() => {
		const context = {
			...memoedCoreData,
		};
		const fetchAdfExport = async () => {
			setIsLoading(true);

			try {
				const mutationResult = await invoker(
					{
						context,
						extensionPayload,
						exportType: 'pdf',
					},
					'adfExport',
				);

				const response = mutationResult.data.invokeExtension.response || {};

				if (response?.body) {
					setAdfDoc(response.body);
				}
				setError(response?.error);
			} catch (error) {
				setError(error && (error as any).message);
			}
			setIsLoading(false);
		};
		fetchAdfExport();
	}, [apolloClient, invoker, memoedCoreData, extensionPayload]);

	return { adfDoc, isLoading, error };
};
