import React, { Fragment } from 'react';

import type { ExtensionHandler } from '@atlaskit/editor-common/extensions';
import type { ADNode } from '@atlaskit/editor-common/validator';
import { RendererActionsContext } from '@atlaskit/renderer/actions';
import type { AnnotationProviders } from '@atlaskit/editor-common/types';
import type { CreateUIAnalyticsEvent } from '@atlaskit/analytics-next';

import { ClassicEditorContextProviderLazyLoader } from '@confluence-classic/confluence-frontend-server/src/components/ClassicEditorContextProvider/ClassicEditorContextProviderLazyLoader';
import { ClassicEditorContextProviderLoader } from '@confluence-classic/confluence-frontend-server/src/components/ClassicEditorContextProvider';
import EditorLoaderLoader from '@confluence-classic/confluence-frontend-server/src/components/EditorLoader/EditorLoaderLoader';
import type {
	DataSourceProvider as ReferentialityDataSourceProvider,
	ExtensionHandlerWithReferenceFn,
} from '@atlassian/editor-referentiality';
import { RedactedPlaceholderExtensionHandler } from '@confluence/external-share';
import { fg } from '@confluence/feature-gating';
import { SessionData } from '@confluence/session-data';
import { ExperienceTimeout } from '@confluence/experience-tracker';
import {
	MacroExperienceStart,
	getMacroAttributesFromADFNode,
	RENDERER,
} from '@confluence/macro-tracker';
import { getExperienceName } from '@confluence/macro-tracker';
import {
	isProfileMacrosExperimentEnabled,
	ProfilePictureMacroHandler,
	UserProfileMacroHandler,
} from '@confluence/profile-macros';
import { DebouncedLegacyMacroRenderer, LegacyMacroRenderer } from '@confluence/fabric-extension-legacy-macro-renderer';
import {
	ExcerptIncludeExtensionHandler,
	getMigrationExtHandlerForRenderer,
} from '@confluence/fabric-extension-handlers';

import type {
	ExtensionHandlerProps,
	RendererExtensionHandlers,
} from '@confluence/fabric-extension-lib/entry-points/fabric-extension-lib-types';
import { EXTENSION_TYPE } from '@confluence/fabric-extension-lib/entry-points/extensionConstants';
import { createMacroExtensionHandler } from '@confluence/fabric-extension-lib/entry-points/extensions-common';
import { RendererExtensionContext } from '@confluence/content-renderer-extension-context';
import {
	ExcerptExtensionHandler,
	DetailsExtensionHandler,
	ExpandExtensionHandler,
	AnchorExtensionHandler,
} from '@confluence/content-renderer-extension-handlers';
import { PagePropertiesReportRenderer } from '@confluence/page-properties-report-renderer';
import { PageContentRenderer } from '@confluence/content-renderer';

import RendererLoader from '../../components/Renderer';

import { MacroAnalyticsWrapper } from '../extensions-common/MacroAnalyticsWrapper';
import {
	getTemplateExtensionHandler,
	getTemplateVariableInputExtensionHandler,
} from '../editor-extensions/template-variable-extension';

import { CreateFromTemplateHandler } from './CreateFromTemplate';
import { LiveSearchHandler } from './LiveSearchHandler';
import { TableOfContentsHandler } from './TableOfContentsHandler';
import { MacroReference } from './MacroReference';
import { sendTrackingEvent } from './analytics';

type Options = {
	adf: ADNode | string | null;
	appearance: string;
	contentId: string;
	spaceKey: string | undefined;
	showTemplateVariablesInPreview?: boolean;
	showTemplateInputInPreview?: boolean;
	shouldIncludeMacroHandlers?: boolean;
	macroRenderedOutput?: any;
	shouldIncludeMacroWithReferencesHandlers?: boolean;
	referentialityDataSourceProvider: ReferentialityDataSourceProvider;
	fromFabricEditor?: boolean;
	isPreviewMode?: boolean;
	contentType?: string;
	mediaToken?: string;
	annotationProvider?: AnnotationProviders;
	isInEditorRendererView?: boolean;
};

const context = {
	insertCss: (styles) => styles._insertCss(),
};

export const getExtensionHandlers = (
	{
		adf,
		contentId,
		appearance,
		showTemplateVariablesInPreview,
		showTemplateInputInPreview,
		spaceKey,
		shouldIncludeMacroHandlers = true,
		macroRenderedOutput,
		shouldIncludeMacroWithReferencesHandlers = false,
		referentialityDataSourceProvider,
		fromFabricEditor = false,
		isPreviewMode = false,
		contentType,
		mediaToken,
		annotationProvider,
		isInEditorRendererView
	}: Options,
	createAnalyticsEvent?: CreateUIAnalyticsEvent,
): RendererExtensionHandlers => {
	const getMacroExtensionHandler = getMacroExtensionHandlerForRenderer;
	const getTemplateHandler = showTemplateInputInPreview
		? getTemplateVariableInputExtensionHandler
		: getTemplateExtensionHandler;

	const templateHandler = showTemplateVariablesInPreview
		? { [EXTENSION_TYPE.TEMPLATE]: getTemplateHandler }
		: {};

	const macroWithReferences = shouldIncludeMacroWithReferencesHandlers
		? {
				[EXTENSION_TYPE.MACRO_WITH_REFERENCES]: getExtensionHandlerWithReferences({
					adf,
					referentialityDataSourceProvider,
				}),
			}
		: {};

	return shouldIncludeMacroHandlers
		? {
				[EXTENSION_TYPE.MACRO_CORE]: getMacroExtensionHandler(
					{
						adf,
						appearance,
						contentId,
						showTemplateVariablesInPreview,
						showTemplateInputInPreview,
						spaceKey,
						macroRenderedOutput,
						referentialityDataSourceProvider,
						fromFabricEditor,
						isPreviewMode,
						contentType,
						mediaToken,
						annotationProvider,
						isInEditorRendererView
					},
					createAnalyticsEvent,
				),
				[EXTENSION_TYPE.MIGRATION]: getMigrationExtHandlerForRenderer({
					contentId,
					contextProviders: {
						ClassicEditorContextProvider: ClassicEditorContextProviderLazyLoader,
					},
				}),
				...macroWithReferences,
				...templateHandler,
			}
		: {};
};

const providers = {
	getExtensionHandlers,
	// CONFCLOUD-72035 - Make sure we are ALWAYS including the EditorLoaderLoader when loading
	//                   legacy macros or else the attachments macro WILL NOT WORK
	EditorLoaderLoader,
};

export const addSSRMetricsInfo = (isMacroSSRD, macroDefaultProps) => {
	// if SSR Macros FF is disabled, page is not SSRd, or there are no macros on the page, do nothing
	if (!window.__SSR_RENDERED__ || !window?.__SSR_MACROS_INFO__) {
		return;
	}

	const { extensionKey } = macroDefaultProps;
	if (isMacroSSRD) {
		window.__SSR_MACROS_INFO__.successes[extensionKey] =
			(window.__SSR_MACROS_INFO__.successes[extensionKey] ?? 0) + 1;
		window.__SSR_MACROS_INFO__.successCount += 1;
	} else {
		window.__SSR_MACROS_INFO__.failures[extensionKey] =
			(window.__SSR_MACROS_INFO__.failures[extensionKey] ?? 0) + 1;
		window.__SSR_MACROS_INFO__.failureCount += 1;
	}
};

export const isFabricMacroSSRD = (macroDefaultProps) => {
	const {
		attributes: { macroId },
	} = macroDefaultProps;
	return Boolean(window?.__LEGACY_MACRO_RENDERED_OUTPUT__?.find((el) => el.key === macroId));
};

export const getExtensionHandlerWithReferences = ({
	adf,
	referentialityDataSourceProvider,
}): ExtensionHandlerWithReferenceFn<any> => {
	const extensionHandlerWithReferences = (
		extension,
		extensionHandler: ({ references }) => JSX.Element,
	) => {
		return (
			<MacroReference
				adf={adf}
				extensionParams={extension}
				referentialityDataSourceProvider={referentialityDataSourceProvider}
			>
				{extensionHandler}
			</MacroReference>
		);
	};
	return extensionHandlerWithReferences;
};

export const getMacroExtensionHandlerForRenderer = (
	{
		adf,
		contentId,
		appearance,
		showTemplateVariablesInPreview,
		showTemplateInputInPreview,
		spaceKey,
		macroRenderedOutput,
		referentialityDataSourceProvider,
		fromFabricEditor,
		isPreviewMode,
		mediaToken,
		annotationProvider,
		isInEditorRendererView
	}: Options,
	createAnalyticsEvent?: CreateUIAnalyticsEvent,
): ExtensionHandler<any> =>
	createMacroExtensionHandler((extension, doc) => {
		const { extensionKey } = extension;

		const attributes = getMacroAttributesFromADFNode(extension);

		const macroParams = extension?.parameters?.macroParams;
		const multiBodiedExtensionActions = doc?.[0]?.actions;

		const isBodiedMacro = extension?.type === 'bodiedExtension';
		const shouldUseDebouncedLegacyMacroRenderer =
			isBodiedMacro && isInEditorRendererView && fg('confluence_bodied_macros_rendered_view_live_pages');

		// When isInEditorRendererView is true, we are rendering the macro in the editor renderer view.
		// In this scenario adf isn't passed to the macro extension handler as prop because we do not have access to
		// the adf from where we are using the macro extension handler. We can instead get the adf from the doc object provided to the handler.
		adf = isInEditorRendererView && !adf && !!doc ? doc?.[0] : adf;

		const attributesForAnalytics = {
			extensionType: extension?.type as string,
			...(extensionKey === 'excerpt-include'
				? { isExcerptNamePresent: !!macroParams?.name }
				: undefined),
			...(extensionKey === 'gadget' &&
				macroParams?.url?.value && {
					gadgetUrl: new URL(macroParams.url.value, location.origin).pathname,
				}),
		};

		const redactedAttributes =
			extensionKey === 'redactedMacroPlaceholder'
				? {
						extensionKey:
							macroParams?.redactedMacroPluginKey?.value ||
							macroParams?.redactedMacroExtensionKey?.value,
					}
				: undefined;
		const isMissingMacroParameters = macroParams?.isMissingRequiredParameters?.value;

		const contentHandler = (macroDefaultProps, cloudId, userId) => {
			const excerptMacroProps = {
				...macroDefaultProps,
				appearance,
			};

			const macroOutput = extension?.parameters?.macroOutput;

			if (extensionKey === 'toc' && macroParams && createAnalyticsEvent) {
				sendTrackingEvent(extensionKey, macroParams, createAnalyticsEvent, contentId);
			}

			const legacyMacroRenderer = () => {
				const isMacroSSRD =
					window.__SSR_RENDERED__ &&
					isFabricMacroSSRD(macroDefaultProps) &&
					!isMissingMacroParameters;
				addSSRMetricsInfo(isMacroSSRD, macroDefaultProps);
				return (
					<RendererExtensionContext.Provider value={providers}>
				{shouldUseDebouncedLegacyMacroRenderer ? (
					<DebouncedLegacyMacroRenderer
						{...macroDefaultProps}
						isTemplateEditorRenderer={showTemplateVariablesInPreview}
						macroRenderedOutputFromSSR={isMacroSSRD && macroRenderedOutput}
						macroOutput={macroOutput}
						isPreviewMode={isPreviewMode}
						isInEditorRendererView={isInEditorRendererView}

					/>
				) : (
					<LegacyMacroRenderer
						{...macroDefaultProps}
						isTemplateEditorRenderer={showTemplateVariablesInPreview}
						macroRenderedOutputFromSSR={isMacroSSRD && macroRenderedOutput}
						macroOutput={macroOutput}
						isPreviewMode={isPreviewMode}
				/>
				)}
					</RendererExtensionContext.Provider>
				);
			};

			switch (extensionKey) {
				case 'redactedMacroPlaceholder':
					return (
						<RedactedPlaceholderExtensionHandler
							{...macroDefaultProps}
							redactedAttributes={redactedAttributes}
						/>
					);
				case 'details':
					return (
						<DetailsExtensionHandler
							{...macroDefaultProps}
							spaceKey={spaceKey}
							Renderer={RendererLoader}
							isLegacyRenderer
							annotationProvider={annotationProvider}
						/>
					);
				case 'excerpt':
					return (
						<ExcerptExtensionHandler
							{...excerptMacroProps}
							spaceKey={spaceKey}
							Renderer={RendererLoader}
							isLegacyRenderer
							mediaToken={mediaToken}
							annotationProvider={annotationProvider}
						/>
					);
				case 'expand':
					return (
						<ExpandExtensionHandler
							{...macroDefaultProps}
							Renderer={RendererLoader}
							annotationProvider={annotationProvider}
							isLegacyRenderer
						/>
					);
				case 'create-from-template':
					return (
						<CreateFromTemplateHandler
							hasADF={!!adf}
							macroDefaultProps={macroDefaultProps}
							cloudId={cloudId}
							userId={userId}
							isPreviewMode={isPreviewMode}
							shouldLoadAfterPaint={fromFabricEditor}
						/>
					);
				case 'toc':
					return (
						<TableOfContentsHandler
							adf={adf}
							macroDefaultProps={macroDefaultProps}
							cloudId={cloudId}
							userId={userId}
							shouldLoadAfterPaint={fromFabricEditor}
						/>
					);
				case 'livesearch':
					return (
						<LiveSearchHandler
							hasADF={!!adf}
							macroDefaultProps={macroDefaultProps}
							shouldLoadAfterPaint={fromFabricEditor}
						/>
					);
				case 'anchor':
					return <AnchorExtensionHandler {...macroDefaultProps} isSSR={false} />;
				case 'excerpt-include':
					if (contentId) {
						return (
							<ClassicEditorContextProviderLoader>
								<ExcerptIncludeExtensionHandler
									{...macroDefaultProps}
									Renderer={PageContentRenderer}
								/>
							</ClassicEditorContextProviderLoader>
						);
					}
				case 'detailssummary':
					return fg('confluence_ppr_adf_rendering') ? (
						<ClassicEditorContextProviderLoader isWithinEditor={false}>
							<PagePropertiesReportRenderer
								macroDefaultProps={macroDefaultProps}
								macroParams={macroParams}
							/>
						</ClassicEditorContextProviderLoader>
					) : (
						legacyMacroRenderer()
					);

				case 'profile-picture':
					return isProfileMacrosExperimentEnabled() ? (
						<ProfilePictureMacroHandler
							macroDefaultProps={macroDefaultProps}
							macroParams={extension?.parameters?.macroParams}
						/>
					) : (
						legacyMacroRenderer()
					);
				case 'profile':
					return isProfileMacrosExperimentEnabled() ? (
						<UserProfileMacroHandler
							macroDefaultProps={macroDefaultProps}
							macroParams={extension?.parameters?.macroParams}
						/>
					) : (
						legacyMacroRenderer()
					);

				// generic macro renderer
				default:
					return legacyMacroRenderer();
			}
		};

		return (
			<RendererActionsContext>
				<MacroReference
					adf={adf}
					extensionParams={extension}
					referentialityDataSourceProvider={referentialityDataSourceProvider}
				>
					{({ references }) => {
						const macroDefaultProps: ExtensionHandlerProps = {
							node: extension,
							references,
							contentId,
							showTemplateVariablesInPreview: showTemplateVariablesInPreview || false,
							showTemplateInputInPreview: showTemplateInputInPreview || false,
							spaceKey: spaceKey || '',
							mode: RENDERER,
							context,
							extensionKey,
							attributes,
							multiBodiedExtensionActions,
						};
						return (
							<Fragment>
								<MacroExperienceStart
									contentId={contentId}
									name={getExperienceName(RENDERER, extension)}
									mode={RENDERER}
									extensionKey={extensionKey}
									attributes={attributes}
									timeout={ExperienceTimeout.MACRO_LOAD}
								/>
								<MacroAnalyticsWrapper
									extensionKey={extensionKey}
									attributes={{
										...attributesForAnalytics,
										...redactedAttributes,
									}}
									node={extension}
								>
									<SessionData>
										{({ cloudId, userId }) => contentHandler(macroDefaultProps, cloudId, userId)}
									</SessionData>
								</MacroAnalyticsWrapper>
							</Fragment>
						);
					}}
				</MacroReference>
			</RendererActionsContext>
		);
	});
