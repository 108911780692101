import { query, writeQuery } from '@confluence/query-preloader-tools';
import { fg } from '@confluence/feature-gating';
import { NativeCollabQuery } from '@confluence/native-collab/entry-points/NativeCollabQuery';
import type {
	NativeCollabQueryRes,
	NativeCollabQueryVariables,
} from '@confluence/native-collab/entry-points/NativeCollabQuery';
import { NativeCollabProviderQuery } from '@confluence/fabric-providers/entry-points/NativeCollabProviderQuery';
import type {
	NativeCollabProviderQueryRes,
	NativeCollabProviderQueryVariables,
} from '@confluence/fabric-providers/entry-points/NativeCollabProviderQuery';
import { CollabDraftQuery } from '@confluence/fabric-providers/entry-points/CollabDraftQuery';
import type {
	CollabDraftQueryRes,
	CollabDraftQueryVariables,
} from '@confluence/fabric-providers/entry-points/CollabDraftQuery';
import {
	isDraftSSRPreloadPermissionError,
	markDraftSSRPreloadPermissionError,
} from '@confluence/fabric-providers/entry-points/preloadDraft';

export function preloadCollabDraftContent(
	contentId: string,
	draftShareId: string | undefined,
	useCache: boolean,
) {
	return query<CollabDraftQueryRes, CollabDraftQueryVariables>({
		query: CollabDraftQuery,
		variables: {
			id: contentId,
			draftShareId: fg('cc_ssr_edit_page_restricted') ? draftShareId : undefined,
			...(fg('cc_mention_hydrate_adf') ? { hydrateAdf: true } : undefined),
		},
		fetchPolicy: useCache ? 'cache-first' : 'network-only',
	}).then((result: any) => {
		if (fg('cc_ssr_edit_page_restricted')) {
			// There is a bug in appolo client (https://github.com/apollographql/react-apollo/issues/3918)
			// that doesn't preserve the result in caches if there is an error in SSR
			// So we need to preserve the error, to be able to verify it later during render phase in SSR
			if (result?.errors?.some((err) => isDraftSSRPreloadPermissionError(err?.message))) {
				markDraftSSRPreloadPermissionError();
			}
		}

		return result;
	});
}

export function updateCollabDraftContent(
	contentId: string,
	draftShareId: string | undefined,
	data: any,
) {
	return writeQuery<CollabDraftQueryRes, CollabDraftQueryVariables>({
		query: CollabDraftQuery,
		variables: {
			id: contentId,
			draftShareId: fg('cc_ssr_edit_page_restricted') ? draftShareId : undefined,
		},
		data,
	});
}

export function preloadNativeCollab(
	contentId: string,
	draftShareId: string | undefined,
	useCache: boolean,
	status?: [string],
) {
	return query<NativeCollabQueryRes, NativeCollabQueryVariables>({
		query: NativeCollabQuery,
		variables: {
			contentId,
			...(draftShareId && { draftShareId }),
			status: status ?? ['draft', 'current'],
		},
		fetchPolicy: useCache ? 'cache-first' : 'network-only',
		context: { single: 'true' },
	});
}

export function preloadNativeCollabProvider({
	accountId,
	useCache,
	shouldIncludePermissionType,
}: {
	accountId: string | null;
	useCache: boolean;
	shouldIncludePermissionType: boolean;
}) {
	return query<NativeCollabProviderQueryRes, NativeCollabProviderQueryVariables>({
		query: NativeCollabProviderQuery,
		variables: {
			accountId,
			shouldIncludePermissionType,
		},
		fetchPolicy: useCache ? 'cache-first' : 'network-only',
		context: { single: 'true' },
	});
}
