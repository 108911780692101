import { preloadSpacePages } from '@confluence/space-pages/entry-points/preloadSpacePages';
import { getPreloaderFnContext } from '@confluence/query-preloader-tools';
import type { RouteMatch } from '@confluence/route';
import { isContentTypeEnabledInCurrentEnv } from '@confluence/content-types-utils';
import { AccessStatus } from '@confluence/session-data';

export const preloadSpacePagesRoute = async (match: RouteMatch) => {
	const { userId, isLoggedIn, accessStatus } = await getPreloaderFnContext();
	const tasks: Promise<any>[] = [];
	const { spaceKey } = match.params;
	const isDatabasesEnabled = isContentTypeEnabledInCurrentEnv('database');
	const isWhiteboardsEnabled = isContentTypeEnabledInCurrentEnv('whiteboard');

	const isLicensed =
		accessStatus === AccessStatus.LICENSED_ADMIN_ACCESS ||
		accessStatus === AccessStatus.LICENSED_USE_ACCESS;

	tasks.push(
		preloadSpacePages({
			spaceKey,
			queryParams: match.query,
			userId,
			isLicensed,
			isLoggedIn,
			spaceId: '',
			isWhiteboardEnabled: isWhiteboardsEnabled,
			isDatabaseEnabled: isDatabasesEnabled,
		}),
	);

	return Promise.all(tasks);
};
