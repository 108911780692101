/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum ActivitiesObjectType {
  BLOGPOST = "BLOGPOST",
  DATABASE = "DATABASE",
  EMBED = "EMBED",
  ISSUE = "ISSUE",
  PAGE = "PAGE",
  WHITEBOARD = "WHITEBOARD",
}

export enum ActivityEventType {
  ASSIGNED = "ASSIGNED",
  COMMENTED = "COMMENTED",
  CREATED = "CREATED",
  EDITED = "EDITED",
  LIKED = "LIKED",
  PUBLISHED = "PUBLISHED",
  TRANSITIONED = "TRANSITIONED",
  UNASSIGNED = "UNASSIGNED",
  UPDATED = "UPDATED",
  VIEWED = "VIEWED",
}

//==============================================================
// END Enums and Input Objects
//==============================================================

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: HomeRecentWorkQuery
// ====================================================

export interface HomeRecentWorkQuery_activities_myActivities_all_edges_node_object_events {
  eventType: ActivityEventType | null;
}

export interface HomeRecentWorkQuery_activities_myActivities_all_edges_node_object_content_links {
  webui: string | null;
  editui: string | null;
}

export interface HomeRecentWorkQuery_activities_myActivities_all_edges_node_object_content_metadata {
  sourceTemplateEntityId: string | null;
}

export interface HomeRecentWorkQuery_activities_myActivities_all_edges_node_object_content_space {
  alias: string | null;
  id: string | null;
  key: string | null;
  name: string | null;
}

export interface HomeRecentWorkQuery_activities_myActivities_all_edges_node_object_content_emoji_nodes {
  id: string | null;
  key: string | null;
  value: string | null;
}

export interface HomeRecentWorkQuery_activities_myActivities_all_edges_node_object_content_emoji {
  nodes: (HomeRecentWorkQuery_activities_myActivities_all_edges_node_object_content_emoji_nodes | null)[] | null;
}

export interface HomeRecentWorkQuery_activities_myActivities_all_edges_node_object_content {
  id: string | null;
  title: string | null;
  subType: string | null;
  type: string | null;
  links: HomeRecentWorkQuery_activities_myActivities_all_edges_node_object_content_links | null;
  metadata: HomeRecentWorkQuery_activities_myActivities_all_edges_node_object_content_metadata;
  space: HomeRecentWorkQuery_activities_myActivities_all_edges_node_object_content_space | null;
  status: string | null;
  blank: boolean;
  emoji: HomeRecentWorkQuery_activities_myActivities_all_edges_node_object_content_emoji | null;
}

export interface HomeRecentWorkQuery_activities_myActivities_all_edges_node_object {
  id: string;
  events: HomeRecentWorkQuery_activities_myActivities_all_edges_node_object_events[] | null;
  content: HomeRecentWorkQuery_activities_myActivities_all_edges_node_object_content | null;
}

export interface HomeRecentWorkQuery_activities_myActivities_all_edges_node {
  id: string;
  timestamp: string | null;
  object: HomeRecentWorkQuery_activities_myActivities_all_edges_node_object | null;
}

export interface HomeRecentWorkQuery_activities_myActivities_all_edges {
  cursor: string;
  node: HomeRecentWorkQuery_activities_myActivities_all_edges_node | null;
}

export interface HomeRecentWorkQuery_activities_myActivities_all {
  edges: (HomeRecentWorkQuery_activities_myActivities_all_edges | null)[] | null;
}

export interface HomeRecentWorkQuery_activities_myActivities {
  all: HomeRecentWorkQuery_activities_myActivities_all | null;
}

export interface HomeRecentWorkQuery_activities {
  myActivities: HomeRecentWorkQuery_activities_myActivities | null;
}

export interface HomeRecentWorkQuery {
  activities: HomeRecentWorkQuery_activities | null;
}

export interface HomeRecentWorkQueryVariables {
  cloudId: string;
  first?: number | null;
  after?: string | null;
  objectTypes?: ActivitiesObjectType[] | null;
}
