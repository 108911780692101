import React, { Component, type ErrorInfo, type ReactElement } from 'react';
import { ProductEnvironment, type Dispatch } from '@atlassian/forge-ui-types';
import ErrorPanel from '../components/UIKit1/errorPanel/errorPanel';
import { MetricsContext } from '../context';
import { captureAndReportError } from '../error-reporting';
import { type CreateUIAnalyticsEvent, useAnalyticsEvents } from '@atlaskit/analytics-next';
import { OPERATIONAL_EVENT_TYPE } from '@atlaskit/analytics-gas-types';
import { FORGE_UI_ANALYTICS_CHANNEL } from '../analytics';

interface Props {
	children: ReactElement;
	dispatch?: Dispatch;
	extension?: Record<string, any>;
}

interface GenericErrorBoundaryWithCreateAnalyticsEventProps extends Props {
	createAnalyticsEvent: CreateUIAnalyticsEvent;
}

interface State {
	thrownError?: {
		error: Error & { errorMessage?: string | JSX.Element };
		errorInfo: ErrorInfo;
	};
}

type CustomErrorMessageArgs = {
	// The error message to log
	logMessage: string;
	// The error title to display to the user
	errorTitle: string;
	// The JSX element to render in place of the error
	errorBody: JSX.Element;
};
export class ErrorWithCustomContent extends Error {
	logMessage: string;
	errorTitle: string;
	errorBody: JSX.Element;

	constructor(error: CustomErrorMessageArgs) {
		super(error.logMessage);
		this.errorTitle = error.errorTitle;
		this.logMessage = error.logMessage;
		this.errorBody = error.errorBody;
	}
}

class GenericErrorBoundaryWithCreateAnalyticsEvent extends Component<
	GenericErrorBoundaryWithCreateAnalyticsEventProps,
	State
> {
	static contextType = MetricsContext;
	context!: React.ContextType<typeof MetricsContext>;

	public state: State = {
		thrownError: undefined,
	};

	public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
		const { page, environment } = this.context;
		const { createAnalyticsEvent, extension } = this.props;

		captureAndReportError({
			error,
			errorInfo,
			page,
			environment: environment || ProductEnvironment.DEVELOPMENT,
			errorExtensionDetails: extension
				? {
						type: extension.type,
						environmentType: extension.environmentType,
						appOwnerAccountId: extension.appOwner?.accountId,
						properties: extension.properties,
						id: extension?.id,
					}
				: undefined,
		});

		createAnalyticsEvent({
			eventType: OPERATIONAL_EVENT_TYPE,
			data: {
				action: 'failed',
				actionSubject: 'forge.ui.renderer',
				source: page,
				tags: ['forge'],
				attributes: {
					target: 'errorBoundary',
					errorName: error.name,
				},
			},
		}).fire(FORGE_UI_ANALYTICS_CHANNEL);

		if (error instanceof ErrorWithCustomContent) {
			this.setState({
				thrownError: {
					error: {
						name: 'error',
						message: error.errorTitle,
						errorMessage: error.errorBody,
					},
					errorInfo,
				},
			});
		} else {
			this.setState({
				thrownError: {
					error,
					errorInfo,
				},
			});
		}
	}

	public render() {
		const { thrownError } = this.state;

		if (!thrownError) {
			return this.props.children;
		}

		return <ErrorPanel error={thrownError.error} dispatch={this.props.dispatch} />;
	}
}

const GenericErrorBoundary = (props: Props) => {
	const { createAnalyticsEvent } = useAnalyticsEvents();

	return (
		<GenericErrorBoundaryWithCreateAnalyticsEvent
			{...props}
			createAnalyticsEvent={createAnalyticsEvent}
		/>
	);
};

export default GenericErrorBoundary;
