import {
	Status,
	setCookie,
	getCookie,
	deleteCookie,
	setStrictlyNecessaryCookie,
	checkThirdParty,
	initializeControls,
	type JSCookieAttributes,
} from '@atlassian/browser-storage-controls';
import { fg } from '@confluence/feature-gating';

export enum CookieLevel {
	USE_PREFERENCES = 'USE_PREFERENCES',
	NECESSARY = 'NECESSARY',
}

export class Cookies {
	static initialize() {
		// Initialize browser-storage-controls with webStorageEnabled
		// This will override browser default LocalStorage and SessionStorage
		if (fg('confluence_browser_storage_controls')) {
			return initializeControls({
				product: 'Confluence',
				webStorageEnabled: true,
			});
		}

		return initializeControls({
			product: 'Confluence',
		});
	}

	/**
	 * Attempts to set a cookie asynchronously using the user's preferences
	 * @param level Describes how necessary the cookie is. Only use NECESSARY if the cookie is absolutely required.
	 * @returns true if the cookie succeeded in being set based on the user's preferences
	 */
	static async set(
		key: string,
		value: string,
		attributes?: JSCookieAttributes,
		level: CookieLevel = CookieLevel.USE_PREFERENCES,
	) {
		// This does not need to be behind the FF because
		// if the cookie is strictly necessary, its set synchronously using js-cookie anyways
		if (level === CookieLevel.NECESSARY) {
			setStrictlyNecessaryCookie(key, value, attributes);
			return Promise.resolve(true);
		}

		return (await setCookie(key, value, attributes)) === Status.SUCCESS;
	}

	/**
	 * Retrieves a single cookie or all cookies if no key is provided
	 */
	// eslint-disable-next-line no-dupe-class-members
	static get(): { [key: string]: string };
	// eslint-disable-next-line no-dupe-class-members
	static get(key: string): string | undefined;
	// eslint-disable-next-line no-dupe-class-members
	static get(key?: string) {
		if (!key) {
			return getCookie();
		}
		return getCookie(key);
	}

	/**
	 * Deletes a cookie given a key
	 */
	static remove(key: string, attributes?: JSCookieAttributes) {
		deleteCookie(key, attributes);
	}

	/**
	 * Executes a callback if the user has allowed or denied a third party cookie
	 */
	static async checkThirdParty(
		key: string,
		onAllowed: () => Promise<void>,
		onDenied?: () => Promise<void>,
	) {
		await checkThirdParty(key, onAllowed, onDenied);
	}
}
