import { usePreset } from '@atlaskit/editor-core/use-preset';

import { createPreset } from './createPreset';
import type { Appearance, Features } from '../../types';
import { useMemoisedFeatures } from './useMemoisedFeatures';

export const usePresetByFeatures = (
	appearance: Appearance,
	features?: Features,
): ReturnType<typeof usePreset> => {
	const memoisedFeatures = useMemoisedFeatures(features);

	const preset = usePreset(
		(builder) => createPreset(builder, appearance, memoisedFeatures),
		[memoisedFeatures],
	);

	return preset;
};
