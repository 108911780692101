import type { RouteMatch } from '@confluence/route';

export const FORCE_TRANSITION_QUERY_PARAM = 'force_transition';

/**
 * Determines if a forced transition is needed between two route states.
 *
 * This function checks for a specific query parameter that signals a transition should occur,
 * even if the route path remains unchanged. This is useful for indicating to the route manager
 * that a transition has happened, especially in scenarios where multiple features share the same route.
 * It is helpful in situations where you want to specify that a transition occurred, so reliance on
 * SSR data or initial load data is avoided when the route remains the same.
 */
export const isForceTransition = (prevMatch: RouteMatch, currentMatch: RouteMatch): boolean => {
	const currentForceTransitionId = currentMatch?.query?.[FORCE_TRANSITION_QUERY_PARAM];
	const previousForceTransitionId = prevMatch?.query?.[FORCE_TRANSITION_QUERY_PARAM];

	return Boolean(
		currentForceTransitionId && currentForceTransitionId !== previousForceTransitionId,
	);
};
