import type {
	ProgressTrackerProps as PlatformProgressTrackerProps,
	Stage as PlatformStage,
} from '@atlaskit/progress-tracker';
import { type ComponentPropsConfig } from '../../../utils/codegen';

export const componentPropsConfig: ComponentPropsConfig = {
	animated: true,
	items: { value: 'filterItems(items)' },
	label: true,
	spacing: true,
	testId: true,
};

type Stage = Pick<PlatformStage, 'id' | 'label' | 'percentageComplete' | 'status' | 'onClick'>;

export type ProgressTrackerProps = Pick<PlatformProgressTrackerProps, 'label' | 'testId'> &
	Partial<Pick<PlatformProgressTrackerProps, 'animated' | 'spacing'>> & {
		items: Array<Stage>;
	};

// Filters out href from items. @forge/bridge router must be used in onClick for in app navigation
const filterItems = (items: Array<Stage>) => {
	return (items as Array<PlatformStage>).map((item) => {
		// removes href and noLink props
		const { href, noLink, ...rest } = item;
		return {
			...rest,
			noLink: !rest.onClick,
		};
	});
};

/**
 * THIS SECTION WAS CREATED VIA CODEGEN DO NOT MODIFY {@see http://go/af-codegen}
 *
 * This component was generated against an existing platform component.
 * If you are seeing lint errors in this file, it means that this file's dependencies have been modified and the component will need to be regenerated.
 *
 * Please view https://hello.atlassian.net/wiki/spaces/ECO/pages/3358130330/How+to+Regenerate+UI+Kit+Components for more information on how to proceed.
 *
 * For further questions, please ping the #help-forge-ui channel.
 *
 * @codegen <<SignedSource::517a7522054e4b6a39932ff32f49499d>>
 * @codegenId #progress-tracker
 * @codegenCommand yarn workspace @atlassian/forge-ui codegen progress-tracker
 * @codegenParams { "defineOwnType": true }
 * @codegenDependency ../../../../../../../design-system/progress-tracker/src/progress-tracker.tsx <<SignedSource::65335bc897abe85ed4fa7c10780ea209>>
 * @codegenDependency ../../../../../../../design-system/progress-tracker/src/internal/link.tsx <<SignedSource::5029106a19d604bfbab3c95a6c817a2b>>
 * @codegenDependency ../../../../../../../design-system/progress-tracker/src/internal/link-new.tsx <<SignedSource::477c706220c99a8c8f0833b001fd918a>>
 * @codegenDependency ../../../../../../../design-system/progress-tracker/src/internal/stage.tsx <<SignedSource::f247c0ae67eef0ba4962228cde1510de>>
 * @codegenDependency ../../../../../../../design-system/progress-tracker/src/types.tsx <<SignedSource::d9847b852fc04067d851ef537d23b80d>>
 */
/* eslint @repo/internal/codegen/signed-source-integrity: "warn" */
import React from 'react';
import type { RenderFn } from '@atlassian/forge-ui-types';
import { ProgressTracker as PlatformProgressTracker } from '@atlaskit/progress-tracker';

// Define the type for ProgressTracker as ProgressTrackerProps at the top of this file

/**
 * A progress tracker displays the steps and progress through a journey.
 */
export const ProgressTracker = (props: Parameters<RenderFn>[0]) => {
  const {
    animated,
    items,
    label,
    spacing,
    testId,
  } = props.forgeDoc.props as ProgressTrackerProps;
  return (
    <PlatformProgressTracker
      animated={animated}
      items={filterItems(items)}
      label={label}
      spacing={spacing}
      testId={testId}
    />
  );
};
/**
 * @codegenEnd
 */
