import gql from 'graphql-tag';

export const DatabaseUnifiedQuery = gql`
	query DatabaseUnifiedQuery($contentId: ID!, $spaceKey: String, $contentVersion: Int!) {
		content(id: $contentId) {
			nodes {
				id
				referenceId
				title
				operations {
					operation
					targetType
				}
				hasRestrictions
				hasViewRestrictions
				hasInheritedRestrictions
				space {
					id
					name
					key
					type
					alias
				}
				type
				status
				ancestors {
					id
					type
					title
					status
				}
			}
		}
		spaces(spaceKey: $spaceKey, first: 10) {
			nodes {
				id
				name
				homepage {
					id
				}
			}
		}
		user(current: true) {
			id
			displayName
			photos {
				value
				isPrimary
			}
			emails {
				value
				isPrimary
			}
			locale
			confluence {
				space {
					id
				}
			}
		}

		dataSecurityPolicy {
			isActionEnabledForContent(
				action: PAGE_EXPORT
				contentId: $contentId
				contentStatus: CURRENT
				spaceKey: $spaceKey
				contentVersion: $contentVersion
			) {
				allowed
			}
		}
	}
`;
