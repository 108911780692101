import React from 'react';
import type { RenderFn } from '@atlassian/forge-ui-types';
import { withMacroStyleWrapper } from '../macroStyleWrapper';

const PlatformDonutChart = React.lazy(() =>
	// eslint-disable-next-line import/dynamic-import-chunkname
	import(
		/* webpackChunkName: '@forge-ui_donutchart' */
		'@atlassian/viz-platform-charts/charts'
	).then((module) => ({ default: module.DonutChart })),
);

const Title = React.lazy(() =>
	// eslint-disable-next-line import/dynamic-import-chunkname
	import(
		/* webpackChunkName: '@forge-ui_donutchart' */
		'@atlassian/viz-platform-charts/charts'
	).then((module) => ({ default: module.DonutChart.elements.Title })),
);

const SubTitle = React.lazy(() =>
	// eslint-disable-next-line import/dynamic-import-chunkname
	import(
		/* webpackChunkName: '@forge-ui_donutchart' */
		'@atlassian/viz-platform-charts/charts'
	).then((module) => ({ default: module.DonutChart.elements.SubTitle })),
);

const MarkLabels = React.lazy(() =>
	// eslint-disable-next-line import/dynamic-import-chunkname
	import(
		/* webpackChunkName: '@forge-ui_donutchart' */
		'@atlassian/viz-platform-charts/charts'
	).then((module) => ({ default: module.DonutChart.elements.MarkLabels })),
);

type PlatformDonutChartProps = React.ComponentProps<typeof PlatformDonutChart>;

/**
 * Added props:
 * - showMarkLabels - show/hide the labels on top of each slice of the Donut, maps to the MarkLabels primitive
 *
 * Excluded props:
 * - children - we will not be supporting primitives/elements to compose the chart
 *
 * Limited props:
 * - labelAccessor - only allow non function types
 * - valueAccessor - only allow non function types
 * - colorAccessor - only allow non function types
 */
export type DonutChartProps = Pick<PlatformDonutChartProps, 'width' | 'height' | 'data'> & {
	colorAccessor: number | string;
	labelAccessor: number | string;
	valueAccessor: number | string;
	title?: string;
	subtitle?: string;
	showMarkLabels?: boolean;
	innerRadius?: number;
	outerRadius?: number;
};

const BaseDonutChart = (props: Parameters<RenderFn>[0]) => {
	const {
		width,
		height,
		data,
		colorAccessor,
		labelAccessor,
		valueAccessor,
		title,
		subtitle,
		showMarkLabels,
		innerRadius,
		outerRadius,
	} = props.forgeDoc.props as DonutChartProps;

	return (
		<PlatformDonutChart
			width={width}
			height={height ? height : 400}
			data={data}
			colorAccessor={colorAccessor}
			valueAccessor={valueAccessor}
			labelAccessor={labelAccessor}
			innerRadius={innerRadius}
			outerRadius={outerRadius}
		>
			<MarkLabels hide={!showMarkLabels} />
			{title && <Title>{title}</Title>}
			{subtitle && <SubTitle>{subtitle}</SubTitle>}
		</PlatformDonutChart>
	);
};

export const DonutChart = withMacroStyleWrapper(BaseDonutChart);
