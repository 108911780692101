// Note that the prop `templateAreas` is removed as it requires child components to use `style`
import { type ComponentPropsConfig } from '../../../utils/codegen';

export const componentPropsConfig: ComponentPropsConfig = {
	children: true,
	as: false,
	justifyContent: true,
	justifyItems: true,
	alignItems: true,
	alignContent: true,
	columnGap: true,
	gap: true,
	rowGap: true,
	autoFlow: true,
	templateRows: true,
	templateColumns: true,
	templateAreas: false,
	id: true,
	testId: true,
	'data-testid': false,
	role: true,
	xcss: false,
};

/**
 * THIS SECTION WAS CREATED VIA CODEGEN DO NOT MODIFY {@see http://go/af-codegen}
 *
 * This component was generated against an existing platform component.
 * If you are seeing lint errors in this file, it means that this file's dependencies have been modified and the component will need to be regenerated.
 *
 * Please view https://hello.atlassian.net/wiki/spaces/ECO/pages/3358130330/How+to+Regenerate+UI+Kit+Components for more information on how to proceed.
 *
 * For further questions, please ping the #help-forge-ui channel.
 *
 * @codegen <<SignedSource::be9c367a5f720974842c20c4efd4d2f3>>
 * @codegenId #grid
 * @codegenCommand yarn workspace @atlassian/forge-ui codegen grid
 * @codegenDependency ../../../../../../../design-system/primitives/src/components/grid.tsx <<SignedSource::3cb2428df08047dd87bb76fd621cbd3a>>
 * @codegenDependency ../../../../../../../design-system/primitives/src/components/types.tsx <<SignedSource::9529629bd4dfcf934c9dfc41516de683>>
 * @codegenDependency ../../../../../../../design-system/primitives/src/xcss/style-maps.partial.tsx <<SignedSource::764fa2a11181a03d6d87ec2b92e13239>>
 * @codegenDependency ../../../../../../../design-system/primitives/src/xcss/xcss.tsx <<SignedSource::4f9c0f527ba99a4723d89b2126ae70b1>>
 */
/* eslint @repo/internal/codegen/signed-source-integrity: "warn" */
import React from 'react';
import type { RenderFn } from '@atlassian/forge-ui-types';
import { Grid as PlatformGrid } from '@atlaskit/primitives';

type PlatformGridProps = React.ComponentProps<typeof PlatformGrid>;

export type GridProps = Pick<
  PlatformGridProps,
  'children' | 'justifyContent' | 'justifyItems' | 'alignItems' | 'alignContent' | 'columnGap' | 'gap' | 'rowGap' | 'autoFlow' | 'templateRows' | 'templateColumns' | 'id' | 'testId' | 'role'
>;

/**
 * Primitives are token-backed low-level building blocks.
 */
export const Grid = (props: Parameters<RenderFn>[0]) => {
  const {
    justifyContent,
    justifyItems,
    alignItems,
    alignContent,
    columnGap,
    gap,
    rowGap,
    autoFlow,
    templateRows,
    templateColumns,
    id,
    testId,
    role,
  } = props.forgeDoc.props as GridProps;
  return (
    <PlatformGrid
      children={props.forgeDoc.children.map(c => <React.Fragment key={c.key}>{props.render(c)}</React.Fragment>)}
      justifyContent={justifyContent}
      justifyItems={justifyItems}
      alignItems={alignItems}
      alignContent={alignContent}
      columnGap={columnGap}
      gap={gap}
      rowGap={rowGap}
      autoFlow={autoFlow}
      templateRows={templateRows}
      templateColumns={templateColumns}
      id={id}
      testId={testId}
      role={role}
    />
  );
};
/**
 * @codegenEnd
 */
