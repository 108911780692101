export class ErrorUtils {
	private static redactSensitiveInfo = (str: string): string => {
		const redacted = str
			// Ignored via go/ees005
			// eslint-disable-next-line require-unicode-regexp
			.replace(/[^,\s]+\@[^,\s]+/g, '[REDACTED EMAIL]')
			// Ignored via go/ees005
			// eslint-disable-next-line require-unicode-regexp
			.replace(/[^,\s]+\.[^,\s]+/g, '[REDACTED URL]');

		return redacted;
	};

	public static extractErrorMessage(error: unknown) {
		if (error instanceof Error) {
			return ErrorUtils.redactSensitiveInfo(error.message || 'unknown-error');
		} else {
			return ErrorUtils.redactSensitiveInfo(String(error));
		}
	}
}
