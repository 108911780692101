import { defineMessages } from 'react-intl-next';

export default defineMessages({
	aiUniversalLongCallToAction: {
		id: 'fabric.editor.ai.shared.aiUniversalLongCallToAction',
		defaultMessage: 'Let Atlassian Intelligence assist your writing',
		description:
			'Call-to-action text for activating the AI, used universally across UI elements like hover buttons, tooltips, and menu entries',
	},
	aiUniversalShortCallToAction: {
		id: 'fabric.editor.ai.shared.aiUniversalShortCallToAction',
		defaultMessage: 'Write',
		description:
			'Call-to-action text for activating the AI, used universally across UI elements like buttons and menu entries',
	},
	aiProactiveButtonLabel: {
		id: 'fabric.editor.ai.shared.aiProactiveButtonLabel',
		defaultMessage: 'Suggestions',
		description: 'Call-to-action text for activating Proactive AI for inline AI suggestions.',
	},
	aiProactiveDescriptor: {
		id: 'fabric.editor.ai.shared.aiProactiveDescriptor.non-final',
		defaultMessage:
			'{count, plural, =0 {Open suggested edits} =1 {New suggested edit} =2 {Two new suggested edits} =3 {Three new suggested edits} =4 {Four new suggested edits} other {More than four new suggested edits}}',
		description: 'Tooltip text for Proactive AI button to activate the inline writing suggestions',
	},
	aiProactiveCloseDescriptor: {
		id: 'fabric.editor.ai.shared.aiProactiveDescriptorClose.non-final',
		defaultMessage: 'Close suggested edits',
		description: 'Tooltip text for Proactive AI button to close the inline writing suggestions',
	},
	aiUniversalDescriptor: {
		id: 'fabric.editor.ai.shared.aiUniversalDescriptor',
		defaultMessage: 'Writing assistant',
		description:
			'Descriptive text for activating the AI, used universally across UI elements like hover buttons, tooltips, and menu entries',
	},
	aiButtonTitle: {
		id: 'fabric.editor.ai.shared.generateContent.aiButtonTitle',
		defaultMessage: 'AI (Atlassian Intelligence)',
		description:
			'Title for Atlassian Intelligence feature shown in editor typeahead and element browser.',
	},
	betaLabel: {
		id: 'fabric.editor.ai.shared.betaLabel',
		defaultMessage: 'Beta',
		description: 'The label describing the beta status of an Atlassian Intelligence prompt',
	},
});
