import { LoadableAfterPaint } from '@confluence/loadable';

export const TourableRedactionExtensionLoader = LoadableAfterPaint({
	loader: async () =>
		(
			await import(
				/* webpackChunkName: "loadable-TourableRedactionExtension" */ './TourableRedactionExtension'
			)
		).TourableRedactionExtension,
});
