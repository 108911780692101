import React from 'react';

import { aiConfigItemMessages } from '@atlaskit/editor-common/ai-messages';
import SpreadsheetIcon from '@atlaskit/icon/core/spreadsheet';

import { createInsertBelow, createReplace } from '../../actions/create-actions';
import { CONFIG_ITEM_KEYS } from '../../config-item-keys';
import type {
	EditorPluginAIConfigItemWithOptions,
	EditorPluginAIConfigItem,
} from '../../config-items';

const intentSchemaId = 'convert_to_table.json';

export const convertToTable: EditorPluginAIConfigItem = {
	key: CONFIG_ITEM_KEYS.CONVERT_TO_TABLE,
	title: aiConfigItemMessages.convertToTableTitle,
	description: aiConfigItemMessages.convertToTableDescription,
	icon: () => <SpreadsheetIcon label="" spacing="spacious" />,
	statusLozengeType: 'beta',
	intentSchemaId,
	actions: {
		selection: [
			createInsertBelow({ appearance: 'secondary' }),
			createReplace({ appearance: 'primary' }),
		],
	},
};

export const convertToTableWithOptions: EditorPluginAIConfigItemWithOptions = {
	config: convertToTable,
	triggers: { empty: { enabled: false }, selection: { enabled: true } },
};
