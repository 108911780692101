import { MIGRATE_PAGE_PUBLISH_EXPERIENCE } from '@confluence/experience-tracker';
import type { ContentAppearance } from '@confluence/content-appearance';
import {
	getContentAppearanceFromContentProperties,
	ContentAppearanceType,
	ContentAppearancePropertyKey,
} from '@confluence/content-appearance';

import {
	getExperienceTracker, // eslint-disable-line no-restricted-imports
} from '@confluence/experience-tracker';
import type {
	ContentTypeType,
	EditorContentV2ActionTypes,
	ErrorActionDataType,
} from '../../actions/editor-action-types';
import { ActionTypes } from '../../actions/editor-action-types';

import type { ResetEditorStateActionType } from '../../actions/types/common-editor-action-types';
import { ActionTypes as CommonEditorActionTypes } from '../../actions/types/common-editor-action-types';

import { EDITOR_NAV_DESTINATION } from '../../constants/editor-constants';
import { OK } from '../../actions/status-codes';
import type { ErrorDataType, ErrorStateType } from './types';

// TODO: Move these back when typing the synchrony state
// import {
//   HANDLE_PUBLISH_EVENT,
// } from "../../support/synchrony-support/action-types";

const noError: ErrorStateType = {
	errorData: _getErrorData(null),
	statusCode: null,
};

function _getErrorData(error: ErrorActionDataType | null): ErrorDataType {
	if (error === null) {
		return {};
	}

	const errorMap = {};
	if (error.data && error.data.errors) {
		error.data.errors.forEach((error) => {
			errorMap[error.message.key] = error.details || true;
		});
	}

	return {
		[error.message]: error.details || true,
		...errorMap,
	};
}

export type EditorContentV2Type = {
	contentId?: string;
	hasRequestedEditorContent: boolean;
	editorContent: object | null;
	title: string | null;
	hasRefreshedPage: boolean;
	contentType: ContentTypeType | null;
	editorMode: EDITOR_NAV_DESTINATION;
	isUnpublishedDraft: boolean | null;
	isPublishingContent: boolean;
	syncRev: string | null;
	confRev: string | null;
	lastPublisher: string | null;
	publishedEditorContent: any;
	isADFContentInvalid: boolean;
	publishedTitle: string;
	contentSpaceKey: string;
	fetchReconciledEditorContentFinished: boolean;
	fetchReconciledEditorContentFailure: boolean;
	fetchReconciledEditorContentRequest: boolean;
	nextTryIn: number | null;
	contentAppearanceDraft: ContentAppearance;
	contentAppearancePublished: ContentAppearance;
	contentAppearanceLoading: boolean;
	showErrorOnUpdateContentAppearanceDraftConflict: boolean;
	showErrorOnUpdateContentAppearancePublishedConflict: boolean;
	currentlyScheduledPublishDate: any;
	shouldShowScheduledPublishDialog: boolean;
	shouldShowScheduledPublishModal: boolean;
	isSchedulingPublish: boolean;
	originalTemplate?: { pluginKey: string; moduleKey: string } | null;
} & ErrorStateType;

export type EditorContentV2State = Readonly<EditorContentV2Type>;

export const defaultState: EditorContentV2State = {
	hasRequestedEditorContent: false,
	contentId: undefined,
	editorContent: null,
	title: null,
	hasRefreshedPage: false,
	contentType: null,
	editorMode: EDITOR_NAV_DESTINATION.NONE,
	isUnpublishedDraft: null,
	isPublishingContent: false,
	syncRev: null,
	confRev: null,
	lastPublisher: null,
	publishedEditorContent: null,
	isADFContentInvalid: false,
	publishedTitle: '',
	contentSpaceKey: '',
	fetchReconciledEditorContentFinished: false,
	fetchReconciledEditorContentFailure: false,
	fetchReconciledEditorContentRequest: false,
	nextTryIn: null,
	contentAppearanceDraft: {
		appearance: ContentAppearanceType.DEFAULT,
		version: -1,
	},
	contentAppearancePublished: {
		appearance: ContentAppearanceType.DEFAULT,
		version: -1,
	},
	contentAppearanceLoading: false,
	showErrorOnUpdateContentAppearanceDraftConflict: false,
	showErrorOnUpdateContentAppearancePublishedConflict: false,
	shouldShowScheduledPublishDialog: false,
	shouldShowScheduledPublishModal: false,
	currentlyScheduledPublishDate: null,
	isSchedulingPublish: false,
	...noError,
};

export default function editorContentV2(
	state = defaultState,
	action: EditorContentV2ActionTypes | ResetEditorStateActionType,
): EditorContentV2State {
	switch (action.type) {
		case ActionTypes.EDITOR_UPDATE_CONTENT_APPEARANCE_PROPERTY_REQUEST:
			if (state.contentId !== action.contentId) {
				return state;
			}

			// updating the published and draft content appearance is the same except for the key. so this reducer handles both.
			// defining new state here is important for getting type safety on conditional key name
			// also does this for EDITOR_UPDATE_CONTENT_APPEARANCE_PROPERTY_SUCCESS
			const updatedAppearanceState = {
				...state,
				contentAppearanceLoading: true,
			};
			if (action.propertyKey === ContentAppearancePropertyKey.PUBLISHED) {
				updatedAppearanceState.contentAppearancePublished = {
					...updatedAppearanceState.contentAppearancePublished,
					appearance: action.propertyValue,
				};
			} else {
				updatedAppearanceState.contentAppearanceDraft = {
					...updatedAppearanceState.contentAppearanceDraft,
					appearance: action.propertyValue,
				};
			}
			return updatedAppearanceState;

		case ActionTypes.EDITOR_UPDATE_CONTENT_APPEARANCE_PROPERTY_SUCCESS:
			if (state.contentId !== action.contentId) {
				return state;
			}
			const contentAppearance = {
				appearance: action.response.value,
				version: action.response.version.number,
			};

			const updatedState = { ...state, contentAppearanceLoading: false };
			if (action.response.key === ContentAppearancePropertyKey.PUBLISHED) {
				updatedState.contentAppearancePublished = contentAppearance;
				updatedState.showErrorOnUpdateContentAppearancePublishedConflict = false;
			} else {
				updatedState.contentAppearanceDraft = contentAppearance;
				updatedState.showErrorOnUpdateContentAppearanceDraftConflict = false;
			}
			return updatedState;

		case ActionTypes.EDITOR_UPDATE_CONTENT_APPEARANCE_PROPERTY_FAILURE:
			if (state.contentId !== action.options.contentId) {
				return state;
			}

			if (action.error?.statusCode === 409) {
				if (action.options.propertyKey === ContentAppearancePropertyKey.DRAFT) {
					if (!state.showErrorOnUpdateContentAppearanceDraftConflict) {
						return {
							...state,
							contentAppearanceLoading: false,
							showErrorOnUpdateContentAppearanceDraftConflict: true,
						};
					}
				} else {
					if (!state.showErrorOnUpdateContentAppearancePublishedConflict) {
						return {
							...state,
							contentAppearanceLoading: false,
							showErrorOnUpdateContentAppearancePublishedConflict: true,
						};
					}
				}
			}

			return {
				...state,
				contentAppearanceLoading: false,
				statusCode: action.error && action.error.statusCode,
				errorData: _getErrorData(action.message),
			};

		case ActionTypes.CHANGE_EDITOR_MODE:
			return {
				...state,
				editorMode: action.mode,
			};

		case ActionTypes.CHANGE_EDITOR_TITLE:
			return {
				...state,
				title: action.title,
			};

		case ActionTypes.EDITOR_CONTENT_V2_REQUEST:
			if (state.contentId !== action.contentId) {
				return {
					...state,
					contentId: action.contentId,
					editorContent: null,
					publishedEditorContent: null,
					isADFContentInvalid: false,
					hasRequestedEditorContent: false,
					fetchReconciledEditorContentRequest: true,
					...noError,
				};
			} else {
				// This typically happens if we need to perform a second request to obtain published content
				// like after content reconciliation asks the confluence backend to perform a recovery.
				// When that happens, we should not wipe out the existing content and state.
				return {
					...state,
					hasRequestedEditorContent: false,
				};
			}

		case ActionTypes.EDITOR_CONTENT_V2_SUCCESS:
			if (state.contentId !== action.contentId) {
				return state;
			}

			const document = action.response.editorContent[action.docFormat];

			const { isADFContentInvalid = false, value: publishedEditorContent } = document;

			const publishedTitle = action.response.title || '';

			const contentAppearanceDraft = getContentAppearanceFromContentProperties(
				action.response.appearanceDraft,
				ContentAppearancePropertyKey.DRAFT,
			);
			const contentAppearancePublished = getContentAppearanceFromContentProperties(
				action.response.appearancePublished,
				ContentAppearancePropertyKey.PUBLISHED,
			);

			const schedulePublishDate = action.response.schedulePublishDate;
			const currentlyScheduledPublishDate = schedulePublishDate
				? new Date(schedulePublishDate)
				: null;

			return {
				...state,
				publishedEditorContent,
				publishedTitle,
				contentSpaceKey: action?.response?.space?.key || '',
				statusCode: OK,
				hasRequestedEditorContent: true,
				errorData: _getErrorData(null),
				contentType: action.response.type,
				isUnpublishedDraft: action.response.isUnpublishedDraft,
				isADFContentInvalid,
				contentAppearanceDraft,
				contentAppearancePublished,
				currentlyScheduledPublishDate,
			};

		case ActionTypes.EDITOR_CONTENT_V2_FAILURE:
			if (state.contentId !== action.options.contentId) {
				return state;
			}

			return {
				...state,
				statusCode: action.error && action.error.statusCode,
				errorData: _getErrorData(action.message || null),

				hasRequestedEditorContent: true, // required to display correct error screen

				// In case of editor failure the reconciliation request thunk chain will be terminated before it get's
				// to a promise catch part, so we need to flag that content reconciliation has ended
				fetchReconciledEditorContentFinished: true,
				fetchReconciledEditorContentFailure: true,
				fetchReconciledEditorContentRequest: false,
			};

		case ActionTypes.UPDATE_CONTENT_SPACE_KEY:
			return {
				...state,
				contentSpaceKey: action.spaceKey,
			};

		case ActionTypes.SET_IS_PUBLISHING_CONTENT:
			return {
				...state,
				isPublishingContent: action.isPublishingContent,
			};

		case ActionTypes.SET_IS_SCHEDULING_PUBLISH:
			return {
				...state,
				isSchedulingPublish: action.isSchedulingPublish,
			};

		case ActionTypes.EDITOR_CONTENT_PUBLISH_REQUEST:
			return {
				...state,
				isPublishingContent: !action.content.schedulePublishDate, // For schedule publish, do not set isPublishingContent to true
			};

		case ActionTypes.EDITOR_CONTENT_PUBLISH_FAILURE:
		case ActionTypes.EDITOR_CONTENT_FROM_TEMPLATE_PUBLISH_FAILURE:
			const contentId = action?.options?.content?.id;
			// contentId is undefined for new templates
			if (contentId && contentId.toString() !== state.contentId) {
				return state;
			}
			if (action.options.migratedRecently) {
				getExperienceTracker().fail({
					name: MIGRATE_PAGE_PUBLISH_EXPERIENCE,
					error: new Error(JSON.stringify(_getErrorData(action.message || null))),
				});
			}

			// Don't show an error message if we are going to retry
			if (
				action.options &&
				action.options.retryOnConflict &&
				action.error &&
				action.error.statusCode === 409
			) {
				return state;
			}
			return {
				...state,
				isPublishingContent: false,
				isSchedulingPublish: false,
				statusCode: action.error && action.error.statusCode,
				errorData: _getErrorData(action.message),
			};

		case ActionTypes.EDITOR_CONTENT_PUBLISH_SUCCESS:
		case ActionTypes.EDITOR_CONTENT_FROM_TEMPLATE_PUBLISH_SUCCESS:
			if (action.migratedRecently) {
				getExperienceTracker().succeed({
					name: MIGRATE_PAGE_PUBLISH_EXPERIENCE,
				});
			}

			return {
				...state,
				isUnpublishedDraft: action.content.schedulePublishDate ? state.isUnpublishedDraft : false, // For schedule publish action, do not update draft status
				isPublishingContent: false,
				isSchedulingPublish: false,
				editorMode: action.editorMode,
			};

		case ActionTypes.CLOSE_EDITOR_ERROR:
			return {
				...state,
				...noError,
			};

		case ActionTypes.SHOW_EDITOR_ERROR:
			return {
				...state,
				statusCode: action.statusCode || null,
				errorData: action.errorData,
			};

		case ActionTypes.EDITOR_CONTENT_PREVIEW_FAILURE:
			return {
				...state,
				statusCode: action.error && action.error.statusCode,
				errorData: _getErrorData(action.message),
			};

		case ActionTypes.HANDLE_PUBLISH_EVENT:
			if (state.contentId !== action.contentId) {
				return state;
			}

			return {
				...state,
				statusCode: null,
				errorData: _getErrorData({ message: 'other-publish' }),
				isUnpublishedDraft: false,
				lastPublisher: action.lastPublisher,
			};

		case CommonEditorActionTypes.RESET_EDITOR_STATE:
			return defaultState;

		case ActionTypes.EDITOR_SHOW_SCHEDULED_PUBLISH_DIALOG:
			return {
				...state,
				...(action.context === 'modal'
					? { shouldShowScheduledPublishModal: true }
					: { shouldShowScheduledPublishDialog: true }),
			};

		case ActionTypes.EDITOR_HIDE_SCHEDULED_PUBLISH_COMPONENT:
			return {
				...state,
				shouldShowScheduledPublishDialog: false,
				shouldShowScheduledPublishModal: false,
			};

		case ActionTypes.EDITOR_CURRENTLY_SCHEDULED_PUBLISH_DATE:
			return {
				...state,
				currentlyScheduledPublishDate: action.currentlyScheduledPublishDate,
			};

		case ActionTypes.EDITOR_CANCEL_SCHEDULED_PUBLISH:
			return {
				...state,
				currentlyScheduledPublishDate: null,
			};

		default:
			return state;
	}
}
