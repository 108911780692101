import { CONFIG_ITEM_KEYS } from '../config-item-keys';
import type { EditorPluginAIConfigItem } from '../config-items';

export const isConfluenceTitleToolbarSuggestTitle = ({
	configItem,
	lastTriggeredFrom,
}: {
	configItem?: EditorPluginAIConfigItem;
	lastTriggeredFrom?: string;
}) => {
	if (!configItem || !lastTriggeredFrom) {
		return false;
	}
	return (
		configItem.key === CONFIG_ITEM_KEYS.SUGGEST_A_TITLE &&
		lastTriggeredFrom === 'confluenceTitleToolbar'
	);
};
