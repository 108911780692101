export const CONNECT_APP_PREFIX_KEY = 'com.atlassian.plugins.atlassian-connect-plugin';
export const PAGE_FAVOURITE_KEY = 'confluence.sections.page.temp:pagefavourite';
export const EDIT_BUTTON_PAGE_KEY = 'confluence.sections.page.temp:edit-page';
export const EDIT_BUTTON_BLOGPOST_KEY = 'confluence.sections.page.temp:edit-blogpost';
export const WATCH_BUTTON_KEY = 'com.atlassian.confluence.plugins.watch-button:watch';
export const SHARE_BUTTON_KEY = 'com.atlassian.confluence.plugins.share-page:share';
export const INVITE_TO_EDIT_KEY =
	'com.atlassian.confluence.plugins.confluence-invite-to-edit:share';
export const PAGE_UNFAVOURITE_KEY = 'confluence.sections.page.temp:pageunfavourite';
export const CREATE_NEW_BLOGPOST_KEY =
	'com.atlassian.confluence.plugins.confluence-create-content-plugin:create-blog-post';
export const SPACE_TAB_PAGES_KEY =
	'com.atlassian.confluence.plugins.confluence-space-ia:spacebar-pages';
export const SPACE_SETTINGS_KEY =
	'com.atlassian.confluence.plugins.confluence-frontend-support:space-tools-link';
