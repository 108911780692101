import React from 'react';

import { fg } from '@confluence/feature-gating';
import type { RouteMatch } from '@confluence/route';
import { SpaceRoleManagementScreen } from '@confluence/space-roles/entry-points/SpaceRoleManagementScreen';
import {
	CONTAINER_ADMIN_HOME_ID,
	CONTAINER_HOME_ID,
} from '@confluence/app-navigation/entry-points/navigationIDs';
import { ConfluenceEdition, useSessionData } from '@confluence/session-data';
import { NotFound } from '@confluence/not-found';
import { SPACE_OVERVIEW } from '@confluence/named-routes';
import { Redirection } from '@confluence/route-manager';

export const SpaceMembersRoute = ({ params: { spaceKey } }: RouteMatch) => {
	const { edition } = useSessionData();
	const isFreeEdition = edition === ConfluenceEdition.FREE;

	if (isFreeEdition) {
		return <NotFound />;
	} else if (!fg('cc_perms_exp_rbac_fe_milestone_2')) {
		return <Redirection name={SPACE_OVERVIEW.name} params={{ spaceKey }} />;
	} else {
		return <SpaceRoleManagementScreen spaceKey={spaceKey} />;
	}
};

SpaceMembersRoute.NAVIGATION_PARAMS = ({ name, params: { spaceKey } }, isNav4Enabled) => ({
	Screen: {
		screenEvent: { name: 'spaceMembersScreen', id: spaceKey },
		pageState: {
			routeName: name,
			spaceKey,
		},
	},
	MainLayout: {
		navView: isNav4Enabled ? CONTAINER_ADMIN_HOME_ID : CONTAINER_HOME_ID,
		spaceKey,
	},
});
