import { query, getPreloaderFnContext } from '@confluence/query-preloader-tools';

import { OperationsQuery } from './OperationsQuery.graphql';

export async function preloadOperations() {
	const { userId } = await getPreloaderFnContext();
	if (!userId) {
		return;
	}

	return query({ query: OperationsQuery });
}
