import type { LabelProps as PlatformLabelProps } from '@atlaskit/form';
import { type ComponentPropsConfig } from '../../../utils/codegen';

/**
 * A label represents a caption for an item in a user interface.
 */
export const componentPropsConfig: ComponentPropsConfig = {
	/**
	 * Content of the label.
	 */
	children: true,
	/**
	 * The unique identifier to match the label to the field component `id`.
	 */
	htmlFor: {
		value: 'labelFor',
		excludeFromDeconstruct: true,
		comment: 'labelFor as prop name to remove reference to HTML',
	},
	/**
	 * The unique identifier for the label.
	 */
	id: true,
	testId: true,
};

export type LabelProps = Pick<PlatformLabelProps, 'children' | 'testId' | 'id'> & {
	labelFor: string;
};
/**
 * THIS SECTION WAS CREATED VIA CODEGEN DO NOT MODIFY {@see http://go/af-codegen}
 *
 * This component was generated against an existing platform component.
 * If you are seeing lint errors in this file, it means that this file's dependencies have been modified and the component will need to be regenerated.
 *
 * Please view https://hello.atlassian.net/wiki/spaces/ECO/pages/3358130330/How+to+Regenerate+UI+Kit+Components for more information on how to proceed.
 *
 * For further questions, please ping the #help-forge-ui channel.
 *
 * @codegen <<SignedSource::cf2fe3b68c9a7ca78189587dc3db41ed>>
 * @codegenId #label
 * @codegenCommand yarn workspace @atlassian/forge-ui codegen label
 * @codegenParams { "defineOwnType": true, "customProps": ["labelFor"] }
 * @codegenDependency ../../../../../../../design-system/form/src/label.tsx <<SignedSource::80cfadbf46904337b0393f3fd5c77725>>
 */
/* eslint @repo/internal/codegen/signed-source-integrity: "warn" */
import React from 'react';
import type { RenderFn } from '@atlassian/forge-ui-types';
import { Label as PlatformLabel } from '@atlaskit/form';

// Define the type for Label as LabelProps at the top of this file

/**
 * A label represents a caption for an item in a user interface.
 */
export const Label = (props: Parameters<RenderFn>[0]) => {
  const {
    id,
    testId,
    labelFor,
  } = props.forgeDoc.props as LabelProps;
  return (
    <PlatformLabel
      children={props.forgeDoc.children.map(c => <React.Fragment key={c.key}>{props.render(c)}</React.Fragment>)}
      // labelFor as prop name to remove reference to HTML
      htmlFor={labelFor}
      id={id}
      testId={testId}
    />
  );
};
/**
 * @codegenEnd
 */
