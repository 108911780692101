// codeBidi props are not allowed to prevent malicious developers from misusing them.
import { type ComponentPropsConfig } from '../../../utils/codegen';

export const componentPropsConfig: ComponentPropsConfig = {
	text: true,
	testId: true,
	showLineNumbers: true,
	language: true,
	highlight: true,
	highlightedStartText: true,
	highlightedEndText: true,
	shouldWrapLongLines: true,
};
/**
 * THIS SECTION WAS CREATED VIA CODEGEN DO NOT MODIFY {@see http://go/af-codegen}
 *
 * This component was generated against an existing platform component.
 * If you are seeing lint errors in this file, it means that this file's dependencies have been modified and the component will need to be regenerated.
 *
 * Please view https://hello.atlassian.net/wiki/spaces/ECO/pages/3358130330/How+to+Regenerate+UI+Kit+Components for more information on how to proceed.
 *
 * For further questions, please ping the #help-forge-ui channel.
 *
 * @codegen <<SignedSource::eb30c32e9d9610efd3f6582964fd638b>>
 * @codegenId #code-block
 * @codegenCommand yarn workspace @atlassian/forge-ui codegen code-block
 * @codegenDependency ../../../../../../../design-system/code/src/internal/types.tsx <<SignedSource::ceba0298b26f35952f570a5288751589>>
 * @codegenDependency ../../../../../../../design-system/code/src/internal/hooks/use-highlight.tsx <<SignedSource::3aa947d407515d9cbaa7fff28d6fe736>>
 * @codegenDependency ../../../../../../../design-system/code/src/internal/theme/styles.tsx <<SignedSource::775aa6408b423b4d5aaaf85c65afbd4c>>
 * @codegenDependency ../../../../../../../design-system/code/src/internal/utils/get-normalized-language.tsx <<SignedSource::4089283c94612ebf84c68b58180afb76>>
 * @codegenDependency ../../../../../../../design-system/code/src/syntax-highlighter/index.tsx <<SignedSource::51c8198de28f6833c3690cdb208ed852>>
 * @codegenDependency ../../../../../../../design-system/code/src/code-block.tsx <<SignedSource::7d6fe980a997396e2724b6f7d128de21>>
 */
/* eslint @repo/internal/codegen/signed-source-integrity: "warn" */
import React from 'react';
import type { RenderFn } from '@atlassian/forge-ui-types';
import { CodeBlock as PlatformCodeBlock } from '@atlaskit/code';

type PlatformCodeBlockProps = React.ComponentProps<typeof PlatformCodeBlock>;

export type CodeBlockProps = Pick<
  PlatformCodeBlockProps,
  'text' | 'testId' | 'showLineNumbers' | 'language' | 'highlight' | 'highlightedStartText' | 'highlightedEndText' | 'shouldWrapLongLines'
>;

/**
 * Code highlights short strings of code snippets inline with body text.
 */
export const CodeBlock = (props: Parameters<RenderFn>[0]) => {
  const {
    text,
    testId,
    showLineNumbers,
    language,
    highlight,
    highlightedStartText,
    highlightedEndText,
    shouldWrapLongLines,
  } = props.forgeDoc.props as CodeBlockProps;
  return (
    <PlatformCodeBlock
      text={text}
      testId={testId}
      showLineNumbers={showLineNumbers}
      language={language}
      highlight={highlight}
      highlightedStartText={highlightedStartText}
      highlightedEndText={highlightedEndText}
      shouldWrapLongLines={shouldWrapLongLines}
    />
  );
};
/**
 * @codegenEnd
 */
