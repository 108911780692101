import { query } from '@confluence/query-preloader-tools';

import { ContentTypesHeaderQuery } from './ContentTypesHeaderQuery.graphql';

export function preloadContentTypesHeader(contentId, useObjectHeader) {
	return query({
		query: ContentTypesHeaderQuery,
		variables: {
			contentId,
			useObjectHeader,
		},
	});
}
