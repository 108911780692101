import {
	JiraExtension,
	type ForgeUIExtensionType,
	type JiraExtensionType,
} from '@atlassian/forge-ui-types';
import { createMakeProductURLPath } from '../createMakeProductURLPath';
import { ensurePropertyExists } from './common';

type FullForgeUIExtensionType = ForgeUIExtensionType & {
	properties: ForgeUIExtensionType['properties'] & {
		route?: string;
	};
};

const makeJiraURLPath = createMakeProductURLPath('/jira');

// NOTE: Project Keys are always uppercase in Jira - the urls below will automatically convert any provided project key to uppercase
// https://confluence.atlassian.com/adminjiraserver/changing-the-project-key-format-938847081.html#:~:text=The%20project%20key%20only%20supports,lowercase%20characters%20to%20uppercase%20ones.

export const jiraExtensionURLHelpers: Partial<
	Record<JiraExtensionType, (extension: FullForgeUIExtensionType, params?: any) => string | null>
> = {
	[JiraExtension.ADMIN_PAGE]: ({ appId, environmentId }) => {
		return makeJiraURLPath(`/settings/apps/${appId}/${environmentId}`);
	},
	[JiraExtension.GLOBAL_PAGE]: ({ appId, environmentId }) => {
		return makeJiraURLPath(`/apps/${appId}/${environmentId}`);
	},
	[JiraExtension.PERSONAL_SETTINGS_PAGE]: ({ appId, environmentId }) => {
		return makeJiraURLPath(`/settings/personal/apps/${appId}/${environmentId}`);
	},
	[JiraExtension.PROJECT_SETTINGS_PAGE]: (
		{ appId, environmentId },
		{ projectKey }: { projectKey: string },
	) => {
		return makeJiraURLPath(
			`/software/projects/${ensurePropertyExists('projectKey', projectKey).toUpperCase()}/settings/apps/${appId}/${environmentId}`,
		);
	},
	[JiraExtension.PROJECT_PAGE]: (
		{ appId, environmentId },
		{ projectKey }: { projectKey: string },
	) => {
		return makeJiraURLPath(
			`/software/projects/${ensurePropertyExists('projectKey', projectKey).toUpperCase()}/apps/${appId}/${environmentId}`,
		);
	},
};

export const jiraNavigationTargetHandlers = {
	dashboard: async ({ dashboardId }: { dashboardId: string }) => {
		return makeJiraURLPath(`/dashboards/${ensurePropertyExists('dashboardId', dashboardId)}`);
	},
	issue: async ({ issueKey }: { issueKey: string }) => {
		return `/browse/${ensurePropertyExists('issueKey', issueKey)}`;
	},
	// equivalent to Connects projectAdminSummary target
	projectSettingsDetails: async ({ projectKey }: { projectKey: string }) => {
		return makeJiraURLPath(
			`/software/projects/${ensurePropertyExists('projectKey', projectKey).toUpperCase()}/settings/details`,
		);
	},
} as const;
