import React, { useState, useEffect } from 'react';

import type { RouteMatch } from '@confluence/route';
import { CONTAINER_HOME_ID } from '@confluence/app-navigation/entry-points/navigationIDs';

import { ConnectSpaceCustomContentListView } from '@confluence/custom-content/entry-points/ConnectSpaceCustomContentListView';

export const SpaceCustomContentListRoute = ({ params: { spaceKey, type } }: RouteMatch) => {
	const [stateKey, setStateKey] = useState(history?.state?.key || Math.random());

	// Updating a key state and reload ConnectSpaceCustomContentListView component.
	//
	// Connect Apps can have an inner history state (created with AP.history), which add
	// hashtag to URL (for example: #!state1)
	// When user navigates to this route again the URL is reset to initial state and
	// hashtag is removed, but inner app does not get any notifications about that and
	// don't reset inner app state.
	//
	// To fix this issue we are listening history.state.key, and when it's changed
	// (user create a new entry in history) refresh component
	useEffect(() => {
		// We rely on history.state.key that is not documented, but major browsers have it
		if (history?.state?.key) {
			setStateKey(history.state.key);
			// A state created with AP.history has _AP object, but not have a key
			// Don't refresh if we back/forward to this state
		} else if (!history?.state?._AP) {
			setStateKey(Math.random());
		}
		/* eslint-disable react-hooks/exhaustive-deps */
	}, [history?.state, setStateKey]);

	return <ConnectSpaceCustomContentListView spaceKey={spaceKey} type={type} key={stateKey} />;
};

SpaceCustomContentListRoute.NAVIGATION_PARAMS = ({
	name,
	params: { spaceKey, type },
}: RouteMatch) => ({
	Screen: {
		screenEvent: {
			name: 'spaceCustomContent',
			id: spaceKey,
			attributes: { type },
		},
		pageState: {
			routeName: name,
			spaceKey,
		},
	},
	MainLayout: {
		navView: CONTAINER_HOME_ID,
		spaceKey,
		disableMinWidth: true,
	},
});
