import { fg } from '@atlaskit/platform-feature-flags';

// This function is used to get the feature flags that should be passed down to Forge Custom UI apps
export const getPlatformFeatureFlags = () => {
	const platformFeatureFlags: string[] = [];
	// Each feature flag name is required to be an inline string for static analysis
	if (fg('platform-visual-refresh-icons')) {
		platformFeatureFlags.push('platform-visual-refresh-icons');
	}
	return platformFeatureFlags;
};
