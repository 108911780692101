import React from 'react';

import type { ExtensionManifest, ExtensionParams } from '@atlaskit/editor-common/extensions';

import { REDACTION_EXTENSION_TYPE, type RedactionExtensionParams } from '../redaction-constants';

import { TourableRedactionExtensionLoader } from './TourableRedactionExtensionLoader';

export const createTourableRedactionExtension = ({
	contentId,
}: {
	contentId?: string | null;
}): ExtensionManifest => {
	return {
		title: 'Redaction',
		type: REDACTION_EXTENSION_TYPE,
		key: 'redaction',
		description: 'Inserts redacted block.',
		icons: {
			// eslint-disable-next-line import/dynamic-import-chunkname
			'48': () => import('@atlaskit/icon/glyph/editor/addon'),
		},
		modules: {
			nodes: {
				default: {
					type: 'inlineExtension',
					render: async () => (args) => {
						const node = args.node as ExtensionParams<RedactionExtensionParams>;
						return <TourableRedactionExtensionLoader contentId={contentId} extensionNode={node} />;
					},
				},
			},
		},
	} as ExtensionManifest;
};
