import React from 'react';

import { SITE_ANALYTICS_PERMISSIONS_EVENTS_SOURCE } from '@confluence/confluence-analytics/entry-points/analyticsConstants';
import { SitePermissionsPageLoader } from '@confluence/confluence-analytics/entry-points/AnalyticsPagesLoaders';
import { ADMIN_HOME_ID } from '@confluence/app-navigation/entry-points/navigationIDs';

export const SiteAnalyticsPermissionsRoute = () => {
	return <SitePermissionsPageLoader />;
};

SiteAnalyticsPermissionsRoute.NAVIGATION_PARAMS = () => ({
	Screen: {
		screenEvent: { name: SITE_ANALYTICS_PERMISSIONS_EVENTS_SOURCE },
	},
	MainLayout: {
		navView: ADMIN_HOME_ID,
	},
});
