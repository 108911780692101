/* eslint react/prop-types:0 */
import React from 'react';

import {
	CONTAINER_ADMIN_HOME_ID,
	CONTAINER_HOME_ID,
} from '@confluence/app-navigation/entry-points/navigationIDs';
import { LoadablePaint } from '@confluence/loadable';

const SpaceSettingsEditorConversionRouteComponent = LoadablePaint({
	loader: () =>
		import(
			/* webpackChunkName: "loadable-SpaceSettingsEditorConversionRouteComponent" */ './SpaceSettingsEditorConversionRouteComponent'
		),
});

export const SpaceSettingsEditorConversionRoute = ({ params: { spaceKey } }) => (
	<SpaceSettingsEditorConversionRouteComponent spaceKey={spaceKey} />
);

SpaceSettingsEditorConversionRoute.NAVIGATION_PARAMS = (
	{ name, params: { spaceKey } },
	isNav4Enabled,
) => ({
	Screen: {
		screenEvent: { name: 'spaceSettingsEditorConversion', id: spaceKey },
		pageState: { spaceKey, routeName: name },
	},
	MainLayout: {
		navView: isNav4Enabled ? CONTAINER_ADMIN_HOME_ID : CONTAINER_HOME_ID,
		spaceKey,
		disableMinWidth: true,
	},
});
