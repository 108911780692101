if (window?.performance) {
	window.performance.mark('CFP-63.app');
	window.performance.mark('navigation-fmp.NavigationFMP.start');
	window.performance.mark('side-navigation-fmp.SideNavigationFMP.start');
}

import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import { createRoot, hydrateRoot } from 'react-dom/client';
import createCache from '@emotion/cache';
import { fg } from '@confluence/feature-gating';
// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import { CacheProvider } from '@emotion/react';
import UAParser from 'ua-parser-js';

import { isEmbeddedConfluence_DO_NOT_USE } from '@atlassian/embedded-confluence/isEmbeddedConfluence';
import UFOSegment from '@atlaskit/react-ufo/segment';
import setupUFOGlobalErrorHandler from '@atlaskit/react-ufo/global-error-handler';

import { initUFO, initUFOWithSsrData } from '@confluence/react-ufo';
import { initializeBrowserMetrics } from '@confluence/browser-metrics';
import {
	collectSSRedLegacyMacros,
	releaseUnusedSSRedLegacyMacrosWithDelay,
} from '@confluence/content-renderer-legacy-macros';
import { getSessionData, getEmptySessionData, setOverride } from '@confluence/session-data';
import { getApolloClient, getAGGClient } from '@confluence/graphql';
import { processQueryParamFeatureFlags } from '@confluence/feature-flag-url-override';
import { loadTranslation } from '@confluence/i18n';
import {
	cleanUpLoadablePlaceholders,
	initLoadablePlaceholders,
	preloadLoadable,
} from '@confluence/loadable';
import { WaterfallMeasures, WATERFALL_INITIAL_MEASURES } from '@confluence/action-measures';
import { getAnalyticsWebClient } from '@confluence/analytics-web-client';
import { isNativeMobile, setUserAgent } from '@confluence/browser-helper';
import { BrowserNotSupportedLoader as BrowserNotSupportedPage } from '@confluence/browser-not-supported/entry-points/BrowserNotSupportedLoader';
import { SSRAppTheme, SSRReactRootTiming, SSRAppScripts } from '@confluence/ssr-app-components';
import {
	initializePreloaderFnContext,
	preloadQueriesForSPAHydration,
} from '@confluence/query-preloaders';
import { StatsigConfigurations } from '@confluence/statsig-client/entry-points/StatsigConfigurations';
import { matchHydrationError, getHydrationErrorWithRoute } from '@confluence/ssr-utilities';

import { coreBrowserNotSupported } from '@devtools/browser-check';

import { captureViewportMediaForAnalytics } from './analytics';
import configureStore from './stores/configureStore';
import { subscribe } from './subscriptions';
import Root from './components/Root/Root';
import { initializeMemoryMonitor } from './memory-monitor';
import { lazyLanguage } from './i18n/lazy-language';
import { preloadComponents } from './route-component-preloader';
import { handleDOMGoogleTranslateIssues } from './google-translate-issue-workaround';
import { postProcessSessionData } from './post-process-session-data';
import { initialization } from './initialization';
import { getVCObserver } from '@atlaskit/react-ufo/vc';
import { getMonitoringClient } from '@confluence/monitoring';

const EMOTION_CACHE_KEY = 'cc';

const initializeVCObserver = (rootNode) => {
	const vcObserver = getVCObserver();
	vcObserver.setSSRElement(rootNode);
	vcObserver.setReactRootRenderStart();
	requestAnimationFrame(() => {
		requestAnimationFrame(() => {
			vcObserver.setReactRootRenderStop();
		});
	});
};

(async function () {
	/**
	 * setupUFOGlobalErrorHandler MUST be the first thing executed before any rendering operations.
	 * This ensures we catch and track any errors that occur during the app's initialization,
	 * including async operations and React rendering. Moving this call later would risk missing
	 * early errors.
	 * it provides ufo:errors:globalCount is the count of all errors which occurred on the client all up
	 */
	setupUFOGlobalErrorHandler();
	const isUFOInitialized = initUFOWithSsrData(window.__SSR_INIT_UFO_DATA__);

	// When page is being refreshed or redirected from server we run into a condition when redirect
	// url requested from the server and SPA (which resources are loaded via early HTML flush) is
	// being initialized at the same time. We don't want to waste browser resources/make network
	// calls when page is going to be redirected anyway, so bail out early.
	if (document.querySelector(`meta[http-equiv="Refresh"]`)) {
		return;
	}

	const rootNode = document.getElementById('confluence-ui');

	if (window.__HYDRATABLE__) {
		// remove nonce attributes from style tags to support react hydration
		document.querySelectorAll('style[nonce]').forEach((styleTag) => {
			styleTag.removeAttribute('nonce');
		});
	}

	const client = getApolloClient();
	const aggClient = getAGGClient();

	let initializeEmbeddedConfluencePromise;
	if (isEmbeddedConfluence_DO_NOT_USE()) {
		initializeEmbeddedConfluencePromise = (
			await import(
				/* webpackChunkName: "loadable-embedded-initialize" */ '@confluence/embedded-confluence/entry-points/initialize'
			)
		).initialize();
	}

	let browserNotSupported;
	// Must call this before any browser-helper functions, to ensure user agent has been set first
	setUserAgent();
	// Bypass browser compatibility checks in the case of the Confluence native mobile app;
	// it is the responsibility of the mobile app to ensure the rendering of Confluence
	const shouldCheckBrowserCompatibility = !isNativeMobile();
	if (shouldCheckBrowserCompatibility) {
		browserNotSupported = coreBrowserNotSupported();

		if (browserNotSupported) {
			// eslint-disable-next-line react/no-deprecated
			return void ReactDOM.render(<BrowserNotSupportedPage />, rootNode);
		}
	}

	const sessionDataPromise = WaterfallMeasures.run(
		WATERFALL_INITIAL_MEASURES.BOOTSTRAP_SESSION_DATA,
		async () => {
			await initializePreloaderFnContext();
			void preloadQueriesForSPAHydration();
			// HOT-89887: server is giving 403 errors on an expired token, causing Apollo
			// to throw. Uncaught, the React app wouldn't start, and can't acknowledge
			// messages from the service worker; thus the service worker can't trigger a
			// redirect to the login page; thus user gets stuck at the Confluence logo
			// screen. By catching here, we can at least bootstrap the app and proceed to
			// the redirect.
			//
			// https://jira.atlassian.com/browse/CONFCLOUD-71974: IP allowlisting is
			// causing a 403 scenario similar to HOT-89887 above.
			return getSessionData().then(postProcessSessionData, getEmptySessionData);
		},
	);

	// Here we are reading from a value that injected by Monolith in DOM to get the locale.
	// We should really read it from session data. However we can't because we will need to wait for session data to come back.
	const translationPromise = WaterfallMeasures.run(
		WATERFALL_INITIAL_MEASURES.BOOTSTRAP_TRANSLATION,
		() => loadTranslation(lazyLanguage),
	);

	// Preload loadable id defined in the global __LOADABLE__ array
	// If the page is rendered by React SSR service this promise will be blocking.
	// On normal SPA it is not blocking.
	const preloadLoadablePromise = WaterfallMeasures.run(
		WATERFALL_INITIAL_MEASURES.BOOTSTRAP_LOADABLE,
		() => preloadLoadable(),
	);

	// Captures the media sources in the current view port as early as possible while SSR response is displaying.
	captureViewportMediaForAnalytics();

	// Preload components/JS chunks as early as possible, when entry point is loaded and
	// route is known, so they'll be ready by the moment when they're actually used.
	preloadComponents(window.location.toString(), false);

	// Initialize Loadable by reading server markup to be used as loadable components' placeholders.
	initLoadablePlaceholders();

	// Scan loaded document to collect DOM elements for legacy macros.
	collectSSRedLegacyMacros();
	Promise.all([
		sessionDataPromise,
		translationPromise,
		preloadLoadablePromise,
		initializeEmbeddedConfluencePromise,
	]).then(([sessionData]) => {
		// Initialize VCObserver before any rendering logic
		if (rootNode && fg('confluence_frontend_vc_observer_initialization')) {
			initializeVCObserver(rootNode);
		}
		initializeBrowserMetrics(sessionData);
		if (!isUFOInitialized) {
			initUFO(sessionData);
		}

		// Persist any FF overrides from URL query params to the server,
		// so they're available on the next reload.
		const urlOverrides = processQueryParamFeatureFlags(sessionData.featureFlags);

		for (const ff in urlOverrides) {
			setOverride(ff, urlOverrides[ff]);
		}

		// Redux store
		const store = configureStore();
		subscribe(store);

		// When __APOLLO_STATE__ is available in the global variable it means we are bootstrapping SPA over a SSR rendered page.
		// __APOLLO_STATE__ is the serialized apollo cache that is enough to render everything that has already preloaded in preloadLoadable.
		// We are disabling network so on the first render so the components will read from cache only not firing queries again.
		// See https://github.com/apollographql/apollo-client/issues/4814
		if (window['__APOLLO_STATE__']) {
			client.disableNetworkFetches = true;
		}
		if (window['__APOLLO_AGG_STATE__']) {
			aggClient.disableNetworkFetches = true;
		}

		// Workaround for this google translate problem https://github.com/facebook/react/issues/11538#issuecomment-417504600
		// which is causing this issue https://product-fabric.atlassian.net/browse/CCP-2409, affecting view page reliability
		handleDOMGoogleTranslateIssues();

		// code for regression drill logic. Systematic way of indentifying and tracking performance regressions in web pages
		// will be used to test out criterion, a new perfomacne tracking software
		// for more info visit https://hello.atlassian.net/wiki/spaces/CVMAI/pages/3761808800/Regression+Drill+Plan
		const delayValueForRegression = StatsigConfigurations.getNumber(
			'confluence_frontend_preprod_slow',
			0,
		);

		if (
			delayValueForRegression > 0 &&
			(process.env.CLOUD_ENV === 'dev' ||
				process.env.CLOUD_ENV === 'staging' ||
				process.env.CLOUD_ENV === 'hello' ||
				process.env.CLOUD_ENV === 'branch')
		) {
			const end = performance.now() + Number(delayValueForRegression);
			while (performance.now() < end) {
				//do nothing
			}
		}

		const cache = createCache({ key: EMOTION_CACHE_KEY });
		const App = (
			<UFOSegment name="confluence-root">
				<SSRAppTheme />

				<CacheProvider value={cache}>
					<SSRReactRootTiming>
						<Root
							store={store}
							context={{
								insertCss: (styles) => styles._insertCss(),
							}}
							apolloClient={client}
							sessionData={sessionData}
						/>
					</SSRReactRootTiming>
				</CacheProvider>

				<SSRAppScripts />
			</UFOSegment>
		);

		const renderCallback = () => {
			if (window['__APOLLO_STATE__']) {
				client.disableNetworkFetches = false;
			}
			if (window['__APOLLO_AGG_STATE__']) {
				aggClient.disableNetworkFetches = false;
			}
			initialization(sessionData);
			// Inside of cleanUpLoadablePlaceholders function, it delayed cleaning up loadable placeholders.
			cleanUpLoadablePlaceholders();
			releaseUnusedSSRedLegacyMacrosWithDelay();

			const userAgentParser = new UAParser(navigator.userAgent);
			const { name: browserName, version: browserVersion } = userAgentParser.getBrowser();

			void getAnalyticsWebClient().then((analyticsClient) => {
				// GASv3 analytics client is initialized inside of initialization() so we
				// must call the analytics client after that function has been called.
				if (shouldCheckBrowserCompatibility) {
					analyticsClient.sendOperationalEvent({
						source: 'confluenceRoot',
						action: 'executed',
						actionSubject: 'browserRuntimeCheck',
						attributes: {
							failedCoreBrowserCheck: browserNotSupported,
							browserName,
							browserVersion,
						},
					});
				}

				initializeMemoryMonitor(analyticsClient);
			});
			if (rootNode && !fg('confluence_frontend_vc_observer_initialization')) {
				initializeVCObserver(rootNode);
			}
		};

		//adding a call to the flag to fix for ssr FF statsig exposures not getting captured
		fg('confluence_ssr_load_after_paint_loadables_as_paint');
		fg('confluence_ssr_enable_hydration');
		fg('confluence_ssr_enable_hydration_home');
		fg('confluence_createroot_in_full_cycle_render');

		const urlParams = new URLSearchParams(window?.location?.search);
		const legacyModeParam = urlParams.has('LEGACY_MODE', '1');
		if (!legacyModeParam && fg('confluence_frontend_use_modern_rendering_engine')) {
			const ConcurrentApp = () => {
				useEffect(() => {
					renderCallback();
				}, []);
				return App;
			};
			if (window['__HYDRATABLE__']) {
				window['__HYDRATION_FAILED__'] = false;
				hydrateRoot(rootNode, <ConcurrentApp />, {
					onRecoverableError: (error) => {
						if (window['__HYDRATION_ERRORS__'] && error?.message) {
							window['__HYDRATION_ERRORS__'].push({
								type: 'ERROR_MESSAGES_FROM_REACT',
								msg: error.message,
							});
						}
						const { hydrationFailed, friendlyMessage, docLink } = matchHydrationError(error);
						if (hydrationFailed) {
							window['__HYDRATION_FAILED__'] = true;
							const errorWithRoute = getHydrationErrorWithRoute(
								window.location.toString(),
								friendlyMessage,
							);
							getMonitoringClient().submitError(errorWithRoute, {
								attribution: 'hydration',
							});
						}
						if (
							process.env.CLOUD_ENV === 'dev' ||
							process.env.CLOUD_ENV === 'branch' ||
							process.env.CLOUD_ENV === 'staging' ||
							process.env.CLOUD_ENV === 'hello'
						) {
							// eslint-disable-next-line no-console
							console.error(
								`Hydration error: ${friendlyMessage} Please use this link for debugging ${docLink}. Error from react: ${error}`,
							);
						}
					},
				});
			} else {
				const concurrentRoot = createRoot(rootNode);
				concurrentRoot.render(<ConcurrentApp />);
			}
		} else {
			// eslint-disable-next-line react/no-deprecated
			const renderFn = window['__HYDRATABLE__'] ? ReactDOM.hydrate : ReactDOM.render;
			renderFn(App, rootNode, renderCallback);
		}
	});
})();
