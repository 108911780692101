import React from 'react';

import { SpaceAnalyticsPageLoader } from '@confluence/confluence-analytics/entry-points/AnalyticsPagesLoaders';
import {
	CONTAINER_ADMIN_HOME_ID,
	CONTAINER_HOME_ID,
} from '@confluence/app-navigation/entry-points/navigationIDs';
import type { RouteMatch } from '@confluence/route';

import SpaceBase from '../../SpaceBase';

export const SpaceAnalyticsRoute = ({ params: { spaceKey } }: RouteMatch) => {
	return (
		<SpaceBase>
			<SpaceAnalyticsPageLoader spaceKey={spaceKey} />
		</SpaceBase>
	);
};

SpaceAnalyticsRoute.NAVIGATION_PARAMS = (
	{ name, params: { spaceKey, tab } }: RouteMatch,
	isNav4Enabled: boolean,
) => ({
	Screen: {
		// screenEvent will be handled in the page components
		pageState: {
			routeName: name,
			spaceKey,
			tab,
		},
	},
	MainLayout: {
		spaceKey,
		navView: isNav4Enabled ? CONTAINER_ADMIN_HOME_ID : CONTAINER_HOME_ID,
	},
});
