import React, { useContext } from 'react';

import { CompanyHubAnalyticsWrapper } from '@confluence/analytics/entry-points/CompanyHubAnalyticsWrapper';
import { PRODUCT_HOME_ID } from '@confluence/app-navigation/entry-points/navigationIDs';
import { CompanyHomeBuilderUpsellPage } from '@confluence/change-edition/entry-points/CompanyHomeBuilderUpsellPage';
import { useCompanyHubPremiumGate } from '@confluence/company-hub-utils/entry-points/useCompanyHubPremiumGate';
import { useIsCompanyHubEnabled } from '@confluence/company-hub-utils/entry-points/useIsCompanyHubEnabled';
import { HOME, COMPANY_HUB, COMPANY_HUB_EDIT } from '@confluence/named-routes';
import { START_TOUCH } from '@confluence/navdex';
import { getCompanyHubSpaceKey } from '@confluence/route-manager/entry-points/companyHubUtils';
import { Redirection } from '@confluence/route-manager/entry-points/Redirection';
import { SPACE_OVERVIEW as SPACE_OVERVIEW_MARKER } from '@confluence/screen';
import { SPAViewContext } from '@confluence/spa-view-context';
import { SpaceOverviewLoader } from '@confluence/space-overview';
import { FullPageLoadingScreen } from '@confluence/full-page-loading-screen';
import { useRouteActions } from '@confluence/route-manager';
import { useCompanyHubOnRouteCreationFlow } from '@confluence/company-hub-utils/entry-points/useCompanyHubOnRouteCreationFlow';
import { useRenderServerPlaceholder } from '@confluence/ssr-utilities';
import { fg } from '@confluence/feature-gating';
import { HubErrorPageComponent } from '@confluence/company-hub-utils/entry-points/HubErrorPageComponent';

import { getCompanyHubPseudoAppRoute } from './getCompanyHubPseudoAppRoute';

export function getCompanyHubRoute(Component: React.ElementType) {
	const CompanyHubRoute = () => {
		const { isCompanyHubEntryPointEnabled, isCompanyHubPublished } = useIsCompanyHubEnabled();
		const { isSiteAdmin, loading } = useContext(SPAViewContext);
		const shouldShowPremiumUpsell = useCompanyHubPremiumGate();
		const renderServerPlaceholder = useRenderServerPlaceholder();
		const { push } = useRouteActions();

		const { isCreatingHub, creationError } = useCompanyHubOnRouteCreationFlow({
			onSuccess: (homepageId) => {
				push(
					COMPANY_HUB_EDIT.toUrl({}, { query: { redirect: 'first-time', contentId: homepageId } }),
				);
			},
		});

		if (isCreatingHub || loading) {
			return <FullPageLoadingScreen />;
		}

		screenEventAttributes.publishedCompanyHub = isCompanyHubPublished;

		if (shouldShowPremiumUpsell) {
			if (isSiteAdmin || fg('company-hub-pseudo-app')) {
				/**
				 * SSR compatibility: CompanyHomeBuilderUpsellPage uses UpsellLooknFeel/SectionMessage
				 * which lacks SSR support. Rendering during SSR would cause:
				 * - Hydration mismatches between server/client renders
				 * - Visible layout shift (CLS) during client hydration since SectionMessage
				 *   appears at the top of the content area
				 *
				 * For now, client-side rendering only, until components are refactored for SSR support.
				 */
				if (renderServerPlaceholder) {
					return null;
				}

				return <CompanyHomeBuilderUpsellPage featureName="companyHub" />;
			} else {
				return <Redirection name={HOME.name} />;
			}
		}

		if (creationError) {
			return <HubErrorPageComponent error={creationError} />;
		}

		if (!isCompanyHubEntryPointEnabled) {
			return <Redirection name={HOME.name} />;
		}

		// The only different between SPA and SSR is we don't render space base in SSR

		const spaceKey = getCompanyHubSpaceKey();

		return (
			<CompanyHubAnalyticsWrapper>
				<Component spaceKey={spaceKey} contentId="" />
			</CompanyHubAnalyticsWrapper>
		);
	};

	const screenEventAttributes: Record<string, any> = {
		navdexPointType: START_TOUCH,
		// Company Hub hides its space from the user, doesn't refer to its page
		// as such, is rather presented to the user as "a new surface", and thus
		// doesn't constitute space usage from the perspective of the user.
		screenIsInSpace: false,
	};

	/**
	 * Warning: Though the function NAVIGATION_PARAMS is invoked during render,
	 * each route has its own implementation. Consequently, it cannot invoke
	 * hooks or, during route transitions between implementations that invoke
	 * different hooks, the rule of hooks to invoke a hook the same number of
	 * times during each render would be violated and an error would be thrown
	 * by React that would damage the DOM.
	 */
	CompanyHubRoute.NAVIGATION_PARAMS = () => {
		const spaceKey = getCompanyHubSpaceKey();

		return {
			Screen: {
				screenEvent: {
					name: 'companyHub',
					id: spaceKey,
					// Warning: The value of the analytics event attribute
					// publishedCompanyHub that ScreenEvent sends will be
					// inaccurate on initial SPA load without SSR, because
					// ScreenEvent sends before SPAViewContext has loaded.
					attributes: screenEventAttributes,
				},
				pageState: {
					spaceKey,
					contentId: SPACE_OVERVIEW_MARKER,
					routeName: COMPANY_HUB.name,
				},
			},
			MainLayout: {
				navView: PRODUCT_HOME_ID,
				spaceKey,
				isViewPage: true,
			},
		};
	};

	return getCompanyHubPseudoAppRoute(CompanyHubRoute);
}

export const CompanyHubRouteForSSR = getCompanyHubRoute(SpaceOverviewLoader);
