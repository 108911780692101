import { useFirePostOfficeAnalyticsEvents } from '@post-office/analytics';
import { useEffect, useState } from 'react';

type Messages<T> = T;
type TranslatedResult<T> = { messages: Messages<T>; errors: Array<string> };
export type MessageLoader<T> = (locale: string) => Promise<TranslatedResult<T>>;

export const useIntlMessages = <T>(
	locale: string,
	messageLoader: MessageLoader<T>,
	initialMessages?: Messages<T>,
): Messages<T | undefined> => {
	const [messages, setMessages] = useState<Messages<T> | undefined>(initialMessages);
	const { fireAnalyticsEvent } = useFirePostOfficeAnalyticsEvents();

	useEffect(() => {
		if (initialMessages) {
			return;
		}

		messageLoader(locale)
			.then((loadedMessages) => {
				if (loadedMessages && loadedMessages.messages) {
					setMessages(loadedMessages.messages);
				}

				if (loadedMessages?.errors?.length > 0) {
					fireAnalyticsEvent({
						action: 'errored',
						actionSubject: 'intlMessagesLoader',
						eventType: 'operational',
						attributes: {
							locale: locale,
							errorMessage: loadedMessages.errors,
						},
					});
				}
			})
			// eslint-disable-next-line no-console
			.catch(console.error);
	}, [locale, initialMessages]);

	return messages;
};
