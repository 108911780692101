import debounce from 'lodash/debounce';

import { getLogger } from '@confluence/logger';
import { BucketQueue } from '@confluence/generics';
import {
	confluenceSessionStorageInstance as sessionStorage,
	keys,
} from '@confluence/storage-manager';

import { createFutureTask } from './futureTask';
import type { PriorityTask, QueueItem, TaskManagerOptions } from './types';
import { LoadingPriority } from './constants';

const logger = getLogger('TaskManager');
const TASK_TIME_QUANTUM = 35; // ms
const TASK_DELAY = 5; // ms

const trackLoadingPhase = (loadingPhase: number) => {
	if (typeof window !== 'undefined' && process.env.PACKAGE_STATS) {
		(window as any).__lf = loadingPhase;
	}
};

const isInputPending = () => {
	// This API is only available in Chrome 87 and higher, as of Oct 2021
	return (navigator as any)?.scheduling?.isInputPending() || false;
};

export class TaskManager<T> {
	private maxQueueTimeMs: number;
	private debounceTimeMs: number;
	private disabled: boolean;
	private allowedPriority: number;
	private totalTasksHydratedAtOncePerTaskId: number;
	private serverMarkupHydratedCountMap: Map<string, number>;

	// This is a priority queue. Lower priority numbers are ordered first.
	private queue = new BucketQueue<QueueItem<T>>(LoadingPriority.MAX_PRIORITY);
	private running = new Set<QueueItem<T>>();

	/**
	 * Run any tasks that are the same priority as the head of the queue.
	 *
	 * This is debounced to allow for higher-priority tasks that are queued
	 * (slightly) later to be run first.
	 */
	private runTasks: () => void;

	constructor(options: TaskManagerOptions = {}) {
		let DEBUG_STEP_OVER = false;
		try {
			// handle The "operation is insecure" when cookie is disabled
			DEBUG_STEP_OVER =
				typeof window !== 'undefined' &&
				Boolean(sessionStorage?.getItem(keys.CONFLUENCE_LOADABLE_STEP_OVER_ENABLED));
		} catch (e) {}

		this.maxQueueTimeMs = options.maxQueueTimeMs || (DEBUG_STEP_OVER ? 0 : 10000);

		this.debounceTimeMs = options.debounceTimeMs || 0;
		this.disabled = options.disabled || false;
		this.allowedPriority = DEBUG_STEP_OVER ? LoadingPriority.PAINT : LoadingPriority.MAX_PRIORITY;
		this.totalTasksHydratedAtOncePerTaskId = options.totalHydratedAtOnce || 0;
		this.serverMarkupHydratedCountMap = new Map<string, number>();

		this.runTasks = debounce(() => {
			const deadline = performance.now() + TASK_TIME_QUANTUM;

			// Get all the heap items unless that is no more item with the same priority
			let lastPriority = Infinity;
			const batch: Array<QueueItem<T>> = [];
			while (
				!this.running.size &&
				this.queue.size &&
				this.queue.peek()!.priority <= Math.min(this.allowedPriority, lastPriority)
			) {
				// If runTask takes more than TIME_QUANTUM ms and there's a pending input
				// break the task execution to allow time for an input handler
				if (isInputPending() && performance.now() >= deadline) {
					setTimeout(() => this.runTasks(), TASK_DELAY);
					return;
				}

				const item = this.queue.poll()!;
				lastPriority = item.priority;
				trackLoadingPhase(lastPriority);

				// Run task
				item.futureTask.run();
				batch.push(item);

				// On task finish
				item.futureTask.result.finally(() => {
					this.running.delete(item);
					if (
						item._delayHydration &&
						//totalTasksHydratedAtOnce acts as a switch to turn on/off hydration delay
						window.__SSR_RENDERED__ &&
						this.totalTasksHydratedAtOncePerTaskId > 0 &&
						this.serverMarkupHydratedCountMap.has(item.id)
					) {
						this.serverMarkupHydratedCountMap.set(
							item.id,
							this.serverMarkupHydratedCountMap.get(item.id)! - 1,
						);
					}

					this.runTasks();
				});
			}

			batch.forEach((t) => this.running.add(t));
			batch.length = 0;
		}, this.debounceTimeMs);

		if (DEBUG_STEP_OVER) {
			document.addEventListener('loadable-devtool-next-phase', () => {
				this.allowedPriority = Math.min(this.allowedPriority + 1, LoadingPriority.MAX_PRIORITY);
				logger.debug`Allowed priority: ${this.allowedPriority}. Remaining tasks: ${this.queue.size}`;
				this.runTasks();
			});
		}
	}

	push({ id, priority, task, _delayHydration = false }: PriorityTask<T>): Promise<T> {
		if (this.disabled) priority = LoadingPriority.PAINT;
		if (_delayHydration && !this.serverMarkupHydratedCountMap.has(id)) {
			this.serverMarkupHydratedCountMap.set(id, 0);
		}

		if (
			_delayHydration &&
			//totalTasksHydratedAtOnce acts as a switch to turn on/off hydration delay
			window.__SSR_RENDERED__ &&
			this.totalTasksHydratedAtOncePerTaskId > 0 &&
			this.serverMarkupHydratedCountMap.has(id)
		) {
			if (this.serverMarkupHydratedCountMap.get(id)! >= this.totalTasksHydratedAtOncePerTaskId) {
				return new Promise((resolve, reject) => {
					const timer = setTimeout(() => {
						this.push({ id, priority, task, _delayHydration })
							.then(resolve, reject)
							.finally(() => clearTimeout(timer));
					}, 1);
				});
			}
		}

		const futureTask = createFutureTask(`${id}:${priority}`, task, this.maxQueueTimeMs);
		const QueueItem: QueueItem<T> = {
			id,
			futureTask,
			priority,
			_delayHydration,
		};

		this.queue.add(QueueItem, QueueItem.priority);
		if (
			_delayHydration &&
			//totalTasksHydratedAtOnce acts as a switch to turn on/off hydration delay
			window.__SSR_RENDERED__ &&
			this.totalTasksHydratedAtOncePerTaskId > 0 &&
			this.serverMarkupHydratedCountMap.has(id)
		) {
			this.serverMarkupHydratedCountMap.set(id, this.serverMarkupHydratedCountMap.get(id)! + 1);
		}

		if (priority === LoadingPriority.PAINT) {
			QueueItem.futureTask.run();
		}
		this.runTasks();

		return futureTask.result;
	}

	reset() {
		this.allowedPriority = LoadingPriority.PAINT;
	}
}

let taskManager: any;
export const getTaskManager = <T>(options?: TaskManagerOptions): TaskManager<T> => {
	if (!taskManager) {
		taskManager = new TaskManager<T>({
			disabled: process.env.NODE_ENV === 'testing',
			...options,
		});
	}
	return taskManager;
};
