import React from 'react';
import type { DataProxy } from 'apollo-cache';
import { ApolloError } from 'apollo-client';
import { defineMessages, FormattedMessage } from 'react-intl-next';

/* eslint-disable-next-line import/no-extraneous-dependencies */
import { isDuplicateSpaceKeyError } from '@confluence/create-space/entry-points/isDuplicateSpaceKeyError';
import { Attribution, isUnauthorizedError } from '@confluence/error-boundary';
import type { FlagsStateContainer } from '@confluence/flags';
import { getApolloClient, markErrorAsHandled } from '@confluence/graphql';
import { getMonitoringClient } from '@confluence/monitoring';
import { ADMIN_NEW_GLOBAL_PERMISSIONS } from '@confluence/named-routes';
import { FrontCoverStateEnum } from '@confluence/route-manager/entry-points/companyHubUtils';

import { checkIfCompanyHubSpaceExists } from './checkIfCompanyHubSpaceExists';
import type {
	CreateCompanyHubSpaceMutation as CreateCompanyHubSpaceMutationType,
	CreateCompanyHubSpaceMutationVariables,
} from './__types__/CreateCompanyHubSpaceMutation';
import { SystemSpaceHomepageTemplate } from './__types__/CreateCompanyHubSpaceMutation';
import { CreateCompanyHubSpaceMutation } from './CreateCompanyHubSpaceMutation.graphql';
import { SiteConfigurationQuery } from './SiteConfigurationQuery.graphql';
import type { SiteConfigurationQuery as SiteConfigurationQueryType } from './__types__/SiteConfigurationQuery';

/* eslint-disable no-restricted-syntax */
// Linter wants to use curly quotes, but we need to match the error message
export const STALE_PERMISSIONS_ERROR_MESSAGE =
	"User doesn't have permission to create system space";

export const hasStalePermissionsErrorMessage = (error: ApolloError): boolean =>
	hasNestedApolloErrorMessage(error, STALE_PERMISSIONS_ERROR_MESSAGE);

const hasNestedApolloErrorMessage = (error: ApolloError, errorMessage: string): boolean => {
	return error?.graphQLErrors?.[0]?.extensions?.data?.errors?.some(
		(
			err:
				| { message: { translation: string[] | null | undefined } | null | undefined }
				| null
				| undefined,
		) => err?.message?.translation?.includes(errorMessage),
	);
};

const getErrorFlagConfig = (
	title: JSX.Element,
	description: JSX.Element,
	actions: { content: JSX.Element; onClick?: () => void; href?: string }[] = [],
	id?: string,
) => ({
	id,
	title,
	description,
	actions,
});

const showStalePermissionsErrorFlag = (flags: FlagsStateContainer) => {
	const title = <FormattedMessage {...i18n.createCompanyHubSpaceStalePermissionsErrorFlagTitle} />;
	const description = (
		<FormattedMessage
			{...i18n.createCompanyHubSpaceStalePermissionsErrorFlagDescription}
			values={{
				a: (...chunks: React.ReactNode[]) => (
					<a
						href="https://support.atlassian.com/confluence-cloud/docs/create-and-customize-a-company-hub"
						target="_blank"
					>
						{chunks}
					</a>
				),
			}}
		/>
	);
	const actions = [
		{
			content: <FormattedMessage {...i18n.createCompanyHubSpaceStalePermissionsErrorFlagButton} />,
			onClick: () => window.location.reload(),
		},
	];

	void flags.showErrorFlag(getErrorFlagConfig(title, description, actions));
};

const showUnauthorizedErrorFlag = (flags: FlagsStateContainer) => {
	const id = 'create-system-space-permissions-failure';
	const title = <FormattedMessage {...i18n.createCompanyHubSpacePermissionsErrorFlagTitle} />;
	const description = (
		<FormattedMessage
			{...i18n.createCompanyHubSpacePermissionsErrorFlagDescription}
			values={{
				b: (...chunks) => <b>{chunks}</b>,
			}}
		/>
	);
	const actions = [
		{
			content: <FormattedMessage {...i18n.createCompanyHubSpacePermissionsErrorFlagButton} />,
			href: ADMIN_NEW_GLOBAL_PERMISSIONS.toUrl(),
		},
	];

	void flags.showErrorFlag(getErrorFlagConfig(title, description, actions, id));
};

const showGenericErrorFlag = (flags: FlagsStateContainer) => {
	const id = 'create-system-space-failure';
	const title = <FormattedMessage {...i18n.createCompanyHubSpaceErrorFlagTitle} />;
	const description = <FormattedMessage {...i18n.createCompanyHubSpaceErrorFlagDescription} />;

	void flags.showErrorFlag(getErrorFlagConfig(title, description, [], id));
};
// eslint-disable-next-line check-i18n-id-prefix/i18n-id-prefix-with-package-name-defineMessages -- To not throw away existing translations when the concept "Company Home Builder" was obsoleted
export const i18n = defineMessages({
	createCompanyHubSpaceErrorFlagTitle: {
		id: 'company-home-builder.company-hub-space-create-modal.create-system-space-error-flag-title',
		description: 'Generic flag title to inform user creating system space failed',
		defaultMessage: 'Something went wrong',
	},
	createCompanyHubSpaceErrorFlagDescription: {
		id: 'company-home-builder.company-hub-space-create-modal.create-system-space-error-flag-description',
		description: 'Generic flag description to inform user creating system space failed',
		defaultMessage: 'Try again to continue setting up your landing page.',
	},
	createCompanyHubSpacePermissionsErrorFlagTitle: {
		id: 'company-home-builder.company-hub-space-create-modal.create-system-space-permissions-error-flag-title',
		description: 'Flag title to inform user creating system space failed due to permissions error',
		defaultMessage: 'You need permission',
	},
	createCompanyHubSpacePermissionsErrorFlagDescription: {
		id: 'company-home-builder.company-hub-space-create-modal.create-system-space-permissions-error-flag-description',
		description:
			'Flag description to inform user creating system space failed due to permissions error',
		defaultMessage: 'You need <b>Create space</b> permissions to create this hub.',
	},
	createCompanyHubSpacePermissionsErrorFlagButton: {
		id: 'company-home-builder.company-hub-space-create-modal.create-system-space-permissions-error-flag-button',
		description:
			'Button to direct the user to Global Permissions page to enable create space permissions',
		defaultMessage: 'Go to permissions',
	},
	createCompanyHubSpaceStalePermissionsErrorFlagTitle: {
		id: 'company-home-builder.company-hub-space-create-modal.create-system-space-stale-permissions-error-flag-title',
		description:
			'Flag title to inform user creating system space failed due to a stale permissions error',
		defaultMessage: 'We couldn’t create your hub',
	},
	createCompanyHubSpaceStalePermissionsErrorFlagDescription: {
		id: 'company-home-builder.company-hub-space-create-modal.create-system-space-stale-permissions-error-flag-description',
		description:
			'Flag description that informs user that they do not have permissions to create a Company hub.',
		defaultMessage: 'Only product admins can create and activate a <a>Company hub</a>',
	},
	createCompanyHubSpaceStalePermissionsErrorFlagButton: {
		id: 'company-home-builder.company-hub-space-create-modal.create-system-space-stale-permissions-error-flag-button',
		description: 'Button label that reloads the current page for the user',
		defaultMessage: 'Got it',
	},
});

export type CreateCompanyHubSpaceResult = {
	didCreateSpace: boolean;
	homepageId: string | null | undefined;
};
/**
 * @returns {boolean} False if there is already a hub space previously created in the instance
 */
export async function createCompanyHubSpace(
	flags?: FlagsStateContainer,
): Promise<CreateCompanyHubSpaceResult> {
	const client = getApolloClient();

	try {
		const hasHubSpace = await checkIfCompanyHubSpaceExists();

		if (!hasHubSpace) {
			const { data, errors } = await client.mutate<
				CreateCompanyHubSpaceMutationType,
				CreateCompanyHubSpaceMutationVariables
			>({
				mutation: CreateCompanyHubSpaceMutation,
				variables: {
					input: {
						systemSpaceHomepageTemplate: SystemSpaceHomepageTemplate.EAP,
					},
				},
				update: (cache, { data }) => {
					const companyHubName = data?.createSystemSpace?.name;
					if (!companyHubName) return;

					updateSiteConfigurationCache(cache, companyHubName);
				},
			});
			if (errors?.length) {
				throw new ApolloError({ graphQLErrors: errors });
			}
			if (data?.createSystemSpace?.id != null) {
				updateFrontCoverStateCache(client);
			}
			return {
				didCreateSpace: true,
				homepageId: data?.createSystemSpace?.homepageId,
			};
		}

		updateFrontCoverStateCache(client);
		return {
			didCreateSpace: false,
			homepageId: undefined,
		};
	} catch (error) {
		if (isDuplicateSpaceKeyError(error)) {
			markErrorAsHandled(error);
			updateFrontCoverStateCache(client);
			return {
				didCreateSpace: false,
				homepageId: undefined,
			};
		}

		if (flags) {
			if (hasStalePermissionsErrorMessage(error)) {
				showStalePermissionsErrorFlag(flags);
			} else if (isUnauthorizedError(error)) {
				showUnauthorizedErrorFlag(flags);
			} else {
				showGenericErrorFlag(flags);
			}
		}

		getMonitoringClient().submitError(error, {
			attribution: Attribution.COMPANY_HUB,
		});
		markErrorAsHandled(error);

		throw error;
	}
}

function updateFrontCoverStateCache(cache: DataProxy) {
	updateSiteConfigurationCache(cache, null, FrontCoverStateEnum.EDITORS_ONLY);
}

function updateSiteConfigurationCache(
	cache: DataProxy,
	companyHubName?: string | null,
	frontCoverState?: FrontCoverStateEnum | null,
) {
	cache.writeQuery<SiteConfigurationQueryType>({
		query: SiteConfigurationQuery,
		data: {
			siteSettings: {
				// Codegen is specifically configured to not add __typename when generating the types file,
				// so unfortunately ts-ignore is required here.
				// @ts-ignore
				__typename: 'SiteSettings',
				...(companyHubName ? { companyHubName } : {}),
				...(frontCoverState
					? {
							frontCover: {
								frontCoverState,
								// Codegen is specifically configured to not add __typename when generating the types file,
								// so unfortunately ts-ignore is required here.
								// @ts-ignore
								__typename: 'FrontCover',
							},
						}
					: {}),
			},
		},
	});
}
