import { query } from '@confluence/query-preloader-tools';

import { ByLineLastUpdatedQuery } from './by-line/ByLineLastUpdatedQuery.graphql';
import type {
	ByLineLastUpdatedQuery as ByLineLastUpdatedQueryType,
	ByLineLastUpdatedQueryVariables,
} from './by-line/__types__/ByLineLastUpdatedQuery';

export function preloadLastUpdated(contentId: any, useCache: any) {
	return query<ByLineLastUpdatedQueryType, ByLineLastUpdatedQueryVariables>({
		query: ByLineLastUpdatedQuery,
		variables: {
			contentId,
		},
		fetchPolicy: useCache ? 'cache-first' : 'network-only',
	});
}
