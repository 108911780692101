import type { FC } from 'react';
import React from 'react';

import { type ForgeUIExtensionType } from '@atlassian/forge-ui-types';
import SectionMessage, { SectionMessageAction } from '@atlaskit/section-message';
import { getATLContextDomain } from '@atlassian/atl-context';
import { Text } from '@atlaskit/primitives';
import { FormattedMessage, useIntl } from 'react-intl-next';
import { messages } from './i18n';

type BlockedMacroSectionMessageProps = {
	extensionName?: string;
};

export const BlockedMacroSectionMessage: FC<BlockedMacroSectionMessageProps> = ({
	extensionName,
}) => {
	const intl = useIntl();

	const sharedFormattedMessageValues = {
		strong: (chunks: React.ReactNode[]) => <Text as="strong">{chunks}</Text>,
	};

	const formattedMessage = extensionName
		? intl.formatMessage(messages.blockedMacroBodyWithAppName, {
				extensionName,
				...sharedFormattedMessageValues,
			})
		: intl.formatMessage(messages.blockedMacroBodyWithoutAppName, {
				...sharedFormattedMessageValues,
			});

	const identityDomain = getATLContextDomain('id');
	const learnMoreURL = `https://${identityDomain}/manage-profile/apps`;

	return (
		<SectionMessage
			title={intl.formatMessage(messages.blockedMacroTitle)}
			actions={[
				<SectionMessageAction href={learnMoreURL}>
					<FormattedMessage {...messages.blockedMacroLearnMore} />
				</SectionMessageAction>,
			]}
		>
			<Text as="p">{formattedMessage}</Text>
		</SectionMessage>
	);
};

interface RenderBlockedMacroExtension {
	extension: ForgeUIExtensionType;
}

export const renderBlockedMacroExtension =
	({ extension }: RenderBlockedMacroExtension) =>
	(): JSX.Element => <BlockedMacroSectionMessage extensionName={extension.properties.title} />;

export const shouldBlockExtensionDueToAppAccessPolicy = (extension: ForgeUIExtensionType) =>
	extension.type === 'xen:macro' &&
	extension.dataClassificationPolicyDecision?.status === 'BLOCKED';
