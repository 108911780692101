import { getMonitoringClient } from '@confluence/monitoring';
import { fg } from '@confluence/feature-gating';
import { getLogger } from '@confluence/logger';

import { getTaskManager } from './TaskManager';
import { preloadIfNeeded } from './preloader';
import type { DefinitionType, ModuleType, LoadableType, LoadableExportType } from './types';
import { LoadingPriority } from './constants';
import { JS_LOAD_START, JS_LOAD_END } from './loadablePerformanceMarks';
import { hasBeenLoaded, saveHasBeenLoaded } from './loadStateCache';
import { createLoadableClientComponent } from './createLoadableClientComponent';
import { createLoadableServerComponent } from './createLoadableServerComponent';
import { shouldForceImportsForJest } from './shouldForceImportsForJest';

const logger = getLogger('Loadable');

function markLoadingStart(loadableName: string, priority: LoadingPriority) {
	const startMark = `${loadableName}(${priority})${JS_LOAD_START}`;

	if (
		// eslint-disable-next-line check-react-ssr-usage/no-react-ssr
		!process.env.REACT_SSR &&
		priority <= LoadingPriority.AFTER_PAINT &&
		performance?.getEntriesByName?.(startMark)?.length === 0
	) {
		performance.mark(startMark);
	}
}

function markLoadingEnd(loadableName: string, priority: LoadingPriority) {
	const endMark = `${loadableName}(${priority})${JS_LOAD_END}`;

	if (
		// eslint-disable-next-line check-react-ssr-usage/no-react-ssr
		!process.env.REACT_SSR &&
		priority <= LoadingPriority.AFTER_PAINT &&
		performance?.getEntriesByName?.(endMark)?.length === 0
	) {
		performance.mark(endMark);

		if (process.env.NODE_ENV !== 'production') {
			performance.measure(
				`${loadableName}(${priority})`,
				`${loadableName}(${priority})${JS_LOAD_START}`,
				endMark,
			);
		}
	}
}

export function createLoadable<P extends {}>(priority: number) {
	return (definition: DefinitionType<P>): LoadableType<P> => {
		const { __loadable_id__, loader, name } = definition;
		const loadableName = name || __loadable_id__ || 'unknown';
		const displayName = `Loadable(${loadableName})`;
		const loadedModuleRef: { ref: ModuleType<P> } = { ref: null };
		const totalHydratedAtOnce = 10;
		/**
		 * DON'T CACHE THE LOADER RESULT OR PROMISE
		 * There are quite a few places that calls function defined in outer scope like packages/confluence-fabric-editor/src/components/EditorLoader/Loader.tsx
		 * There is no guarantee they won't change and loader result is consistent. Caching the result might lead to bugs.
		 * We can theoretically run babel plugin to find out these cases and exclude them https://stash.atlassian.com/projects/CONFCLOUD/repos/confluence-frontend/commits/6ab47236eec2fa7c4a7542ad05b8c45964443ba1
		 * However I don't feel worth the effort of maintaining another piece of logic in babel plugin and risk of missing out the side effects in loader function.
		 * So I am leaving it as it is.
		 */
		const cleanup = () => {
			markLoadingEnd(loadableName, priority);
			saveHasBeenLoaded(__loadable_id__);
		};
		const runLoader = (): Promise<ModuleType<P> | Error> => {
			markLoadingStart(loadableName, priority);
			return loader().then(
				(module) => {
					cleanup();
					return (loadedModuleRef.ref = (module as any)?.default || module);
				},
				// At the time when a promise is rejected, it must already been attached with a catch.
				// However it is not the case when running in the task manager.
				// Because a task can return a promise or a value. We have to check before attaching a catch.
				// This makes the rejection unhandled. So we are converting it to a resolved promise with an error.
				// The error object will be handled later in LoadableComponent.
				(e: Error) => {
					cleanup();
					// eslint-disable-next-line check-react-ssr-usage/no-react-ssr
					if (process.env.REACT_SSR) {
						let extra: string[] = [];
						if (e.message.includes('randomBytes')) {
							extra = [
								'==IMPORTANT==',
								"It seems like you are using 'randomBytes' or `uuid` please make sure they are called lazily.",
								'This means the function call should not be executed when the module is imported by another module.',
								'This is because the SSR environment pre-compiles all the modules.',
								"During pre-compile (or we call init) phase it doesn't have access to Nodejs libraries like 'crypto'.",
								'It is OK to call them in the running phase, eg: in side React component',
							];
						}

						const error = new Error();
						// Only stack is logged so adding the extra message to stack
						error.stack =
							[
								`Error when calling load() of "${loadableName}" in SSR App bundle's init().`,
								...extra,
								'Please check with #cc-fe-performance if you need help with this.\n',
							].join('\n') + e.stack;
						throw error;
					} else {
						if (fg('confluence_frontend_surface_loadable_errors')) {
							getMonitoringClient().submitError(e, {
								attribution: 'backbone-loadable',
							});
							logger.error`Error loading loadable component ${loadableName}. Please check with #cc-fe-performance if you need help with this.\n`;
						}
						return e;
					}
				},
			);
		};
		const load = (
			priorityOverride: number = -1,
			isHydrating: boolean = false,
		): Promise<ModuleType<P> | Error> => {
			if (loadedModuleRef.ref) {
				return Promise.resolve(loadedModuleRef.ref);
			}

			// eslint-disable-next-line check-react-ssr-usage/no-react-ssr
			return process.env.REACT_SSR || hasBeenLoaded(__loadable_id__)
				? runLoader()
				: getTaskManager<ModuleType<P> | Error>({
						totalHydratedAtOnce,
					}).push({
						id: __loadable_id__!,
						priority: priorityOverride >= LoadingPriority.PAINT ? priorityOverride : priority,
						task: runLoader,
						_delayHydration: isHydrating,
					});
		};

		if (shouldForceImportsForJest()) {
			void runLoader();
		} else if (window['__SSR_RENDERED__']) {
			/*
			 * Preload dynamic split when:
			 * - In React SSR mode
			 * - id of the component is in the magical __LOADABLE__ array
			 * Note: __LOADABLE__ array is produced by React SSR server where server knows what loadable has been used on the server side.
			 * Client will preload everything in __LOADABLE__ so component do not flip back to the loading state.
			 */
			preloadIfNeeded(__loadable_id__, name, () => load(LoadingPriority.PAINT));
		}

		// eslint-disable-next-line check-react-ssr-usage/no-react-ssr
		if (process.env.REACT_SSR) {
			return createLoadableServerComponent(definition, {
				displayName,
				loadableName,
				priority,
				load,
				loadedModuleRef,
			});
		}

		return createLoadableClientComponent(definition, {
			displayName,
			loadableName,
			priority,
			load,
			loadedModuleRef,
		});
	};
}

/** https://pug.jira-dev.com/wiki/spaces/CFE/blog/20778976578/Introducing%2Bphased%2Bloadable */
export const LoadablePaint: LoadableExportType = createLoadable(LoadingPriority.PAINT);

/** https://pug.jira-dev.com/wiki/spaces/CFE/blog/20778976578/Introducing%2Bphased%2Bloadable */
export const LoadableAfterPaint: LoadableExportType = createLoadable(LoadingPriority.AFTER_PAINT);

/** https://pug.jira-dev.com/wiki/spaces/CFE/blog/20778976578/Introducing%2Bphased%2Bloadable */
export const LoadableLazy: LoadableExportType = createLoadable(LoadingPriority.LAZY);

/** https://pug.jira-dev.com/wiki/spaces/CFE/blog/20778976578/Introducing%2Bphased%2Bloadable */
export const LoadableBackground: LoadableExportType = createLoadable(LoadingPriority.BACKGROUND);

export const LoadableHydrateOnHover: LoadableExportType = createLoadable(
	LoadingPriority.HYDRATE_ON_HOVER,
);
