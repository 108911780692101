import React from 'react';

import type { RouteMatch } from '@confluence/route';

import { CONTAINER_HOME_ID } from '@confluence/app-navigation/entry-points/navigationIDs';
import { LoadablePaint } from '@confluence/loadable';

const QuestionsRouteComponent = LoadablePaint({
	loader: () =>
		import(/* webpackChunkName: "loadable-QuestionsRouteComponent" */ './QuestionsRouteComponent'),
});

export const QuestionsRoute = ({ params: { spaceKey } }: RouteMatch) => {
	return <QuestionsRouteComponent spaceKey={spaceKey} />;
};

QuestionsRoute.NAVIGATION_PARAMS = ({ params: { spaceKey } }: RouteMatch) => ({
	Screen: {
		screenEvent: {
			name: 'questionsScreen',
		},
		pageState: {
			spaceKey,
		},
	},
	MainLayout: {
		navView: CONTAINER_HOME_ID,
		spaceKey,
	},
});
