import React from 'react';

import AKIcon, { type IconProps as AKIconProps, type CustomGlyphProps } from '@atlaskit/icon';
import TextIcon from '@atlaskit/icon/core/text';
import { token } from '@atlaskit/tokens';

const IconGlyph = (props: CustomGlyphProps) => (
	<svg
		width="24"
		height="24"
		viewBox="0 0 24 24"
		fill="currentColor"
		xmlns="http://www.w3.org/2000/svg"
		role="presentation"
		// Ignored via go/ees005
		// eslint-disable-next-line react/jsx-props-no-spreading
		{...props}
	>
		<path d="M13 8C13 7.44772 12.5523 7 12 7C11.4477 7 11 7.44772 11 8V17C11 17.5523 11.4477 18 12 18C12.5523 18 13 17.5523 13 17V8Z" />
		<path d="M17 6H7C6.44772 6 6 6.44772 6 7C6 7.55228 6.44772 8 7 8H17C17.5523 8 18 7.55228 18 7C18 6.44772 17.5523 6 17 6Z" />
	</svg>
);

export const SuggestTitleIcon = () => (
	<TextIcon
		label=""
		color="currentColor"
		spacing="spacious"
		// Ignored via go/ees005
		// eslint-disable-next-line react/jsx-props-no-spreading
		LEGACY_fallbackIcon={(props: AKIconProps) => <AKIcon glyph={IconGlyph} {...props} />}
		LEGACY_secondaryColor={token('color.icon')}
	/>
);
