import { query, getPreloaderFnContext } from '@confluence/query-preloader-tools';

import { GetUserStateQuery } from './graphql/GetUserStateQuery.graphql';
import type { GetUserState } from './graphql/__types__/GetUserStateQuery';

export async function preloadUserState() {
	const { userId } = await getPreloaderFnContext();
	if (!userId) {
		return;
	}
	return query<GetUserState, null>({
		query: GetUserStateQuery,
	});
}
