/** @jsx jsx */
import React, { useMemo, useCallback, useState } from 'react';
import { jsx } from '@compiled/react';
import type { SingleValue } from 'react-select';

import type { SingleValueProps, OptionProps, OptionType } from '@atlaskit/select';
import Select, { components } from '@atlaskit/select';
import { xcss, Box, Text } from '@atlaskit/primitives';
import { useAnalyticsEvents } from '@atlaskit/analytics-next';

import type { SpaceRole } from '../model/space-roles-types';
interface Option {
	id: string;
	label: string;
	value: string;
	description: string;
}

interface RoleSelectorProps {
	initialRoleId?: string | null;
	value?: string | null | OptionType;
	isLoading?: boolean;
	placeholder?: React.ReactNode;
	onChange?: (role: SpaceRole) => void;
	spaceRoleOptions: Pick<SpaceRole, 'id' | 'name' | 'description'>[];
	onMenuOpen?: () => void;
	onMenuClose?: () => void;
	menuPlacement?: 'auto' | 'bottom' | 'top';
	appearance?: 'none' | 'default' | 'subtle';
}

const menuItemStyle = xcss({
	display: 'flex',
	flexDirection: 'column',
	gap: 'space.050',
});

export const RoleSelector: React.FC<RoleSelectorProps> = ({
	initialRoleId,
	value,
	isLoading,
	onChange,
	placeholder,
	spaceRoleOptions,
	onMenuClose,
	onMenuOpen,
	menuPlacement = 'auto',
	appearance = 'subtle',
}) => {
	const [internalVal, setInternalVal] = useState<string | OptionType | null>(
		(value === undefined ? initialRoleId : value) || null,
	);
	const { createAnalyticsEvent } = useAnalyticsEvents();

	// The component is controlled if value is not `undefined`. `initialRole` will ignored in controlled mode.
	const isControlledComponent = value !== undefined;

	const options: Option[] = useMemo(
		() =>
			spaceRoleOptions.map((role) => ({
				id: role?.id ?? '',
				label: role?.name ?? '',
				value: role?.id ?? '',
				description: role?.description ?? '',
			})),
		[spaceRoleOptions],
	);

	const getOptionFromValue = useCallback(
		(val: OptionType | string | null | undefined): Option | null => {
			if (!val) return null;

			return typeof val === 'string'
				? options.find((option) => option.value === val) || null
				: {
						id: val.value.toString(),
						label: val.label,
						value: val.value.toString(),
						description: '',
					};
		},
		[options],
	);

	const CustomSingleValue = React.memo((props: SingleValueProps<Option>) => (
		<components.SingleValue {...props}>{props.data.label}</components.SingleValue>
	));

	const CustomOption = React.memo((props: OptionProps<Option>) => (
		<components.Option {...props}>
			<Box xcss={menuItemStyle}>
				<Text>{props.data.label}</Text>
				<Text color="color.text.subtlest">{props.data.description}</Text>
			</Box>
		</components.Option>
	));

	const handleChange = (newSelection: SingleValue<Option>) => {
		createAnalyticsEvent({
			type: 'sendUIEvent',
			data: {
				actionSubject: 'button',
				action: 'clicked',
				actionSubjectId: 'change',
				source: 'spaceRoles-roleSelector',
				attributes: { newSelection: newSelection?.label },
			},
		}).fire();

		setInternalVal(newSelection?.value || null);
		onChange?.(spaceRoleOptions.find((role) => role?.id === newSelection?.value) as SpaceRole);
	};

	const handleMenuOpen = (currentRoleSelection: Option | null) => {
		onMenuOpen?.();
		createAnalyticsEvent({
			type: 'sendUIEvent',
			data: {
				actionSubject: 'button',
				action: 'clicked',
				actionSubjectId: 'open',
				source: 'spaceRoles-roleSelector',
				attributes: { currentRoleSelection: currentRoleSelection?.label },
			},
		}).fire();
	};

	const SELECT_STYLE_PROPS = {
		isSearchable: false,
		styles: {
			menu: (base: any) => ({
				...base,
				width: '320px',
				padding: 0,
				margin: 0,
			}),
		},
	};

	const selectValue = isControlledComponent
		? getOptionFromValue(value)
		: getOptionFromValue(internalVal);

	return (
		<Select
			data-testid="space-roles-table-role-selector"
			placeholder={isLoading ? '' : placeholder}
			options={options}
			appearance={appearance}
			isLoading={isLoading}
			onChange={handleChange}
			onMenuOpen={() =>
				handleMenuOpen(getOptionFromValue(isControlledComponent ? value : internalVal))
			}
			onMenuClose={onMenuClose}
			components={{ SingleValue: CustomSingleValue, Option: CustomOption }}
			menuPosition="fixed"
			menuPlacement={menuPlacement}
			maxMenuHeight={320}
			scrollBehavior="outside"
			defaultValue={getOptionFromValue(initialRoleId)}
			value={selectValue}
			isOptionSelected={(option) => option.value === selectValue?.value}
			{...SELECT_STYLE_PROPS}
		/>
	);
};
