import gql from 'graphql-tag';

import { WatchDialogContentFragment } from '@confluence/watch-dialog/entry-points/WatchDialogContent.fragment';

const PageCardSearchResult = gql`
	fragment PageCardSearchResult on ConfluenceSearchResult {
		... on ConfluenceContentSearchResult @include(if: $isLicensed) {
			watchDialogContent: content {
				...WatchDialogContentFragment
			}
		}
		... on ConfluenceContentSearchResult {
			id
			title
			url
			excerpt
			lastModified {
				value
			}
			content {
				id
				type
				subType
				status
				blank
				links {
					editui
				}
				emoji: properties(keys: ["emoji-title-published", "emoji-title-draft"]) {
					nodes {
						id
						key
						value
					}
				}
				editorVersion: properties(key: "editor") {
					nodes {
						id
						key
						value
					}
				}
				space {
					id
					key
					alias
					name
				}
				history {
					createdBy {
						profilePicture {
							path
						}
						displayName
						... on KnownUser {
							accountId
						}
						... on User {
							accountId
						}
					}
					ownedBy {
						profilePicture {
							path
						}
						displayName
						... on KnownUser {
							accountId
						}
						... on User {
							accountId
						}
					}
				}
				metadata {
					currentuser {
						favourited {
							isFavourite
						}
					}
					comments {
						commentsCount
					}
				}
				likes {
					count
				}
				version {
					by {
						profilePicture {
							path
						}
						displayName
						... on KnownUser {
							accountId
						}
						... on User {
							accountId
						}
					}
				}
			}
		}
	}
`;

export const SpacePagesQuery = gql`
	query SpacePagesQuery(
		$searchText: String!
		$filters: ConfluenceSearchFilters!
		$first: Int!
		$maxNumberOfResults: Int!
		$token: String
		$sortBy: [ConfluenceContentSortField!]
		$isLicensed: Boolean!
		$includeRecommended: Boolean = false
		$spaceId: String = ""
		$scopes: [ConfluenceContentSearchScope!]!
	) @allowHigherTimeout {
		confluenceContentSearch(
			query: $searchText
			scopes: $scopes
			filters: $filters
			first: $first
			token: $token
			sort: $sortBy
			sessionAttributes: { experience: "confluence.spacePages" }
		) {
			nodes {
				...PageCardSearchResult
			}
			totalCount
			pageInfo {
				hasNextPage
				nextPageToken
			}
		}
		objectRecommendations(
			context: { containerId: $spaceId, product: "confluence" }
			modelRequestParams: { experience: "ConfSpacePages", caller: "confluence" }
			maxNumberOfResults: $maxNumberOfResults
		) @include(if: $includeRecommended) {
			nodes {
				id
				content {
					id
					title
					type
					subType
					status
					blank
					excerpt(length: 372)
					links {
						webui
						editui
					}
					emoji: properties(keys: ["emoji-title-published", "emoji-title-draft"]) {
						nodes {
							id
							key
							value
						}
					}
					editorVersion: properties(key: "editor") {
						nodes {
							id
							key
							value
						}
					}
					space {
						id
						key
						alias
						name
					}
					history {
						createdBy {
							profilePicture {
								path
							}
							displayName
							... on KnownUser {
								accountId
							}
							... on User {
								accountId
							}
						}
						ownedBy {
							profilePicture {
								path
							}
							displayName
							... on KnownUser {
								accountId
							}
							... on User {
								accountId
							}
						}
					}
					metadata {
						lastModifiedDate
						currentuser {
							favourited {
								isFavourite
							}
						}
						comments {
							commentsCount
						}
					}
					likes {
						count
					}
					version {
						by {
							profilePicture {
								path
							}
							displayName
							... on KnownUser {
								accountId
							}
							... on User {
								accountId
							}
						}
					}
				}
			}
		}
	}
	${PageCardSearchResult}
	${WatchDialogContentFragment}
`;
