import stringify from 'fast-json-stable-stringify';
import { gzipSync } from 'fflate';
import type { GQLExtension } from '../../../web-client/graphql/types';

export const getEncodedAppContext = (extension: GQLExtension): string => {
	const { egress = [], overrides = {}, installationConfig = [] } = extension;

	return gzipAndB64Encode({
		egress,
		overrides,
		installationConfig,
		hostname: window.location.hostname,
	});
};

const gzipAndB64Encode = (json: Record<string, any>): string => {
	const jsonString = stringify(json); // We use fast-json-stable-stringify to ensure that the JSON is deterministic

	try {
		const zippedData = gzipSync(new TextEncoder().encode(jsonString), {
			level: 9, // Max compression,
			mtime: 0, // We set the mtime to 0 to ensure that the output is deterministic
		});
		return btoa(String.fromCharCode(...zippedData));
	} catch (err: unknown) {
		// eslint-disable-next-line no-console
		console.error((err as Error).message);
	}

	return '';
};
