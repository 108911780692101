import React from 'react';

import { Onboarding } from '@confluence/onboarding';
import { Attribution, ErrorBoundary } from '@confluence/error-boundary';

export const OnboardingRoute = () => (
	<ErrorBoundary attribution={Attribution.CC_ONBOARDING}>
		<Onboarding />
	</ErrorBoundary>
);

OnboardingRoute.NAVIGATION_PARAMS = () => ({
	Screen: {
		screenEvent: { name: 'onboardingContainerScreen' },
	},
	MainLayout: false,
});
