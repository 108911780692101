import { type ComponentPropsConfig } from '../../../utils/codegen';

/**
 * A modal body is used to display the main content of a modal.
 */
export const componentPropsConfig: ComponentPropsConfig = {
	children: true,
	testId: true,
};

/**
 * THIS SECTION WAS CREATED VIA CODEGEN DO NOT MODIFY {@see http://go/af-codegen}
 *
 * This component was generated against an existing platform component.
 * If you are seeing lint errors in this file, it means that this file's dependencies have been modified and the component will need to be regenerated.
 *
 * Please view https://hello.atlassian.net/wiki/spaces/ECO/pages/3358130330/How+to+Regenerate+UI+Kit+Components for more information on how to proceed.
 *
 * For further questions, please ping the #help-forge-ui channel.
 *
 * @codegen <<SignedSource::c2314bb0b18dbb918f3873ab2268a4df>>
 * @codegenId #modal-body
 * @codegenCommand yarn workspace @atlassian/forge-ui codegen modal-body
 * @codegenDependency ../../../../../../../design-system/modal-dialog/src/modal-body.tsx <<SignedSource::38bdc3101c57748fdb828f0ff7b375cb>>
 * @codegenDependency ../../../../../../../design-system/modal-dialog/src/hooks.tsx <<SignedSource::5062e27591547b31bd9e5702d12d3685>>
 * @codegenDependency ../../../../../../../design-system/modal-dialog/src/internal/components/scroll-container.tsx <<SignedSource::ea6c2461f593a22022946cc576ecf54a>>
 * @codegenDependency ../../../../../../../design-system/modal-dialog/src/internal/hooks/use-scroll.tsx <<SignedSource::f12edad503dc45bae3887f1b1fbfe75c>>
 */
/* eslint @repo/internal/codegen/signed-source-integrity: "warn" */
import React from 'react';
import type { RenderFn } from '@atlassian/forge-ui-types';
import { ModalBody as PlatformModalBody } from '@atlaskit/modal-dialog';

type PlatformModalBodyProps = React.ComponentProps<typeof PlatformModalBody>;

export type ModalBodyProps = Pick<
  PlatformModalBodyProps,
  'children' | 'testId'
>;

/**
 * A modal body is used to display the main content of a modal.
 */
export const ModalBody = (props: Parameters<RenderFn>[0]) => {
  const {
    testId,
  } = props.forgeDoc.props as ModalBodyProps;
  return (
    <PlatformModalBody
      children={props.forgeDoc.children.map(c => <React.Fragment key={c.key}>{props.render(c)}</React.Fragment>)}
      testId={testId}
    />
  );
};
/**
 * @codegenEnd
 */
