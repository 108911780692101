import { HomeRecentWorkQuery } from './HomeRecentWorkQuery.graphql';
import { ActivitiesObjectType } from './__types__/HomeRecentWorkQuery';

export const getRecentWorkQueryOptions = ({
	cloudId,
	isLoggedIn,
	isWhiteboardViewedEnabled = false,
	isDatabaseEnabled = false,
	first = 10,
}: {
	cloudId: string;
	isLoggedIn: boolean;
	isWhiteboardViewedEnabled?: boolean;
	isDatabaseEnabled?: boolean;
	first?: number;
}) => ({
	query: HomeRecentWorkQuery,
	variables: {
		cloudId,
		first,
		after: '',
		objectTypes: [
			ActivitiesObjectType.PAGE,
			ActivitiesObjectType.BLOGPOST,
			ActivitiesObjectType.EMBED,
			...(isWhiteboardViewedEnabled ? [ActivitiesObjectType.WHITEBOARD] : []),
			...(isDatabaseEnabled ? [ActivitiesObjectType.DATABASE] : []),
		],
	},
	skip: !isLoggedIn,
});
