import React from 'react';
import AddonIcon from '@atlaskit/icon/core/migration/app--addon';
import { token } from '@atlaskit/tokens';
import { N60 } from '@atlaskit/theme/colors';

const Icon = ({ label = '', imgUrl }: { label: string; imgUrl?: string }) => {
	return imgUrl ? (
		<img data-testid="macro-custom-icon-img" alt={label} src={imgUrl} width="100%" />
	) : (
		<AddonIcon
			spacing="spacious"
			label={label}
			color={token('color.icon.accent.gray', N60)}
			LEGACY_size="large"
		/>
	);
};

export default Icon;
