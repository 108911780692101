// Local / client-side FF overrides are deprecated in favor of persisting
// overrides to the server. The functionality in this file will remain available
// for devs who have existing locally-stored FF overrides, but may be removed
// in a future update.
// Users may move their locally-stored FF overrides to the server by going to
// Confluence Devtools and clicking the "Move my overrides" button.
export const OVERRIDES_STORAGE_KEY = 'confluence-frontend-next.featureFlagLocalOverrides';

// check if localStorage is supported for a current browser/user
const checkLocalStorageSupport = () => {
	try {
		const testKey = '__random__local__storage__test__key__';
		// eslint-disable-next-line check-set-storage-usage/no-unimported-storage-set
		window.localStorage.setItem(testKey, testKey);
		window.localStorage.removeItem(testKey);
		return true;
	} catch (e) {
		return false;
	}
};

const isLocalStorageSupported = checkLocalStorageSupport();

const getOverrides = (): { [flagName: string]: boolean | string } => {
	let raw: string | null;

	if (isLocalStorageSupported) {
		raw = window.localStorage.getItem(OVERRIDES_STORAGE_KEY);
	} else {
		//fall back to sessionStorage if localStorage is not accessible
		raw = window.sessionStorage.getItem(OVERRIDES_STORAGE_KEY);
	}

	try {
		return raw ? JSON.parse(raw) : {};
	} catch (_) {
		return {};
	}
};

const hasOverrides = () => {
	//fall back to sessionStorage if localStorage is not accessible
	return isLocalStorageSupported
		? window.localStorage.getItem(OVERRIDES_STORAGE_KEY) != null
		: window.sessionStorage.getItem(OVERRIDES_STORAGE_KEY) != null;
};

/**
 * Remove all locally-stored FF overrides. When calling this function,
 * please make sure you've saved your overrides to the persisted store
 * if you don't want to lose them!
 */
export const removeAllLocalOverrides = () => {
	if (isLocalStorageSupported) {
		window.localStorage.removeItem(OVERRIDES_STORAGE_KEY);
	} else {
		//fall back to sessionStorage if localStorage is not accessible
		window.sessionStorage.removeItem(OVERRIDES_STORAGE_KEY);
	}
};

/**
 * Applies overrides onto the original, but only for keys already on the original
 * @deprecated Use applyPersistedOverrides()
 */
export const applyOverrides = <TObjectValue>(
	original: { [flagName: string]: TObjectValue },
	setterFn: (
		key: string,
		overrides: { [flagName: string]: string | boolean },
		original: { [flagName: string]: TObjectValue },
	) => TObjectValue,
): { [flagName: string]: TObjectValue } => {
	if (!hasOverrides()) {
		return original;
	}

	const overrides = getOverrides();

	return Object.keys(original).reduce<{ [flagName: string]: TObjectValue }>((result, key) => {
		result[key] = setterFn(key, overrides, original);
		return result;
	}, {});
};
