import React, { Fragment, type ReactNode } from 'react';

import { ScreenEvent } from '@confluence/analytics';
import { RestrictedSpace } from '@confluence/no-permission';
import { SpaceRestrictionCheck } from '@confluence/restrictions/entry-points/SpaceRestrictionCheck';
import { Space404 } from '@confluence/space-404';

type SpaceRestrictionHandlerProps = {
	children: ReactNode;
	spaceKey: string;
	isAnonymous?: boolean;
	contentId?: string;
	isFirstRoute?: boolean;
	goBackPreviousPage?: () => void;
};

export const SpaceRestrictionHandler: React.FC<SpaceRestrictionHandlerProps> = ({
	children,
	spaceKey,
	isAnonymous = false,
	contentId,
	isFirstRoute = false,
	goBackPreviousPage,
}) => {
	return (
		<SpaceRestrictionCheck spaceKey={spaceKey}>
			{({ isSpaceRestricted, isSpaceNotFound }) => {
				if (isSpaceRestricted) {
					return (
						<RestrictedSpace
							goToPreviousPage={goBackPreviousPage}
							isFirstRoute={isFirstRoute}
							contentId={contentId}
						/>
					);
				}

				if (isSpaceNotFound && !isAnonymous && !contentId) {
					return (
						<Fragment>
							<Space404 goBackPreviousPage={goBackPreviousPage} isFirstRoute={isFirstRoute} />
							<ScreenEvent name="spaceNotFoundScreen" id={spaceKey} />
						</Fragment>
					);
				}

				return <>{children}</>;
			}}
		</SpaceRestrictionCheck>
	);
};
