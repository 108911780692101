// The below lint rules are disabled because Pressable relies on building styles at runtime from in Forge apps
/* eslint-disable @atlaskit/design-system/consistent-css-prop-usage -- Ignored via go/DSP-18766 */
/* eslint-disable @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766 */
/* eslint-disable @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766 */

import { ComponentPropsConfig } from "../../../utils/codegen";
import { adaptEventHandler } from "../../../utils/adaptEventHandler";
import { xcssValidator, type XCSSProp } from '../../../utils/xcssValidator';
import { xcss as platformXCSS } from '@atlaskit/primitives';

type PlatformXCSSParam = Parameters<typeof platformXCSS>[0];
type PlatformPressableProps = React.ComponentProps<typeof PlatformPressable>;

/**
 * A pressable is a primitive for building custom buttons.
 */
export const componentPropsConfig: ComponentPropsConfig = {
  backgroundColor: true,
  "data-testid": false,
  children: true,
  isDisabled: true,
  onClick: { 
    value: 'adaptEventHandler(onClick)'
  },
  padding: true,
  paddingBlock: true,
  paddingBlockEnd: true,
  paddingBlockStart: true,
  paddingInline: true,
  paddingInlineEnd: true,
  paddingInlineStart: true,
  style: false,
  testId: true,
  xcss: {
		value: 'xcss && platformXCSS(xcssValidator(xcss) as PlatformXCSSParam)',
	},
}


export type PressableProps = Pick<PlatformPressableProps, 'children' | 'ref' | 'testId'> & {
  /**
   * Token representing background color with a built-in fallback value.
   */
  backgroundColor?: PlatformPressableProps['backgroundColor'],
  /**
   * Whether the button is disabled.
   */
  isDisabled?: PlatformPressableProps['isDisabled'],
  /**
   * Handler called on click.
   */
  onClick?: () => void,
  /**
   * Tokens representing CSS shorthand for `paddingBlock` and `paddingInline` together.
   *
   * @see paddingBlock
   * @see paddingInline
   */
  padding?: PlatformPressableProps['padding'],
  /**
   * Tokens representing CSS shorthand `paddingBlock`.
   *
   * @see paddingBlockStart
   * @see paddingBlockEnd
   */
  paddingBlock?: PlatformPressableProps['paddingBlock'],
  /**
   * Tokens representing CSS `paddingBlockEnd`.
   */
  paddingBlockEnd?: PlatformPressableProps['paddingBlockEnd'],
  /**
   * Tokens representing CSS `paddingBlockStart`.
   */
  paddingBlockStart?: PlatformPressableProps['paddingBlockStart'],
  /**
   * Tokens representing CSS shorthand `paddingInline`.
   *
   * @see paddingInlineStart
   * @see paddingInlineEnd
   */
  paddingInline?: PlatformPressableProps['paddingInline'],
  /**
   * Tokens representing CSS `paddingInlineEnd`.
   */
  paddingInlineEnd?: PlatformPressableProps['paddingInlineEnd'],
  /**
   * Tokens representing CSS `paddingInlineStart`.
   */
  paddingInlineStart?: PlatformPressableProps['paddingInlineStart'],
  /**
   * Apply a subset of permitted styles, powered by Atlassian Design System tokens.
   * For a list of supported style properties on this component, see [here](/platform/forge/ui-kit/components/xcss).
   *
   * @type XCSSProp
   */
  xcss?: XCSSProp;
}

/**
 * THIS SECTION WAS CREATED VIA CODEGEN DO NOT MODIFY {@see http://go/af-codegen}
 *
 * This component was generated against an existing platform component.
 * If you are seeing lint errors in this file, it means that this file's dependencies have been modified and the component will need to be regenerated.
 *
 * Please view https://hello.atlassian.net/wiki/spaces/ECO/pages/3358130330/How+to+Regenerate+UI+Kit+Components for more information on how to proceed.
 *
 * For further questions, please ping the #help-forge-ui channel.
 *
 * @codegen <<SignedSource::b9e819daedb99c749c62a194991babeb>>
 * @codegenId #pressable
 * @codegenCommand yarn workspace @atlassian/forge-ui codegen pressable
 * @codegenParams { "defineOwnType": true }
 * @codegenDependency ../../../../../../../design-system/primitives/src/components/types.tsx <<SignedSource::9529629bd4dfcf934c9dfc41516de683>>
 * @codegenDependency ../../../../../../../design-system/primitives/src/components/pressable.tsx <<SignedSource::072e6a2988d7b66f2945b45721548911>>
 * @codegenDependency ../../../../../../../design-system/primitives/src/xcss/style-maps.partial.tsx <<SignedSource::6b100f0da8b9d5aaa5db3cc730c8a2a5>>
 * @codegenDependency ../../../../../../../design-system/primitives/src/xcss/xcss.tsx <<SignedSource::4f9c0f527ba99a4723d89b2126ae70b1>>
 */
/* eslint @repo/internal/codegen/signed-source-integrity: "warn" */
import React from 'react';
import type { RenderFn } from '@atlassian/forge-ui-types';
import { Pressable as PlatformPressable } from '@atlaskit/primitives';

// Define the type for Pressable as PressableProps at the top of this file

/**
 * A pressable is a primitive for building custom buttons.
 */
export const Pressable = (props: Parameters<RenderFn>[0]) => {
  const {
    backgroundColor,
    isDisabled,
    onClick,
    padding,
    paddingBlock,
    paddingBlockEnd,
    paddingBlockStart,
    paddingInline,
    paddingInlineEnd,
    paddingInlineStart,
    testId,
    xcss,
  } = props.forgeDoc.props as PressableProps;
  return (
    <PlatformPressable
      backgroundColor={backgroundColor}
      children={props.forgeDoc.children.map(c => <React.Fragment key={c.key}>{props.render(c)}</React.Fragment>)}
      isDisabled={isDisabled}
      onClick={adaptEventHandler(onClick)}
      padding={padding}
      paddingBlock={paddingBlock}
      paddingBlockEnd={paddingBlockEnd}
      paddingBlockStart={paddingBlockStart}
      paddingInline={paddingInline}
      paddingInlineEnd={paddingInlineEnd}
      paddingInlineStart={paddingInlineStart}
      testId={testId}
      xcss={xcss && platformXCSS(xcssValidator(xcss) as PlatformXCSSParam)}
    />
  );
};
/**
 * @codegenEnd
 */