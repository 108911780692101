import { usePostOfficeRoute } from '@atlassian/post-office-context';
import { type MessageCategory } from '@post-office/placement-common';
import { PlacementPreviewProvider } from '@post-office/placement-preview';
import { type MessageCreationType } from '@post-office/shared-contracts';
import { ScreenSpaceFlagsPlacementComponent } from '@post-office-placement/screen-space-flags/placement';
import { ErrorBoundary } from 'react-error-boundary';

import type { PlacementPreviewProps } from './types';
import { Placement } from '../index';

/**
 * @typedef {Object} EligibleMessage
 * @property {string} messageInstanceId - The unique identifier for the message instance.
 * @property {string} messageTemplateId - The identifier for the message template.
 * @property {MessageCategory} messageCategory - The category of the message.
 * @property {string} [createdAt] - The creation timestamp of the message.
 * @property {string} [eventTime] - The event time associated with the message.
 * @property {SerializableRecord} context - The context in which the message is sent, as a serializable record.
 * @property {RecommendationSession} [recommendationSession] - The recommendation session details, if applicable.
 * @property {ReadStatus} [readStatus] - The read status of the message, if available.
 */

/**
 * @typedef {EligibleMessage[]} EligibleMessages
 * An array of EligibleMessage objects.
 */

/**
 * PlacementPreview allows you to explicitly a eligibleMessages from the frontend,
 * skipping message selection and filtering. This allow you ask a placement to show specific
 * messages using real hydration.
 *
 * @param {React.ReactNode} props.PlacementComponent - The placement component to be rendered.
 * @param {string} props.placementId - The placement id corresponding to to the PlacementComponent.
 * @param {EligibleMessages} props.eligibleMessages
 * @param {OnError} [props.onError] - You can use this to add custom error handling
 * @param {errorFallback} [props.errorFallback] - A react component to render in case of error, defaults to null
 *
 * @returns {JSX.Element}
 *
 * @example
 * // Define some example eligible messages
 * const eligibleMessages = [
 *   {
 *     messageInstanceId: 'msg-001',
 *     messageTemplateId: 'template-abc',
 *     messageCategory: ''Marketing',
 *     context: { userId: 'user-123', productId: 'prod-456' },
 *   }
 * ];
 *
 * // Use PlacementPreview to render the placement with the eligible messages
 * <PlacementPreview
 *    PlacementComponent={MyPlacementComponent}
 *    placementId="placement-123"
 *    eligibleMessages={eligibleMessages}
 *  />
 *
 **/
export const PlacementPreview = ({
	PlacementComponent,
	placementId,
	eligibleMessages,
	errorFallback = null,
	onError,
	returnOverride,
	disableInitialData,
}: PlacementPreviewProps): JSX.Element => (
	<ErrorBoundary fallback={errorFallback} onError={onError}>
		<PlacementPreviewProvider eligibleMessages={eligibleMessages} returnOverride={returnOverride}>
			<Placement
				PlacementComponent={PlacementComponent}
				placementId={placementId}
				disableInitialData={disableInitialData}
			/>
		</PlacementPreviewProvider>
	</ErrorBoundary>
);

export const ScreenSpaceFlagPlacementPreviewSwitcher = ({
	isEnabled,
	children,
}: {
	isEnabled: boolean;
	children: React.ReactNode;
}) => {
	const { searchParams } = usePostOfficeRoute();

	const previewId = searchParams?.get('contentfulPreviewId');
	const hasPreviewType = Boolean(searchParams?.get('contentfulPreviewId'));
	const eligibleMessage = [
		{
			messageInstanceId: 'messageInstanceId',
			messageTemplateId: 'marketing-screen-space-flag-contentful-usecase',
			messageCategory: 'Engagement' as MessageCategory,
			context: { id: previewId },
			createdAt: new Date().toString(),
			eventTime: new Date().toString(),
			triggerId: 'triggerId',
			messageCreationType: 'explicit' as MessageCreationType,
		},
	];

	if (isEnabled && hasPreviewType) {
		return (
			<PlacementPreview
				placementId="screen-space-flags"
				PlacementComponent={ScreenSpaceFlagsPlacementComponent}
				eligibleMessages={eligibleMessage}
			/>
		);
	}

	return <>{children}</>;
};
