import gql from 'graphql-tag';

export const SPAViewContextQuery = gql`
	query SPAViewContextQuery {
		isSiteAdmin
		abTestCohorts
		experimentFeatures
		userCanCreateContent
		isNewUser
		tenantContext {
			licenseStates {
				confluence {
					ccpEntitlementId
				}
			}
		}
		tenant {
			cloudId
		}
		siteSettings {
			homepage {
				uri
				title
			}
			siteTitle
			showApplicationTitle
			frontCover {
				frontCoverState
			}
			companyHubName
		}
		siteDescription {
			logoUrl
		}
		currentConfluenceUser {
			isAnonymous
		}
	}
`;
