/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum InspectPermissions {
  COMMENT = "COMMENT",
  EDIT = "EDIT",
  VIEW = "VIEW",
}

//==============================================================
// END Enums and Input Objects
//==============================================================

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: UserPermissionsQuery
// ====================================================

export interface UserPermissionsQuery_content_nodes_history_createdBy_Anonymous_profilePicture {
  path: string;
}

export interface UserPermissionsQuery_content_nodes_history_createdBy_Anonymous {
  displayName: string | null;
  profilePicture: UserPermissionsQuery_content_nodes_history_createdBy_Anonymous_profilePicture | null;
  type: string | null;
}

export interface UserPermissionsQuery_content_nodes_history_createdBy_KnownUser_profilePicture {
  path: string;
}

export interface UserPermissionsQuery_content_nodes_history_createdBy_KnownUser {
  displayName: string | null;
  profilePicture: UserPermissionsQuery_content_nodes_history_createdBy_KnownUser_profilePicture | null;
  type: string | null;
  accountId: string | null;
  accountType: string | null;
  email: string | null;
}

export interface UserPermissionsQuery_content_nodes_history_createdBy_User_profilePicture {
  path: string;
}

export interface UserPermissionsQuery_content_nodes_history_createdBy_User {
  displayName: string | null;
  profilePicture: UserPermissionsQuery_content_nodes_history_createdBy_User_profilePicture | null;
  type: string | null;
  accountId: string | null;
  accountType: string | null;
  email: string | null;
}

export type UserPermissionsQuery_content_nodes_history_createdBy = UserPermissionsQuery_content_nodes_history_createdBy_Anonymous | UserPermissionsQuery_content_nodes_history_createdBy_KnownUser | UserPermissionsQuery_content_nodes_history_createdBy_User;

export interface UserPermissionsQuery_content_nodes_history_ownedBy_Anonymous_profilePicture {
  path: string;
}

export interface UserPermissionsQuery_content_nodes_history_ownedBy_Anonymous {
  displayName: string | null;
  profilePicture: UserPermissionsQuery_content_nodes_history_ownedBy_Anonymous_profilePicture | null;
  type: string | null;
}

export interface UserPermissionsQuery_content_nodes_history_ownedBy_KnownUser_profilePicture {
  path: string;
}

export interface UserPermissionsQuery_content_nodes_history_ownedBy_KnownUser {
  displayName: string | null;
  profilePicture: UserPermissionsQuery_content_nodes_history_ownedBy_KnownUser_profilePicture | null;
  type: string | null;
  accountId: string | null;
  accountType: string | null;
  email: string | null;
}

export interface UserPermissionsQuery_content_nodes_history_ownedBy_User_profilePicture {
  path: string;
}

export interface UserPermissionsQuery_content_nodes_history_ownedBy_User {
  displayName: string | null;
  profilePicture: UserPermissionsQuery_content_nodes_history_ownedBy_User_profilePicture | null;
  type: string | null;
  accountId: string | null;
  accountType: string | null;
  email: string | null;
}

export type UserPermissionsQuery_content_nodes_history_ownedBy = UserPermissionsQuery_content_nodes_history_ownedBy_Anonymous | UserPermissionsQuery_content_nodes_history_ownedBy_KnownUser | UserPermissionsQuery_content_nodes_history_ownedBy_User;

export interface UserPermissionsQuery_content_nodes_history {
  createdBy: UserPermissionsQuery_content_nodes_history_createdBy | null;
  ownedBy: UserPermissionsQuery_content_nodes_history_ownedBy | null;
}

export interface UserPermissionsQuery_content_nodes_links {
  webui: string | null;
}

export interface UserPermissionsQuery_content_nodes_ancestors_history_createdBy_Anonymous_profilePicture {
  path: string;
}

export interface UserPermissionsQuery_content_nodes_ancestors_history_createdBy_Anonymous {
  displayName: string | null;
  profilePicture: UserPermissionsQuery_content_nodes_ancestors_history_createdBy_Anonymous_profilePicture | null;
  type: string | null;
}

export interface UserPermissionsQuery_content_nodes_ancestors_history_createdBy_KnownUser_profilePicture {
  path: string;
}

export interface UserPermissionsQuery_content_nodes_ancestors_history_createdBy_KnownUser {
  displayName: string | null;
  profilePicture: UserPermissionsQuery_content_nodes_ancestors_history_createdBy_KnownUser_profilePicture | null;
  type: string | null;
  accountId: string | null;
  accountType: string | null;
  email: string | null;
}

export interface UserPermissionsQuery_content_nodes_ancestors_history_createdBy_User_profilePicture {
  path: string;
}

export interface UserPermissionsQuery_content_nodes_ancestors_history_createdBy_User {
  displayName: string | null;
  profilePicture: UserPermissionsQuery_content_nodes_ancestors_history_createdBy_User_profilePicture | null;
  type: string | null;
  accountId: string | null;
  accountType: string | null;
  email: string | null;
}

export type UserPermissionsQuery_content_nodes_ancestors_history_createdBy = UserPermissionsQuery_content_nodes_ancestors_history_createdBy_Anonymous | UserPermissionsQuery_content_nodes_ancestors_history_createdBy_KnownUser | UserPermissionsQuery_content_nodes_ancestors_history_createdBy_User;

export interface UserPermissionsQuery_content_nodes_ancestors_history_ownedBy_Anonymous_profilePicture {
  path: string;
}

export interface UserPermissionsQuery_content_nodes_ancestors_history_ownedBy_Anonymous {
  displayName: string | null;
  profilePicture: UserPermissionsQuery_content_nodes_ancestors_history_ownedBy_Anonymous_profilePicture | null;
  type: string | null;
}

export interface UserPermissionsQuery_content_nodes_ancestors_history_ownedBy_KnownUser_profilePicture {
  path: string;
}

export interface UserPermissionsQuery_content_nodes_ancestors_history_ownedBy_KnownUser {
  displayName: string | null;
  profilePicture: UserPermissionsQuery_content_nodes_ancestors_history_ownedBy_KnownUser_profilePicture | null;
  type: string | null;
  accountId: string | null;
  accountType: string | null;
  email: string | null;
}

export interface UserPermissionsQuery_content_nodes_ancestors_history_ownedBy_User_profilePicture {
  path: string;
}

export interface UserPermissionsQuery_content_nodes_ancestors_history_ownedBy_User {
  displayName: string | null;
  profilePicture: UserPermissionsQuery_content_nodes_ancestors_history_ownedBy_User_profilePicture | null;
  type: string | null;
  accountId: string | null;
  accountType: string | null;
  email: string | null;
}

export type UserPermissionsQuery_content_nodes_ancestors_history_ownedBy = UserPermissionsQuery_content_nodes_ancestors_history_ownedBy_Anonymous | UserPermissionsQuery_content_nodes_ancestors_history_ownedBy_KnownUser | UserPermissionsQuery_content_nodes_ancestors_history_ownedBy_User;

export interface UserPermissionsQuery_content_nodes_ancestors_history {
  createdBy: UserPermissionsQuery_content_nodes_ancestors_history_createdBy | null;
  ownedBy: UserPermissionsQuery_content_nodes_ancestors_history_ownedBy | null;
}

export interface UserPermissionsQuery_content_nodes_ancestors_links {
  webui: string | null;
}

export interface UserPermissionsQuery_content_nodes_ancestors {
  hasInheritedRestriction: boolean;
  hasRestriction: boolean;
  hasViewRestriction: boolean;
  history: UserPermissionsQuery_content_nodes_ancestors_history | null;
  id: string | null;
  title: string | null;
  type: string | null;
  links: UserPermissionsQuery_content_nodes_ancestors_links | null;
}

export interface UserPermissionsQuery_content_nodes_space_homepage {
  id: string | null;
}

export interface UserPermissionsQuery_content_nodes_space {
  hasRestriction: boolean;
  hasViewRestriction: boolean;
  homepage: UserPermissionsQuery_content_nodes_space_homepage | null;
  id: string | null;
  key: string | null;
  name: string | null;
}

export interface UserPermissionsQuery_content_nodes {
  hasInheritedRestriction: boolean;
  hasRestriction: boolean;
  hasViewRestriction: boolean;
  history: UserPermissionsQuery_content_nodes_history | null;
  id: string | null;
  title: string | null;
  type: string | null;
  links: UserPermissionsQuery_content_nodes_links | null;
  ancestors: (UserPermissionsQuery_content_nodes_ancestors | null)[] | null;
  space: UserPermissionsQuery_content_nodes_space | null;
}

export interface UserPermissionsQuery_content {
  nodes: (UserPermissionsQuery_content_nodes | null)[] | null;
}

export interface UserPermissionsQuery_siteConfiguration {
  customSiteLogo: boolean;
  siteFaviconUrl: string;
}

export interface UserPermissionsQuery_siteDescription {
  logoUrl: string | null;
}

export interface UserPermissionsQuery_siteSettings {
  siteTitle: string;
}

export interface UserPermissionsQuery_tenant {
  cloudId: string;
}

export interface UserPermissionsQuery {
  content: UserPermissionsQuery_content | null;
  siteConfiguration: UserPermissionsQuery_siteConfiguration;
  siteDescription: UserPermissionsQuery_siteDescription | null;
  siteSettings: UserPermissionsQuery_siteSettings | null;
  tenant: UserPermissionsQuery_tenant;
}

export interface UserPermissionsQueryVariables {
  contentId: string;
  accountId: string;
  permission: InspectPermissions;
  status: (string | null)[];
}
