import React from 'react';
import type { IntlShape } from 'react-intl-next';

import type { CreateUIAnalyticsEvent } from '@atlaskit/analytics-next/types';
import type { ExtensionManifest, ExtensionModule } from '@atlaskit/editor-common/extensions';

import type { ExperienceTrackerAPI } from '@confluence/experience-tracker';
import type { FlagsStateContainer } from '@confluence/flags';

import CreateIcon from './whiteboard-icon.svg';

import { messages } from './messages';
import { createWhiteboardQuickInsertModule } from './create-whiteboard-quick-insert';
import { slashCommands } from './slash-commands';

type CreateWhiteboardExtensionProps = {
	pageId: string;
	spaceId: string;
	spaceKey: string;
	intl: IntlShape;
	experienceTracker?: ExperienceTrackerAPI;
	createAnalyticsEvent?: CreateUIAnalyticsEvent;
	flags?: FlagsStateContainer;
	contentType?: string;
	allowWhiteboardQuickInsert?: boolean;
};

export const createWhiteboardExtension = ({
	pageId,
	spaceId,
	spaceKey,
	intl,
	flags,
	experienceTracker,
	createAnalyticsEvent,
	contentType,
	allowWhiteboardQuickInsert,
}: CreateWhiteboardExtensionProps): ExtensionManifest => {
	// empty extension if not on page
	if (contentType !== 'page') {
		return {
			title: intl.formatMessage(messages.whiteboardManifestTitle),
			description: intl.formatMessage(messages.whiteboardManifestDescription),
			type: 'com.atlassian.confluence.macro.core',
			key: 'whiteboard-extension',
			icons: {
				// eslint-disable-next-line import/dynamic-import-chunkname
				'48': () => import('@atlaskit/icon/glyph/editor/addon'),
			},
			modules: {
				quickInsert: [],
			},
		};
	}

	const icon = async () => () => (
		<img src={CreateIcon} alt={intl.formatMessage(messages.createWhiteboardIconAltText)} />
	);

	const quickInsertCommands: ExtensionModule[] = slashCommands.map((command) => ({
		key: `create-${command.key}`,
		title: intl.formatMessage(command.title),
		description: intl.formatMessage(command.description),
		icon,
		action: createWhiteboardQuickInsertModule(
			pageId,
			spaceId,
			spaceKey,
			experienceTracker,
			createAnalyticsEvent,
			flags,
			command.templateKey,
		),
	}));

	return {
		title: intl.formatMessage(messages.whiteboardManifestTitle),
		description: intl.formatMessage(messages.whiteboardManifestDescription),
		type: 'com.atlassian.confluence.macro.core',
		key: 'whiteboard-extension',
		icons: { '48': icon },
		modules: {
			quickInsert: allowWhiteboardQuickInsert ? quickInsertCommands : [],
		},
	};
};
