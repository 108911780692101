import { fg } from '@confluence/feature-gating';
import { AccessStatus } from '@confluence/session-data';
import { isMobileUserAgent, shouldShowMobileWeb } from '@confluence/mobile-detection';

/**
 * Checking for if Loom Beta is enabled.
 * Note: This file is a non-hook version of the function useIsLoomBetaEnabled.ts so that it can be run during SSR preloading
 * It will eventually replace the hook after SSR rollout
 */
export const getIsLoomBetaEnabled = (accessStatus: AccessStatus) => {
	const isConfluenceLicensed =
		accessStatus === AccessStatus.LICENSED_ADMIN_ACCESS ||
		accessStatus === AccessStatus.LICENSED_USE_ACCESS;

	// don't enable entry points if user is on a mobile device or viewing mobile web
	const isMobileDeviceOrMobileWeb = isMobileUserAgent() || shouldShowMobileWeb();

	return isConfluenceLicensed && !isMobileDeviceOrMobileWeb && fg('confluence_loom_beta_enabled');
};
