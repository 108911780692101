import React, { useRef, useMemo } from 'react';
import { defineMessages } from 'react-intl-next';
import isEqual from 'lodash/isEqual';

import { DefaultExtensionProvider } from '@atlaskit/editor-common/extensions';
import { token } from '@atlaskit/tokens';
import { Box, xcss } from '@atlaskit/primitives';

import { LoadableAfterPaint } from '@confluence/loadable';

import {
	type CustomSitesExtensionProviderProps,
	getExtensionManifest,
} from '../shared-components/customSitesManifestBuilder';

import { searchExtensionKey, searchExtensionType, searchVersion1 } from './searchConstants';

const SearchExtension = LoadableAfterPaint({
	loader: async () =>
		(await import(/* webpackChunkName: "loadable-SearchExtension" */ './SearchExtension'))
			.SearchExtension,
});

const SearchExtensionContainerStyles = xcss({
	// This padding ensures that the corners of the search bar are not cut off by the containers border
	// radius when the search bar has square corners.
	padding: 'space.025',
});

const i18n = defineMessages({
	title: {
		id: 'custom-sites-extensions.search.manifest.macro-title',
		defaultMessage: 'Custom search',
		description:
			'Title of company hub search macro to be displayed in toolbar/shortcut macros dropdown.',
	},
	description: {
		id: 'custom-sites-extensions.search.manifest.macro-description',
		defaultMessage: 'Add a filtered search bar',
		description:
			'Description of company hub search macro to be displayed in toolbar/shortcut macros dropdown.',
	},
	defaultPlaceholderText: {
		id: 'custom-sites-extensions.search.manifest.default-placeholder-text',
		defaultMessage: 'Search for verified announcements, resources, and policies',
		description: 'Default placeholder text for company hub search macro.',
	},
});

export const searchExtensionProvider = async ({
	intl,
	contentId,
	editorActions,
	openConfigPanel,
	setUpsellModalSource,
}: CustomSitesExtensionProviderProps) => {
	return new DefaultExtensionProvider([
		getExtensionManifest({
			key: searchExtensionKey,
			type: searchExtensionType,
			title: intl.formatMessage(i18n.title),
			description: intl.formatMessage(i18n.description),
			source: 'premiumSearchInsert',
			setUpsellModalSource,
			icons: {
				'48': async () =>
					(
						await import(
							/* webpackChunkName: "loadable-SearchMacroIcon" */
							'@confluence/icons/entry-points/SearchMacroIcon'
						)
					).SearchMacroIcon,
			},

			parameters: {
				extensionTitle: intl.formatMessage(i18n.title),
				version: searchVersion1,
				filters: {
					product: 'confluence' as const,
				},
				styles: {
					placeholder: intl.formatMessage(i18n.defaultPlaceholderText),
					backgroundColor: token('elevation.surface'),
					textColor: token('color.text'),
					borderRadius: token('border.radius.100'),
				},
			},

			update: async () => {
				const selectedNode = editorActions?.getSelectedNode()?.toJSON();
				const selectedLocalId = selectedNode?.attrs?.localId;

				if (Boolean(selectedLocalId) && openConfigPanel) {
					openConfigPanel(selectedLocalId);
				}
			},

			render:
				async () =>
				({ node }) => {
					// We want to avoid re-rendering the SearchInput from the platform package
					// to prevent issues related to its state logic. For example, the store
					// doesn’t check for the initial value in the text field, leading to
					// incorrect responses in the dropdown menu.
					const prevNodeRef = useRef(node);
					const memoizedNode = useMemo(() => {
						if (!isEqual(prevNodeRef.current, node)) {
							prevNodeRef.current = node;
						}
						return prevNodeRef.current;
					}, [node]);

					return (
						<Box xcss={SearchExtensionContainerStyles}>
							<SearchExtension contentId={contentId} extensionNode={memoizedNode} />
						</Box>
					);
				},
		}),
	]);
};
