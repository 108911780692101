import { DefaultExtensionProvider } from '@atlaskit/editor-common/extensions';
import { isFedRamp } from '@atlassian/atl-context';

import buildActionItemsPanelManifest from './manifests/action-items/manifest';
import buildSummarisePagePanelManifest from './manifests/summarise-page/manifest';
import type { AIManifest } from './types';

export const getAIPanelsProvider = ({ intl, providerFactory }: AIManifest) => {
	const summarisePage = buildSummarisePagePanelManifest({
		intl,
		providerFactory,
	});
	const findActionItems = buildActionItemsPanelManifest({
		intl,
		providerFactory,
	});
	const extensions = [];

	/**
	 * WARNING: Do not remove the isFedRamp check.
	 * This is to ensure that AI functionality is not enabled in FedRamp environments.
	 */
	if (!isFedRamp()) {
		extensions.push(summarisePage);
		extensions.push(findActionItems);
	}

	// Ignored via go/ees005
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	return new DefaultExtensionProvider<any>(extensions);
};
