import { query } from '@confluence/query-preloader-tools';

import { spaceForBaseSpaceQuery } from './queries/spaceForBaseSpace.graphql';
import type {
	spaceForBaseSpaceQuery as spaceForBaseSpaceQueryType,
	spaceForBaseSpaceQueryVariables as spaceForBaseSpaceQueryVariablesType,
} from './queries/__types__/spaceForBaseSpaceQuery';

export function preloadSpace(spaceKey: spaceForBaseSpaceQueryVariablesType['spaceKey']) {
	return query<spaceForBaseSpaceQueryType, spaceForBaseSpaceQueryVariablesType>({
		query: spaceForBaseSpaceQuery,
		variables: {
			spaceKey,
		},
	});
}
