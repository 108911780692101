import React from 'react';
import type { IntlFormatters } from 'react-intl-next';
import { defineMessages } from 'react-intl-next';
import uuid from 'uuid/v4';

import { DefaultExtensionProvider } from '@atlaskit/editor-common/extensions';
import { token } from '@atlaskit/tokens';

import { LoadableAfterPaint } from '@confluence/loadable';

import { getExtensionManifest } from '../shared-components/customSitesManifestBuilder';
import type { CustomSitesExtensionProviderProps } from '../shared-components/customSitesManifestBuilder';

import { linksMenuExtensionType } from './linksMenuExtensionType';
import { ALIGNMENT, BORDER, CORNERS, ITEM } from './linksMenuTypes';
import type { LinksMenuParameters, Styles } from './linksMenuTypes';

const LINKS_MENU_VERSION_1 = '1';
const LinksMenuExtension = LoadableAfterPaint({
	loader: async () =>
		(await import(/* webpackChunkName: "loadable-LinksMenuExtension" */ './LinksMenuExtension'))
			.LinksMenuExtension,
});

const i18n = defineMessages({
	linksMenuTitle: {
		id: 'custom-sites-extensions.links-menu.manifest.extension-title',
		defaultMessage: 'Links menu [New]',
		description:
			'Title of links menu extension to be displayed in toolbar/shortcut macros dropdown.',
	},
	linksMenuDescription: {
		id: 'custom-sites-extensions.links-menu.manifest.extension-description',
		defaultMessage: 'Add multiple links and sub-menus to Company Hub',
		description:
			'Description of links menu extension to be displayed in toolbar/shortcut macros dropdown',
	},
	firstDefaultItem: {
		id: 'custom-sites-extensions.links-menu.manifest.first-default-item',
		defaultMessage: 'About us',
		description: 'Title of first placeholder item on links menu rendered in extension',
	},
	secondDefaultItem: {
		id: 'custom-sites-extensions.links-menu.manifest.second-default-item',
		defaultMessage: 'Benefits',
		description: 'Title of second placeholder item on links menu rendered in extension',
	},
	thirdDefaultItem: {
		id: 'custom-sites-extensions.links-menu.manifest.third-default-item',
		defaultMessage: 'Teams',
		description: 'Title of third placeholder item on links menu rendered in extension',
	},
	nestedDefaultItem: {
		id: 'custom-sites-extensions.links-menu.manifest.nested-default-item',
		defaultMessage: 'Team 1',
		description:
			'Title of nested placeholder item inside the third menu item on links menu rendered in extension',
	},
});

export const DEFAULT_STYLES: Styles = {
	alignment: ALIGNMENT.CENTER,
	fillColor: token('elevation.surface'),
	borderColor: token('color.background.accent.gray.subtler'),
	border: BORDER.NONE,
	cornerStyle: CORNERS.SHARP,
	textColor: token('color.text'),
	highlightColor: token('color.text'),
};

const getParameters = (formatMessage: IntlFormatters['formatMessage']): LinksMenuParameters => ({
	version: LINKS_MENU_VERSION_1,
	styles: DEFAULT_STYLES,
	items: [
		{
			itemId: uuid(),
			type: ITEM.LINK,
			title: formatMessage(i18n.firstDefaultItem),
			link: '',
		},
		{
			itemId: uuid(),
			type: ITEM.LINK,
			title: formatMessage(i18n.secondDefaultItem),
			link: '',
		},
		{
			itemId: uuid(),
			type: ITEM.MENU,
			title: formatMessage(i18n.thirdDefaultItem),
			childItems: [
				{
					itemId: uuid(),
					type: ITEM.LINK,
					title: formatMessage(i18n.nestedDefaultItem),
					link: '',
				},
			],
		},
	],
});

export const linksMenuExtensionProvider = async ({
	editorActions,
	openConfigPanel,
	intl,
	contentId,
}: CustomSitesExtensionProviderProps) => {
	const parameters = getParameters(intl.formatMessage);
	return new DefaultExtensionProvider([
		getExtensionManifest<LinksMenuParameters>({
			title: intl.formatMessage(i18n.linksMenuTitle),
			type: linksMenuExtensionType,
			key: 'links-menu',
			description: intl.formatMessage(i18n.linksMenuDescription),
			icons: {
				'48': () =>
					import(
						/* webpackChunkName: "loadable-LinksMenuExtensionIcon" */
						'@confluence/icons/entry-points/LinksMenuExtensionIcon'
					).then((mod) => mod.LinksMenuExtensionIcon),
			},
			parameters,
			update: async () => {
				const selectedNode = editorActions?.getSelectedNode()?.toJSON();
				const selectedLocalId = selectedNode?.attrs?.localId;

				if (!!selectedLocalId && openConfigPanel) {
					openConfigPanel(selectedLocalId);
				}
			},
			render:
				async () =>
				({ node }) => {
					return <LinksMenuExtension extensionNode={node} contentId={contentId} />;
				},
			// @TODO add links menu upsell in COMPHUB-2408
			setUpsellModalSource: undefined,
			source: 'premiumCarouselInsert',
		}),
	]);
};
