import {
	DefaultExtensionProvider,
	ExtensionAutoConvertHandler,
	type ExtensionManifest,
	type ExtensionProvider,
} from '@atlaskit/editor-common/extensions';
import { CONNECT_CONFLUENCE_MACRO_EXTENSION_TYPE } from '../utils/constants';
import { type ForgeExtensionManifest, type ForgeExtensionParameters } from '../types';
import { AnalyticsAction, type AnalyticsClient } from '../utils/analytics';

/**
 * When connect module is migrated to forge, look up for forge extension that matches connect macro key.
 * This is to allow migrated connect macro data to be rendered with forge extension.
 */

type ManifestType = ExtensionManifest<ForgeExtensionParameters> | ForgeExtensionManifest;

export type SendAnalyticsFunc = (extensionKey: string, errorMessage: string) => unknown;

export class ForgeExtensionProvider extends DefaultExtensionProvider<ForgeExtensionParameters> {
	connectExtensionProvider: Promise<ExtensionProvider> | undefined;
	sendAnalytics: SendAnalyticsFunc | undefined;
	analyticsClient: AnalyticsClient | undefined;

	constructor(
		manifests: ManifestType[] | Promise<ManifestType[]>,
		connectExtensionProvider?: Promise<ExtensionProvider>,
		sendAnalytics?: SendAnalyticsFunc,
		autoConvertHandlers?: ExtensionAutoConvertHandler[],
		analyticsClient?: AnalyticsClient,
	) {
		super(manifests);
		this.connectExtensionProvider = connectExtensionProvider;
		this.sendAnalytics = sendAnalytics;
		this.manifestsPromise = Promise.resolve(manifests);
		this.autoConvertHandlers = autoConvertHandlers;
		this.analyticsClient = analyticsClient;
	}

	async getExtension(type: string, key: string): Promise<ManifestType> {
		try {
			return await super.getExtension(type, key);
		} catch (forgeError) {
			// There is no Forge app to match the full ARI extensionKey
			// If the extensionType is Connect, we fall back to trying to resolve it by harmonised connect module key
			if (type === CONNECT_CONFLUENCE_MACRO_EXTENSION_TYPE) {
				const { sendEvent } = this.analyticsClient || {};
				if (!this.connectExtensionProvider) {
					if (sendEvent) {
						sendEvent(AnalyticsAction.H11N_RESOLVE_FAILED, {
							extensionKey: key,
							error: 'No connect extension provider',
						});
					} else {
						this.sendAnalytics?.(key, 'No connect extension provider');
					}
				} else {
					try {
						// We first check that no Connect app still exists that can handle this extensionKey
						await (await this.connectExtensionProvider).getExtension(type, key);
					} catch (connectError) {
						// We know that there is no corresponding Connect app, so we can look up the Forge app by Connect key
						const extension = await this.getConnectToForgeExtension(key);
						if (extension) {
							return extension;
						}
						// Report failure to find a Forge extension for this Connect key
						if (sendEvent) {
							sendEvent(AnalyticsAction.H11N_RESOLVE_FAILED, {
								extensionKey: key,
								error: forgeError,
							});
						} else {
							this.sendAnalytics?.(
								key,
								forgeError instanceof Error ? forgeError.message : String(forgeError),
							);
						}
					}
				}
			}
			throw forgeError;
		}
	}

	async getExtensions(): Promise<ManifestType[]> {
		return super.getExtensions();
	}

	async getConnectToForgeExtension(key: string): Promise<ForgeExtensionManifest | undefined> {
		const extensions = await this.getExtensions();
		return extensions.find(
			(manifest: ManifestType): manifest is ForgeExtensionManifest =>
				'connectModuleKey' in manifest &&
				Boolean(manifest.migrationKey) &&
				manifest.connectModuleKey === key,
		);
	}
}
