import type { ComponentClass, FC } from 'react';
import React, { createContext } from 'react';

import type { ExperienceTrackerAPI } from '../ExperienceTracker';

export const ExperienceTrackerContext = createContext<ExperienceTrackerAPI>({
	start() {},
	succeed() {},
	fail() {},
	abort() {},
	stopOnError() {},
	subscribe() {
		return () => {};
	},
	getExperienceState() {
		return null;
	},
	updateAttributes() {},
});

export type WithExperienceTrackerProps = {
	experienceTracker: ExperienceTrackerAPI;
};

/**
 * For function components, please use `useContext(ExperienceTrackerContext)` instead!
 */
export function withExperienceTracker<P>(
	WrappedComponent: ComponentClass<P & WithExperienceTrackerProps>,
) {
	const withExperienceTracker: FC<P> = (props: P) => (
		<ExperienceTrackerContext.Consumer>
			{(value) => <WrappedComponent {...props} experienceTracker={value} />}
		</ExperienceTrackerContext.Consumer>
	);

	withExperienceTracker.displayName = `withExperienceTracker(${
		WrappedComponent.displayName || WrappedComponent.name
	})`;

	return withExperienceTracker;
}
