import { getSSRRenderInputs } from '@confluence/ssr-utilities';

export interface LivePageSSRStatusType {
	isLive: boolean | undefined;
	isTypeKnown: boolean;
}

export const useLivePageSSRStatus = (): LivePageSSRStatusType => {
	// eslint-disable-next-line check-react-ssr-usage/no-react-ssr
	if (process.env.REACT_SSR) {
		return {
			isLive: getSSRRenderInputs().isLivePage,
			isTypeKnown: true,
		};
	}

	if (window.__SSR_RENDERED__ && window.__SSR_IS_LIVE_PAGE__ !== undefined) {
		return {
			isLive: window.__SSR_IS_LIVE_PAGE__,
			isTypeKnown: true,
		};
	}

	return {
		isLive: undefined,
		isTypeKnown: false,
	};
};
