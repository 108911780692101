import React, { useCallback } from 'react';
import { defineMessages } from 'react-intl-next';

import type { RenderVariant } from '../../../linkCardsTypes';
import { CardSizes } from '../../../linkCardsTypes';
import type { UpdateMainFormFieldType } from '../../../../shared-components/ConfigPanel/configPanelTypes';

import type { FieldOption } from './ConfigFieldDropdown';
import { ConfigFieldDropdown } from './ConfigFieldDropdown';

export const DEFAULT_CARDS_SIZE = CardSizes.MEDIUM;

const i18n = defineMessages({
	sizeTextLabel: {
		id: 'custom-sites-extensions.link-cards.link-cards-config-panel.size-text-label',
		defaultMessage: 'Size',
		description: 'A visible text label for the size section of the link cards config panel',
	},
	sizeSmallDropDownItemLabel: {
		id: 'custom-sites-extensions.link-cards.link-cards-config-panel.size-field.drop-down.item.small.label',
		defaultMessage: 'Small',
		description:
			'A visible label for the select option/selection/item to change card size to small for link card',
	},
	sizeMediumDropDownItemLabel: {
		id: 'custom-sites-extensions.link-cards.link-cards-config-panel.size-field.drop-down.item.medium.label',
		defaultMessage: 'Medium',
		description:
			'A visible label for the select option/selection/item to change card to size to medium for link card',
	},
	sizeLargeDropDownItemLabel: {
		id: 'custom-sites-extensions.link-cards.link-cards-config-panel.size-field.drop-down.item.large.label',
		defaultMessage: 'Large',
		description:
			'A visible label for the select option/selection/item to change card to size to large for link card',
	},
});

const sizeOptions: FieldOption<CardSizes>[] = [
	{
		label: i18n.sizeSmallDropDownItemLabel,
		value: CardSizes.SMALL,
	},
	{
		label: i18n.sizeMediumDropDownItemLabel,
		value: CardSizes.MEDIUM,
	},
	{
		label: i18n.sizeLargeDropDownItemLabel,
		value: CardSizes.LARGE,
	},
];

const defaultOption = sizeOptions[0];

type SizeFieldProps = {
	sizeParameter: CardSizes;
	renderVariant: RenderVariant;
	updateMainFormField: UpdateMainFormFieldType;
};

export const SizeField: React.FC<SizeFieldProps> = ({
	sizeParameter,
	renderVariant,
	updateMainFormField,
}) => {
	const selectedOption = sizeOptions.find(({ value }) => value === sizeParameter) || defaultOption;

	const handleSelect = useCallback(
		(selectedLabel: FieldOption<CardSizes>) => {
			updateMainFormField({ size: selectedLabel.value });
		},
		[updateMainFormField],
	);

	return (
		<ConfigFieldDropdown<CardSizes>
			fieldType="size"
			label={i18n.sizeTextLabel}
			onSelect={handleSelect}
			options={sizeOptions}
			selectedOption={selectedOption}
			renderVariant={renderVariant}
		/>
	);
};
