import type { LocalOverrides } from 'statsig-js-lite';

import FeatureGates from '@atlaskit/feature-gate-js-client';

import {
	confluenceLocalStorageInstance,
	PERSISTED_KEYS_ON_SERVER,
} from '@confluence/storage-manager';

const STATSIG_OVERRIDES_STORAGE_KEY = PERSISTED_KEYS_ON_SERVER?.PERSISTED_OVERRIDES_STATSIG;

export const updatePersistedStatsigOverrides = (overrides: LocalOverrides) =>
	confluenceLocalStorageInstance.setItem(STATSIG_OVERRIDES_STORAGE_KEY, overrides);

export const getPersistedStatsigOverrides = () =>
	confluenceLocalStorageInstance.getItem(STATSIG_OVERRIDES_STORAGE_KEY);

export const applyPersistedStatsigOverrides = () => {
	const { configs = {}, gates = {}, layers = {} } = getPersistedStatsigOverrides() || {};

	// only apply overrides if there are any, otherwise tests overrides will be wiped
	if (
		Object.keys(configs).length > 0 ||
		Object.keys(gates).length > 0 ||
		Object.keys(layers).length > 0
	) {
		return FeatureGates.setOverrides({
			configs,
			layers,
			gates,
		});
	}

	return null;
};
