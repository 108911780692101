import { fromJS, List } from 'immutable';

import { token } from '@atlaskit/tokens';

import { SPOTLIGHTS_FLOWS } from './changeboarding-spotlights';

const DEFAULT_SPOTLIGHT_STATE = fromJS({
	id: -1,
	ready: false,
	requested: false,
	dismissed: false,
	attributes: {
		heading: '',
		footer: '',
		dialogPlacement: 'right top',
		targetBgColor: token('elevation.surface'),
		targetRadius: 4,
	},
});

const ORIGINAL_FLOW_SET = SPOTLIGHTS_FLOWS.map((flow) =>
	flow.map((id) => DEFAULT_SPOTLIGHT_STATE.merge({ id })),
);

const DEFAULT_STATE = fromJS({
	index: -1,
	flow: -1,
	loaded: 0,
	spotlights: ORIGINAL_FLOW_SET,
});

export default function changeboarding(state = DEFAULT_STATE, action = {}) {
	const { spotlightId } = action;
	const spotlights = state.get('spotlights');
	const index = state.get('index');
	switch (action.type) {
		case 'CHANGEBOARDING_NEXT':
			return state.merge({ index: index + 1 });
		case 'CHANGEBOARDING_PREV':
			return state.merge({ index: index - 1 });
		case 'CHANGEBOARDING_TARGET_READY':
			for (let flowIndex = 0; flowIndex < spotlights.size; flowIndex++) {
				let flowReady = !!spotlights.get(flowIndex).size;
				for (
					let spotlightIndex = 0;
					spotlightIndex < spotlights.get(flowIndex).size;
					spotlightIndex++
				) {
					if (spotlights.getIn([flowIndex, spotlightIndex, 'id']) === spotlightId) {
						state = state.setIn(['spotlights', flowIndex, spotlightIndex, 'loaded'], true);
						const { spotlightAttributes } = action;
						const existingAttributes = state.getIn([
							'spotlights',
							flowIndex,
							spotlightIndex,
							'attributes',
						]);

						const newAttributes = fromJS(spotlightAttributes);
						/*
              pass in spotlightAttributes from action to the state (if there is any)
             */
						state = state.setIn(
							['spotlights', flowIndex, spotlightIndex, 'attributes'],
							existingAttributes.merge(newAttributes),
						);
					}
					flowReady = flowReady && state.getIn(['spotlights', flowIndex, spotlightIndex, 'loaded']);
				}
				if (flowReady) {
					return state.merge({
						flow: flowIndex,
						index: 0,
						loaded: spotlights.get(flowIndex).size,
					});
				}
			}
			break;
		case 'CHANGEBOARDING_DISMISS':
			const flow = state.get('flow');
			// The last flow should be empty.
			const last = spotlights.size - 1;
			if (flow === last) {
				return state.setIn(['spotlights', last], List([]));
			} else {
				/*
          for multi step flow, clear the spotlights on dismiss to make it disappear
         */
				return state.deleteIn(['spotlights', flow]);
			}
		case 'CHANGEBOARDING_INVOKE':
			for (let flowIndex = 0; flowIndex < spotlights.size; flowIndex++) {
				for (
					let spotlightIndex = 0;
					spotlightIndex < spotlights.get(flowIndex).size;
					spotlightIndex++
				) {
					if (spotlights.getIn([flowIndex, spotlightIndex, 'id']) === spotlightId) {
						let newState = state.setIn(
							['spotlights', flowIndex, spotlightIndex, 'requested'],
							true,
						);
						const { spotlightAttributes } = action;
						const existingAttributes = newState.getIn([
							'spotlights',
							flowIndex,
							spotlightIndex,
							'attributes',
						]);
						const newAttributes = fromJS(spotlightAttributes);
						/*
              pass in spotlightAttributes from action to the state (if there is any)
             */
						newState = newState.setIn(
							['spotlights', flowIndex, spotlightIndex, 'attributes'],
							existingAttributes.merge(newAttributes),
						);

						return newState;
					}
				}
			}
			return state.setIn(
				['spotlights', spotlights.size - 1, spotlights.get(spotlights.size - 1).size],
				DEFAULT_SPOTLIGHT_STATE.merge({
					id: spotlightId,
				}),
			);
		case 'CHANGEBOARDING_SET_SPOTLIGHT_ATTRIBUTES':
			const { spotlightAttributes } = action;
			for (let flowIndex = 0; flowIndex < spotlights.size; flowIndex++) {
				for (
					let spotlightIndex = 0;
					spotlightIndex < spotlights.get(flowIndex).size;
					spotlightIndex++
				) {
					if (spotlights.getIn([flowIndex, spotlightIndex, 'id']) === spotlightId) {
						return state.setIn(
							['spotlights', flowIndex, spotlightIndex, 'attributes'],
							spotlightAttributes,
						);
					}
				}
			}
			break;
	}
	return state;
}
