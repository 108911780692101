import { fromJS, OrderedSet } from 'immutable';

import ContentRecord from '../records/content-record';
import VersionRecord from '../records/version-record';
import UserRecord from '../records/user-record';
import SpaceRecord from '../records/space-record';
import LabelRecord from '../records/label-record';

export const EDITOR_VERSIONS = {
	FABRIC: 'FABRIC',
	TINY_MCE: 'TINY_MCE',
};

export const DEFAULT_INITIAL_STATE = fromJS({
	spaces: {},
	lookAndFeelSettings: {},
	contents: {},
	versions: {},
	users: {},
	labels: {},
});

const entityReducerCommon = (state, action, entityKey, recordCreator = fromJS) => {
	if (
		!action.response ||
		!action.response.entities ||
		!action.response.entities.hasOwnProperty(entityKey)
	) {
		return state;
	}

	const actionEntities = action.response.entities[entityKey];
	for (const key of Object.keys(actionEntities)) {
		const stateEntity = state.get(key);
		let record = recordCreator(actionEntities[key]);
		if (stateEntity) {
			record = stateEntity.mergeDeepWith((prev, next) => {
				if (next === null) {
					return prev;
				}
				return next;
			}, record);
		}
		state = state.set(key, record);
	}
	return state;
};

const contents = (state, action) => {
	state = entityReducerCommon(state, action, 'contents', (item) => new ContentRecord(item));

	switch (action.type) {
		case 'CONTENT_FAILURE':
		case 'EDITOR_CONTENT_V2_FAILURE':
			if (action.error?.extensions?.statusCode === 403) {
				let hasInheritedRestrictions = false;
				let data = action.error && action.error.data;

				// handle the REST case, which will be removed soon
				if (action.message && action.message.data) {
					data = action.message && action.message.data;
				}

				if (data) {
					if (
						(data.errors || []).some((error) => error.message.key === 'confluence.space.restricted')
					) {
						// Show not found when user doesn't have permission to view the space
						// Make it consistent with pre-SPA
						return state;
					}

					hasInheritedRestrictions = (data.errors || []).some(
						(error) => error.message.key === 'confluence.content.restricted.inherited',
					);
				}

				// replace by new content record
				state = state.set(
					action.options.contentId,
					new ContentRecord({
						id: action.options.contentId,
						isUnauthorized: true,
						hasInheritedRestrictions,
					}),
				);
			}
			return state;

		case 'QUICK_RELOAD_SUCCESS':
		case 'CONTENT_SUCCESS':
			if (action.type === 'CONTENT_SUCCESS') {
				const editorValue = action?.response?.properties?.editor?.value;
				const editorVersion =
					editorValue === 'v2' ? EDITOR_VERSIONS.FABRIC : EDITOR_VERSIONS.TINY_MCE;

				state = state.setIn([action.contentId, 'editorVersion'], editorVersion);
			}

			// update last fetched time.
			const lastFetchTime =
				action.response && action.response.lastFetchTime
					? action.response.lastFetchTime
					: new Date().getTime();
			return state.setIn([action.contentId, 'lastFetchTime'], lastFetchTime);

		case 'EDITOR_CONTENT_METADATA_SUCCESS':
			const updatedAncestors = action.response?.ancestors?.map((ancestor) => {
				return ancestor.id;
			});
			if (updatedAncestors) {
				return state.setIn([action.contentId, 'ancestors'], OrderedSet(updatedAncestors));
			}
	}

	return state;
};

const users = (state, action) =>
	entityReducerCommon(state, action, 'users', (item) => new UserRecord(item));
const lookAndFeelSettings = (state, action) =>
	entityReducerCommon(state, action, 'lookAndFeelSettings', (item) => fromJS(item));
const labels = (state, action) =>
	entityReducerCommon(state, action, 'labels', (item) => new LabelRecord(item));

const versions = (state, action) => {
	state = entityReducerCommon(state, action, 'versions', (item) => new VersionRecord(item));
	if (action.type === 'HANDLE_VERSION_UPDATE') {
		state = state.setIn([action.contentId, 'number'], action.version);
	}
	return state;
};

export function correctSpaceKeyCaseMismatch(state, action) {
	const spaceKeyInRequest = action.key || action.spaceKey;

	if (
		!spaceKeyInRequest ||
		!action.response ||
		!action.response.entities ||
		!action.response.entities.spaces
	) {
		return state;
	}

	const spaceKeysInResponse = Object.keys(action.response.entities.spaces);
	if (spaceKeysInResponse.length) {
		const spaceKeyInResponse = spaceKeysInResponse[0];

		if (
			spaceKeyInRequest !== spaceKeyInResponse &&
			spaceKeyInRequest.toLowerCase() === spaceKeyInResponse.toLowerCase()
		) {
			// Front-end is fetching a space with wrong letter case
			// Copy the record to correct place that rest of the code is expecting.
			state = state.withMutations((spaces) => {
				spaces.set(spaceKeyInRequest, spaces.get(spaceKeyInResponse)).delete(spaceKeyInResponse);
			});
		}
	}

	return state;
}

const spaces = (state, action) => {
	const previousState = state;
	state = entityReducerCommon(state, action, 'spaces', (item) => new SpaceRecord(item));

	if (state !== previousState) {
		state = correctSpaceKeyCaseMismatch(state, action);
	}
	if (action.type === 'SPACE_HOME_CONTENT_SUCCESS') {
		if (state.get(action.spaceKey)) {
			return state.mergeDeep({
				[action.spaceKey]: {
					homepageId: action.response.id,
				},
			});
		}
		return state;
	} else {
		return state;
	}
};

const entityReducers = {
	contents,
	versions,
	users,
	spaces,
	lookAndFeelSettings,
	labels,
};

const invokeEntityReducer = (state, action, entityKey) => {
	const entityReducer = entityReducers[entityKey];
	let stateEntities = state.get(entityKey);
	stateEntities = entityReducer(stateEntities, action);
	return state.set(entityKey, stateEntities);
};

export function entities(state = DEFAULT_INITIAL_STATE, action) {
	return state.withMutations((state) => {
		for (const entityKey of Object.keys(entityReducers)) {
			state = invokeEntityReducer(state, action, entityKey);
		}
		return state;
	});
}
