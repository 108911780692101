import React from 'react';
import {
	type EffectsDispatcher as LegacyDispatch,
	type Dispatch,
	type RenderFn,
} from '@atlassian/forge-ui-types';
// eslint-disable-next-line import/no-extraneous-dependencies
import memoizeOne from 'memoize-one';
import { type Props } from '../components';
import type { ModalWidths } from '../utils';

export type ComponentMap = {
	[name: string]: RenderFn;
};

export interface ModalExtension {
	/* For extension point specific behaviour */
	type?: 'macroConfig';
	/* This function should be passed in by the product to close the Modal extension point */
	closeModalExtension: () => void;
	/* Title of the Modal */
	title?: string;
	/* Modal Width, defaults to medium if not passed in */
	modalWidth?: ModalWidths;
	/* This function could be passed in by the product to be executed after onSubmit if successful */
	onSubmitSuccess?: () => void;
	/* Extension point specific modal prop behaviour */
	shouldCloseOnOverlayClick?: boolean;
	/* Extension point specific modal prop behaviour */
	shouldCloseOnEscapePress?: boolean;
}

// downgrades the new dispatch function to be compatible with the old dispatch shape
// this is required for the components that use the old dispatch
export const downgradeDispatch = memoizeOne(
	(dispatch: Dispatch): LegacyDispatch =>
		(legacyEffects) => {
			return Promise.all(
				(Array.isArray(legacyEffects) ? legacyEffects : [legacyEffects]).map((legacyEffect) => {
					switch (legacyEffect.type) {
						case 'initialize': {
							return dispatch({ type: 'render', extensionData: {} });
						}
						case 'event': {
							return dispatch({
								type: 'event',
								handler: legacyEffect.handler,
								args: legacyEffect.args,
								extensionData: {},
							});
						}
						default:
							throw Error(`Cannot dispatch "${legacyEffect.type}" effect`);
					}
				}),
			).then(() => {});
		},
);

// upgrades the component function to the new component map API
// this is temporary while we upgrade the component functions to new API
export const upgradeComponent =
	(Comp: React.FunctionComponent<Props>): RenderFn =>
	({ forgeDoc, render, dispatch }) => {
		const { children, key, props, type } = forgeDoc;
		return (
			<Comp
				type={type}
				key={key}
				props={props}
				dispatch={downgradeDispatch(dispatch)}
				children={children}
				Components={{} /* no components actually use this component map */}
				render={(props) => render(props.aux!)}
				renderChildren={(props) => props.children.map(render)}
			/>
		);
	};
