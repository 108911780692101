/**
 * @jsxRuntime classic
 * @jsx jsx
 */
import React from 'react';
import { type ForgeUIExtensionType } from '@atlassian/forge-ui-types';
import { css, jsx } from '@compiled/react';
import { type ForgeConfigPayload } from './customConfigValidator';
import {
	type CustomEditContext,
	type ForgeExtension,
	type ForgeExtensionProviderSharedParams,
} from '../types';

const hideIframe = css({
	display: 'none',
});

const bodiedExtensionHelper: {
	setRenderer: (renderer: (() => JSX.Element) | null) => void;
} = {
	setRenderer: () => {},
};

/**
 * A component that allows us to render Forge UI Extensions directly in the editor
 * Normally, they are rendered as part of the extension manifest's render function,
 * but that function is not called for bodied extensions, so we need to render them separately.
 */
export const EditorForgeExtensionConfigHelperRenderer = () => {
	const [renderer, setRenderer] = React.useState<(() => JSX.Element) | null>(null);
	bodiedExtensionHelper.setRenderer = (renderer) =>
		setRenderer(
			// We wrap the renderer function here, otherwise setState thinks we're passing a state mutation function
			() => renderer,
		);

	return renderer ? renderer() : null;
};

export const renderConfigHelper = async ({
	extension,
	forgeExtensionNode,
	hasCustomConfig,
	isInitialInsertion,
	createRenderFunction,
	onSubmit,
	onValidate,
}: {
	extension: ForgeUIExtensionType;
	forgeExtensionNode: ForgeExtension;
	hasCustomConfig: boolean;
	isInitialInsertion: boolean;
	createRenderFunction: ForgeExtensionProviderSharedParams['createRenderFunction'];
	onSubmit: (payload: ForgeConfigPayload) => void;
	onValidate: (data: unknown) => ForgeConfigPayload;
}) => {
	let gqlExtension: ForgeUIExtensionType = extension;
	let customEditContext: CustomEditContext | undefined;

	if (hasCustomConfig) {
		gqlExtension = {
			...extension,
			properties: {
				...extension.properties,
				resource: extension.properties?.config?.resource,
				render: extension.properties?.config?.render,
				title: extension.properties?.config?.title,
				viewportSize: extension.properties?.config?.viewportSize,
			},
		};

		const onModalClose = () => bodiedExtensionHelper.setRenderer(null);
		customEditContext = {
			isInserting: isInitialInsertion,
			bridge: {
				close: onModalClose,
				submit: ({ data }: { data: unknown }) => {
					// This can throw and propagate exceptions back down to the app
					const payload = onValidate(data);

					// This can throw and propagate exceptions back down to the app
					onSubmit(payload);

					if (!payload.keepEditing) {
						onModalClose();
					}
					return true;
				},
			},
			modalExtension: {
				type: 'macroConfig',
				title: extension.properties?.config?.title,
				closeModalExtension: onModalClose,
				modalWidth: extension.properties?.config?.viewportSize,
				shouldCloseOnOverlayClick: false,
				shouldCloseOnEscapePress: false,
			},
		};
	}

	const renderFunction = await createRenderFunction(gqlExtension, customEditContext);

	// The Forge Doc config iframe should be hidden and resolve the config in the background
	// The loading component for custom config is unaffected as it is rendered in a Portal
	bodiedExtensionHelper.setRenderer(() => (
		<div css={hideIframe}>{renderFunction({ node: forgeExtensionNode })}</div>
	));
};
