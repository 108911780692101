import { basePlugin } from '@atlaskit/editor-plugins/base';
import { EditorPresetBuilder } from '@atlaskit/editor-common/preset';
import { blockTypePlugin } from '@atlaskit/editor-plugins/block-type';
import { listPlugin } from '@atlaskit/editor-plugins/list';
import { typeAheadPlugin } from '@atlaskit/editor-plugins/type-ahead';
import { decorationsPlugin } from '@atlaskit/editor-plugins/decorations';
import { textFormattingPlugin } from '@atlaskit/editor-plugins/text-formatting';
import { hyperlinkPlugin } from '@atlaskit/editor-plugin-hyperlink';
import { codeBlockPlugin } from '@atlaskit/editor-plugins/code-block';
import { compositionPlugin } from '@atlaskit/editor-plugins/composition';
import { insertBlockPlugin } from '@atlaskit/editor-plugins/insert-block';
import { pastePlugin } from '@atlaskit/editor-plugins/paste';
import { betterTypeHistoryPlugin } from '@atlaskit/editor-plugins/better-type-history';
import { toolbarListsIndentationPlugin } from '@atlaskit/editor-plugin-toolbar-lists-indentation';
import { floatingToolbarPlugin } from '@atlaskit/editor-plugins/floating-toolbar';
import { copyButtonPlugin } from '@atlaskit/editor-plugins/copy-button';
import { textColorPlugin } from '@atlaskit/editor-plugins/text-color';
import { quickInsertPlugin } from '@atlaskit/editor-plugins/quick-insert';
import { editorDisabledPlugin } from '@atlaskit/editor-plugins/editor-disabled';
import type { Appearance, Features } from '../../types';

const shouldShow = (option?: boolean) => {
	// if option is not defined, return false
	if (option === undefined) {
		return false;
	}
	return option;
};

type CreatePreset = (
	builder: EditorPresetBuilder,
	appearance: Appearance,
	features?: Features,
) => any;

export const createPreset: CreatePreset = (builder, appearance, features) => {
	const defaultFeatures: Features = {
		blockType: true,
		textFormatting: true,
		list: true,
		insertBlock: true,
		textColor: true,
		hyperLink: true,
		quickInsert: true,
		codeBlock: true,
	};

	// if `features` is not defined, set all options to true
	// if `features` is provided, only options that are set to true by the developer will render. This is so any new plugin additions made by us will not be rendered by default as the developer has intentionally only set certain options to appear
	const enabledPlugins = features ?? defaultFeatures;

	return builder
		.add(basePlugin)
		.maybeAdd(blockTypePlugin, shouldShow(enabledPlugins?.blockType)) // allows for different text tags(paragraphs, heading levels) and quote blocks to be added
		.add(betterTypeHistoryPlugin) // required for paste plugin
		.add([pastePlugin, {}]) // allows links to be converted to hyper link on paste
		.add(typeAheadPlugin)
		.maybeAdd(quickInsertPlugin, shouldShow(enabledPlugins?.quickInsert)) // allows for `/` to insert nodes from a menu
		.maybeAdd(textFormattingPlugin, shouldShow(enabledPlugins?.textFormatting))
		.maybeAdd(textColorPlugin, shouldShow(enabledPlugins?.textColor))
		.maybeAdd(listPlugin, shouldShow(enabledPlugins?.list)) // required for `-` to insert lists
		.maybeAdd(
			[
				toolbarListsIndentationPlugin, // adds the list indentation section to the toolbar
				{ showIndentationButtons: false, allowHeadingAndParagraphIndentation: false },
			],
			shouldShow(enabledPlugins?.list),
		)
		.add(copyButtonPlugin) // required for floating toolbar plugins
		.add(editorDisabledPlugin) // required for floating toolbar and `disabled` prop
		.add(decorationsPlugin) // required for floating toolbar
		.add(floatingToolbarPlugin) // required for toolbar to appear under links
		.maybeAdd(
			[
				hyperlinkPlugin, // allows for hyper link to be added to text
				{
					editorAppearance: appearance,
					lpLinkPicker: true,
				},
			],
			shouldShow(enabledPlugins?.hyperLink),
		)
		.add(compositionPlugin) // required for codeblock
		.maybeAdd([codeBlockPlugin, {}], shouldShow(enabledPlugins?.codeBlock))
		.maybeAdd(
			[
				insertBlockPlugin, // this controls the last set of toolbar insertions(link, codeblock and quote block)
				{
					appearance,
				},
			],
			shouldShow(enabledPlugins?.insertBlock),
		);
};
