import { query } from '@confluence/query-preloader-tools';
import { fg } from '@confluence/feature-gating';

import { TopNavigationQuery } from './TopNavigationQuery.graphql';

export function preloadTopNavigation() {
	return query({
		query: TopNavigationQuery,
		variables: {
			includeEmails:
				fg('confluence_dev_tools_from_flag_icon') || fg('confluence_nav_4_account_menu'),
			includeNav4AdminOptIn: fg('confluence_nav4_ga_phase_1'),
		},
	});
}
