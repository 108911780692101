import { type ComponentPropsConfig } from '../../../utils/codegen';

/**
 * A `TabList` groups `Tab` components together.
 */
export const componentPropsConfig: ComponentPropsConfig = {
	children: {
		value: "props.forgeDoc.children.flatMap((c) => c.type === 'String' ? [] : props.render(c))",
		comment: 'Filter out line spacing strings as TabList should only have Tab children',
	},
};

/**
 * THIS SECTION WAS CREATED VIA CODEGEN DO NOT MODIFY {@see http://go/af-codegen}
 *
 * This component was generated against an existing platform component.
 * If you are seeing lint errors in this file, it means that this file's dependencies have been modified and the component will need to be regenerated.
 *
 * Please view https://hello.atlassian.net/wiki/spaces/ECO/pages/3358130330/How+to+Regenerate+UI+Kit+Components for more information on how to proceed.
 *
 * For further questions, please ping the #help-forge-ui channel.
 *
 * @codegen <<SignedSource::690f6ba485bd17247fe85affb6179988>>
 * @codegenId #tab-list
 * @codegenCommand yarn workspace @atlassian/forge-ui codegen tab-list
 * @codegenDependency ../../../../../../../design-system/tabs/src/types.tsx <<SignedSource::f2168d42d44e8f2d02748d5db80bad13>>
 * @codegenDependency ../../../../../../../design-system/tabs/src/hooks.tsx <<SignedSource::a657d685e36a99316aa148260b467552>>
 * @codegenDependency ../../../../../../../design-system/tabs/src/internal/context.tsx <<SignedSource::7ce8eeffe9338a642187bc5baebb0242>>
 * @codegenDependency ../../../../../../../design-system/tabs/src/components/tab-list.tsx <<SignedSource::6149bb243cc6f703e66034f910b94134>>
 */
/* eslint @repo/internal/codegen/signed-source-integrity: "warn" */
import React from 'react';
import type { RenderFn } from '@atlassian/forge-ui-types';
import { TabList as PlatformTabList } from '@atlaskit/tabs';

type PlatformTabListProps = React.ComponentProps<typeof PlatformTabList>;

export type TabListProps = Pick<
  PlatformTabListProps,
  'children'
>;

/**
 * A `TabList` groups `Tab` components together.
 */
export const TabList = (props: Parameters<RenderFn>[0]) => {
  const {
  } = props.forgeDoc.props as TabListProps;
  return (
    <PlatformTabList
      // Filter out line spacing strings as TabList should only have Tab children
      children={props.forgeDoc.children.flatMap((c) => c.type === 'String' ? [] : props.render(c))}
    />
  );
};
/**
 * @codegenEnd
 */
