/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum BulkSetSpacePermissionSpaceType {
  COLLABORATION = "COLLABORATION",
  GLOBAL = "GLOBAL",
  KNOWLEDGE_BASE = "KNOWLEDGE_BASE",
  PERSONAL = "PERSONAL",
}

export enum BulkSetSpacePermissionSubjectType {
  GROUP = "GROUP",
  USER = "USER",
}

export enum SpacePermissionType {
  ADMINISTER_SPACE = "ADMINISTER_SPACE",
  ARCHIVE_PAGE = "ARCHIVE_PAGE",
  COMMENT = "COMMENT",
  CREATE_ATTACHMENT = "CREATE_ATTACHMENT",
  CREATE_EDIT_PAGE = "CREATE_EDIT_PAGE",
  EDIT_BLOG = "EDIT_BLOG",
  EXPORT_PAGE = "EXPORT_PAGE",
  EXPORT_SPACE = "EXPORT_SPACE",
  REMOVE_ATTACHMENT = "REMOVE_ATTACHMENT",
  REMOVE_BLOG = "REMOVE_BLOG",
  REMOVE_COMMENT = "REMOVE_COMMENT",
  REMOVE_MAIL = "REMOVE_MAIL",
  REMOVE_OWN_CONTENT = "REMOVE_OWN_CONTENT",
  REMOVE_PAGE = "REMOVE_PAGE",
  SET_PAGE_PERMISSIONS = "SET_PAGE_PERMISSIONS",
  VIEW_SPACE = "VIEW_SPACE",
}

//==============================================================
// END Enums and Input Objects
//==============================================================

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: BulkSpacePermissionsMutation
// ====================================================

export interface BulkSpacePermissionsMutation_bulkSetSpacePermission_errors_extensions {
  errorType: string | null;
  statusCode: number | null;
}

export interface BulkSpacePermissionsMutation_bulkSetSpacePermission_errors {
  message: string | null;
  extensions: (BulkSpacePermissionsMutation_bulkSetSpacePermission_errors_extensions | null)[] | null;
}

export interface BulkSpacePermissionsMutation_bulkSetSpacePermission {
  success: boolean;
  errors: BulkSpacePermissionsMutation_bulkSetSpacePermission_errors[] | null;
  spacesUpdatedCount: number;
}

export interface BulkSpacePermissionsMutation {
  bulkSetSpacePermission: BulkSpacePermissionsMutation_bulkSetSpacePermission | null;
}

export interface BulkSpacePermissionsMutationVariables {
  spacePermissions: (SpacePermissionType | null)[];
  spaceTypes: (BulkSetSpacePermissionSpaceType | null)[];
  subjectId: string;
  subjectType: BulkSetSpacePermissionSubjectType;
}
