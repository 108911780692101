import { redirectToLoginPage } from '@confluence-classic/confluence-authentication';
import { RELATION_ENTITIES, SPACE } from './endpoints';
import { spaceResponseTransformer } from './transformers';

/**
 * Helper methods for fetching spaces.
 * @module confluence-rest-api/spaces
 */
import requestHelper from './request-helper';

/**
 * Add a relationship between a space and current user.
 *
 * @param {Object} options
 * @param {String} options.relation - The relation between 2 entities
 * @param {String} options.key - The id of the entity
 * @param {String} options.action - The action to the entity
 * @param callback - The callback that handles the response.
 */
const relations = (options, callback) => {
	const params = {
		sourceType: 'user',
		sourceKey: 'current',
		relationName: options.relation,
		targetType: 'Space',
		targetKey: options.key,
	};
	const url = requestHelper.getFullPath(RELATION_ENTITIES, params);
	if (options.action === 'add') {
		requestHelper.put(url, {
			callback,
			httpCodeHandlers: { 401: () => redirectToLoginPage() },
			routeName: 'RELATION_ENTITIES',
		});
	} else if (options.action === 'delete') {
		requestHelper.delete(url, {
			callback,
			httpCodeHandlers: { 401: () => redirectToLoginPage() },
			routeName: 'RELATION_ENTITIES',
		});
	}
};

/**
 * Fetching a space by space key
 * @param options
 * @param callback
 *
 * // ES6
 * import { spaces } from '@confluence-classic/confluence-rest-api';
 * spaces.space({spaceKey: 'ds'}, (err, response) => {
 *     // ...
 * });
 *
 * // ES5
 * var spaces = require('@confluence-classic/confluence-rest-api').spaces;
 * spaces.space({spaceKey: 'ds'}, function(err, response) {
 *     // ...
 * });
 */
export function space(options, callback) {
	const defaultSpaceExpansions = 'icon,metadata.labels,operations,lookAndFeel,settings,theme';
	const url = requestHelper.getFullPath(SPACE, {
		spaceKey: options.spaceKey,
		expand: options.expand ? options.expand : defaultSpaceExpansions,
	});
	requestHelper.get(url, {
		callback,
		httpCodeHandlers: { 401: () => redirectToLoginPage() },
		responseTransformer: spaceResponseTransformer,
		routeName: 'SPACE',
	});
}
