import React from 'react';

import AKIcon, { type IconProps as AKIconProps, type CustomGlyphProps } from '@atlaskit/icon';
import EmojiNeutralIcon from '@atlaskit/icon/core/emoji-neutral';

const IconGlyph = (props: CustomGlyphProps) => (
	<svg
		width="24"
		height="24"
		viewBox="0 0 24 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		role="presentation"
		// Ignored via go/ees005
		// eslint-disable-next-line react/jsx-props-no-spreading
		{...props}
	>
		<path
			d="M20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20C16.4183 20 20 16.4183 20 12Z"
			stroke="currentColor"
			strokeWidth="2"
		/>
		<path
			d="M11 9.5C11 8.67157 10.3284 8 9.5 8C8.67157 8 8 8.67157 8 9.5C8 10.3284 8.67157 11 9.5 11C10.3284 11 11 10.3284 11 9.5Z"
			fill="currentColor"
		/>
		<path
			d="M16 9.5C16 8.67157 15.3284 8 14.5 8C13.6716 8 13 8.67157 13 9.5C13 10.3284 13.6716 11 14.5 11C15.3284 11 16 10.3284 16 9.5Z"
			fill="currentColor"
		/>
		<path
			d="M15 13.5H9C8.44772 13.5 8 13.9477 8 14.5C8 15.0523 8.44772 15.5 9 15.5H15C15.5523 15.5 16 15.0523 16 14.5C16 13.9477 15.5523 13.5 15 13.5Z"
			fill="currentColor"
		/>
	</svg>
);

export const ToneNeutralIcon = () => (
	<EmojiNeutralIcon
		color="currentColor"
		label=""
		spacing="spacious"
		// Ignored via go/ees005
		// eslint-disable-next-line react/jsx-props-no-spreading
		LEGACY_fallbackIcon={(props: AKIconProps) => <AKIcon glyph={IconGlyph} {...props} />}
	/>
);
