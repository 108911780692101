import React, { type FunctionComponent } from 'react';
import { Inline, Text } from '@atlaskit/primitives';

const AKLabel = React.lazy(() =>
	import(
		/* webpackChunkName: '@atlaskit-internal_.form' */
		'@atlaskit/form'
	).then((module) => ({
		default: module.Label,
	})),
);

interface Props {
	isRequired?: boolean;
	label: string;
	name: string;
}

export const Label: FunctionComponent<Props> = ({ isRequired, label, name }) => {
	return (
		<AKLabel htmlFor={name} id={`${name}-label`}>
			<Inline space="space.025">
				{label}
				{isRequired && (
					<Text as="strong" color="color.text.danger" size="UNSAFE_small">
						*
					</Text>
				)}
			</Inline>
		</AKLabel>
	);
};
