import { getPlatformFeatureFlags } from './getFeatureFlags';

export const getSrcUrl = (
	tunnels: { resourceKey: string; tunnelUrl: string }[],
	extensionResource: string,
	defaultUrl: string,
) => {
	const platformFeatureFlags = getPlatformFeatureFlags();
	const featureFlagsQueryparams =
		platformFeatureFlags.length > 0
			? `?${new URLSearchParams({
					platformFeatureFlags: platformFeatureFlags.join(','),
				}).toString()}`
			: '';
	const matchTunnel = tunnels.find((tunnel) => tunnel.resourceKey === extensionResource);
	if (matchTunnel) {
		return `${matchTunnel.tunnelUrl}${featureFlagsQueryparams}`;
	}
	return `${defaultUrl}${featureFlagsQueryparams}`;
};
