import React from 'react';
import type { IntlShape } from 'react-intl-next';
import { defineMessages } from 'react-intl-next';

import type { EditorActions } from '@atlaskit/editor-core';
import type { ExtensionManifest } from '@atlaskit/editor-common/extensions';
import AngleBracketsIcon from '@atlaskit/icon/core/angle-brackets';

import { TemplateVariableInput, TemplateVariableComponent } from '@confluence/template-variable';
import type { MacroConfig } from '@confluence/fabric-extension-lib/entry-points/fabric-extension-lib-types';
import {
	EXTENSION_TYPE,
	EXTENSION_KEY,
} from '@confluence/fabric-extension-lib/entry-points/extensionConstants';
import type { TemplateVariableParams } from './types';
import { TEXT_TYPE, defaultVariableName, openTemplateVariableDialog } from './index';

export enum TemplateVariableMode {
	CONFIG,
	INPUT,
}

const i18n = defineMessages({
	templateVariablesAdd: {
		id: 'confluence-fabric-editor.template.variables.add',
		defaultMessage: 'New variable',
		description: 'Extension title text that shows up in extensions menu on the template editor',
	},
	templateVariablesAddTooltip: {
		id: 'confluence-fabric-editor.template.variables.add.tooltip',
		defaultMessage: 'Add a new field into the page for the template user to fill out.',
		description:
			'Extension description text that shows up in extensions menu on the template editor',
	},
});

export function getTemplateVariableExtensionManifest(
	intl: IntlShape,
	mode: TemplateVariableMode = TemplateVariableMode.CONFIG,
	config?: MacroConfig,
	editorActions?: EditorActions,
): ExtensionManifest<TemplateVariableParams> {
	const type = 'inlineExtension';
	const title = intl.formatMessage(i18n.templateVariablesAdd);
	const Component =
		mode === TemplateVariableMode.INPUT ? TemplateVariableInput : TemplateVariableComponent;

	return {
		key: EXTENSION_KEY.TEMPLATE,
		type: EXTENSION_TYPE.TEMPLATE,
		title,
		description: intl.formatMessage(i18n.templateVariablesAddTooltip),
		icons: {
			'48': async () => () => (
				<AngleBracketsIcon color="currentColor" label={title} spacing="spacious" />
			),
		},
		modules: {
			quickInsert: editorActions && [
				{
					key: EXTENSION_KEY.TEMPLATE,
					featured: true,
					action: {
						type: 'node',
						key: 'default',
						parameters: {
							type: TEXT_TYPE,
							name: defaultVariableName,
						},
					},
				},
			],
			nodes: {
				default: {
					type,
					render:
						async () =>
						({ node }) => <Component parameters={node.parameters} />,
					update:
						config &&
						(async (parameters) => {
							try {
								const { attrs } = await openTemplateVariableDialog(
									{ type, attrs: { parameters } },
									config,
								);
								return (attrs.parameters as TemplateVariableParams) || parameters;
							} catch {
								// On error keep the existing value
								return parameters;
							}
						}),
				},
			},
		},
	};
}
