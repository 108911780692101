import { makeXCSSValidator } from './xcssValidate';

export const xcssValidator = makeXCSSValidator({
	// color related props
	color: true,
	boxShadow: true,
	opacity: true,
	backgroundColor: true,
	borderColor: true,
	borderBlockColor: true,
	borderBlockEndColor: true,
	borderBlockStartColor: true,
	borderBottomColor: true,
	borderInlineColor: true,
	borderInlineEndColor: true,
	borderInlineStartColor: true,
	borderLeftColor: true,
	borderRightColor: true,
	borderTopColor: true,

	overflow: {
		supportedValues: ['hidden', 'visible', 'scroll', 'auto'],
	},

	overflowX: {
		supportedValues: ['hidden', 'visible', 'scroll', 'auto'],
	},
	overflowY: {
		supportedValues: ['hidden', 'visible', 'scroll', 'auto'],
	},

	// layout and space related props
	display: {
		supportedValues: ['block', 'inline-block', 'inline', 'none'],
	},
	flexGrow: {
		allowCSS: true,
	},
	width: {
		allowCSS: true,
	},
	height: {
		allowCSS: true,
	},
	minWidth: {
		allowCSS: true,
	},
	maxWidth: {
		allowCSS: true,
	},
	minHeight: {
		allowCSS: true,
	},
	maxHeight: {
		allowCSS: true,
	},
	margin: true,
	marginBlock: true,
	marginBlockEnd: true,
	marginBlockStart: true,
	marginBottom: true,
	marginInline: true,
	marginInlineEnd: true,
	marginInlineStart: true,
	marginLeft: true,
	marginRight: true,
	marginTop: true,
	padding: true,
	paddingBlock: true,
	paddingBlockEnd: true,
	paddingBlockStart: true,
	paddingBottom: true,
	paddingInline: true,
	paddingInlineEnd: true,
	paddingInlineStart: true,
	paddingLeft: true,
	paddingRight: true,
	paddingTop: true,

	// other box related props
	borderRadius: { supportedValues: ['border.radius'] },
	borderBottomLeftRadius: { supportedValues: ['border.radius'] },
	borderBottomRightRadius: { supportedValues: ['border.radius'] },
	borderTopLeftRadius: { supportedValues: ['border.radius'] },
	borderTopRightRadius: { supportedValues: ['border.radius'] },
	borderEndEndRadius: { supportedValues: ['border.radius'] },
	borderEndStartRadius: { supportedValues: ['border.radius'] },
	borderStartEndRadius: { supportedValues: ['border.radius'] },
	borderStartStartRadius: { supportedValues: ['border.radius'] },
	borderWidth: { supportedValues: ['border.width'] },
	borderBlockWidth: { supportedValues: ['border.width'] },
	borderBlockEndWidth: { supportedValues: ['border.width'] },
	borderBlockStartWidth: { supportedValues: ['border.width'] },
	borderBottomWidth: { supportedValues: ['border.width'] },
	borderInlineWidth: { supportedValues: ['border.width'] },
	borderInlineEndWidth: { supportedValues: ['border.width'] },
	borderInlineStartWidth: { supportedValues: ['border.width'] },
	borderLeftWidth: { supportedValues: ['border.width'] },
	borderRightWidth: { supportedValues: ['border.width'] },
	borderTopWidth: { supportedValues: ['border.width'] },

	// other props not in tokens based props
	borderTopStyle: {
		supportedValues: ['dotted', 'dashed', 'solid', 'none', 'hidden'],
	},
	borderBottomStyle: {
		supportedValues: ['dotted', 'dashed', 'solid', 'none', 'hidden'],
	},
	borderRightStyle: {
		supportedValues: ['dotted', 'dashed', 'solid', 'none', 'hidden'],
	},
	borderLeftStyle: {
		supportedValues: ['dotted', 'dashed', 'solid', 'none', 'hidden'],
	},
	borderStyle: {
		supportedValues: ['dotted', 'dashed', 'solid', 'none', 'hidden'],
	},
	position: {
		supportedValues: ['relative', 'static'],
	},
});

export type XCSSProp = ReturnType<typeof xcssValidator>;
