import { PerformanceObserverEntryTypes } from '../../const';
import { EntriesBuffer } from '../../utils/buffer';

let pe: PerformanceObserver | null = null;

const getObserver = (): PerformanceObserver => {
	if (pe !== null) {
		return pe;
	}
	pe = new PerformanceObserver((list) => {
		list.getEntries().forEach((entry) => {
			if (entry.entryType === PerformanceObserverEntryTypes.LayoutShift) {
				EntriesBuffer[PerformanceObserverEntryTypes.LayoutShift].push(entry);
			}
			if (entry.entryType === PerformanceObserverEntryTypes.LongTask) {
				EntriesBuffer[PerformanceObserverEntryTypes.LongTask].push(entry);
			}
			if (entry.entryType === PerformanceObserverEntryTypes.Event) {
				EntriesBuffer[PerformanceObserverEntryTypes.Event].push(entry);
			}
		});
	});

	return pe;
};

export const startLightHouseObservers = () => {
	if (pe) {
		// Disconnect the observer if it's already set up
		pe.disconnect();
		pe = null;
	}
	for (const type of Object.values(PerformanceObserverEntryTypes)) {
		getObserver().observe({
			type,
			buffered: true,
		});
	}
};
