import React from 'react';
import type { RenderFn } from '@atlassian/forge-ui-types';
import { withMacroStyleWrapper } from '../macroStyleWrapper';

const PlatformHorizontalStackBarChart = React.lazy(() =>
	// eslint-disable-next-line import/dynamic-import-chunkname
	import(
		/* webpackChunkName: '@forge-ui_horizontalstackbarchart' */
		'@atlassian/viz-platform-charts/charts'
	).then((module) => ({ default: module.HorizontalStackBarChart })),
);

const Title = React.lazy(() =>
	// eslint-disable-next-line import/dynamic-import-chunkname
	import(
		/* webpackChunkName: '@forge-ui_horizontalstackbarchart' */
		'@atlassian/viz-platform-charts/charts'
	).then((module) => ({ default: module.HorizontalStackBarChart.elements.Title })),
);

const SubTitle = React.lazy(() =>
	// eslint-disable-next-line import/dynamic-import-chunkname
	import(
		/* webpackChunkName: '@forge-ui_horizontalstackbarchart' */
		'@atlassian/viz-platform-charts/charts'
	).then((module) => ({ default: module.HorizontalStackBarChart.elements.SubTitle })),
);

type PlatformHorizontalStackBarChartProps = React.ComponentProps<
	typeof PlatformHorizontalStackBarChart
>;
/**
 * Excluded props:
 * - children - we will not be supporting primitives/elements to compose the chart
 *
 * Limited props:
 * - xAccessor - only allow non function types
 * - yAccessor - only allow non function types
 * - colorAccessor - only allow non function types
 */
export type HorizontalStackBarChartProps = Pick<
	PlatformHorizontalStackBarChartProps,
	'width' | 'height' | 'data'
> & {
	xAccessor: number | string;
	yAccessor: number | string;
	colorAccessor: number | string;
	title?: string;
	subtitle?: string;
};
const BaseHorizontalStackBarChart = (props: Parameters<RenderFn>[0]) => {
	const { width, height, data, xAccessor, yAccessor, colorAccessor, title, subtitle } = props
		.forgeDoc.props as HorizontalStackBarChartProps;

	return (
		<PlatformHorizontalStackBarChart
			width={width}
			height={height ? height : 400}
			data={data}
			xAccessor={xAccessor}
			yAccessor={yAccessor}
			colorAccessor={colorAccessor}
		>
			{title && <Title>{title}</Title>}
			{subtitle && <SubTitle>{subtitle}</SubTitle>}
		</PlatformHorizontalStackBarChart>
	);
};

export const HorizontalStackBarChart = withMacroStyleWrapper(BaseHorizontalStackBarChart);
