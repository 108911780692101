import { preloadContent } from '@confluence/content-body/entry-points/preloadContent';
import { preloadContentHeader } from '@confluence/content-header/entry-points/preloadContentHeader';
import { preloadContentPrerequisites } from '@confluence/content-prerequisites/entry-points/preloadContentPrerequisites';
import { preloadContentSmartLinks } from '@confluence/content-smartlinks/entry-points/preloadContentSmartLinks';
import { preloadContentTypesHeader } from '@confluence/content-types-header/entry-points/preloadContentTypesHeader';
import { preloadCustomHeaderAndFooter } from '@confluence/custom-header-footer/entry-points/preloadCustomHeaderAndFooter';
import { preloadPageTitleContentProperties } from '@confluence/content-topper/entry-points/preloadPageTitleContentProperties';
import { preloadMacrosSSR } from '@confluence/fabric-extension-handlers/entry-points/preloadMacrosSSR';
import { preloadMediaToken } from '@confluence/fabric-media-support/entry-points/preloadMediaToken';
import { preloadIsAttachmentDownloadEnabled } from '@confluence/fabric-media-support/entry-points/preloadIsAttachmentDownloadEnabled';
import { preloadFocusedInlineComment } from '@confluence/inline-comments-queries/entry-points/preloadFocusedInlineComment';
import { preloadInlineComments } from '@confluence/inline-comments-queries/entry-points/preloadInlineComments';
import { preloadInlineHighlights } from '@confluence/inline-highlights-query/entry-points/preloadInlineHighlights';
import { preloadAIFloatingContextMenu } from '@confluence/ai-floating-context-menu/entry-points/preloadAIFloatingContextMenu';
import { COMPANY_HUB_EDIT, EDIT_BLOG_V2, EDIT_PAGE_V2 } from '@confluence/named-routes';
import { preloadEndOfPageRecommendation } from '@confluence/page-recommendations/entry-points/preloadEndOfPageRecommendation';
import { preloadRecordLoomButton } from '@confluence/loom-utils/entry-points/preloadRecordLoomButton';
import { preloadPresenceSettings } from '@confluence/team-presence/entry-points/preloadPresenceSettings';
import { AccessStatus } from '@confluence/session-data';
import {
	getPreloaderFnContext,
	prepareGuardExceptionTask,
	createWrappedPreloader,
} from '@confluence/query-preloader-tools';
import { preloadPageReactions } from '@confluence/reactions/entry-points/preloadPageReactions';
import type { RouteMatch } from '@confluence/route';
import { preloadSpaceDetail } from '@confluence/space-utils/entry-points';
import { preloadPageStatusQuery } from '@confluence/view-page-common/entry-points/preloadPageStatusQuery';
import { preloadLegacyBridge } from '@confluence/view-page/entry-points/preloadLegacyBridge';
import {
	ATL_PAGE_CONTENT_FOOTER_ACTIONS,
	ATL_GENERAL,
} from '@confluence/web-item-location/entry-points/WEB_FRAGMENT_LOCATIONS';
import { preloadWebPanelLocation } from '@confluence/web-panel-location/entry-points/preloadWebPanelLocation';
import { preloadLabels } from '@confluence/labels/entry-points/preloadLabels';
import { fg } from '@confluence/feature-gating';
import { FORGE_MODULE_XEN_MACRO } from '@confluence/forge-ui/entry-points/ForgeModuleType';
import { preloadUseExtensionList } from '@confluence/forge-ui/entry-points/preloadUseExtensionList';
import { preloadShareAndRestrictButtonQuery } from '@confluence/share-and-restrict-dialog/entry-points/preloadShareAndRestrictButtonQuery';

import {
	getIsEmbeddedConfluence,
	getUsername,
	hasEmbeddedAllowedFeatures,
	shouldPreloadNavigationForTransition,
} from './matchHelpers';
import { matchBlogPage } from './matchRoutes';
import { preloadNavigationTasks } from './preloadNavigationTasks';

/*
 * preloadViewPageRoute preload queries are currently arranged in order of latency, with slowest
 * queries pushed to the tasks array first.
 */
export const preloadViewPageRoute = async (
	match: RouteMatch,
	url: string,
	isTransition: boolean = false,
	previousMatch: RouteMatch | null = null,
) => {
	const {
		featureFlags,
		isLicensed,
		userId,
		edition,
		cloudId,
		accessStatus,
		isLoggedIn,
		environment,
		isAdminHubAIEnabled,
	} = await getPreloaderFnContext();
	const tasks: Promise<any>[] = [];
	const { spaceKey, contentId } = match.params;
	const username = getUsername(match);
	const isEmbeddedConfluence = getIsEmbeddedConfluence(match);
	const isBlog = Boolean(matchBlogPage(url));
	const isInitialLoad = !isTransition;
	const shouldPreloadNavigation =
		!isEmbeddedConfluence && (isInitialLoad || shouldPreloadNavigationForTransition(previousMatch));

	// Don't preload queries that will be updated by editor if previous route is edit_page
	const isPrevEditRoute =
		previousMatch?.name === EDIT_PAGE_V2.name ||
		previousMatch?.name === EDIT_BLOG_V2.name ||
		previousMatch?.name === COMPANY_HUB_EDIT.name;

	if (isPrevEditRoute && isTransition) {
		// Preload for edit -> view transition
		return preloadContentHeader({
			spaceKey,
			contentId,
			isLicensed,
			userId,
			username,
			edition,
			isPrevEditRoute,
			useNewContentTopper: true,
			isBlog,
		});
	}

	// 1. Content + Byline
	const preloadContentAndByline = [
		prepareGuardExceptionTask('Macros', () =>
			preloadMacrosSSR({
				contentId,
				useMultipleMacrosQuery: true,
			}),
		),
		prepareGuardExceptionTask('Content', () =>
			preloadContent({
				contentId,
				isBlog,
				spaceKey,
				featureFlags,
			}).then(({ result, hasErrors }): Promise<any> | void => {
				// In case of errors on preloading content the content data may not make it to the
				// Apollo cache. To ensure we can try handling errors or missing content data cases
				// on SSR we preload content prerequisites here.
				// We don't want to preload content dependencies as in case of errors and/or missing
				// content this may result in unnecessary queries.
				if (hasErrors) {
					return preloadContentPrerequisites(contentId, spaceKey);
					// eslint-disable-next-line check-react-ssr-usage/no-react-ssr
				} else if (process.env.REACT_SSR) {
					return preloadMacrosSSR({
						contentId,
						useMultipleMacrosQuery: false,
						contentNodes: result?.data?.content?.nodes,
					});
				}
			}),
		),
		preloadContentHeader({
			spaceKey,
			contentId,
			isLicensed,
			userId,
			username,
			edition,
			isPrevEditRoute,
			useNewContentTopper: true,
			isBlog,
		}),
		preloadPageStatusQuery(contentId),
		preloadLegacyBridge({ contentId, isBlog }),
	];

	// eslint-disable-next-line check-react-ssr-usage/no-react-ssr
	if (process.env.REACT_SSR && fg('confluence_react_streaming')) {
		createWrappedPreloader({
			promise: preloadContentAndByline,
			preloaderName: `preloadContentAndByline`,
		});
	} else {
		tasks.push(...preloadContentAndByline);
	}

	// 2. Content types header (object header)
	if (fg('confluence_frontend_object_header')) {
		tasks.push(preloadContentTypesHeader(contentId, fg('confluence_frontend_object_header')));
		// (No need to call preloadPageRestrictionsQuery() here; it will be called by
		//  preloadContentHeader() above)
	}

	// 2a. Unified Share and Restrict Button
	if (fg('confluence_frontend_usd_ssr')) {
		tasks.push(preloadShareAndRestrictButtonQuery(contentId));
	}

	// 3. Page title
	tasks.push(preloadPageTitleContentProperties({ contentId, spaceKey }));

	// 4. Space details
	tasks.push(preloadSpaceDetail(spaceKey));

	// 5. Content Smart Links
	tasks.push(preloadContentSmartLinks(contentId));

	// 6. Navigation & 6. Page tree + blog tree for blog post
	if (shouldPreloadNavigation) {
		tasks.push(preloadNavigationTasks(spaceKey, contentId, isLicensed, isBlog));
	}

	// 7. Inline Comments
	if (match?.query.focusedCommentId) {
		tasks.push(preloadFocusedInlineComment(match?.query.focusedCommentId as string, contentId));
	}
	if (!isEmbeddedConfluence || hasEmbeddedAllowedFeatures('inline-comments', match)) {
		// when rendering an embedded confluence page, we do not want to preload inline comments if they are not requested via the allowlist features
		tasks.push(preloadInlineHighlights(contentId));
		tasks.push(preloadInlineComments({ pageId: contentId }));
	}

	// 8. Reactions
	if (!isEmbeddedConfluence || hasEmbeddedAllowedFeatures('page-reactions', match)) {
		// Preload whether the server has enabled reactions at all to show placeholder
		tasks.push(
			preloadWebPanelLocation({
				contentId,
				location: ATL_PAGE_CONTENT_FOOTER_ACTIONS,
			}),
		);
		// Preload reactions asynchronously so SSR won't wait for it
		// it's best effort preloading so if it preloads then we have the data, otherwise SPA can load it
		if (fg('confluence_ssr_reactions_preload')) {
			tasks.push(preloadPageReactions(contentId));
		}
	}

	// 9. Recommendations
	if (fg('ssr_end_of_page_recommendation')) {
		tasks.push(preloadEndOfPageRecommendation({ contentId, spaceKey }));
	}

	// 10. Stuff only needed for initial load
	if (isInitialLoad) {
		tasks.push(
			// We only refresh token when it is about to expire.
			preloadMediaToken(contentId),
			preloadCustomHeaderAndFooter(spaceKey),
		);
		if (fg('dlp_attachment_download_respects_data_export_dsp')) {
			tasks.push(preloadIsAttachmentDownloadEnabled(contentId, spaceKey));
		}
	}

	// 11. Labels
	tasks.push(preloadLabels({ contentId }));

	// 12. Preload web panel atl_general
	if (fg('confluence_preload_webpanel_atl_general')) {
		tasks.push(
			preloadWebPanelLocation({
				contentId,
				location: ATL_GENERAL,
			}),
		);
	}

	// 13. Object Sidebar Control + AI Button
	if (isAdminHubAIEnabled && fg('confluence_frontend_object_sidebar_ssr')) {
		tasks.push(preloadAIFloatingContextMenu(contentId, isBlog));
	}

	// 14. Preload forge extension manifest
	if (fg('confluence_preload_forge_viewport_heights')) {
		tasks.push(
			preloadUseExtensionList({
				contentId,
				spaceKey,
				moduleType: FORGE_MODULE_XEN_MACRO,
			}),
		);
	}

	// 15. Preload Loom button in Action Bar
	if (
		(accessStatus === AccessStatus.LICENSED_ADMIN_ACCESS ||
			accessStatus === AccessStatus.LICENSED_USE_ACCESS) &&
		isLoggedIn &&
		fg('confluence_frontend_object_sidebar_ssr')
	) {
		tasks.push(
			preloadRecordLoomButton({
				cloudId,
				accessStatus,
				isLoggedIn,
				userId,
				environment,
			}),
		);
	}

	// TODO: These flags are dependant on each other, we don't want to SSR this data unless presence is actually on
	if (fg('confluence_team_presence_general_availability')) {
		if (fg('confluence_team_presence_ssr')) {
			tasks.push(preloadPresenceSettings({ cloudId, spaceKey }));
		}
	}

	return Promise.all(tasks);
};
