/**
 * @jsxRuntime classic
 * @jsx jsx
 */
import { css, jsx } from '@compiled/react';
import { token } from '@atlaskit/tokens';
import { useIsMacro } from '../../utils/useIsMacro';
import { adaptEventHandler } from '../../utils';
import React from 'react';
import type { RenderFn } from '@atlassian/forge-ui-types';
import { LoadingButton as PlatformLoadingButton } from '@atlaskit/button';

const buttonStyle = css({
	font: token('font.body'),
});

type PlatformLoadingButtonProps = React.ComponentProps<typeof PlatformLoadingButton>;

export type LoadingButtonProps = Pick<
	PlatformLoadingButtonProps,
	| 'children'
	| 'appearance'
	| 'autoFocus'
	| 'isDisabled'
	| 'isSelected'
	| 'onBlur'
	| 'onClick'
	| 'onFocus'
	| 'spacing'
	| 'testId'
	| 'shouldFitContainer'
	| 'type'
	| 'isLoading'
>;

/**
 * A button that shows an spinner as an overlay on the button when you set an isLoading prop to true.
 */
export const LoadingButton = (props: Parameters<RenderFn>[0]) => {
	const {
		appearance,
		autoFocus,
		isDisabled,
		isSelected,
		onBlur,
		onClick,
		onFocus,
		spacing,
		testId,
		shouldFitContainer,
		type,
		isLoading,
	} = props.forgeDoc.props as LoadingButtonProps;
	const isMacro = useIsMacro();
	return (
		<div css={[isMacro ? buttonStyle : undefined]}>
			<PlatformLoadingButton
				children={props.forgeDoc.children.map((c) => (
					<React.Fragment key={c.key}>{props.render(c)}</React.Fragment>
				))}
				appearance={appearance}
				autoFocus={autoFocus}
				isDisabled={isDisabled}
				isSelected={isSelected}
				onBlur={adaptEventHandler(onBlur)}
				onClick={adaptEventHandler(onClick)}
				onFocus={adaptEventHandler(onFocus)}
				spacing={spacing}
				testId={testId}
				shouldFitContainer={shouldFitContainer}
				type={type}
				isLoading={isLoading}
			/>
		</div>
	);
};
