import { preloadContentPrerequisites } from '@confluence/content-prerequisites/entry-points/preloadContentPrerequisites';
import { preloadContentTypesHeader } from '@confluence/content-types-header/entry-points/preloadContentTypesHeader';
import { fg } from '@confluence/feature-gating';
import { preloadPageRestrictionsQuery } from '@confluence/page-restrictions-context/entry-points/preloadPageRestrictionsQuery';
import { getPreloaderFnContext } from '@confluence/query-preloader-tools';
import type { RouteMatch } from '@confluence/route';
import { preloadPresenceSettings } from '@confluence/team-presence/entry-points/preloadPresenceSettings';

export const preloadContentHistoryRoute = async (match: RouteMatch) => {
	const tasks: Promise<any>[] = [];

	const { contentId, spaceKey } = match.params;

	// Since this query is not called in ContentPrerequisites when contentId is
	// missing, we should follow the same reasoning and skip preload when content is
	// missing.
	if (contentId) {
		tasks.push(preloadContentPrerequisites(contentId, spaceKey));
	}

	if (fg('confluence_frontend_object_header')) {
		tasks.push(preloadContentTypesHeader(contentId, fg('confluence_frontend_object_header')));
		// Standalone Restrictions button needs to have its data preloaded
		tasks.push(preloadPageRestrictionsQuery(contentId, false));
	}

	// TODO: These flags are dependant on each other, we don't want to SSR this data unless presence is actually on
	if (fg('confluence_team_presence_general_availability')) {
		if (fg('confluence_team_presence_ssr')) {
			const preloadedSessionContext = await getPreloaderFnContext();

			const { cloudId } = preloadedSessionContext;

			tasks.push(preloadPresenceSettings({ cloudId, spaceKey }));
		}
	}

	return Promise.all(tasks);
};
