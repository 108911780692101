import React, { useState, useEffect } from 'react';
import type ApolloClient from 'apollo-client';
import SectionMessage from '@atlaskit/section-message';

import {
	type Extension,
	GET_APP_NAME,
	type AppDetails,
	type AppDetailsQueryParams,
} from '../web-client';

export const SANDBOX_DEPRECATION_ERROR = 'INVALID_INVOCATION_RUNTIME_ERROR';

interface ForgeDeprecationMessageProps {
	client?: ApolloClient<any>;
	extension: Extension;
	error?: string;
}

const buildMessage = (title: string, error?: string) => {
	if (error === SANDBOX_DEPRECATION_ERROR) {
		return `Your app ${title} is no longer working as it uses a deprecated platform feature. Please check if an update for this app is available through Atlassian Administration, or ask the app developer to upgrade the app.\nIf the app is listed on the marketplace, contact the developer using the Support tab.`;
	}

	return `${title} version is outdated due to a deprecated platform component.\nThe app developer needs to update it to a compatible version.\nPlease ask your admin to check for updates or contact the app developer.`;
};

export const ForgeDeprecationMessage = (props: ForgeDeprecationMessageProps) => {
	const { client, extension, error } = props;
	const [appName, setAppName] = useState<string | undefined>(undefined);

	useEffect(() => {
		if (client) {
			const response = client.query<AppDetails, AppDetailsQueryParams>({
				query: GET_APP_NAME,
				variables: { id: extension.id },
			});

			response.then((data) => {
				const appName = data.data?.app.name;

				setAppName(appName);
			});
		}
	}, [client, extension.id]);

	const title = appName ? appName : extension.properties.title;
	const message = buildMessage(title, error);

	return (
		<SectionMessage title="App update required" appearance="error">
			{message}
		</SectionMessage>
	);
};
