import { OPERATIONAL_EVENT_TYPE } from '@atlaskit/analytics-gas-types';
import type { CreateUIAnalyticsEvent } from '@atlaskit/analytics-next';
import { useAnalyticsEvents } from '@atlaskit/analytics-next/useAnalyticsEvents';

import { useMetricsContext } from '../context/metrics';
import { FORGE_UI_ANALYTICS_CHANNEL } from '.';
import { useMemoOne } from 'use-memo-one';
import { fg } from '@atlaskit/platform-feature-flags';

type EnvironmentType = 'PRODUCTION' | 'DEVELOPMENT' | 'STAGING';

type Components = Record<
	string,
	{
		count: number;
		propCount: Record<string, number>;
	}
>;

type BaseEventPayload = {
	source: string;
};

type TrackComponentUsageRenderedPayload = BaseEventPayload & {
	isLegacyUiKit: boolean;
	forgeEnvironment: EnvironmentType;
	forgeReactMajorVersion: string | null;
	components: Components;
};

type TrackRendererRenderedPayload = BaseEventPayload & {
	target: 'renderer'; // 'useWebRuntime' is used for the UI Kit 1, and should deprecated in the future clean up
};

type TrackBridgePayload = BaseEventPayload & {
	method: string;
	forgeEnvironment?: EnvironmentType;
	additionalAttributes?: Record<string, unknown>;
};

// For extensibility
type TrackBridgeSucceedPayload = TrackBridgePayload;

type TrackBridgeFailedPayload = TrackBridgePayload & {
	errorName: string;
};

type TrackExtensionLoadedPayload = BaseEventPayload & {
	forgeEnvironment?: EnvironmentType;
	renderType: 'CustomUI' | 'UIKit1' | 'UIKit';
	extensionType: 'extension' | 'inlineExtension' | 'bodiedExtension';
	// Used for tracking custom macro config loading
	isConfiguring?: boolean;
	isInserting?: boolean;
};

type TrackExtensionRemountedPayload = BaseEventPayload & {
	mountCount: number;
};

type FireEventPayload = {
	action: 'succeeded' | 'failed' | 'loaded' | 'rendered' | 'remounted';
	actionSubject:
		| 'forge.ui.bridge'
		| 'forge.ui.analytics.component_usage'
		| 'forge.ui.extension'
		| 'forge.ui.renderer';
	source: string;
	attributes?: Record<string, unknown>;
};

const fireEvent = (
	createAnalyticsEvent: CreateUIAnalyticsEvent,
	{ action, actionSubject, source, attributes }: FireEventPayload,
) =>
	createAnalyticsEvent({
		eventType: OPERATIONAL_EVENT_TYPE,
		data: {
			action,
			actionSubject,
			attributes,
			source,
			tags: ['forge'],
		},
	}).fire(FORGE_UI_ANALYTICS_CHANNEL);

export const trackBridgeSucceed = (
	createAnalyticsEvent: CreateUIAnalyticsEvent,
	{ source, method, additionalAttributes, forgeEnvironment }: TrackBridgeSucceedPayload,
) =>
	fireEvent(createAnalyticsEvent, {
		action: 'succeeded',
		actionSubject: 'forge.ui.bridge',
		source,
		attributes: {
			...(additionalAttributes ?? {}),
			method,
			forgeEnvironment,
		},
	});

export const trackBridgeFailed = (
	createAnalyticsEvent: CreateUIAnalyticsEvent,
	{ source, errorName, method, forgeEnvironment, additionalAttributes }: TrackBridgeFailedPayload,
) =>
	fireEvent(createAnalyticsEvent, {
		action: 'failed',
		actionSubject: 'forge.ui.bridge',
		source,
		attributes: {
			...(additionalAttributes ?? {}),
			error: errorName,
			method,
			forgeEnvironment,
		},
	});

export const trackComponentUsageRendered = (
	createAnalyticsEvent: CreateUIAnalyticsEvent,
	{
		source,
		isLegacyUiKit,
		forgeEnvironment,
		components,
		forgeReactMajorVersion,
	}: TrackComponentUsageRenderedPayload,
) =>
	fireEvent(createAnalyticsEvent, {
		action: 'rendered',
		actionSubject: 'forge.ui.analytics.component_usage',
		source,
		attributes: {
			appType: `UIKit${isLegacyUiKit ? '1' : ''}`,
			forgeEnvironment,
			components,
			forgeReactMajorVersion,
		},
	});

export const trackRendererRendered = (
	createAnalyticsEvent: CreateUIAnalyticsEvent,
	{ source, target }: TrackRendererRenderedPayload,
) =>
	fireEvent(createAnalyticsEvent, {
		action: 'rendered',
		actionSubject: 'forge.ui.renderer',
		source,
		attributes: {
			target,
		},
	});

export const trackExtensionLoaded = (
	createAnalyticsEvent: CreateUIAnalyticsEvent,
	{
		source,
		forgeEnvironment,
		renderType,
		extensionType,
		isConfiguring,
		isInserting,
	}: TrackExtensionLoadedPayload,
) =>
	fireEvent(createAnalyticsEvent, {
		action: 'loaded',
		actionSubject: 'forge.ui.extension',
		source,
		attributes: {
			forgeEnvironment,
			renderType,
			extensionType,
			isConfiguring,
			isInserting,
		},
	});

export const trackExtensionRemounted = (
	createAnalyticsEvent: CreateUIAnalyticsEvent,
	{ source, mountCount }: TrackExtensionRemountedPayload,
) =>
	fireEvent(createAnalyticsEvent, {
		action: 'remounted',
		actionSubject: 'forge.ui.extension',
		source,
		attributes: {
			mountCount,
		},
	});

const createForgeUiAnalyticsEventTrackers = ({
	createAnalyticsEvent,
	source,
}: {
	createAnalyticsEvent: CreateUIAnalyticsEvent;
	source: string;
}) => {
	return {
		trackComponentUsageRendered: ({
			isLegacyUiKit,
			forgeEnvironment,
			components,
			forgeReactMajorVersion,
		}: Omit<TrackComponentUsageRenderedPayload, 'source'>) =>
			trackComponentUsageRendered(createAnalyticsEvent, {
				source,
				isLegacyUiKit,
				forgeEnvironment,
				components,
				forgeReactMajorVersion,
			}),
		trackRendererRendered: ({ target }: Omit<TrackRendererRenderedPayload, 'source'>) =>
			trackRendererRendered(createAnalyticsEvent, { source, target }),
		trackExtensionLoaded: ({
			forgeEnvironment,
			renderType,
			extensionType,
			isConfiguring,
			isInserting,
		}: Omit<TrackExtensionLoadedPayload, 'source'>) =>
			trackExtensionLoaded(createAnalyticsEvent, {
				source,
				forgeEnvironment,
				renderType,
				extensionType,
				isConfiguring,
				isInserting,
			}),
		trackExtensionRemounted: ({ mountCount }: Omit<TrackExtensionRemountedPayload, 'source'>) =>
			trackExtensionRemounted(createAnalyticsEvent, { source, mountCount }),
		trackBridgeSucceed: ({ method, forgeEnvironment }: Omit<TrackBridgeSucceedPayload, 'source'>) =>
			trackBridgeSucceed(createAnalyticsEvent, { source, method, forgeEnvironment }),
		trackBridgeFailed: ({
			method,
			errorName,
			forgeEnvironment,
		}: Omit<TrackBridgeFailedPayload, 'source'>) =>
			trackBridgeFailed(createAnalyticsEvent, { source, errorName, method, forgeEnvironment }),
	};
};

export const useForgeUiAnalyticsEvent = () => {
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const { page: source } = useMetricsContext();

	const cachedTrackers = useMemoOne(
		() => createForgeUiAnalyticsEventTrackers({ createAnalyticsEvent, source }),
		[createAnalyticsEvent, source],
	);

	// eslint-disable-next-line @atlaskit/platform/ensure-feature-flag-prefix
	return fg('forge-ui-macro-config-update-improvement')
		? cachedTrackers
		: createForgeUiAnalyticsEventTrackers({ createAnalyticsEvent, source });
};
