import { type ComponentPropsConfig } from '../../../utils/codegen';

export const componentPropsConfig: ComponentPropsConfig = {
	defaultValue: true,
	options: true,
	onInvalid: true,
	testId: true,
};

/**
 * THIS SECTION WAS CREATED VIA CODEGEN DO NOT MODIFY {@see http://go/af-codegen}
 *
 * This component was generated against an existing platform component.
 * If you are seeing lint errors in this file, it means that this file's dependencies have been modified and the component will need to be regenerated.
 *
 * Please view https://hello.atlassian.net/wiki/spaces/ECO/pages/3358130330/How+to+Regenerate+UI+Kit+Components for more information on how to proceed.
 *
 * For further questions, please ping the #help-forge-ui channel.
 *
 * @codegen <<SignedSource::90f1249428d6fce66f8b8d4763355a49>>
 * @codegenId #radio-group
 * @codegenCommand yarn workspace @atlassian/forge-ui codegen radio-group
 * @codegenParams { "hasFieldProps": { "id": false, "onBlur": false, "onFocus": false, "aria-invalid": false } }
 * @codegenDependency ../../../../../../../design-system/radio/src/radio-group.tsx <<SignedSource::2eb4b4fddac4a9859277c4a0e674e27e>>
 * @codegenDependency ../../../../../../../design-system/radio/src/radio.tsx <<SignedSource::1ce6fd7cad97797af37f8f7842ce7eea>>
 * @codegenDependency ../../../../../../../design-system/radio/src/types.tsx <<SignedSource::6a2f73d4dc252916d11e2658bb97efc2>>
 */
/* eslint @repo/internal/codegen/signed-source-integrity: "warn" */
import React from 'react';
import type { RenderFn } from '@atlassian/forge-ui-types';
import { RadioGroup as PlatformRadioGroup } from '@atlaskit/radio';
import { adaptEventHandler } from "../../../utils";
import type { EventHandlerProps } from "../../types";

type PlatformRadioGroupProps = React.ComponentProps<typeof PlatformRadioGroup>;

export type RadioGroupProps = Pick<
  PlatformRadioGroupProps,
  'defaultValue' | 'options' | 'onInvalid' | 'testId'
 | 'isRequired' | 'isDisabled' | 'isInvalid' | 'value' | 'aria-labelledby' | 'name'
> & Pick<EventHandlerProps, 'onChange'>;

/**
 * A radio input allows users to select only one option from a number of choices. Radio is generally displayed in a radio group.
 */
export const RadioGroup = (props: Parameters<RenderFn>[0]) => {
  const {
    defaultValue,
    options,
    onInvalid,
    testId,
    // Field props
    isRequired,
    isDisabled,
    isInvalid,
    onChange,
    value,
    "aria-labelledby": ariaLabelledby,
    name,
  } = props.forgeDoc.props as RadioGroupProps;
  return (
    <PlatformRadioGroup
      defaultValue={defaultValue}
      options={options}
      onInvalid={onInvalid}
      testId={testId}
      // Field props
      isRequired={isRequired}
      isDisabled={isDisabled}
      isInvalid={isInvalid}
      onChange={adaptEventHandler(onChange)}
      value={value}
      aria-labelledby={ariaLabelledby}
      name={name}
    />
  );
};
/**
 * @codegenEnd
 */
