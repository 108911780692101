import React, { type ReactNode } from 'react';
import { type Props } from '../..';
import { type HeadingProps } from '@atlassian/forge-ui-types';
import AdsHeading from '@atlaskit/heading';

export function Heading({
	size = 'medium',
	children,
}: Omit<HeadingProps, 'children'> & { children: ReactNode | ReactNode[] }) {
	if (size === 'large') {
		return <AdsHeading size="xlarge">{children}</AdsHeading>;
	}
	if (size === 'small') {
		return <AdsHeading size="xsmall">{children}</AdsHeading>;
	}
	// size === 'medium'
	return <AdsHeading size="medium">{children}</AdsHeading>;
}

export default Heading;

export function HeadingFn({
	Components,
	props,
	children,
	renderChildren,
	dispatch,
	render,
}: Props) {
	const { size } = props as HeadingProps;
	return (
		<Heading size={size}>{renderChildren({ Components, children, render, dispatch })}</Heading>
	);
}
