import React from 'react';
import { FormattedMessage, defineMessages } from 'react-intl-next';

import Link from '@atlaskit/link';

const i18n = defineMessages({
	hydrationErrorMessage: {
		id: 'ssr-render-failure-notification.hydration.flag.message',
		defaultMessage:
			'To view the hydration mismatches, refer to window.__HYDRATION_ERRORS__ in the browser console.',
		description:
			'Message to be shown when the hydration failure flag is showing due to a hydration mismatch.',
	},
	runbookLink: {
		id: 'ssr-render-failure-notification.hydration.runbook.link',
		defaultMessage:
			'See <runbookLink>this page</runbookLink> for information on how to debug the mismatches.',
		description: 'Describes how to access runbook pages on debuging hydration failures.',
	},
});

export const RenderHydrationFailureMessage = () => {
	return (
		<span data-testid="flagErrorMessage">
			<b>
				<FormattedMessage {...i18n.hydrationErrorMessage} />
			</b>{' '}
			<FormattedMessage
				{...i18n.runbookLink}
				values={{
					runbookLink: (chunks: React.ReactNode[]) => (
						<Link
							target="_blank"
							href="https://hello.atlassian.net/wiki/spaces/CCPERF/pages/4848884799/Debugging+Hydration+Mismatches"
						>
							{chunks}
						</Link>
					),
				}}
			/>
		</span>
	);
};
