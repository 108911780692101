import React from 'react';

import { aiConfigItemMessages } from '@atlaskit/editor-common/ai-messages';

import { RephraseIcon } from '../../../../icons/prompt/rephrase';
import { createInsertBelow, createReplace } from '../../actions/create-actions';
import { CONFIG_ITEM_KEYS } from '../../config-item-keys';
import type {
	EditorPluginAIConfigItemWithOptions,
	EditorPluginAIConfigItem,
} from '../../config-items';

const intentSchemaId = 'rephrase.json';

export const rephrase: EditorPluginAIConfigItem = {
	key: CONFIG_ITEM_KEYS.REPHRASE,
	title: aiConfigItemMessages.rephraseTitle,
	description: aiConfigItemMessages.rephraseDescription,
	icon: () => <RephraseIcon />,
	statusLozengeType: 'beta',
	intentSchemaId,
	getBackendModel: () => 'assistance-service',
	actions: {
		empty: [
			createInsertBelow({ appearance: 'secondary' }),
			createReplace({ appearance: 'primary' }),
		],
		selection: [
			createInsertBelow({ appearance: 'secondary' }),
			createReplace({ appearance: 'primary' }),
		],
	},
};

export const rephraseWithOptions: EditorPluginAIConfigItemWithOptions = {
	config: rephrase,
	triggers: { empty: { enabled: true, docMinSize: 1 }, selection: { enabled: true } },
};
