import React from 'react';

import { aiConfigItemMessages } from '@atlaskit/editor-common/ai-messages';
import { fg } from '@atlaskit/platform-feature-flags';

import { FindActionItemsIcon } from '../../../../icons/prompt/findActionItems';
import {
	createInsertAtCurrentPosition,
	createInsertAtTop,
	createInsertBelow,
} from '../../actions/create-actions';
import { CONFIG_ITEM_KEYS } from '../../config-item-keys';
import type {
	EditorPluginAIConfigItemWithOptions,
	EditorPluginAIConfigItem,
} from '../../config-items';

export const findActionItems: EditorPluginAIConfigItem = {
	key: CONFIG_ITEM_KEYS.FIND_ACTION_ITEMS,
	title: aiConfigItemMessages.findActionItemsTitle,
	description: aiConfigItemMessages.findActionItemsDescription,
	icon: () => <FindActionItemsIcon />,
	intentSchemaId: 'action_items_intent_schema.json',
	actions: {
		empty: [
			createInsertAtTop({ appearance: 'secondary' }),
			createInsertAtCurrentPosition({ appearance: 'primary' }),
		],
		selection: [
			createInsertAtTop({ appearance: 'secondary' }),
			createInsertBelow({ appearance: 'primary' }),
		],
	},
	getBackendModel: () => {
		if (
			fg('platform_editor_ai_assistance_service') ||
			fg('platform_editor_ai_assistance_service_second')
		) {
			return 'assistance-service';
		}

		return 'xp-gen-ai';
	},
};

export const findActionItemsWithOptions: EditorPluginAIConfigItemWithOptions = {
	config: findActionItems,
	triggers: { empty: { enabled: true, docMinSize: 1 }, selection: { enabled: true } },
};
