import { query } from '@confluence/query-preloader-tools';

import { SPAViewContextQuery } from './SPAViewContextQuery.graphql';

export function preloadSPAViewContext() {
	// @ts-ignore
	if (window['__ENABLE_SITE_INFORMATION_QUERY__']) {
		return Promise.resolve();
	}
	return query({
		query: SPAViewContextQuery,
	});
}
