import { getPreferences } from '@atlassian/browser-storage-controls';
import type { ConsentPreference } from '@atlassian/browser-storage-controls/cookie-controls';

import { getMonitoringClient } from '@confluence/monitoring';
import { SSRMeasures } from '@confluence/action-measures';
import { getSSRRenderInputs } from '@confluence/ssr-utilities';

export const MONITORING_ATTRIBUTION = 'backbone';

export const preloadPreferences = async (): Promise<ConsentPreference | null> => {
	if (window.__PRELOADED_COOKIE_PREFERENCES__) {
		return window.__PRELOADED_COOKIE_PREFERENCES__;
	}

	const { perimeter } = getSSRRenderInputs();

	// if we are in FedRAMP, don't preload the consent categories
	if (perimeter === 'fedramp-moderate') {
		return (window.__PRELOADED_COOKIE_PREFERENCES__ = null);
	}

	return SSRMeasures.run('ssr-app/prepare/preloadQuery/fetch:Preferences', async () => {
		try {
			const result = await getPreferences();
			return (window.__PRELOADED_COOKIE_PREFERENCES__ = result || null);
		} catch (e) {
			getMonitoringClient().submitError(e, {
				attribution: MONITORING_ATTRIBUTION,
			});
			return (window.__PRELOADED_COOKIE_PREFERENCES__ = null);
		}
	});
};
