import memoize from 'memoize-one';
import uuid from 'uuid/v4';

import { createExperienceTrackerAnalyticsEventPayload } from '@atlassian/embedded-confluence/createExperienceTrackerAnalyticsEventPayload';

import { getAnalyticsWebClient } from '@confluence/analytics-web-client';
import {
	getMonitoringClient,
	setSlaErrorAttributes,
	setContentErrorAttributes,
} from '@confluence/monitoring';
import { isCompanyHubSpaceKey } from '@confluence/route-manager/entry-points/companyHubUtils';
import type { MonitoringContext } from '@confluence/monitoring';

import {
	ADD_PAGE_COMMENT_EXPERIENCE,
	CREATE_PAGE_EXPERIENCE,
	EDIT_PAGE_EXPERIENCE,
	VIEW_PAGE_EXPERIENCE,
	VIEW_CONTENT_EXPERIENCE,
	SPACE_OVERVIEW_EXPERIENCE,
	FRONT_COVER_LOAD_EXPERIENCE,
} from './ExperienceName';
import { ExperienceTracker } from './ExperienceTracker';
import type { ExperienceTrackerAPI } from './ExperienceTracker';
import type { ExperienceEvent, ExperienceAttributes } from './ExperienceEvent';
import { ExperienceTimeoutError } from './ExperienceTimeoutError';
import { startViewPageSessionExperience } from './viewPageSessionExperience';

type ActiveSlaExperienceProperties = Pick<ExperienceEvent, 'id' | 'attributes'>;

const SLA_EXPERIENCES = new Set<string>([
	ADD_PAGE_COMMENT_EXPERIENCE,
	CREATE_PAGE_EXPERIENCE,
	EDIT_PAGE_EXPERIENCE,
	VIEW_PAGE_EXPERIENCE,
]);

const activeSlaExperiences = new Map<string, ActiveSlaExperienceProperties>();
const activeExperiences = new Set<string>();

// Exported for testing
export function captureExperience({ action, name, id, attributes = {} }: ExperienceEvent) {
	const isSlaExperience = SLA_EXPERIENCES.has(name);
	if (action === 'taskStart') {
		if (isSlaExperience) {
			// Create a unique experience ID for request headers
			attributes.uniqueExperienceId = uuid();
			activeSlaExperiences.set(name, { id, attributes });
		}
		activeExperiences.add(name);
	} else {
		if (isSlaExperience) {
			// Extract unique experience ID to attach to event attributes for analytics/monitoring logs
			attributes.uniqueExperienceId =
				activeSlaExperiences.get(name)?.attributes?.uniqueExperienceId;
			activeSlaExperiences.delete(name);
		}
		activeExperiences.delete(name);
	}

	const slaExperience = getLatestActiveSlaExperience();
	const experiences = activeExperiences.size > 0 ? activeExperiences : null;
	if (slaExperience || experiences) {
		const updatedContext: Partial<MonitoringContext> = {};
		if (experiences) {
			updatedContext.activeExperiences = activeExperiences;
		}
		if (slaExperience) {
			updatedContext.slaExperience = slaExperience.name;
		}

		getMonitoringClient().updateContext(updatedContext);
	}
}

export function getLatestActiveSlaExperience():
	| ({ name: string } & ActiveSlaExperienceProperties)
	| null {
	if (activeSlaExperiences.size === 0) {
		return null;
	}
	const experienceEntry = Array.from(activeSlaExperiences).pop()!;
	return {
		...experienceEntry[1],
		name: experienceEntry[0],
	};
}

export const getExperienceTracker = memoize(() => {
	const tracker = new ExperienceTracker();

	// Do nothing for search engine crawlers
	if (/bot/i.test(window.navigator.userAgent)) {
		return tracker;
	}

	tracker.subscribe((event) => {
		//
		// View Page SLA Split
		//
		if (event.name === VIEW_CONTENT_EXPERIENCE) {
			splitViewContentExperience(tracker, event);
			// View-content was introduced only to split space-overview out of
			// view-page, because view-page is covered by Premium SLA and
			// space-overview was not satisfactorily reliable. View-content is not
			// allowlisted in cc-fe-telemetry so submitting through MonitoringClient
			// below is a waste. Sending through AnalyticsWebClient is a waste too
			// since view-content is not tracked as an SLO and it could be derived
			// from view-page and space-overview which are tracked as separate SLOs
			// already anyway. Splunk found 23 million view-content events in the last
			// 24 hours which is about 3% of experience-tracker events.
			return;
		}

		captureExperience(event);

		//
		// AnalyticsWebClient
		//

		void getAnalyticsWebClient().then((client) => {
			client.sendOperationalEvent(createExperienceTrackerAnalyticsEventPayload(event));
		});

		//
		// MonitoringClient
		//
		const isTimeoutError =
			(event.action === 'taskFail' && event?.error?.name === 'ExperienceTimeoutError') ||
			(event.action === 'taskAbort' &&
				typeof event?.reason === 'string' &&
				event.reason.startsWith('ExperienceTimeout'));
		const isPermissionError =
			event.name === EDIT_PAGE_EXPERIENCE &&
			event.action === 'taskAbort' &&
			(event.reason?.includes?.('401') || event.reason?.includes?.('403'));
		const tags = [
			`action:${event.action}`,
			`isActiveTab:${document.visibilityState === 'visible'}`,
			...(event.action === 'taskFail' || event.action === 'taskAbort'
				? [`isTimeoutError:${isTimeoutError}`]
				: []),
			...(event.name === EDIT_PAGE_EXPERIENCE && event.action === 'taskAbort'
				? [`isPermissionError:${isPermissionError}`]
				: []),
		];
		if (event.attributes) {
			for (const a in event.attributes) {
				tags.push(`${a}:${event.attributes[a]}`);
			}
		}

		if (event.action === 'taskFail' && SLA_EXPERIENCES.has(event.name)) {
			// in this section, we submit information about SLA-affecting experience failures.
			// We don't submit timeout errors to monitoring systems however; they would be submitted to Analytics instead.

			// cc-fe-telemetry will filter these additional tags before sending to SFX, these are only for "Raw TelemetryEvent" Splunk logs
			if (event?.error?.message) {
				tags.push(`error:${event.error.message}`);
			}
			// Supply additional information from alternative sources, for case where SessionDataQuery is not available
			tags.push(`hostname:${window.location.hostname}`);
			tags.push(`tenantIdMeta:${window.AJS?.Meta?.get('cloud-id')}`);

			getMonitoringClient().incrementCounter(`experience-tracker:${event.name}`, tags);

			if (!(event.error instanceof ExperienceTimeoutError)) {
				setContentErrorAttributes(event.error, {
					objectId: event.attributes?.contentId as string | null | undefined,
				});
				setSlaErrorAttributes(event.error, {
					failedSlaExperience: event.name,
					experienceId: (event.attributes?.uniqueExperienceId as string) ?? event.id,
				});
				getMonitoringClient().submitError(event.error, { attribution: 'sla' });
			}
		} else {
			getMonitoringClient().incrementCounter(`experience-tracker:${event.name}`, tags);
		}
	});

	return tracker;
});

export function stopExperienceOnError(
	name: string,
	error: Error,
	attributes?: ExperienceAttributes,
) {
	getExperienceTracker().stopOnError({ name, error, attributes });
}

export const forwardExperience = (
	tracker: ExperienceTrackerAPI,
	event: ExperienceEvent,
	name: string = event.name,
) => {
	switch (event.action) {
		case 'taskStart':
			tracker.start({ ...event, name });
			break;
		case 'taskSuccess':
			tracker.succeed({ ...event, name });
			break;
		case 'taskFail':
			tracker.fail({ ...event, name });
			break;
		case 'taskAbort':
			tracker.abort({ ...event, name });
	}
};

const splitViewContentExperience = (tracker: ExperienceTracker, event: ExperienceEvent) => {
	let name: ExperienceEvent['name'];

	if (event.attributes?.contentType === 'space') {
		name = SPACE_OVERVIEW_EXPERIENCE;

		const spaceKey = event.attributes?.spaceKey;
		if (typeof spaceKey === 'string' && isCompanyHubSpaceKey(spaceKey)) {
			name = FRONT_COVER_LOAD_EXPERIENCE;
		}
	} else {
		name = VIEW_PAGE_EXPERIENCE;
		if (event.action === 'taskStart') {
			startViewPageSessionExperience({
				tracker,
				attributes: { ...event.attributes },
				startTime: event.startTime,
				id: event.id,
			});
		}
	}

	forwardExperience(tracker, event, name);
};
