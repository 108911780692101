import { fg } from '@confluence/feature-gating';

export const HEAP_THRESHOLD_TO_ALERT = 0.8;
export const CHECK_MEMORY_INTERVAL = 1000 * 10; // 10 seconds
export const EVENT_LISTENER_THRESHOLD = 30000; // Normal pages have around 10-20k event listeners
let routeName = 'unknown';
let analyticsClient = null;
let memoryMonitorInterval = null;
let eventListenerCount = 0;
let initializedEventListeners = false;

function bytesToMB(bytes) {
	const MB = 1024 * 1024;
	return bytes / MB;
}

// exported for testing
export function checkMemoryUsage() {
	if (!analyticsClient) {
		return false;
	}
	if (performance.memory) {
		const { jsHeapSizeLimit, usedJSHeapSize } = performance.memory;
		const heapThreshold = jsHeapSizeLimit * HEAP_THRESHOLD_TO_ALERT;
		if (usedJSHeapSize > heapThreshold) {
			analyticsClient.sendOperationalEvent({
				source: 'confluence-memory-monitor',
				action: 'measured',
				actionSubject: 'highHeapUsage',
				attributes: {
					heapUsagePercent: Math.floor((usedJSHeapSize / jsHeapSizeLimit) * 100),
					heapUsageMB: Math.floor(bytesToMB(usedJSHeapSize)),
					maxHeapSizeMB: Math.floor(bytesToMB(jsHeapSizeLimit)),
					route: routeName,
				},
			});
		}
	}
	if (initializedEventListeners && eventListenerCount > EVENT_LISTENER_THRESHOLD) {
		analyticsClient.sendOperationalEvent({
			source: 'confluence-memory-monitor',
			action: 'measured',
			actionSubject: 'highEventListenerCount',
			attributes: {
				eventListenerCount,
				route: routeName,
			},
		});
	}
	return true;
}

export function initializeMemoryMonitor(client) {
	if (!fg('confluence_frontend_memory_monitor')) {
		return null;
	}
	analyticsClient = client;

	const originalAddEventListener = EventTarget.prototype.addEventListener;
	const originalRemoveEventListener = EventTarget.prototype.removeEventListener;

	if (!initializedEventListeners) {
		EventTarget.prototype.addEventListener = function (type, listener, options) {
			eventListenerCount++;
			originalAddEventListener.call(this, type, listener, options);
		};

		EventTarget.prototype.removeEventListener = function (type, listener, options) {
			eventListenerCount--;
			originalRemoveEventListener.call(this, type, listener, options);
		};
		initializedEventListeners = true;
	}

	if (!memoryMonitorInterval) {
		memoryMonitorInterval = setInterval(checkMemoryUsage, CHECK_MEMORY_INTERVAL);
	}
	return true;
}

export function setMemoryMonitorRouteName(name) {
	routeName = name;
}

export function clearMemoryMonitorInterval() {
	if (memoryMonitorInterval) {
		clearInterval(memoryMonitorInterval);
		memoryMonitorInterval = null;
	}
}
