/**
 * Excluded props to simplify API:
 * - strategy
 * - hideTooltipOnClick
 * - hideTooltipOnMouseDown
 * - onHide
 * - onShow
 */
import { type ComponentPropsConfig } from '../../../utils/codegen';

/**
 * A `Tooltip` is a floating, non-actionable label used to explain a user interface element or feature.
 */
export const componentPropsConfig: ComponentPropsConfig = {
	children: true,
	position: true,
	mousePosition: true,
	content: true,
	truncate: true,
	testId: true,
	delay: true,
	onShow: false,
	onHide: false,
	hideTooltipOnClick: false,
	hideTooltipOnMouseDown: false,
	strategy: false,
};

/**
 * THIS SECTION WAS CREATED VIA CODEGEN DO NOT MODIFY {@see http://go/af-codegen}
 *
 * This component was generated against an existing platform component.
 * If you are seeing lint errors in this file, it means that this file's dependencies have been modified and the component will need to be regenerated.
 *
 * Please view https://hello.atlassian.net/wiki/spaces/ECO/pages/3358130330/How+to+Regenerate+UI+Kit+Components for more information on how to proceed.
 *
 * For further questions, please ping the #help-forge-ui channel.
 *
 * @codegen <<SignedSource::1163c3b7ce3b74d1a5c8052ac08ba391>>
 * @codegenId #tooltip
 * @codegenCommand yarn workspace @atlassian/forge-ui codegen tooltip
 * @codegenDependency ../../../../../../../design-system/tooltip/src/types.tsx <<SignedSource::753ba7210c150b2e0de79a67215e6500>>
 * @codegenDependency ../../../../../../../design-system/tooltip/src/internal/drag-manager.tsx <<SignedSource::e37a076de93e58bf69536474ad1d5b33>>
 * @codegenDependency ../../../../../../../design-system/tooltip/src/internal/tooltip-manager.ts <<SignedSource::656ffe6b6907adf8fda3001dfb263e8f>>
 * @codegenDependency ../../../../../../../design-system/tooltip/src/internal/use-unique-id.ts <<SignedSource::d46a71c8c7884c089934054e775709b5>>
 * @codegenDependency ../../../../../../../design-system/tooltip/src/tooltip-container.tsx <<SignedSource::47d069485db5572c691e79aed6b80521>>
 * @codegenDependency ../../../../../../../design-system/tooltip/src/utilities.ts <<SignedSource::9481eee079368e54afb665190e27b0bb>>
 * @codegenDependency ../../../../../../../design-system/tooltip/src/tooltip.tsx <<SignedSource::77cf7af2fd3fdac7a03a5c38b619fe93>>
 */
/* eslint @repo/internal/codegen/signed-source-integrity: "warn" */
import React from 'react';
import type { RenderFn } from '@atlassian/forge-ui-types';
import PlatformTooltip from '@atlaskit/tooltip';

type PlatformTooltipProps = React.ComponentProps<typeof PlatformTooltip>;

export type TooltipProps = Pick<
  PlatformTooltipProps,
  'children' | 'position' | 'mousePosition' | 'content' | 'truncate' | 'testId' | 'delay'
>;

/**
 * A `Tooltip` is a floating, non-actionable label used to explain a user interface element or feature.
 */
export const Tooltip = (props: Parameters<RenderFn>[0]) => {
  const {
    position,
    mousePosition,
    content,
    truncate,
    testId,
    delay,
  } = props.forgeDoc.props as TooltipProps;
  return (
    <PlatformTooltip
      children={props.forgeDoc.children.map(c => <React.Fragment key={c.key}>{props.render(c)}</React.Fragment>)}
      position={position}
      mousePosition={mousePosition}
      content={content}
      truncate={truncate}
      testId={testId}
      delay={delay}
    />
  );
};
/**
 * @codegenEnd
 */
