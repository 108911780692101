//Product View IDs
export const PRODUCT_HOME_ID = 'product/home';
export const PRODUCT_ADDONS_ID = 'product/addons';

//Container View IDs
export const CONTAINER_HOME_ID = 'container/home';
export const CONTAINER_HOME_LOADING_ID = 'container/home/loading';
export const CONTAINER_BLOG_ID = 'container/blog';
export const CONTAINER_PDF_EXPORT_ID = 'container/pdf-export';

//Settings View IDs
export const CONTAINER_ADMIN_HOME_ID = 'container/admin/home';
export const COMPANY_HUB_SETTINGS_ID = 'container/admin/company-hub';
export const ADMIN_HOME_ID = 'admin/home';
