import { query } from '@confluence/query-preloader-tools';

import { TitleContentPropertyQuery } from './TitleContentPropertyQuery.graphql';

export const preloadEditorPageTitleContentProperties = async ({
	contentId,
	draftShareId,
	useCache,
}: {
	contentId?: string | null;
	draftShareId: string;
	useCache: boolean;
}) => {
	const variables = {
		contentId,
		status: ['current', 'draft', 'archived'],
		draftShareId,
		fetchPolicy: useCache ? 'cache-first' : 'network-only',
	};
	return query({
		query: TitleContentPropertyQuery,
		variables,
	});
};
