import React from 'react';

import { fg } from '@confluence/feature-gating';

/**
 * Provides a way to conditionally wrap content in React.StrictMode.
 * It allows for the conditional disabling of strict mode in local development environments.
 */
export const StrictMode = ({ children }: { children: React.JSX.Element }) => {
	// allow developers to disable strict mode for local development
	const isStrictModeDisabled = process.env.STRICT_MODE === 'false';

	if (isStrictModeDisabled) {
		return children;
	}

	// React.StrictMode usage is enabled via feature flag or environment variable
	if (fg('confluence_strict_mode') || process.env.STRICT_MODE === 'true') {
		return <React.StrictMode>{children}</React.StrictMode>;
	} else {
		return children;
	}
};
