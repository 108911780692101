import gql from 'graphql-tag';

import { PageCardContentFragment } from '@confluence/page-card/entry-points/PageCardContent.fragment';

export const HomeDraftsQuery = gql`
	query HomeDraftsQuery($cqlcontext: String, $first: Int, $after: String) {
		search(
			cql: "type in (page,blogpost) order by lastModified desc"
			cqlcontext: $cqlcontext
			first: $first
			after: $after
		) {
			nodes {
				content {
					...PageCardContentFragment
					status
					blank
					metadata {
						lastModifiedDate
					}
					editorVersion: properties(key: "editor") {
						nodes {
							id
							key
							value
						}
					}
					emoji: properties(keys: ["emoji-title-draft"]) {
						nodes {
							id
							key
							value
						}
					}
				}
			}
			pageInfo {
				hasNextPage
				endCursor
			}
		}
	}
	${PageCardContentFragment}
`;
