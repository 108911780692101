import { type ComponentPropsConfig } from '../../../utils/codegen';

/**
 * A modal footer often contains a primary action and the ability to cancel and close the dialog,
 * though can contain any React element.
 */
export const componentPropsConfig: ComponentPropsConfig = {
	children: true,
	testId: true,
};
/**
 * THIS SECTION WAS CREATED VIA CODEGEN DO NOT MODIFY {@see http://go/af-codegen}
 *
 * This component was generated against an existing platform component.
 * If you are seeing lint errors in this file, it means that this file's dependencies have been modified and the component will need to be regenerated.
 *
 * Please view https://hello.atlassian.net/wiki/spaces/ECO/pages/3358130330/How+to+Regenerate+UI+Kit+Components for more information on how to proceed.
 *
 * For further questions, please ping the #help-forge-ui channel.
 *
 * @codegen <<SignedSource::2dcb2b7c4fbc0e8916665042dd0dab69>>
 * @codegenId #modal-footer
 * @codegenCommand yarn workspace @atlassian/forge-ui codegen modal-footer
 * @codegenDependency ../../../../../../../design-system/modal-dialog/src/modal-footer.tsx <<SignedSource::89e85a949446624ebbb05abfd86bff00>>
 * @codegenDependency ../../../../../../../design-system/modal-dialog/src/hooks.tsx <<SignedSource::5062e27591547b31bd9e5702d12d3685>>
 */
/* eslint @repo/internal/codegen/signed-source-integrity: "warn" */
import React from 'react';
import type { RenderFn } from '@atlassian/forge-ui-types';
import { ModalFooter as PlatformModalFooter } from '@atlaskit/modal-dialog';

type PlatformModalFooterProps = React.ComponentProps<typeof PlatformModalFooter>;

export type ModalFooterProps = Pick<
  PlatformModalFooterProps,
  'children' | 'testId'
>;

/**
 * A modal footer often contains a primary action and the ability to cancel and close the dialog,
 * though can contain any React element.
 */
export const ModalFooter = (props: Parameters<RenderFn>[0]) => {
  const {
    testId,
  } = props.forgeDoc.props as ModalFooterProps;
  return (
    <PlatformModalFooter
      children={props.forgeDoc.children.map(c => <React.Fragment key={c.key}>{props.render(c)}</React.Fragment>)}
      testId={testId}
    />
  );
};
/**
 * @codegenEnd
 */
