import type { FC } from 'react';
import React, { createContext, memo, useMemo } from 'react';
import { useQuery } from '@apollo/react-hooks';

import { markErrorAsHandled } from '@confluence/graphql';
import { getMonitoringClient } from '@confluence/monitoring';
import { isUnauthorizedError } from '@confluence/unauthorized-error-boundary';
import { SiteInformationQuery } from '@confluence/session-data';
import type { SiteInformationQueryType } from '@confluence/session-data';

import type { SPAViewContextQuery as SPAViewContextQueryType } from './__types__/SPAViewContextQuery';
import { SPAViewContextQuery } from './SPAViewContextQuery.graphql';

export type SPAViewContextValue = {
	tenantId: string;
	isNewUser: boolean;
	userCanCreateContent: boolean;
	isAnonymous: boolean;
	siteLogoUrl: string;
	siteTitle: string;
	showSiteTitle: boolean;
	homepageUri: string;
	companyHubName: string;
	frontCoverState: string;
	homepageTitle: string;
	experimentFeatures: string;
	abTestCohorts: string;
	isSiteAdmin: boolean;
	loading?: boolean;
	error?: Error;
};

export const defaultContext: SPAViewContextValue = {
	tenantId: '',
	isNewUser: false,
	userCanCreateContent: false,
	isAnonymous: false,
	siteLogoUrl: '',
	siteTitle: '',
	showSiteTitle: false,
	homepageUri: '',
	homepageTitle: '',
	companyHubName: '',
	frontCoverState: '',
	experimentFeatures: '',
	abTestCohorts: '',
	isSiteAdmin: false,
	loading: true,
};

export const SPAViewContext = createContext<SPAViewContextValue>(defaultContext);

export const SPAViewContextProvider: FC<{ children?: React.ReactNode }> = memo(({ children }) => {
	const isSiteInformationQueryEnabled =
		// @ts-ignore
		window['__ENABLE_SITE_INFORMATION_QUERY__'] || process.env.NODE_ENV === 'testing';

	const { data, loading, error } = useQuery<SiteInformationQueryType | SPAViewContextQueryType>(
		// eslint-disable-next-line graphql-relay-compat/no-import-graphql-operations -- Read https://go/connie-relay-migration-fyi
		isSiteInformationQueryEnabled ? SiteInformationQuery : SPAViewContextQuery,
	);
	if (error) {
		if (!isUnauthorizedError(error)) {
			getMonitoringClient().submitError(error, { attribution: 'backbone' });
		}
		markErrorAsHandled(error);
	}

	// The context will fallback to default if any error happens
	const context = useMemo(
		() => ({
			...(data ? transformData(data) : defaultContext),
			loading,
			error,
		}),
		[loading, error, data],
	);

	return <SPAViewContext.Provider value={context}>{children}</SPAViewContext.Provider>;
});

type Nullable<T> = {
	[P in keyof T]: T[P] | null;
};

export const transformData = (
	data: SiteInformationQueryType | SPAViewContextQueryType,
): SPAViewContextValue => {
	const { isSiteAdmin, abTestCohorts, experimentFeatures, userCanCreateContent, isNewUser } = data;
	const { uri: homepageUri, title: homepageTitle } = data?.siteSettings?.homepage || {};
	const { isAnonymous } = data?.currentConfluenceUser || {};
	const { logoUrl: siteLogoUrl } = data?.siteDescription || {};
	const { siteTitle } = data?.siteSettings || {};
	const { showApplicationTitle: showSiteTitle, companyHubName } = data?.siteSettings || {};
	const { frontCoverState: frontCoverState } = data?.siteSettings?.frontCover || {};
	const tenantId = data?.tenant?.cloudId;
	const contextData: Nullable<Partial<SPAViewContextValue>> = {
		tenantId,
		isNewUser,
		userCanCreateContent,
		isAnonymous,
		siteLogoUrl,
		siteTitle,
		showSiteTitle,
		homepageUri,
		frontCoverState,
		companyHubName,
		homepageTitle,
		experimentFeatures,
		abTestCohorts,
		isSiteAdmin,
		loading: false,
	};

	// replace null values from query data with default values
	for (const [key, value] of Object.entries(contextData)) {
		if (value === null || value === undefined) {
			(contextData as any)[key] = (defaultContext as any)[key];
		}
	}

	return contextData as SPAViewContextValue;
};
