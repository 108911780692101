import React, { useMemo } from 'react';
import { useIntl, defineMessages } from 'react-intl-next';

import TextArea from '@atlaskit/textarea';
import Textfield from '@atlaskit/textfield';
import type { OptionType } from '@atlaskit/select';
import { PopupSelect } from '@atlaskit/select';
import Button from '@atlaskit/button/custom-theme-button';
import ChevronDownIcon from '@atlaskit/icon/utility/chevron-down';
import Tooltip from '@atlaskit/tooltip';

import type { TextareaInputProps, TextfieldInputProps, DropDownInputProps } from './types';
import { ButtonStyle, DropDownInputWrapper } from './SharedComponents';
import { LIST_TYPE, MULTI_LINES_TYPE } from './constants';

export const i18n = defineMessages({
	invalidVariableNameError: {
		id: 'template-variable.description.invalid.variable.name',
		defaultMessage:
			'This template contains a variable using invalid character(s) (. , [ , ], /). Please have the template owner remove these characters from the variable to use it.',
		description: 'Error message displayed for invalid variable name',
	},
});

const generateDropdownOptionsList = (options?: Array<string>): Array<any> =>
	options
		? options.map((option) => ({
				label: option,
				value: option,
			}))
		: [];

const styles = {
	option: (provided) => ({
		...provided,
		wordBreak: 'break-word',
	}),
};

const checkTemplateVariableValidity = (variableName) => {
	const invalidVariableCharacters = ['.', '[', ']', '/'];

	return invalidVariableCharacters.some((invalidVariableCharacter) =>
		variableName?.includes(invalidVariableCharacter),
	);
};

export const DropDownInput = ({ parameters, onChange, value, placeholder }: DropDownInputProps) => {
	const intl = useIntl();
	// We are passing in placeholder as the validity check b/c it hasn't been stripped of . [ or ]
	const hasInvalidVariableName = checkTemplateVariableValidity(placeholder);
	const parameterOptions = parameters.params && parameters.params.options;
	const options = useMemo<OptionType[]>(
		() => generateDropdownOptionsList(parameterOptions),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[parameterOptions],
	);

	const onChangeHandler = useMemo(
		() =>
			(option: { label: string; value: string }): void => {
				onChange(option.value);
			},
		[onChange],
	);

	return (
		<>
			{hasInvalidVariableName ? (
				<Tooltip content={intl.formatMessage(i18n.invalidVariableNameError)}>
					<DropDownInputWrapper>
						<PopupSelect
							options={options}
							placeholder={`${value || placeholder}`}
							onChange={onChangeHandler as any}
							target={({ ref, isOpen }) => (
								<ButtonStyle>
									<Button
										ref={ref}
										iconAfter={<ChevronDownIcon label="dropdown icon" />}
										isSelected={isOpen}
										isDisabled={hasInvalidVariableName}
									>
										{`${value || placeholder}`}
									</Button>
								</ButtonStyle>
							)}
							popperProps={{
								placement: 'auto',
								modifiers: [
									{
										name: 'preventOverflow',
										options: {
											boundary: 'clippingParents',
										},
									},
								],
							}}
							maxMenuHeight={200}
							maxMenuWidth={200}
							searchThreshold={10}
							styles={styles}
							value={{ label: value, value }}
						/>
					</DropDownInputWrapper>
				</Tooltip>
			) : (
				<DropDownInputWrapper>
					<PopupSelect
						options={options}
						placeholder={`${value || placeholder}`}
						onChange={onChangeHandler as any}
						target={({ ref, isOpen }) => (
							<ButtonStyle>
								<Button
									ref={ref}
									iconAfter={<ChevronDownIcon label="dropdown icon" />}
									isSelected={isOpen}
									isDisabled={parameters.isDisabled}
								>
									{`${value || placeholder}`}
								</Button>
							</ButtonStyle>
						)}
						popperProps={{
							placement: 'auto',
							modifiers: [
								{
									name: 'preventOverflow',
									options: {
										boundary: 'clippingParents',
									},
								},
							],
						}}
						maxMenuHeight={200}
						maxMenuWidth={200}
						searchThreshold={10}
						styles={styles}
						value={{ label: value, value }}
					/>
				</DropDownInputWrapper>
			)}
		</>
	);
};

export const TextfieldInput = ({
	onChange,
	value,
	inputName,
	parameters,
	placeholder,
}: TextfieldInputProps) => {
	const intl = useIntl();
	// We are passing in placeholder as the validity check b/c it hasn't been stripped of . [ or ]
	const hasInvalidVariableName = checkTemplateVariableValidity(placeholder);

	return (
		<>
			{hasInvalidVariableName ? (
				<Tooltip content={intl.formatMessage(i18n.invalidVariableNameError)}>
					{(tooltipProps) => (
						<Textfield
							{...tooltipProps}
							testId="text-field-input"
							name={inputName}
							onChange={onChange}
							value={value || ''}
							placeholder={placeholder}
							isDisabled={hasInvalidVariableName}
							width={200}
							isCompact
						/>
					)}
				</Tooltip>
			) : (
				<Textfield
					testId="text-field-input"
					name={inputName}
					onChange={onChange}
					value={value || ''}
					placeholder={placeholder}
					isDisabled={parameters?.isDisabled}
					width={200}
					isCompact
				/>
			)}
		</>
	);
};

export const TextareaInput = ({ parameters, onChange, value, placeholder }: TextareaInputProps) => {
	const intl = useIntl();
	// We are passing in placeholder as the validity check b/c it hasn't been stripped of . [ or ]
	const hasInvalidVariableName = checkTemplateVariableValidity(placeholder);

	return (
		<>
			{hasInvalidVariableName ? (
				<Tooltip content={intl.formatMessage(i18n.invalidVariableNameError)}>
					{(tooltipProps) => (
						<TextArea
							{...tooltipProps}
							testId="text-area-input"
							onChange={onChange}
							value={value || ''}
							minimumRows={(parameters.params && parameters.params.rows) || 1}
							// @ts-ignore cols used to be in the list of props of TextArea but later then removed
							cols={(parameters.params && parameters.params.columns) || 30}
							resize="vertical"
							isDisabled={hasInvalidVariableName}
							placeholder={placeholder}
						/>
					)}
				</Tooltip>
			) : (
				<TextArea
					testId="text-area-input"
					onChange={onChange}
					value={value || ''}
					minimumRows={(parameters.params && parameters.params.rows) || 1}
					// @ts-ignore cols used to be in the list of props of TextArea but later then removed
					cols={(parameters.params && parameters.params.columns) || 30}
					resize="vertical"
					isDisabled={parameters?.isDisabled}
					placeholder={placeholder}
				/>
			)}
		</>
	);
};

export const TemplateVariableInputComponent = (props) => {
	const { parameters } = props;

	switch (parameters.type) {
		case LIST_TYPE:
			return <DropDownInput {...props} />;
		case MULTI_LINES_TYPE:
			return <TextareaInput {...props} />;
		default:
			return <TextfieldInput {...props} />;
	}
};
