import { useEffect } from 'react';

import {
	GLOBALLY_DEFINED_MACRO_RESOURCES_CONTAINER,
	replaceDomElementContent,
} from '@confluence/dom-helpers';
import { loadResources, requireSuperBatch } from '@confluence/wrm';

import type { ContentBodyType } from './__types__';

type UseGloballyDefinedMacroResourcesInputType = {
	contentBody: ContentBodyType;
};
export const useGloballyDefinedMacroResources = ({
	contentBody,
}: UseGloballyDefinedMacroResourcesInputType) => {
	const isFabric = contentBody?.representation === 'atlas_doc_format';

	const webresource = contentBody?.webresource;
	const cssMarkup = webresource?.tags?.css || '';
	// tags are coming from server with "&" encoded as html entity, and loadResources doesn't expect that
	const jsMarkup = (webresource?.tags?.js || '').replaceAll?.('&amp;', '&');

	const hasGloballyDefinedResourcesForMacros = Boolean(cssMarkup || jsMarkup);

	useEffect(() => {
		// eslint-disable-next-line check-react-ssr-usage/no-react-ssr
		if (!process.env.REACT_SSR && isFabric && hasGloballyDefinedResourcesForMacros) {
			replaceDomElementContent(GLOBALLY_DEFINED_MACRO_RESOURCES_CONTAINER, cssMarkup);
			void requireSuperBatch().then(() => {
				const el = document.getElementById(GLOBALLY_DEFINED_MACRO_RESOURCES_CONTAINER);
				if (!el) {
					return;
				}
				void loadResources(el, jsMarkup);
			});
		}

		// On unmount of the renderer we should clean up styles that were injected to display macros
		return () => {
			// eslint-disable-next-line check-react-ssr-usage/no-react-ssr
			if (!process.env.REACT_SSR) {
				replaceDomElementContent(GLOBALLY_DEFINED_MACRO_RESOURCES_CONTAINER, '');
			}
		};
	}, [hasGloballyDefinedResourcesForMacros, cssMarkup, jsMarkup, isFabric]);
};
