import React, { Component, type ErrorInfo } from 'react';
import { type Dispatch, ProductEnvironment } from '@atlassian/forge-ui-types';
import ErrorPanel from '../components/UIKit1/errorPanel/errorPanel';
import { captureAndReportError } from '../error-reporting';
import { type CreateUIAnalyticsEvent, useAnalyticsEvents } from '@atlaskit/analytics-next';
import { OPERATIONAL_EVENT_TYPE } from '@atlaskit/analytics-gas-types';
import { FORGE_UI_ANALYTICS_CHANNEL } from '../analytics';
import { MetricsContext } from '../context';

export class ComponentError extends Error {
	component: string;

	constructor(message: string, component: string) {
		super(message);
		this.component = component;
		// https://github.com/Microsoft/TypeScript/wiki/Breaking-Changes#extending-built-ins-like-error-array-and-map-may-no-longer-work
		Object.setPrototypeOf(this, ComponentError.prototype);
	}
}

interface Props {
	children?: React.ReactNode;
	dispatch?: Dispatch;
	extension?: Record<string, any>;
}

interface ComponentErrorBoundaryWithCreateAnalyticsEventProps extends Props {
	createAnalyticsEvent: CreateUIAnalyticsEvent;
}

interface State {
	thrownError?: {
		error: Error;
		errorInfo: ErrorInfo;
	};
}
class ComponentErrorBoundaryWithCreateAnalyticsEvent extends Component<
	ComponentErrorBoundaryWithCreateAnalyticsEventProps,
	State
> {
	static contextType = MetricsContext;
	context!: React.ContextType<typeof MetricsContext>;

	public state: State = {
		thrownError: undefined,
	};

	public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
		// Filters out reporting errors for components that are not valid e.g. <Buton/>
		if (!(error instanceof ComponentError)) {
			throw error;
		}

		const { extension } = this.props;
		const { page, environment } = this.context;

		captureAndReportError({
			error,
			errorInfo,
			page,
			environment: environment ?? ProductEnvironment.DEVELOPMENT,
			errorExtensionDetails: extension
				? {
						type: extension.type,
						environmentType: extension.environmentType,
						appOwnerAccountId: extension.appOwner?.accountId,
						properties: extension.properties,
						id: extension?.id,
					}
				: undefined,
		});

		this.props
			.createAnalyticsEvent({
				eventType: OPERATIONAL_EVENT_TYPE,
				data: {
					action: 'failed',
					actionSubject: 'forge.ui.renderer',
					source: page,
					tags: ['forge'],
					attributes: {
						target: 'errorBoundary',
						errorName: 'componentError',
						component: error.component,
					},
				},
			})
			.fire(FORGE_UI_ANALYTICS_CHANNEL);

		this.setState({
			thrownError: {
				error,
				errorInfo,
			},
		});
	}

	public render() {
		const { thrownError } = this.state;
		if (!thrownError) {
			return this.props.children;
		}

		return <ErrorPanel error={thrownError.error} dispatch={this.props.dispatch} />;
	}
}

const ComponentErrorBoundary = (props: Props) => {
	const { createAnalyticsEvent } = useAnalyticsEvents();

	return (
		<ComponentErrorBoundaryWithCreateAnalyticsEvent
			{...props}
			createAnalyticsEvent={createAnalyticsEvent}
		/>
	);
};

export default ComponentErrorBoundary;
