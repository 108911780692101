import { preloadWebItemLocation } from '@confluence/web-item-location/entry-points/preloadWebItemLocation';
import { preloadUseExtensionList } from '@confluence/forge-ui/entry-points/preloadUseExtensionList';
import { getPreloaderFnContext } from '@confluence/query-preloader-tools';
import { fg } from '@confluence/feature-gating';

import { getGlobalAppsParams } from './getGlobalAppsParams';

export const preloadGlobalApps = async () => {
	const { edition, accessStatus } = await getPreloaderFnContext();

	const { webItemParams, forgeExtensionParams } = getGlobalAppsParams({
		edition,
		accessStatus,
	});

	const promises = [preloadWebItemLocation(webItemParams)];

	if (fg('confluence_nav_4_global_forge_extensions_preload')) {
		promises.push(preloadUseExtensionList(forgeExtensionParams));
	}

	return Promise.all(promises);
};
