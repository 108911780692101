import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { ApolloProvider } from 'react-apollo'; // 97KB
import { Provider } from 'react-redux'; // 11KB
// We have deprecated unstated. Please use react-sweet-state instead
// eslint-disable-next-line no-restricted-imports
import { Provider as UnstatedProvider } from 'unstated';

import AtlaskitAnalyticsListener from '@atlaskit/analytics-listeners';

import {
	PostOfficeRouterListener,
	PostOfficeProvider,
} from '@atlassian/confluence-post-office-provider';
import { ScreenSpaceFlagsPlacement } from '@atlassian/confluence-post-office-provider/entry-points/screen-space-flags-placement';
import { getAnalyticsWebClient } from '@confluence/analytics-web-client';
import { NavdexListener } from '@confluence/navdex';
import {
	ExperienceTrackerContext,
	/* eslint-disable-next-line no-restricted-imports */
	getExperienceTracker, // eslint-disable-line no-restricted-imports
} from '@confluence/experience-tracker';
import { SessionDataContext } from '@confluence/session-data';
import { I18nProvider } from '@confluence/i18n';
import { CrossFlowProvider } from '@confluence/cross-flow';
import { UpFlowProvider } from '@confluence/change-edition/entry-points/UpFlowProvider';
import { ConfluenceAnalyticsListener } from '@confluence/analytics';
import { SPAViewContextProvider } from '@confluence/spa-view-context';
import { CoreInvitesProvider } from '@confluence/core-invites-provider';
import { BannerStateContainer } from '@confluence/banners';
import { ContentStateStateContainer } from '@confluence/content-state';
import { ReattachCommentProvider } from '@confluence/comment-context';
import { ChoreographerAssetsContextProvider } from '@confluence/choreographer-services';
import { XProductUserInviteProvider } from '@confluence/fabric-media-support';
import { fg } from '@confluence/feature-gating';

import { EngagementProvider } from '../EngagementProvider';

import { App } from './App';

export default class Root extends Component {
	getChildContext() {
		const { context } = this.props;
		const noop = () => {};
		return {
			insertCss: context.insertCss || noop,
		};
	}

	render() {
		window.performance.mark('CFP-63.root-component-render');

		const { store, apolloClient, sessionData } = this.props;

		return (
			// This is at the top because the root-level UnstatedProvider must not re-render
			// otherwise it will lose and recreate its instances
			<UnstatedProvider
				inject={[
					new BannerStateContainer({
						initialState: {
							banners: window.__SSR_BANNERS__,
						},
					}),
					...(fg('confluence_populate_content_state_from_ssr')
						? [new ContentStateStateContainer(window.__SSR_CONTENT_STATE__)]
						: []),
				]}
			>
				<ApolloProvider client={apolloClient}>
					<Provider store={store}>
						<SessionDataContext.Provider value={sessionData}>
							<ChoreographerAssetsContextProvider>
								<SPAViewContextProvider>
									<ExperienceTrackerContext.Provider value={getExperienceTracker()}>
										<I18nProvider>
											<CrossFlowProvider>
												<AtlaskitAnalyticsListener client={getAnalyticsWebClient()}>
													<NavdexListener>
														<EngagementProvider>
															<ConfluenceAnalyticsListener apolloClient={apolloClient}>
																<UpFlowProvider>
																	<CoreInvitesProvider>
																		<XProductUserInviteProvider>
																			<ReattachCommentProvider>
																				<PostOfficeProvider>
																					<PostOfficeRouterListener>
																						<App sessionData={sessionData} />
																						<ScreenSpaceFlagsPlacement />
																					</PostOfficeRouterListener>
																				</PostOfficeProvider>
																			</ReattachCommentProvider>
																		</XProductUserInviteProvider>
																	</CoreInvitesProvider>
																</UpFlowProvider>
															</ConfluenceAnalyticsListener>
														</EngagementProvider>
													</NavdexListener>
												</AtlaskitAnalyticsListener>
											</CrossFlowProvider>
										</I18nProvider>
									</ExperienceTrackerContext.Provider>
								</SPAViewContextProvider>
							</ChoreographerAssetsContextProvider>
						</SessionDataContext.Provider>
					</Provider>
				</ApolloProvider>
			</UnstatedProvider>
		);
	}
}

Root.displayName = 'Root';

Root.childContextTypes = {
	/**
	 * Handler for css injection in components.
	 */
	insertCss: PropTypes.func.isRequired,
};

Root.propTypes = {
	/**
	 * Global store
	 */
	store: PropTypes.object.isRequired,
	/**
	 * Context including the css handler (It has different versions for client and server rendering)
	 */
	context: PropTypes.shape({
		insertCss: PropTypes.func,
	}),

	apolloClient: PropTypes.object.isRequired,
	sessionData: PropTypes.object.isRequired,
};
