import { AnalyticsAction, AnalyticsClient } from '../../../utils/analytics';
import {
	AutoConvertDefinition,
	AutoConvertMacroAttributesMap,
	AutoConvertMacroAttributes,
	AutoConvertMatcherToExtensionsMap,
} from '../types';

export const findMatchedAutoConvertDefinition = (
	link: string,
	autoConvertDefinitions: AutoConvertDefinition[] | null,
) => {
	const matchedAutoConvertDefinition: undefined | AutoConvertDefinition =
		autoConvertDefinitions?.find((acd) => {
			return !!link.match(acd.matcher);
		});
	return matchedAutoConvertDefinition;
};

export const convertLinkToMacroNode = (
	link: string,
	analyticsClient: AnalyticsClient,
	autoConvertDefinitions?: AutoConvertDefinition[],
	macroAttributesMap?: AutoConvertMacroAttributesMap,
	autoConvertMatcherToExtensionsMap?: AutoConvertMatcherToExtensionsMap,
): null | AutoConvertMacroAttributes => {
	if (!autoConvertDefinitions || !macroAttributesMap || !autoConvertMatcherToExtensionsMap) {
		return null;
	}
	const matchedAutoConvertDefinition = findMatchedAutoConvertDefinition(
		link,
		autoConvertDefinitions,
	);
	if (matchedAutoConvertDefinition) {
		const { extensionKey } = matchedAutoConvertDefinition;
		const macroAttributes = macroAttributesMap[extensionKey];
		// Clone the object here to avoid mutating the original object
		const convertedMacroAttributes: AutoConvertMacroAttributes = structuredClone(macroAttributes);
		convertedMacroAttributes.attrs.parameters.autoConvertLink = link;
		convertedMacroAttributes.attrs.parameters.hasBeenAutoConverted = true;

		analyticsClient.sendEvent(AnalyticsAction.FORGE_MACRO_AUTO_CONVERT_SUCCESS, {
			extensionId: convertedMacroAttributes.attrs.parameters.extensionId,
			macroTitle: convertedMacroAttributes.attrs.parameters.extensionTitle,
			forgeEnvironment: convertedMacroAttributes.attrs.parameters.forgeEnvironment,
			render: convertedMacroAttributes.attrs.parameters.render,
			link,
		});

		return convertedMacroAttributes;
	} else {
		return null;
	}
};
