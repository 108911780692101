import { type ComponentPropsConfig } from '../../../utils/codegen';

/**
 * A helper message tells the user what kind of input the field takes.
 * For example, a helper message could be "Password should be more than 4 characters".
 */
export const componentPropsConfig: ComponentPropsConfig = {
	children: true,
	testId: true,
};

/**
 * THIS SECTION WAS CREATED VIA CODEGEN DO NOT MODIFY {@see http://go/af-codegen}
 *
 * This component was generated against an existing platform component.
 * If you are seeing lint errors in this file, it means that this file's dependencies have been modified and the component will need to be regenerated.
 *
 * Please view https://hello.atlassian.net/wiki/spaces/ECO/pages/3358130330/How+to+Regenerate+UI+Kit+Components for more information on how to proceed.
 *
 * For further questions, please ping the #help-forge-ui channel.
 *
 * @codegen <<SignedSource::a59844a2965042cd828adb364cb07249>>
 * @codegenId #helper-message
 * @codegenCommand yarn workspace @atlassian/forge-ui codegen helper-message
 * @codegenDependency ../../../../../../../design-system/form/src/messages.tsx <<SignedSource::2859e3f88bda4f26333a19c51d4281fa>>
 * @codegenDependency ../../../../../../../design-system/form/src/field-id-context.tsx <<SignedSource::e7f0005d72bda85a5d2db3d5c67dc35f>>
 */
/* eslint @repo/internal/codegen/signed-source-integrity: "warn" */
import React from 'react';
import type { RenderFn } from '@atlassian/forge-ui-types';
import { HelperMessage as PlatformHelperMessage } from '@atlaskit/form';

type PlatformHelperMessageProps = React.ComponentProps<typeof PlatformHelperMessage>;

export type HelperMessageProps = Pick<
  PlatformHelperMessageProps,
  'children' | 'testId'
>;

/**
 * A helper message tells the user what kind of input the field takes.
 * For example, a helper message could be "Password should be more than 4 characters".
 */
export const HelperMessage = (props: Parameters<RenderFn>[0]) => {
  const {
    testId,
  } = props.forgeDoc.props as HelperMessageProps;
  return (
    <PlatformHelperMessage
      children={props.forgeDoc.children.map(c => <React.Fragment key={c.key}>{props.render(c)}</React.Fragment>)}
      testId={testId}
    />
  );
};
/**
 * @codegenEnd
 */
