// Note: codeBidi props are not exposed to prevent misuse by malicious developers
import { type ComponentPropsConfig } from '../../../utils/codegen';
import { getTextFromForgeDocChildren } from '../../../utils/getTextFromForgeDocChildren';


export const componentPropsConfig: ComponentPropsConfig = {
	children: {
		value: 'getTextFromForgeDocChildren(props.forgeDoc)',
		comment: 'Only render string children',
	},
	testId: true,
};

/**
 * THIS SECTION WAS CREATED VIA CODEGEN DO NOT MODIFY {@see http://go/af-codegen}
 *
 * This component was generated against an existing platform component.
 * If you are seeing lint errors in this file, it means that this file's dependencies have been modified and the component will need to be regenerated.
 *
 * Please view https://hello.atlassian.net/wiki/spaces/ECO/pages/3358130330/How+to+Regenerate+UI+Kit+Components for more information on how to proceed.
 *
 * For further questions, please ping the #help-forge-ui channel.
 *
 * @codegen <<SignedSource::730adbf33213c43122e833c054e5c50a>>
 * @codegenId #code
 * @codegenCommand yarn workspace @atlassian/forge-ui codegen code
 * @codegenDependency ../../../../../../../design-system/code/src/types.tsx <<SignedSource::d271c01d0f676cbb85111eff8000741e>>
 * @codegenDependency ../../../../../../../design-system/code/src/bidi-warning/index.tsx <<SignedSource::eed1a1140ce6d1464984243dc5b41ba4>>
 * @codegenDependency ../../../../../../../design-system/code/src/bidi-warning/bidi-warning-decorator.tsx <<SignedSource::da907e8c6d00361cb45ab856cd948df8>>
 * @codegenDependency ../../../../../../../design-system/code/src/internal/theme/constants.tsx <<SignedSource::743b7d3fede097dc2d0bec8788738e7c>>
 * @codegenDependency ../../../../../../../design-system/code/src/internal/theme/styles.tsx <<SignedSource::775aa6408b423b4d5aaaf85c65afbd4c>>
 * @codegenDependency ../../../../../../../design-system/code/src/code.tsx <<SignedSource::2b54d1553dfe878f1f179514f694ec84>>
 */
/* eslint @repo/internal/codegen/signed-source-integrity: "warn" */
import React from 'react';
import type { RenderFn } from '@atlassian/forge-ui-types';
import { Code as PlatformCode } from '@atlaskit/code';

type PlatformCodeProps = React.ComponentProps<typeof PlatformCode>;

export type CodeProps = Pick<
  PlatformCodeProps,
  'children' | 'testId'
>;

/**
 * Code highlights short strings of code snippets inline with body text.
 */
export const Code = (props: Parameters<RenderFn>[0]) => {
  const {
    testId,
  } = props.forgeDoc.props as CodeProps;
  return (
    <PlatformCode
      // Only render string children
      children={getTextFromForgeDocChildren(props.forgeDoc)}
      testId={testId}
    />
  );
};
/**
 * @codegenEnd
 */
