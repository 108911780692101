/**
 * Figure out what to do with these, possibly export/shift into editor-common
 * during move into editor-core
 */

import type { Fragment, Node as ProsemirrorNode, Slice } from '@atlaskit/editor-prosemirror/model';
import type { ReadonlyTransaction } from '@atlaskit/editor-prosemirror/state';
import type { Step } from '@atlaskit/editor-prosemirror/transform';
import { ReplaceAroundStep, ReplaceStep } from '@atlaskit/editor-prosemirror/transform';
import { type Decoration } from '@atlaskit/editor-prosemirror/view';

import { createGeneratedContentDecorations, createGeneratedNodeDecorations } from '../actions';

type ReplaceOrReplaceAroundStep = Step & {
	from: number;
	to: number;
	slice: Slice;
};

function getClosestValidTextPositionBefore({ pos, doc }: { pos: number; doc: ProsemirrorNode }) {
	let validPos = pos;
	while (validPos > 0) {
		if (doc.resolve(validPos).parent.inlineContent) {
			break;
		}
		validPos--;
	}
	return validPos;
}

function getClosestValidTextPositionAfter({ pos, doc }: { pos: number; doc: ProsemirrorNode }) {
	let validPos = pos;
	let isValidPos = false;
	while (isValidPos === false) {
		if (doc.resolve(validPos).parent.inlineContent) {
			isValidPos = true;
			break;
		}
		validPos++;
	}
	return validPos;
}

function findLastChildWithTextContent(content: Fragment) {
	if (content.childCount <= 0) {
		return null;
	}

	let count = content.childCount - 1;
	const validNode = content.child(count);

	while (count >= 0) {
		if (content.child(count).textContent !== '') {
			break;
		}
		count--;
	}
	return validNode;
}

function getEndPosition({ step, doc }: { step: ReplaceOrReplaceAroundStep; doc: ProsemirrorNode }) {
	let validPos = step.from + step.slice.size;
	let isValidPos = false;

	// Fragments can contain empty nodes, find the last childNode that has textContent
	const validNode = findLastChildWithTextContent(step.slice.content);

	// Return start position if there are no valid nodes
	if (!validNode) {
		return step.from;
	}

	// Find textContent of last childNode with textContent
	const textContent = validNode.textContent;

	// Loop through endPos and find position where textBetween start and endPos matches textContent from last childNode in step
	// Check slice created between the positions to ensure no empty child nodes
	while (isValidPos === false && validPos > 0) {
		const textBetween = doc.textBetween(step.from, validPos);
		const slice = doc.slice(step.from, validPos);

		if (textBetween.endsWith(textContent) && slice.content.lastChild?.textContent !== '') {
			isValidPos = true;
			break;
		}
		validPos--;
	}

	return validPos;
}

function getValidStartTextPosition({ pos, doc }: { pos: number; doc: ProsemirrorNode }) {
	let startPos = pos;
	let isValidPos = false;
	while (isValidPos === false) {
		if (doc.nodeAt(startPos)) {
			isValidPos = true;
			break;
		}
		startPos++;
	}

	return getClosestValidTextPositionAfter({
		pos: startPos,
		doc: doc,
	});
}

export function getValidEndTextPosition({
	step,
	doc,
}: {
	step: ReplaceOrReplaceAroundStep;
	doc: ProsemirrorNode;
}) {
	const endPos = getEndPosition({ step, doc });

	return getClosestValidTextPositionBefore({
		pos: endPos,
		doc: doc,
	});
}

/**
 * Grabs the step from a transaction inserting AI content.
 *
 *
 * At some point, fold this in to a common "find first replacement step" util
 *
 * Find first replacement step, assume no multi transformation steps
 *
 * will see what prebuilts should import from any helpers, or shift to editor-core/common at some point
 */
export function findFirstReplaceStep(steps: Step[]): ReplaceOrReplaceAroundStep | null {
	const step = steps.find(
		(step): step is ReplaceOrReplaceAroundStep =>
			step instanceof ReplaceStep || step instanceof ReplaceAroundStep,
	);
	if (!step) {
		return null;
	}
	return step;
}

export function getGeneratedDecorationFromTransaction(
	transaction: ReadonlyTransaction,
): ReturnType<typeof createGeneratedContentDecorations> | null {
	const step = findFirstReplaceStep(transaction.steps);
	if (!step) {
		return null;
	}

	const validStartDecorationPos = getValidStartTextPosition({
		pos: step.from,
		doc: transaction.doc,
	});

	const validEndDecorationPos = getValidEndTextPosition({
		step,
		doc: transaction.doc,
	});

	return createGeneratedContentDecorations(validStartDecorationPos, validEndDecorationPos);
}

export function getGeneratedNodeDecorationsFromTransaction(
	transaction: ReadonlyTransaction,
): Array<ReturnType<typeof createGeneratedNodeDecorations>> {
	const replaceSteps = transaction.steps.filter(
		(step): step is ReplaceOrReplaceAroundStep =>
			step instanceof ReplaceStep || step instanceof ReplaceAroundStep,
	);
	if (replaceSteps.length === 0) {
		return [];
	}

	return replaceSteps
		.map((step) => {
			const startPos = step.from;
			const node = transaction.doc.nodeAt(startPos);
			if (node) {
				const endPos = startPos + node.nodeSize;
				return createGeneratedNodeDecorations(startPos, endPos);
			}
		})
		.filter((decoration) => decoration !== undefined) as Decoration[];
}
