import React from 'react';
import type { JSONDocNode } from '@atlaskit/editor-json-transformer';
import type { RenderFn } from '@atlassian/forge-ui-types';
import type { EditorProps } from '../../types';
import { usePresetByFeatures } from '../utils/usePresetByFeatures';

const PlatformCommentEditor = React.lazy(() =>
	import(
		/* webpackChunkName: '@atlaskit-internal_comment-editor' */
		'@atlaskit/editor-core/appearance-editor-comment'
	).then((module) => ({ default: module.CommentEditor })),
);

export type CommentEditorProps = EditorProps & {
	/**
	 * Renders a Save button at the bottom of the editor. Handler is called when this button is clicked.
	 */
	onSave?: (value?: JSONDocNode) => void;
	/**
	 * Renders a Cancel button at the bottom of the editor. Handler is called when this button is clicked.
	 */
	onCancel?: () => void;
};

/**
 * The comment editor provides a contained editor UI with a simple toolbar.
 */
export const CommentEditor = (props: Parameters<RenderFn>[0]) => {
	const { onChange, defaultValue, features, isDisabled, onSave, onCancel } = props.forgeDoc
		.props as CommentEditorProps;

	const { preset, editorApi } = usePresetByFeatures('comment', features);

	return (
		<PlatformCommentEditor
			disabled={isDisabled}
			defaultValue={defaultValue}
			preset={preset}
			onSave={
				onSave
					? () => {
							editorApi?.core.actions.requestDocument((jsonDocNode) => {
								onSave?.(jsonDocNode);
							});
						}
					: undefined
			}
			onCancel={onCancel ? () => onCancel() : undefined}
			onChange={() => {
				editorApi?.core.actions.requestDocument((jsonDocNode) => {
					onChange?.(jsonDocNode);
				});
			}}
		/>
	);
};
