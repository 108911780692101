import type { ParsedUrlQuery } from './types';

/**
 * For the given "queryParams" and "paramName", returns the first value from the query string.
 *
 * If no such param exists, the return value defaults to `undefined`.
 */
export function getSingleParam(
	queryParams: ParsedUrlQuery | null | undefined,
	paramName: string,
): string | undefined {
	const value = queryParams?.[paramName];

	return Array.isArray(value) ? value[0] : value;
}

/**
 * For the given "queryParams" and "paramName", tries to convert the first value of the param
 * to an integer.
 *
 * If no such param exists, or if it does not represent a valid integer number
 * in radix=10, the return value will be `defaultValue` (which is `undefined` by default).
 */
export function getSingleParamInt(
	queryParams: ParsedUrlQuery | null | undefined,
	paramName: string,
): number | undefined;
export function getSingleParamInt<T>(
	queryParams: ParsedUrlQuery | null | undefined,
	paramName: string,
	defaultValue: T,
): number | T;
export function getSingleParamInt<T>(
	queryParams: ParsedUrlQuery | null | undefined,
	paramName: string,
	defaultValue?: T,
): number | T | undefined {
	const value = getSingleParam(queryParams, paramName);
	const intValue = parseInt(value ?? '', 10);

	return isNaN(intValue) ? defaultValue : intValue;
}
