import React from 'react';

import ModalDialog, { ModalTransition } from '@atlaskit/modal-dialog';

import { ScreenEvent } from '@confluence/analytics';

import type { SpaceSelection } from '../page-components/SpaceSelectionRadioButtons';

import { LoadingDialog } from './LoadingDialog';
import { ConfirmationDialog } from './ConfirmationDialog';

export const BulkSpacePermissionsModal = ({
	principalName,
	isLoading,
	isRemoval,
	spaceSelection,
	onCancel,
	onConfirm,
}: {
	principalName: string;
	isLoading: boolean;
	isRemoval: boolean;
	spaceSelection: SpaceSelection;
	onCancel: () => void;
	onConfirm: () => void;
}) => (
	<ModalTransition>
		<ScreenEvent
			name="bulk-space-permissions-dialog"
			id="bulk-space-permissions-dialog"
			attributes={isRemoval ? { action: 'remove' } : { action: 'add' }}
		/>
		<ModalDialog
			testId="bulk-space-permissions-confirmation"
			onClose={
				// Don't allow the dialog to close while mutation is loading
				isLoading ? undefined : onCancel
			}
		>
			{isLoading ? (
				<LoadingDialog />
			) : (
				<ConfirmationDialog
					principalName={principalName}
					isRemoval={isRemoval}
					spaceSelection={spaceSelection}
					onCancel={onCancel}
					onConfirm={onConfirm}
				/>
			)}
		</ModalDialog>
	</ModalTransition>
);
