import type { ComponentType } from 'react';
import React from 'react';

import SuccessIcon from '@atlaskit/icon/core/check-mark';
import ErrorIcon from '@atlaskit/icon/core/cross-circle';
import InfoIcon from '@atlaskit/icon/core/information';
import SuccessCircleIcon from '@atlaskit/icon/core/success';
import WarningIcon from '@atlaskit/icon/core/warning';
import type { GlyphProps } from '@atlaskit/icon/types';
import type { NewIconProps } from '@atlaskit/icon';
import { token } from '@atlaskit/tokens';
import type { IconColor } from '@atlaskit/tokens/css-type-schema';

function colorizeIcon(
	Icon: ComponentType<GlyphProps> | ComponentType<NewIconProps>,
	color: IconColor,
	label: string,
	isBold: boolean,
) {
	return isBold ? (
		<Icon label={label} color="currentColor" spacing="spacious" />
	) : (
		<Icon label={label} color={color} spacing="spacious" />
	);
}

export const FlagIcon = ({
	type,
	isBold,
	customIcon,
}: {
	type?: 'success-circle' | 'success' | 'error' | 'warning' | 'info' | 'custom';
	isBold: boolean;
	customIcon?: JSX.Element;
}) => {
	switch (type) {
		case 'success-circle':
			return colorizeIcon(SuccessCircleIcon, token('color.icon.success'), 'Success', isBold);
		case 'success':
			return colorizeIcon(SuccessIcon, token('color.icon.success'), 'Success', isBold);
		case 'error':
			return colorizeIcon(ErrorIcon, token('color.icon.danger'), 'Error', isBold);
		case 'warning':
			return colorizeIcon(WarningIcon, token('color.icon.warning'), 'Warning', isBold);
		case 'custom':
			return customIcon ?? colorizeIcon(InfoIcon, token('color.icon.information'), 'Info', isBold);
		case 'info':
		default:
			return colorizeIcon(InfoIcon, token('color.icon.information'), 'Info', isBold);
	}
};
