import {
	AutoConvertDefinition,
	AutoConvertMacroAttributesMap,
	AutoConvertMatcherToExtensionsMap,
} from './types';

export const createAutoConvertMatcherToExtensionsMap = (
	autoConvertDefinitions?: AutoConvertDefinition[],
	macroADFNodeMap?: AutoConvertMacroAttributesMap,
): AutoConvertMatcherToExtensionsMap => {
	if (!autoConvertDefinitions || !macroADFNodeMap) {
		return {};
	}
	return autoConvertDefinitions.reduce((acc, def) => {
		if (!acc[def.matcher]) {
			acc[def.matcher] = [];
		}
		acc[def.matcher].push({
			macroName: def.macroName,
			macroADFNode: macroADFNodeMap[def.extensionKey],
			extensionKey: def.extensionKey,
		});
		return acc;
	}, {} as AutoConvertMatcherToExtensionsMap);
};
