type HydrationError = {
	type: string;
	msg: string;
};
export const convertHydrationErrors = (hydrationErrors: HydrationError[]) => {
	return hydrationErrors.reduce((accumulator: { [key: string]: string }, error) => {
		if (!error.type || !error.msg) return accumulator;
		if (!accumulator[error.type]) {
			accumulator[error.type] = '';
		}
		accumulator[error.type] += (accumulator[error.type] ? ' ; ' : '') + error.msg;
		return accumulator;
	}, {});
};
