import { elementToForgeDoc } from '../../../utils/elementToForgeDoc';

import React from 'react';
import type { RenderFn } from '@atlassian/forge-ui-types';
import PlatformTextfield from '@atlaskit/textfield';
import { adaptEventHandler } from '../../utils';
import type { EventHandlerProps } from '../types';
import { useCursorState } from '../../utils/useCursorState';

type PlatformTextfieldProps = React.ComponentProps<typeof PlatformTextfield>;

export type TextfieldProps = Pick<
	PlatformTextfieldProps,
	| 'appearance'
	| 'elemAfterInput'
	| 'elemBeforeInput'
	| 'isCompact'
	| 'autoFocus'
	| 'isReadOnly'
	| 'isMonospaced'
	| 'placeholder'
	| 'testId'
	| 'width'
	| 'type'
	| 'defaultValue'
	| 'min'
	| 'max'
	| 'maxLength'
	| 'minLength'
	| 'pattern'
	| 'id'
	| 'isRequired'
	| 'isDisabled'
	| 'isInvalid'
	| 'value'
	| 'aria-invalid'
	| 'aria-labelledby'
	| 'name'
> &
	Pick<EventHandlerProps, 'onChange' | 'onBlur' | 'onFocus'>;

/**
 * A text field is an input that allows a user to write or edit text.
 */
export const Textfield = (props: Parameters<RenderFn>[0]) => {
	const {
		appearance,
		elemAfterInput,
		elemBeforeInput,
		isCompact,
		autoFocus,
		isReadOnly,
		isMonospaced,
		placeholder,
		testId,
		width,
		type,
		defaultValue,
		min,
		max,
		maxLength,
		minLength,
		pattern,
		// Field props
		id,
		isRequired,
		isDisabled,
		isInvalid,
		onChange,
		onBlur,
		onFocus,
		value,
		'aria-invalid': ariaInvalid,
		'aria-labelledby': ariaLabelledby,
		name,
	} = props.forgeDoc.props as TextfieldProps;

	const { onChangeWithCursorState, inputRef } = useCursorState(value, onChange);

	return (
		<PlatformTextfield
			ref={inputRef}
			appearance={appearance}
			// forge/react reconciler does not convert components in props into ForgeDoc. We need to do it manually here
			elemAfterInput={
				elemAfterInput
					? props.render(elementToForgeDoc(elemAfterInput as React.ReactElement))
					: null
			}
			// forge/react reconciler does not convert components in props into ForgeDoc. We need to do it manually here
			elemBeforeInput={
				elemBeforeInput
					? props.render(elementToForgeDoc(elemBeforeInput as React.ReactElement))
					: null
			}
			isCompact={isCompact}
			autoFocus={autoFocus}
			isReadOnly={isReadOnly}
			isMonospaced={isMonospaced}
			placeholder={placeholder}
			testId={testId}
			width={width}
			type={type}
			defaultValue={defaultValue}
			min={min}
			max={max}
			maxLength={maxLength}
			minLength={minLength}
			pattern={pattern}
			// Field props
			id={id}
			isRequired={isRequired}
			isDisabled={isDisabled}
			isInvalid={isInvalid}
			onChange={adaptEventHandler(onChangeWithCursorState)}
			onBlur={adaptEventHandler(onBlur)}
			onFocus={adaptEventHandler(onFocus)}
			value={value}
			aria-invalid={ariaInvalid}
			aria-labelledby={ariaLabelledby}
			name={name}
		/>
	);
};
