import {
	ConfluenceExtension,
	type ForgeUIExtensionType,
	type ConfluenceExtensionType,
} from '@atlassian/forge-ui-types';
import { createMakeProductURLPath } from '../createMakeProductURLPath';
import {
	type TargetURLUtils,
	getModuleByKey,
	NavigationError,
	ensurePropertyExists,
} from './common';

type FullForgeUIExtensionType = ForgeUIExtensionType & {
	properties: ForgeUIExtensionType['properties'] & {
		route?: string;
	};
};

const makeConfluenceURLPath = createMakeProductURLPath('/wiki');

const isCustomContentType = (contentType: string) => contentType.startsWith('forge:');

export const confluenceExtensionURLHelpers: Partial<
	Record<
		ConfluenceExtensionType,
		(extension: FullForgeUIExtensionType, params?: any) => string | null
	>
> = {
	[ConfluenceExtension.GLOBAL_PAGE]: ({ properties, environmentId, appId }) => {
		const { route } = properties;
		if (appId && environmentId && route) {
			return makeConfluenceURLPath(`/apps/${appId}/${environmentId}/${route}`);
		}
		return null;
	},
	[ConfluenceExtension.GLOBAL_SETTINGS]: ({ id }) => makeConfluenceURLPath('/admin/forge', { id }),
	[ConfluenceExtension.SPACE_PAGE]: (
		{ properties, environmentId, appId },
		{ spaceKey }: { spaceKey: string },
	) => {
		const { route } = properties;
		if (route && environmentId && appId) {
			return makeConfluenceURLPath(
				`/spaces/${ensurePropertyExists('spaceKey', spaceKey)}/apps/${appId}/${environmentId}/${route}`,
			);
		}
		return null;
	},
	[ConfluenceExtension.SPACE_SETTINGS]: (
		{ properties, appId, environmentId },
		{ spaceKey }: { spaceKey: string },
	) => {
		const { route } = properties;
		if (!spaceKey) {
			throw NavigationError.MissingProperty('spaceKey');
		}
		if (route && environmentId && appId) {
			return makeConfluenceURLPath(
				`/spaces/${ensurePropertyExists('spaceKey', spaceKey)}/settings/apps/${appId}/${environmentId}/${route}`,
			);
		}
		return null;
	},
};

export const confluenceNavigationTargetURLHelpers = {
	contentView: async ({ contentId, version }: { contentId: string; version?: number }) => {
		if (!contentId) {
			throw NavigationError.MissingProperty('contentId');
		}
		let resp = null;
		try {
			resp = await fetch(`/wiki/rest/api/content/${contentId}`);
		} catch (e) {
			throw NavigationError.FailedToFetchContentInfo(contentId);
		}

		const {
			id,
			type,
			_links: { webui },
		} = await resp.json();

		// if version is provided, we should use it, however, it only works for none-custom content type
		if (version && version >= 0) {
			if (isCustomContentType(type)) {
				throw new Error('Version is not supported for custom content type');
			}
			return makeConfluenceURLPath('/pages/viewpage.action', {
				pageId: id,
				pageVersion: `${version}`,
			});
		} else if (webui) {
			return makeConfluenceURLPath(webui);
		}
		throw NavigationError.LinkForContentNotFound(contentId);
	},
	contentEdit: async ({ contentId }: { contentId: string }) => {
		let resp;
		try {
			resp = await fetch(`/wiki/rest/api/content/${contentId}`);
		} catch (e) {
			throw NavigationError.FailedToFetchContentInfo(contentId);
		}
		const {
			_links: { edituiv2 },
		} = await resp.json();

		// content with custom content type doesn't have edit link
		if (edituiv2) {
			return makeConfluenceURLPath(edituiv2);
		}
		throw NavigationError.LinkForContentNotFound(contentId);
	},
	spaceView: async ({ spaceKey }: { spaceKey: string }) => {
		return makeConfluenceURLPath(`/spaces/${ensurePropertyExists('spaceKey', spaceKey)}`);
	},
	contentList: async (
		params:
			| {
					contentType: 'page' | 'blogpost';
					spaceKey: string;
			  }
			| {
					contentType: 'customContent';
					moduleKey: string;
			  },
		{ getAppForgeUIExtensions }: TargetURLUtils,
	) => {
		const { contentType } = params;
		if (!contentType) {
			throw NavigationError.MissingProperty('contentType');
		}
		if (contentType === 'customContent' && params.moduleKey) {
			const { moduleKey } = params;
			const module = await getModuleByKey(getAppForgeUIExtensions, moduleKey);
			if (!module) {
				throw NavigationError.ModuleNotFound(moduleKey);
			}
			const { appId, environmentId } = module;
			if (appId && environmentId) {
				return makeConfluenceURLPath('/search', {
					type: `forge:${appId}:${environmentId}:${moduleKey}`,
				});
			}
		} else if (contentType === 'page' && params.spaceKey) {
			return makeConfluenceURLPath(`/spaces/${params.spaceKey}/pages`);
		} else if (contentType === 'blogpost' && params.spaceKey) {
			return makeConfluenceURLPath(`/spaces/${params.spaceKey}/blogs`);
		}
		throw NavigationError.InvalidValueForProperty('contentType', contentType);
	},
} as const;
