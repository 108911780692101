import { useStableObject } from '@atlassian/post-office-context';
import {
	Placement as InternalPlacement,
	type PlacementOnEvent,
} from '@atlassian/post-office-placement-shared';
import {
	type PlacementComponent as PlacementComponentType,
	createPlacementComponent,
} from '@post-office/placement-common';
// @ts-expect-error: 'React' is declared but its value is never read
// React import required to render in Jira SSR
import React, {
	type FunctionComponent,
	type PropsWithChildren,
	type ReactElement,
	memo,
	useMemo,
} from 'react';

import ErrorBoundary from './ErrorBoundary';
import { ANALYTICS_CHANNEL } from './constants';

const STABLE_OBJECT: LocalPlacementProps = {};

type LocalPlacementProps = Record<string, unknown>;

type PlacementID = string;

interface BaseProps {
	placementId: PlacementID;
	PlacementComponent: PlacementComponentType<LocalPlacementProps>;
	placementProps?: LocalPlacementProps;
	ErrorFallback?: FunctionComponent;
	Fallback?: ReactElement | null;
	disableInitialData?: boolean;
	onEvent?: PlacementOnEvent;
}
export type PlacementProps = PropsWithChildren<BaseProps>;

const Placement: FunctionComponent<PlacementProps> = ({
	placementId,
	placementProps,
	children,
	ErrorFallback,
	PlacementComponent,
	disableInitialData = false,
	onEvent,
}) => {
	const MemoizedPlacement = useMemo(
		() => createPlacementComponent(placementId, PlacementComponent),
		[placementId, PlacementComponent],
	);

	const stableLocalPlacementProps = useStableObject<LocalPlacementProps>(
		placementProps ?? STABLE_OBJECT,
	);

	return (
		<InternalPlacement
			placementId={placementId}
			consumptionType="local"
			onEvent={onEvent}
			disableInitialData={disableInitialData}
		>
			{({ initialData, context, onEvent: sharedOnEvent }) => (
				<ErrorBoundary
					analyticsChannel={ANALYTICS_CHANNEL}
					placementId={placementId}
					onError={sharedOnEvent}
					fallback={ErrorFallback}
				>
					<MemoizedPlacement
						initialData={initialData}
						context={context}
						// eslint-disable-next-line @typescript-eslint/ban-ts-comment
						// @ts-ignore - Confluence Local Consumption
						errorFallback={ErrorFallback}
						{...stableLocalPlacementProps}
					>
						{children}
					</MemoizedPlacement>
				</ErrorBoundary>
			)}
		</InternalPlacement>
	);
};

export default memo(Placement);
