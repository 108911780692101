import { type ComponentPropsConfig } from '../../../utils/codegen';

export const componentPropsConfig: ComponentPropsConfig = {
	size: true,
	testId: true,
	defaultChecked: true,
	isChecked: true,
	label: true,
};

/**
 * THIS SECTION WAS CREATED VIA CODEGEN DO NOT MODIFY {@see http://go/af-codegen}
 *
 * This component was generated against an existing platform component.
 * If you are seeing lint errors in this file, it means that this file's dependencies have been modified and the component will need to be regenerated.
 *
 * Please view https://hello.atlassian.net/wiki/spaces/ECO/pages/3358130330/How+to+Regenerate+UI+Kit+Components for more information on how to proceed.
 *
 * For further questions, please ping the #help-forge-ui channel.
 *
 * @codegen <<SignedSource::87a222ed2ab796cd385f4c7f1f2f18ea>>
 * @codegenId #toggle
 * @codegenCommand yarn workspace @atlassian/forge-ui codegen toggle
 * @codegenParams { "hasFieldProps": { "isRequired": false, "isInvalid": false, "aria-invalid": false, "aria-labelledby": false } }
 * @codegenDependency ../../../../../../../design-system/toggle/src/types.tsx <<SignedSource::a770f09c4d02fcde594f342e6c467e7d>>
 * @codegenDependency ../../../../../../../analytics/analytics-next/src/hocs/withAnalyticsEvents.tsx <<SignedSource::912bf75b9266c0c95b2e6bf4d8e3c915>>
 * @codegenDependency ../../../../../../../design-system/toggle/src/icon-container.tsx <<SignedSource::3fb3f2370904e913f8624d9f8ee3eb15>>
 * @codegenDependency ../../../../../../../design-system/toggle/src/toggle.tsx <<SignedSource::73a6b97a831fbdfd7fd1d1e6da58ee3a>>
 */
/* eslint @repo/internal/codegen/signed-source-integrity: "warn" */
import React from 'react';
import type { RenderFn } from '@atlassian/forge-ui-types';
import PlatformToggle from '@atlaskit/toggle';
import { adaptEventHandler } from "../../../utils";
import type { EventHandlerProps } from "../../types";

type PlatformToggleProps = React.ComponentProps<typeof PlatformToggle>;

export type ToggleProps = Pick<
  PlatformToggleProps,
  'size' | 'testId' | 'defaultChecked' | 'isChecked' | 'label'
 | 'id' | 'isDisabled' | 'value' | 'name'
> & Pick<EventHandlerProps, 'onChange' | 'onBlur' | 'onFocus'>;

/**
 * A toggle is used to view or switch between enabled or disabled states.
 */
export const Toggle = (props: Parameters<RenderFn>[0]) => {
  const {
    size,
    testId,
    defaultChecked,
    isChecked,
    label,
    // Field props
    id,
    isDisabled,
    onChange,
    onBlur,
    onFocus,
    value,
    name,
  } = props.forgeDoc.props as ToggleProps;
  return (
    <PlatformToggle
      size={size}
      testId={testId}
      defaultChecked={defaultChecked}
      isChecked={isChecked}
      label={label}
      // Field props
      id={id}
      isDisabled={isDisabled}
      onChange={adaptEventHandler(onChange)}
      onBlur={adaptEventHandler(onBlur)}
      onFocus={adaptEventHandler(onFocus)}
      value={value}
      name={name}
    />
  );
};
/**
 * @codegenEnd
 */
