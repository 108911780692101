/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum SystemSpaceHomepageTemplate {
  EAP = "EAP",
  MINIMAL = "MINIMAL",
  VISUAL = "VISUAL",
}

export interface SystemSpaceHomepageInput {
  systemSpaceHomepageTemplate: SystemSpaceHomepageTemplate;
}

//==============================================================
// END Enums and Input Objects
//==============================================================

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateCompanyHubSpaceMutation
// ====================================================

export interface CreateCompanyHubSpaceMutation_createSystemSpace {
  homepageId: string | null;
  id: string | null;
  name: string | null;
  type: string | null;
}

export interface CreateCompanyHubSpaceMutation {
  createSystemSpace: CreateCompanyHubSpaceMutation_createSystemSpace;
}

export interface CreateCompanyHubSpaceMutationVariables {
  input: SystemSpaceHomepageInput;
}
