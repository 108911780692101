import type { AIDecorationExperiencePluginState } from '../reducer';

export const hasGeneratedContentDecorations = (state: AIDecorationExperiencePluginState) =>
	state.modalDecorationSet.find(
		undefined,
		undefined,
		(spec) =>
			spec.key === 'generatedInlineDecoration' ||
			spec.key === 'generatedNodeDecoration' ||
			spec.key === 'generatedStartWidget' ||
			spec.key === 'generatedEndWidget',
	).length > 0;
