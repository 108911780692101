import { useEffect } from 'react';
import { useAnalyticsEvents } from '@atlaskit/analytics-next';

import { trackRendererRendered } from '../analytics/useForgeUiAnalyticsEvent';
import { useMetricsContext } from '../context/metrics';

export const useSendRendererRenderedAnalytics = () => {
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const { page: source } = useMetricsContext();

	useEffect(() => {
		trackRendererRendered(createAnalyticsEvent, {
			source,
			target: 'renderer',
		});
	});
};
