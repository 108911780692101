import React from 'react';
import { defineMessages, useIntl } from 'react-intl-next';

import { Box, Stack, xcss, Text, Inline } from '@atlaskit/primitives';
import Heading from '@atlaskit/heading';
import Button from '@atlaskit/button/new';

import type { SpaceRole } from '@confluence/space-roles/entry-points/space-role-types';

const i18n = defineMessages({
	viewRolePermissionsLabel: {
		id: 'bulk-permissions.info-popup.view-role-permissions-label',
		defaultMessage: 'View role permissions',
		description:
			'Label for the view role permissions link in the popup that is shown to an admin on popup',
	},
});

const contentStyle = xcss({
	width: '315px',
	padding: 'space.300',
});

const headerStyle = xcss({
	marginBottom: 'space.100',
});

const buttonStyle = xcss({
	paddingTop: 'space.150',
	display: 'flex',
	alignItems: 'center',
});

const usePermissionsSummary = ({
	roleId,
	roleLookup,
}: {
	roleId: string | null;
	roleLookup: SpaceRole[];
}) => {
	const role = roleLookup.find((lookup) => lookup.id === roleId);

	// Role access
	return {
		heading: role?.name,
		description: role?.description,
	};
};

export const InfoPopupContent = ({
	onCtaClick,
	roleId,
	roleLookup,
}: {
	onCtaClick: () => void;
	roleId: string | null;
	permissions: string[];
	roleLookup: SpaceRole[];
}) => {
	const { formatMessage } = useIntl();
	const { heading, description } = usePermissionsSummary({
		roleId,
		roleLookup,
	});

	return (
		<Stack xcss={contentStyle}>
			<>
				<Box xcss={headerStyle}>
					<Heading size="xsmall">{heading}</Heading>
				</Box>
				<Text>{description}</Text>
				<Inline xcss={buttonStyle}>
					{Boolean(roleId) ? (
						<Button onClick={onCtaClick}>{formatMessage(i18n.viewRolePermissionsLabel)}</Button>
					) : null}
				</Inline>
			</>
		</Stack>
	);
};
