// Need to disable aobve as there's a prop named token that's being used already
/** @jsx jsx */
import type { FC, Ref } from 'react';
import React from 'react';
import { useIntl } from 'react-intl-next';
import memoizeOne from 'memoize-one';
import { css, jsx } from '@compiled/react';

import { token as cssToken } from '@atlaskit/tokens';
import type { MediaClientConfig } from '@atlaskit/media-core';
import type { FileIdentifier } from '@atlaskit/media-client';
import { MediaImage } from '@atlaskit/media-image';
import ImageIcon from '@atlaskit/icon/core/image';
import { N800 } from '@atlaskit/theme/colors';
import Tooltip from '@atlaskit/tooltip/Tooltip';

import type { InlineImageComponentProps } from './types';
import { i18n } from './i18n';

const imageWrapper = css({
	display: 'inline',
});

const ImageWrapperWithRef = React.forwardRef((props, ref: Ref<HTMLElement>) => {
	return <div css={imageWrapper} {...props} ref={ref as Ref<HTMLDivElement>} />;
});

const getMediaClientConfig: (
	clientId: string,
	token: string,
	baseUrl: string,
) => MediaClientConfig = memoizeOne((clientId, token, baseUrl) => ({
	authProvider: () =>
		Promise.resolve({
			clientId,
			token,
			baseUrl,
		}),
}));

const getApiConfig = memoizeOne((collectionName, width, height) => ({
	collectionName,
	width: Number(width) || undefined,
	height: Number(height) || undefined,
}));

const getIdentifier: (collectionName: string, identifier: string) => FileIdentifier = memoizeOne(
	(collectionName, identifier) => ({
		collectionName,
		id: identifier,
		mediaItemType: 'file',
	}),
);

export const InlineImageComponent: FC<InlineImageComponentProps> = ({
	width = 'auto',
	height = 'auto',
	id,
	collection,
	clientId,
	token,
	link,
	baseUrl,
}: InlineImageComponentProps) => {
	const intl = useIntl();
	return (
		<MediaImage
			identifier={getIdentifier(collection, id)}
			mediaClientConfig={getMediaClientConfig(clientId, token, baseUrl)}
			apiConfig={getApiConfig(collection, width, height)}
		>
			{({ loading, error, data }) => {
				if (loading) {
					return null;
				}

				if (error || !data) {
					return (
						<Tooltip tag="span" content={intl.formatMessage(i18n.imageFailedToLoad)}>
							<ImageIcon
								color={cssToken('color.text', N800)}
								label={intl.formatMessage(i18n.imageFailedToLoad)}
							/>
						</Tooltip>
					);
				}

				return (
					<Tooltip content={intl.formatMessage(i18n.openLink)} tag={ImageWrapperWithRef} delay={0}>
						<a href={link}>
							<img src={data.src} alt="" />
						</a>
					</Tooltip>
				);
			}}
		</MediaImage>
	);
};
