import { RenderTrackerProvider } from './render-tracker';
import { StableAnaltyicsProvider } from './stable-analytics';
import { StableChoreographerProvider } from './stable-choreographer';
import { StableContextProvider } from './stable-context';
import { StablePlacementProvider } from './stable-placement';
import { StableRouteProvider } from './stable-route';
import type { StableProvidersProps } from './types';
import type { StableProvider } from '../types';

export { PerformanceTracking } from './performance-tracking';

export const StableProviders: StableProvider<StableProvidersProps> = ({
	children,
	context,
	placement,
	route,
	choreographer,
}) => (
	<StableContextProvider context={context}>
		<StablePlacementProvider placement={placement}>
			<StableRouteProvider route={route}>
				<StableAnaltyicsProvider>
					<StableChoreographerProvider choreographer={choreographer}>
						<RenderTrackerProvider placementId={placement.placementId}>
							{children}
						</RenderTrackerProvider>
					</StableChoreographerProvider>
				</StableAnaltyicsProvider>
			</StableRouteProvider>
		</StablePlacementProvider>
	</StableContextProvider>
);
