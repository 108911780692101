import type { ApolloError } from 'apollo-client';

import { query } from '@confluence/query-preloader-tools';

import { SpaceDetailQuery } from './SpaceDetailQuery.graphql';
import type { SpaceDetailQuery as SpaceDetailQueryType } from './__types__/SpaceDetailQuery';

export const fetchSpaceDetail = (
	spaceKey: string | null | undefined = '',
): Promise<{
	data: SpaceDetailQueryType | null;
	loading?: boolean;
	error?: ApolloError | null;
}> => {
	if (spaceKey) {
		return query({
			query: SpaceDetailQuery,
			variables: {
				spaceKey,
			},
		});
	}

	return Promise.resolve({ data: null, loading: false, error: null });
};
