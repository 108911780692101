import { type ComponentPropsConfig } from '../../../utils/codegen';

/**
 * Use a form section to group related information together, so that longer forms are easier to understand.
 * There can be multiple form sections in one form.
 */
export const componentPropsConfig: ComponentPropsConfig = {
	title: true,
	children: true,
	description: true,
};

/**
 * THIS SECTION WAS CREATED VIA CODEGEN DO NOT MODIFY {@see http://go/af-codegen}
 *
 * This component was generated against an existing platform component.
 * If you are seeing lint errors in this file, it means that this file's dependencies have been modified and the component will need to be regenerated.
 *
 * Please view https://hello.atlassian.net/wiki/spaces/ECO/pages/3358130330/How+to+Regenerate+UI+Kit+Components for more information on how to proceed.
 *
 * For further questions, please ping the #help-forge-ui channel.
 *
 * @codegen <<SignedSource::bb2ab8946e58e295a5264225361bab85>>
 * @codegenId #form-section
 * @codegenCommand yarn workspace @atlassian/forge-ui codegen form-section
 * @codegenDependency ../../../../../../../design-system/form/src/form-section.tsx <<SignedSource::7ee6359746c4b1544bbbfa71142ac023>>
 */
/* eslint @repo/internal/codegen/signed-source-integrity: "warn" */
import React from 'react';
import type { RenderFn } from '@atlassian/forge-ui-types';
import { FormSection as PlatformFormSection } from '@atlaskit/form';

type PlatformFormSectionProps = React.ComponentProps<typeof PlatformFormSection>;

export type FormSectionProps = Pick<
  PlatformFormSectionProps,
  'title' | 'children' | 'description'
>;

/**
 * Use a form section to group related information together, so that longer forms are easier to understand.
 * There can be multiple form sections in one form.
 */
export const FormSection = (props: Parameters<RenderFn>[0]) => {
  const {
    title,
    description,
  } = props.forgeDoc.props as FormSectionProps;
  return (
    <PlatformFormSection
      title={title}
      children={props.forgeDoc.children.map(c => <React.Fragment key={c.key}>{props.render(c)}</React.Fragment>)}
      description={description}
    />
  );
};
/**
 * @codegenEnd
 */
