import React from 'react';
import { defineMessages, useIntl } from 'react-intl-next';

import { Box, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';

import { BulkSpacePermissionsTable } from '../BulkSpacePermissionsTable';
import type { SpacePermissionType } from '../graphql/__types__/BulkSpacePermissionsMutation';

const i18n = defineMessages({
	permissionsSelectionHeader: {
		id: 'bulk-permissions.space.permissions-selection-header',
		defaultMessage: 'Choose space permissions',
		description: 'Header text of the permissions selection section',
	},
	permissionsSelectionSubtext: {
		id: 'bulk-permissions.space.permissions-selection-subtext',
		defaultMessage:
			'Select which permissions the user or group should have in all spaces. This will overwrite any existing permissions they have. <b>This can’t be undone</b> so make sure to double check who you’re adding and what permissions they should have.',
		description: 'Subtext of the permissions selection section',
	},
	permissionsSelectionSubtextAdditional: {
		id: 'bulk-permissions.space.permissions-selection-subtext-additional',
		defaultMessage: 'To remove permissions from all spaces, uncheck the view permission.',
		description: 'Additional subtext of the permissions selection section',
	},
});

const permissionSelectionHeaderStyle = xcss({
	font: token('font.body.large'),
	fontWeight: token('font.weight.medium'),
	paddingBottom: 'space.100',
	paddingTop: 'space.300',
});

const subtextStyle = xcss({
	font: token('font.body'),
});

type PermissionSelectionSectionProps = {
	spacePermissions: SpacePermissionType[];
	setSpacePermissions: (permissions: SpacePermissionType[]) => void;
};

export const PermissionSelectionSection = ({
	spacePermissions,
	setSpacePermissions,
}: PermissionSelectionSectionProps) => {
	const { formatMessage } = useIntl();

	return (
		<>
			<Box xcss={permissionSelectionHeaderStyle}>
				{formatMessage(i18n.permissionsSelectionHeader)}
			</Box>
			<Box xcss={subtextStyle}>
				{formatMessage(i18n.permissionsSelectionSubtext, {
					b: (chunks: React.ReactNode[]) => <b>{chunks}</b>,
				})}
				<br />
				<Box paddingBlockStart="space.200">
					{formatMessage(i18n.permissionsSelectionSubtextAdditional)}
				</Box>
			</Box>
			<BulkSpacePermissionsTable
				spacePermissions={spacePermissions}
				setSpacePermissions={setSpacePermissions}
			/>
		</>
	);
};
