import gql from 'graphql-tag';

export const RequestViewQuery = gql`
	query RequestViewQuery($id: ID!) {
		singleContent(id: $id) {
			id
			status
			links {
				webui
			}
		}

		user(current: true) {
			id
			confluence {
				roles {
					canBeSuperAdmin
				}
			}
		}
	}
`;
