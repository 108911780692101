import type { FieldProps } from '@atlassian/forge-ui-types';
import type { DateTimePickerSelectProps } from '@atlaskit/datetime-picker';
import { type ComponentPropsConfig } from '../../../utils/codegen';

/**
 * A time picker allows the user to select a specific time.
 */
export const componentPropsConfig: ComponentPropsConfig = {
	appearance: true,
	defaultIsOpen: true,
	autoFocus: true,
	defaultValue: true,
	formatDisplayLabel: false,
	innerProps: false,
	isOpen: true,
	label: true,
	parseInputValue: false,
	spacing: true,
	times: true,
	timeIsEditable: true,
	hideIcon: true,
	timeFormat: true,
	placeholder: true,
	locale: true,
	testId: true,
	selectProps: true,
	onChange: true,
	value: {
		replaceLine: true,
		value: '{...(value !== undefined ? { value }: undefined) }',
		comment: 'Explicitly setting value to undefined is causing issues',
	},
};
/**
 * Excluded props:
 * - innerProps - not supporting HTML attributes at this time
 * - parseInputValue - not supporting as it does not work, function can't be passed outside of runtime
 * - formatDisplayLabel - not supporting as it does not work, function can't be passed outside of runtime
 */

// Type copied from packages/design-system/datetime-picker/src/components/time-picker.tsx
type Appearance = 'default' | 'subtle' | 'none';
type Spacing = 'compact' | 'default';

export type TimePickerProps = {
	appearance?: Appearance;
	autoFocus?: boolean;
	defaultIsOpen?: boolean;
	defaultValue?: string;
	isOpen?: boolean;
	label?: string;
	onChange?: (value: string) => void;
	spacing?: Spacing;
	times?: string[];
	timeIsEditable?: boolean;
	value?: string;
	isInvalid?: boolean;
	hideIcon?: boolean;
	timeFormat?: string;
	placeholder?: string;
	locale?: string;
	testId?: string;
	selectProps?: DateTimePickerSelectProps;
} & Partial<Omit<FieldProps, 'onChange'>>;

/**
 * THIS SECTION WAS CREATED VIA CODEGEN DO NOT MODIFY {@see http://go/af-codegen}
 *
 * This component was generated against an existing platform component.
 * If you are seeing lint errors in this file, it means that this file's dependencies have been modified and the component will need to be regenerated.
 *
 * Please view https://hello.atlassian.net/wiki/spaces/ECO/pages/3358130330/How+to+Regenerate+UI+Kit+Components for more information on how to proceed.
 *
 * For further questions, please ping the #help-forge-ui channel.
 *
 * @codegen <<SignedSource::92884e797d6eb9afed90d195efeaa405>>
 * @codegenId #time-picker
 * @codegenCommand yarn workspace @atlassian/forge-ui codegen time-picker
 * @codegenParams {  "defineOwnType": true, "hasFieldProps": { "value": false, "onChange": false} }
 * @codegenDependency ../../../../../../../design-system/datetime-picker/src/types.tsx <<SignedSource::565058cc799c249288166aa59a14e471>>
 * @codegenDependency ../../../../../../../analytics/analytics-next/src/hocs/withAnalyticsEvents.tsx <<SignedSource::912bf75b9266c0c95b2e6bf4d8e3c915>>
 * @codegenDependency ../../../../../../../design-system/datetime-picker/src/internal/index.tsx <<SignedSource::78a77bf39be7819249c18801c3d7f4b9>>
 * @codegenDependency ../../../../../../../design-system/datetime-picker/src/internal/fixed-layer-menu.tsx <<SignedSource::8a5892613bc69fde2d990aea26e29e6d>>
 * @codegenDependency ../../../../../../../design-system/datetime-picker/src/internal/parse-time.tsx <<SignedSource::5ef6cbd79d19c4509ee2be60e271f4f3>>
 * @codegenDependency ../../../../../../../design-system/datetime-picker/src/internal/parse-tokens.tsx <<SignedSource::ddc88693298751deb4801a641ea3b0a3>>
 * @codegenDependency ../../../../../../../design-system/datetime-picker/src/internal/single-value.tsx <<SignedSource::555aab359c98135752aadcb9a0c27882>>
 * @codegenDependency ../../../../../../../design-system/datetime-picker/src/components/time-picker.tsx <<SignedSource::7268a45a463e2b3a5aa5cb1d28801ecb>>
 */
/* eslint @repo/internal/codegen/signed-source-integrity: "warn" */
import React from 'react';
import type { RenderFn } from '@atlassian/forge-ui-types';
import { TimePicker as PlatformTimePicker } from '@atlaskit/datetime-picker';
import { adaptEventHandler } from "../../../utils";

// Define the type for TimePicker as TimePickerProps at the top of this file

/**
 * A time picker allows the user to select a specific time.
 */
export const TimePicker = (props: Parameters<RenderFn>[0]) => {
  const {
    appearance,
    defaultIsOpen,
    autoFocus,
    defaultValue,
    isOpen,
    label,
    spacing,
    times,
    timeIsEditable,
    hideIcon,
    timeFormat,
    placeholder,
    locale,
    testId,
    selectProps,
    onChange,
    value,
    // Field props
    id,
    isRequired,
    isDisabled,
    isInvalid,
    onBlur,
    onFocus,
    "aria-invalid": ariaInvalid,
    "aria-labelledby": ariaLabelledby,
    name,
  } = props.forgeDoc.props as TimePickerProps;
  // warning: appearance is deprecated;
  // warning: defaultIsOpen is deprecated;
  return (
    <PlatformTimePicker
      appearance={appearance}
      defaultIsOpen={defaultIsOpen}
      autoFocus={autoFocus}
      defaultValue={defaultValue}
      isOpen={isOpen}
      label={label}
      spacing={spacing}
      times={times}
      timeIsEditable={timeIsEditable}
      hideIcon={hideIcon}
      timeFormat={timeFormat}
      placeholder={placeholder}
      locale={locale}
      testId={testId}
      selectProps={selectProps}
      onChange={onChange}
      // Explicitly setting value to undefined is causing issues
      {...(value !== undefined ? { value }: undefined) }
      // Field props
      id={id}
      isRequired={isRequired}
      isDisabled={isDisabled}
      isInvalid={isInvalid}
      onBlur={adaptEventHandler(onBlur)}
      onFocus={adaptEventHandler(onFocus)}
      aria-invalid={ariaInvalid}
      aria-labelledby={ariaLabelledby}
      name={name}
    />
  );
};
/**
 * @codegenEnd
 */
