import React, { useState } from 'react';

import { getCompanyHubSpaceKey } from '@confluence/route-manager/entry-points/companyHubUtils';
import { PRODUCT_HOME_ID } from '@confluence/app-navigation/entry-points/navigationIDs';
import { useCompanyHubOnRouteCreationFlow } from '@confluence/company-hub-utils/entry-points/useCompanyHubOnRouteCreationFlow';
import { useCompanyHubPremiumGate } from '@confluence/company-hub-utils/entry-points/useCompanyHubPremiumGate';
import { CompanyHomeBuilderUpsellPage } from '@confluence/change-edition/entry-points/CompanyHomeBuilderUpsellPage';
import { FullPageLoadingScreen } from '@confluence/full-page-loading-screen';
import { usePageContentId } from '@confluence/page-context';
import type { RouteMatch } from '@confluence/route';
import { useRouteActions } from '@confluence/route-manager';
import { END } from '@confluence/navdex';
import { HubErrorPageComponent } from '@confluence/company-hub-utils/entry-points/HubErrorPageComponent';

import { EditPageRoute } from '../EditPageRoute';

import { getCompanyHubPseudoAppRoute } from './getCompanyHubPseudoAppRoute';

export function getCompanyHubEditRoute(component) {
	const CompanyHubEditRoute = (routeProps: RouteMatch) => {
		const spaceKey = getCompanyHubSpaceKey();
		const [contentId] = usePageContentId();
		const [queriedContentId, setQueriedContentId] = useState<string | undefined | null>(null);
		const { setQueryParams } = useRouteActions();
		const shouldShowPremiumUpsell = useCompanyHubPremiumGate();

		const { isCreatingHub, creationError } = useCompanyHubOnRouteCreationFlow({
			onSuccess: (homepageId) => {
				setQueriedContentId(homepageId);
				setQueryParams({ redirect: 'first-time' });
			},
			onExists: (homepageId) => {
				setQueriedContentId(homepageId);
			},
		});

		if (creationError) {
			return <HubErrorPageComponent error={creationError} />;
		}

		if (shouldShowPremiumUpsell) {
			return <CompanyHomeBuilderUpsellPage featureName="companyHub" />;
		}

		// If there is no contentId in SSR then we will just see the FullPageLoadingScreen being rendered
		// So we skip the loading screen component to ensure the EditPageRoute is rendered instead
		// eslint-disable-next-line check-react-ssr-usage/no-react-ssr
		if ((isCreatingHub || (!queriedContentId && !contentId)) && !process.env.REACT_SSR) {
			return <FullPageLoadingScreen />;
		}

		const Component = component;
		return (
			<Component
				{...routeProps}
				params={{
					contentId: contentId ?? (queriedContentId || ''),
					spaceKey,
					contentType: 'pages',
				}}
			/>
		);
	};

	CompanyHubEditRoute.NAVIGATION_PARAMS = (routeProps: RouteMatch) => {
		const spaceKey = getCompanyHubSpaceKey();
		return {
			Screen: {
				screenEvent: {
					name: 'companyHubEditScreen',
					id: spaceKey,
					attributes: {
						navdexPointType: END,
						// Company Hub hides its space from the user, doesn't refer to its page
						// as such, is rather presented to the user as "a new surface", and thus
						// doesn't constitute space usage from the perspective of the user.
						screenIsInSpace: false,
					},
				},
				// When no contentId is passed, Screen.js handles querying it and updating pageState with it.
				// usually, this is done by the Screen.js component, but in this case, we need to do it here
				// because contentId is not getting queried properly on route creation.
				pageState: { spaceKey, contentId: routeProps?.query?.contentId },
			},
			MainLayout: {
				navView: PRODUCT_HOME_ID,
			},
		};
	};

	return getCompanyHubPseudoAppRoute(CompanyHubEditRoute);
}

export const CompanyHubEditRouteForSSR = getCompanyHubEditRoute(EditPageRoute);
