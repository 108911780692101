import { LoadablePaint } from '@confluence/loadable';

export const CompanyHubMainLayout = LoadablePaint({
	_reactTreeRootForServerPlaceholderId: true,
	name: 'CompanyHubMainLayout',
	loader: async () =>
		(
			await import(
				/* webpackChunkName: "loadable-CompanyHubMainLayout" */ '../src/company-hub/CompanyHubMainLayout'
			)
		).CompanyHubMainLayout,
});
