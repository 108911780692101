/**
 * Validates auto convert url patterns. The regex is matching:
 * - a required protocol (http:// or https://)
 * - allows wildcard (*) in any part of the url
 * - no whitespaces
 * - 1024 character limit
 * For more information: https://hello.atlassian.net/wiki/spaces/ECO/pages/4856894219/Manifest+Schema+Changes+Documentation
 */
export const isPatternValid = (pattern: string) => {
	const urlRegex =
		/^(?=.{1,1024}$)https?:\/\/(?:[a-zA-Z0-9-\*]+\.)*[a-zA-Z0-9-\*]+(?:\.[a-zA-Z\*]{1,})?(?:\/.*)?$/;

	return urlRegex.test(pattern);
};
