import type { EditorState } from 'prosemirror-state';
import type { CommandDispatch, EditorActions } from '@atlaskit/editor-core';
import type { EditorView } from '@atlaskit/editor-prosemirror/dist/types/view';

import { findMatchedAutoConvertDefinition } from './convertLinkToMacroNode';
import { AutoConvertDefinition, AutoConvertMatcherToExtensionsMap } from '../types';
import { createLocalId } from '../../../utils/createLocalId';
import { EXTENSION_NAMESPACE } from '../../../utils/constants';

const getExtensionTypeFromADFNodeLayout = (layout: string) => {
	switch (layout) {
		case 'bodied':
			return 'bodiedExtension';
		case 'inline':
			return 'inlineExtension';
		default:
			return 'extension';
	}
};

const getNodeByLayout = (
	extension: Omit<AutoConvertDefinition, 'matcher'>,
	currentNodeType?: string,
	pastedLink?: string,
) => {
	const localId = createLocalId();
	const extensionType = getExtensionTypeFromADFNodeLayout(
		extension.macroADFNode?.attrs.parameters.layout,
	);
	const extensionNode = {
		type: extensionType,
		attrs: {
			extensionKey: extension.extensionKey,
			extensionType: EXTENSION_NAMESPACE,
			localId: localId,
			parameters: {
				extensionId: extension.macroADFNode?.attrs.parameters.extensionId,
				extensionTitle: extension.macroADFNode?.attrs.parameters.extensionTitle,
				forgeEnvironment: extension.macroADFNode?.attrs.parameters.forgeEnvironment,
				render: extension.macroADFNode?.attrs.parameters.render,
				layout: extension.macroADFNode?.attrs.parameters.layout,
				autoConvertLink: pastedLink,
				hasBeenAutoConverted: true,
			},
			text: extension.macroADFNode?.attrs.text,
		},
		...(extensionType === 'bodiedExtension'
			? {
					// include empty content so bodiedExtension doesn't throw error
					content: [{ type: 'paragraph', content: [] }],
				}
			: {}),
	};

	//Changing from a block/bodied to inline extension requires establishing a paragraph wrapping node
	if (
		getExtensionTypeFromADFNodeLayout(extension.macroADFNode?.attrs.parameters.layout) ===
			'inlineExtension' &&
		(currentNodeType === 'extension' || currentNodeType === 'bodiedExtension')
	) {
		return {
			type: 'paragraph',
			content: [extensionNode],
		};
	}
	return extensionNode;
};

export const makeAutoConvertDropdownOptions = (
	autoConvertDefinitions?: AutoConvertDefinition[] | null,
	autoConvertMatcherToExtensionsMap?: AutoConvertMatcherToExtensionsMap | null,
	editorActions?: EditorActions,
	link?: string,
) => {
	if (!autoConvertDefinitions || !autoConvertMatcherToExtensionsMap) {
		return [];
	}

	let pastedLink = link;
	if (!link) {
		const node = editorActions?.getSelectedNode();
		pastedLink = node?.attrs.parameters.autoConvertLink;
	}

	const matchedAutoConvertDefinition = pastedLink
		? findMatchedAutoConvertDefinition(pastedLink, autoConvertDefinitions)
		: undefined;

	if (matchedAutoConvertDefinition && autoConvertMatcherToExtensionsMap) {
		const extensionsWithTheSameMatcher =
			autoConvertMatcherToExtensionsMap[matchedAutoConvertDefinition.matcher];
		const node = editorActions?.getSelectedNode();
		const currentNodeType = node?.type.name;
		const options = extensionsWithTheSameMatcher.map((extension) => {
			return {
				id: `${extension.extensionKey}-dropdown-option`,
				title: extension.macroName,
				selected: extension.extensionKey === node?.attrs.extensionKey,
				onClick: async (state: EditorState, dispatch?: CommandDispatch, view?: EditorView) => {
					if (view) {
						const { selection } = view?.state;
						const insertPosition = selection.$from.pos;

						// If the current node is an inlineExtension, replaceSelection is unable to delete the current inline node
						if (currentNodeType === 'inlineExtension') {
							const transaction = state.tr.deleteSelection().scrollIntoView();
							view?.dispatch(transaction);
						}
						editorActions?.replaceSelection(
							getNodeByLayout(extension, currentNodeType, pastedLink),
							true,
							insertPosition,
						);
					}
					return true;
				},
			};
		});
		return options;
	}

	return [];
};
