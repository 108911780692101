import { redirectToLoginPage } from '@confluence-classic/confluence-authentication';
import requestHelper from './request-helper';

// This is intentionally outside endpoints.js to prevent any transformers leaking here.
const EDITOR_PLUGIN_CODE_LANGUAGES = '/plugins/newcode/getlanguages.action';
const EDITOR_PLUGIN_WIKI_CONFLUENCE_CONVERTER = '/rest/tinymce/1/wikixhtmlconverter';
const EDITOR_PLUGIN_WIKI_MARKDOWN_CONVERTER = '/rest/tinymce/1/markdownxhtmlconverter';

/**
 * Gets the list of languages for New Code macro.
 *
 * @param callback
 */
export function getPluginCodeLanguages(callback) {
	const url = requestHelper.getFullPath(EDITOR_PLUGIN_CODE_LANGUAGES);
	requestHelper.get(url, {
		callback,
		httpCodeHandlers: { 401: () => redirectToLoginPage() },
		routeName: 'EDITOR_PLUGIN_CODE_LANGUAGES',
	});
}

/**
 * Convert markdown to XHTML
 * @param {String} body - request body.
 * @param callback
 */
export function getWikiConfluenceToXhtml(body, callback) {
	const url = requestHelper.getFullPath(EDITOR_PLUGIN_WIKI_CONFLUENCE_CONVERTER);
	requestHelper.postLegacyResource(url, {
		body,
		callback,
		httpCodeHandlers: { 401: () => redirectToLoginPage() },
		headers: {
			Accept: 'text/plain, */*',
			'Content-Type': 'application/json; charset=utf-8',
		},
		routeName: 'EDITOR_PLUGIN_WIKI_CONFLUENCE_CONVERTER',
	});
}

/**
 * Convert markdown to XHTML
 * @param {String} body - request body.
 * @param callback
 */
export function getWikiMarkdownToXhtml(body, callback) {
	const url = requestHelper.getFullPath(EDITOR_PLUGIN_WIKI_MARKDOWN_CONVERTER);
	requestHelper.postLegacyResource(url, {
		body,
		callback,
		httpCodeHandlers: { 401: () => redirectToLoginPage() },
		headers: {
			Accept: 'text/plain, */*',
			'Content-Type': 'application/json; charset=utf-8',
		},
		routeName: 'EDITOR_PLUGIN_WIKI_MARKDOWN_CONVERTER',
	});
}
