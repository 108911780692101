type ServiceName = string;
type CustomURL = string;

type RenderInputType = {
	host: string;
	cloudId: string;
	customURLs: Record<ServiceName, CustomURL> | undefined;
	userContext: string | undefined;
	userId: string | undefined;
	isLivePage: boolean | undefined;
	cookiesConsentToken: string | undefined;
	cookiesFallbackToken: string | undefined;
	perimeter: string;
	aggHeaders: { [header: string]: string };
	batchQueries: Record<string, string>;
};

let ssrRenderInputs = {} as RenderInputType;

export function setSSRRenderInputs(inputs: RenderInputType) {
	ssrRenderInputs = inputs;
}

export function getSSRRenderInputs(): RenderInputType {
	return ssrRenderInputs;
}
