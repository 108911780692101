import type { envType, OperationalEventPayload } from '@atlassiansox/analytics-web-client';

import { Choreographer } from '../Choreographer';
import { MessageAction, MessageDeliveryStatus, ProductIds } from '../constants';
import { DebugLogger } from '../DebugLogger';
import { Logger } from '../Logger';
import { type IMessage } from '../types';

import { ChoreographerPlugin } from './ChoreographerPlugin';

export const DUPLICATE_REQUEST_WINDOW = 1000 * 60 * 5; // 5m timeout for duplicate request attempts

export class EngagekitChoreographerPlugin extends ChoreographerPlugin {
	constructor(env: envType, metaMetricsData: OperationalEventPayload['attributes'] = {}) {
		super(ProductIds.ENGAGEKIT, env, metaMetricsData);
	}

	/**
	 * Informs the Logger utility class to *NOT* log the provided event if it contains a blockedByProductId
	 * attribute of the Engagekit product. EP is _super_ noisy when it comes to logging, and we get no value
	 * out of the events it logs for its own message starts that are blocked, by any messages. The reason is
	 * that EP tries to start all of its messages all of the time, and lets the gateway determine whether to
	 * actually display the message. Roughly 99.9% of messages fail to start for reasons of prior exposure,
	 * the user being in the holdout group, or any number of other reasons, so we're logging roughly 1000x what
	 * we get any value out of. This filter will now prevent all of those extra, more useless logs, from being
	 * actually fired off, and will keep just the log entries from which we can derive some actual value.
	 *
	 * @param event OperationalEventPayload
	 * @returns boolean
	 */
	public shouldLogEvent(event: OperationalEventPayload): boolean {
		return (
			event.actionSubject !== MessageAction.START || event.action !== MessageDeliveryStatus.BLOCKED
		);
	}

	private isValidDuplicateStartRequest(messageId: string) {
		const currentMessage =
			Choreographer.getInstance().getCurrentMessage() ??
			Choreographer.getInstance().getCurrentPendingMessage();

		return Boolean(
			currentMessage &&
				currentMessage.productId === this.state.productId &&
				currentMessage.messageId === messageId &&
				Date.now() - currentMessage.timestamp < DUPLICATE_REQUEST_WINDOW,
		);
	}

	public async startMessageWithPlugin(
		messageId: string,
		callback: Required<IMessage>['start'],
		additionalData: OperationalEventPayload['attributes'] = {},
	) {
		const debugLogger = new DebugLogger(this.state.productId, messageId);
		const actionSubject = MessageAction.START;

		debugLogger.trace({
			verbose: `EngagekitChoreographerPlugin::startMessageWithPlugin`,
		});

		/**
		 * The Choreographer sets a 5m TTL for any message that opens, before forcefully closing it.
		 * EP also allows a messageId to re-request its start if it hasn't yet been dismissed (this is a
		 * feature of HISI. For this reason, we can mimic that functionality here (otherwise, Choreographer
		 * will block the subsequest start request attempt altogether), by checking the Choreographer's
		 * currentMessage against our productId/messageId combo here. If this *is* the currentMessage being
		 * displayed, it means it hasn't been dismissed, and we let the request dispatch for EP to make the call.
		 */
		const isValidDuplicateStartRequest = this.isValidDuplicateStartRequest(messageId);

		if (isValidDuplicateStartRequest) {
			const logger = new Logger(
				actionSubject,
				messageId,
				{
					...this.state.metaMetricsData,
					...additionalData,
				},
				this.analyticsClient,
			);

			const didStart = Boolean(await callback());

			if (didStart) {
				logger.started();

				debugLogger.log({
					verbose: {
						phase: actionSubject,
						plugin: this.constructor.name,
						disposition: MessageDeliveryStatus.STARTED,
						timestamp: performance.now(),
					},
				});
			}
			return didStart;
		}

		// Do a just-in-time message start subscription with our callback to stargate
		this.onStart(messageId, callback);

		/**
		 * Dispatch the request to start the message, and return the response, which will
		 * either be 'started' if successful, and anything else if unsuccessful. Convert
		 * that value with a boolean check, because the consumer only cares about true/false.
		 */
		const result = await this.startMessage(messageId, additionalData);

		return result === MessageDeliveryStatus.STARTED;
	}

	public async stopMessageWithPlugin(
		messageId: string,
		callback: Required<IMessage>['stop'],
		additionalData: OperationalEventPayload['attributes'] = {},
	) {
		const debugLogger = new DebugLogger(this.state.productId, messageId);

		debugLogger.trace({
			verbose: `EngagekitChoreographerPlugin::stopMessageWithPlugin`,
		});

		// Do a just-in-time message stop subscription with our callback to stargate
		this.onStop(messageId, callback);

		/**
		 * Dispatch the request to stop the message, and return the response, which will
		 * either be 'stopped' if successful, and anything else if unsuccessful. Convert
		 * that value with a boolean check, because the consumer only cares about true/false.
		 */
		const result = await this.stopMessage(messageId, additionalData);

		return result === MessageDeliveryStatus.STOPPED;
	}
}
