import { redirectToLoginPage } from '@confluence-classic/confluence-authentication';
import { EDIT_PAGE_PROPERTIES } from './endpoints';
import requestHelper from './request-helper';

export function updateContentProperty(options, callback) {
	const params = {
		contentId: options.contentId,
		propertyKey: options.propertyKey,
	};

	const body = {
		value: options.propertyValue,
		version: {
			number: options.versionNumber,
			minorEdit: true,
		},
	};

	const url = requestHelper.getFullPath(EDIT_PAGE_PROPERTIES, params);

	requestHelper.put(url, {
		body,
		callback,
		httpCodeHandlers: { 401: () => redirectToLoginPage() },
		headers: {
			'Content-Type': 'application/json',
		},
		routeName: 'EDIT_PAGE_PROPERTIES',
	});
}
