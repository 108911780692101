/* eslint-disable check-set-storage-usage/no-unimported-storage-set */
import type {
	AtlBrowserStorageLocal,
	AtlBrowserStorageSession,
} from '@atlassian/browser-storage-controls';

import { fg } from '@confluence/feature-gating';
import { getLogger } from '@confluence/logger';
// This monitoring package is causing a circular dependency with using storage-manager in mobile-detection or any other packages using analytics-web-client. If changes are made, mobile-detection should also be checked to see if similar changes are needed
// TODO: Remove comment once circular dependency is resolved
import { getMonitoringClient } from '@confluence/monitoring';

import { UpdateLocalStorageMutation } from './UpdateLocalStorageMutation.graphql';
import type { UpdateLocalStorageMutationVariables } from './__types__/UpdateLocalStorageMutation';
import { PERSISTED_KEYS_ON_SERVER } from './LocalStorageKeys';
import { getStorageKey } from './storageHelpers';
import type { StorageManagerInitContext } from './StorageManagerInitContext';

const logger = getLogger('storage-manager');
const localStorageMigrationEntriesKey = 'localstorage-migration-entries';

export type AtlBrowserStorage = typeof AtlBrowserStorageLocal | typeof AtlBrowserStorageSession;

// exported so that it can be used in patchLocalStorage.ts
export function isQuotaExceededError(error: any) {
	if (error && 'code' in error) {
		switch (error.code) {
			case 22: {
				return true;
			}
			case 1014: {
				// Firefox
				if (error.name === 'NS_ERROR_DOM_QUOTA_REACHED') {
					return true;
				}
				break;
			}
			default: {
				return false;
			}
		}
	}

	return false;
}

// exported for test only
/**
 * @param value
 * @returns Either json representation of the value (if parsing succeeds), or the value (if parsing fails)
 */
export function jsonParseSafe(value: string | null | undefined): any {
	if (!value) {
		return value;
	}

	try {
		return JSON.parse(value);
	} catch (_) {
		return value;
	}
}

/**
 * Manager to store data in browser Storage
 * It ensures that the keys are namespaced with a given prefix to avoid clashing with anything else.
 *
 * @param storageManager an instance of storage with the appropriate driver configured via index.js
 * @param prefix the prefix to be added to the namespace. i.e. 'atlassian'
 * @param id of the storageManager to be returned. This is used to create a unique namespace for keys.
 * @param userId the userId returned via SessionData or ajs-atlassian-account-id from Meta
 * @param cache the data provider for local,indexDb and session storage that stores key values
 */
export class StorageManager {
	private _storageManager: Storage | AtlBrowserStorage | null;
	private _delimiter = '#';
	private _namespace: string;
	private _userId: string | null | undefined;

	private _isLicensedUser: boolean;
	private _hasQuotaErrorBeenSubmitted = false;

	constructor(
		storageManager: Storage | AtlBrowserStorage | null,
		prefix: string,
		id: string,
		storagePrefix: string,
		{ userId, isLicensed }: StorageManagerInitContext,
	) {
		this._storageManager = storageManager;
		this._namespace = userId
			? `${storagePrefix}/${prefix}.${userId}.${id}`
			: `${storagePrefix}/null`;
		this._userId = userId;
		this._isLicensedUser = isLicensed ?? false;
	}

	/**
	 * PRIVATE Does not trigger Init
	 * creates a prefix for a value
	 */
	private _getPrefix(seconds: number | undefined): string {
		const milliseconds = (seconds || 0) * 1000;
		if (!milliseconds) {
			return '';
		}
		return Date.now() + milliseconds + this._delimiter;
	}

	private _persistSetValue = (originalKey: string, value: any, persistKey: string) => {
		// there is a cyclic dependency with @confluence/graphql
		// communicate local/session storage update to cc-bandana
		if (
			Object.values(PERSISTED_KEYS_ON_SERVER).indexOf(originalKey) > -1 &&
			!sessionStorage.getItem('confluence.disable-persisted-storage-for-tests') &&
			window['GLOBAL_APOLLO_CLIENT'] &&
			this._userId &&
			this._isLicensedUser
		) {
			const mutationVariables: UpdateLocalStorageMutationVariables = {
				storageinput: {
					stringValues: [{ key: persistKey, value }],
				},
				stringKeys: [persistKey],
			};
			window['GLOBAL_APOLLO_CLIENT']
				.mutate({
					mutation: UpdateLocalStorageMutation,
					variables: mutationVariables,
				})
				.catch((e) => {
					this._submitError(
						e,
						`Error updating user preferences with key ${originalKey} and value ${value}: ${e}`,
					);
				});
		}
	};

	/**
	 * Gets the item stored in local storage for the given key. null is returned if it doesn't exist.
	 * Note that this method will always return a string representation of what is stored.
	 */
	getItem = (key: string): any | null => {
		if (!this._storageManager) {
			logger.warn`Local/session storage not supported on browser`;
			return null;
		}
		try {
			const cacheKey = getStorageKey(key, `${this._namespace}.${key}`);
			let item;

			if (fg('confluence_browser_storage_controls')) {
				item = this._storageManager.getItem(key);
			} else {
				item = this._storageManager.getItem(cacheKey);
				/**
				 * Currently some keys from independent usages of native local/session storage
					 were adding keys without namespace. This helps cleanup those keys and return the
					values for those keys if they exist
				* Once we stop seeing increments in counter in metrics for localStorageMigrationEntriesKey
						we can remove this logic*/
				if (!item) {
					item = this._storageManager.getItem(key);
					if (item) {
						this._incrementLocalStorageMigrationEntries();
						this.setItem(key, item);
						this._storageManager.removeItem(`${key}`);
					}
				}
			}
			if (item) {
				item = jsonParseSafe(item);
				let match: RegExpExecArray | null;
				const prefixMatch = /(\d+)#/;
				if (typeof item === 'string' && (match = prefixMatch.exec(item))) {
					const itemWithStrippedExpirationPrefix = item.replace(match[0], '');
					const expirationTimestamp = parseInt(match[1], 10);
					const isExpired = !isNaN(expirationTimestamp) && Date.now() > expirationTimestamp;
					if (isExpired) {
						this.removeItem(key);
						return null;
					}

					return itemWithStrippedExpirationPrefix;
				}
				return item;
			}

			return null;
		} catch (e) {
			this._submitError(e, `Error getting value from storage manager for key:${key} : ${e}`);
			return null;
		}
	};

	getAndRemoveItem = (key: string) => {
		if (!this._storageManager) {
			logger.warn`Local/session storage not supported on browser`;
			return null;
		}
		try {
			const item = this.getItem(key);
			if (item) {
				this.removeItem(key);
				return item;
			}
			return null;
		} catch (e) {
			this._submitError(
				e,
				`Error getting and removing value from storage manager for key:${key} : ${e}`,
			);
			return null;
		}
	};

	/**
	 * Get all the items in local storage that are bound to the given context.
	 */
	getAllItems = (): { [key: string]: any } => {
		const items: { [key: string]: any } = {};
		if (!this._storageManager) {
			logger.warn`Local/session storage not supported on browser`;
			return items;
		}
		const length = this._storageManager.length;

		// Currently getAllItems is unsupported if _storageManager is AtlBrowserStorage,
		// which retrieves length differently
		if (typeof length !== 'number') {
			return items;
		}
		try {
			for (let i = 0; i < length; i++) {
				const itemKey: string | null = this._storageManager.key(i);
				if (itemKey && itemKey.includes(`${this._namespace}.`)) {
					const keyWithoutNamespace = itemKey.split(`${this._namespace}.`)[1];
					const rawItemValue = this._storageManager.getItem(itemKey);
					items[keyWithoutNamespace] = jsonParseSafe(rawItemValue);
				}
			}

			return items;
		} catch (e) {
			this._submitError(e, `Error getting all items from storage manager : ${e}`);
			return items;
		}
	};

	/**
	 * Returns a boolean to let you know if we contain a key that matches, and has not expired.
	 */
	doesContain = (key: string): boolean => {
		if (!this._storageManager) {
			logger.warn`Local/session storage not supported on browser`;
			return false;
		}
		try {
			const value = this.getItem(key);
			return typeof value === 'boolean' ? !value : !!value;
		} catch (e) {
			this._submitError(e, `Error getting value from storage manager for key ${key}: ${e}`);
			return false;
		}
	};

	/**
	 * Gets the item stored in local storage for the given key and returns the boolean value of it.
	 * It correctly convert the "true" and "false" strings to return true/false booleans.
	 */
	getItemAsBoolean = (key: string): boolean => {
		if (!this._storageManager) {
			logger.warn`Local/session storage not supported on browser`;
			return false;
		}
		try {
			const value = this.getItem(key);
			if (value && typeof value === 'string' && value.indexOf('false') >= 0) {
				return false;
			}
			if (value && typeof value === 'string' && value.indexOf('true') >= 0) {
				return true;
			}
			return !!value;
		} catch (e) {
			this._submitError(
				e,
				`Error getting value as boolean from storage manager for key ${key}: ${e}`,
			);
			return false;
		}
	};

	/**
	 * synchronously sets a value
	 * @param expire expiration time in seconds
	 * @returns {string}
	 */
	setItem = (key: string, value: any, expire?: number) => {
		if (!this._storageManager) {
			logger.warn`Local/session storage not supported on browser`;
			return null;
		}
		try {
			const cacheKey = getStorageKey(key, `${this._namespace}.${key}`);

			// If it's not an object then append prefix.
			if (typeof value !== 'object') {
				value = `${this._getPrefix(expire)}${value}`;
			}
			value = JSON.stringify(value);

			// Use original key to call AtlBrowserStorage setItem, because only the original key is registered
			if (fg('confluence_browser_storage_controls')) {
				const processSetItem = this._storageManager.setItem(key, value);
				processSetItem?.then(() => {
					// Currently AtlBrowserStorage setItem does not return SUCCESS or BLOCKED,
					// so we check the successful status by calling getItem
					// only persist the value if the setItem was successful
					const isSuccessful = this._storageManager && this._storageManager.getItem(key);
					if (isSuccessful) {
						this._persistSetValue(key, value, cacheKey);
					}
				});
			} else {
				this._storageManager.setItem(cacheKey, value);
				this._persistSetValue(key, value, cacheKey);
			}

			return JSON.parse(value);
		} catch (e) {
			if (isQuotaExceededError(e)) {
				// for "quota exceeded" errors we're re-massaging the error message to exclude `this._namespace`
				// DOMException properties are read-only, so we can't just modify them inline, see https://developer.mozilla.org/en-US/docs/Web/API/DOMException
				if (!this._hasQuotaErrorBeenSubmitted) {
					// we'll only submit "quota exceeded" error to the monitoring systems once per user session -- there isn't really a need to submit all such errors.
					this._submitError(new Error(`Quota exceeded on key "${key}"`));
					this._hasQuotaErrorBeenSubmitted = true;
				}
				logger.error`Storage quota exceeded when for key ${key} and value ${value}; original error: ${e}`;
			} else {
				this._submitError(
					e,
					`Error setting value from storage manager with key ${key} and value ${value}: ${e}`,
				);
			}

			return null;
		}
	};

	/**
	 * Removes a Key from the Store
	 */
	removeItem = (key: string): void => {
		if (!this._storageManager) {
			logger.warn`Local/session storage not supported on browser`;
			return;
		}
		try {
			const cacheKey = getStorageKey(key, `${this._namespace}.${key}`);
			if (fg('confluence_browser_storage_controls')) {
				this._storageManager.removeItem(`${key}`);
			} else {
				this._storageManager.removeItem(`${cacheKey}`);
			}
			return;
		} catch (e) {
			this._submitError(e, `Error removing value from storage manager for key ${key}: ${e}`);
			return;
		}
	};

	initializeFromServer = (results: Array<{ key: string; value: string }>): void => {
		if (!Array.isArray(results)) {
			return;
		}
		if (sessionStorage.getItem('confluence.disable-persisted-storage-for-tests')) {
			return;
		}

		try {
			if (fg('confluence_browser_storage_controls')) {
				results.forEach((val) => {
					const originalKey = val?.key?.replace(`${this._namespace}.`, '');
					if (this._storageManager && originalKey) {
						this._storageManager.setItem(originalKey, val.value);
					}
				});
			} else {
				results.forEach(
					(val) => this._storageManager && this._storageManager.setItem(val.key, val.value),
				);
			}
		} catch (e) {
			if (isQuotaExceededError(e)) {
				this._submitError(new Error('Quota exceeded on intializing persisted values from server'));
			} else {
				this._submitError(
					e,
					`Error setting value from storage manager while initializing persisted values from server: ${e}`,
				);
			}
		}
	};

	private _submitError = (e: any, message?: string) => {
		if (message) {
			logger.error`${message}`;
		}
		getMonitoringClient().submitError(e, {
			attribution: 'unknown',
		});
	};

	private _incrementLocalStorageMigrationEntries = () => {
		getMonitoringClient().incrementCounter(localStorageMigrationEntriesKey);
	};
}
