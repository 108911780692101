import React from 'react';
import { type RenderFn } from '@atlassian/forge-ui-types';

export interface ListItemProps {
	children: React.ReactNode;
}

export const ListItem = (props: Parameters<RenderFn>[0]) => {
	const { forgeDoc, render } = props;
	return <li key={forgeDoc.key} children={forgeDoc.children.map(render)} />;
};
