export const convertPatternToRegex = (pattern: string): string => {
	// Escape special regex characters, excluding `*`
	const escapePattern = (str: string): string => {
		return str.replace(/[\-\[\]\/\(\)\+\?\.\\\^\$\|]/g, '\\$&');
	};
	// Consolidate any double wildcards (** -> *)
	pattern = pattern.replace(/\*\*/g, '*');
	// Escape special characters (but leave `*` as-is for now)
	pattern = escapePattern(pattern);
	// Replace wildcards (*) with regex equivalent
	pattern = pattern.replace(/\*/g, '[^/]*?'); // Replace escaped `*`
	// Add start (^) and end ($) anchors
	pattern = '^' + pattern + '$';
	return pattern;
};
