import { type MutationHookOptions, useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { useRef } from 'react';
import { isFedRamp } from '@atlassian/atl-context';
import {
	type ContextId,
	type ExtensionId,
	type ProductEnvironment,
	ForgeCDNEnvironment,
} from '@atlassian/forge-ui-types';
import {
	type GQLUserAuthTokenForExtensionInput,
	type GQLUserAuthTokenForExtensionResponse,
} from '../../../web-client/graphql/types';
import { useTracingContext } from '../../../error-reporting';
import { base64ToBlob, blobToBase64, getCDNEnvironment } from '../../../utils';
import { fg } from '@atlaskit/platform-feature-flags';

export const authTokenForExtensionMutation = gql`
	mutation forge_ui_userAuthTokenForExtension($input: UserAuthTokenForExtensionInput!) {
		userAuthTokenForExtension(input: $input) {
			success
			errors {
				message
			}
			authToken {
				token
				ttl
			}
		}
	}
`;

interface MutationVariables {
	input: GQLUserAuthTokenForExtensionInput;
}

interface MutationData {
	userAuthTokenForExtension: GQLUserAuthTokenForExtensionResponse;
}

interface AuthToken {
	token: string;
	expiry: number; // expiry in ms
}

const validProducts = ['confluence', 'jira', 'bitbucket'] as const;
export type Product = (typeof validProducts)[number];

export type OAuthFetchClient = (
	restPath: string,
	product: Product,
	init: Omit<RequestInit, 'body'> & { body?: string | null },
	isMultipartFormData: boolean,
) => Promise<Response>;

interface useProductFetchClientOptions {
	extensionId: ExtensionId;
	contextIds: ContextId[];
	environment: ProductEnvironment;
	cloudId?: string;
	workspaceId?: string;
	accountType?: 'atlassian' | 'customer' | 'anonymous';
	renderThreeLOPrompt: (retry: () => Promise<void>) => Promise<void>;
	options?: MutationHookOptions<MutationData, MutationVariables>;
}

const apiGatewayUrlMap: Record<ForgeCDNEnvironment, string> = {
	dev: 'api.stg.atlassian.com',
	stg: 'api.stg.atlassian.com',
	prod: 'api.atlassian.com',
	fex: 'api.atlassian-fex.com',
};

const getApiGatewayUrl = (environment: ForgeCDNEnvironment): string => {
	const apiBase = apiGatewayUrlMap[environment];
	const url =
		isFedRamp() && environment !== ForgeCDNEnvironment.FEDRAMP_SANDBOX
			? apiBase.replace('atlassian.com', 'atlassian-us-gov-mod.com')
			: apiBase;

	if (!url) {
		throw new Error(`Invalid environment: ${environment}`);
	}

	return url;
};

function getBaseUrl(environment: ForgeCDNEnvironment, product: Product, cloudId?: string): string {
	if (product === 'bitbucket') {
		return environment === 'prod' || environment === 'fex'
			? 'https://api.bitbucket.org'
			: 'https://api.integration.bb-inf.net';
	}
	const apiBase = getApiGatewayUrl(environment);
	return `https://${apiBase}/ex/${product}/${cloudId}`;
}

function createProductUrl(
	environment: ForgeCDNEnvironment,
	product: Product,
	restPath: string,
	cloudId?: string,
): string {
	const baseUrl = getBaseUrl(environment, product, cloudId);
	const fullUrl =
		// bitbucket requests could be made with absolute URLs
		// e.g. when following href links in API responses
		product === 'bitbucket' && restPath.startsWith(`${baseUrl}/`)
			? restPath
			: `${baseUrl}${restPath}`;
	const productUrl = new URL(fullUrl).toString();

	if (!productUrl.startsWith(`${baseUrl}/`)) {
		throw new Error('Invalid product URL');
	}

	return productUrl;
}

function isTokenValid(token: AuthToken | undefined): token is AuthToken {
	return !!(token && token.expiry > Date.now());
}

function isProduct(product: unknown): product is Product {
	return validProducts.includes(product as Product);
}

export function isBinaryContentType(contentType: string | undefined | null): boolean {
	return Boolean(
		contentType && !contentType.includes('application/json') && contentType.search(/text\//) === -1,
	);
}

function parseFormData(formLike: Record<string, string>): FormData {
	const form = new FormData();

	for (const [key, value] of Object.entries(formLike)) {
		if (key === 'file') {
			const fileName = formLike['__fileName'];
			const mimeType = formLike['__fileType'];

			// convert back from base64 to File (Blob)
			const blob = base64ToBlob(value, mimeType);
			const file = new File([blob], fileName, { type: mimeType });

			form.append('file', file);
		} else {
			form.append(key, value);
		}
	}

	// remove request metadata properties
	form.delete('__fileName');
	form.delete('__fileType');

	return form;
}

const MILLIS_IN_SECOND = 1000;

export const requestProduct = async (url: string, init?: RequestInit): Promise<Response> => {
	const response = await fetch(url, init);

	const isAttachment = isBinaryContentType(response.headers.get('content-type'));
	if (response.ok && response.body !== null && response.status !== 204 && isAttachment) {
		const b64Content = await blobToBase64(await response.blob());

		return new Response(b64Content, {
			status: response.status,
			statusText: response.statusText,
			headers: response.headers,
		});
	}

	return response;
};

export function useProductFetchClient({
	extensionId,
	accountType,
	environment,
	cloudId,
	workspaceId,
	renderThreeLOPrompt,
	options,
	contextIds = [],
}: useProductFetchClientOptions): OAuthFetchClient {
	const [mutationFunction] = useMutation<MutationData, MutationVariables>(
		authTokenForExtensionMutation,
		options,
	);
	const authTokenRef = useRef<AuthToken | undefined>(undefined);

	const tracing = useTracingContext();

	/**
	 * Workspace based products like Trello and Bitbucket will not pass in cloudId,
	 * they will pass in workspaceId instead.
	 */
	if (!cloudId && !workspaceId) {
		return async () => {
			throw new Error(
				'Product APIs are not available in this module. Remove requestConfluence, requestJira or requestBitbucket from your code.',
			);
		};
	}

	const fetchOAuthToken = async (
		extensionId: ExtensionId,
		contextIds: ContextId[],
	): Promise<AuthToken> => {
		tracing?.recordGqlCall('userAuthTokenForExtension - start');

		const { data } = await mutationFunction({
			variables: {
				input: {
					extensionId,
					contextIds,
				},
			},
		});

		if (data?.userAuthTokenForExtension) {
			const { success, authToken, errors } = data.userAuthTokenForExtension;

			tracing?.recordGqlCall('userAuthTokenForExtension - end');

			if (
				!success &&
				errors &&
				errors[0].message.includes('No Atlassian OAuth token found for this user and extension')
			) {
				return new Promise((resolve) => {
					renderThreeLOPrompt(async () => {
						const token = await fetchOAuthToken(extensionId, contextIds);
						resolve(token);
					});
				});
			}

			if (success && authToken) {
				const { token, ttl } = authToken;

				return { token, expiry: Date.now() + ttl * MILLIS_IN_SECOND };
			}
		}

		throw new Error('An unexpected error occurred when fetching an auth token');
	};

	const cdnEnvironment = getCDNEnvironment(environment);

	return async (restPath, product, init, isMultipartFormData) => {
		if (!isProduct(product)) {
			throw new Error(`Invalid product: ${product}`);
		}

		const atlassianAccount = !accountType || accountType === 'atlassian';
		if (!isTokenValid(authTokenRef.current) && atlassianAccount) {
			authTokenRef.current = await fetchOAuthToken(extensionId, contextIds);
		}

		const headers = new Headers(init.headers);
		headers.set('Authorization', `Bearer ${authTokenRef?.current?.token}`);

		// eslint-disable-next-line @atlaskit/platform/ensure-feature-flag-prefix
		if (fg('forge-ui-add-x-ecosystem-user-agent-header')) {
			// this header is used to identify requests made by Forge, based on:
			// https://hello.atlassian.net/wiki/x/O5jUIwE?atlOrigin=eyJpIjoiM2NhNjU1MDUxOWJkNDcxNThiNzJjNWU1NzE5Y2FmZTMiLCJwIjoiYyJ9
			headers.set('x-ecosystem-user-agent', 'forge');
		}

		const body = isMultipartFormData ? parseFormData(JSON.parse(init.body as string)) : init.body;

		if (isMultipartFormData) {
			// let the fetch api correctly set the multipart/form-data content-type
			// https://github.com/github/fetch/issues/505#issuecomment-293064470
			headers.delete('content-type');
		}

		return requestProduct(createProductUrl(cdnEnvironment, product, restPath, cloudId), {
			...init,
			body,
			headers,
		});
	};
}
