import React, { useMemo } from 'react';
import type { RenderFn } from '@atlassian/forge-ui-types';
import type { EditorProps } from '../../types';
import { JSONTransformer } from '@atlaskit/editor-json-transformer';
import { usePresetByFeatures } from '../utils/usePresetByFeatures';

const PlatformChromelessEditor = React.lazy(() =>
	import(
		/* webpackChunkName: '@atlaskit-internal_chromeless-editor' */
		'@atlaskit/editor-core/appearance-editor-chromeless'
	).then((module) => ({ default: module.ChromelessEditor })),
);

export type ChromelessEditorProps = EditorProps;

/**
 * The chromeless editor provides the Editor without any of the standard UI features. It's ideal for cases where the integrator wants complete control and responsibility over the editor UI.
 */
export const ChromelessEditor = (props: Parameters<RenderFn>[0]) => {
	const { onChange, defaultValue, features, isDisabled } = props.forgeDoc
		.props as ChromelessEditorProps;

	const transformer = useMemo(() => new JSONTransformer(), []);

	const { preset } = usePresetByFeatures('chromeless', features);

	return (
		<PlatformChromelessEditor
			disabled={isDisabled}
			defaultValue={defaultValue}
			preset={preset}
			onChange={(editorView) => {
				const jsonDocNode = transformer.encode(editorView.state.doc);
				onChange?.(jsonDocNode);
			}}
		/>
	);
};
