/* eslint-disable no-console */
import { fg } from '@atlaskit/platform-feature-flags';

import {
	type OperationalEventAction,
	type OperationalEventAttributes,
	sendPackageOperationalEvent,
} from '../analytics-client';
import { getBscGlobalState } from '../bsc-global-state';

const LOG_PREFIX = '[browser-storage-controls]';

// Feel free to add more types here, future dev
type LoggingMetadata = string | { [k: string]: string };

interface OperationalErrorLogConfig {
	action: OperationalEventAction;
	attributes?: OperationalEventAttributes;
	message: string;
	metadata?: LoggingMetadata;
}

export class Logger {
	static info(message: string, metadata?: LoggingMetadata) {
		console.log(`${LOG_PREFIX}: ${message}`, metadata ? JSON.stringify(metadata) : '');
	}

	static error(message: string, metadata?: LoggingMetadata) {
		console.error(`${LOG_PREFIX}: ${message}`, metadata ? JSON.stringify(metadata) : '');
	}

	static errorWithOperationalEvent({
		action,
		attributes = {},
		message,
		metadata,
	}: OperationalErrorLogConfig) {
		try {
			sendPackageOperationalEvent({
				action,
				attributes: { error: message, ...attributes },
			});
		} catch (e: any) {
			this.error(`Failed to send operational event on error: ${action}. ${e.message || ''}`);
		}

		this.error(message, metadata ? JSON.stringify(metadata) : '');
	}

	static warn(message: string, metadata?: LoggingMetadata) {
		console.warn(`${LOG_PREFIX}: ${message}`, metadata ? JSON.stringify(metadata) : '');
	}

	/**
	 * Logger for additional debugging of the package. Debug logs are only sent when the
	 * atl-bsc-debug-mode cookie is set on the browser. This is evaluated via global state
	 */
	static debug(message: string, metadata?: LoggingMetadata) {
		if (getBscGlobalState().packageDebugMode && fg('platform_moonjelly_bsc_debug_mode')) {
			console.debug(`${LOG_PREFIX}: DEBUG ${message}`, metadata ? JSON.stringify(metadata) : '');
		}
	}
}
