import gql from 'graphql-tag';

export const EditorContentStateQuery = gql`
	query EditorContentStateQuery($contentId: ID!, $isDraft: Boolean!) {
		singleContent(id: $contentId) {
			id
			contentState(isDraft: $isDraft) {
				id
				name
				color
				restrictionLevel
				isCallerPermitted
			}
			# currently only view page and live pages require this field to be loaded for the content header
			contentStateLastUpdated(format: USER_FRIENDLY) @skip(if: $isDraft) {
				value
			}
		}
		availableContentStates(contentId: $contentId) {
			customContentStates {
				name
				color
				id
			}
			spaceContentStates {
				name
				color
				id
				restrictionLevel
			}
		}
	}
`;
