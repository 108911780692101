// The below lint rules are disabled because Box relies on building styles at runtime from in Forge apps
/* eslint-disable @atlaskit/design-system/consistent-css-prop-usage -- Ignored via go/DSP-18766 */
/* eslint-disable @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766 */
/* eslint-disable @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766 */

import { xcss as platformXCSS } from '@atlaskit/primitives';
import { type XCSSProp, xcssValidator } from '../../../utils/xcssValidator';

type PlatformBoxProps = React.ComponentProps<typeof PlatformBox>;

/**
 * A box is a generic container that provides managed access to design tokens.
 */
export const componentPropsConfig = {
	as: false,
	padding: true,
	paddingBlock: true,
	paddingBlockStart: true,
	paddingBlockEnd: true,
	paddingInline: true,
	paddingInlineStart: true,
	paddingInlineEnd: true,
	backgroundColor: true,
	children: true,
	ref: true,
	testId: true,
	'data-testid': false,
	xcss: {
		value: 'xcss && platformXCSS(xcssValidator(xcss) as PlatformXCSSParam)',
	},
	role: true,
	style: false,
};

export type BoxProps = Pick<PlatformBoxProps, 'children' | 'ref' | 'testId'> & {
	/**
	 * A shorthand for `paddingBlock` and `paddingInline` together.
	 *
	 * @type [Space tokens](https://atlassian.design/components/tokens/all-tokens#space)
	 */
	padding?: PlatformBoxProps['padding'];

	/**
	 * The logical block start and end padding of an element.
	 *
	 * @type [Space tokens](https://atlassian.design/components/tokens/all-tokens#space)
	 */
	paddingBlock?: PlatformBoxProps['paddingBlock'];

	/**
	 * The logical block start padding of an element.
	 *
	 * @type [Space tokens](https://atlassian.design/components/tokens/all-tokens#space)
	 */
	paddingBlockStart?: PlatformBoxProps['paddingBlockStart'];

	/**
	 * The logical block end padding of an element.
	 *
	 * @type [Space tokens](https://atlassian.design/components/tokens/all-tokens#space)
	 */
	paddingBlockEnd?: PlatformBoxProps['paddingBlockEnd'];

	/**
	 * The logical inline start and end padding of an element.
	 *
	 * @type [Space tokens](https://atlassian.design/components/tokens/all-tokens#space)
	 */
	paddingInline?: PlatformBoxProps['paddingInline'];

	/**
	 * The logical inline end padding of an element.
	 *
	 * @type [Space tokens](https://atlassian.design/components/tokens/all-tokens#space)
	 */
	paddingInlineEnd?: PlatformBoxProps['paddingInlineEnd'];

	/**
	 * The logical inline start padding of an element.
	 *
	 * @type [Space tokens](https://atlassian.design/components/tokens/all-tokens#space)
	 */
	paddingInlineStart?: PlatformBoxProps['paddingInlineStart'];

	/**
	 * A token alias for background color. See: [Design tokens](https://atlassian.design/components/tokens/all-tokens)
	 * for a list of available colors.
	 *
	 * When the background color is set to a surface token, the current surface CSS variable
	 * will also be set to this value in the `Box` styles.
	 *
	 * @type [Background color tokens](https://atlassian.design/components/tokens/all-tokens#color-background)
	 */
	backgroundColor?: PlatformBoxProps['backgroundColor'];

	/**
	 * Apply a subset of permitted styles, powered by Atlassian Design System tokens.
	 * For a list of supported style properties on this component, see [here](/platform/forge/ui-kit/components/xcss).
	 *
	 * @type XCSSProp
	 */
	xcss?: XCSSProp;

	/**
	 * @type string
	 */
	role?: PlatformBoxProps['role'];

	/**
	 * @type ForgeComponent
	 */
	children?: PlatformBoxProps['children'];
};

type PlatformXCSSParam = Parameters<typeof platformXCSS>[0];

/**
 * THIS SECTION WAS CREATED VIA CODEGEN DO NOT MODIFY {@see http://go/af-codegen}
 *
 * This component was generated against an existing platform component.
 * If you are seeing lint errors in this file, it means that this file's dependencies have been modified and the component will need to be regenerated.
 *
 * Please view https://hello.atlassian.net/wiki/spaces/ECO/pages/3358130330/How+to+Regenerate+UI+Kit+Components for more information on how to proceed.
 *
 * For further questions, please ping the #help-forge-ui channel.
 *
 * @codegen <<SignedSource::4c1cdc547fd9bc14d10ce39b9d443dd7>>
 * @codegenId #box
 * @codegenCommand yarn workspace @atlassian/forge-ui codegen box
 * @codegenParams { "defineOwnType": true }
 * @codegenDependency ../../../../../../../design-system/primitives/src/components/types.tsx <<SignedSource::9529629bd4dfcf934c9dfc41516de683>>
 * @codegenDependency ../../../../../../../design-system/primitives/src/components/box.tsx <<SignedSource::d494e9639c15f8d2e963619e4f6d4bf9>>
 * @codegenDependency ../../../../../../../design-system/primitives/src/utils/surface-provider.tsx <<SignedSource::b2f47f7dfba2a18d1e5f39f5b93f4959>>
 * @codegenDependency ../../../../../../../design-system/primitives/src/utils/types.tsx <<SignedSource::6aae50141ed52478c88909359e21c67b>>
 * @codegenDependency ../../../../../../../design-system/primitives/src/xcss/style-maps.partial.tsx <<SignedSource::6b100f0da8b9d5aaa5db3cc730c8a2a5>>
 * @codegenDependency ../../../../../../../design-system/primitives/src/xcss/xcss.tsx <<SignedSource::4f9c0f527ba99a4723d89b2126ae70b1>>
 */
/* eslint @repo/internal/codegen/signed-source-integrity: "warn" */
import React from 'react';
import type { RenderFn } from '@atlassian/forge-ui-types';
import { Box as PlatformBox } from '@atlaskit/primitives';

// Define the type for Box as BoxProps at the top of this file

/**
 * A box is a generic container that provides managed access to design tokens.
 */
export const Box = (props: Parameters<RenderFn>[0]) => {
  const {
    padding,
    paddingBlock,
    paddingBlockStart,
    paddingBlockEnd,
    paddingInline,
    paddingInlineStart,
    paddingInlineEnd,
    backgroundColor,
    ref,
    testId,
    xcss,
    role,
  } = props.forgeDoc.props as BoxProps;
  return (
    <PlatformBox
      padding={padding}
      paddingBlock={paddingBlock}
      paddingBlockStart={paddingBlockStart}
      paddingBlockEnd={paddingBlockEnd}
      paddingInline={paddingInline}
      paddingInlineStart={paddingInlineStart}
      paddingInlineEnd={paddingInlineEnd}
      backgroundColor={backgroundColor}
      children={props.forgeDoc.children.map(c => <React.Fragment key={c.key}>{props.render(c)}</React.Fragment>)}
      ref={ref}
      testId={testId}
      xcss={xcss && platformXCSS(xcssValidator(xcss) as PlatformXCSSParam)}
      role={role}
    />
  );
};
/**
 * @codegenEnd
 */
