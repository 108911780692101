import { useEffect, useMemo } from 'react';

import { choreographerFactory, ProductIds } from '@atlassian/ipm-choreographer';

import { getMeta } from './utils/getMeta';
import { determineAnalyticsEnvironment, useAnalyticsEnvironment } from './useAnalyticsEnvironment';

let choreographerServices: ReturnType<typeof choreographerFactory>;
const ajsEnvironment = getMeta('ajs-environment');

if (typeof globalThis.window !== undefined && ajsEnvironment) {
	const environment = determineAnalyticsEnvironment(ajsEnvironment);
	choreographerServices = choreographerFactory(ProductIds.CONFLUENCE, environment);
}

export const useChoreographerServices = () => {
	const analyticsEnv = useAnalyticsEnvironment();
	const choreographerServiceInstance = useMemo(() => {
		if (!choreographerServices && analyticsEnv) {
			choreographerServices = choreographerFactory(ProductIds.CONFLUENCE, analyticsEnv);
		}
		// Return module level choreographer services
		return choreographerServices;
	}, [analyticsEnv]);
	const { plugin } = choreographerServiceInstance ?? {};

	useEffect(() => {
		/**
		 * Cleanup function in case we unmount this component to remount another one.
		 */
		return () => {
			if (plugin) {
				plugin.destroy();
			}
		};
	}, [plugin]);

	return choreographerServiceInstance;
};
