import {
	type PlacementComponent,
	type PlacementConfigurationOptions,
	createPlacementComponent as createPlacementComponentActual,
} from '@post-office/placement-contexts';

export const createPlacementComponent = <P,>(
	placementId: string,
	Component: PlacementComponent<P>,
	placementConfigurationOptions?: PlacementConfigurationOptions,
) => createPlacementComponentActual(placementId, Component, placementConfigurationOptions);
