// Note -- when updating this function -- also update
// the one used in packages/editor/editor-plugin-ai/examples/utils/getDevFetchCustomHeaders.ts
export function _getFetchCustomHeaders(): HeadersInit {
	const currentHost = window.location.origin;

	switch (true) {
		case currentHost === 'https://statlas.prod.atl-paas.net':
			// Update this to add custom headers when run in atlaskit branch deploys
			// ie.
			// {
			//   'X-CloudID': 'Magic string'
			// }
			return {};
		default:
			return {};
	}
}

export function deriveHost() {
	const {
		origin = '',
		port = '',
		hostname = '',
	} = typeof window.location !== 'undefined' ? window.location : {};

	if (
		(hostname === 'localhost' && port !== '8081') ||
		[
			'atlaskit-website-staging.stg-east.frontend.public.atl-paas.net',
			'statlas.prod.atl-paas.net',
			'atlaskit.atlassian.com',
		].some((h) => hostname === h)
	) {
		return 'https://pug.jira-dev.com';
	}

	return origin;
}

export function deriveGenerativeAIApiUrl() {
	return `${deriveHost()}/gateway/api/editor-ai/v1/generative/ai`;
}

export const convoAIStatelessApiURL = `${deriveHost()}/gateway/api/assist/chat/v1/invoke_agent/stream`;
// remove this when cleanup platform_editor_ai_update_api_endpoint_for_rovo
export const convoAIStatefulApiURL = `${deriveHost()}/gateway/api/assist/chat/v1/channel`; // + `/${channelID}/message/stream` at the end;
export const convoAIRovoApiURL = `${deriveHost()}/gateway/api/assist/rovo/v1/editor/conversation`; // + `/${channelID}/message/stream` at the end;
export const convoAIAuditLogApiURL = `${deriveHost()}/gateway/api/ai/v2/ai-feature/audit-logs`;

export function deriveProactiveAIApiUrl() {
	return `${deriveHost()}/gateway/api/assist/ai/v2/ai-feature/proactive`;
}
