import React, { memo, useContext, useEffect } from 'react';

import { type FrameProps, type RenderFn } from '@atlassian/forge-ui-types';
import { FrameCount } from '../../../provider/FrameCountProvider';
import { forgePropsAreEqual } from '../../utils/forgePropsAreEqual';

const Frame = ({
	forgeDoc,
	IframeRenderer,
}: Parameters<RenderFn>[0] & {
	IframeRenderer: (inputProps: FrameProps) => JSX.Element;
}) => {
	const { setFrameCount } = useContext(FrameCount);

	useEffect(() => {
		setFrameCount((prevCount) => prevCount + 1);
		return () => setFrameCount((prevCount) => prevCount - 1);
	}, [setFrameCount]);

	const { resource, frameId } = forgeDoc.props as FrameProps;
	return <IframeRenderer resource={resource} frameId={frameId} />;
};

const FrameMemo = memo(Frame, forgePropsAreEqual);

export const makeFrame = (IframeRenderer: (inputProps: FrameProps) => JSX.Element) => {
	return (props: Parameters<RenderFn>[0]) => (
		<FrameMemo {...props} IframeRenderer={IframeRenderer} />
	);
};
