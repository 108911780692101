import React from 'react';
import type { RouteComponentProps } from 'react-router';

import type { Route, RouteMatch } from '@confluence/route';
import type { ParsedUrlQuery } from '@confluence/route-manager-utils';

export type RoutesContextType = {
	getHref(): string;
	history: RouteComponentProps['history'] | null;
	location: RouteComponentProps['location'] | null;
	match: (Route & RouteMatch) | null;
	transitionId: number | null;
	/**
	 * @deprecated use either `Route.toUrl()` instead (if you have access
	 * to a `Route` object). When the route isn't known in advance, an
	 * alternative via `useRouteDataRef()` is available
	 */
	toUrl(
		name: string | undefined,
		params?: {
			query?: { [key: string]: any };
			hash?: string;
			[key: string]: any;
		},
	): string;
	/**
	 * @deprecated use `useMatchRoute` hook from `RouteState` instead
	 */
	matchRoute(url: string): (Route & RouteMatch) | null;
	matchSupportedRoute(url: string): (Route & RouteMatch) | null;
	getQueryParams(): ParsedUrlQuery;
	/**
	 * Updates the query params using `history.push` by default.
	 * Set the second parameter to true to replace current url instead.
	 */
	setQueryParams<T extends {} = {}>(query: T, replace?: boolean): void;
	getHash(): string;
	setHash(hash: string): void;
	replace(url: string, forceReload?: boolean): void;
	push(url: string, forceReload?: boolean): void;
	routes: Route[];
	/** @deprecated use `window.open` instead */
	open: typeof window.open;
	onPageReload(url: string, isForced: boolean): void;
	preloadRoute(matchOrUrl: (Route & RouteMatch) | string): void;
};

const nullNoop = () => null;
const objNoop = () => ({});
const stringNoop = () => '';
const voidNoop = () => {};

/**
 * @deprecated Please use hooks for RouteState instead, if possible.
 */
export const RoutesContext = React.createContext<RoutesContextType>({
	getHref: stringNoop,
	history: null,
	location: null,
	match: null,
	transitionId: null,
	toUrl: stringNoop,
	matchRoute: nullNoop,
	matchSupportedRoute: nullNoop,
	getQueryParams: objNoop,
	setQueryParams: voidNoop,
	getHash: stringNoop,
	setHash: voidNoop,
	replace: voidNoop,
	routes: [],
	push: voidNoop,
	open: nullNoop,
	onPageReload: voidNoop,
	preloadRoute: voidNoop,
});

RoutesContext.displayName = 'RoutesContext';
