import { parse } from '@atlassian/cs-ari';
import { getExtensionType } from '@atlassian/forge-ui';
import { ForgeUIExtensionType } from '@atlassian/forge-ui-types';
import { convertPatternToRegex } from './utils/convertPatternToRegex';
import { isPatternValid } from './utils/isPatternValid';
import { AutoConvertDefinition, AutoConvertMacroAttributesMap } from './types';
import { EXTENSION_NAMESPACE } from '../../utils/constants';

export const createAutoConvertDefinitions = (
	extensions: readonly ForgeUIExtensionType[] | null,
): {
	autoConvertDefinitions: AutoConvertDefinition[] | undefined;
	macroADFNodeMap: AutoConvertMacroAttributesMap | undefined;
} => {
	if (!extensions) {
		return { autoConvertDefinitions: undefined, macroADFNodeMap: undefined };
	}

	const autoConvertDefinitions: AutoConvertDefinition[] = [];

	// a map of macro key to ADF node
	const macroADFNodeMap: AutoConvertMacroAttributesMap = {};

	extensions.forEach((extension) => {
		if (!parse(extension.id).resourceId || !extension.properties.autoConvert) {
			return;
		}

		const extensionIdWithoutPrefix = parse(extension.id).resourceId!;

		// only macro with autoconvertData can be converted
		extension.properties.autoConvert.matchers.forEach(({ pattern }) => {
			// Invalid patterns will silently fail. We are relying on the CLI linter to warn developers for invalid pattern
			if (isPatternValid(pattern)) {
				const type = getExtensionType(extension);
				macroADFNodeMap[extensionIdWithoutPrefix] = {
					type: type,
					attrs: {
						extensionKey: extensionIdWithoutPrefix,
						extensionType: EXTENSION_NAMESPACE,
						parameters: {
							extensionId: extension.id,
							extensionTitle: extension.properties.title,
							layout: extension.properties.layout || 'block',
							forgeEnvironment: extension.environmentType,
							render: extension.properties.render || 'default',
						},
						layout: 'default',
						text: extension.properties.title,
					},
				};
				const autoConvertDefinition: AutoConvertDefinition = {
					macroName: extension.properties.title || '',
					matcher: convertPatternToRegex(pattern),
					extensionKey: extensionIdWithoutPrefix,
				};
				autoConvertDefinitions.push(autoConvertDefinition);
			}
		});
	});
	return { autoConvertDefinitions, macroADFNodeMap };
};
