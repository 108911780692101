import type { OnOpen, OptionsV1, CompletionStatus } from '../types';

/**
 * Version 1 of public Cross Flow API
 */
export interface API {
	open(options: OptionsV1): Promise<CompletionStatus>;
}

export const VERSION = 1;

export const createAPIv1 = (onOpen: OnOpen): API => ({
	open(options) {
		return onOpen(options);
	},
});
