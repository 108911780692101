import { Logger } from '../../../../common/utils/logger';
import { BSCIndexedDB } from '../../../../services/indexed-db-service';
import { findStorageTarget, WebStorageType } from '../../../../services/web-storage-service';
import { setItemOverride } from '../set-item-override';

export const createWebStoragePassthrough = async (storageType: WebStorageType) => {
	const bscStorage = findStorageTarget(storageType);

	if (!bscStorage.isAvailable) {
		Logger.warn(`No ${storageType} capabilities found`);
		return;
	}

	if (!BSCIndexedDB.isAvailable) {
		Logger.warn('IndexedDB is not available');
		return;
	}

	if (!BSCIndexedDB.isBooted) {
		// If we've yet to invoke booting of the IDB service, ensure we do it here.
		await BSCIndexedDB.boot();
	}

	const originalSetItem = bscStorage.storage.setItem;

	// As we cannot use Object.defineProperty(window[storageType], 'setItem', ...implementation)
	// we have to override the entire utility with our own edition of the webStorage class
	const overriddenStorageClass = {
		clear: bscStorage.clear.bind(bscStorage),
		key: bscStorage.key.bind(bscStorage),
		length: bscStorage.length.bind(bscStorage),
		removeItem: bscStorage.removeItem.bind(bscStorage),
		getItem: bscStorage.getItem.bind(bscStorage),
		setItem: setItemOverride(bscStorage, originalSetItem, storageType),
	};

	const caseSensitiveStorageType =
		storageType === WebStorageType.LocalStorage ? 'localStorage' : 'sessionStorage';

	Object.defineProperty(window, caseSensitiveStorageType, {
		value: overriddenStorageClass,
		writable: true,
		configurable: true,
	});
};
