// Disable no-re-export rule for entry point files
/* eslint-disable @atlaskit/editor/no-re-export */

import {
	aiMessages,
	aiConfigItemMessages,
	aiLanguageMessages,
} from '@atlaskit/editor-common/ai-messages';
import { type EditorView } from '@atlaskit/editor-prosemirror/view';
import { fg } from '@atlaskit/platform-feature-flags';

import { createOpenAIModalCommand } from '../../pm-plugins/decoration/actions';
import type {
	EditorPluginAIProvider,
	EditorPluginAIPublicOptions,
	SelectionToolbarDropdownConfig,
} from '../../types/types';

import type {
	EditorPluginAIConfigItemWithOptions,
	EditorPluginAIConfigItem,
} from './config-items/config-items';
import { brainstorm, brainstormWithOptions } from './config-items/prompts/brainstorm/brainstorm';
import {
	changeToneToCasual,
	changeToneToCasualWithOptions,
	changeToneToEducational,
	changeToneToEducationalWithOptions,
	changeToneToEmpathetic,
	changeToneToEmpatheticWithOptions,
	changeToneToNeutral,
	changeToneToNeutralWithOptions,
	changeToneToProfessional,
	changeToneToProfessionalWithOptions,
} from './config-items/prompts/change-tone/change-tone';
import { convertToBulletListWithOptions } from './config-items/prompts/convert-to-bullet-list/convert-to-bullet-list';
import { convertToTableWithOptions } from './config-items/prompts/convert-to-table/convert-to-table';
import {
	fixSpellingAndGrammarWithOptions,
	improveWritingWithOptions,
	improveWriting,
	fixSpellingAndGrammar,
} from './config-items/prompts/enhance/enhance';
import { getExperimentalPromptPlaceholderConfigItemWithOptions } from './config-items/prompts/experimental-prompt-placeholder/experimental-prompt-placeholder';
import { findActionItemsWithOptions } from './config-items/prompts/find-action-items/find-action-items';
import { freeGenerate } from './config-items/prompts/free-generate/free-generate';
import { makeLongerWithOptions } from './config-items/prompts/make-longer/make-longer';
import {
	makeShorter,
	makeShorterWithOptions,
} from './config-items/prompts/make-shorter/make-shorter';
import { rephraseWithOptions } from './config-items/prompts/rephrase/rephrase';
import { suggestTitleWithOptions } from './config-items/prompts/suggest-title/suggest-title';
import {
	summarizeWriting,
	summarizeWritingWithOptions,
} from './config-items/prompts/summarize-writing/summarize-writing';
import {
	translationConfigItemWithOptions,
	translationConfigItemsMap,
} from './config-items/prompts/translate/translate';
import { getSortedSupportedTranslationLanguages } from './config-items/utils/translation-utils';
import { _getFetchCustomHeaders, deriveGenerativeAIApiUrl } from './utils';

export const UNSTABLE_configItems = {
	brainstormConfigItem: brainstorm,
};

const dynamicSurfaceConfigItems: EditorPluginAIConfigItemWithOptions[] = [
	improveWritingWithOptions,
	fixSpellingAndGrammarWithOptions,
	changeToneToProfessionalWithOptions,
	changeToneToEmpatheticWithOptions,
	changeToneToCasualWithOptions,
	changeToneToNeutralWithOptions,
	changeToneToEducationalWithOptions,
	makeShorterWithOptions,
	makeLongerWithOptions,
	rephraseWithOptions,
	convertToBulletListWithOptions,
	convertToTableWithOptions,
	findActionItemsWithOptions,
	suggestTitleWithOptions,
	brainstormWithOptions,
	...translationConfigItemWithOptions,
	summarizeWritingWithOptions,
];

/**
 * IMPORTANT: This API is likely to change in future versions when we look
 * to provide more configurability to consumers.
 * In future you may need to refactor to use a new API.
 *
 * ```ts
 * import { UNSTABLE_configItems, UNSTABLE_DO_NOT_USE_ONLY_FOR_CONFLUENCE_triggerConfigItem } from '@atlaskit/editor-plugin-ai/prebuilt/confluence';
 * // ...
 * UNSTABLE_DO_NOT_USE_ONLY_FOR_CONFLUENCE_triggerConfigItem({
 *   editorView,
 *   configItem: UNSTABLE_configItems.brainstormConfigItem,
 * })
 * ```
 */
export const UNSTABLE_DO_NOT_USE_ONLY_FOR_CONFLUENCE_triggerConfigItem = (props: {
	editorView: EditorView;
	configItem: EditorPluginAIConfigItem;
}) => {
	const { editorView, configItem } = props;
	const openAIModalCommand = createOpenAIModalCommand({
		state: editorView.state,
		configItem,
		lastTriggeredFrom: 'triggerConfigItem',
		aiGlobalOptIn: {
			status: 'enabled',
			triggerOptInFlow: () => {},
		},
	});
	openAIModalCommand(editorView.state, editorView.dispatch);
};

// Page comment editor
export function createPageCommentEditorPluginAIOptions(
	options: EditorPluginAIPublicOptions,
): EditorPluginAIProvider {
	/**
	 * Not abstracting these defaults up, in order to be explicit about possible
	 * different behaviours between page comment and page editors.
	 */
	// Now deprecated - migrate to handleFeedbackSubmission
	const onExperienceEvent = options.onExperienceEvent || {
		THUMBS_UP: () => {},
		THUMBS_DOWN: () => {},
		REVIEW_STATE_ENTERED: () => {},
		EXPERIENCE_COMPLETE: () => {},
	};

	function getConfigItems(): EditorPluginAIConfigItemWithOptions[] {
		const list: EditorPluginAIConfigItemWithOptions[] = [
			changeToneToProfessionalWithOptions,
			summarizeWritingWithOptions,
			improveWritingWithOptions,
			fixSpellingAndGrammarWithOptions,
			makeShorterWithOptions,
			makeLongerWithOptions,
			rephraseWithOptions,
			convertToBulletListWithOptions,
			convertToTableWithOptions,
			suggestTitleWithOptions,
			brainstormWithOptions,
			changeToneToEmpatheticWithOptions,
			changeToneToCasualWithOptions,
			changeToneToNeutralWithOptions,
			changeToneToEducationalWithOptions,
			...translationConfigItemWithOptions,
		];

		const experimentalPromptPlaceholderData =
			getExperimentalPromptPlaceholderConfigItemWithOptions();
		if (experimentalPromptPlaceholderData) {
			const { listOrder, configItemWithOptions } = experimentalPromptPlaceholderData;
			list.splice(listOrder, 0, configItemWithOptions);
		}

		return list;
	}

	return {
		aiGlobalOptIn: options.aiGlobalOptIn,
		baseGenerate: freeGenerate,
		configItemWithOptions: getConfigItems(),
		disableAISelectionToolbar: true,
		generativeAIApiUrl: options.generativeAIApiUrl || deriveGenerativeAIApiUrl(),
		product: options.product ? options.product : 'CONFLUENCE',
		objectId: options.objectId,
		getFetchCustomHeaders: options.getFetchCustomHeaders || _getFetchCustomHeaders,
		onExperienceEvent,
		handleFeedbackSubmission: options.handleFeedbackSubmission,
		AIButtonWrapper: options.AIButtonWrapper,
		PromptEditor: options.PromptEditor,
		providers: options.providers,
		auditLogSettings: options.auditLogSettings,
	};
}

// Page editor
export const createPageEditorPluginAIOptions = (
	options: EditorPluginAIPublicOptions,
): EditorPluginAIProvider => {
	/**
	 * Not abstracting these defaults up, in order to be explicit about possible
	 * different behaviours between page comment and page editors.
	 */
	// Now deprecated - migrate to handleFeedbackSubmission
	const onExperienceEvent = options.onExperienceEvent || {
		THUMBS_UP: () => {},
		THUMBS_DOWN: () => {},
		REVIEW_STATE_ENTERED: () => {},
		EXPERIENCE_COMPLETE: () => {},
	};

	function getConfigItems(): EditorPluginAIConfigItemWithOptions[] {
		const list: EditorPluginAIConfigItemWithOptions[] = [
			changeToneToProfessionalWithOptions,
			summarizeWritingWithOptions,
			improveWritingWithOptions,
			fixSpellingAndGrammarWithOptions,
			makeShorterWithOptions,
			makeLongerWithOptions,
			rephraseWithOptions,
			convertToBulletListWithOptions,
			convertToTableWithOptions,
			findActionItemsWithOptions,
			suggestTitleWithOptions,
			brainstormWithOptions,
			changeToneToEmpatheticWithOptions,
			changeToneToCasualWithOptions,
			changeToneToNeutralWithOptions,
			changeToneToEducationalWithOptions,
			...translationConfigItemWithOptions,
		];

		const experimentalPromptPlaceholderData =
			getExperimentalPromptPlaceholderConfigItemWithOptions();
		if (experimentalPromptPlaceholderData) {
			const { listOrder, configItemWithOptions } = experimentalPromptPlaceholderData;
			list.splice(listOrder, 0, configItemWithOptions);
		}

		return list;
	}

	return {
		aiGlobalOptIn: options.aiGlobalOptIn,
		baseGenerate: freeGenerate,
		configItemWithOptions: getConfigItems(),
		getSelectionToolbarDropdowns() {
			const selectionToolbarOptions: SelectionToolbarDropdownConfig[] = [
				{
					id: 'change-tone',
					label: aiMessages.confluenceChangeToneSelectionToolbarDropdownMenuTitle,
					options: [
						{
							label:
								aiConfigItemMessages.changeToneProfessionalToneSelectionToolbarDropdownItemTitle,
							configItem: changeToneToProfessional,
						},
						{
							label: aiConfigItemMessages.changeToneEmpatheticToneSelectionToolbarDropdownItemTitle,
							configItem: changeToneToEmpathetic,
						},
						{
							label: aiConfigItemMessages.changeToneCasualToneSelectionToolbarDropdownItemTitle,
							configItem: changeToneToCasual,
						},
						{
							label: aiConfigItemMessages.changeToneNeutralToneSelectionToolbarDropdownItemTitle,
							configItem: changeToneToNeutral,
						},
						{
							label:
								aiConfigItemMessages.changeToneEducationalToneSelectionToolbarDropdownItemTitle,
							configItem: changeToneToEducational,
						},
					],
				},
				{
					id: 'rewrite',
					label: aiMessages.confluenceRewriteSelectionToolbarDropdownMenuTitle,
					options: [
						{
							label: aiConfigItemMessages.improveWritingSelectionToolbarDropdownItemTitle,
							configItem: improveWriting,
						},
						{
							label: aiConfigItemMessages.fixSpellingGrammarSelectionToolbarDropdownItemTitle,
							configItem: fixSpellingAndGrammar,
						},
						{
							label: aiConfigItemMessages.summarizeWritingSelectionToolbarDropdownItemTitle,
							configItem: summarizeWriting,
						},
						{
							label: aiConfigItemMessages.makeShorterSelectionToolbarDropdownItemTitle,
							configItem: makeShorter,
						},
					],
				},
			];

			const translateOptions = getSortedSupportedTranslationLanguages().map((language) => {
				const label = aiLanguageMessages[language];
				return {
					label,
					configItem: translationConfigItemsMap[language],
				};
			});

			selectionToolbarOptions.push({
				id: 'translate',
				label: aiMessages.confluenceTranslateSelectionToolbarDropdownMenuTitle,
				options: translateOptions,
			});
			return selectionToolbarOptions;
		},
		disableAISelectionToolbar: false,
		generativeAIApiUrl: options.generativeAIApiUrl || deriveGenerativeAIApiUrl(),
		hidePrimaryToolbarButton: options.hidePrimaryToolbarButton,
		product: options.product ? options.product : 'CONFLUENCE',
		objectId: options.objectId,
		getFetchCustomHeaders: options.getFetchCustomHeaders || _getFetchCustomHeaders,
		getChannelVariables: options.getChannelVariables,
		onDocChangeByAgent: options.onDocChangeByAgent,
		onAIProviderChanged: options.onAIProviderChanged,
		onExperienceEvent,
		handleFeedbackSubmission: options.handleFeedbackSubmission,
		AIButtonWrapper: options.AIButtonWrapper,
		PromptEditor: options.PromptEditor,
		disableInterrogation: options.disableInterrogation ?? false,
		aiUsageDisclaimer: options.aiUsageDisclaimer,
		isRovoEnabled: options.isRovoEnabled ?? true,
		providers: options.providers,
		auditLogSettings: options.auditLogSettings,
		aiSmartButtonOptions: options.aiSmartButtonOptions,
		...(fg('ai-smart-button-team-25') && {
			dynamicSurfaceConfigItems,
		}),
	};
};
