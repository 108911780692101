import type { FC, ReactNode } from 'react';
import React, { useMemo } from 'react';

import { EXTERNAL_SHARE } from '@confluence/named-routes';

import { useWithUserTypes } from './useWithUserTypes';
import { ExternalShareContext } from './ExternalShareContext';

type ExternalShareContextProviderProps = {
	path: string;
	children?: ReactNode;
};

export const ExternalShareContextProvider: FC<ExternalShareContextProviderProps> = ({
	children,
	path,
}) => {
	const isExternalShareRequest = !!EXTERNAL_SHARE.match(path);
	const { userType } = useWithUserTypes(isExternalShareRequest);

	const externalShareContextValue = useMemo(
		() => ({
			isExternalShareRequest,
			userType,
		}),
		[isExternalShareRequest, userType],
	);

	return (
		<ExternalShareContext.Provider value={externalShareContextValue}>
			{children}
		</ExternalShareContext.Provider>
	);
};
