import { VIEW_PAGE_EXPERIENCE, VIEW_PAGE_SESSION_EXPERIENCE } from './ExperienceName';
import { hasHealthyRunningExperience } from './hasHealthyRunningExperience';
import type { ExperienceTracker } from './ExperienceTracker';

// Returns true is view-page-session experience is running and all relevant edit-page subexperiences are either not started or have already succeeded
export const hasHealthyRunningViewPageExperience = () =>
	hasHealthyRunningExperience({
		parentExperience: VIEW_PAGE_SESSION_EXPERIENCE,
		subExperiences: [VIEW_PAGE_EXPERIENCE],
	});

export const startViewPageSessionExperience = ({
	id,
	tracker,
	attributes,
	startTime,
}: {
	id: string;
	tracker: ExperienceTracker;
	attributes: {};
	startTime?: number;
}) => {
	tracker.start({
		name: VIEW_PAGE_SESSION_EXPERIENCE,
		id,
		startTime,
		attributes,
		collect: (events, experience) => {
			const viewExperienceLoadingFailed = events.find(
				({ action, name }) => action === 'taskFail' && name === VIEW_PAGE_EXPERIENCE,
			);
			const viewExperienceLoadingAborted = events.find(
				({ action, name }) => action === 'taskAbort' && name === VIEW_PAGE_EXPERIENCE,
			);
			if (viewExperienceLoadingFailed) {
				experience.fail({
					error: new Error('View page load experience failed'),
				});
			} else if (viewExperienceLoadingAborted) {
				experience.abort({
					reason: 'View page load experience aborted',
				});
			}
		},
	});
};
