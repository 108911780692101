import { type ComponentPropsConfig } from '../../../utils/codegen';

export const componentPropsConfig: ComponentPropsConfig = {
	children: true,
	as: false,
	justifyContent: true,
	alignItems: true,
	columnGap: true,
	gap: true,
	rowGap: true,
	direction: true,
	wrap: true,
	testId: true,
	'data-testid': false,
	role: true,
	xcss: false,
};

/**
 * THIS SECTION WAS CREATED VIA CODEGEN DO NOT MODIFY {@see http://go/af-codegen}
 *
 * This component was generated against an existing platform component.
 * If you are seeing lint errors in this file, it means that this file's dependencies have been modified and the component will need to be regenerated.
 *
 * Please view https://hello.atlassian.net/wiki/spaces/ECO/pages/3358130330/How+to+Regenerate+UI+Kit+Components for more information on how to proceed.
 *
 * For further questions, please ping the #help-forge-ui channel.
 *
 * @codegen <<SignedSource::a0cbee0ba3cb58844997a62ca7e53590>>
 * @codegenId #flex
 * @codegenCommand yarn workspace @atlassian/forge-ui codegen flex
 * @codegenDependency ../../../../../../../design-system/primitives/src/components/flex.tsx <<SignedSource::bd7b084a24732a0bb7d29b4cc3211e29>>
 * @codegenDependency ../../../../../../../design-system/primitives/src/components/types.tsx <<SignedSource::9529629bd4dfcf934c9dfc41516de683>>
 * @codegenDependency ../../../../../../../design-system/primitives/src/xcss/style-maps.partial.tsx <<SignedSource::764fa2a11181a03d6d87ec2b92e13239>>
 * @codegenDependency ../../../../../../../design-system/primitives/src/xcss/xcss.tsx <<SignedSource::4f9c0f527ba99a4723d89b2126ae70b1>>
 */
/* eslint @repo/internal/codegen/signed-source-integrity: "warn" */
import React from 'react';
import type { RenderFn } from '@atlassian/forge-ui-types';
import { Flex as PlatformFlex } from '@atlaskit/primitives';

type PlatformFlexProps = React.ComponentProps<typeof PlatformFlex>;

export type FlexProps = Pick<
  PlatformFlexProps,
  'children' | 'justifyContent' | 'alignItems' | 'columnGap' | 'gap' | 'rowGap' | 'direction' | 'wrap' | 'testId' | 'role'
>;

/**
 * Primitives are token-backed low-level building blocks.
 */
export const Flex = (props: Parameters<RenderFn>[0]) => {
  const {
    justifyContent,
    alignItems,
    columnGap,
    gap,
    rowGap,
    direction,
    wrap,
    testId,
    role,
  } = props.forgeDoc.props as FlexProps;
  return (
    <PlatformFlex
      children={props.forgeDoc.children.map(c => <React.Fragment key={c.key}>{props.render(c)}</React.Fragment>)}
      justifyContent={justifyContent}
      alignItems={alignItems}
      columnGap={columnGap}
      gap={gap}
      rowGap={rowGap}
      direction={direction}
      wrap={wrap}
      testId={testId}
      role={role}
    />
  );
};
/**
 * @codegenEnd
 */
