import gql from 'graphql-tag';

export const CollabDraftQuery = gql`
	query CollabDraftQuery($id: ID!, $draftShareId: String = "", $hydrateAdf: Boolean) {
		collabDraft(id: $id, draftShareId: $draftShareId, hydrateAdf: $hydrateAdf) {
			document
			version
			metadata {
				title
			}
		}
	}
`;
