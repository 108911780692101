import { LoadablePaint } from '@confluence/loadable';

export const LiveEditABTestSiteSettingsPageLoader = LoadablePaint({
	loader: async () =>
		(
			await import(
				/* webpackChunkName: "loadable-LiveEditABTestSiteSettingsPage" */ '../src/site-settings/LiveEditABTestSiteSettingsPage'
			)
		).LiveEditABTestSiteSettingsPage,
});
