import { fg } from '@confluence/feature-gating';

import { AccessStatus, useSessionData } from '@confluence/session-data';

export const shouldSkipQueryBasedOnFGs = () => {
	if (fg('confluence_nav4_ga_phase_2')) {
		//if phase 2 is enabled ignore all opt out
		return true;
	}
	// eslint-disable-next-line confluence-feature-gating/no-preconditioning
	if (fg('confluence_nav_4') && fg('confluence_nav_4_opt')) {
		//fire the query if nav4 & opt are enabled
		return false;
	}
	if (fg('confluence_nav4_ga_phase_1')) {
		//if nav4 and opt are disabled, but phase 1 is enabled, check the opt status
		return false; //should still skip if admin opt in is enabled
	}
	return true;
};

const hasAccess = (accessStatus: AccessStatus | null) =>
	![AccessStatus.ANONYMOUS_ACCESS, AccessStatus.UNLICENSED_AUTHENTICATED_ACCESS, null].includes(
		accessStatus,
	);

export const useShouldSkipOptOutQuery = () => {
	const { accessStatus } = useSessionData();

	// Should skip for anonymous and unlicensed users as userPreferences will return error in those cases
	return { shouldSkipOptOutQuery: !hasAccess(accessStatus) || shouldSkipQueryBasedOnFGs() };
};
