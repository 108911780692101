import React from 'react';

import { PageAnalyticsPageLoader } from '@confluence/confluence-analytics/entry-points/AnalyticsPagesLoaders';
import { CONTAINER_HOME_ID } from '@confluence/app-navigation/entry-points/navigationIDs';
import type { RouteMatch } from '@confluence/route';

export const PageAnalyticsRoute = ({ params: { contentId } }: RouteMatch) => {
	return <PageAnalyticsPageLoader contentId={contentId} />;
};

PageAnalyticsRoute.NAVIGATION_PARAMS = ({ params: { spaceKey, contentId, tab } }: RouteMatch) => ({
	Screen: {
		// screenEvent will be handled in the page components
		pageState: {
			spaceKey,
			contentId,
			tab,
		},
	},
	MainLayout: {
		spaceKey,
		navView: CONTAINER_HOME_ID,
	},
});
