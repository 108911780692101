import type { FC, ReactNode } from 'react';
import React, { createContext, useContext, useReducer, useMemo, useCallback } from 'react';

import type { ExperimentalTCDecoratedSubCalendar, Event } from '../__types__/types';

export enum CALENDAR_CONTEXT {
	SPACE_CALENDARS = 'spaceCalendars',
	MY_CALENDARS = 'myCalendars',
	SPACE_SUBCALENDAR = 'spaceSubCalendar',
	EMBEDDED_SUBCALENDAR = 'embeddedSubCalendar',
}

export type ValidationError = {
	error: string;
	field: string;
	value: string;
	errorCode: string;
	errorMessage?: string;
	subCalendarName?: string;
	oAuthUrl?: string;
};

export type CalendarError = {
	id: string;
	error: string | ValidationError;
};

type State = {
	calendars: ExperimentalTCDecoratedSubCalendar[];
	events: Event[];
	calendarContext: CALENDAR_CONTEXT;
	userTimeZoneId: string;
	userTimeFormat: string;
	viewDate: Date;
	dateTimeFormatOptions: {
		hour: 'numeric' | '2-digit';
		minute: 'numeric' | '2-digit';
		hourCycle: 'h12' | 'h23';
		timeZone: string;
	};
	calendarErrors: CalendarError[];
	isLoadingEvents: boolean;
	firstDay: number;
};

type Action = {
	type: string;
	[key: string]: any;
};

type Actions = {
	setEvents: (events: Event[]) => void;
	setCalendarEvents: (calendarIds: string[], events: Event[]) => void;
	setCalendars: (calendars: ExperimentalTCDecoratedSubCalendar[]) => void;
	setViewDate: (viewDate: Date) => void;
	setCalendarErrors: (calendarErrors: CalendarError[]) => void;
	setIsLoadingEvents: (isLoadingEvents: boolean) => void;
	setFirstDay: (firstDay: number) => void;
};

type TCContextType = {
	state: State;
	actions: Actions;
};

const TCContext = createContext<TCContextType>({} as TCContextType);

export const useTCContextDispatch = () => {
	const context = useContext(TCContext);

	if (context === undefined) {
		throw new Error('useTCContextDispatch must be used within TCContextProvider');
	}

	return context;
};

type TCContextProviderProps = {
	calendars: ExperimentalTCDecoratedSubCalendar[];
	calendarContext: CALENDAR_CONTEXT;
	userTimeZoneId: string;
	children: ReactNode;
	userTimeFormat: string;
	isLoadingEvents?: boolean;
	firstDay?: number;
};

let initialState: State;
const getInitialState = ({
	calendars,
	calendarContext,
	userTimeZoneId,
	userTimeFormat,
	isLoadingEvents,
	firstDay,
}): State => {
	return (
		initialState || {
			calendars,
			events: [],
			calendarContext,
			userTimeZoneId,
			userTimeFormat,
			viewDate: new Date(),
			dateTimeFormatOptions: {
				hour: 'numeric',
				minute: 'numeric',
				hourCycle: userTimeFormat === 'displayTimeFormat12' ? 'h12' : 'h23',
				timeZone: userTimeZoneId,
			},
			calendarErrors: [],
			isLoadingEvents,
			firstDay,
		}
	);
};

const AT = {
	SET_EVENTS: 'SET_EVENTS',
	SET_CALENDAR_EVENTS: 'SET_CALENDAR_EVENTS',
	SET_CALENDARS: 'SET_CALENDARS',
	SET_VIEW_DATE: 'SET_VIEW_DATE',
	SET_CALENDAR_ERRORS: 'SET_CALENDAR_ERRORS',
	SET_IS_LOADING_EVENTS: 'SET_IS_LOADING_EVENTS',
	SET_FIRST_DAY: 'SET_FIRST_DAY',
};

export const TCContextProvider: FC<TCContextProviderProps> = ({
	calendars,
	calendarContext,
	userTimeZoneId,
	children,
	userTimeFormat,
	isLoadingEvents = false,
	firstDay = 1,
}) => {
	const initialState: State = getInitialState({
		calendars,
		calendarContext,
		userTimeZoneId,
		userTimeFormat,
		isLoadingEvents,
		firstDay,
	});

	const reducer = useCallback(
		(state: State, action: Action) => {
			switch (action.type) {
				case AT.SET_EVENTS: {
					return {
						...state,
						events: action.events,
					};
				}
				case AT.SET_CALENDAR_EVENTS: {
					const calendarIds = action.calendarIds;
					const newEvents = action.events;
					const oldEvents = state.events.filter(
						(event) => !calendarIds.includes(event.subCalendarId),
					);

					const updatedEvents = oldEvents.concat(newEvents);

					return {
						...state,
						events: updatedEvents,
					};
				}
				case AT.SET_CALENDARS: {
					return {
						...state,
						calendars: action.calendars,
					};
				}
				case AT.SET_VIEW_DATE: {
					return {
						...state,
						viewDate: action.viewDate,
					};
				}
				case AT.SET_CALENDAR_ERRORS: {
					if (state.calendarErrors.length === 0 && action.calendarErrors.length === 0) {
						return state;
					}
					return {
						...state,
						calendarErrors: action.calendarErrors,
					};
				}
				case AT.SET_IS_LOADING_EVENTS: {
					return {
						...state,
						isLoadingEvents: action.isLoadingEvents,
					};
				}
				case AT.SET_FIRST_DAY: {
					return {
						...state,
						firstDay: action.firstDay,
					};
				}
				default:
					return initialState;
			}
		},
		[initialState],
	);

	const [state, dispatch] = useReducer(reducer, initialState);

	const actions = useMemo(
		() => ({
			setEvents: (events: Event[]) => {
				dispatch({ type: AT.SET_EVENTS, events });
			},
			setCalendarEvents: (calendarIds: string[], events: Event[]) => {
				dispatch({ type: AT.SET_CALENDAR_EVENTS, calendarIds, events });
			},
			setCalendars: (calendars: ExperimentalTCDecoratedSubCalendar[]) => {
				dispatch({ type: AT.SET_CALENDARS, calendars });
			},
			setViewDate: (viewDate: Date) => {
				dispatch({ type: AT.SET_VIEW_DATE, viewDate });
			},
			setCalendarErrors: (calendarErrors: CalendarError[]) => {
				dispatch({ type: AT.SET_CALENDAR_ERRORS, calendarErrors });
			},
			setIsLoadingEvents: (isLoadingEvents: boolean) => {
				dispatch({ type: AT.SET_IS_LOADING_EVENTS, isLoadingEvents });
			},
			setFirstDay: (firstDay: number) => {
				dispatch({ type: AT.SET_FIRST_DAY, firstDay });
			},
		}),
		[],
	);

	const tcContextValue = useMemo(() => ({ state, actions }), [state, actions]);

	return <TCContext.Provider value={tcContextValue}>{children}</TCContext.Provider>;
};
