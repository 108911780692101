/**
 *  Data Services - Analytics Web Client for E-MAU
 *  url to the JIRA ticket (https://product-fabric.atlassian.net/browse/CFEA-16)
 *  source code (https://bitbucket.org/atlassian/analytics-web-client)
 */

import OriginTracer from '@atlassiansox/origin-tracing/origin-tracer';

import { isNativeMobile, setUserAgent } from '@confluence/browser-helper';
import { getBrowserHistory } from '@confluence/browser-history';
import { getBuildInfo } from '@confluence/build-info';
import { liftPromiseState } from '@confluence/lifted-promise';
import { shouldShowMobileWeb } from '@confluence/mobile-detection';
import type { EventMatcher } from '@confluence/filtered-awc';

import { storeOrigin } from './storeOrigin';

// Must call this before any browser-helper functions, to ensure user agent has been set first
setUserAgent();

let provideClient: (analyticsWebClient: any) => void;
const analyticsWebClientPromise = liftPromiseState(
	new Promise<any>((resolve) => {
		provideClient = resolve;
	}),
);

let provideDwellTimeHelper: (dwellTimeHelper: any) => void;
const dwellTimeHelperPromise = liftPromiseState(
	new Promise<any>((resolve) => {
		provideDwellTimeHelper = resolve;
	}),
);

export type Config = {
	envType: any;
	originType: any;
	platformType: any;
	environment: string;
	product?: string;
	subproduct?: string;
	locale?: string;
};

export const createConfig = ({
	envType,
	originType,
	environment,
	platformType,
	locale,
	product,
	subproduct,
}: Config) => {
	const env =
		// In dev, proxying to a site will give that site's environment,
		// which is not what we want
		window.location.hostname === 'localhost'
			? envType.DEV
			: environment === 'STAGING'
				? envType.STAGING
				: environment === 'PRODUCTION'
					? envType.PROD
					: envType.DEV;

	return {
		env,
		product: product || 'confluence',
		...(subproduct ? { subproduct } : undefined),
		version: getBuildInfo().FRONTEND_VERSION,
		origin: originType.WEB,
		platform: shouldShowMobileWeb() ? platformType.MOBILE_WEB : platformType.WEB,
		locale,
	};
};

export const getWorkspaceId = (cloudId: string, activationId: string) =>
	`ari:cloud:confluence:${cloudId}:workspace/${activationId}`;

/***
 *
 * Should be called once only to configure the analytics web client. When the client
 * is configured, the promise will resolve with the configured instance of the client.
 */
export const initializeAnalyticsClient = ({
	cloudId,
	userId,
	environment,
	product,
	subproduct,
	locale,
	activationId,
	eventFilterOptions = {
		shouldFilterEvents: false,
		eventMatchers: [],
	},
	callback,
}: {
	cloudId: string;
	userId: string | null;
	environment: string;
	product: string;
	subproduct?: string;
	activationId?: string;
	locale?: string;
	eventFilterOptions?: {
		shouldFilterEvents: Boolean;
		eventMatchers: EventMatcher[];
	};
	callback?: () => void;
}) => {
	let config: ReturnType<typeof createConfig>;

	Promise.all<any[]>([
		import(
			/* webpackChunkName: "loadable-atlassiansoxanalytics-web-client" */ '@atlassiansox/analytics-web-client'
		),
		import(
			/* webpackChunkName: "loadable-atlassianexposure-events-compressor" */ '@atlassian/exposure-events-compressor'
		),

		// This is needed for @atlassiansox/origin-tracing to work in old versions of Edge (<=16).
		// Origin Tracing needs URLSearchParams, but the API is missing from old Edge,
		// and old Edge is not served the ES5/polyfills bundle.
		typeof URLSearchParams === 'undefined'
			? import(
					/* webpackChunkName: "loadable-url-search-params-polyfill" */ 'url-search-params-polyfill'
				)
			: Promise.resolve(),
	])
		.then(async ([analyticsWebClientModule, { buildCompressionFunction, canCompress }]) => {
			const {
				default: AnalyticsWebClient,
				originType,
				tenantType,
				userType,
				envType,
				platformType,
				DwellTimeHelper,
				CompressionRule,
			} = analyticsWebClientModule;

			config = createConfig({
				envType,
				originType,
				environment,
				platformType,
				locale,
				product,
				subproduct,
			});

			let analyticsWebClient: any;

			// COMMENTS-195 - Send an operational event when a session starts
			const handleSessionStarted = () => {
				analyticsWebClient &&
					analyticsWebClient.sendOperationalEvent({
						action: 'started',
						actionSubject: 'session',
						source: 'webClient',
					});
			};

			provideDwellTimeHelper(DwellTimeHelper);
			const browserHistory = getBrowserHistory();

			const settings = {
				historyReplaceFn: (url: string) => {
					const { pathname, search, hash } = new URL(url);

					// Warning: can't pass an instance of URL here as it will erase query params and hash
					// Caused: https://product-fabric.atlassian.net/browse/WS-2317
					browserHistory.replace({ pathname, search, hash });
				},
				delayQueueCompressors: [new CompressionRule(canCompress, buildCompressionFunction())],
				onNewSessionStarted: handleSessionStarted,
				apiHostProtocol: window.location.hostname === 'localhost' ? 'http' : 'https',
				disableCookiePersistence: true,
			};

			const { shouldFilterEvents, eventMatchers } = eventFilterOptions;

			// We suppress analytics events if we're within the mobile app so that we don't
			// end up firing overlapping events from the native and web code
			if (shouldFilterEvents || isNativeMobile()) {
				/**
				 * Don't use Stargate if we're filtering events.
				 * According to the analytics-web-client package source code at https://bitbucket.org/atlassian/analytics-web-client/src/bf3bd861ee8d08759e0e79830fcb1d69156db1a5/src/analyticsWebClient.js?at=master#lines-208
				 * By default, useStargate is true. On a production instance, even if we set the env to "staging" or "dev", this event will land to the huge production Redash Socrates table, such as cloud.event_operational.
				 * For the purpose of preproduction performance detection, we need the analytics event to send to staging table, such as raw_dlf_stg.event_operational.
				 * The staging table has much less data, so we can run data transformation job hourly, and query more real-time data. Production table is huge, so we can only run data transformation job daily.
				 */
				const useStargate = !shouldFilterEvents;

				const { createFilteredAWC } = await import(
					/* webpackChunkName: "loadable-confluencefiltered-awc" */ '@confluence/filtered-awc'
				);
				analyticsWebClient = createFilteredAWC(
					analyticsWebClientModule,
					config,
					{
						...settings,
						useStargate,
					},
					eventMatchers,
				);
			} else {
				analyticsWebClient = new AnalyticsWebClient(config, settings);
			}

			analyticsWebClient.setTenantInfo(tenantType.CLOUD_ID, cloudId);

			if (activationId) {
				const workspaceId = getWorkspaceId(cloudId, activationId!);
				analyticsWebClient.setWorkspaceInfo(workspaceId);
			}

			// userId is null when the user is anonymous. In that scenario we don't
			// want to set any account ID on the analytics client.
			if (userId) {
				analyticsWebClient.setUserInfo(userType.ATLASSIAN_ACCOUNT, userId);
			}

			analyticsWebClient.setOriginTracingHandlers({
				atlOrigin: (encodedOrigin: string) => {
					const origin = OriginTracer.fromEncoded(encodedOrigin);
					const attributes = origin.toAnalyticsAttributes();
					if (userId) {
						storeOrigin(attributes);
					}
					return {
						originTracingAttributes: {
							id: (attributes as any).originId,
							product: (attributes as any).originProduct,
							library: attributes.originLibrary,
						},
						taskSessionId: origin.id,
					};
				},
			});
			analyticsWebClient.startUIViewedEvent();
			provideClient(analyticsWebClient);
			callback && callback();
		})
		.catch((error) => {
			throw new Error(`Analytics Web Client ${error}, ${JSON.stringify({ ...config })}`);
		});
};

/***
 *
 * Returns a promise that will resolve with an instance of the analytics web client (configured with the correct
 * environment, tenant and user information).
 *
 * The promise will only resolve after initializeAnalyticsClient has been called.
 *
 * @returns Promise
 */
export const getAnalyticsWebClient = () => analyticsWebClientPromise;
export const getDwellTimeHelper = () => dwellTimeHelperPromise;
