import gql from 'graphql-tag';

export const SiteInformationQuery = gql`
	query SiteInformationQuery @SLA {
		tenant {
			shard
			cloudId
			environment
			activationId
			editions {
				edition
			}
		}
		organization {
			orgId
		}
		userForAnalytics: user {
			id
		}
		user(current: true) {
			id
			displayName
			confluence {
				accessStatus
				accountId
				userKey
				locale
			}
			timeZone
		}
		getAIConfig(product: CONFLUENCE) {
			isEnabled
			isRovoEnabled
			isRovoLLMEnabled
		}
		tenantContext {
			licenseStates {
				confluence {
					ccpEntitlementId
				}
			}
		}
		isSiteAdmin
		abTestCohorts
		experimentFeatures
		userCanCreateContent
		isNewUser
		siteSettings {
			homepage {
				uri
				title
			}
			siteTitle
			showApplicationTitle
			frontCover {
				frontCoverState
			}
			companyHubName
		}
		siteDescription {
			logoUrl
		}
		currentConfluenceUser {
			isAnonymous
		}
	}
`;
