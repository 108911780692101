import React, { useCallback, Fragment } from 'react';
import PropTypes from 'prop-types';
import window from 'window-or-global';
import { ReactReduxContext } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { nativeEditorComponentsPreloader } from '@confluence-classic/confluence-fabric-editor/src/lib';

import { addUFOCustomData } from '@atlaskit/react-ufo/custom-data';

import { isEmbeddedConfluence_DO_NOT_USE } from '@atlassian/embedded-confluence/isEmbeddedConfluence';
import { SSRRenderFailureNotification } from '@confluence/ssr-render-failure-notification';

import { ConnectSupport } from '@confluence/connect-support';
import { RouteManager } from '@confluence/route-manager/entry-points/RouteManager';
import { useNavigationPolicy } from '@confluence/route-manager/entry-points/useNavigationPolicy';
import { isViewPageLite } from '@confluence/view-page/entry-points/isViewPageLite';
import { namedRoutes, isEditPage } from '@confluence/named-routes';
import { Attribution, ErrorBoundary } from '@confluence/error-boundary';
import {
	startBrowserMetricsPageLoad,
	markCustomTransitionExperienceForRoute,
	BMEventBus,
	EMBEDDED_EDIT_FMP_ACTION,
	EMBEDDED_EDIT_TTI_ACTION,
	TimeToAppIdle,
} from '@confluence/browser-metrics';
import { setUniquePageLoadId } from '@confluence/unique-page-load-id';
import { CopySpacePermissionsForLegacyNewSpace } from '@confluence/copy-space-permissions';
import { Flags } from '@confluence/flags';
import { CreateDialogContextProvider } from '@confluence/create-dialog-context';
import { Dialogs } from '@confluence/dialogs';
import { NetworkStatusListener } from '@confluence/network-status-listener';
import { Pendo } from '@confluence/pendo';
import { HighlightActionsProviderForNav3 } from '@confluence/highlight-actions/entry-points/HighlightActionsProvider';
import {
	debuggerEnabled,
	clearAnalyticsDebugger,
} from '@confluence/analytics-debugger/entry-points/AnalyticsDebugger';
import { getBrowserHistory, setBrowserHistoryListener } from '@confluence/browser-history';
import { startExperienceForRoute } from '@confluence/experience-tracker';
import { getMonitoringClient } from '@confluence/monitoring';
import { releaseSSRedLegacyMacros } from '@confluence/content-renderer-legacy-macros';
import { ExternalShareContextProvider } from '@confluence/external-share-context';
import { Heartbeat } from '@confluence/heartbeat';
import { EditPageLoadingContextProvider } from '@confluence/load-edit-page/entry-points/EditPageLoadingContext';
import { LocalStorageQuotaSubscriber } from '@confluence/local-storage-quota-subscriber';
import { useInitializeThemeStateless } from '@confluence/theming/entry-points/useInitializeThemeStateless';
import { NotificationLandedEvent } from '@confluence/notifications/entry-points/NotificationLandedEvent';
import { ExperienceActionMeasures } from '@confluence/action-measures';
import { Cookies } from '@confluence/cookies';
import { resetApolloStats } from '@confluence/graphql';
import { preloadQueryBasedOnRoute, preloadExtensionsData } from '@confluence/query-preloaders';
import { StrictMode } from '@confluence/strict-mode';
import { useIsDesktop } from '@confluence/desktop-native-bridge';
import { fg } from '@confluence/feature-gating';

import { routeComponents, NotFound } from '../Routes';
import { setMemoryMonitorRouteName } from '../../memory-monitor';
import { LazyCreateDialogQueryRenderer } from '../CreateDialogQueryRenderer';
import { LazyCreateDialogBlueprintWizardRenderer } from '../PseudoWizardCreateDialog';
import { preloadLivePagesComponents } from '../../preloaders';
import LegacyBridge from '../LegacyBridge';
import SingleModalContainer from '../SingleModal';
import LegacyCssOverrides from '../../tech-debt/LegacyCssOverrides';
import ShortcutsProvider from '../KeyboardShortcuts'; // 0KB
import ChangeboardingManager from '../Changeboarding/ChangeboardingManager'; // 240KB
import EngagementMessages from '../EngagementMessages';
import { preloadComponents } from '../../route-component-preloader';
// This is tangled with fabric editor state that we can't remove for now.
import ErrorContainer from '../ReduxError';

const browserHistory = getBrowserHistory();

/**
 * Note: this wont run on legacy .action routes
 * if you are adding a global component that needs to work on legacy routes, you may need to add it to StandaloneConfluenceNavigation as well
 */

export function App({ sessionData }) {
	window.performance.mark('CFP-63.app-component-render');

	const { routePolicy } = useNavigationPolicy();
	const { isDesktop } = useIsDesktop();

	// Initialize cookies at App if confluence_frontend_move_initialize_controls is Off
	// Otherwise, initialize it earlier at post-process-session-data
	if (!fg('confluence_frontend_move_initialize_controls')) {
		Cookies.initialize();
	}

	// Initializing the theme in a non-blocking way, instead of using <ThemeContainer>, so
	// that the app does not flash and re-paint after the SPA loads on top of the SSR page.
	useInitializeThemeStateless();

	// Lite Mode™ check outside of the router
	// For certain pages supporting lite mode some functionality is not needed
	const isLite = isViewPageLite();

	const onRoute = useCallback(
		async (match, transitionId) => {
			// Reset window.__APOLLO_STATS__ on transition
			resetApolloStats();

			// transitionId = 0 when it is initial load, and 0 is falsey
			if (transitionId) {
				// The page can't be rendered by SSR if is a transition
				window['__SSR_RENDERED__'] = false;
				//hydrateRoot and related logic should not be enabled on transitions
				//as its only for ssr rendered page
				window['__HYDRATABLE__'] = false;
				releaseSSRedLegacyMacros();
				if (debuggerEnabled) {
					clearAnalyticsDebugger();
				}
			} else {
				window.performance.mark('CFP-63.route-manager-initialized');
			}
			setUniquePageLoadId(match?.name ?? '', match, transitionId, sessionData);

			startExperienceForRoute(match, transitionId, sessionData);

			setMemoryMonitorRouteName(match?.name ?? 'unknown');

			void BMEventBus.stream([{ type: 'page-transition', ID: transitionId }]);
			//if the new editor metrics FF is on, start BM here only if the route is not edit page or if it is initial load, or an embedded editor.
			if (!isEditPage(match?.name) || transitionId === 0 || isEmbeddedConfluence_DO_NOT_USE()) {
				startBrowserMetricsPageLoad(
					match?.name ?? '',
					match?.ufoName,
					transitionId,
					match?.params.contentId,
				);

				addUFOCustomData({ isDesktop });

				//If it is an embedded editor, start the BM right here since it does a full page relaod
				if (isEditPage(match?.name) && isEmbeddedConfluence_DO_NOT_USE()) {
					ExperienceActionMeasures.markMeasureStart(EMBEDDED_EDIT_FMP_ACTION);
					ExperienceActionMeasures.markMeasureStart(EMBEDDED_EDIT_TTI_ACTION);
				}
			}

			// mark edit experience after transition to new route is complete
			markCustomTransitionExperienceForRoute(match?.name);

			getMonitoringClient().updateContext({
				pageName: match?.name ?? 'unknown',
				transitionId,
				product: isEmbeddedConfluence_DO_NOT_USE() ? 'embeddedConfluence' : 'confluence',
			});
		},
		[sessionData, isDesktop],
	);

	/**
	 * Please note this App is a pure component.
	 * This means following components will NOT re-render when user navigates.
	 * Please be careful when adding any component that requires router information.
	 */
	return (
		<StrictMode>
			<ErrorBoundary attribution={Attribution.BACKBONE}>
				<ChangeboardingManager>
					<SSRRenderFailureNotification />
					<ConnectedRouter history={browserHistory} context={ReactReduxContext}>
						{/* Components above this line do NOT re-render in SPA transition */}
						<Fragment>
							<LegacyBridge />
							{/* This needs be placed before content */}
							<ExternalShareContextProvider path={browserHistory.location.pathname}>
								<CreateDialogContextProvider>
									{/* These preloaders need to be passed here to avoid circular dependencies in full page editor */}
									<EditPageLoadingContextProvider
										nativeEditorComponentsPreloader={nativeEditorComponentsPreloader}
										livePageComponentsPreloader={preloadLivePagesComponents}
									>
										<RouteManager
											routeComponents={routeComponents}
											namedRoutes={namedRoutes}
											notFound={NotFound}
											onInitialize={onRoute}
											onTransition={onRoute}
											onPreload={onPreloadRoute}
											routePolicy={routePolicy}
											setBrowserHistoryListener={setBrowserHistoryListener}
										>
											{/* Components that relies on RoutesContext */}
											<LegacyCssOverrides />
											<Heartbeat />
											<NotificationLandedEvent />
											<TimeToAppIdle />

											{!isLite && (
												<>
													<Dialogs />
													<LazyCreateDialogQueryRenderer />
													<LazyCreateDialogBlueprintWizardRenderer />
												</>
											)}
										</RouteManager>
									</EditPageLoadingContextProvider>
								</CreateDialogContextProvider>
							</ExternalShareContextProvider>
							<HighlightActionsProviderForNav3 />
							<SingleModalContainer />
							<NetworkStatusListener />
						</Fragment>
						{/* Components after this line do NOT re-render in SPA transition */}
					</ConnectedRouter>
					<ShortcutsProvider />
					<ErrorContainer />
					<ConnectSupport />
					<Pendo />
					<Flags />
					<LocalStorageQuotaSubscriber />

					{!isLite && (
						<>
							<CopySpacePermissionsForLegacyNewSpace />
							<EngagementMessages />
						</>
					)}
				</ChangeboardingManager>
			</ErrorBoundary>
		</StrictMode>
	);
}

App.propTypes = {
	sessionData: PropTypes.object.isRequired,
};

function onPreloadRoute({ url }, previousMatch) {
	void preloadComponents(url, true);
	void preloadQueryBasedOnRoute(url, true, previousMatch);
	void preloadExtensionsData(url);
}
