import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useQuery } from 'react-apollo';

import { useNativeCollabState } from '@confluence/native-collab';

import { getContentAppearanceFromQueryData, getContentAppearanceQuery } from './contentAppearance';
import type { ContentAppearance } from './types';
import { ContentAppearancePropertyKey, ContentAppearanceType } from './types';

type ContentAppearanceContextType = {
	contentAppearance: ContentAppearance;
	setContentAppearance(contentAppearance: ContentAppearance): void;
	contentAppearanceLoading: boolean;
};
const ContentAppearanceContext = React.createContext<ContentAppearanceContextType>({
	contentAppearance: {
		appearance: ContentAppearanceType.DEFAULT,
		version: 0,
	},
	setContentAppearance: () => {},
	contentAppearanceLoading: false,
});

type ContentAppearanceContextProviderProps = {
	contentId: string;
	children: React.ReactNode;
};
export const ContentAppearanceContextProvider = ({
	contentId,
	children,
}: ContentAppearanceContextProviderProps) => {
	const [contentAppearanceOverride, setContentAppearanceOverride] = useState<ContentAppearance>();

	const [{ collabEditProvider }] = useNativeCollabState();
	// Subscribe to metadata:changed events to update the content appearance for collaborators
	useEffect(() => {
		if (!collabEditProvider) {
			return;
		}

		const handleMetadataChanged = (metadata: any) => {
			const widthValue = metadata?.editorWidth?.value;
			const version = metadata?.editorWidth?.version;

			if (widthValue) {
				setContentAppearanceOverride((currentAppearance) => {
					if (!currentAppearance || version > currentAppearance.version) {
						return {
							appearance: widthValue,
							version,
						};
					} else {
						return currentAppearance;
					}
				});
			}
		};

		collabEditProvider.on('metadata:changed', handleMetadataChanged);
		return () => {
			collabEditProvider.off('metadata:changed', handleMetadataChanged);
		};
	}, [collabEditProvider]);

	const draftAppearanceQuery = getContentAppearanceQuery(ContentAppearancePropertyKey.DRAFT);
	const { data: initialContentAppearanceData, loading: initialContentAppearanceLoading } = useQuery(
		draftAppearanceQuery,
		{
			variables: {
				contentId,
				status: ['current', 'draft'],
			},
		},
	);

	const context = useMemo(() => {
		const initialContentAppearance = getContentAppearanceFromQueryData(
			initialContentAppearanceData,
			ContentAppearancePropertyKey.DRAFT,
		);
		return {
			contentAppearance: contentAppearanceOverride || initialContentAppearance,
			setContentAppearance: setContentAppearanceOverride,
			contentAppearanceLoading: initialContentAppearanceLoading,
		};
	}, [initialContentAppearanceData, initialContentAppearanceLoading, contentAppearanceOverride]);

	return (
		<ContentAppearanceContext.Provider value={context}>
			{children}
		</ContentAppearanceContext.Provider>
	);
};

type ContentAppearanceContextConsumerProps = {
	children: (props: ContentAppearanceContextType) => JSX.Element;
};
export const ContentAppearanceContextConsumer = ({
	children,
}: ContentAppearanceContextConsumerProps) => {
	const context = useContentAppearance();
	return children(context);
};

export const useContentAppearance = () => {
	return useContext(ContentAppearanceContext);
};
