import gql from 'graphql-tag';

export const NativeCollabProviderQuery = gql`
	query NativeCollabProviderQuery($accountId: ID, $shouldIncludePermissionType: Boolean) {
		user(id: $accountId) {
			id
			displayName
			photos {
				value
				isPrimary
			}

			confluence @include(if: $shouldIncludePermissionType) {
				permissionType
			}
		}
	}
`;
