import type { ApolloError } from 'apollo-client';
import type { GraphQLError } from 'graphql';

// This error happens if the space key the user requested gets taken before they create their space
export const isDuplicateSpaceKeyError = (error: ApolloError | null | undefined): boolean => {
	return !!error?.graphQLErrors?.some(_isDuplicateSpaceKeyError);
};

const SPACE_ALREADY_EXISTS_ERROR = 'A space already exists';
const _isDuplicateSpaceKeyError = (error: GraphQLError): boolean => {
	const isStatusCode400 = error.extensions?.statusCode === 400;
	const hasSimpleErrorMessage = error.message.includes(SPACE_ALREADY_EXISTS_ERROR);

	const hasNestedErrorMessage = error.extensions?.data?.errors?.every(
		(
			err:
				| { message: { translation: string[] | null | undefined } | null | undefined }
				| null
				| undefined,
		) => err?.message?.translation?.includes(SPACE_ALREADY_EXISTS_ERROR),
	);

	return isStatusCode400 && (hasSimpleErrorMessage || hasNestedErrorMessage);
};
