import { query } from '@confluence/query-preloader-tools';
import { expVal } from '@confluence/feature-experiments';

import { FeedContentQuery } from './FeedContentQuery.graphql';
import { FeedTypeSelectorRefreshQuery } from './FeedTypeSelectorRefreshQuery.graphql';
import { FeedPopularFeedQuery } from './FeedPopularFeedQuery.graphql';

export const preloadFeed = ({ isLoggedIn }) => {
	// If the user is not logged in we show the following feed by default
	// so only preload that feed. If the user is logged in preload both
	// feeds and the feed preference
	const tasks: Array<Promise<any>> = [
		query({
			query: FeedContentQuery,
			variables: {
				first: expVal('confluence_home_feed_initial_page_size', 'following_ssr', 5),
			},
		}),
	];
	if (isLoggedIn) {
		tasks.push(
			query({
				query: FeedPopularFeedQuery,
				variables: {
					first: expVal('confluence_home_feed_initial_page_size', 'popular_ssr', 5),
				},
			}),
			query({
				query: FeedTypeSelectorRefreshQuery,
			}),
		);
	}
	return Promise.all(tasks);
};
