import { isNotFoundSpaceError } from '@confluence/content-unified-query/entry-points/errors';
import { query, isUnauthorizedError } from '@confluence/query-preloader-tools';

import type {
	SpaceOverviewIdQuery as SpaceOverviewIdQueryType,
	SpaceOverviewIdQueryVariables,
} from './__types__/SpaceOverviewIdQuery';
import { SpaceOverviewIdQuery } from './SpaceOverviewIdQuery.graphql';

export function preloadSpaceOverviewId(
	spaceKey: string,
): Promise<{ result?: any; hasErrors: boolean }> {
	return query<SpaceOverviewIdQueryType, SpaceOverviewIdQueryVariables>({
		query: SpaceOverviewIdQuery,
		variables: {
			spaceKey,
		},
	}).then((result: any) => {
		const hasErrors = result?.errors?.length > 0;
		result?.errors?.forEach((error) => {
			// The "Cannot find space" condition is handled by redirecting to the correct space even if
			// the error is not propagated to ContentPrerequisites where it is normally handled.
			// And we use implicit dependency on GraphqlErrorProcessor from "@confluence/graphql" to
			// avoid direct import of this package into preloader bundle.
			if (isUnauthorizedError(error) || isNotFoundSpaceError(error)) {
				window?.__GRAPHQL_ERROR_PROCESSOR__?.markErrorAsHandled(error);
			}
		});

		return {
			result,
			hasErrors,
		};
	});
}
