export type VendorExtensionListType = {
	[key: string]: {
		app_name: string;
		vendor_name: string;
		marketplace_type: string;
		appid: string;
	};
};

// This is a temporary list of vendor extensions as workaround to not being able to put appIds into Statsig feature flags
export const vendorExtensionList: VendorExtensionListType = {
	'b8cb5c9a-6931-4960-87d3-7afae75b8c09': {
		app_name: 'AI Forms+ for Confluence',
		vendor_name: 'Apps+',
		marketplace_type: 'Free',
		appid: 'b8cb5c9a-6931-4960-87d3-7afae75b8c09',
	},
	'8b09aac4-7c40-4079-b548-216055dc0700': {
		app_name: 'AI Macros+ for Confluence',
		vendor_name: 'Apps+',
		marketplace_type: 'Free',
		appid: '8b09aac4-7c40-4079-b548-216055dc0700',
	},
	'10dbf7e0-383d-4322-97da-d3c00287bbb9': {
		app_name: 'AI Images+ for Confluence',
		vendor_name: 'Apps+',
		marketplace_type: 'Free',
		appid: '10dbf7e0-383d-4322-97da-d3c00287bbb9',
	},
	'2d0e76c1-3cb3-488f-89b1-661b7c5ce5bc': {
		app_name: 'AI Buttons+ for Confluence',
		vendor_name: 'Apps+',
		marketplace_type: 'Free',
		appid: '2d0e76c1-3cb3-488f-89b1-661b7c5ce5bc',
	},
	'510bf3f8-920d-42f8-9f64-47024a80a51e': {
		app_name: 'AI Checklist & Todo+ for Confluence',
		vendor_name: 'Apps+',
		marketplace_type: 'Free',
		appid: '510bf3f8-920d-42f8-9f64-47024a80a51e',
	},
	'a9267d77-c528-4f87-80a7-e2127d3da127': {
		app_name: 'AI Summary+ for Confluence',
		vendor_name: 'Apps+',
		marketplace_type: 'Free',
		appid: 'a9267d77-c528-4f87-80a7-e2127d3da127',
	},
	'4bc5e451-38f9-41d9-992d-da403231bd6f': {
		app_name: 'AI Polls+ for Confluence',
		vendor_name: 'Apps+',
		marketplace_type: 'Free',
		appid: '4bc5e451-38f9-41d9-992d-da403231bd6f',
	},
	'12102cc6-220b-4ec1-9d73-474c36bd241d': {
		app_name: 'AI LaTeX+ for Confluence',
		vendor_name: 'Apps+',
		marketplace_type: 'Free',
		appid: '12102cc6-220b-4ec1-9d73-474c36bd241d',
	},
	'5e3e625a-abb1-49d4-877c-58b2829cb842': {
		app_name: 'AI Glossary+ for Confluence',
		vendor_name: 'Apps+',
		marketplace_type: 'Free',
		appid: '5e3e625a-abb1-49d4-877c-58b2829cb842',
	},
	'd931ce14-1bf4-4678-94b1-86e23c8dfdca': {
		app_name: 'AI Tabs+ for Confluence',
		vendor_name: 'Apps+',
		marketplace_type: 'Free',
		appid: 'd931ce14-1bf4-4678-94b1-86e23c8dfdca',
	},
	'b6618ec0-bc76-4bc3-9ae3-4f9a74750094': {
		app_name: 'AI Sentiment+ for Confluence',
		vendor_name: 'Apps+',
		marketplace_type: 'Free',
		appid: 'b6618ec0-bc76-4bc3-9ae3-4f9a74750094',
	},
	'708f790f-6899-486d-a2cf-426154e1cb05': {
		app_name: 'AI Tooltips+ for Confluence',
		vendor_name: 'Apps+',
		marketplace_type: 'Free',
		appid: '708f790f-6899-486d-a2cf-426154e1cb05',
	},
	'813f7fa3-0d5b-43f3-a37e-f9afecfa6308': {
		app_name: 'AI Tables+ for Confluence',
		vendor_name: 'Apps+',
		marketplace_type: 'Free',
		appid: '813f7fa3-0d5b-43f3-a37e-f9afecfa6308',
	},
	'088acb75-45fa-4b04-9ed3-d37fa6092b10': {
		app_name: 'AI Code+ for Confluence',
		vendor_name: 'Apps+',
		marketplace_type: 'Free',
		appid: '088acb75-45fa-4b04-9ed3-d37fa6092b10',
	},
	'551cb6a7-51b0-471d-922b-612ceea751cf': {
		app_name: 'AI Copywriter+ for Confluence',
		vendor_name: 'Apps+',
		marketplace_type: 'Free',
		appid: '551cb6a7-51b0-471d-922b-612ceea751cf',
	},
	'1150dd4c-6c84-4671-a596-ebaad90974b8': {
		app_name: 'AI Grammar+ for Confluence',
		vendor_name: 'Apps+',
		marketplace_type: 'Free',
		appid: '1150dd4c-6c84-4671-a596-ebaad90974b8',
	},
	'691021b8-e005-4e49-a786-7dec03beb369': {
		app_name: 'AI Headings+ for Confluence',
		vendor_name: 'Apps+',
		marketplace_type: 'Free',
		appid: '691021b8-e005-4e49-a786-7dec03beb369',
	},
	'91100b64-f932-44f0-bf99-0450f69316d5': {
		app_name: 'AI Labels+ for Confluence',
		vendor_name: 'Apps+',
		marketplace_type: 'Free',
		appid: '91100b64-f932-44f0-bf99-0450f69316d5',
	},
	'd04f8a66-c37d-408f-b030-141b660f1250': {
		app_name: 'AI Photos+ for Confluence',
		vendor_name: 'Apps+',
		marketplace_type: 'Free',
		appid: 'd04f8a66-c37d-408f-b030-141b660f1250',
	},
	'6f4b5678-8e17-4acf-8a76-f15b6a71b71c': {
		app_name: 'AI Tags+ for Confluence',
		vendor_name: 'Apps+',
		marketplace_type: 'Free',
		appid: '6f4b5678-8e17-4acf-8a76-f15b6a71b71c',
	},
	'cb297002-e1aa-4fa3-8b8a-6466cfd7ecb5': {
		app_name: 'Statuspage for Compass',
		vendor_name: 'Atlassian',
		marketplace_type: 'Free',
		appid: 'cb297002-e1aa-4fa3-8b8a-6466cfd7ecb5',
	},
	'564688a0-afba-45d3-b882-dbf6edbe6066': {
		app_name: 'Swagger UI for Compass',
		vendor_name: 'Atlassian',
		marketplace_type: 'Free',
		appid: '564688a0-afba-45d3-b882-dbf6edbe6066',
	},
	'64cf65a9-1426-4878-8a92-9aefe254ea2f': {
		app_name: 'New Relic for Compass',
		vendor_name: 'Atlassian',
		marketplace_type: 'Free',
		appid: '64cf65a9-1426-4878-8a92-9aefe254ea2f',
	},
	'737c2d8a-cc06-4bd2-8ad4-68f162f57598': {
		app_name: 'Cloud Migration Planner',
		vendor_name: 'Atlassian Labs',
		marketplace_type: 'Free',
		appid: '737c2d8a-cc06-4bd2-8ad4-68f162f57598',
	},
	'4dfbeb40-d007-48d3-a2a7-3a188b3bc5ef': {
		app_name: 'Confluence recent edits overview',
		vendor_name: 'Atlassian Labs',
		marketplace_type: 'Free',
		appid: '4dfbeb40-d007-48d3-a2a7-3a188b3bc5ef',
	},
	'd699590b-1078-499f-a1bd-482eb1b218be': {
		app_name: 'Admin Kit for Confluence Cloud',
		vendor_name: 'Atlassian Labs',
		marketplace_type: 'Free',
		appid: 'd699590b-1078-499f-a1bd-482eb1b218be',
	},
	'4676252e-b3a0-4f9a-beef-48882f49c2de': {
		app_name: 'JSM Assets - Microsoft Entra ID (Azure AD) Beta Integration',
		vendor_name: 'Atlassian Labs',
		marketplace_type: 'Free',
		appid: '4676252e-b3a0-4f9a-beef-48882f49c2de',
	},
	'0ca92324-d526-4942-bd9d-9a1913b9105c': {
		app_name: 'Form macro builder for Confluence',
		vendor_name: 'Atlassian Labs',
		marketplace_type: 'Free',
		appid: '0ca92324-d526-4942-bd9d-9a1913b9105c',
	},
	'00aa7b1c-3f40-49c6-bbae-3e87ac99ddaa': {
		app_name: 'Admin Kit for Jira Cloud',
		vendor_name: 'Atlassian Labs',
		marketplace_type: 'Free',
		appid: '00aa7b1c-3f40-49c6-bbae-3e87ac99ddaa',
	},
	'7e6878eb-2b76-4708-b872-de6abdd1caef': {
		app_name: 'easyJOB',
		vendor_name: 'Because Software Organisationsberatungs- und  Entwicklungsgesellschaft m.b.H',
		marketplace_type: 'Free',
		appid: '7e6878eb-2b76-4708-b872-de6abdd1caef',
	},
	'9ea1f892-1356-44bd-9ac8-684abf3a5714': {
		app_name: 'Flagsmith for Jira',
		vendor_name: 'Flagsmith',
		marketplace_type: 'Free',
		appid: '9ea1f892-1356-44bd-9ac8-684abf3a5714',
	},
	'fb9149f6-e173-45e2-9254-fa1e1cd4fdf5': {
		app_name: 'Herzum Approval',
		vendor_name: 'Herzum',
		marketplace_type: 'Marketplace',
		appid: 'fb9149f6-e173-45e2-9254-fa1e1cd4fdf5',
	},
	'168f56b1-e9c7-47df-a65b-fa0aaf9d7a10': {
		app_name: 'Service Time Hours Management',
		vendor_name: 'Inlogiq',
		marketplace_type: 'Marketplace',
		appid: '168f56b1-e9c7-47df-a65b-fa0aaf9d7a10',
	},
	'3122982b-49a3-434c-91a1-88467ae83eb8': {
		app_name: 'Project Bookmarks',
		vendor_name: 'Interesting Projects',
		marketplace_type: 'Marketplace',
		appid: '3122982b-49a3-434c-91a1-88467ae83eb8',
	},
	'b4bb918a-0e24-4a7c-9ad8-9a1c3f0cd3ec': {
		app_name: 'JAI Autocomplete with AI for Jira',
		vendor_name: 'Interesting Projects',
		marketplace_type: 'Free',
		appid: 'b4bb918a-0e24-4a7c-9ad8-9a1c3f0cd3ec',
	},
	'ec62cde0-6732-4494-8207-ca0723ff5b0e': {
		app_name: 'HubSpot Importer for JSM Assets',
		vendor_name: 'Pio',
		marketplace_type: 'Marketplace',
		appid: 'ec62cde0-6732-4494-8207-ca0723ff5b0e',
	},
	'25b731e3-f758-4010-91ef-4fc6f0e2720b': {
		app_name: 'Google Workspace Importer for JSM Assets',
		vendor_name: 'Pio',
		marketplace_type: 'Marketplace',
		appid: '25b731e3-f758-4010-91ef-4fc6f0e2720b',
	},
	'e99ca91d-be69-4ade-82bf-b34ec0fc2cba': {
		app_name: 'Datadog Importer for JSM Assets',
		vendor_name: 'Pio',
		marketplace_type: 'Marketplace',
		appid: 'e99ca91d-be69-4ade-82bf-b34ec0fc2cba',
	},
	'0e5645ae-7bc8-4aef-b86e-8a38be838195': {
		app_name: 'Quick Charts for JSM Assets',
		vendor_name: 'Pio',
		marketplace_type: 'Marketplace',
		appid: '0e5645ae-7bc8-4aef-b86e-8a38be838195',
	},
	'80ebfdab-4e82-4f0d-bf68-8b278735a2b1': {
		app_name: 'Snipe-IT Importer for JSM Assets',
		vendor_name: 'Pio',
		marketplace_type: 'Marketplace',
		appid: '80ebfdab-4e82-4f0d-bf68-8b278735a2b1',
	},
	'74df661b-9196-41cd-9f04-dbd51cfe0a0e': {
		app_name: 'Jamf Importer for JSM Assets',
		vendor_name: 'Pio',
		marketplace_type: 'Marketplace',
		appid: '74df661b-9196-41cd-9f04-dbd51cfe0a0e',
	},
	'9d13536c-5852-433a-bf95-f40b714daac1': {
		app_name: 'Azure AD (Microsoft Entra ID) Importer for JSM Assets',
		vendor_name: 'Pio',
		marketplace_type: 'Marketplace',
		appid: '9d13536c-5852-433a-bf95-f40b714daac1',
	},
	'f319d73c-0f64-482a-b840-cc0fc44bb2c4': {
		app_name: 'Tempo Importer for JSM Assets',
		vendor_name: 'Pio',
		marketplace_type: 'Marketplace',
		appid: 'f319d73c-0f64-482a-b840-cc0fc44bb2c4',
	},
	'6e4433d2-1b6d-42da-97f7-7bbe1b5616b6': {
		app_name: 'Database Importer for JSM Assets',
		vendor_name: 'Pio',
		marketplace_type: 'Marketplace',
		appid: '6e4433d2-1b6d-42da-97f7-7bbe1b5616b6',
	},
	'367714bc-ae28-4be7-addd-47b3063a9ce6': {
		app_name: 'Microsoft Intune Importer for JSM Assets',
		vendor_name: 'Pio',
		marketplace_type: 'Marketplace',
		appid: '367714bc-ae28-4be7-addd-47b3063a9ce6',
	},
	'302a2739-e439-4ca6-a2a9-ccd4d41f3ed8': {
		app_name: 'Okta Importer for JSM Assets',
		vendor_name: 'Pio',
		marketplace_type: 'Marketplace',
		appid: '302a2739-e439-4ca6-a2a9-ccd4d41f3ed8',
	},
	'9839f764-b22c-43af-a333-741dfdd01282': {
		app_name: 'AWS Importer for JSM Assets',
		vendor_name: 'Pio',
		marketplace_type: 'Marketplace',
		appid: '9839f764-b22c-43af-a333-741dfdd01282',
	},
	'4bef8fe8-6c31-42dd-a86f-3988ee3b740f': {
		app_name: 'Salesforce (CRM) Importer for JSM Assets',
		vendor_name: 'Pio',
		marketplace_type: 'Marketplace',
		appid: '4bef8fe8-6c31-42dd-a86f-3988ee3b740f',
	},
	'1b1c1dae-8212-4967-8cd1-1f9528d97a0a': {
		app_name: 'Quarter Custom Field Type',
		vendor_name: 'Raledo Software',
		marketplace_type: 'Marketplace',
		appid: '1b1c1dae-8212-4967-8cd1-1f9528d97a0a',
	},
	'44095ea5-4e3a-4448-90e3-e934edf0d8fd': {
		app_name: 'Simple Issue Links',
		vendor_name: 'Raledo Software',
		marketplace_type: 'Free',
		appid: '44095ea5-4e3a-4448-90e3-e934edf0d8fd',
	},
	'46040245-5cda-487c-816e-73e2774387c6': {
		app_name: 'Skills for Jira',
		vendor_name: 'Totem Dev',
		marketplace_type: 'Marketplace',
		appid: '46040245-5cda-487c-816e-73e2774387c6',
	},
	'7d332ecd-e403-43a5-8a57-5ab64084fd4a': {
		app_name: 'Links From Comments',
		vendor_name: 'Transition Technologies PSC',
		marketplace_type: 'Free',
		appid: '7d332ecd-e403-43a5-8a57-5ab64084fd4a',
	},
	'4c68c0f4-b8b4-46e8-9577-7157ff909b6f': {
		app_name: 'Absence Tracker',
		vendor_name: 'Transition Technologies PSC',
		marketplace_type: 'Free',
		appid: '4c68c0f4-b8b4-46e8-9577-7157ff909b6f',
	},
	'e9ca56d5-4e4b-421a-a1cb-583a8e439cca': {
		app_name: 'Assistant for Trello',
		vendor_name: 'Transition Technologies PSC',
		marketplace_type: 'Free',
		appid: 'e9ca56d5-4e4b-421a-a1cb-583a8e439cca',
	},
};
