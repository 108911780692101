import type { SelectProps as PlatformSelectProps } from '@atlaskit/select';

export type SelectProps = Pick<
	PlatformSelectProps<any, true | false>,
	| 'appearance'
	| 'autoFocus'
	| 'closeMenuOnScroll'
	| 'closeMenuOnSelect'
	| 'defaultInputValue'
	| 'defaultMenuIsOpen'
	| 'defaultValue'
	| 'id'
	// `id` should be used instead of inputId. `inputId` is retained for now for backwards compatibility
	| 'inputId'
	| 'inputValue'
	| 'isClearable'
	| 'isDisabled'
	| 'isInvalid'
	| 'isLoading'
	| 'isMulti'
	| 'isSearchable'
	| 'menuIsOpen'
	| 'openMenuOnFocus'
	| 'name'
	| 'onBlur'
	| 'onChange'
	| 'onFocus'
	| 'onInputChange'
	| 'options'
	| 'placeholder'
	| 'spacing'
	| 'testId'
	| 'value'
	| 'isRequired'
>;

/**
 * ADS Select has a highly complex API surface area with some props not being compatiable with Forge.
 * To simplify usage and ensure compatibility, the following props have been excluded:
 * - backspaceRemovesValue
 * - blurInputOnSelect
 * - captureMenuScroll
 * - components
 * - controlShouldRenderValue
 * - escapeClearsValue
 * - formatGroupLabel
 * - formatOptionLabel
 * - getOptionLabel
 * - getOptionValue
 * - hideSelectedOptions
 * - isOptionDisabled - not working
 * - isOptionSelected - not working
 * - noOptionsMessage - prop excluded because we can’t pass function implementations across the bridge
 * - filterOption - prop excluded because we can’t pass function implementations across the bridge
 *
 */

import React from 'react';
import type { RenderFn } from '@atlassian/forge-ui-types';
import { SelectWithoutAnalytics as PlatformSelectWithoutAnalytics } from '@atlaskit/select';
import { adaptEventHandler } from '../../utils';
import { PortalConsumer } from '../../../context';

/**
 * Select allows users to make a single selection or multiple selections from a list of options.
 */
export const Select = (props: Parameters<RenderFn>[0]) => {
	const {
		spacing,
		appearance,
		testId,
		autoFocus,
		defaultValue,
		closeMenuOnSelect,
		inputValue,
		inputId,
		isClearable,
		isLoading,
		isMulti,
		isSearchable,
		menuIsOpen,
		openMenuOnFocus,
		onInputChange,
		options,
		placeholder,
		onChange,
		isRequired,
		// Field props
		id,
		isDisabled,
		isInvalid,
		onBlur,
		onFocus,
		value,
		name,
	} = props.forgeDoc.props as SelectProps;
	return (
		// Portal is required to render the menu as a sibling of the select container so that it appears ontop of the parent when used within ModalBody
		// ModalBody is a scrollable area and the menu is hidden when rendered within it
		<PortalConsumer>
			{(portal) => (
				<PlatformSelectWithoutAnalytics
					spacing={spacing}
					appearance={appearance}
					testId={testId}
					autoFocus={autoFocus}
					defaultValue={defaultValue}
					closeMenuOnSelect={closeMenuOnSelect}
					inputValue={inputValue}
					inputId={id || inputId}
					isClearable={isClearable}
					isLoading={isLoading}
					isMulti={isMulti}
					isSearchable={isSearchable}
					menuIsOpen={menuIsOpen}
					openMenuOnFocus={openMenuOnFocus}
					menuPortalTarget={portal}
					isRequired={isRequired}
					// onInputChange must return a void type for this to work
					onInputChange={(value, metaData) => {
						onInputChange?.(value, metaData);
						return;
					}}
					options={options}
					placeholder={placeholder}
					onChange={onChange}
					// Field props
					isDisabled={isDisabled}
					isInvalid={isInvalid}
					onBlur={adaptEventHandler(onBlur)}
					onFocus={adaptEventHandler(onFocus)}
					value={value}
					name={name}
				/>
			)}
		</PortalConsumer>
	);
};
