import React from 'react';

import type { RouteMatch } from '@confluence/route';
import {
	CONTAINER_HOME_ID,
	COMPANY_HUB_SETTINGS_ID,
} from '@confluence/app-navigation/entry-points/navigationIDs';
import { isCompanyHubSpaceKey } from '@confluence/route-manager/entry-points/companyHubUtils';
import { ContentHistoryLoader } from '@confluence/content-history';
import { getCompanyHubPseudoAppRoute } from '@confluence/ssr-app/entry-points/getCompanyHubPseudoAppRoute';

const ContentHistoryRouteComponent = ({ params: { spaceKey, contentId } }: RouteMatch) => (
	<ContentHistoryLoader spaceKey={spaceKey} contentId={contentId} stack="classic" />
);

ContentHistoryRouteComponent.NAVIGATION_PARAMS = ({
	params: { spaceKey, contentId, contentSlug },
}: RouteMatch) => {
	const isCompanyHub = isCompanyHubSpaceKey(spaceKey);

	return {
		Screen: {
			screenEvent: {
				name: isCompanyHub ? 'hubHistoryScreen' : 'pageHistoryScreen',
				id: contentId,
			},
			pageState: {
				spaceKey,
				contentId,
				contentSlug,
			},
		},
		MainLayout: {
			spaceKey,
			navView: isCompanyHub ? COMPANY_HUB_SETTINGS_ID : CONTAINER_HOME_ID,
		},
	};
};

export const ContentHistoryRoute = getCompanyHubPseudoAppRoute(
	ContentHistoryRouteComponent,
	(route: RouteMatch) => isCompanyHubSpaceKey(route?.params?.spaceKey),
);
