import type { PerformanceEntryBuffer } from '../utils/buffer';

export type RelevantNodeInfo = {
	className: string;
	localName: string;
};

export type CLSData = {
	score: number;
	nodeCounts: {
		[nodeKey: string]: number;
	};
};

export interface LayoutShift extends PerformanceEntry {
	hadRecentInput: boolean;
	sources: Array<{
		node: RelevantNodeInfo;
	}>;
	value: number;
}

/*
Calculates Cumulative Layout Shift (CLS) in a given timeframe

The function retrieves layout shift entries from the buffer, filtering those that occur between the start
and stop times. It groups these shifts into session windows of up to 5 seconds each for the following reasons:

1. Avoid artificially improving CLS score of slow pages
2. Cap the impact of continual small shifts that don't increase annoyance over time (e.g. live score updating page)
Source: https://web.dev/evolving-cls/#why-5-seconds

It identifies the session window with the highest cumulative score, considering this as the page's CLS score
*/

export const getCLS = (start: number, stop: number, buffer: PerformanceEntryBuffer) => {
	const clsData: CLSData = {
		score: 0,
		nodeCounts: {},
	};

	const allLayoutShifts = buffer.getAll() as LayoutShift[];
	const layoutShifts = allLayoutShifts.filter(
		// Filter for layoutShifts within start and stop time and if entry had no recent user input (which can cause CLS)
		(entry) => entry.startTime >= start && entry.startTime <= stop && !entry.hadRecentInput,
	);
	const sessionWindows = [];
	let currentWindow = null;

	// Record CLS offenderes by node type
	layoutShifts.forEach((layoutShift: LayoutShift) => {
		layoutShift.sources.forEach((source) => {
			const { node } = source;

			const nodeKey = `${node?.localName || '-'}(:)${node?.className || '-'}`;

			const count = clsData.nodeCounts[nodeKey];
			if (count > 0) clsData.nodeCounts[nodeKey] += 1;
			else clsData.nodeCounts[nodeKey] = 1;
		});
	});

	// Group layout shifts into session windows and calculate score
	for (const shift of layoutShifts) {
		const { startTime } = shift;
		const endTime = shift.startTime + shift.duration;
		const score = (shift as LayoutShift).value;
		if (
			currentWindow === null ||
			startTime - currentWindow.endTime > 1000 ||
			endTime - currentWindow.startTime > 5000
		) {
			// Start a new session window
			currentWindow = {
				startTime,
				endTime,
				score,
			};
			sessionWindows.push(currentWindow);
		} else {
			// Add layout shift to current session window
			currentWindow.endTime = endTime;
			currentWindow.score += score;
		}
	}
	// Find session window with highest cumulative score
	const maxScore = sessionWindows.reduce(
		(max, sessionWindow) => (sessionWindow.score > max ? sessionWindow.score : max),
		0,
	);
	// Return score of largest burst as CLS metric rounded to 4 decimal places
	clsData.score = Math.round(maxScore * 10000) / 10000;
	return clsData;
};
