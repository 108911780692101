/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum LicenseStatus {
  ACTIVE = "ACTIVE",
  SUSPENDED = "SUSPENDED",
  UNLICENSED = "UNLICENSED",
}

//==============================================================
// END Enums and Input Objects
//==============================================================

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetUserAccessQuery
// ====================================================

export interface GetUserAccessQuery_tenantContext_licensedProducts {
  licenseStatus: LicenseStatus;
  productKey: string;
}

export interface GetUserAccessQuery_tenantContext {
  licensedProducts: GetUserAccessQuery_tenantContext_licensedProducts[];
}

export interface GetUserAccessQuery {
  tenantContext: GetUserAccessQuery_tenantContext | null;
  permitted: boolean;
}

export interface GetUserAccessQueryVariables {
  resourceId: string;
  permissionId: string;
}
