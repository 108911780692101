/**
 * @jsxRuntime classic
 * @jsx jsx
 */
// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import { jsx } from '@emotion/react';
import React, { Fragment, lazy, useState, useEffect } from 'react';
import {
	type DatePickerProps,
	type FieldChildrenProps,
	type FieldProps,
} from '@atlassian/forge-ui-types';
import { type Props } from '../..';
import { FormStateChangeNotifier } from '../form';
import { FieldContainer } from '../utils/FieldContainer';

const AKDatePicker = React.lazy(() =>
	import(
		/* webpackChunkName: '@atlaskit-internal_.datetime-picker' */
		'@atlaskit/datetime-picker'
	).then((module) => ({
		default: module.DatePicker,
	})),
);

const AKField = React.lazy(() =>
	import(
		/* webpackChunkName: '@atlaskit-internal_.form' */
		'@atlaskit/form'
	).then((module) => ({ default: module.Field })),
);

const AKHelperMessage = lazy(() =>
	import(
		/* webpackChunkName: '@atlaskit-internal_.form' */
		'@atlaskit/form'
	).then((module) => ({
		default: module.HelperMessage,
	})),
);

const AKErrorMessage = React.lazy(() =>
	import(
		/* webpackChunkName: '@atlaskit-internal_.form' */
		'@atlaskit/form'
	).then((module) => ({ default: module.ErrorMessage })),
);

const validate = (value: any) => (!value ? 'EMPTY' : undefined);

export const getDateFormat = (locale: string) => {
	const formatObj = new Intl.DateTimeFormat(locale).formatToParts(
		new Date(),
	) as Intl.DateTimeFormatPart[];

	return formatObj
		.map((obj) => {
			switch (obj.type) {
				case 'day':
					return 'DD';
				case 'month':
					return 'MM';
				case 'year':
					return 'YYYY';
				default:
					return obj.value;
			}
		})
		.join('');
};

const DatePicker = ({
	name,
	label,
	description,
	placeholder,
	defaultValue,
	isRequired,
}: DatePickerProps) => {
	const [locale, setLocale] = useState('en-US');

	useEffect(() => {
		if (window.navigator.languages && locale !== window.navigator.languages[0]) {
			setLocale(window.navigator.languages[0]);
		}
	}, [locale]);
	return (
		<FieldContainer>
			<AKField
				name={name}
				label={label}
				defaultValue={defaultValue}
				isRequired={isRequired}
				validate={isRequired ? validate : undefined}
			>
				{({ fieldProps, error }: FieldChildrenProps & { error?: string }) => {
					const {
						id,
						isDisabled,
						isInvalid,
						onBlur,
						onChange,
						onFocus,
						'aria-invalid': ariaInvalid,
						'aria-labelledby': ariaLabelledBy,
					} = fieldProps as FieldProps;
					return (
						<Fragment>
							<FormStateChangeNotifier name={name} value={fieldProps.value} />
							<AKDatePicker
								id={id}
								isDisabled={isDisabled}
								onBlur={onBlur}
								onFocus={onFocus}
								onChange={onChange}
								value={fieldProps.value || ''}
								placeholder={placeholder}
								dateFormat={getDateFormat(locale)}
								isInvalid={isInvalid || Boolean(error)}
								aria-invalid={ariaInvalid}
								aria-labelledby={ariaLabelledBy}
							/>
							{error === 'EMPTY' && <AKErrorMessage>This field is required.</AKErrorMessage>}
							{description && <AKHelperMessage>{description}</AKHelperMessage>}
						</Fragment>
					);
				}}
			</AKField>
		</FieldContainer>
	);
};

export default DatePicker;

export const DatePickerFn: React.FunctionComponent<Props> = ({ props }) => {
	const { name, label, defaultValue, description, placeholder, isRequired } =
		props as DatePickerProps;
	return (
		<DatePicker
			name={name}
			label={label}
			defaultValue={defaultValue}
			description={description}
			placeholder={placeholder}
			isRequired={isRequired}
		/>
	);
};
