import { useCallback, useMemo } from 'react';
import {
	buildBasePath,
	type PostOfficeEnvironmentValues,
	type PostOfficeContextValue,
} from '@atlassian/post-office-context';
import { defaultFetcher, requestInitialData } from '@atlassian/post-office-placement-shared';

import { type PreloadFnContext } from '@confluence/query-preloader-tools';

import {
	type ConfluenceGlobalThisWithPostOfficeCache,
	PostOfficeCacheClient,
	type PostOfficeCache,
} from './post-office-cache-client';
import {
	type ConfluenceSessionDataFields,
	sessionToPostOfficeContext,
	transformConfluenceEnvToPostOfficeEnvironment,
} from './session-to-post-office-context';

/**
 * Post Office class for managing in memory cache of Placement queries.
 * We store the cache reference on the global object to persist across SSR requests.
 */
const POST_OFFICE_QUERY_CACHE: PostOfficeCache =
	(globalThis as ConfluenceGlobalThisWithPostOfficeCache)?.__POST_OFFICE_QUERY_CACHE__ || {};

const postOfficeClient = new PostOfficeCacheClient(POST_OFFICE_QUERY_CACHE);

type OptionalEnvConfigsForPostOfficeEnvironment = Omit<PostOfficeEnvironmentValues, 'envConfigs'> &
	Partial<Pick<PostOfficeEnvironmentValues, 'envConfigs'>>;

export const resStatusAsString = ({ status, statusText }: Response) =>
	JSON.stringify({ status, statusText });

export const assertResponseIsOk = (res: Response | undefined): Response => {
	if (typeof res === 'undefined') {
		throw new Error('No response from server');
	}

	if (res.ok) {
		return res;
	}

	throw new Error(resStatusAsString(res));
};

const wrapResolvedDataAsFetchResponse = (data: any) => {
	const init = {
		status: 200,
		statusText: 'ok!',
	};
	return new Response(JSON.stringify(data), init);
};

// eslint-disable-next-line check-react-ssr-usage/no-react-ssr
const getIsSSR = () => Boolean(process.env.REACT_SSR);

const confluenceFetcher = async (url: string, headers?: HeadersInit) => {
	const fetcher = defaultFetcher(url, headers)
		.then(assertResponseIsOk)
		.then((res) => res.json())
		.then((data) => {
			postOfficeClient.setDataWithUrl(url, data);
			return wrapResolvedDataAsFetchResponse(data);
		});

	// When we're in SPA mode, we want to cache the fetcher so that it can be reused
	// if the network request takes too long and the data doesn't come back in time
	// before another request is requested by <Placement>
	if (!getIsSSR()) {
		postOfficeClient.setFetcherWithUrl(url, fetcher);
	}

	return fetcher;
};

/**
 * Common method for setting up the Post Office placement request URL with the environment and product context
 *
 * @param placementId
 * @param contextOptions
 * @returns
 */
const preloadPostOfficePlacement = async (
	placementId: string,
	contextOptions: {
		postOfficeContext: PostOfficeContextValue;
		environment: OptionalEnvConfigsForPostOfficeEnvironment;
	},
) => {
	const { postOfficeContext, environment } = contextOptions;

	const confluenceFetcherWithS2SHeaders = async (url: string) => {
		// eslint-disable-next-line check-react-ssr-usage/no-react-ssr
		const isSSR = Boolean(process.env.REACT_SSR);
		if (isSSR) {
			let hostEnv = 'prod';
			if (environment.currentEnv !== 'production') {
				hostEnv = 'staging';
			}

			const fetchHeaders = {
				'x-ssr-service-name': 'post-office', // Tells Tesseract to S2S with `post-office`
				'x-ssr-service-env': hostEnv, // Defaults to current Env, however Post Office is not in dev
			};

			return confluenceFetcher(url.replace('gateway/api/post-office/', ''), fetchHeaders);
		}

		return confluenceFetcher(url);
	};

	return requestInitialData(
		placementId,
		postOfficeContext,
		confluenceFetcherWithS2SHeaders,
		buildBasePath(environment.currentEnv, environment.envConfigs),
	);
};

/**
 * Returns a fetcher for Post Office placement API that is shared with the SSR preload
 *
 * @param placementId
 * @returns
 */
export const usePlacementSSRFetch = () => {
	return useCallback((url: string) => {
		// Use data in cache if it exists
		const data = postOfficeClient.getDataWithUrl(url);
		if (data) {
			/**
			 * Preloaded data must be serialized, however our initialData is expected to be in the form of a Response promise.
			 * TODO: Remove this workaround once the Post Office placement API is updated to handle flat data.
			 */
			return Promise.resolve(wrapResolvedDataAsFetchResponse(data));
		}

		// When in SPA mode, we want to re-use the pending fetcher if it exists - so that we don't make another Network request from the Placement component
		if (!getIsSSR()) {
			const fetcher = postOfficeClient.getFetcherWithUrl(url);
			if (fetcher) {
				return fetcher;
			}
		}

		// Use a new fetcher if neither data nor fetcher is in cache
		return confluenceFetcher(url);
	}, []);
};

/**
 * Returns the Post Office placement initial data from the cache if it exists
 *
 * @param placementId
 * @returns Post Office placement initial data from cache
 */
export const usePlacementPreloadedData = (placementId: string) => {
	return useMemo(() => {
		const data = postOfficeClient.getDataWithPlacementId(placementId);
		if (data) {
			return data;
		}

		return undefined;
	}, [placementId]);
};

/**
 * Preload a Post Office placement API via SSR
 *
 * @param placementId
 * @param preloadedSessionContext
 * @returns
 */
export const preloadPostOfficePlacementWithSessionContext = async (
	placementId: string,
	preloadedSessionContext: Pick<PreloadFnContext, ConfluenceSessionDataFields | 'environment'>,
) => {
	return preloadPostOfficePlacement(placementId, {
		postOfficeContext: sessionToPostOfficeContext(preloadedSessionContext),
		environment: {
			currentEnv: transformConfluenceEnvToPostOfficeEnvironment(
				preloadedSessionContext.environment,
			),
		},
	});
};
