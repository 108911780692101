import { type ErrorInfo } from 'react';
import { ForgeCDNEnvironment, type ProductEnvironment } from '@atlassian/forge-ui-types';
import { isFedRamp } from '@atlassian/atl-context';
import { type TracingService } from './tracing';
import { getCDNEnvironment, parseExtensionId } from '../utils';

const packageVersion = process.env._PACKAGE_VERSION_ as string;

export const COMMERCIAL_SENTRY_DSN =
	'https://1210cfc4338849588134f2c80b99f75c@o55978.ingest.sentry.io/5988824';
export const FEDRAMP_SENTRY_DSN =
	'https://a377d38318350e08236b26b7b13c8016@sentry.atlassian-us-gov-mod.com/23';

export interface ErrorExtensionDetails {
	type?: string;
	environmentType?: string;
	appOwnerAccountId?: string;
	id?: string;
	properties?: Record<string, any>;
}

export interface ErrorDetails {
	error: Error;
	environment: ProductEnvironment;
	errorInfo?: ErrorInfo;
	page: string;
	errorExtensionDetails?: ErrorExtensionDetails;
	tracing?: TracingService;
}

export const captureAndReportError = async ({
	error,
	environment,
	errorExtensionDetails,
	page,
	errorInfo,
	tracing,
}: ErrorDetails) => {
	try {
		const module = await import('@sentry/browser');
		const { BrowserClient, Hub } = module;

		const cdnEnv = getCDNEnvironment(environment);

		// From https://sentry.io/settings/atlassian-2y/projects/forge-ui/keys/ and https://sentry.prod.services.kitt-inf-us-gov-mod.net/settings/sentry/projects/forge-ui/keys

		const dsn =
			isFedRamp() && cdnEnv !== ForgeCDNEnvironment.FEDRAMP_SANDBOX
				? FEDRAMP_SENTRY_DSN
				: COMMERCIAL_SENTRY_DSN;

		const client = new BrowserClient({
			environment,
			dsn,
			release: `forge-ui@${packageVersion}`,
		});

		const hub = new Hub(client);

		hub.withScope(async (scope) => {
			if (tracing) {
				const breadcrumbs = tracing.getBreadcrumbs();
				breadcrumbs.forEach((bc) => scope.addBreadcrumb(bc, 20));
			}

			if (errorInfo) {
				scope.setExtra('componentTrace', {
					errorInfo,
					componentStack: errorInfo?.componentStack?.substring(0, 1000),
				});
			}

			scope.setTag('extensionPoint', page);

			if (errorExtensionDetails) {
				scope.setExtra('extensionProperties', errorExtensionDetails.properties);

				try {
					const appIdFromId = parseExtensionId(errorExtensionDetails.id ?? '').appId;
					if (appIdFromId) {
						scope.setTag('appId', appIdFromId);
					}
				} catch (e) {
					// parseExtensionId() uses parse() form @atlassian/cs-ari, which throws an error if the ID is not valid
				}
				if (errorExtensionDetails.environmentType) {
					scope.setTag('forgeEnvironment', errorExtensionDetails.environmentType);
				}
				if (errorExtensionDetails.type) {
					scope.setTag('extensionType', errorExtensionDetails.type);
				}
				if (errorExtensionDetails.appOwnerAccountId) {
					scope.setTag('appOwner', errorExtensionDetails.appOwnerAccountId);
				}
			}
			hub.captureException(error);
		});
		return client.close();
	} catch (e) {
		// Error reporting failed, we don't want to cause a unhandledrejection event
	}
};

export { TracingProvider, useTracingContext } from './tracing';
export type { TracingService } from './tracing';
