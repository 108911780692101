import { useContext, useEffect, useState } from 'react';

import { useSessionData } from '@confluence/session-data';
import {
	ExperienceTrackerContext,
	VIEW_PUBLIC_LINK_SHARED_PAGE_EXPERIENCE,
} from '@confluence/experience-tracker';
import { BadStatusError, cfetch } from '@confluence/network';

import { UserTypes } from './UserTypes';

const checkDomainApproval = async (cloudId: string, userId: string, onError: (_) => void) => {
	enum OrgMemberCapabilityType {
		DIRECT_ACCESS = 'DIRECT_ACCESS',
		REQUEST_ACCESS = 'REQUEST_ACCESS',
		PENDING_REQUEST_EXISTS = 'PENDING_REQUEST_EXISTS',
	}
	const params = new URLSearchParams({
		resource: `ari:cloud:confluence::site/${cloudId}`,
	});
	let hasCapabilities = false;
	try {
		const response = await cfetch(
			`/gateway/api/invitations/v1/access-requests/capabilities?${params.toString()}`,
			{
				method: 'GET',
				headers: {
					resource: `ari:cloud:confluence::site/${cloudId}`,
					ACCEPT: 'application/json',
					'Account-Id': userId,
				},
			},
		);
		const data = await response.json();
		if ((Object.values(data.results)[0] as string) in OrgMemberCapabilityType) {
			hasCapabilities = true;
		}
	} catch (e) {
		const response = BadStatusError.isBadStatusError(e) ? e.response : undefined;
		const error = new Error(
			`Error in getting approved domains, status = ${response?.status}, message = "${response?.statusText}"`,
		);
		onError(error);
	}
	return hasCapabilities;
};

export const useWithUserTypes = (isExternalShareRequest: boolean = false) => {
	const { isLicensed, cloudId, userId } = useSessionData();
	const experienceTracker = useContext(ExperienceTrackerContext);
	const [isApprovedOrgMember, setIsApprovedOrgMember] = useState(false);

	useEffect(() => {
		const validateDomain = async () => {
			if (userId && !isLicensed && isExternalShareRequest) {
				const isApproved = await checkDomainApproval(cloudId, userId, (error) => {
					experienceTracker.fail({
						name: VIEW_PUBLIC_LINK_SHARED_PAGE_EXPERIENCE,
						error,
					});
				});
				isApproved && setIsApprovedOrgMember(isApproved);
			}
		};
		void validateDomain();
	}, [userId, isLicensed, cloudId, experienceTracker, isExternalShareRequest]);

	if (!userId) return { userType: UserTypes.ANONYMOUS };
	if (isLicensed) return { userType: UserTypes.CONFLUENCE_USER };
	return {
		userType: isApprovedOrgMember ? UserTypes.ORG_MEMBER : UserTypes.ATLASSIAN_USER,
	};
};
