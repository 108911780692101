/**
 * @jsxRuntime classic
 * @jsx jsx
 */
import {
	Fragment,
	useRef,
	type ReactNode,
	Suspense,
	useContext,
	useEffect,
	useMemo,
	useState,
	forwardRef,
} from 'react';

import { jsx } from '@compiled/react';
import { Box } from '@atlaskit/primitives/compiled';
import { cssMap } from '@atlaskit/css';
import { CURRENT_SURFACE_CSS_VAR, useThemeObserver } from '@atlaskit/tokens';

import { EnvironmentContext } from '../../context';
import { useCustomUITunnelsList } from '../../web-client';
import { parseExtensionId, useMemoizedStateObject } from '../../utils';

import { type CoreDataInner } from '@atlassian/forge-ui-types';

import stringify from 'fast-json-stable-stringify';

import { TracingProvider } from '../../error-reporting';
import {
	createSrcFromExtension,
	getBrowserLocale,
	getBrowserTimezone,
	getExtensionEntryPoint,
	getResolverContext,
	getSrcUrl,
	getViewContext,
} from './utils';
import type { HTMLCustomIFrameElement, ViewContext, IframeProps } from './types';
import { InnerIframe } from './InnerIframe';
import { getLoadingComponent } from './utils/getLoadingComponent';
import { useForgeUiAnalyticsEvent } from '../../analytics/useForgeUiAnalyticsEvent';
import { getExtensionType } from '../../utils/getExtensionType';
import { fg } from '@atlaskit/platform-feature-flags';
import { ContextChangeObserverWrapper } from './ContextChangeObserverWrapper';

const styles = cssMap({
	hostedResourcesIframeContainer: {
		position: 'relative',
	},
	box: {
		width: '100%',
		height: '100%',
	},
});

const ReloadOnContextChangeWrapper = ({
	context,
	children,
	getCurrentForgeReactMajorVersion,
}: {
	context: ViewContext;
	children: ReactNode;
	getCurrentForgeReactMajorVersion: IframeProps['getCurrentForgeReactMajorVersion'];
}) => {
	// eslint-disable-next-line @atlaskit/platform/ensure-feature-flag-prefix
	if (getCurrentForgeReactMajorVersion && fg('forge-ui-macro-config-update-improvement')) {
		return (
			<ContextChangeObserverWrapper
				context={context}
				getCurrentForgeReactMajorVersion={getCurrentForgeReactMajorVersion}
			>
				{children}
			</ContextChangeObserverWrapper>
		);
	}
	return <Fragment key={stringify(context)}>{children}</Fragment>;
};

export const Iframe = forwardRef<HTMLCustomIFrameElement, IframeProps>(
	(
		{
			accountId,
			extension,
			contextIds,
			apolloClient,
			components,
			coreData,
			extensionData,
			extensionViewData,
			loadingComponent,
			onLoad,
			height = '100%',
			width = '100%',
			bridge,
			customBridgeMethods,
			isResizable = true,
			entryPoint,
			extensionPayload,
			getContextToken,
			timezone,
			locale,
			isHidden = false,
			isInModal = false,
			setShouldShowRenderer,
			setIsLoading,
			getCurrentForgeReactMajorVersion,
			onError,
		},
		iframeRef,
	) => {
		const environment = useContext(EnvironmentContext);

		// ARKEN-856: useThemeObserver() is a bit noisy, it returns copies of the same theme which breaks useBridge() dependencies
		// We use useMemoizedStateObject() to properly memoize its output doing a deep-equality check
		const theme = useMemoizedStateObject(useThemeObserver());
		const iframeWrapperRef = useRef<HTMLDivElement>(null);

		const [surfaceColor, setSurfaceColor] = useState<string | null>(null);
		const { trackExtensionLoaded } = useForgeUiAnalyticsEvent();

		useEffect(() => {
			if (!iframeWrapperRef.current) {
				return;
			}
			const nextSurfaceColor = getComputedStyle(iframeWrapperRef.current).getPropertyValue(
				CURRENT_SURFACE_CSS_VAR,
			);

			setSurfaceColor(nextSurfaceColor);
		}, [theme]);

		const { appId, moduleKey } = parseExtensionId(extension.id);
		const { environmentId, environmentType } = extension;

		const extensionEntryPoint = getExtensionEntryPoint(extension, entryPoint);

		const defaultSrc = useMemo(
			() => createSrcFromExtension(appId, extension, environment, extensionEntryPoint),
			[appId, extension, environment, extensionEntryPoint],
		);

		const { tunnels, loading } = useCustomUITunnelsList({
			client: apolloClient,
			appId,
			environmentId,
			environmentType,
		});

		useEffect(() => {
			if (extension?.properties?.render !== 'native') {
				const extensionType = getExtensionType(extension);
				trackExtensionLoaded({
					forgeEnvironment: extension?.environmentType,
					renderType: 'CustomUI',
					extensionType,
					isConfiguring: extensionData.macro?.isConfiguring,
					isInserting: extensionData.macro?.isInserting,
				});
			}
			// Adding `extension` itself or `trackExtensionLoaded` will cause an infinite loop
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [extension?.environmentType, extension?.properties?.render, extension?.type]);

		const [isThreeLOPromptVisible, setIsThreeLOPromptVisible] = useState(false);

		const coreDataInner: CoreDataInner = {
			...coreData,
			environmentId,
			environmentType,
			moduleKey,
			siteUrl: window.location.origin,
		};

		const resizingEnabled = isResizable && !extension.properties.viewportSize;

		return (
			<Box
				ref={iframeWrapperRef}
				testId="hosted-resources-iframe-container"
				xcss={styles.hostedResourcesIframeContainer}
				style={{
					// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
					minHeight: isThreeLOPromptVisible || isHidden ? undefined : height,
					// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
					height: resizingEnabled || isThreeLOPromptVisible || isHidden ? undefined : height,
					// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
					width,
				}}
			>
				{loading && (
					<Suspense fallback={null}>
						<Box xcss={styles.box}>
							{/* This hides another loading spinner we have that is shown while we are fetching the list of current active tunnels.
              This is not needed for UI Kit 2 (where isHidden = true) since we’ll already show a loading spinner with the RendererNext component */}
							{loading && !isHidden && getLoadingComponent(loadingComponent)}
						</Box>
					</Suspense>
				)}
				{!loading && (
					<TracingProvider>
						<ReloadOnContextChangeWrapper
							getCurrentForgeReactMajorVersion={getCurrentForgeReactMajorVersion}
							context={getViewContext({
								resolverContext: getResolverContext(coreDataInner, extensionData),
								accountId,
								extension,
								timezone: timezone ?? getBrowserTimezone(),
								locale: locale ?? getBrowserLocale(),
								extensionViewData,
							})}
						>
							<InnerIframe
								accountId={accountId}
								extension={extension}
								contextIds={contextIds}
								apolloClient={apolloClient}
								components={components}
								coreData={coreDataInner}
								extensionData={extensionData}
								extensionViewData={extensionViewData}
								loadingComponent={loadingComponent}
								onLoad={onLoad}
								height={height}
								width={width}
								src={getSrcUrl(tunnels, extensionEntryPoint, defaultSrc)}
								bridge={bridge}
								customBridgeMethods={customBridgeMethods}
								isResizable={resizingEnabled}
								getContextToken={getContextToken}
								timezone={timezone}
								locale={locale}
								setIsThreeLOPromptVisible={setIsThreeLOPromptVisible}
								setShouldShowRenderer={setShouldShowRenderer}
								extensionPayload={extensionPayload}
								isHidden={isHidden}
								theme={theme}
								surfaceColor={surfaceColor}
								appId={appId}
								ref={iframeRef}
								isInModal={isInModal}
								setIsLoading={setIsLoading}
								onError={onError}
							/>
						</ReloadOnContextChangeWrapper>
					</TracingProvider>
				)}
			</Box>
		);
	},
);
