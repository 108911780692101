import { query } from '@confluence/query-preloader-tools';
import { fg } from '@confluence/feature-gating';

import { LabelsQuery } from './LabelsQuery.graphql';
import { ALLOWED_CONTENT_STATUSES } from '../constants';

export function preloadLabels({ contentId }: { contentId: string }) {
	if (!contentId || !fg('confluence_frontend_preload_labels')) {
		return Promise.resolve();
	}

	return query({
		query: LabelsQuery,
		variables: {
			contentId,
			status: ALLOWED_CONTENT_STATUSES,
			refetchQuery: false,
		},
	});
}
