import { adaptEventHandler } from '../../../utils';
import { type ComponentPropsConfig } from '../../../utils/codegen';

export const componentPropsConfig: ComponentPropsConfig = {
	day: true,
	defaultDay: true,
	defaultMonth: true,
	defaultYear: true,
	defaultPreviouslySelected: true,
	defaultSelected: true,
	disabled: true,
	disabledDateFilter: false,
	maxDate: true,
	minDate: true,
	nextMonthLabel: true,
	onBlur: { value: "adaptEventHandler(onBlur)" },
	onChange: true,
	onFocus: { value: "adaptEventHandler(onFocus)" },
	onSelect: true,
	previouslySelected: true,
	previousMonthLabel: true,
	selected: true,
	today: true,
	year: true,
	locale: true,
	testId: true,
	weekStartDay: true,
	tabIndex: true,
};

/** These props have been excluded:
 * - style
 * - className
 * - analyticsContext
 * - disabledDateFilter - this prop is not working due to same issue as DatePicker. The function definition is unable to be retreived from WebRuntime.
 * /
/**
 * THIS SECTION WAS CREATED VIA CODEGEN DO NOT MODIFY {@see http://go/af-codegen}
 *
 * This component was generated against an existing platform component.
 * If you are seeing lint errors in this file, it means that this file's dependencies have been modified and the component will need to be regenerated.
 *
 * Please view https://hello.atlassian.net/wiki/spaces/ECO/pages/3358130330/How+to+Regenerate+UI+Kit+Components for more information on how to proceed.
 *
 * For further questions, please ping the #help-forge-ui channel.
 *
 * @codegen <<SignedSource::c674e3ffb512f9147c7d6eeedeaabefa>>
 * @codegenId #calendar
 * @codegenCommand yarn workspace @atlassian/forge-ui codegen calendar
 * @codegenDependency ../../../../../../../design-system/calendar/src/types.tsx <<SignedSource::8f13c1d59297b971f50a7ce6cbb460a3>>
 * @codegenDependency ../../../../../../../analytics/analytics-next/src/hocs/withAnalyticsEvents.tsx <<SignedSource::912bf75b9266c0c95b2e6bf4d8e3c915>>
 * @codegenDependency ../../../../../../../design-system/calendar/src/internal/components/header.tsx <<SignedSource::73ee6fd7688465620d1cbdd40c861963>>
 * @codegenDependency ../../../../../../../design-system/calendar/src/internal/components/week-days.tsx <<SignedSource::098729ff889bf403081b53fcb6e84750>>
 * @codegenDependency ../../../../../../../design-system/calendar/src/internal/components/week-header.tsx <<SignedSource::cf9e0db1acbd5029099a274c695e8d8f>>
 * @codegenDependency ../../../../../../../design-system/calendar/src/internal/constants.tsx <<SignedSource::424b99d3c4a1defe44a9a72afb0efe10>>
 * @codegenDependency ../../../../../../../design-system/calendar/src/internal/hooks/use-controlled-date-state.tsx <<SignedSource::cd116eed14ef9663eebc95f3f575584c>>
 * @codegenDependency ../../../../../../../design-system/calendar/src/internal/hooks/use-focusing.tsx <<SignedSource::59fa58a7ccc2b3da832b45e013e5b415>>
 * @codegenDependency ../../../../../../../design-system/calendar/src/internal/hooks/use-get-weeks.tsx <<SignedSource::248aa0401505c425531ebe2331896859>>
 * @codegenDependency ../../../../../../../design-system/calendar/src/internal/hooks/use-handle-date-change.tsx <<SignedSource::18e3427402d1c3e454fcba6844f6c8e0>>
 * @codegenDependency ../../../../../../../design-system/calendar/src/internal/hooks/use-handle-date-select.tsx <<SignedSource::c7b8e7a565d7f23670c415295a286ca9>>
 * @codegenDependency ../../../../../../../design-system/calendar/src/internal/hooks/use-locale.tsx <<SignedSource::9b5fc02a9dc7162af69a44b4ae423161>>
 * @codegenDependency ../../../../../../../design-system/calendar/src/calendar.tsx <<SignedSource::1f8661148707c879fea374a117c6fea8>>
 */
/* eslint @repo/internal/codegen/signed-source-integrity: "warn" */
import React from 'react';
import type { RenderFn } from '@atlassian/forge-ui-types';
import PlatformCalendar from '@atlaskit/calendar';

type PlatformCalendarProps = React.ComponentProps<typeof PlatformCalendar>;

export type CalendarProps = Pick<
  PlatformCalendarProps,
  'day' | 'defaultDay' | 'defaultMonth' | 'defaultYear' | 'defaultPreviouslySelected' | 'defaultSelected' | 'disabled' | 'maxDate' | 'minDate' | 'nextMonthLabel' | 'onBlur' | 'onChange' | 'onFocus' | 'onSelect' | 'previouslySelected' | 'previousMonthLabel' | 'selected' | 'today' | 'year' | 'locale' | 'testId' | 'weekStartDay' | 'tabIndex'
>;

/**
 * An interactive calendar for date selection experiences.
 */
export const Calendar = (props: Parameters<RenderFn>[0]) => {
  const {
    day,
    defaultDay,
    defaultMonth,
    defaultYear,
    defaultPreviouslySelected,
    defaultSelected,
    disabled,
    maxDate,
    minDate,
    nextMonthLabel,
    onBlur,
    onChange,
    onFocus,
    onSelect,
    previouslySelected,
    previousMonthLabel,
    selected,
    today,
    year,
    locale,
    testId,
    weekStartDay,
    tabIndex,
  } = props.forgeDoc.props as CalendarProps;
  return (
    <PlatformCalendar
      day={day}
      defaultDay={defaultDay}
      defaultMonth={defaultMonth}
      defaultYear={defaultYear}
      defaultPreviouslySelected={defaultPreviouslySelected}
      defaultSelected={defaultSelected}
      disabled={disabled}
      maxDate={maxDate}
      minDate={minDate}
      nextMonthLabel={nextMonthLabel}
      onBlur={adaptEventHandler(onBlur)}
      onChange={onChange}
      onFocus={adaptEventHandler(onFocus)}
      onSelect={onSelect}
      previouslySelected={previouslySelected}
      previousMonthLabel={previousMonthLabel}
      selected={selected}
      today={today}
      year={year}
      locale={locale}
      testId={testId}
      weekStartDay={weekStartDay}
      tabIndex={tabIndex}
    />
  );
};
/**
 * @codegenEnd
 */
