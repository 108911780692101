import React from 'react';
import type { IntlShape } from 'react-intl-next';
import { defineMessages } from 'react-intl-next';

import { DefaultExtensionProvider } from '@atlaskit/editor-common/extensions';
import { token } from '@atlaskit/tokens';

import { LoadableAfterPaint } from '@confluence/loadable';

import {
	type CustomSitesExtensionProviderProps,
	getExtensionManifest,
} from '../shared-components/customSitesManifestBuilder';

import type { SpotlightParameters as SpotlightParametersType } from './spotlightTypes';
import {
	SpotlightButtonStyle,
	SpotlightHeight,
	SpotlightCornerStyle,
	SpotlightLayout,
} from './spotlightTypes';
import { spotlightExtensionKey, spotlightExtensionType } from './spotlightConstants';

const SpotlightExtension = LoadableAfterPaint({
	loader: async () =>
		(await import(/* webpackChunkName: "loadable-SpotlightExtension" */ './SpotlightExtension'))
			.SpotlightExtension,
});

const i18n = defineMessages({
	title: {
		id: 'custom-sites-extensions.spotlight.manifest.macro-title',
		defaultMessage: 'Spotlight',
		description:
			'Title of spotlight extension to be displayed in toolbar/shortcut macros dropdown.',
	},
	description: {
		id: 'custom-sites-extensions.spotlight.manifest.macro-description',
		defaultMessage: 'Highlight important messages with a visual spotlight.',
		description:
			'Description of spotlight extension to be displayed in toolbar/shortcut macros dropdown',
	},
	titleNew: {
		id: 'custom-sites-extensions.spotlight.manifest.macro-title.new',
		defaultMessage: 'Spotlight [New]',
		description:
			'Title of spotlight extension to be displayed in toolbar/shortcut macros dropdown. The [New] text is a text label indicating that this feature is new.',
	},
	initialTitleParameter: {
		id: 'custom-sites-extensions.spotlight.manifest.initial-state.text-title',
		defaultMessage: 'Give your spotlight a title',
		description:
			'Initial text title parameter of spotlight extension to be displayed in the UI of the editor and view page.',
	},
	initialDescriptionParameter: {
		id: 'custom-sites-extensions.spotlight.manifest.initial-state.text-description',
		defaultMessage:
			'Design a spotlight to highlight your most vital information. Bring the most important messages to life with custom colors, a powerful image, and flexible layouts.',
		description:
			'Initial description parameter of spotlight extension to be displayed in the UI of the editor and view page.',
	},
	initialImageAltTextParameter: {
		id: 'custom-sites-extensions.spotlight.manifest.initial-state.alt-image-text',
		defaultMessage: 'Landscape photography of desert',
		description:
			'Initial image alt text parameter of spotlight extension to be displayed in the UI of the editor and view page.',
	},
});

export const spotlightParameters = (intl: IntlShape): SpotlightParametersType => ({
	extensionTitle: intl.formatMessage(i18n.title),
	height: SpotlightHeight.MEDIUM,
	backgroundColor: token('color.background.accent.orange.subtlest'),
	cornerStyle: SpotlightCornerStyle.ROUNDED,
	layout: SpotlightLayout.SPLIT_IMAGE_RIGHT,
	content: {
		text: {
			title: intl.formatMessage(i18n.initialTitleParameter),
			description: intl.formatMessage(i18n.initialDescriptionParameter),
			textColor: token('color.text'),
		},
		button: {
			isButtonShown: false,
			text: '',
			link: '',
			style: SpotlightButtonStyle.DEFAULT,
		},
		image: {
			imageSrc:
				'https://images.unsplash.com/photo-1527519135413-1e146b552e10?q=80&w=2940&auto=format',
			altText: intl.formatMessage(i18n.initialImageAltTextParameter),
		},
	},
});

export const spotlightExtensionProvider = async ({
	editorActions,
	openConfigPanel,
	setUpsellModalSource,
	intl,
	contentId,
}: CustomSitesExtensionProviderProps) => {
	const title = intl.formatMessage(i18n.titleNew);
	const description = intl.formatMessage(i18n.description);
	const type = spotlightExtensionType;
	const render =
		async () =>
		({ node }) => <SpotlightExtension contentId={contentId} extensionNode={node} />;

	const icons = {
		// eslint-disable-next-line import/dynamic-import-chunkname
		'48': () =>
			import(
				/* webpackChunkName: "loadable-atlaskitIconCorePremium" */ '@atlaskit/icon/core/premium'
			).then(({ default: PremiumIcon }) => () => (
				<PremiumIcon color={token('color.icon.subtle')} label="" spacing="spacious" />
			)),
	};

	const parameters = spotlightParameters(intl);

	const update = async () => {
		const selectedNode = editorActions?.getSelectedNode()?.toJSON();
		const selectedLocalId = selectedNode?.attrs?.localId;

		if (!!selectedLocalId && openConfigPanel) {
			openConfigPanel(selectedLocalId);
		}
	};

	return new DefaultExtensionProvider([
		getExtensionManifest<SpotlightParametersType>({
			key: spotlightExtensionKey,
			icons,
			parameters,
			update,
			render,
			title,
			type,
			description,
			setUpsellModalSource,
			source: 'premiumSpotlightInsert',
		}),
	]);
};
