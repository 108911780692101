import { getMonitoringClient } from '@confluence/monitoring';
import { SSRMeasures } from '@confluence/action-measures';
import { cfetch } from '@confluence/network';
import { getSSRRenderInputs } from '@confluence/ssr-utilities';

import type { CookieCategories } from './types';

export const MONITORING_ATTRIBUTION = 'backbone';

export const preloadCookieCategories = async (): Promise<CookieCategories | null> => {
	if (window.__COOKIE_CONSENT_CATEGORIES__) {
		return window.__COOKIE_CONSENT_CATEGORIES__;
	}

	const { perimeter } = getSSRRenderInputs();

	// if we are in FedRAMP, don't preload the consent categories
	if (perimeter === 'fedramp-moderate') {
		return (window.__COOKIE_CONSENT_CATEGORIES__ = null);
	}

	return SSRMeasures.run('ssr-app/prepare/preloadQuery/fetch:CookieCategories', async () => {
		try {
			const res = await cfetch(
				`https://atlassian-cookies--categories.us-east-1.prod.public.atl-paas.net/categories_LOCAL_STORAGE.json`,
				{
					method: 'GET',
					credentials: 'omit',
					headers: {
						Accept: 'application/json',
					},
				},
			);
			if (!res.ok) {
				throw new Error(`Cannot load Cookie Consent Categories: ${res.statusText}`);
			}
			const result = await res.json();
			return (window.__COOKIE_CONSENT_CATEGORIES__ = result);
		} catch (e) {
			getMonitoringClient().submitError(e, {
				attribution: MONITORING_ATTRIBUTION,
			});
			return (window.__COOKIE_CONSENT_CATEGORIES__ = null);
		}
	});
};
