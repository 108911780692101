export enum ITEM {
	LINK = 'link',
	MENU = 'menu',
}
type BaseItemType = {
	itemId: string;
	type: ITEM;
	title: string;
};
export interface LinkItemType extends BaseItemType {
	type: ITEM.LINK;
	link: string;
}
export interface MenuItemType extends BaseItemType {
	type: ITEM.MENU;
	childItems?: ItemType[];
}
export enum ALIGNMENT {
	LEFT = 'left',
	CENTER = 'center',
	RIGHT = 'right',
}
export enum BORDER {
	NONE = 'none',
	ALL_SIDES = 'all_sides',
	BOTTOM = 'bottom',
}
export enum CORNERS {
	ROUND = 'round',
	SHARP = 'sharp',
}
export type Styles = {
	alignment: ALIGNMENT;
	fillColor: string;
	borderColor: string;
	border: BORDER;
	cornerStyle: CORNERS;
	textColor: string;
	highlightColor: string;
};
export type ItemType = MenuItemType | LinkItemType;
export type Items = ItemType[];
export type LinksMenuParameters = {
	version: string;
	items?: Items;
	styles: Styles;
};
export type ResolvedLinkDataObject = {
	data: {
		'atlassian:ari'?: string;
		icon?: {
			url?: string;
		};
	};
};
