import React, { useMemo } from 'react';
import type { ReactNode } from 'react';
import { useIntl } from 'react-intl';
import type { ApolloError } from 'apollo-client';

import EmptyState from '@atlaskit/empty-state';
import { LinkButton } from '@atlaskit/button/new';
import { Flex, xcss } from '@atlaskit/primitives';
import Link from '@atlaskit/link';

import { ADMIN_NEW_GLOBAL_PERMISSIONS } from '@confluence/named-routes';
import { isUnauthorizedError } from '@confluence/error-boundary';

import { hasStalePermissionsErrorMessage, i18n } from './createCompanyHubSpace';
import genericErrorSvg from './assets/GenericError.svg';
import restrictedErrorSvg from './assets/RestrictedError.svg';

const containerStyles = xcss({
	height: '90vh',
	paddingInline: 'space.200',
});

type HubErrorPageComponentProps = {
	error: ApolloError;
};

type ErrorConfig = {
	imageUrl: string;
	title: string;
	description: ReactNode;
	buttonLabel?: string;
	buttonLink?: string;
};

export const HubErrorPageComponent = ({ error }: HubErrorPageComponentProps) => {
	const { formatMessage } = useIntl();

	const errorConfig: ErrorConfig = useMemo(() => {
		if (hasStalePermissionsErrorMessage(error)) {
			return {
				imageUrl: restrictedErrorSvg,
				title: formatMessage(i18n.createCompanyHubSpaceStalePermissionsErrorFlagTitle),
				description: formatMessage(i18n.createCompanyHubSpaceStalePermissionsErrorFlagDescription, {
					a: (...chunks: ReactNode[]) => (
						<Link
							href="https://support.atlassian.com/confluence-cloud/docs/create-and-customize-a-company-hub"
							target="_blank"
							rel="noopener noreferrer"
						>
							{chunks}
						</Link>
					),
				}),
			};
		}

		if (isUnauthorizedError(error)) {
			return {
				imageUrl: restrictedErrorSvg,
				title: formatMessage(i18n.createCompanyHubSpacePermissionsErrorFlagTitle),
				description: formatMessage(i18n.createCompanyHubSpacePermissionsErrorFlagDescription, {
					b: (...chunks: ReactNode[]) => <b>{chunks}</b>,
				}),
				buttonLabel: formatMessage(i18n.createCompanyHubSpacePermissionsErrorFlagButton),
				buttonLink: ADMIN_NEW_GLOBAL_PERMISSIONS.toUrl(),
			};
		}

		return {
			imageUrl: genericErrorSvg,
			title: formatMessage(i18n.createCompanyHubSpaceErrorFlagTitle),
			description: formatMessage(i18n.createCompanyHubSpaceErrorFlagDescription),
		};
	}, [error, formatMessage]);

	const { imageUrl, title, description, buttonLabel, buttonLink } = errorConfig;

	return (
		<Flex justifyContent="center" alignItems="center" xcss={containerStyles}>
			<EmptyState
				header={title}
				description={description}
				primaryAction={
					buttonLabel && buttonLink ? (
						<LinkButton appearance="primary" href={buttonLink}>
							{buttonLabel}
						</LinkButton>
					) : undefined
				}
				imageUrl={imageUrl}
			/>
		</Flex>
	);
};
