import { LoadablePaint, MustPreloadLoadableNames } from '@confluence/loadable';

export const NativeCollabLoader = LoadablePaint({
	name: MustPreloadLoadableNames.NativeCollabLoader,
	loader: async () => {
		const { NativeCollabComponent } = await import(
			/* webpackChunkName: "loadable-NativeCollabComponent" */ './NativeCollabComponent'
		);
		return NativeCollabComponent;
	},
});

export { NativeCollabContext } from './NativeCollabContext';
export type { NativeCollabInjectedProps, CollabEditState } from './commonCollabTypes';
export type { CollabHeartbeatError } from './useEditorHeartbeat/useEditorHeartbeat';
export { isPermissionError as isHeartbeatPermissionError } from './useEditorHeartbeat/useEditorHeartbeat';
export { CollabRecoveryErrorType } from './useError';
export { CollabServiceProperty } from './commonCollabTypes';
export { InviteToEditButton, InviteToEditHelper } from './components/InviteToEditButton';
export { fetchCollabPermissionToken } from './fetchCollabPermissionToken';
export { NativeCollabQuery } from './NativeCollabQuery.graphql';
export type {
	NativeCollabQuery as NativeCollabQueryResponse,
	NativeCollabQueryVariables,
} from './__types__/NativeCollabQuery';
export { useDraftSyncAndPublishContext } from './DraftSyncAndPublishContext';
export { useConnected } from './useConnected';
export { useConnecting } from './useConnecting';
export {
	useNativeCollabConnected,
	useNativeCollabDraftSync,
	useNativeCollabState,
	NativeCollabStateContainer,
} from './useNativeCollabState';
export { nativeCollabPermissionsTokenState } from './CollabPermissionsTokenState';
export {
	useNativeCollabPresenceState,
	NativeCollabPresenceStateContainer,
} from './useNativeCollabPresenceState';
