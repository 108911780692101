import { createWrappedPreloader } from '@confluence/query-preloader-tools';

import { preloadHomeNavigation } from './HomeNavigation/preloadHomeNavigation';
import { preloadOverview } from './HomeViews/Overview/preloadOverview';
import { preloadRecent } from './HomeViews/Recent/preloadRecent';
import { preloadStarred } from './HomeViews/Starred/preloadStarred';
import { preloadDrafts } from './HomeViews/Drafts/preloadDrafts';
import { View } from './homeRouteParams';

type MatchParams = Record<string, string>;

type PreloadHomeOptions = {
	cloudId: string | null;
	isLicensed: boolean;
	userId: string | null;
	matchParams?: MatchParams;
	isWhiteboardViewedEnabled?: boolean;
	isDatabaseEnabled?: boolean;
};

const getSelectedView = (matchParams?: MatchParams) => {
	const view = matchParams?.view as View;
	return Object.values(View).includes(view) ? view : View.OVERVIEW;
};

const preloadSelectedView = ({
	cloudId,
	isLicensed,
	userId,
	matchParams,
	isWhiteboardViewedEnabled = false,
	isDatabaseEnabled = false,
}: PreloadHomeOptions) => {
	if (!cloudId) {
		return Promise.resolve({});
	}
	const isLoggedIn = Boolean(userId);

	const selectedView = getSelectedView(matchParams);
	switch (selectedView) {
		case View.RECENT:
			return preloadRecent({
				cloudId,
				isLoggedIn,
				isWhiteboardViewedEnabled,
			});
		case View.STARRED:
			return preloadStarred({ isLoggedIn });
		case View.DRAFTS:
			return preloadDrafts({ isLoggedIn });
		case View.OVERVIEW:
		default:
			return preloadOverview({
				userId,
				cloudId,
				isLicensed,
				isWhiteboardViewedEnabled,
				isDatabaseEnabled,
			});
	}
};

export const preloadHomeShell = (options: PreloadHomeOptions) => {
	return preloadHomeNavigation({
		isLoggedIn: Boolean(options.userId),
		isLicensed: options.isLicensed,
	}).then(() => ({ data: {} }));
};

export const preloadHomeMainContent = (options: PreloadHomeOptions) => {
	return createWrappedPreloader({
		promise: preloadSelectedView(options),
		preloaderName: `preloadHomeSelectedView`,
	});
};

export const preloadHome = (options: PreloadHomeOptions) => {
	return Promise.all([
		preloadHomeNavigation({
			isLoggedIn: Boolean(options.userId),
			isLicensed: options.isLicensed,
		}),
		preloadSelectedView(options),
	]).then(() => ({ data: {} }));
};
