import type { FC } from 'react';
import React from 'react';
import type { QueryResult } from 'react-apollo';
import { ApolloProvider } from 'react-apollo';
import { useQuery } from '@apollo/react-hooks';

import { getApolloClient } from '@confluence/graphql';
import {
	MacroExperienceSuccess,
	MacroExperienceFailure,
	getExperienceName,
	getMacroAttributesFromADFNode,
} from '@confluence/macro-tracker';
import type { MacroExperienceBase } from '@confluence/macro-tracker';
/* eslint-disable no-restricted-imports */
import {
	ExperienceTrackerContext,
	getExperienceTracker, // eslint-disable-line no-restricted-imports
} from '@confluence/experience-tracker';

import type { InlineImageProps } from './types';
import { InlineImageQuery } from './InlineImageQuery.graphql';
import { InlineImageComponent } from './InlineImageComponent';

const InlineImageWithApolloProvider: FC<InlineImageProps & MacroExperienceBase> = ({
	link,
	id,
	collection,
	contentId,
	mode,
	extensionKey,
	node,
	...props
}: InlineImageProps & MacroExperienceBase) => {
	const { data, loading, error }: QueryResult = useQuery(
		// eslint-disable-next-line graphql-relay-compat/no-import-graphql-operations -- Read https://go/connie-relay-migration-fyi
		InlineImageQuery,
		{
			variables: { contentId },
		},
	);

	if (loading) {
		return null;
	}

	const token = data?.content?.nodes?.[0]?.body?.atlas_doc_format?.mediaToken?.token;
	const clientId = data?.mediaConfiguration?.clientId;
	const fileStoreUrl = data?.mediaConfiguration?.fileStoreUrl;
	const attributes = getMacroAttributesFromADFNode(node);

	return (
		// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
		<div data-testid="inline-image" style={{ display: 'inline-block' }}>
			<InlineImageComponent
				{...props}
				id={id}
				collection={collection}
				link={link}
				token={token}
				clientId={clientId}
				baseUrl={fileStoreUrl}
			/>
			{error ? (
				<MacroExperienceFailure
					name={getExperienceName(mode, node)}
					contentId={contentId}
					extensionKey={extensionKey}
					mode={mode}
					error={
						error || new Error(`${extensionKey} failed to render. InlineImage query data is empty`)
					}
					attributes={attributes}
					source="InlineImage"
				/>
			) : (
				<MacroExperienceSuccess
					name={getExperienceName(mode, node)}
					contentId={contentId}
					extensionKey={extensionKey}
					mode={mode}
					attributes={attributes}
				/>
			)}
		</div>
	);
};

export const InlineImage = (props) => (
	<ApolloProvider client={getApolloClient()}>
		{/* eslint-disable-next-line no-restricted-imports */}
		<ExperienceTrackerContext.Provider value={getExperienceTracker()}>
			<InlineImageWithApolloProvider {...props} />
		</ExperienceTrackerContext.Provider>
	</ApolloProvider>
);
