import { query } from '@confluence/query-preloader-tools';

import { CatchupContentType } from './__types__/CatchupLastViewedTimeQuery';
import { CatchupLastViewedTimeQuery } from './CatchupLastViewedTimeQuery.graphql';

export function preloadAIFloatingContextMenu(contentId: string, isBlog: boolean) {
	const contentType = isBlog ? CatchupContentType.BLOGPOST : CatchupContentType.PAGE;
	return query({
		query: CatchupLastViewedTimeQuery,
		variables: {
			contentId,
			contentType,
		},
	});
}
