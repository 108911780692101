import React from 'react';

import { aiConfigItemMessages } from '@atlaskit/editor-common/ai-messages';
import { fg } from '@atlaskit/platform-feature-flags';

import { MakeShorterIcon } from '../../../../icons/prompt/makeShorter';
import { createInsertBelow, createReplace } from '../../actions/create-actions';
import { CONFIG_ITEM_KEYS } from '../../config-item-keys';
import type {
	EditorPluginAIConfigItemWithOptions,
	EditorPluginAIConfigItem,
} from '../../config-items';

export const makeShorter: EditorPluginAIConfigItem = {
	key: CONFIG_ITEM_KEYS.MAKE_SHORTER,
	title: aiConfigItemMessages.makeShorterTitle,
	description: aiConfigItemMessages.makeShorterDescription,
	icon: () => <MakeShorterIcon />,
	showInRefineDropdown: true,
	intentSchemaId: 'shorten_intent_schema.json',
	actions: {
		empty: [
			createInsertBelow({ appearance: 'secondary' }),
			createReplace({ appearance: 'primary' }),
		],
		selection: [
			createInsertBelow({ appearance: 'secondary' }),
			createReplace({ appearance: 'primary' }),
		],
	},
	getBackendModel: () => {
		if (
			fg('platform_editor_ai_assistance_service') ||
			fg('platform_editor_ai_assistance_service_second')
		) {
			return 'assistance-service';
		}

		return 'xp-gen-ai';
	},
};

export const makeShorterWithOptions: EditorPluginAIConfigItemWithOptions = {
	config: makeShorter,
	triggers: { empty: { enabled: true, docMinSize: 1 }, selection: { enabled: true } },
};
