import { useQuery } from '@apollo/react-hooks';

import { getMonitoringClient } from '@confluence/monitoring';
import { Attribution, isUnauthorizedError } from '@confluence/error-boundary';
import { markErrorAsHandled } from '@confluence/graphql';

import { getIsProductAdminFromQuery } from './getIsProductAdminFromQuery';
import { UseIsProductAdminQuery } from './UseIsProductAdminQuery.graphql';
import type { UseIsProductAdminQuery as UseIsProductAdminQueryType } from './__types__/UseIsProductAdminQuery';

export const useIsProductAdmin = () => {
	const {
		data,
		error,
		loading: isAdminCheckLoading,
	} = useQuery<UseIsProductAdminQueryType>(
		// eslint-disable-next-line graphql-relay-compat/no-import-graphql-operations -- Read https://go/connie-relay-migration-fyi
		UseIsProductAdminQuery,
	);

	if (error) {
		if (!isUnauthorizedError(error)) {
			getMonitoringClient().submitError(error, {
				attribution: Attribution.ADMIN_EXPERIENCE,
			});
		}
		markErrorAsHandled(error);
	}

	const isProductAdmin = getIsProductAdminFromQuery(data);

	return { isProductAdmin, isAdminCheckLoading };
};
