import { LoadablePaint, MustPreloadLoadableNames } from '@confluence/loadable';

export { useEditorFullPageExperiments } from '../src/hooks/useEditorFullPageExperiments';

export { TreeRootForServerPlaceholderWrapper } from './TreeRootForServerPlaceholderWrapper';

export const FullPageEditorLoader = LoadablePaint({
	name: MustPreloadLoadableNames.FullPageEditorLoader,
	loader: async () => {
		const { FullPageEditorComponent } = await import(
			/* webpackChunkName: "loadable-FullPageEditorComponent" */ './FullPageEditorComponent'
		);
		return FullPageEditorComponent;
	},
});
