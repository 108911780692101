import { query } from '@confluence/query-preloader-tools';

import { AudienceSegmentationContextProviderQuery } from './AudienceSegmentationContextProviderQuery.graphql';

export function preloadAudienceSegmentationContext({ accountId }: { accountId: string }) {
	return query({
		query: AudienceSegmentationContextProviderQuery,
		variables: {
			accountId,
		},
	});
}
