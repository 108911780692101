import { sendPackageOperationalEvent } from '../../../common/utils';
import { isProcessingAllowedByPreferences } from '../../../common/utils/item-processing';
import { Logger } from '../../../common/utils/logger';
import { BSCIndexedDB } from '../../../services/indexed-db-service';
import { CategoryStorageType, Status } from '../../../types';
import { WebStorageType } from '../types';

// SET COOKIE ANALOGUE
/**
 * Sets a WebStorage item asynchronously. This queries user preferences before setting
 * the item, so it must be used for any items which are not strictly necessary.
 */
export const setStorageItem = async (
	itemKey: string,
	value: string,
	storageType: WebStorageType,
	productName: string | undefined,
	browserStorage: Storage,
): Promise<Status> => {
	// user belongs to an org that opted into cookies management and we should respect their preferences

	// Flag enabled, user belongs to an org that opted into cookies management and we should respect their preferences
	const shouldSetItem = await isProcessingAllowedByPreferences({
		itemKey,
		storageType:
			storageType === WebStorageType.LocalStorage
				? CategoryStorageType.LocalStorage
				: CategoryStorageType.SessionStorage,
		productName,
	});

	const eventAttributes = {
		wasRejected: !shouldSetItem,
		itemKey,
		storageType,
	};
	try {
		sendPackageOperationalEvent({
			action: 'usedAtlBrowserStorageSetItem',
			attributes: eventAttributes,
		});

		if (shouldSetItem) {
			// Store the item as a record in our IDB service to track that we've seen it before.
			// That way, when we can confirm/deny that this has been set by our service when we check again.
			// If it fails for some reason, it will be caught before actually setting the item
			await BSCIndexedDB.setRecord(itemKey);
			browserStorage.setItem(itemKey, value);
			return Status.SUCCESS;
		} else {
			return Status.BLOCKED;
		}
	} catch (e: any) {
		Logger.errorWithOperationalEvent({
			action: 'usedAtlBrowserStorageSetItemError',
			attributes: eventAttributes,
			message: `Failed to set ${storageType} item. ${e.message || ''}`,
		});
		return Status.BLOCKED;
	}
};
