import type { ContainerList, ContainerData } from '@atlassiansox/cross-flow-plugins';
import { createGetContainersPlugin, ContainerTypes } from '@atlassiansox/cross-flow-plugins';

import { getApolloClient } from '@confluence/graphql';
import { SiteInformationQuery } from '@confluence/session-data';
import type { SPAViewContextQueryType } from '@confluence/spa-view-context';
import { SPAViewContextQuery } from '@confluence/spa-view-context';
import type { SiteInformationQueryType } from '@confluence/session-data';

interface SiteSettings {
	siteSettings: {
		siteTitle: string;
	};
	tenant: {
		cloudId: string;
	};
}

function hasSiteSettings(data: unknown): data is SiteSettings {
	return data && typeof data === 'object' && data['siteSettings'] && data['tenant'];
}

const getCloudId = async (): Promise<ContainerList> => {
	const isSiteInformationQueryEnabled = window['__ENABLE_SITE_INFORMATION_QUERY__'];
	const { data } = await getApolloClient().query<
		SiteInformationQueryType | SPAViewContextQueryType
	>({
		// eslint-disable-next-line graphql-relay-compat/no-import-graphql-operations -- Read https://go/connie-relay-migration-fyi
		query: isSiteInformationQueryEnabled ? SiteInformationQuery : SPAViewContextQuery,
	});
	const containers: ContainerData[] = [];
	if (hasSiteSettings(data)) {
		const { siteTitle } = data.siteSettings;
		const tenantId = data.tenant.cloudId;

		containers.push({
			id: tenantId,
			displayName: siteTitle,
			type: ContainerTypes.ATLASSIAN_CLOUD_ID,
		});
	}

	return {
		type: ContainerTypes.ATLASSIAN_CLOUD_ID,
		containers,
	};
};

export const getContainersPlugin = createGetContainersPlugin(getCloudId);
