import React from 'react';
import { css } from '@compiled/react';

import Image from '@atlaskit/image';

const imageStylesMobile = css({
	width: '68%',
});

const imageStylesDesktop = css({
	width: 'unset',
});

const imageStyles = css({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles
	maxWidth: 'unset !important',
	margin: '0 auto',
});

type ImageProps = {
	isMobile: boolean;
	src: string;
	srcDark?: string;
};

export function ErrorPageImage({ src, srcDark, isMobile }: ImageProps) {
	return (
		<Image
			alt=""
			src={src}
			srcDark={srcDark || src}
			css={[isMobile && imageStylesMobile, !isMobile && imageStylesDesktop, imageStyles]}
		/>
	);
}
