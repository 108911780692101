import React from 'react';
import type { RenderFn } from '@atlassian/forge-ui-types';
import PlatformTextarea from '@atlaskit/textarea';
import { adaptEventHandler } from '../../utils';
import type { EventHandlerProps } from '../types';
import { useCursorState } from '../../utils/useCursorState';

type PlatformTextareaProps = React.ComponentProps<typeof PlatformTextarea>;

export type TextAreaProps = Pick<
	PlatformTextareaProps,
	| 'appearance'
	| 'defaultValue'
	| 'isCompact'
	| 'isMonospaced'
	| 'isReadOnly'
	| 'maxHeight'
	| 'minimumRows'
	| 'placeholder'
	| 'resize'
	| 'spellCheck'
	| 'testId'
	| 'maxLength'
	| 'minLength'
	| 'autoFocus'
	| 'id'
	| 'isRequired'
	| 'isDisabled'
	| 'isInvalid'
	| 'value'
	| 'aria-invalid'
	| 'aria-labelledby'
	| 'name'
> &
	Pick<EventHandlerProps, 'onChange' | 'onBlur' | 'onFocus'>;

/**
 * A text area lets users enter long form text which spans over multiple lines.
 */
export const TextArea = (props: Parameters<RenderFn>[0]) => {
	const {
		appearance,
		defaultValue,
		isCompact,
		isMonospaced,
		isReadOnly,
		maxHeight,
		minimumRows,
		placeholder,
		resize,
		spellCheck,
		testId,
		maxLength,
		minLength,
		autoFocus,
		// Field props
		id,
		isRequired,
		isDisabled,
		isInvalid,
		onChange,
		onBlur,
		onFocus,
		value,
		'aria-invalid': ariaInvalid,
		'aria-labelledby': ariaLabelledby,
		name,
	} = props.forgeDoc.props as TextAreaProps;

	const { onChangeWithCursorState, inputRef } = useCursorState(value, onChange);

	return (
		<PlatformTextarea
			// @ts-ignore type from ADS is missing the ref object. It is still supported
			ref={inputRef}
			appearance={appearance}
			defaultValue={defaultValue}
			isCompact={isCompact}
			isMonospaced={isMonospaced}
			isReadOnly={isReadOnly}
			maxHeight={maxHeight}
			minimumRows={minimumRows}
			placeholder={placeholder}
			resize={resize}
			spellCheck={spellCheck}
			testId={testId}
			maxLength={maxLength}
			minLength={minLength}
			autoFocus={autoFocus}
			// Field props
			id={id}
			isRequired={isRequired}
			isDisabled={isDisabled}
			isInvalid={isInvalid}
			onChange={adaptEventHandler(onChangeWithCursorState)}
			onBlur={adaptEventHandler(onBlur)}
			onFocus={adaptEventHandler(onFocus)}
			value={value}
			aria-invalid={ariaInvalid}
			aria-labelledby={ariaLabelledby}
			name={name}
		/>
	);
};
