import type { ReactNode } from 'react';
import React from 'react';
import type { RenderFn } from '@atlassian/forge-ui-types';

export type FormProps = {
	/**
	 * Event handler called when the form is submitted. Fields must be free of validation errors.
	 *
	 * All modules except for `jiraServiceManagement:assetsImportType` must use `() => Promise<void>|void`.
	 *
	 * For the `jiraServiceManagement:assetsImportType` module, the `onSubmit` event handler returns
	 * a `boolean` value indicating if the form is valid (`true`) or invalid (`false`).
	 * If you use `() => Promise<void>|void` for this module, it will default to true.
	 */
	onSubmit: () => Promise<void | boolean> | void;
	children: ReactNode;
};

/**
 * A form allows users to input information.
 */
export const Form = (props: Parameters<RenderFn>[0]) => {
	const { onSubmit } = props.forgeDoc.props as FormProps;
	return (
		<form
			onSubmit={(e) => {
				e.preventDefault();
				onSubmit();
			}}
		>
			{props.forgeDoc.children.map(props.render)}
		</form>
	);
};

export const FormContext = React.createContext<{
	formValues: Record<string, any>;
	setFormValue: (name: string, value: any) => void;
} | null>(null);
