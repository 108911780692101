import { type ComponentPropsConfig } from '../../../utils/codegen';

/**
 * An error message is used to tell a user that the field input is invalid.
 * For example, an error message could be "Invalid username, needs to be more than 4 characters".
 */
export const componentPropsConfig: ComponentPropsConfig = {
	children: true,
	testId: true,
};

/**
 * THIS SECTION WAS CREATED VIA CODEGEN DO NOT MODIFY {@see http://go/af-codegen}
 *
 * This component was generated against an existing platform component.
 * If you are seeing lint errors in this file, it means that this file's dependencies have been modified and the component will need to be regenerated.
 *
 * Please view https://hello.atlassian.net/wiki/spaces/ECO/pages/3358130330/How+to+Regenerate+UI+Kit+Components for more information on how to proceed.
 *
 * For further questions, please ping the #help-forge-ui channel.
 *
 * @codegen <<SignedSource::be1f4520897d7635d3c32a2e1eaab501>>
 * @codegenId #error-message
 * @codegenCommand yarn workspace @atlassian/forge-ui codegen error-message
 * @codegenDependency ../../../../../../../design-system/form/src/messages.tsx <<SignedSource::2859e3f88bda4f26333a19c51d4281fa>>
 * @codegenDependency ../../../../../../../design-system/form/src/field-id-context.tsx <<SignedSource::e7f0005d72bda85a5d2db3d5c67dc35f>>
 */
/* eslint @repo/internal/codegen/signed-source-integrity: "warn" */
import React from 'react';
import type { RenderFn } from '@atlassian/forge-ui-types';
import { ErrorMessage as PlatformErrorMessage } from '@atlaskit/form';

type PlatformErrorMessageProps = React.ComponentProps<typeof PlatformErrorMessage>;

export type ErrorMessageProps = Pick<
  PlatformErrorMessageProps,
  'children' | 'testId'
>;

/**
 * An error message is used to tell a user that the field input is invalid.
 * For example, an error message could be "Invalid username, needs to be more than 4 characters".
 */
export const ErrorMessage = (props: Parameters<RenderFn>[0]) => {
  const {
    testId,
  } = props.forgeDoc.props as ErrorMessageProps;
  return (
    <PlatformErrorMessage
      children={props.forgeDoc.children.map(c => <React.Fragment key={c.key}>{props.render(c)}</React.Fragment>)}
      testId={testId}
    />
  );
};
/**
 * @codegenEnd
 */
