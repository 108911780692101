import React, { createContext, useContext, useMemo } from 'react';

import { type Fetcher } from '../types';

import { type PlacementFetchingProviderProps } from './types';

export const PlacementFetchingContext = createContext<Fetcher>({
	fetch: (...args) => globalThis.fetch(...args),
});

export const usePlacementFetchingContext = () => useContext(PlacementFetchingContext);

/**
 * @typedef {Object} Fetcher
 * @property {(url: string, options?: RequestInit) => Promise<Response>} fetch - A function to fetch resources.
 */

/**
 * Provides a custom fetcher to placement data fetchers 'usePlacementApi' and 'useInitialData'.
 * Its primary use is preview where it is used to change the fetch payload Provides a cus
 *
 * @param {Fetcher} props.fetcher - An object containing a fetch method for making HTTP requests.
 * @param {React.ReactNode} props.children - The child elements or components to be rendered inside the PlacementProvider.
 * @returns {JSX.Element}
 */
export const PlacementFetchingProvider = ({
	fetch,
	children,
}: PlacementFetchingProviderProps): JSX.Element => {
	const value = useMemo<Fetcher>(() => ({ fetch }), [fetch]);

	return (
		<PlacementFetchingContext.Provider value={value}>{children}</PlacementFetchingContext.Provider>
	);
};
