/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import FeatureGates from '@atlaskit/feature-gate-js-client';
import { useStableObject } from '@atlassian/post-office-context';
import { UFOv1, UFOv2 } from '@atlassian/post-office-frontend-performance-tracking';
import {
	InvalidMessageTemplateIdError,
	MessageRendererEmptyValidationResponseError,
} from '@post-office/errors';
import { MessageContextProvider } from '@post-office/message-context';
import {
	FeatureFlaggedUfoWrapper,
	MessageAnalyticsContextProvider,
	MessageRendererProvider,
	TrackMount,
} from '@post-office/placement-contexts';
import { type ErrorBoundaryProps, withErrorBoundary } from '@post-office/placement-contexts';
import { RecommendationProvider } from '@post-office/recommendation-context';
import { type MessageCreationType } from '@post-office/shared-contracts';
import {
	type ComponentProps,
	type FunctionComponent,
	type LazyExoticComponent,
	type MemoExoticComponent,
	type ReactNode,
	Suspense,
	type SuspenseProps,
	memo,
	useEffect,
	useMemo,
} from 'react';

import { ChoreographerWrapper } from './choreographer-wrapper';
import { type MessageCategory } from '../types';

type RenderableMessageProps<MessageTemplateId extends string = string> = {
	messageTemplateId: MessageTemplateId;
	messageInstanceId: string;
	messageCategory: MessageCategory;
	transactionAccountId?: string;
	recommendationSession?: {
		sessionId: string;
		entityid: string;
	};
	product?: string;
	loadingFallback?: React.ReactNode;
	postOffice?: {
		analyticsDetails?: Record<string, string | number>;
	};
	triggerId?: string;
	messageCreationType?: MessageCreationType;
	SuspenseBoundary?: typeof MaybeSuspenseBoundary;
};

type PlacementPropsShape = Record<string, unknown> | undefined;

type RenderableMessageComponent<T extends Record<string, unknown> = Record<string, unknown>> =
	| LazyExoticComponent<React.FC<T>>
	| React.FC<T>;

export type MessageProps<MessageComponents extends Record<string, RenderableMessageComponent>> = {
	[K in keyof MessageComponents]: ComponentProps<MessageComponents[K]> &
		RenderableMessageProps<Extract<K, string>>;
}[keyof MessageComponents];

export type MessageRendererType = MemoExoticComponent<
	(
		props: MessageProps<Record<string, RenderableMessageComponent>> &
			Record<string, unknown> &
			ErrorBoundaryProps,
	) => ReactNode
>;

export type InitializeMessageRenderer = () => MessageRendererType;

const STABLE_EMPTY_OBJECT = {};

/**
 * Wraps children in Suspense boundary when not in SSR environment.
 *
 * @param children
 * @param props
 * @constructor
 */
export const MaybeSuspenseBoundary: FunctionComponent<SuspenseProps> = ({ children, ...props }) => {
	return process.env.REACT_SSR ? <>{children}</> : <Suspense {...props}>{children}</Suspense>;
};

// Any is here as we never care about the value, and it stops the types getting more complex
export const asMessageRenderer = <
	MessageComponents extends Record<string, RenderableMessageComponent>,
>(
	messageComponents: MessageComponents,
	messageValidator?: (props: unknown) => MessageProps<MessageComponents>,
): {
	initializeMessageRenderer: InitializeMessageRenderer;
	MessageRenderer: MessageRendererType;
} => {
	const initializeMessageRenderer = <PlacementProps extends PlacementPropsShape>() => {
		// Return renderer component
		return memo(
			({
				messageTemplateId,
				messageInstanceId,
				recommendationSession,
				fallbackRender,
				loadingFallback,
				onError,
				messageCategory,
				postOffice,
				triggerId,
				messageCreationType,
				SuspenseBoundary = Suspense,
				...data
			}: MessageProps<MessageComponents> & PlacementProps & ErrorBoundaryProps) => {
				const isUFOEnabled = FeatureGates.checkGate('enable_ufo_tracking_in_post_office');
				const recommendationSessionStable = useStableObject(
					recommendationSession ?? STABLE_EMPTY_OBJECT,
				);
				const postOfficeStable = useStableObject(postOffice ?? STABLE_EMPTY_OBJECT);

				const { markMessageFailed, markMessageUnmounted, messageSuccessComponent } = useMemo(() => {
					const messagePerformanceTracker = isUFOEnabled
						? new UFOv1.MessagePerformanceTracker(messageTemplateId)
						: undefined;

					const onMessageSuccess = () => {
						void messagePerformanceTracker?.markSuccess();
					};

					const markMessageFailed =
						(onError?: (error: Error, info: React.ErrorInfo) => void) =>
						(error: Error, info: React.ErrorInfo) => {
							void messagePerformanceTracker?.markFailure(error);
							onError && onError(error, info);
						};

					return {
						/** To be called when the message is unmounted  */
						markMessageUnmounted: messagePerformanceTracker?.abort,
						/** To be called when the message fails to render */
						markMessageFailed: markMessageFailed,
						/** To be rendered when the message is successfully loaded */
						messageSuccessComponent: <TrackMount onMount={onMessageSuccess} />,
					};
				}, [messageTemplateId]);

				useEffect(() => {
					return () => {
						void markMessageUnmounted?.();
					};
				}, [markMessageUnmounted]);

				const Core = () => {
					const Component = messageComponents?.[messageTemplateId];

					// This should not happen but things can happen at runtime;
					if (!Component) {
						// Throw to error boundary
						throw new InvalidMessageTemplateIdError(
							JSON.stringify({ messageTemplateId, messageInstanceId }),
						);
					}

					let validatedMessage;
					if (messageValidator) {
						// The messageValidator should also throw an error if the validation failed,
						// which will again be thrown here and should be caught by the ErrorBoundary
						validatedMessage = messageValidator(data);
						if (!validatedMessage) {
							throw new MessageRendererEmptyValidationResponseError();
						}
					}

					const loadingComponent = useMemo(
						() =>
							isUFOEnabled ? (
								<UFOv2.UFOLoadHold name={UFOv2.HoldNames.MESSAGE}>
									{loadingFallback ?? null}
								</UFOv2.UFOLoadHold>
							) : (
								<>{loadingFallback ?? null}</>
							),
						[loadingFallback, isUFOEnabled],
					);

					// This any should be factored out as part of validation work
					const componentProps: any = messageValidator ? validatedMessage : data;
					const componentPropsStable = useStableObject(componentProps);

					// messageTemplateId exists in the API but component is empty (should not happen)
					if (!Component) {
						return null;
					}

					return FeatureGates.checkGate('po-refactor-message-renderer-provider') ? (
						<MessageRendererProvider
							messageInstanceId={messageInstanceId}
							messageTemplateId={messageTemplateId}
							messageCategory={messageCategory}
							recommendationSession={
								recommendationSessionStable === STABLE_EMPTY_OBJECT
									? undefined
									: (recommendationSessionStable as { sessionId: string; entityid: string })
							}
							messageSuccessComponent={messageSuccessComponent}
							componentProps={componentPropsStable}
							loadingFallback={loadingFallback}
							triggerId={triggerId}
							messageCreationType={messageCreationType}
							postOffice={postOfficeStable}
							isUFOEnabled={isUFOEnabled}
							SuspenseBoundary={SuspenseBoundary}
						>
							{/* eslint-disable-next-line @typescript-eslint/no-unsafe-argument */}
							<Component {...componentProps} />
						</MessageRendererProvider>
					) : (
						<ChoreographerWrapper
							messageId={messageInstanceId}
							messageTemplateId={messageTemplateId}
							messageCategory={messageCategory}
						>
							<FeatureFlaggedUfoWrapper
								messageTemplateId={messageTemplateId}
								isEnabled={isUFOEnabled}
							>
								<MessageContextProvider
									value={{
										messageInstanceId,
										messageTemplateId,
										triggerId,
										messageCreationType,
										transactionAccountId: componentProps.transactionAccountId,
										analyticsDetails: postOffice?.analyticsDetails,
									}}
								>
									<RecommendationProvider value={recommendationSession ?? {}}>
										<SuspenseBoundary fallback={loadingComponent}>
											<MessageAnalyticsContextProvider>
												{messageSuccessComponent}
												<Component {...componentProps} />
											</MessageAnalyticsContextProvider>
										</SuspenseBoundary>
									</RecommendationProvider>
								</MessageContextProvider>
							</FeatureFlaggedUfoWrapper>
						</ChoreographerWrapper>
					);
				};

				return withErrorBoundary(Core)({
					errorBoundaryLocation: 'placement-message-renderer-component',
					fallbackRender,
					onError: markMessageFailed(onError),
				});
			},
		);
	};

	return {
		initializeMessageRenderer,
		MessageRenderer: initializeMessageRenderer(),
	};
};
