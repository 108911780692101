/**
 * This file was automatically generated using "yarn generate:all" command.
 * Please do not edit it manually.
 * 
 * Generate source: local/cli/src/commands/placements/generate-intl-provider.ts
 **/


import { withIntlProvider } from '@post-office/placement-common';
import { messagesLoader as growthMessages} from '@post-office/team-package--i18n--growth'
import { messagesLoader as jiraTanukiMessages} from '@post-office/team-package--i18n--jira-tanuki'
import { messagesLoader as marketingMessages} from '@post-office/team-package--i18n--marketing'
import { messagesLoader as mercuryMessages} from '@post-office/team-package--i18n--mercury'
import { messagesLoader as playgroundMessages} from '@post-office/team-package--i18n--playground'
import { messagesLoader as postOfficeTemplateMessages} from '@post-office/team-package--i18n--post-office-template'
import { messagesLoader as testMessagesMessages} from '@post-office/team-package--i18n--test-messages'
import type { ComponentType } from 'react';

type TranslatedMessages = Record<string, string>;

type TranslatedMessagesResult<T> = { messages: T; errors: Array<string> };

const loadMessagesByTeam = async (locale: string): Promise<TranslatedMessagesResult<TranslatedMessages>> => {
return Promise.allSettled([
growthMessages(locale),
jiraTanukiMessages(locale),
marketingMessages(locale),
mercuryMessages(locale),
playgroundMessages(locale),
postOfficeTemplateMessages(locale),
testMessagesMessages(locale),
]).then(results => {
return results.reduce((acc, result) => {
if (result.status === 'fulfilled') {
return {
messages: {
...acc.messages,
...result.value,
} as TranslatedMessages,
errors: [...acc.errors],
}
} else if (result.status === 'rejected') {
return {
messages: { ...acc.messages },
errors: [...acc.errors, `Failed to load translations with ${result.reason}`]
};
}
return acc;
}, { messages: {} as TranslatedMessages, errors: [] as Array<string> });
});
};

export { loadMessagesByTeam as loadPlacementMessages }

/**
 * New IntlProvider for InApp Placements with a restructured messagesLoader, being rolled out soon with Feature Flag
 **/
export const withScreenSpaceFlagsIntlProvider = <P extends object>(Component: ComponentType<P>): ComponentType<P &
{ messages?: TranslatedMessages }> => {
	return withIntlProvider<P, TranslatedMessages>(
		{
			messagesLoader: loadMessagesByTeam,
		},
		Component,
	);
};
