import React from 'react';

import { aiLanguageMessages } from '@atlaskit/editor-common/ai-messages';

import { TranslateIcon } from '../../../../icons/prompt/translate';
import { createInsertBelow, createReplace } from '../../actions/create-actions';
import type { CONFIG_ITEM_KEYS } from '../../config-item-keys';
import type {
	EditorPluginAIConfigItemWithOptions,
	EditorPluginAIConfigItem,
} from '../../config-items';
import {
	getSortedSupportedTranslationLanguages,
	isRefinementSupport,
} from '../../utils/translation-utils';

const supportedTranslationLanguages = getSortedSupportedTranslationLanguages();

const translationConfigItemsArr = supportedTranslationLanguages
	.map((language) => {
		const lang = aiLanguageMessages[language];
		if (!lang) {
			return false;
		}
		const configItem: EditorPluginAIConfigItem = {
			key: `Translate to ${language}` as CONFIG_ITEM_KEYS,
			title: aiLanguageMessages[`${language}Title`],
			description: aiLanguageMessages[`${language}Description`],
			icon: () => <TranslateIcon />,
			showInRefineDropdown: isRefinementSupport(language),
			nestingConfig: {
				id: 'translate-to',
				parentTestId: 'translate-to',
				parentTitle: aiLanguageMessages.nestingParentTitlePostGA,
				shortTitle: lang,
			},
			intentSchemaId: 'translate_intent_schema.json',
			lang: lang.defaultMessage,
			actions: {
				empty: [
					createInsertBelow({ appearance: 'secondary' }),
					createReplace({ appearance: 'primary' }),
				],
				selection: [
					createInsertBelow({ appearance: 'secondary' }),
					createReplace({ appearance: 'primary' }),
				],
			},
		};

		return [language, configItem];
	})
	.filter(Boolean) as [string, EditorPluginAIConfigItem][];

export const translationConfigItemsMap: {
	[k: string]: EditorPluginAIConfigItem;
} = Object.fromEntries(translationConfigItemsArr.filter(Boolean));

export const translationConfigItems: EditorPluginAIConfigItem[] =
	Object.values(translationConfigItemsMap);

export const translationConfigItemWithOptions: EditorPluginAIConfigItemWithOptions[] =
	translationConfigItems.map((config) => ({
		config,
		triggers: { empty: { enabled: true, docMinSize: 1 }, selection: { enabled: true } },
	}));
