import type { ExtensionPayload, ExtensionViewData } from '@atlassian/forge-ui-types';
import type { ThemeState } from '@atlaskit/tokens';

import type { Extension } from '../../../web-client';
import type { ResolverContext, ViewContext } from '../types';

export const getViewContext = ({
	resolverContext,
	accountId,
	extension,
	timezone,
	locale,
	extensionPayload,
	extensionViewData,
	theme,
	surfaceColor,
}: {
	resolverContext: ResolverContext;
	accountId: string | undefined;
	extension: Extension;
	timezone: string;
	locale: string;
	extensionPayload?: ExtensionPayload;
	extensionViewData?: ExtensionViewData;
	theme?: Partial<ThemeState> | null;
	surfaceColor?: string | null;
}): ViewContext => {
	// Combine the static macro extension data with the dynamic extension view data (macro body) into a single macro object
	const macro = {
		...resolverContext.extension?.macro,
		...extensionViewData?.macro,
	};

	/* adding hasAccess separately instead of using userAccess as a whole is to avoid appending __typename in the context */
	const userAccess = extension.userAccess ?? { hasAccess: false };
	return {
		...resolverContext,
		extension: {
			...resolverContext.extension,
			...extensionViewData,
			...extensionPayload,
			...(Object.keys(macro).length > 0 ? { macro } : {}),
		},
		accountId,
		license: extension?.license || null,
		timezone,
		locale,
		theme,
		surfaceColor,
		userAccess,
	};
};
