/**
 * @jsx jsx
 */
import { useState, useMemo, useRef, useEffect, useCallback, type ReactNode } from 'react';
import { ReplaySubject } from 'rxjs/ReplaySubject';
import { debounceTime } from 'rxjs/operators';
import type ApolloClient from 'apollo-client';
import { EnvironmentContext, type Extension, ForgeUIRenderer } from '@atlassian/forge-ui/ui';
import { emit, on } from '@atlassian/forge-ui/events';
import type {
	ProductEnvironment,
	CoreData,
	ExtensionData,
	ExtensionViewData,
	ForgeDoc,
} from '@atlassian/forge-ui-types';

import { type FlagFunctions, getFlagProvider } from '../../utils/getFlagProvider';
import Blanket from '@atlaskit/blanket';
import Portal from '@atlaskit/portal';
import { layers } from '@atlaskit/theme/constants';
import Spinner from '@atlaskit/spinner';
import { isCustomUI } from '@atlassian/forge-ui';
import { css, jsx } from '@compiled/react';
import type { CustomEditContext } from '../../types';

const spinnerContainerStyles = css({
	display: 'flex',
	width: '100%',
	height: '100%',
	alignItems: 'center',
	justifyContent: 'center',
	pointerEvents: 'none',
});
interface ForgeUIExtensionProps {
	accountId: string;
	apolloClient: ApolloClient<object>;
	contextIds: string[];
	coreData: CoreData;
	extensionData: ExtensionData;
	extensionViewData: ExtensionViewData;
	extension: Extension;
	environment: ProductEnvironment;
	locale: string;
	flags: FlagFunctions;
	customEditContext?: CustomEditContext;
}

type ViewportSizeType = 'small' | 'medium' | 'large' | 'xlarge';
type ViewportSizeTypeWithDefault = ViewportSizeType | 'default';
type ViewportSizeObjectType = {
	[size in ViewportSizeTypeWithDefault]: string;
};

const macroHeights: ViewportSizeObjectType = {
	small: '112px',
	medium: '262px',
	default: '262px',
	large: '524px',
	xlarge: '1048px',
};

const calculateHeight = (size?: ViewportSizeType) => {
	return macroHeights[size ?? 'default'];
};

export const ForgeUIExtension = ({
	accountId,
	apolloClient,
	coreData,
	extensionData,
	extensionViewData,
	environment,
	extension,
	locale,
	flags,
	customEditContext,
}: ForgeUIExtensionProps) => {
	const { cloudId, localId } = coreData;
	const modalExtension = customEditContext?.modalExtension;
	const bridge = customEditContext?.bridge;

	const [macroConfigForgeDocSubject$] = useState(() => new ReplaySubject(10));
	const configValueRef = useRef(undefined);
	// Stores the forgeDoc for the macro config schema. The ref is often a render behind
	// the actual schema, so should only be used on the initial load.
	const macroConfigForgeDocRef = useRef<ForgeDoc | undefined>(undefined);

	// This sets up an event listener to respond to Editor's requests for the macro config schema.
	// When the GET_CONFIG_FORGE_DOC_${id} event is emitted, the payload includes the current
	// config value, and the resolve and reject functions of a Promise. The listener should be
	// resolving the promise with the value of the macro config forgeDoc.
	const id = `${extension.id}-${coreData.localId}`;
	const reconcile = useCallback(
		({ forgeDoc }: { forgeDoc: ForgeDoc }) => {
			Object.freeze(forgeDoc);
			macroConfigForgeDocRef.current = forgeDoc;
			macroConfigForgeDocSubject$.next(forgeDoc);
			emit(`CONFIG_FORGE_DOC_UPDATED_${id}`);
		},
		[id, macroConfigForgeDocSubject$],
	);

	useEffect(() => {
		const macroConfigForgeDocSubscription = on(
			`GET_CONFIG_FORGE_DOC_${id}`,
			async ({ resolve, reject, config }) => {
				const subscription = macroConfigForgeDocSubject$.pipe(debounceTime(200)).subscribe({
					next: (forgeDoc) => {
						resolve({
							type: 'Root',
							props: {},
							children: [forgeDoc],
						});
						subscription.unsubscribe();
					},
				});
				configValueRef.current = config;
			},
		);

		return () => {
			macroConfigForgeDocSubscription.unsubscribe();
		};
	}, [id, macroConfigForgeDocSubject$]);

	const { showFlag, closeFlag } = useMemo(() => getFlagProvider(flags), [flags]);

	let loadingComponent: ReactNode | undefined;

	if (!isCustomUI(extension) && modalExtension) {
		loadingComponent = (
			<Portal zIndex={layers.blanket()}>
				<Blanket isTinted={true} onBlanketClicked={() => modalExtension.closeModalExtension()}>
					<div css={spinnerContainerStyles}>
						<Spinner size="large" />
					</div>
				</Blanket>
			</Portal>
		);
	}

	return (
		<EnvironmentContext.Provider value={environment}>
			<ForgeUIRenderer
				accountId={accountId}
				bridge={{
					...bridge,
					showFlag,
					closeFlag,
					reconcile,
				}}
				client={apolloClient}
				cloudId={cloudId!}
				contextIds={[`ari:cloud:confluence::site/${cloudId}`]}
				extension={extension}
				extensionData={extensionData}
				extensionViewData={extensionViewData}
				height={
					extension.properties.viewportSize
						? calculateHeight(extension.properties.viewportSize)
						: undefined
				}
				locale={locale}
				localId={localId}
				product="confluence"
				onForgeDocUpdated={(forgeDoc: ForgeDoc) => {
					if (forgeDoc.type === 'MacroConfig') {
						if (customEditContext) {
							throw new Error(
								'ForgeReconciler.addConfig() cannot be called from within a custom config resource',
							);
						}
						macroConfigForgeDocRef.current = forgeDoc;
						macroConfigForgeDocSubject$.next(forgeDoc);
						emit(`CONFIG_FORGE_DOC_UPDATED_${id}`);
					}
				}}
				modalExtension={modalExtension}
				onModalExtensionClose={modalExtension?.closeModalExtension}
				loadingComponent={loadingComponent}
			/>
		</EnvironmentContext.Provider>
	);
};
