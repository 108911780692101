/**
 * @jsxRuntime classic
 * @jsx jsx
 */

import { css, jsx } from '@compiled/react';
import { type CSSProperties } from 'react';
import { ModalBody } from '@atlaskit/modal-dialog';
import { token } from '@atlaskit/tokens';
import { N0 } from '@atlaskit/theme/colors';
import { type ModalSizesType } from '../../custom-ui';

type ModalContentWrapperProps = {
	children: JSX.Element;
	size: ModalSizesType;
	styles?: CSSProperties;
	isResizable?: boolean;
};

const smallModalStyles = css({
	height: '20vh',
	minHeight: '320px',
	backgroundColor: token('elevation.surface.overlay', N0),
	borderRadius: token('border.radius', '3px'),
});

const mediumModalStyles = css({
	height: '40vh',
	minHeight: '520px',
	backgroundColor: token('elevation.surface.overlay', N0),
	borderRadius: token('border.radius', '3px'),
});

const largeModalStyles = css({
	height: '70vh',
	minHeight: '720px',
	backgroundColor: token('elevation.surface.overlay', N0),
	borderRadius: token('border.radius', '3px'),
});

const xlargeModalStyles = css({
	height: '90vh',
	// default behaviour and height from ModalBody is used here.
	// If a static height is used, it will not be responsive and overflow off the screen and viewport height is too small.
	minHeight: 'calc(100vh - 119px)',
	backgroundColor: token('elevation.surface.overlay', N0),
	borderRadius: token('border.radius', '3px'),
});

const maxModalStyles = css({
	height: '100%',
	backgroundColor: token('elevation.surface.overlay', N0),
	borderRadius: token('border.radius', '3px'),
});

const resizableModalStyles = css({
	overflow: 'auto',
	height: 'auto',
});

// TODO: Fix below styles once we move to compiled/react
export const ModalContentWrapper = ({
	children,
	size,
	styles,
	isResizable,
}: ModalContentWrapperProps) => {
	switch (size) {
		case 'small':
			return (
				// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop
				<div css={[smallModalStyles, isResizable && resizableModalStyles]} style={styles}>
					{children}
				</div>
			);
		case 'medium':
			return (
				// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop
				<div css={[mediumModalStyles, isResizable && resizableModalStyles]} style={styles}>
					{children}
				</div>
			);
		case 'large':
			return (
				// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop
				<div css={[largeModalStyles, isResizable && resizableModalStyles]} style={styles}>
					{children}
				</div>
			);
		case 'xlarge':
			return (
				// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop
				<div css={[xlargeModalStyles, isResizable && resizableModalStyles]} style={styles}>
					{children}
				</div>
			);
		case 'max':
			return (
				// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop
				<div css={[maxModalStyles]} style={styles}>
					{children}
				</div>
			);
		default:
			return <ModalBody>{children}</ModalBody>;
	}
};

type ModalWidths = 'small' | 'medium' | 'large' | 'xlarge' | 'max';

export const getAtlasKitModalWidth = (modalSize: ModalWidths) => {
	if (modalSize === 'xlarge') {
		return 'x-large';
	}
	if (modalSize === 'max') {
		return '100%';
	}
	return modalSize;
};
