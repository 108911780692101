import { type ComponentPropsConfig } from '../../../utils/codegen';

/**
 * Use a form footer to set the content at the end of the form. This is used for a button that submits the form.
 *
 * This is positioned after the last field in the form.
 */
export const componentPropsConfig: ComponentPropsConfig = {
	children: true,

	/**
	 * Sets the alignment of the footer contents. This is often a button. Defaults to `end`.
	 */
	align: true,
};

/**
 * THIS SECTION WAS CREATED VIA CODEGEN DO NOT MODIFY {@see http://go/af-codegen}
 *
 * This component was generated against an existing platform component.
 * If you are seeing lint errors in this file, it means that this file's dependencies have been modified and the component will need to be regenerated.
 *
 * Please view https://hello.atlassian.net/wiki/spaces/ECO/pages/3358130330/How+to+Regenerate+UI+Kit+Components for more information on how to proceed.
 *
 * For further questions, please ping the #help-forge-ui channel.
 *
 * @codegen <<SignedSource::e1f509ee9447da9402a10ec375a0c784>>
 * @codegenId #form-footer
 * @codegenCommand yarn workspace @atlassian/forge-ui codegen form-footer
 * @codegenDependency ../../../../../../../design-system/form/src/form-footer.tsx <<SignedSource::52d654b9d94d94c181b0548b9541d185>>
 * @codegenDependency ../../../../../../../design-system/form/src/types.tsx <<SignedSource::ff606b6a8f48b81e3a15a284f31316cd>>
 */
/* eslint @repo/internal/codegen/signed-source-integrity: "warn" */
import React from 'react';
import type { RenderFn } from '@atlassian/forge-ui-types';
import { FormFooter as PlatformFormFooter } from '@atlaskit/form';

type _PlatformFormFooterProps = React.ComponentProps<typeof PlatformFormFooter>;
export type PlatformFormFooterProps = Omit<_PlatformFormFooterProps, 'align'> & {
/**
 * Sets the alignment of the footer contents. This is often a button. Defaults to `end`.
 */
	align?: _PlatformFormFooterProps['align'];
}

export type FormFooterProps = Pick<
  PlatformFormFooterProps,
  'children' | 'align'
>;

/**
 * Use a form footer to set the content at the end of the form. This is used for a button that submits the form.
 * 
 * This is positioned after the last field in the form.
 */
export const FormFooter = (props: Parameters<RenderFn>[0]) => {
  const {
    align,
  } = props.forgeDoc.props as FormFooterProps;
  return (
    <PlatformFormFooter
      children={props.forgeDoc.children.map(c => <React.Fragment key={c.key}>{props.render(c)}</React.Fragment>)}
      align={align}
    />
  );
};
/**
 * @codegenEnd
 */
