import { isSSR } from '../../common/utils';
import { Logger } from '../../common/utils/logger';

export interface AtlIndexedDBItem {
	atlSetItem: string;
	id: string;
}

class IndexedDBService {
	private dbName: string;
	private storeName: string;
	private bootUpPromiseCache: Promise<void> | null;
	db: IDBDatabase | null = null;
	isBooted = false;
	isAvailable = false;

	constructor(dbName: string, storeName: string) {
		this.dbName = dbName;
		this.storeName = storeName;
		this.isAvailable = this.getIsDBAvailable();
		this.isBooted = false;
		this.bootUpPromiseCache = null;
	}

	async boot() {
		if (!this.isAvailable) {
			Logger.warn('IndexedDB is not available');
			return;
		}
		if (this.isBooted) {
			// Short circuit if we've already booted once
			return;
		}

		// Only boot once, even if we've been called numerous times sequentially
		if (!this.bootUpPromiseCache) {
			this.bootUpPromiseCache = this.openDB();

			try {
				await this.bootUpPromiseCache;
				this.isBooted = true;
			} catch (e) {
				this.isBooted = false;
				Logger.warn('Error booting up IndexedDB.');
				throw e;
			}
		}
	}

	getIsDBAvailable() {
		return !isSSR() && !!window.indexedDB;
	}

	async openDB() {
		return new Promise<void>((resolve, reject) => {
			let request = indexedDB.open(this.dbName, 2);

			request.onerror = this.onConnectionErrorHandler(reject);
			request.onsuccess = this.onConnectionSuccessHandler(request, resolve);
			request.onupgradeneeded = this.onConnectionUpgradeNeededHandler(request);
		});
	}

	onConnectionErrorHandler = (reject: (reason?: any) => void) => (event: Event) => {
		Logger.warn('Error opening database.');
		reject(event);
	};

	onConnectionSuccessHandler = (connection: IDBOpenDBRequest, resolve: () => void) => () => {
		this.db = connection.result;
		resolve();
	};

	onConnectionUpgradeNeededHandler = (request: IDBOpenDBRequest) => () => {
		this.db = request.result;

		this.db.createObjectStore(this.storeName, { keyPath: 'id' });
	};

	async getRecord(recordKey: string): Promise<AtlIndexedDBItem> {
		try {
			if (!this.isBooted) {
				await this.boot();
			}

			if (!this.db) {
				throw new Error('Database is not opened');
			}

			let fetchTransaction = this.db.transaction([this.storeName]);
			let recordStore = fetchTransaction.objectStore(this.storeName);

			const fetchedRecord = new Promise<any>((resolve, reject) => {
				let request = recordStore.get(recordKey);

				request.onerror = this.onGetRecordErrorHandler(reject);
				request.onsuccess = this.onGetRecordSuccessHandler(request, resolve);
			});

			return fetchedRecord;
		} catch (e: any) {
			Logger.error(`Error fetching record from database. ${e.message || ''}`);
			throw e;
		}
	}

	onGetRecordErrorHandler = (reject: (reason?: any) => void) => () => {
		const getErrorMessage = `Failed to perform a get transaction on IndexedDB: ${this.dbName} + ${this.storeName}`;
		Logger.warn(getErrorMessage);
		reject(getErrorMessage);
	};

	onGetRecordSuccessHandler =
		(getRequest: IDBRequest<any>, resolve: (value?: AtlIndexedDBItem) => void) => () => {
			resolve(getRequest.result);
		};

	async setRecord(recordKey: string): Promise<void> {
		try {
			if (!this.isBooted) {
				await this.boot();
			}

			if (!this.db) {
				throw new Error('Database is not opened');
			}

			const setRecord = new Promise<void>((resolve, reject) => {
				let setTransaction = this.db!.transaction([this.storeName], 'readwrite');
				let objectStore = setTransaction.objectStore(this.storeName);

				const newAtlRecord: AtlIndexedDBItem = { atlSetItem: 'SET-BY-PACKAGE', id: recordKey };
				let request = objectStore.put(newAtlRecord);

				request.onerror = this.onSetRecordErrorHandler(reject);
				request.onsuccess = this.onSetRecordSuccessHandler(resolve);
			});
			return setRecord;
		} catch (e: any) {
			Logger.warn(`Error setting record in database. ${e.message || ''}`);
			throw e;
		}
	}

	onSetRecordErrorHandler = (reject: (reason?: any) => void) => () => {
		const errorMessage = `Failed to perform a set transaction on IndexedDB: ${this.dbName} + ${this.storeName}`;
		Logger.warn(errorMessage);
		reject(errorMessage);
	};

	onSetRecordSuccessHandler = (resolve: () => void) => () => {
		resolve();
	};
}

const BSCIndexedDB = new IndexedDBService('BSCIndexedDB', 'InternalStorageKeys');

export { BSCIndexedDB, IndexedDBService };
