import gql from 'graphql-tag';

export const SuggestedTemplatesQuery = gql`
	query SuggestedTemplatesQuery($spaceKey: String!, $sortingScheme: String, $limit: Int!) {
		allTemplates(spaceKey: $spaceKey, sortingScheme: $sortingScheme, limit: $limit) {
			nodes {
				templateId
				contentBlueprintId
				blueprintModuleCompleteKey
				itemModuleCompleteKey
				templateType
				name
				description
				iconURL
				darkModeIconURL
				styleClass
				hasWizard
				isNew
				isPromoted
				isFavourite
				hasGlobalBlueprintContent
				categoryIds
				keywords
				recommendationRank
				author
			}
		}
	}
`;
