import { useState, useContext, useEffect, useRef } from 'react';
import type { ApolloError } from 'apollo-client';

import { useAnalyticsEvents } from '@atlaskit/analytics-next';

import { isUnauthorizedError } from '@confluence/error-boundary';
import { FrontCoverStateEnum } from '@confluence/route-manager/entry-points/companyHubUtils';
import { SPAViewContext } from '@confluence/spa-view-context';
import { fg } from '@confluence/feature-gating';
import {
	COMPANY_HUB_CREATION_EXPERIENCE,
	ExperienceTimeout,
	ExperienceTrackerContext,
} from '@confluence/experience-tracker';

import { useCompanyHubPremiumGate } from '../upsell/useCompanyHubPremiumGate';

import { createCompanyHubSpace, hasStalePermissionsErrorMessage } from './createCompanyHubSpace';
import type { CreateCompanyHubSpaceResult } from './createCompanyHubSpace';

type UseCompanyHubOnRouteCreationFlowProps = {
	onSuccess?: (homepageId: CreateCompanyHubSpaceResult['homepageId']) => void;
	onExists?: (homepageId: CreateCompanyHubSpaceResult['homepageId']) => void;
};

export function useCompanyHubOnRouteCreationFlow({
	onSuccess,
	onExists,
}: UseCompanyHubOnRouteCreationFlowProps): {
	isCreatingHub: boolean;
	creationError: ApolloError | null;
} {
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const [isCreatingHub, setIsCreatingHub] = useState<boolean>(false);
	const [creationError, setCreationError] = useState<ApolloError | null>(null);
	const { isSiteAdmin, frontCoverState } = useContext(SPAViewContext);
	const experienceTracker = useContext(ExperienceTrackerContext);

	const shouldShowPremiumUpsell = useCompanyHubPremiumGate({
		skip: !fg('company-hub-pseudo-app'),
	});

	const isCompanyHubUnset = frontCoverState === FrontCoverStateEnum.UNSET;
	const hasCreatedHub = useRef<boolean>(false);

	useEffect(() => {
		if (!hasCreatedHub.current && fg('company-hub-pseudo-app')) {
			const shouldCreateHub =
				isCompanyHubUnset && isSiteAdmin && !shouldShowPremiumUpsell && !isCreatingHub;

			if (shouldCreateHub) {
				setIsCreatingHub(true);
				hasCreatedHub.current = true;
				experienceTracker.start({
					name: COMPANY_HUB_CREATION_EXPERIENCE,
					timeout: ExperienceTimeout.DEFAULT,
				});
				createCompanyHubSpace()
					.then(({ didCreateSpace, homepageId }) => {
						if (didCreateSpace) {
							experienceTracker.succeed({
								name: COMPANY_HUB_CREATION_EXPERIENCE,
							});
							onSuccess?.(homepageId);
						} else {
							experienceTracker.abort({
								name: COMPANY_HUB_CREATION_EXPERIENCE,
								reason:
									'Hub space already exists. Handled by redirecting user to Hub editor without first time experience',
							});
							onExists?.(homepageId);
						}
					})
					.catch((error) => {
						if (fg('company-hub-pseudo-app')) {
							setCreationError(error);
						}

						if (hasStalePermissionsErrorMessage(error)) {
							experienceTracker.abort({
								name: COMPANY_HUB_CREATION_EXPERIENCE,
								reason: 'User has stale admin permissions',
							});
						} else if (isUnauthorizedError(error)) {
							experienceTracker.abort({
								name: COMPANY_HUB_CREATION_EXPERIENCE,
								reason: 'User is not authenticated',
							});
						} else {
							experienceTracker.fail({
								name: COMPANY_HUB_CREATION_EXPERIENCE,
								error,
							});
						}
					})
					.finally(() => {
						setIsCreatingHub(false);

						createAnalyticsEvent({
							type: 'sendTrackEvent',
							data: {
								source: 'companyHub',
								action: 'created',
								actionSubject: 'onRouteStart',
								actionSubjectId: 'companyHubOnRouteCreation',
								attributes: {
									// TODO Add whether is company hub view or edit
								},
							},
						}).fire();
					});
			}
		}
	}, [
		isCompanyHubUnset,
		isSiteAdmin,
		createAnalyticsEvent,
		shouldShowPremiumUpsell,
		isCreatingHub,
		experienceTracker,
		onSuccess,
		onExists,
	]);

	return {
		isCreatingHub,
		creationError,
	};
}
