import { elementToForgeDoc } from '../../../../utils/elementToForgeDoc';
import { sanitizeUrl } from '../../../../utils';
import { type ComponentPropsConfig } from '../../../utils/codegen';

export const componentPropsConfig: ComponentPropsConfig = {
	text: true,
	appearance: true,
	color: true,
	elemBefore: {
		value: 'elemBefore ? props.render(elementToForgeDoc(elemBefore as React.ReactElement)): null',
		comment:
			'forge/react reconciler does not convert components in props into ForgeDoc. We need to do it manually here',
	},
	href: { value: 'href ? sanitizeUrl(href) : undefined' },
	linkComponent: false,
	testId: true,
};

/**
 * THIS SECTION WAS CREATED VIA CODEGEN DO NOT MODIFY {@see http://go/af-codegen}
 *
 * This component was generated against an existing platform component.
 * If you are seeing lint errors in this file, it means that this file's dependencies have been modified and the component will need to be regenerated.
 *
 * Please view https://hello.atlassian.net/wiki/spaces/ECO/pages/3358130330/How+to+Regenerate+UI+Kit+Components for more information on how to proceed.
 *
 * For further questions, please ping the #help-forge-ui channel.
 *
 * @codegen <<SignedSource::10978f8cf20f84b2252d0d7cd45708c4>>
 * @codegenId #tag
 * @codegenCommand yarn workspace @atlassian/forge-ui codegen tag
 * @codegenDependency ../../../../../../../design-system/tag/src/tag/internal/shared/types.tsx <<SignedSource::97268e995bd3ebb297a23128b2e6712d>>
 * @codegenDependency ../../../../../../../design-system/tag/src/tag/internal/shared/base.tsx <<SignedSource::6777da8ce8df478f49395fea1ddbfd44>>
 * @codegenDependency ../../../../../../../design-system/tag/src/tag/internal/shared/before.tsx <<SignedSource::2d1f88a9a72eb79263ba826f8ac5d925>>
 * @codegenDependency ../../../../../../../design-system/tag/src/tag/internal/shared/content.tsx <<SignedSource::9832d89acda724385c7f1ca8bb33915e>>
 * @codegenDependency ../../../../../../../design-system/tag/src/tag/internal/simple/index.tsx <<SignedSource::605699a8e28b9c03e3c31fa7dc137717>>
 */
/* eslint @repo/internal/codegen/signed-source-integrity: "warn" */
import React from 'react';
import type { RenderFn } from '@atlassian/forge-ui-types';
import { SimpleTag as PlatformSimpleTag } from '@atlaskit/tag';

type PlatformSimpleTagProps = React.ComponentProps<typeof PlatformSimpleTag>;

export type SimpleTagProps = Pick<
  PlatformSimpleTagProps,
  'text' | 'appearance' | 'color' | 'elemBefore' | 'href' | 'testId'
>;

/**
 * A tag labels UI objects for quick recognition and navigation.
 */
export const SimpleTag = (props: Parameters<RenderFn>[0]) => {
  const {
    text,
    appearance,
    color,
    elemBefore,
    href,
    testId,
  } = props.forgeDoc.props as SimpleTagProps;
  return (
    <PlatformSimpleTag
      text={text}
      appearance={appearance}
      color={color}
      // forge/react reconciler does not convert components in props into ForgeDoc. We need to do it manually here
      elemBefore={elemBefore ? props.render(elementToForgeDoc(elemBefore as React.ReactElement)): null}
      href={href ? sanitizeUrl(href) : undefined}
      testId={testId}
    />
  );
};
/**
 * @codegenEnd
 */
