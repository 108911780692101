import React from 'react';
import { getEditorPreloader } from '@confluence-classic/confluence-fabric-editor/src/lib';

import type { RouteMatch } from '@confluence/route';
import { CONTAINER_HOME_ID } from '@confluence/app-navigation/entry-points/navigationIDs';
import { START_TOUCH } from '@confluence/navdex';
import { SpaceBlogsLoader } from '@confluence/space-blogs';

import SpaceBase from '../../SpaceBase';

export const SpaceBlogsRoute = () => {
	return (
		<SpaceBase>
			<SpaceBlogsLoader />
		</SpaceBase>
	);
};

SpaceBlogsRoute.NAVIGATION_PARAMS = ({ params: { spaceKey } }: RouteMatch) => ({
	Screen: {
		screenEvent: {
			name: 'spaceBlogsScreen',
			id: spaceKey,
			attributes: {
				containerId: spaceKey,
				navigationLayer: 'container',
				navdexPointType: START_TOUCH,
			},
		},
		pageState: { spaceKey },
	},
	MainLayout: {
		navView: CONTAINER_HOME_ID,
		spaceKey,
		editorPreloader: getEditorPreloader,
		disableMinWidth: true,
	},
});
