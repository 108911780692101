import type { ComponentType } from 'react';
import React, { PureComponent } from 'react';
import type { WrappedComponentProps } from 'react-intl-next';
import { injectIntl } from 'react-intl-next';

import type { FlagProps } from '@atlaskit/flag';
import Flag, { AutoDismissFlag, FlagGroup } from '@atlaskit/flag';

import {
	ChoreographerAssetsContextConsumer,
	type MaybeChoreographedComponentProps,
} from '@confluence/choreographer-services';

import { FlagIcon } from './FlagIcon';
import type { FlagAction, FlagDescriptor } from './FlagsStateContainer';

type FlagsComponentAction = Omit<FlagAction, 'content'> &
	(
		| {
				content: FlagAction['content'];
		  }
		| {
				contentKey: string;
		  }
	);

export type FlagsComponentProps = {
	flags: FlagDescriptor[];
	areFlagsVisible: boolean;

	/**
	 * Function to bridge atlaskit flags to AUI
	 */
	applyAJSFlagOverride?(showFlagFn: Function): void;
	removeAJSFlagOverride?(): void;

	hideFlag?(flagId: string | number): void;
	showFlag?(flag: FlagDescriptor): void;
};

export const FlagsComponent: ComponentType<FlagsComponentProps> = injectIntl(
	class FlagsComponent extends PureComponent<FlagsComponentProps & WrappedComponentProps> {
		componentDidMount() {
			const { showFlag, applyAJSFlagOverride } = this.props;

			if (showFlag) {
				applyAJSFlagOverride?.(showFlag);
			}
		}

		componentWillUnmount() {
			this.props.removeAJSFlagOverride?.();
		}

		translateActions(actions: FlagsComponentAction[]): FlagAction[] {
			const { intl } = this.props;

			return actions.map((action) => {
				if ('contentKey' in action) {
					const i18nMessage = {
						id: action.contentKey,
					};
					return {
						...action,
						content: intl.formatMessage(i18nMessage),
					};
				}

				return action;
			});
		}

		onDismissed = (flagId: string | number) => {
			const { flags, hideFlag } = this.props;

			flags.forEach((flag) => {
				if (flag.id === flagId && typeof flag.onClose === 'function') {
					flag.onClose();
				}
			});

			hideFlag?.(flagId);
		};

		private toFlagProps({
			id,
			title = '',
			description = '',
			actions = [],
			type,
			appearance,
			customIcon,
			messageId,
			messageType,
			onMessageDisposition,
			delayAnnouncement,
		}: FlagDescriptor): FlagProps & MaybeChoreographedComponentProps {
			return {
				icon: <FlagIcon type={type} isBold={Boolean(appearance)} customIcon={customIcon} />,
				appearance,
				id,
				title,
				description,
				messageId,
				messageType,
				onMessageDisposition,
				actions: this.translateActions(actions),
				...(delayAnnouncement && { delayAnnouncement }),
			};
		}

		render() {
			const { flags, areFlagsVisible } = this.props;

			return (
				<ChoreographerAssetsContextConsumer>
					{(choreographerAssets) => (
						<FlagGroup onDismissed={this.onDismissed}>
							{flags.map((flagDescriptor) => {
								const FlagComponentType: ComponentType<FlagProps> =
									flagDescriptor.customComponent ||
									(flagDescriptor.close === 'auto'
										? choreographerAssets?.AutoDismissFlag ?? AutoDismissFlag
										: choreographerAssets?.Flag ?? Flag);

								return (
									<div
										data-test-id="flag-visibility-wrapper"
										key={flagDescriptor.id}
										style={{ visibility: areFlagsVisible ? 'visible' : 'hidden' }}
										aria-hidden={!areFlagsVisible}
									>
										<FlagComponentType {...this.toFlagProps(flagDescriptor)} />
									</div>
								);
							})}
						</FlagGroup>
					)}
				</ChoreographerAssetsContextConsumer>
			);
		}
	},
);
