import { namedRoutes } from '@confluence/named-routes';
import { Route } from '@confluence/route';

export type ErrorDefinition = {
	friendlyMessage: string;
	docLink?: string;
	originalString?: string;
	fallback?: boolean;
	hydrationFailed?: boolean;
};

const DOC_LINK =
	'https://hello.atlassian.net/wiki/spaces/CCPERF/pages/4848884799/Debugging+Hydration+Mismatches';

export const HYDRATION_ERROR_FRAGMENTS: Array<{
	matchers: Array<RegExp>;
	friendlyError: ErrorDefinition;
	hydrationFailed: boolean;
}> = [
	{
		matchers: [
			/Hydration failed because the initial UI does not match what was rendered on the server/,
		],
		friendlyError: {
			friendlyMessage:
				'Hydration failed because the initial UI does not match what was rendered on the server.',
			docLink: DOC_LINK,
		},
		hydrationFailed: true,
	},
	{
		matchers: [
			/The server could not finish this Suspense boundary, likely due to an error during server rendering/,
		],
		friendlyError: {
			friendlyMessage:
				'The server could not finish this Suspense boundary, likely due to an error during server rendering. Switched to client rendering.',
			docLink: DOC_LINK,
		},
		hydrationFailed: true,
	},
	{
		matchers: [
			/This Suspense boundary received an update before it finished hydrating. This caused the boundary to switch to client rendering/,
		],
		friendlyError: {
			friendlyMessage:
				'This Suspense boundary received an update before it finished hydrating. This caused the boundary to switch to client rendering. The usual way to fix this is to wrap the original update in startTransition.',
			docLink: DOC_LINK,
		},
		hydrationFailed: true,
	},
	{
		matchers: [
			/There was an error while hydrating. Because the error happened outside of a Suspense boundary, the entire root will switch to client rendering./,
		],
		friendlyError: {
			friendlyMessage:
				'There was an error while hydrating. Because the error happened outside of a Suspense boundary, the entire root will switch to client rendering.',
			docLink: DOC_LINK,
		},
		hydrationFailed: true,
	},
	{
		matchers: [
			/This root received an early update, before anything was able hydrate. Switched the entire root to client rendering./,
		],
		friendlyError: {
			friendlyMessage:
				'This root received an early update, before anything was able hydrate. Switched the entire root to client rendering.',
			docLink: DOC_LINK,
		},
		hydrationFailed: true,
	},
	{
		matchers: [/Text content does not match server-rendered HTML./],
		friendlyError: {
			friendlyMessage: 'Text content does not match server-rendered HTML.',
			docLink: DOC_LINK,
		},
		hydrationFailed: true,
	},
	{
		matchers: [
			/There was an error while hydrating this Suspense boundary. Switched to client rendering./,
		],
		friendlyError: {
			friendlyMessage:
				'There was an error while hydrating this Suspense boundary. Switched to client rendering.',
			docLink: DOC_LINK,
		},
		hydrationFailed: true,
	},
	//Production errors
	{
		matchers: [/Minified React error #418/],
		friendlyError: {
			friendlyMessage:
				'Hydration failed because the initial UI does not match what was rendered on the server in production. Run in development mode for more details.',
			docLink: DOC_LINK,
		},
		hydrationFailed: true,
	},
	{
		matchers: [/Minified React error #419/],
		friendlyError: {
			friendlyMessage:
				'The server could not finish this Suspense boundary, likely due to an error during server rendering. Switched to client rendering. Run in development mode for more details.',
			docLink: DOC_LINK,
		},
		hydrationFailed: true,
	},
	{
		matchers: [/Minified React error #421/],
		friendlyError: {
			friendlyMessage:
				'This Suspense boundary received an update before it finished hydrating. This caused the boundary to switch to client rendering. The usual way to fix this is to wrap the original update in startTransition.',
			docLink: DOC_LINK,
		},
		hydrationFailed: true,
	},
	{
		matchers: [/Minified React error #422/],
		friendlyError: {
			friendlyMessage:
				'There was an error while hydrating this Suspense boundary. Switched to client rendering.',
			docLink: DOC_LINK,
		},
		hydrationFailed: true,
	},
	{
		matchers: [/Minified React error #423/],
		friendlyError: {
			friendlyMessage:
				'There was an error while hydrating. Because the error happened outside of a Suspense boundary, the entire root will switch to client rendering.',
			docLink: DOC_LINK,
		},
		hydrationFailed: true,
	},
	{
		matchers: [/Minified React error #424/],
		friendlyError: {
			friendlyMessage:
				'This root received an early update, before anything was able hydrate. Switched the entire root to client rendering.',
			docLink: DOC_LINK,
		},
		hydrationFailed: true,
	},
	{
		matchers: [/Minified React error #425/],
		friendlyError: {
			friendlyMessage: 'Text content does not match server-rendered HTML.',
			docLink: DOC_LINK,
		},
		hydrationFailed: true,
	},
];

export const matchHydrationError = (errorString: string): ErrorDefinition => {
	for (const { matchers, friendlyError, hydrationFailed } of HYDRATION_ERROR_FRAGMENTS) {
		if (matchers.some((matcher) => matcher.test(errorString))) {
			return { ...friendlyError, originalString: errorString, hydrationFailed };
		}
	}

	return {
		friendlyMessage: 'An additional error occurred, attached below.',
		docLink: '',
		fallback: true,
		originalString: errorString,
		hydrationFailed: false,
	};
};

export const getHydrationErrorWithRoute = (url: string, friendlyMessage: string): Error => {
	const match = Route.matchFirst(Object.values(namedRoutes), url);

	return new Error(`Hydration failed on route ${match?.name}: ${friendlyMessage}.`);
};
