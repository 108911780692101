/**
 * @jsxRuntime classic
 * @jsx jsx
 */
import { type ExtensionAPI, type ExtensionManifest } from '@atlaskit/editor-common/extensions';
import { shouldBlockExtensionDueToAppAccessPolicy } from '../render';
import { parse } from '@atlassian/cs-ari';
import { getExtensionType, isCustomUI as getIsCustomUI } from '@atlassian/forge-ui';
import { fg } from '@atlaskit/platform-feature-flags';

import { type ForgeUIExtensionType } from '@atlassian/forge-ui-types';
import { jsx } from '@compiled/react';
import { EXTENSION_NAMESPACE } from '../utils/constants';
import { createTitleWithEnvironmentInfo } from './getForgeExtensionProvider';
import { createLocalId } from '../utils/createLocalId';
import { extractKeyFromId } from '../utils/extractKeyFromId';
import Icon from './icon';
import {
	type ForgeConfigPayload,
	type renderConfigHelper,
	validateForgeConfigPayload,
	handleNodeUpdateError,
	transformConfigAfter,
} from '../config';
import {
	type ForgeExtensionProviderSharedParams,
	type ForgeCreateExtensionProps,
	type ForgeExtensionManifest,
	type ForgeExtensionParameters,
	type UpdateFunction,
} from '../types';
import { createConnectToForgeGetFieldsDefinitionFunction } from '../config/getFieldsDefinition';
import { AnalyticsAction, type AnalyticsClient } from '../utils/analytics';
import {
	AutoConvertDefinition,
	AutoConvertMatcherToExtensionsMap,
} from './macro-auto-convert/types';
import { makeAutoConvertDropdownOptions } from './macro-auto-convert/utils/makeAutoConvertDropdownOptions';

export const manifestIcons = {
	'16': () => import(/* webpackChunkName: "@atlaskit-internal_icon-16" */ './icon'),
	'24': () => import(/* webpackChunkName: "@atlaskit-internal_icon-24" */ './icon'),
	'48': () => import(/* webpackChunkName: "@atlaskit-internal_icon-48" */ './icon'),
};

type ExtensionMapperParams = Pick<
	ForgeExtensionProviderSharedParams,
	'editorActions' | 'createRenderFunction' | 'getFieldsDefinitionFunction'
> & {
	analyticsClient: AnalyticsClient;
	eventSource: string;
	fieldsModules: ExtensionManifest<ForgeExtensionParameters>['modules']['fields'];
	renderConfigHelper: typeof renderConfigHelper;
	connectToForgeBlockedExtensions: Set<unknown>;
	updateFunction: UpdateFunction;
	autoConvertDefinitions?: AutoConvertDefinition[];
	autoConvertMatcherToExtensionsMap?: AutoConvertMatcherToExtensionsMap;
};

export const updateNodeWithPayload = (
	actions: ExtensionAPI,
	localId: string,
	payload: ForgeConfigPayload,
) => {
	try {
		actions.doc.update(localId, (currentValue) =>
			mergeNodeWithPayload(currentValue?.attrs || {}, payload),
		);
	} catch (error: unknown) {
		handleNodeUpdateError(error);
	}
};

export const mergeNodeWithPayload = <T extends Record<string, unknown>>(
	attrs: T,
	{ config, body }: ForgeConfigPayload,
): { attrs: T } & Pick<ForgeCreateExtensionProps, 'content'> => {
	// HOTFIX for HOT-115700
	// https://ecosystem-platform.atlassian.net/browse/EXT-2877
	// Remove "forgeEnvironment" and "render" from the node attributes, as they would break validation
	// and therefore prevent the user from submitting a macro configuration.
	const { forgeEnvironment, render, ...cleanedAttrs } = attrs;

	return {
		attrs: {
			...(cleanedAttrs as T),
			parameters: {
				...((cleanedAttrs as T).parameters || {}),
				...transformConfigAfter(config),
			},
		},
		...(body ? { content: body.content } : {}),
	};
};

export const getExtensionManifestMapper =
	({
		analyticsClient,
		editorActions,
		eventSource,
		fieldsModules,
		renderConfigHelper,
		createRenderFunction,
		connectToForgeBlockedExtensions,
		getFieldsDefinitionFunction,
		updateFunction,
		autoConvertDefinitions,
		autoConvertMatcherToExtensionsMap,
	}: ExtensionMapperParams) =>
	(extension: ForgeUIExtensionType): ForgeExtensionManifest => {
		const { sendEvent } = analyticsClient;
		const { id, properties, environmentType, migrationKey, environmentKey } = extension;
		const title = createTitleWithEnvironmentInfo(
			properties.title || 'Forge UI Macro',
			environmentType,
			environmentKey,
		);

		const extensionIdWithoutPrefix = parse(id).resourceId!;
		const isCustomUI = getIsCustomUI(extension);
		const isUiKitTwo = extension.properties.render === 'native';

		const hasUiKitOneConfig = !!properties.config && !!properties.config.function;
		const hasConfig = isUiKitTwo || isCustomUI ? !!properties.config : hasUiKitOneConfig;
		const hasCustomConfig = (isUiKitTwo || isCustomUI) && !!properties.config?.resource;
		const hasAutoConvertLinks = !!properties.autoConvert;
		const isInline = properties.layout === 'inline';

		const connectModuleKey = extractKeyFromId(extensionIdWithoutPrefix);
		const createExtensionProps = (macroId?: string): ForgeCreateExtensionProps => {
			const localId = macroId || createLocalId();
			const extensionId = id;
			const type = getExtensionType(extension);
			return {
				// Do not add any additional props in this object or in attrs without changing the adf schema
				type: type,
				attrs: {
					extensionKey: extensionIdWithoutPrefix,
					extensionType: EXTENSION_NAMESPACE,
					// Only parameters can change without changing the adf schema
					// Be careful as connect and editor reference node.attrs.parameters.X in multiple places
					parameters: {
						localId,
						extensionId,
						extensionTitle: title,
						layout: type,
						forgeEnvironment: environmentType,
						render: properties.render,
					},
					text: title,
					localId,
				},
				...(type === 'bodiedExtension'
					? {
							// include empty content so bodiedExtension doesn't throw error
							content: [{ type: 'paragraph', content: [] }],
						}
					: {}),
			};
		};

		const icon = <Icon label={properties?.title ?? ''} imgUrl={properties.icon} />;
		const macroIcons =
			properties.icon && properties.icon.length > 0
				? {
						'16': () => Promise.resolve(() => icon),
						'24': () => Promise.resolve(() => icon),
						'48': () => Promise.resolve(() => icon),
					}
				: manifestIcons;

		const getFieldsDefinition = createConnectToForgeGetFieldsDefinitionFunction({
			analyticsClient,
			connectToForgeBlockedExtensions,
			eventSource,
			extension,
			hasCustomConfig,
			hasUiKitOneConfig,
			isCustomUI,
			isUiKitTwo,
			uiKitOneGetFieldsDefinitionFunction: getFieldsDefinitionFunction,
		});

		const makeAutoConvertDropdownOptionsInManifest = () => {
			const node = editorActions?.getSelectedNode();
			const link = node?.attrs.parameters.autoConvertLink;
			return makeAutoConvertDropdownOptions(
				autoConvertDefinitions,
				autoConvertMatcherToExtensionsMap,
				editorActions,
				link,
			);
		};

		return {
			title,
			description: properties.description,
			type: EXTENSION_NAMESPACE,
			key: extensionIdWithoutPrefix,
			icons: macroIcons,
			categories: properties.categories,
			connectModuleKey,
			migrationKey,
			createExtensionProps,
			autoConvert: properties.autoConvert,
			modules: {
				...(shouldBlockExtensionDueToAppAccessPolicy(extension)
					? {}
					: {
							quickInsert: [
								{
									key: 'forge-macro',
									action: async (actions) => {
										let extensionProps = createExtensionProps();
										const { localId } = extensionProps.attrs.parameters;
										const { type: extensionType } = extensionProps;
										const isBodiedExtension = extensionType === 'bodiedExtension';

										if (hasCustomConfig) {
											if (fg('forge_macro_custom_config_openoninsert')) {
												// Skips merging node with payload that renders the config on insert
												if (extension.properties.config?.openOnInsert === false) {
													sendEvent(AnalyticsAction.FORGE_EXTENSION_INSERTED, {
														extension,
														localId,
													});
													return extensionProps;
												}
											}

											const forgeExtensionNode = {
												...extensionProps.attrs,
												type: extensionType,
											};

											const payload = await new Promise<ForgeConfigPayload>((resolve) => {
												let isInitialInsertion = true;
												renderConfigHelper({
													extension,
													forgeExtensionNode,
													hasCustomConfig,
													isInitialInsertion: true, // We don't use the variable here because this is only executed once
													createRenderFunction,
													onSubmit: (payload: ForgeConfigPayload) => {
														if (isInitialInsertion) {
															isInitialInsertion = false;
															resolve(payload);
														} else if (actions) {
															// Update existing node
															updateNodeWithPayload(actions, localId, payload);
														}
													},
													onValidate: (data: unknown) =>
														validateForgeConfigPayload(data, {
															isBodiedExtension,
															isInitialInsertion,
															schema: editorActions?._privateGetEditorView?.()?.state.schema,
														}),
												});
											});

											extensionProps = {
												...extensionProps,
												...mergeNodeWithPayload(extensionProps.attrs, payload),
											};
										}

										sendEvent(AnalyticsAction.FORGE_EXTENSION_INSERTED, {
											extension,
											localId,
										});

										return extensionProps;
									},
								},
							],
						}),
				nodes: {
					default: {
						type: 'extension',
						render: () => createRenderFunction(extension),
						update: hasConfig
							? (params, actions) => updateFunction(params, actions, extension, hasCustomConfig)
							: undefined,
						getFieldsDefinition,
					},
				},
				fields: fieldsModules,
				contextualToolbars:
					hasAutoConvertLinks && fg('forge-ui-macro-autoconvert')
						? [
								{
									context: {
										nodeType: getExtensionType(extension),
										extensionKey: extensionIdWithoutPrefix,
										type: 'extension',
										extensionType: 'com.atlassian.ecosystem',
										shouldExclude: (node) => {
											return !node.attrs?.parameters.hasBeenAutoConverted;
										},
									},
									toolbarItems: [
										{
											key: `${extension.key}-convert-to-smartlink-toolbarItem`,
											label: 'Convert to link',
											display: 'icon',
											icon: () =>
												import(
													/* webpackChunkName: "@atlaskit-internal_icon" */ '@atlaskit/icon/core/smart-link'
												).then((mod) => mod.default),
											action: async () => {
												/*We need the editorView object to determine the position of the node.
								By default, the safeInsert method inside replaceSelection inserts nodes in pos + 1 if the selected
								node is an extension. */

												const editorView = editorActions?._privateGetEditorView();
												const node = editorActions?.getSelectedNode();
												const originalLink: string = node?.attrs.parameters.autoConvertLink;
												if (editorView) {
													const { selection } = editorView?.state;
													const insertPosition = selection.$from.pos;
													editorActions?.replaceSelection(
														isInline
															? {
																	type: 'inlineCard',
																	attrs: {
																		url: originalLink,
																	},
																}
															: {
																	type: 'paragraph',
																	content: [
																		{
																			type: 'inlineCard',
																			attrs: {
																				url: originalLink,
																			},
																		},
																	],
																},
														true,
														insertPosition,
													);

													sendEvent(AnalyticsAction.FORGE_MACRO_AUTO_CONVERT_TO_SMARTLINK, {
														extensionId: extension.key,
														forgeEnvironment: extension.environmentType,
														render: extension.properties.render,
														link: originalLink,
													});
												}
											},
											tooltip: 'Convert to link',
											ariaLabel: 'Convert to link',
										},
										{
											id: `${extension.key}-display-options-toolbarItem`,
											title: 'Display options',
											type: 'dropdown',
											options: () => makeAutoConvertDropdownOptionsInManifest(),
											tooltip: 'Display options',
										},
									],
								},
							]
						: [],
			},
		};
	};
