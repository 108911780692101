import { query } from '@confluence/query-preloader-tools';
import { fg } from '@confluence/feature-gating';
import type { AccessStatus } from '@confluence/session-data';

import {
	LicenseStatus,
	type LicensedProductsQuery as LicensedProductsQueryType,
} from './graphql/__types__/LicensedProductsQuery';
import { LicensedProductsQuery } from './graphql/LicensedProductsQuery.graphql';
import { getIsLoomDisabled } from './getIsLoomDisabled';
import { getIsLoomBetaEnabled } from './getIsLoomBetaEnabled';
import type { LoomEntryPointVariant } from './useLoomEntryPointVariantState';
import {
	LoomTenantProvisionStatus,
	getLoomUserPermissionStatus,
	LoomUserPermissionStatus,
} from './useLoomEntryPointVariantState';

declare global {
	interface Window {
		__SSR_LOOM_ENTRY_POINT__?: LoomEntryPointVariant | null;
	}
}

export type preloadRecordLoomButtonProps = {
	cloudId: string;
	accessStatus: AccessStatus;
	isLoggedIn: boolean;
	userId: string | null;
	environment: string;
};
/*
 * Preloader function to determine entrypoint variant, which follows the same logic in next/packages/loom-utils/src/useLoomEntryPointVariantState.tsx.
 * The function is replicated here because hooks do not execute during SSR (including useIsLoomDisabled/useIsLoomBetaEnabled) and there is no access to the central state
 *
 * NOTE: CHANGES TO THIS LOGIC MUST ALSO BE REPLICATED IN next/packages/loom-utils/src/useLoomEntryPointVariantState.tsx TO ENSURE THE CORRECT EXPERIENCE DURING SSR
 */

export async function preloadRecordLoomButton({
	cloudId,
	accessStatus,
	isLoggedIn,
	userId,
	environment,
}: preloadRecordLoomButtonProps): Promise<any> {
	const isLoomDisabled = getIsLoomDisabled({ accessStatus, isLoggedIn });
	const isBetaEnabled = getIsLoomBetaEnabled(accessStatus);

	if (window['__SSR_LOOM_ENTRY_POINT__']) {
		return Promise.resolve();
	}

	if (isLoomDisabled) {
		window['__SSR_LOOM_ENTRY_POINT__'] = { entryPointVariant: 'DISABLED' };
		return Promise.resolve();
	}

	if (isBetaEnabled) {
		window['__SSR_LOOM_ENTRY_POINT__'] = { entryPointVariant: 'BETA' };
		return Promise.resolve();
	}

	// For now, we have to do the Beta check first because `confluence_frontend_loom_cross_sell` is
	// not enabled on Beta sites
	if (!fg('confluence_frontend_loom_cross_sell')) {
		window['__SSR_LOOM_ENTRY_POINT__'] = { entryPointVariant: 'DISABLED' };
		return Promise.resolve();
	}

	try {
		const { data } = await query<LicensedProductsQueryType, any>({
			query: LicensedProductsQuery,
		});

		const isTenantLicensed = data?.tenantContext?.licensedProducts.some(
			(product) => product.productKey === 'loom' && product.licenseStatus === LicenseStatus.ACTIVE,
		);

		const loomTenantProvisionStatus = isTenantLicensed
			? LoomTenantProvisionStatus.PROVISIONED
			: LoomTenantProvisionStatus.NOT_PROVISIONED;

		if (loomTenantProvisionStatus === LoomTenantProvisionStatus.NOT_PROVISIONED) {
			window['__SSR_LOOM_ENTRY_POINT__'] = { entryPointVariant: 'CROSS_FLOW' };

			// if entry point variant is "CROSS_FLOW" but FF is enabled, return "DISABLED"
			if (fg('confluence_frontend_loom_cross_flow_ads_opt_out')) {
				window['__SSR_LOOM_ENTRY_POINT__'] = { entryPointVariant: 'DISABLED' };
			}
			return Promise.resolve();
		}
	} catch {
		window['__SSR_LOOM_ENTRY_POINT__'] = { entryPointVariant: 'CROSS_FLOW', error: true };

		// if entry point variant is "CROSS_FLOW" but FF is enabled, return "DISABLED"
		if (fg('confluence_frontend_loom_cross_flow_ads_opt_out')) {
			window['__SSR_LOOM_ENTRY_POINT__'] = { entryPointVariant: 'DISABLED' };
		}
		return Promise.resolve();
	}

	// tenant is provisioned at this point, switch to "CROSS_JOIN" and attempt permissions check
	window['__SSR_LOOM_ENTRY_POINT__'] = { entryPointVariant: 'CROSS_JOIN' };

	// PERMISSIONS CHECK
	const loomUserPermissionStatus = await getLoomUserPermissionStatus(cloudId, userId, environment);

	if (loomUserPermissionStatus === LoomUserPermissionStatus.NOT_PERMITTED) {
		// if not permitted, entryPointVariant is already 'CROSS_JOIN' so resolve promise
		return Promise.resolve();
	}

	if (loomUserPermissionStatus === LoomUserPermissionStatus.ERROR) {
		window['__SSR_LOOM_ENTRY_POINT__'] = { entryPointVariant: 'CROSS_JOIN', error: true };
		return Promise.resolve();
	}

	window['__SSR_LOOM_ENTRY_POINT__'] = { entryPointVariant: 'CO_USE' };
	return Promise.resolve();
}
