import type AnalyticsWebClient from '@atlassiansox/analytics-web-client';

import { MessageDeliveryStatus } from './constants';
import type {
	Attributes,
	IChoreographerPlugin,
	ILogger,
	LoggerState,
	MessageActions,
	MessageDeliveryStatuses,
} from './types';

export class Logger implements ILogger {
	protected state: LoggerState;

	/**
	 * Utility class to simplify and allow for wider use of sending operational event to the Analytics web client for logging/metrics.
	 *
	 * @param actionSubject A (MessageActions) string denoting either the start or stop phase of a message.
	 * @param actionSubjectId Unique messageId to be logged with this even.
	 * @param metricsData Additional data from plugin to be sent with event for metrics logging.
	 * @param analyticsClient An instance of AnalyticsWebClient, unique to each caller
	 * @param [plugin] An optional plugin instance.
	 */
	constructor(
		actionSubject: MessageActions,
		actionSubjectId: string,
		metricsData: Attributes,
		analyticsClient: AnalyticsWebClient,
		plugin?: IChoreographerPlugin,
	) {
		this.state = {
			actionSubject: actionSubject,
			actionSubjectId: actionSubjectId,
			metricsData,
			analyticsClient: analyticsClient,
			plugin,
		};
	}

	/**
	 * Send operational event to the Analytics web client for logging/metrics.
	 *
	 * @param action A (MessageDeliveryStatuses) string denoting the ultimate disposition of this message start or stop request.
	 */
	private sendOperationalEvent(action: MessageDeliveryStatuses, additionalAttributes?: Attributes) {
		const {
			analyticsClient,
			actionSubject,
			actionSubjectId,
			metricsData: attributes,
			plugin,
		} = this.state;

		const event = {
			source: 'choreographer',
			action,
			actionSubject,
			actionSubjectId,
			attributes: {
				...(attributes ?? {}),
				...(additionalAttributes ?? {}),
			},
			tags: ['postOffice'],
		};

		if (plugin?.shouldLogEvent?.(event) ?? true) {
			void analyticsClient.sendOperationalEvent(event);
		}
	}

	blocked(additionalAttributes?: Attributes) {
		this.sendOperationalEvent(MessageDeliveryStatus.BLOCKED, additionalAttributes);
	}

	error(additionalAttributes?: Attributes) {
		this.sendOperationalEvent(MessageDeliveryStatus.ERROR, additionalAttributes);
	}

	unregistered(additionalAttributes?: Attributes) {
		this.sendOperationalEvent(MessageDeliveryStatus.UNREGISTERED, additionalAttributes);
	}

	started(additionalAttributes?: Attributes) {
		this.sendOperationalEvent(MessageDeliveryStatus.STARTED, additionalAttributes);
	}

	stopped(additionalAttributes?: Attributes) {
		this.sendOperationalEvent(MessageDeliveryStatus.STOPPED, additionalAttributes);
	}
}
