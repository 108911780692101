import { fg } from '@confluence/feature-gating';

import { fetchDraft } from './fetchDraft';

declare global {
	interface Window {
		__PRELOAD_DRAFT__?: any;
	}
}

export async function preloadDraft(contentId: string, draftShareId?: string) {
	window.__PRELOAD_DRAFT__ = {
		contentId,
		draftFetchPromise: fetchDraft(contentId, draftShareId),
		// eslint-disable-next-line no-restricted-properties
		startTime: window?.performance?.now?.(),
	};
}

let hasDraftSSRPreloadError = false;
export function markDraftSSRPreloadPermissionError(value = true) {
	hasDraftSSRPreloadError = value;
}

const DRAFT_PERMISSION_ERROR =
	'com.atlassian.confluence.api.service.exceptions.PermissionException: User does not have permission';

export function isDraftSSRPreloadPermissionError(message?: string) {
	if (!message) return false;
	return message.indexOf(DRAFT_PERMISSION_ERROR) > -1 || false;
}

export function isEditPageDraftRestricted() {
	return hasDraftSSRPreloadError || window.__SSR_EDIT_PAGE_DRAFT_RESTRICTED__ || false;
}

export function editPageDraftRestrictedProp() {
	if (hasDraftSSRPreloadError && fg('cc_ssr_edit_page_restricted')) {
		return { __SSR_EDIT_PAGE_DRAFT_RESTRICTED__: true };
	}

	return undefined;
}
