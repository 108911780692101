import { userTransformer } from './users';

export const getKind = (content, labels) => {
	if (labels) {
		const kind = getKindFromLabels(labels);

		if (kind) {
			return kind;
		}
	}

	// TODO: that will need to be i18n
	const typesToKind = {
		page: 'Page',
		blog: 'Blogpost',
		question: 'Question',
	};

	return typesToKind[content.type];
};

export const getKindFromLabels = (labels) => {
	if (!labels.length) {
		return null;
	}

	// TODO: that will need to be i18n
	const labelToKind = {
		'meeting-notes': 'Meeting notes',
		'product-requirements': 'Product requirement',
		decisions: 'Decision',
		'file-list': 'File list',
		retrospective: 'Retrospective',
		'kb-how-to-article': 'How to article',
		'task-report': 'Task report',
		jirareport: 'Jira report',
		'shared-links': 'Shared link',
		'kb-troubleshooting-article': 'Troubleshooting article',
		'gliffy-blueprint': 'Gliffy diagram',
	};

	const acceptedLabels = Object.keys(labelToKind);
	const found = labels.filter((label) => acceptedLabels.indexOf(label.name) > -1);

	return found.length ? labelToKind[found[0].name] : null;
};

export const getMetaDataLabelResults = (item, defaultResult) => {
	if (
		typeof item === 'object' &&
		item &&
		typeof item.metadata === 'object' &&
		item.metadata &&
		typeof item.metadata.labels === 'object' &&
		item.metadata.labels &&
		typeof item.metadata.labels.results !== 'undefined'
	) {
		return item.metadata.labels.results;
	}
	return defaultResult;
};

export function contentTransformer(response) {
	return {
		...response,
		version: {
			...response.version,
			id: response.id,
			by: {
				...response.version.by,
			},
		},
		contributors:
			response.history &&
			response.history.contributors &&
			response.history.contributors.publishers &&
			response.history.contributors.publishers.users &&
			response.history.contributors.publishers.users.map(userTransformer),
		labels: getMetaDataLabelResults(response, []),
	};
}
