import { preloadSpaceGuestList } from '@confluence/space-guest-list/entry-points/preloadSpaceGuestList';
import { preloadShortcutsSmartLinks } from '@confluence/space-shortcuts/entry-points/preloadShortcutsSmartLinks';
import {
	preloadSideNavigation,
	preloadSideNavigationNeedsContentId,
} from '@confluence/side-navigation/entry-points/preloadSideNavigation';
import { preloadSpaceViews } from '@confluence/space-views/entry-points/preloadSpaceViews';
import { preloadPageTree } from '@confluence/page-tree/entry-points/preloadPageTree';
import { preloadBlogTree } from '@confluence/blog-tree/entry-points/preloadBlogTree';
import { PAGE_TREE_STATUSES } from '@confluence/page-tree/entry-points/pageTreeStatuses';
import { preloadPostOfficeConfluenceSideNav } from '@confluence/experiment-post-office-side-nav/entry-points/preloadConfluenceSideNav';
import { fg } from '@confluence/feature-gating';
import { SSRMeasures } from '@confluence/action-measures';
import { getPreloaderFnContext } from '@confluence/query-preloader-tools';
import { expValEqualsNoExposure } from '@confluence/feature-experiments';

export const preloadNavigationTasks = async (
	spaceKey: string,
	contentId: string,
	isLicensed: boolean,
	isBlog: boolean,
) => {
	const tasks: Promise<any>[] = [];
	tasks.push(
		preloadSpaceGuestList(spaceKey),
		preloadShortcutsSmartLinks(spaceKey),
		preloadSideNavigation(spaceKey),
		preloadSideNavigationNeedsContentId(spaceKey, contentId),
		preloadSpaceViews(spaceKey, isLicensed),
	);

	tasks.push(
		preloadPageTree({
			spaceKey,
			...(isBlog ? {} : { contentId }),
			statuses: PAGE_TREE_STATUSES,
			isSuperAdmin: false,
		}),
		preloadBlogTree({ spaceKey }),
	);

	if (fg('po-connie-side-nav-ssr-shadow')) {
		// Confluence Side Nav in SSR
		const preloadedSessionContext = await getPreloaderFnContext();
		const isLocaleEligible = preloadedSessionContext.locale.toLowerCase().startsWith('en');

		if (
			preloadedSessionContext.userId &&
			isLocaleEligible &&
			// Suppress exposure as this is not the true exposure point, check required to prevent unnecessary preloading
			// eslint-disable-next-line confluence-feature-gating/no-exposure-recommendation
			expValEqualsNoExposure('post-office_expt_confluence-next-best-actions', 'cohort', 'variation')
		) {
			const preloadPostOfficeSideNav = async () => {
				return SSRMeasures.run(
					'ssr-app/prepare/preloadQuery/postOffice:ConfluenceSideNav',
					async () => {
						return preloadPostOfficeConfluenceSideNav(preloadedSessionContext);
					},
				);
			};

			tasks.push(preloadPostOfficeSideNav());
		}
	}

	return Promise.all(tasks);
};
