import { getApolloClient, markErrorAsHandled } from '@confluence/graphql';
import { getCompanyHubSpaceKey } from '@confluence/route-manager/entry-points/companyHubUtils';

import type { CompanyHubSpaceQuery as CompanyHubSpaceQueryType } from './__types__/CompanyHubSpaceQuery';
import { CompanyHubSpaceQuery } from './CompanyHubSpaceQuery.graphql';

export async function checkIfCompanyHubSpaceExists(): Promise<boolean> {
	const key = getCompanyHubSpaceKey();
	if (!key) {
		throw new Error('Failed to get company hub space key');
	}

	try {
		const { data } = await getApolloClient().query<CompanyHubSpaceQueryType>({
			query: CompanyHubSpaceQuery,
			variables: { key },
		});
		return !!data?.space?.id;
	} catch (error) {
		if (error?.message.includes('NotFoundException')) {
			markErrorAsHandled(error);
			return false;
		} else {
			throw error;
		}
	}
}
