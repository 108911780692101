import { addFeatureFlagAccessed as addFeatureFlagAccessedForUFO } from '@atlaskit/react-ufo/feature-flags-accessed';
import { StatsigConfigurations } from '@confluence/statsig-client/entry-points/StatsigConfigurations';

import {
	ALLOWLIST_FFS_CUSTOM_EVENTS,
	FF_ALLOWLIST_FOR_PERF_METRICS,
} from '@confluence/browser-metrics';
import { Cookies } from '@confluence/cookies';
import { fg } from '@confluence/feature-gating';
import {
	applyPersistedOverrides,
	applyPersistedStatsigOverrides,
	updateSessionData,
} from '@confluence/session-data';
import { getSSRFeatureFlag } from '@confluence/ssr-utilities';
import { initializeStorageManager } from '@confluence/storage-manager';

export const postProcessSessionData = async (sessionData) => {
	const { userId, featureFlags, isLicensed } = sessionData;

	if (fg('confluence_frontend_move_initialize_controls')) {
		await Cookies.initialize();
	}

	// Local and session storage
	initializeStorageManager({ userId, isLicensed }).then(() => {
		// Load and process FF overrides persisted by storage-manager
		updateSessionData(sessionData, applyPersistedOverrides(featureFlags));

		// Apply Statsig overrides persisted by storage-manager if this is not an integration test site
		if (
			!StatsigConfigurations.isKillSwitchOn('confluence_ssr_apply_persisted_overrides_blocklist')
		) {
			applyPersistedStatsigOverrides();
		}
	});

	// To add allowed feature flags to UFO payload
	[...FF_ALLOWLIST_FOR_PERF_METRICS, ...ALLOWLIST_FFS_CUSTOM_EVENTS].forEach((flag) => {
		if (featureFlags.hasOwnProperty(flag) && typeof featureFlags[flag] !== 'object') {
			addFeatureFlagAccessedForUFO(flag, featureFlags[flag]);
			return;
		}
		const ssrFeatureFlag = getSSRFeatureFlag(flag);
		if (
			ssrFeatureFlag !== null &&
			ssrFeatureFlag !== undefined &&
			typeof ssrFeatureFlag !== 'object'
		) {
			addFeatureFlagAccessedForUFO(flag, ssrFeatureFlag);
			return;
		}

		// eslint-disable-next-line confluence-feature-gating/static-feature-gates, confluence-feature-gating/inline-usage
		const statsigFlag = fg(flag);

		if (typeof statsigFlag !== 'object') {
			addFeatureFlagAccessedForUFO(flag, statsigFlag);
		}
	});

	return sessionData;
};
