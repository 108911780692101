import React from 'react';

import type { TemplateVariableComponentProps } from './types';
import {
	TemplateVariableNameWrapper,
	TemplateVariableName,
	TemplateVariableType,
} from './SharedComponents';

export const TemplateVariableComponent = ({ parameters }: TemplateVariableComponentProps) => {
	return (
		<span>
			<TemplateVariableNameWrapper>
				<TemplateVariableName data-testid="variable-name">{parameters?.name}</TemplateVariableName>
			</TemplateVariableNameWrapper>
			<TemplateVariableType data-testid="variable-type">
				{`| ${parameters?.type}`}
			</TemplateVariableType>
		</span>
	);
};
