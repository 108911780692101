import { fg } from '@confluence/feature-gating';
import { expVal } from '@confluence/feature-experiments';

import { useNav4OptOut } from './useNav4OptOut';
import { getNav4GAPhase } from './getNav4GAPhase';
import { useGetNav4OptIn } from './useGetNav4OptIn';

// eslint-disable-next-line confluence-feature-gating/inline-usage
export const useIsNav4Enabled = () => {
	const currentGAPhase = getNav4GAPhase();
	const { nav4AdminOptedIn } = useGetNav4OptIn();
	const { nav4OptOut } = useNav4OptOut();

	if (currentGAPhase === 2) {
		return true;
	}

	if (nav4OptOut) {
		return false;
	}

	return (
		fg('confluence_nav_4') ||
		expVal('confluence_jira_nav4_new_customers', 'willShowNav4', false) ||
		expVal('confluence_nav_4_free', 'isEnabled', false) ||
		// nav4OptOut === false means the user has explicitely opted in for themselved
		(currentGAPhase === 1 && (nav4AdminOptedIn || nav4OptOut === false))
	);
};
