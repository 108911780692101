import gql from 'graphql-tag';

export const SpaceOverviewIdQuery = gql`
	query SpaceOverviewIdQuery($spaceKey: String) {
		space(key: $spaceKey) {
			id
			homepageId
		}
	}
`;
