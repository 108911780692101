import React, { useEffect, useState } from 'react';
import { type NativeUserPickerProps, type RenderFn } from '@atlassian/forge-ui-types';
import { useUsers, type UserData } from '../../../web-client/hooks/useUser';
import { type Props } from '../..';

import {
	type UserQuery,
	useMentionResource,
} from '../../UIKit1/userPicker/providers/useMentionResource';

import { UserPicker } from './UserPicker';
import type { Dependencies } from './types';
import { type UserPickerOption } from '../../UIKit1/form/transformFormData';

// Format the values(UserData) returned from useUsers hook, to pass to defaultValue prop
const hydrateDefaultValue = (users: { [accountId: string]: UserData }, isMulti = false) => {
	const defaultValues: UserPickerOption[] = [];

	for (const userId in users) {
		const { name, picture } = users[userId];
		// Only grabbing the values where user name exist
		if (name) {
			defaultValues.push({
				name,
				avatarUrl: picture,
				id: userId,
				type: 'user',
			});
		}
	}
	return isMulti ? defaultValues : defaultValues[0];
};

const UserPickerFn: React.FunctionComponent<Pick<Props, 'props'> & Dependencies> = ({
	client,
	mentionProvider,
	accountId,
	cloudId,
	productAttributes,
	productKey,
	baseUrl,
	props,
}) => {
	const {
		name,
		label,
		placeholder,
		description,
		// Warning: Smart user picker ONLY supports default value hydration
		// of *users*, not groups!
		defaultValue: defaultUserValue = '',
		isRequired,
		isMulti,
		includeUsers,
		includeGroups,
		onChange,
	} = props as NativeUserPickerProps;
	const query = useMentionResource(mentionProvider);
	const accountIds = Array.isArray(defaultUserValue) ? defaultUserValue : [defaultUserValue];
	const usersData = useUsers(accountIds, client);

	// Hydrate the default value ourselves, since we are controlling value from <Field>
	// component value. It will get passed into the user picker as simply `value`.
	const hydratedDefaultValue = hydrateDefaultValue(usersData, isMulti);

	return (
		<UserPicker
			accountId={accountId}
			cloudId={cloudId}
			productAttributes={productAttributes}
			productKey={productKey}
			mentionProvider={mentionProvider}
			name={name}
			label={label}
			placeholder={placeholder}
			description={description}
			defaultValue={hydratedDefaultValue}
			isRequired={isRequired}
			isMulti={isMulti}
			includeUsers={includeUsers}
			includeGroups={includeGroups}
			baseUrl={baseUrl}
			onChange={onChange}
			query={query}
		/>
	);
};

export const makeMockUserPicker = ({
	query,
	accountId,
	cloudId,
	productKey,
	baseUrl,
}: Omit<Dependencies, 'client' | 'mentionProvider'> & { query: UserQuery }) => {
	return function UserPickerNext({ forgeDoc: { props } }: Parameters<RenderFn>[0]) {
		const {
			name,
			label,
			placeholder,
			description,
			isRequired,
			isMulti,
			includeUsers,
			includeGroups,
			onChange,
			defaultValue: defaultUserId,
		} = props as NativeUserPickerProps;

		const [defaultValueLoaded, setDefaultValueLoaded] = useState(!defaultUserId);
		const [defaultValue, setDefaultValue] = useState<
			UserPickerOption | UserPickerOption[] | undefined
		>();
		useEffect(() => {
			if (defaultUserId && !defaultValueLoaded) {
				query().then((users) => {
					const targetUsers = users.filter(({ id }) =>
						Array.isArray(defaultUserId) ? defaultUserId.includes(id) : defaultUserId === id,
					);
					if (targetUsers.length === 0) {
						setDefaultValue(undefined);
					} else {
						if (isMulti) {
							setDefaultValue(targetUsers);
						} else {
							setDefaultValue(targetUsers[0]);
						}
					}
					setDefaultValueLoaded(true);
				});
			}
		}, [isMulti, defaultUserId, defaultValueLoaded, setDefaultValue, setDefaultValueLoaded]);

		if (!defaultValueLoaded) {
			return <>{null}</>;
		}

		return (
			<UserPicker
				accountId={accountId}
				cloudId={cloudId}
				productKey={productKey}
				baseUrl={baseUrl}
				name={name}
				label={label}
				placeholder={placeholder}
				description={description}
				isRequired={isRequired}
				isMulti={isMulti}
				includeUsers={includeUsers}
				includeGroups={includeGroups}
				onChange={onChange}
				query={query}
				defaultValue={defaultValue}
			/>
		);
	};
};

// ApolloClient, cloudId and productKey must be passed as props,
// and will not be fetched via context.
export const makeNativeUserPicker = ({
	client,
	mentionProvider,
	accountId,
	cloudId,
	productKey,
	baseUrl,
	productAttributes,
}: Dependencies) => {
	return function UserPickerNext({ forgeDoc: { props } }: Parameters<RenderFn>[0]) {
		return (
			<UserPickerFn
				props={props}
				client={client}
				mentionProvider={mentionProvider}
				accountId={accountId}
				cloudId={cloudId}
				productAttributes={productAttributes}
				productKey={productKey}
				baseUrl={baseUrl}
			/>
		);
	};
};

export { isProductKey } from './types';
