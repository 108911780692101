import gql from 'graphql-tag';

export const ByLineLastUpdatedQuery = gql`
	query ByLineLastUpdatedQuery($contentId: ID!, $status: [String]) {
		content(id: $contentId, status: $status) {
			nodes {
				id
				version {
					friendlyWhen
					when
				}
			}
		}
	}
`;
