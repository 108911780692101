import { query } from '@confluence/query-preloader-tools';
import { fg } from '@confluence/feature-gating';

import { EditorContentStateQuery } from './EditorContentStateQuery.graphql';
import type {
	EditorContentStateQuery as EditorContentStateQueryType,
	EditorContentStateQueryVariables,
} from './__types__/EditorContentStateQuery';

type PreloadEditorContentStateParams = {
	contentId: any;
	useCache: boolean;
	shouldGetDraftVersion?: boolean;
};

export function preloadEditorContentState({
	contentId,
	useCache,
	shouldGetDraftVersion = false,
}: PreloadEditorContentStateParams) {
	return query<EditorContentStateQueryType, EditorContentStateQueryVariables>({
		query: EditorContentStateQuery,
		variables: {
			contentId,
			isDraft: fg('confluence_live_docs_content_state_publish_version')
				? shouldGetDraftVersion
				: true,
		},
		fetchPolicy: useCache ? 'cache-first' : 'network-only',
	});
}
