import React from 'react';

import { aiConfigItemMessages } from '@atlaskit/editor-common/ai-messages';
import { fg } from '@atlaskit/platform-feature-flags';

import { BrainstormIcon } from '../../../../icons/prompt/brainstorm';
import { createInsertAtCurrentPosition } from '../../actions/create-actions';
import { CONFIG_ITEM_KEYS } from '../../config-item-keys';
import type {
	EditorPluginAIConfigItemWithOptions,
	EditorPluginAIConfigItem,
} from '../../config-items';

export const brainstorm: EditorPluginAIConfigItem = {
	key: CONFIG_ITEM_KEYS.BRAINSTORM,
	title: aiConfigItemMessages.brainstormTitle,
	description: aiConfigItemMessages.brainstormDescription,
	icon: () => <BrainstormIcon />,
	promptLabel: aiConfigItemMessages.brainstormPromptLabel,
	promptHint: aiConfigItemMessages.brainstormPromptHint,
	doNotHighlightDocWhenSelectionIsEmpty: true,
	agentId: 'editor_agent',
	intentSchemaId: 'brainstorm_intent_schema.json',
	alwaysNoDocAndSelection: true,
	actions: {
		empty: [createInsertAtCurrentPosition({ appearance: 'primary' })],
	},
	getBackendModel: () => {
		if (
			fg('platform_editor_ai_assistance_service') ||
			fg('platform_editor_ai_assistance_service_second')
		) {
			return 'assistance-service';
		}

		return 'xp-gen-ai';
	},
};

export const brainstormWithOptions: EditorPluginAIConfigItemWithOptions = {
	config: brainstorm,
	triggers: { empty: { enabled: true, docMinSize: 0 }, selection: { enabled: false } },
};
