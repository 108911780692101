import { query } from '@confluence/query-preloader-tools';

import {
	type DataSecurityPolicyDecidableContentStatus,
	type DSPIsActionEnabledForContentQuery as DSPIsActionEnabledForContentQueryT,
	type DSPIsActionEnabledForContentQueryVariables,
	DataSecurityPolicyAction,
} from './__types__/DSPIsActionEnabledForContentQuery';
import { DSPIsActionEnabledForContentQuery } from './DSPIsActionEnabledForContentQuery.graphql';

export const preloadIsAttachmentDownloadEnabled = async (contentId: string, spaceKey: string) => {
	const response = await query<
		DSPIsActionEnabledForContentQueryT,
		DSPIsActionEnabledForContentQueryVariables
	>({
		query: DSPIsActionEnabledForContentQuery,
		variables: {
			action: DataSecurityPolicyAction.PAGE_EXPORT,
			contentId,
			contentStatus: 'CURRENT' as DataSecurityPolicyDecidableContentStatus,
			contentVersion: 1,
			spaceKey,
		},
	});

	// eslint-disable-next-line check-react-ssr-usage/no-react-ssr
	if (process.env.REACT_SSR) {
		const { mediaConfigEnforceDSPRecorder } = await import(
			/* webpackChunkName: "loadable-confluencessr-utilities" */ '@confluence/ssr-utilities'
		);
		const isAttachmentDownloadAllowed =
			response?.data?.dataSecurityPolicy?.isActionEnabledForContent?.allowed || false;
		// When attachment download is not allowed, enforce the data security policy
		mediaConfigEnforceDSPRecorder.set(!isAttachmentDownloadAllowed);
	}

	return response;
};
