import { fg } from '@atlaskit/platform-feature-flags';

import { V003_SCHEMA_KEY } from '../../../constants';
import { fetchConsentPreferences } from '../../../services/consent-hub-service';
import { checkTokenVersionValidity } from '../transformer';

import { type ValidConsentData } from './types';

export const getValidConsentData = async (
	fallbackConsentToken?: string,
): Promise<ValidConsentData | undefined> => {
	// Fetches preferences from ConsentHub and returns serialized token data if possible
	const consentData = await fetchConsentPreferences();
	let { consentToken, consentTimestamp, consentHubInaccessible } = consentData;

	let { isValid } = checkTokenVersionValidity(consentToken);

	if (isValid && consentHubInaccessible) {
		if (fallbackConsentToken) {
			// `consentHubInaccessible`: ConsentHub request failed (e.g. service is down/bad request/etc). Attempt to use
			// any previously set consents in the interim if we have them.
			consentToken = fallbackConsentToken;
		} else {
			if (fg('platform_moonjelly_bsc_fallback_token_audit')) {
				const version = V003_SCHEMA_KEY;
				const consents = consentToken.slice(3, 7);

				// V003 BSC bits set to reflect default consent token status, authenticated user status,
				// and unavailable consentHub records status)
				const serviceBits = '111';

				// Temporary: Sets a default token with V003 properties
				// when ConsentHub is inaccessible and there is not fallback token. This
				// will ensure the banner isn't shown when ConsentHub is down.
				consentToken = `${version}${consents}${serviceBits}`;
			} else {
				// Temporary: Sets the default token to have true consentedUnderXXXX bits
				// when ConsentHub is inaccessible and there is not fallback token. This
				// will ensure the banner isn't shown when ConsentHub is down.
				consentToken = `${consentToken.slice(0, 8)}1111`;
			}
		}
	}

	return isValid ? { consentToken, consentTimestamp } : undefined;
};
