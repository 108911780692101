import React from 'react';

import { BadgeFn } from '../components/UIKit1/badge';
import { Button } from '../components/UIKit1/button';
import { ButtonSetFn } from '../components/UIKit1/button/ButtonSet';
import { CheckboxGroupFn } from '../components/UIKit1/checkbox';
import { CodeFn } from '../components/UIKit1/code';
import { DateLozengeFn } from '../components/UIKit1/dateLozenge';
import { DatePickerFn } from '../components/UIKit1/datepicker';
import { ErrorPanelRenderFn } from '../components/UIKit1/errorPanel/errorPanel';
import { Form } from '../components/UIKit1/form';
import { FormConditionFn } from '../components/UIKit1/formCondition';
import { ImageFn } from '../components/UIKit1/image';
import { InlineDialog } from '../components/UIKit1/inlineDialog';
import { ModalDialog } from '../components/UIKit1/modalDialog';
import { RadioGroupFn } from '../components/UIKit1/radio';
import { RangeFn } from '../components/UIKit1/range';
import { SectionMessageFn } from '../components/UIKit1/sectionMessage';
import { SelectFn } from '../components/UIKit1/select';
import { StatusLozengeFn } from '../components/UIKit1/statusLozenge';
import { TabsFn } from '../components/UIKit1/tabs';
import { TableFn } from '../components/UIKit1/table';
import { TagFn, TagGroupFn } from '../components/UIKit1/tag';
import { TextFn } from '../components/UIKit1/text';
import { TextAreaFn } from '../components/UIKit1/textarea';
import { TextFieldFn } from '../components/UIKit1/textfield';
import { ToggleFn } from '../components/UIKit1/toggle';
import { TooltipFn } from '../components/UIKit1/tooltip';
import { ViewFn } from '../components/UIKit1/view';
import { HeadingFn } from '../components/UIKit1/heading';

import { EmFn, LinkFn, StrikeFn, StringFn, StrongFn } from '../components/UIKit1/markup';
import { upgradeComponent, type ComponentMap } from './util';

export const uiKit1Components: ComponentMap = {
	Badge: upgradeComponent(BadgeFn),
	Button: (args) => <Button {...args} key={args.forgeDoc.key} />,
	ButtonSet: upgradeComponent(ButtonSetFn),
	CheckboxGroup: upgradeComponent(CheckboxGroupFn),
	Code: upgradeComponent(CodeFn),
	DateLozenge: upgradeComponent(DateLozengeFn),
	DatePicker: upgradeComponent(DatePickerFn),
	Em: upgradeComponent(EmFn),
	ErrorPanel: ErrorPanelRenderFn,
	Form: (args) => <Form {...args} key={args.forgeDoc.key} />,
	FormCondition: upgradeComponent(FormConditionFn),
	Heading: upgradeComponent(HeadingFn),
	Image: upgradeComponent(ImageFn),
	InlineDialog: (args) => <InlineDialog {...args} key={args.forgeDoc.key} />,
	Link: upgradeComponent(LinkFn),
	ModalDialog: (args) => <ModalDialog {...args} key={args.forgeDoc.key} />,
	RadioGroup: upgradeComponent(RadioGroupFn),
	Range: upgradeComponent(RangeFn),
	SectionMessage: upgradeComponent(SectionMessageFn),
	Select: upgradeComponent(SelectFn),
	StatusLozenge: upgradeComponent(StatusLozengeFn),
	Strike: upgradeComponent(StrikeFn),
	String: upgradeComponent(StringFn),
	Strong: upgradeComponent(StrongFn),
	Table: upgradeComponent(TableFn),
	Tabs: upgradeComponent(TabsFn),
	Tag: upgradeComponent(TagFn),
	TagGroup: upgradeComponent(TagGroupFn),
	Text: upgradeComponent(TextFn),
	TextArea: upgradeComponent(TextAreaFn),
	TextField: upgradeComponent(TextFieldFn),
	Toggle: upgradeComponent(ToggleFn),
	Tooltip: upgradeComponent(TooltipFn),
	View: upgradeComponent(ViewFn),
};
