import { type ComponentPropsConfig } from '../../../utils/codegen';

/**
 * A stack manages the vertical layout of direct children using flexbox.
 */
export const componentPropsConfig: ComponentPropsConfig = {
	children: true,
	alignBlock: true,
	alignInline: true,
	grow: true,
	space: true,
	spread: true,
	testId: true,
};

/**
 * THIS SECTION WAS CREATED VIA CODEGEN DO NOT MODIFY {@see http://go/af-codegen}
 *
 * This component was generated against an existing platform component.
 * If you are seeing lint errors in this file, it means that this file's dependencies have been modified and the component will need to be regenerated.
 *
 * Please view https://hello.atlassian.net/wiki/spaces/ECO/pages/3358130330/How+to+Regenerate+UI+Kit+Components for more information on how to proceed.
 *
 * For further questions, please ping the #help-forge-ui channel.
 *
 * @codegen <<SignedSource::a3c53bc80d75614b9e0a59c110b19ac7>>
 * @codegenId #stack
 * @codegenCommand yarn workspace @atlassian/forge-ui codegen stack
 * @codegenDependency ../../../../../../../design-system/primitives/src/components/stack.tsx <<SignedSource::0a5e084e1117126351333d4974c451d4>>
 * @codegenDependency ../../../../../../../design-system/primitives/src/components/types.tsx <<SignedSource::9529629bd4dfcf934c9dfc41516de683>>
 * @codegenDependency ../../../../../../../design-system/primitives/src/xcss/style-maps.partial.tsx <<SignedSource::8bcf55e5bf649e3b56615551591fb48d>>
 * @codegenDependency ../../../../../../../design-system/primitives/src/xcss/xcss.tsx <<SignedSource::4f9c0f527ba99a4723d89b2126ae70b1>>
 * @codegenDependency ../../../../../../../design-system/primitives/src/components/flex.tsx <<SignedSource::fa7ea17c137d6a8d10cc7536f8e48f3f>>
 */
/* eslint @repo/internal/codegen/signed-source-integrity: "warn" */
import React from 'react';
import type { RenderFn } from '@atlassian/forge-ui-types';
import { Stack as PlatformStack } from '@atlaskit/primitives';

type PlatformStackProps = React.ComponentProps<typeof PlatformStack>;

export type StackProps = Pick<
  PlatformStackProps,
  'children' | 'alignBlock' | 'alignInline' | 'grow' | 'space' | 'spread' | 'testId'
>;

/**
 * A stack manages the vertical layout of direct children using flexbox.
 */
export const Stack = (props: Parameters<RenderFn>[0]) => {
  const {
    alignBlock,
    alignInline,
    grow,
    space,
    spread,
    testId,
  } = props.forgeDoc.props as StackProps;
  return (
    <PlatformStack
      children={props.forgeDoc.children.map(c => <React.Fragment key={c.key}>{props.render(c)}</React.Fragment>)}
      alignBlock={alignBlock}
      alignInline={alignInline}
      grow={grow}
      space={space}
      spread={spread}
      testId={testId}
    />
  );
};
/**
 * @codegenEnd
 */
