import { type ComponentType } from 'react';
import { type MessageFormatElement } from 'react-intl-next';

import IntlProvider, { type IntlProviderProps } from './IntlProvider';

export const withIntlProvider =
	<P extends object, T extends Record<string, string> | Record<string, MessageFormatElement[]>>(
		intlProviderProps: IntlProviderProps<T>,
		Component: ComponentType<Omit<P, 'preloadedMessages'>>,
	): ComponentType<P & { preloadedMessages?: T }> =>
	({ preloadedMessages, ...props }) => {
		return (
			<IntlProvider {...intlProviderProps} messages={preloadedMessages}>
				<Component {...props} />
			</IntlProvider>
		);
	};
