export const SPACES_KEY = 'com.atlassian.confluence.plugins.confluence-space-ia:header-spaces-link';
export const PEOPLE_KEY = 'com.atlassian.confluence.plugins.confluence-space-ia:header-people-link';
export const DISCOVER_KEY =
	'com.atlassian.plugins.atlassian-connect-plugin:com.atlassian.confluence.emcee__enduser-discover';
export const AUTOMATION_KEY =
	'com.atlassian.confluence.plugins.confluence-space-ia:header-automation-link';
export const CALENDAR_KEY = 'com.atlassian.confluence.extra.team-calendars:header-mycalendar-link';
export const ANALYTICS_KEY =
	'com.atlassian.plugins.atlassian-connect-plugin:com.addonengine.analytics__analytics-instance-settings-menu-item-adg3';
export const ANALYTICS_KEY_MODERATE =
	'com.atlassian.plugins.atlassian-connect-plugin:com.addonengine.analytics.moderate__analytics-instance-settings-menu-item-adg3';
