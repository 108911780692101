// Displays a warning message only once per message to avoid spamming the console with the same message multiple times.
const messages = new Set<string>();
export default function warnOnce(message: string) {
	if (messages.has(message)) {
		return;
	}

	messages.add(message);

	if (typeof window !== 'undefined') {
		// eslint-disable-next-line no-console
		console.warn(message);
	}
}
