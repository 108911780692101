import { sanitizeUrl } from '../../../../utils';
import { adaptEventHandler } from '../../../utils';
import { type ComponentPropsConfig } from '../../../utils/codegen';

export const componentPropsConfig: ComponentPropsConfig = {
	children: true,
	linkComponent: false,
	onClick: { value: 'adaptEventHandler(onClick)' },
	href: { value: 'href ? sanitizeUrl(href): undefined', comment: 'safety measure' },
	testId: true,
};

/**
 * THIS SECTION WAS CREATED VIA CODEGEN DO NOT MODIFY {@see http://go/af-codegen}
 *
 * This component was generated against an existing platform component.
 * If you are seeing lint errors in this file, it means that this file's dependencies have been modified and the component will need to be regenerated.
 *
 * Please view https://hello.atlassian.net/wiki/spaces/ECO/pages/3358130330/How+to+Regenerate+UI+Kit+Components for more information on how to proceed.
 *
 * For further questions, please ping the #help-forge-ui channel.
 *
 * @codegen <<SignedSource::6dd8593e38c637b95f8ac467c941bb80>>
 * @codegenId #section-message-action
 * @codegenCommand yarn workspace @atlassian/forge-ui codegen section-message-action
 * @codegenDependency ../../../../../../../design-system/section-message/src/types.tsx <<SignedSource::b21d73664ad9dc3f6d85413e39ff5771>>
 * @codegenDependency ../../../../../../../design-system/section-message/src/section-message-action.tsx <<SignedSource::4473f9e8f9dafceab37a5b0230283af6>>
 */
/* eslint @repo/internal/codegen/signed-source-integrity: "warn" */
import React from 'react';
import type { RenderFn } from '@atlassian/forge-ui-types';
import { SectionMessageAction as PlatformSectionMessageAction } from '@atlaskit/section-message';

type PlatformSectionMessageActionProps = React.ComponentProps<typeof PlatformSectionMessageAction>;

export type SectionMessageActionProps = Pick<
  PlatformSectionMessageActionProps,
  'children' | 'onClick' | 'href' | 'testId'
>;

/**
 * A section message is used to alert users to a particular section of the screen.
 */
export const SectionMessageAction = (props: Parameters<RenderFn>[0]) => {
  const {
    onClick,
    href,
    testId,
  } = props.forgeDoc.props as SectionMessageActionProps;
  return (
    <PlatformSectionMessageAction
      children={props.forgeDoc.children.map(c => <React.Fragment key={c.key}>{props.render(c)}</React.Fragment>)}
      onClick={adaptEventHandler(onClick)}
      // safety measure
      href={href ? sanitizeUrl(href): undefined}
      testId={testId}
    />
  );
};
/**
 * @codegenEnd
 */
