/**
 * Helper methods for fetching content.
 * @module confluence-rest-api/content
 */

import { redirectToLoginPage } from '@confluence-classic/confluence-authentication';

import {
	CONTENT_CREATE,
	DELETE_CONTENT,
	DRAFT_METADATA,
	DRAFT_SYNC,
	EDIT_PAGE_METADATA,
	EDITOR_PAGE,
	CONTENT_PUBLISH,
	CONTENT_FROM_TEMPLATE_PUBLISH,
	RELATION_ENTITIES,
	RENDER_CONTENT,
	REVERT_CONTENT,
	CANCEL_SCHEDULED_PUBLISH,
} from './endpoints';

import {
	contentWithBodyResponseTransformer,
	editorContentWithBodyResponseTransformer,
	favouriteResponseTransformer,
	publishContentTransformer,
	schedulePublishContentTransformer,
	renderContentResponseTransformer,
	unFavouriteResponseTransformer,
	relationSourcesTransformer,
} from './transformers';
import { contentTransformer } from './transformers/pagetree';
import requestHelper from './request-helper';

const JSONPostHeaders = {
	Accept: 'application/json',
	'Content-Type': 'application/json',
};

/**
 * Fetch a page's metadata with ancestors - need for editor to have info about page parents
 * @param {Object} options - The options for the Rest Api call.
 * @param {string} [options.contentId] - current content id
 * @param callback
 */
export function editPageMetadata(options, callback) {
	const params = {
		contentId: options.contentId,
	};
	const url = requestHelper.getFullPath(EDIT_PAGE_METADATA, params);
	requestHelper.get(url, {
		callback,
		httpCodeHandlers: { 401: () => redirectToLoginPage() },
		responseTransformer: contentWithBodyResponseTransformer,
		routeName: 'EDIT_PAGE_METADATA',
	});
}

/**
 * Fetch a page data for editor.
 * @param {Object} options - The options for the Rest Api call.
 * @param {string} [options.contentId] - current content id.
 * @param callback
 */
export function editorPage(options, callback) {
	const params = {
		contentId: options.contentId,
		timestamp: Date.now(),
		draftShareId: options.draftShareId,
	};

	const url = requestHelper.getFullPath(EDITOR_PAGE, params);
	requestHelper.get(url, {
		callback,
		httpCodeHandlers: { 401: () => redirectToLoginPage() },
		responseTransformer: editorContentWithBodyResponseTransformer,
		routeName: 'EDITOR_PAGE',
	});
}

/**
 * Publish a page to the content api.
 * @param {Object} options - The options for the Rest Api call.
 * @param {string} [options.contentId] - current content id.
 * @param callback
 */
export function publishEditorPage(options, callback) {
	const schedulePublishTime = options.content.schedulePublishDate;

	const params = {
		contentId: options.content.id,
		schedulePublishTime: schedulePublishTime,
	};

	const url = requestHelper.getFullPath(CONTENT_PUBLISH, params);
	requestHelper.put(url, {
		body: options.content,
		callback,
		httpCodeHandlers: { 401: () => redirectToLoginPage() },
		headers: JSONPostHeaders,
		responseTransformer: schedulePublishTime
			? schedulePublishContentTransformer
			: publishContentTransformer,
		routeName: 'CONTENT_PUBLISH',
	});
}

/**
 * Syncs a draft to the content api.
 * @param {Object} options - The options for the Rest Api call.
 * @param {string} [options.contentId] - current content id.
 * @param callback
 */
export function syncEditorDraft(options, callback) {
	const params = {
		contentId: options.content.id,
	};

	const url = requestHelper.getFullPath(DRAFT_SYNC, params);
	requestHelper.put(url, {
		body: options.content,
		callback,
		httpCodeHandlers: { 401: () => redirectToLoginPage() },
		headers: JSONPostHeaders,
		responseTransformer: publishContentTransformer,
		routeName: 'DRAFT_SYNC',
	});
}

/**
 * Publish a newly created blueprint page to the blueprint content api.
 * @param {Object} options - The options for the Rest Api call.
 * @param {string} [options.contentId] - current content id.
 * @param callback
 */
export function publishEditorPageFromTemplate(options, callback) {
	const schedulePublishTime = options.content.schedulePublishDate;

	const params = {
		contentId: options.content.id,
		schedulePublishTime: schedulePublishTime,
	};

	const url = requestHelper.getFullPath(CONTENT_FROM_TEMPLATE_PUBLISH, params);
	requestHelper.put(url, {
		body: options.content,
		callback,
		httpCodeHandlers: { 401: () => redirectToLoginPage() },
		headers: JSONPostHeaders,
		responseTransformer: schedulePublishTime
			? schedulePublishContentTransformer
			: publishContentTransformer,
		routeName: 'CONTENT_FROM_TEMPLATE_PUBLISH',
	});
}

/**
 * Create new page for editor.
 * @param {Object} options - The options for the Rest Api call.
 * @param {string} [options.contentId] - current content id.
 * @param callback
 */
export function createPage(options, callback) {
	const body = {
		type: options.contentType || 'page',
		status: 'draft',
		//title: "",    // This is possible for draft but not for published page.
		space: {
			key: options.spaceKey,
		},
	};
	if (options.fromPageId) {
		body.ancestors = [{ id: options.fromPageId }];
	}
	if (options.title) {
		body.title = options.title;
	}
	const url = requestHelper.getFullPath(CONTENT_CREATE);

	requestHelper.post(url, {
		body,
		callback,
		httpCodeHandlers: { 401: () => redirectToLoginPage() },
		headers: JSONPostHeaders,
		responseTransformer: contentTransformer,
		routeName: 'CONTENT_CREATE',
	});
}

/**
 * Fetch a draft's metadata with ancestors - need for editor to have info about page parents
 * @param {Object} options - The options for the Rest Api call.
 * @param {string} [options.contentId] - current content id
 * @param callback
 */
export function draftMetadata(options, callback) {
	const params = {
		contentId: options.contentId,
	};
	const url = requestHelper.getFullPath(DRAFT_METADATA, params);
	requestHelper.get(url, {
		callback,
		httpCodeHandlers: { 401: () => redirectToLoginPage() },
		responseTransformer: contentWithBodyResponseTransformer,
		routeName: 'DRAFT_METADATA',
	});
}

/**
 * Cancel a scheduled publish.
 * @param {Object} options - The options for the Rest Api call.
 * @param {string} [options.contentId] - current content id.
 * @param callback
 */
export function cancelScheduledPublish(options, callback) {
	const params = {
		contentId: options.contentId,
	};
	const url = requestHelper.getFullPath(CANCEL_SCHEDULED_PUBLISH, params);

	requestHelper.delete(url, {
		callback,
		httpCodeHandlers: { 401: () => redirectToLoginPage() },
		routeName: 'CANCEL_SCHEDULED_PUBLISH',
	});
}
