import React from 'react';
import { fg } from '@atlaskit/platform-feature-flags';
import { LoadablePaint } from '@confluence/loadable';
import PropTypes from 'prop-types';
import {
	CONTAINER_ADMIN_HOME_ID,
	CONTAINER_HOME_ID,
} from '@confluence/app-navigation/entry-points/navigationIDs';
import { Redirection } from '@confluence/route-manager/entry-points/Redirection';
import { LEGACY_SPACE_PDF_EXPORT } from '@confluence/named-routes';

export const SPACE_SETTINGS_AFM_ROUTE_FF = 'confluence-fe-space-settings-enable-afm-pdf';

const LazyExportSpaceRouteComponent = LoadablePaint({
	loader: async () =>
		(
			await import(
				/* webpackChunkName: "loadable-ExportSpaceRouteV2Component" */
				'./ExportSpaceRouteComponent'
			)
		).ExportSpaceRouteComponent,
});

export const LazyExportSpaceRoute = ({ params: { spaceKey } }) => {
	if (!fg(SPACE_SETTINGS_AFM_ROUTE_FF)) {
		return (
			<Redirection
				name={LEGACY_SPACE_PDF_EXPORT.name}
				params={{
					spaceKey,
				}}
			/>
		);
	}
	return <LazyExportSpaceRouteComponent spaceKey={spaceKey} />;
};

LazyExportSpaceRoute.propTypes = {
	params: PropTypes.shape({
		spaceKey: PropTypes.string.isRequired,
	}).isRequired,
};

LazyExportSpaceRoute.NAVIGATION_PARAMS = ({ params: { spaceKey }, isNav4Enabled }) => ({
	Screen: {
		screenEvent: { name: 'ExportSpaceScreen' },
		pageState: { spaceKey },
	},
	MainLayout: {
		navView: isNav4Enabled ? CONTAINER_ADMIN_HOME_ID : CONTAINER_HOME_ID,
		spaceKey,
		disableMinWidth: true,
	},
});
