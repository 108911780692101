import { getSessionData } from '@confluence/session-data';
import { getValue } from '@confluence/lifted-promise';
import type { SessionDataType } from '@confluence/session-data';

const COMPANY_HUB_SPACE_KEY_PREFIX = '~HUB';

export const isCompanyHubSpaceKey = (spaceKey?: string | null): boolean =>
	!!(spaceKey?.startsWith(COMPANY_HUB_SPACE_KEY_PREFIX) && spaceKey?.endsWith('~'));

export const getCompanyHubSpaceKey = (
	sessionData?: Pick<SessionDataType, 'activationId' | 'cloudId'>,
) => {
	if (!sessionData?.activationId || !sessionData?.cloudId) {
		sessionData = getValue(getSessionData());
	}
	if (sessionData) {
		const { activationId, cloudId } = sessionData;

		if (activationId && cloudId) {
			return `${COMPANY_HUB_SPACE_KEY_PREFIX}-${cloudId}-${activationId}~`;
		}
	}
	// We used to return undefined, but pretty much always we fell back to the empty string with code like `?? ''` or `|| ''`.
	// Instead of sprinkling noise like that all over, it's less complex to do the de facto fallback here.
	return '';
};

export enum FrontCoverStateEnum {
	UNSET = 'unset',
	TRANSITION = 'transition',
	HIDDEN = 'hidden',
	EDITORS_ONLY = 'editors_only',
	SHOWN = 'shown',
}

export enum FrontCoverStateInputEnum {
	UNSET = 'UNSET',
	TRANSITION = 'TRANSITION',
	HIDDEN = 'HIDDEN',
	SHOWN = 'SHOWN',
}
