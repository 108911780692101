import gql from 'graphql-tag';

export const GetUserAccessQuery = gql`
	query GetUserAccessQuery($resourceId: String!, $permissionId: String!) {
		tenantContext {
			licensedProducts {
				licenseStatus
				productKey
			}
		}
		permitted(resourceId: $resourceId, permissionId: $permissionId)
	}
`;
