import { UFOv2 } from '@atlassian/post-office-frontend-performance-tracking';
import { MessageContextProvider } from '@post-office/message-context';
import { RecommendationProvider } from '@post-office/recommendation-context';
import { type MessageCreationType } from '@post-office/shared-contracts';
import {
	type FunctionComponent,
	type ReactNode,
	Suspense,
	type SuspenseProps,
	useMemo,
} from 'react';

import { ChoreographerWrapper } from './choreographer-wrapper';
import { FeatureFlaggedUfoWrapper } from './ufo-wrapper';
import { MessageAnalyticsContextProvider } from '../message-analytics-context';
import { type MessageCategory } from '../types';

type ComponentProps = {
	transactionAccountId?: string;
};

type ProviderProps = {
	messageTemplateId: string;
	messageInstanceId: string;
	messageCategory: MessageCategory;
	transactionAccountId?: string;
	recommendationSession?: {
		sessionId: string;
		entityid: string;
	};
	product?: string;
	loadingFallback?: React.ReactNode;
	postOffice?: {
		analyticsDetails?: Record<string, string | number>;
	};
	triggerId?: string;
	messageCreationType?: MessageCreationType;
	messageSuccessComponent?: JSX.Element;
	componentProps: ComponentProps;
	isUFOEnabled: boolean;
	children?: ReactNode;
	SuspenseBoundary?: FunctionComponent<SuspenseProps>;
};

const DEFAULT_RECOMMENDATION_SESSION = {};

export const MessageRendererProvider = ({
	messageInstanceId,
	messageTemplateId,
	messageCategory,
	recommendationSession,
	messageSuccessComponent,
	componentProps,
	loadingFallback,
	triggerId,
	messageCreationType,
	postOffice,
	isUFOEnabled,
	children,
	SuspenseBoundary = Suspense,
}: ProviderProps) => {
	const loadingComponent = useMemo(
		() =>
			isUFOEnabled ? (
				<UFOv2.UFOLoadHold name={UFOv2.HoldNames.MESSAGE}>
					{loadingFallback ?? null}
				</UFOv2.UFOLoadHold>
			) : (
				<>{loadingFallback ?? null}</>
			),
		[loadingFallback, isUFOEnabled],
	);

	const messageContextMemo = useMemo(
		() => ({
			messageInstanceId,
			messageTemplateId,
			triggerId,
			messageCreationType,
			componentProps,
			postOffice,
		}),
		[
			messageInstanceId,
			messageTemplateId,
			triggerId,
			messageCreationType,
			componentProps,
			postOffice,
		],
	);

	return (
		<ChoreographerWrapper
			messageId={messageInstanceId}
			messageTemplateId={messageTemplateId}
			messageCategory={messageCategory}
		>
			<FeatureFlaggedUfoWrapper messageTemplateId={messageTemplateId} isEnabled={isUFOEnabled}>
				<MessageContextProvider value={messageContextMemo}>
					<RecommendationProvider value={recommendationSession ?? DEFAULT_RECOMMENDATION_SESSION}>
						<SuspenseBoundary fallback={loadingComponent}>
							<MessageAnalyticsContextProvider>
								{messageSuccessComponent}
								{children}
							</MessageAnalyticsContextProvider>
						</SuspenseBoundary>
					</RecommendationProvider>
				</MessageContextProvider>
			</FeatureFlaggedUfoWrapper>
		</ChoreographerWrapper>
	);
};
