import type { ConfigResult, ValuesPayload } from '@atlaskit/config-common-libs';
import { ConfigCollection } from '@atlaskit/config-common-libs';
import FeatureGates from '@atlaskit/feature-gate-js-client';

declare global {
	interface Window {
		__STATSIGCONFIGS_JS__: StatsigConfigurations;
	}
}

class StatsigConfigurations {
	private static configCollection: ConfigCollection;
	private static configOverrides: { [configName: string]: Record<string, unknown> };

	static initializeFromValues(configCollectionValues: ValuesPayload) {
		this.configCollection = ConfigCollection.fromValues(configCollectionValues);

		if (FeatureGates.initializeCompleted()) {
			StatsigConfigurations.initializeOverrides();
		}
	}

	private static initializeOverrides() {
		const featureGateOverrides = FeatureGates.getOverrides();
		this.configOverrides = Object.entries(featureGateOverrides.configs).reduce(
			(overrideConfigs, [name, value]) => {
				// We currently use statsig-js-lite 3rd party library for Statsig gate and experiment overrides.
				// Experiment overrides are stored in the same configs object, so we need to extract the
				// overrides for dynamic configs.
				if (Object.keys(value).length === 1 && value.hasOwnProperty('value')) {
					overrideConfigs[name] = value;
				}
				return overrideConfigs;
			},
			{} as { [configName: string]: Record<string, unknown> },
		);
	}

	private static getValueOrDefault<T>(
		getFunction: (cname: string) => ConfigResult<T>,
		configName: string,
		defaultVal: T,
	): T {
		if (this.configOverrides && Object.keys(this.configOverrides).length > 0) {
			const override = this.configOverrides[configName];
			if (override && typeof override.value === typeof defaultVal) {
				return override.value as T;
			}
		}

		if (!this.configCollection) {
			return defaultVal;
		}

		const configResult = getFunction.call(this.configCollection, configName);
		if (!configResult || 'error' in configResult || !('value' in configResult)) {
			return defaultVal;
		}

		return configResult.value;
	}

	public static isKillSwitchOn(configName: string): boolean {
		return this.getValueOrDefault(this.configCollection?.getBoolean, configName, false);
	}

	public static getBoolean(configName: string, defaultVal: boolean): boolean {
		return this.getValueOrDefault(this.configCollection?.getBoolean, configName, defaultVal);
	}

	public static getString(configName: string, defaultVal: string): string {
		return this.getValueOrDefault(this.configCollection?.getString, configName, defaultVal);
	}

	public static getNumber(configName: string, defaultVal: number): number {
		return this.getValueOrDefault(this.configCollection?.getNumber, configName, defaultVal);
	}

	public static getStringList(configName: string, defaultVal: string[]): string[] {
		return this.getValueOrDefault(this.configCollection?.getStringList, configName, defaultVal);
	}

	public static getNumberList(configName: string, defaultVal: number[]): number[] {
		return this.getValueOrDefault(this.configCollection?.getNumberList, configName, defaultVal);
	}
}

/* eslint-disable import/no-default-export */
export default StatsigConfigurations;
