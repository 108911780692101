import { type ComponentPropsConfig } from '../../../utils/codegen';

export const componentPropsConfig: ComponentPropsConfig = {
	id: true,
	children: {
		value: "props.forgeDoc.children.flatMap((c) => c.type === 'String' ? [] : props.render(c))",
		comment: 'Filter out line spacing strings as Tabs should only have TabList & TabPanel children',
	},
	defaultSelected: true,
	onChange: true,
	selected: true,
	shouldUnmountTabPanelOnChange: true,
	testId: true,
};

/**
 * THIS SECTION WAS CREATED VIA CODEGEN DO NOT MODIFY {@see http://go/af-codegen}
 *
 * This component was generated against an existing platform component.
 * If you are seeing lint errors in this file, it means that this file's dependencies have been modified and the component will need to be regenerated.
 *
 * Please view https://hello.atlassian.net/wiki/spaces/ECO/pages/3358130330/How+to+Regenerate+UI+Kit+Components for more information on how to proceed.
 *
 * For further questions, please ping the #help-forge-ui channel.
 *
 * @codegen <<SignedSource::789fed9a1643612991bbf9e6f4bb63c9>>
 * @codegenId #tabs
 * @codegenCommand yarn workspace @atlassian/forge-ui codegen tabs
 * @codegenDependency ../../../../../../../design-system/tabs/src/types.tsx <<SignedSource::f2168d42d44e8f2d02748d5db80bad13>>
 * @codegenDependency ../../../../../../../analytics/analytics-next/src/hocs/withAnalyticsEvents.tsx <<SignedSource::912bf75b9266c0c95b2e6bf4d8e3c915>>
 * @codegenDependency ../../../../../../../design-system/tabs/src/internal/context.tsx <<SignedSource::7ce8eeffe9338a642187bc5baebb0242>>
 * @codegenDependency ../../../../../../../design-system/tabs/src/components/tabs.tsx <<SignedSource::86b9c54a06734784213ab277bb99cda7>>
 */
/* eslint @repo/internal/codegen/signed-source-integrity: "warn" */
import React from 'react';
import type { RenderFn } from '@atlassian/forge-ui-types';
import PlatformTabs from '@atlaskit/tabs';

type PlatformTabsProps = React.ComponentProps<typeof PlatformTabs>;

export type TabsProps = Pick<
  PlatformTabsProps,
  'id' | 'children' | 'defaultSelected' | 'onChange' | 'selected' | 'shouldUnmountTabPanelOnChange' | 'testId'
>;

/**
 * Tabs are used to organize content by grouping similar information on the same page.
 */
export const Tabs = (props: Parameters<RenderFn>[0]) => {
  const {
    id,
    defaultSelected,
    onChange,
    selected,
    shouldUnmountTabPanelOnChange,
    testId,
  } = props.forgeDoc.props as TabsProps;
  return (
    <PlatformTabs
      id={id}
      // Filter out line spacing strings as Tabs should only have TabList & TabPanel children
      children={props.forgeDoc.children.flatMap((c) => c.type === 'String' ? [] : props.render(c))}
      defaultSelected={defaultSelected}
      onChange={onChange}
      selected={selected}
      shouldUnmountTabPanelOnChange={shouldUnmountTabPanelOnChange}
      testId={testId}
    />
  );
};
/**
 * @codegenEnd
 */
