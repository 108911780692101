import React, { useEffect, useState, type ChangeEvent, useCallback } from 'react';
import type { RenderFn } from '@atlassian/forge-ui-types';
import { Box } from '@atlaskit/primitives';
import PlatformCheckbox from '@atlaskit/checkbox';

export interface CheckboxGroupProps {
	/**
	 * Sets the name prop on each of the Checkbox elements in the group.
	 */
	name: string;
	/**
	 * An array of objects, each object is mapped onto a Checkbox element within the group.
	 */
	options: { label: string; value: string; isDisabled?: boolean }[];
	/**
	 * Once set, controls the selected value on the `CheckboxGroup`.
	 */
	value?: string[];
	/*
	 * Function that gets fired after each change event.
	 */
	onChange?: (values: string[]) => void;
	/**
	 * Sets the initial selected value on the `CheckboxGroup`.
	 */
	defaultValue?: string[];
	/**
	 * Sets the disabled state of all Checkbox elements in the group.
	 */
	isDisabled?: boolean;
}

/**
 * A Checkbox group is a list of options where one or more choices can be selected.
 */
export const CheckboxGroup = (props: Parameters<RenderFn>[0]) => {
	const {
		name,
		options,
		value,
		onChange,
		defaultValue = [],
		isDisabled = false,
	} = props.forgeDoc.props as CheckboxGroupProps;
	const [selectedValues, setSelectedValues] = useState<string[]>(value ?? defaultValue);

	useEffect(() => {
		if (value) {
			setSelectedValues(value);
		}
	}, [value]);
	const notStaticCheckbox = !value || onChange;
	const onCheckboxChange = useCallback(
		(event: ChangeEvent<HTMLInputElement>) => {
			if (notStaticCheckbox) {
				const newSelectedValues = selectedValues.includes(event.target.value)
					? selectedValues.filter((item) => item !== event.target.value)
					: [...selectedValues, event.target.value];

				setSelectedValues(newSelectedValues);
				if (onChange) {
					onChange(newSelectedValues);
				}
			}
		},
		[notStaticCheckbox, onChange, selectedValues, setSelectedValues],
	);

	return (
		<Box role="group">
			{options.map((item, index) => {
				return (
					<PlatformCheckbox
						key={index}
						name={name}
						value={item.value}
						label={item.label}
						isChecked={selectedValues.includes(item.value)}
						isDisabled={item.isDisabled ?? isDisabled}
						onChange={onCheckboxChange}
					/>
				);
			})}
		</Box>
	);
};
