import { query } from '@confluence/query-preloader-tools';

import { preloadOptions } from '../Options/preloadOptions';

import { getRecentQueryOptions } from './recentQueryHelpers';
import { HomeRecentUserPreferencesQuery } from './HomeRecentQueries.graphql';

export const preloadRecent = ({
	cloudId,
	isLoggedIn,
	isWhiteboardViewedEnabled,
	isWhiteboardUpdatedEnabled,
}: {
	cloudId: string;
	isLoggedIn: boolean;
	isWhiteboardViewedEnabled?: boolean;
	isWhiteboardUpdatedEnabled?: boolean;
}) => {
	return Promise.all([
		query<unknown, {}>(
			getRecentQueryOptions({
				pill: 'all',
				cloudId,
				endCursor: '',
				isWhiteboardViewedEnabled,
				isWhiteboardUpdatedEnabled,
			}),
		),
		preloadOptions({ isLoggedIn }),
		...(isLoggedIn ? [query({ query: HomeRecentUserPreferencesQuery })] : []),
	]);
};
