import { fg } from '@confluence/feature-gating';

export const getNav4GAPhase = () => {
	if (fg('confluence_nav4_ga_phase_2')) {
		return 2;
	}

	if (fg('confluence_nav4_ga_phase_1')) {
		return 1;
	}

	return 0;
};
