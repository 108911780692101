import type { FC } from "react";
import memoize from 'memoize-one';

import type { ADDoc, ADNode } from "@atlaskit/editor-common/validator";
import type { ExtensionParams, ReferenceEntity } from "@atlaskit/editor-common/extensions";
import type { DataSourceProvider as ReferentialityDataSourceProvider} from "@atlassian/editor-referentiality";
import { useReference } from "@atlassian/editor-referentiality";

type Props = {
  adf: ADNode | string | null;
  extensionParams: ExtensionParams<any>;
  referentialityDataSourceProvider: ReferentialityDataSourceProvider;
  children: (data: { references: ReferenceEntity[] }) => JSX.Element;
}

const memoAdfParsed = memoize((adf: ADNode | string | null) => {
  let result = adf as ADDoc;
  if (typeof adf === "string") {
    try {
      result = JSON.parse(adf);
    } catch(e) {
      result = {
        version: 1,
        type: 'doc',
        content: [],
      }
    }
  }
  return result;
});

export const MacroReference: FC<Props> = ({
  adf,
  extensionParams,
  referentialityDataSourceProvider,
  children
}) => {
  const adfParsed = memoAdfParsed(adf);
  const { references } = useReference({
    adf: adfParsed,
    extensionParams,
    dataSourceProvider: referentialityDataSourceProvider
  });

  return (
    children({references})
  );
}
