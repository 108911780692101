import { type SerializableRecord } from '@post-office/serializable-record';
import { useMemo } from 'react';

import { type State } from './types';
import { type DataFetcher, useData } from './useData';

export const useInitialData = <I extends SerializableRecord>(
	initialData: Promise<Response | undefined> | undefined,
): State<I> => {
	const initialDataCallback = useMemo<DataFetcher | undefined>(() => {
		if (typeof initialData === 'undefined') {
			return undefined;
		}

		return () => initialData;
	}, [initialData]);

	return useData(initialDataCallback, {
		analytics: {
			name: 'initialData',
		},
	});
};
