import React, { Fragment, useContext } from 'react';
import { useQuery } from '@apollo/react-hooks';

import { ConnectAddon } from '@confluence/connect-addon';
import { ContentUnifiedQuery, ContentBlogUnifiedQuery } from '@confluence/content-unified-query';
import { RoutesContext } from '@confluence/route-manager/entry-points/RoutesContext';
import {
	usePageContentId,
	usePageSpaceKey,
	useContentType,
	usePageState,
} from '@confluence/page-context';
import { SPACE_OVERVIEW } from '@confluence/named-routes';
import { ContentScreenBackgroundLayer } from '@confluence/content-container';
import { getMonitoringClient } from '@confluence/monitoring';
import { markErrorAsHandled } from '@confluence/graphql';
import { Attribution } from '@confluence/error-boundary';

import { ExperimentalThemeSpaceHomeOverrideQuery } from './ExperimentalThemeSpaceHomeOverrideQuery.experimentalgraphql';

const connectAddonContainerStyle = { position: 'relative' };

const connectAddonProps = {
	contentStyle: {
		width: '100%',
		textAlign: 'center',
		display: 'inline-block',
	},
	width: '100%',
	height: '100%',
};

const SPACE_THEME_KEY_MODULE_KEY_REGEX = /^ac:(.*)__(.*)-remote-theme$/;
export const parseThemeKey = (themeKey: string) => {
	const regexResult = SPACE_THEME_KEY_MODULE_KEY_REGEX.exec(themeKey);
	return regexResult ? [regexResult[1], regexResult[2]] : [null, null];
};

export const SpaceBaseThemedComponent = ({ children }: React.PropsWithChildren<{}>) => {
	const { match } = useContext(RoutesContext);
	const [{ contentIdLoading }] = usePageState();
	const [contentId] = usePageContentId();
	const [spaceKey] = usePageSpaceKey();
	const [contentType] = useContentType();

	let chooseContentUnifiedQuery = ContentUnifiedQuery;

	if (contentType === 'blogpost') {
		chooseContentUnifiedQuery = ContentBlogUnifiedQuery;
	}

	const isSpaceOverview = match?.name === SPACE_OVERVIEW.name;
	const skipContentQuery = !contentId || !spaceKey || !isSpaceOverview || contentIdLoading;
	const contentQueryResult = useQuery(chooseContentUnifiedQuery, {
		context: { single: true },
		variables: {
			contentId,
			spaceKey,
			versionOverride: null,
			useNewContentTopper: true,
		},
		skip: skipContentQuery,
	});

	const themeKey = contentQueryResult?.data?.space?.theme?.themeKey;
	const routeOverrideEnabled = contentQueryResult?.data?.space?.settings?.routeOverrideEnabled;
	const [themeAddonKey, themeModuleKey] = parseThemeKey(themeKey);
	const skipOverrideQuery = !themeAddonKey || !themeModuleKey || !routeOverrideEnabled;

	const overrideQueryResult = useQuery(
		// eslint-disable-next-line graphql-relay-compat/no-import-graphql-operations -- Read https://go/connie-relay-migration-fyi
		ExperimentalThemeSpaceHomeOverrideQuery,
		{
			variables: {
				themeAddonKey,
				themeModuleKey,
				spaceKey,
				spaceHomePageId: contentId,
			},
			skip: skipOverrideQuery,
		},
	);

	if (contentQueryResult.error) {
		getMonitoringClient().submitError(contentQueryResult.error, {
			attribution: Attribution.ECOSYSTEM,
		});
		markErrorAsHandled(contentQueryResult.error);
		return <Fragment>{children}</Fragment>;
	}

	if (overrideQueryResult.error) {
		getMonitoringClient().submitError(overrideQueryResult.error, {
			attribution: Attribution.ECOSYSTEM,
		});
		markErrorAsHandled(overrideQueryResult.error);
		return <Fragment>{children}</Fragment>;
	}

	if (contentIdLoading) {
		return null;
	}

	if (skipContentQuery) {
		return <Fragment>{children}</Fragment>;
	}

	if (contentQueryResult.loading || (!skipOverrideQuery && overrideQueryResult.loading)) {
		return null;
	}

	if (skipOverrideQuery) {
		return <Fragment>{children}</Fragment>;
	}

	if (!contentQueryResult.data || !overrideQueryResult.data) {
		return <Fragment>{children}</Fragment>;
	}

	const { hasSpaceHomeOverride, spaceHomeOverrideDescriptor } =
		overrideQueryResult.data.experimentalThemeSpaceHomeOverride;

	if (!hasSpaceHomeOverride) {
		return <Fragment>{children}</Fragment>;
	}

	spaceHomeOverrideDescriptor.productContext = JSON.parse(spaceHomeOverrideDescriptor.productCtx);

	return (
		<Fragment>
			<ContentScreenBackgroundLayer lookAndFeel={contentQueryResult?.data?.space?.lookAndFeel} />
			<ConnectAddon
				containerStyle={connectAddonContainerStyle}
				descriptor={spaceHomeOverrideDescriptor}
				{...connectAddonProps}
			/>
		</Fragment>
	);
};
