import { OPERATIONAL_EVENT_TYPE, TRACK_EVENT_TYPE } from '@atlaskit/analytics-gas-types';
import {
	type AnalyticsWebClient,
	type ForgeUIAnalyticsContext,
	type GasV3Payload,
	extensionIdToAnalyticsAttributes,
	sendEvent,
} from '@atlassian/forge-ui/analytics';
import type { EnvironmentType, ForgeUIExtensionType } from '@atlassian/forge-ui-types';

import { CONNECT_ADDON_ACTION_SUBJECT, EXTENSION_NAMESPACE, FORGE_EXTENSION } from './constants';

export const AnalyticsAction = {
	FORGE_EXTENSION_INSERTED: 'inserted',
	FORGE_EXTENSION_FAILED: 'failed',
	FORGE_EXTENSION_ERRORED: 'errored',
	FORGE_MACRO_AUTO_CONVERT_SUCCESS: 'auto_convert_success',
	FORGE_MACRO_AUTO_CONVERT_TO_SMARTLINK: 'auto_convert_to_smartlink',
	H11N_CONFIG_WARNING: 'h11n_macro_config_warning',
	H11N_CONFIG_ERROR: 'h11n_macro_config_error',
	H11N_RESOLVE_FAILED: 'h11n_macro_resolve_failed',
	H11N_UPGRADE_RICH_TEST_SKIPPED: 'h11n_macro_upgrade_rich_text_skipped',
	H11N_UPGRADE_SUCCESS: 'h11n_macro_upgrade_success',
	H11N_UPGRADE_FAILED: 'h11n_macro_upgrade_failed',
	H11N_RENDER_SUCCESS: 'h11n_macro_render_success',
	H11N_RENDER_FAILED: 'h11n_macro_render_success',
} as const;

type Action = (typeof AnalyticsAction)[keyof typeof AnalyticsAction];
interface SendEventParams {
	localId?: string;
	nodeLocalId?: string;
	error?: unknown;
	extension?: ForgeUIExtensionType;
	extensionId?: string;
	extensionKey?: string;
	extensionType?: string;
	macroId?: string;
	macroTitle?: string;
	link?: string;
	forgeEnvironment?: EnvironmentType;
	render?: 'native' | 'default';
}

export type AnalyticsClient = {
	sendEvent: (action: Action, params: SendEventParams) => void;
};

export const createAnalyticsClient = ({
	analyticsWebClient,
	forgeUIAnalyticsContext,
	source,
}: {
	analyticsWebClient: AnalyticsWebClient | Promise<AnalyticsWebClient>;
	forgeUIAnalyticsContext: ForgeUIAnalyticsContext;
	source: 'editPageScreen' | 'viewPageScreen';
}): AnalyticsClient => {
	const analyticsClient = {
		sendEvent: (action: Action, params: SendEventParams) => {
			const {
				localId,
				nodeLocalId,
				extension,
				extensionId: id,
				extensionKey,
				extensionType,
				error,
				macroId,
				macroTitle,
				link,
				forgeEnvironment,
				render,
			} = params;
			const extensionId = id || extension?.id;
			const { environmentType } = extension || {};

			const commonAttributes = {
				...extensionIdToAnalyticsAttributes(extensionId),
				...(extension?.environmentType ? { forgeEnvironment: environmentType } : {}),
			};

			const errorMessage = error instanceof Error ? error.message : String(error);
			const errorName = error instanceof Error ? error.name : String(error);

			const eventProperties: GasV3Payload = action.startsWith('h11n')
				? {
						action,
						actionSubject: CONNECT_ADDON_ACTION_SUBJECT,
						actionSubjectId: commonAttributes.extensionKey,
						eventType: OPERATIONAL_EVENT_TYPE,
						source,
						...forgeUIAnalyticsContext,
					}
				: {
						action,
						actionSubject: FORGE_EXTENSION,
						actionSubjectId: extensionId,
						eventType: OPERATIONAL_EVENT_TYPE,
						source,
						...forgeUIAnalyticsContext,
					};

			switch (action) {
				case AnalyticsAction.H11N_UPGRADE_SUCCESS:
					sendEvent(analyticsWebClient)({
						...eventProperties,
						eventType: TRACK_EVENT_TYPE,
						attributes: {
							...commonAttributes,
							localId,
							nodeLocalId,
							extensionType,
						},
					});
					return;
				case AnalyticsAction.H11N_RENDER_SUCCESS:
					sendEvent(analyticsWebClient)({
						...eventProperties,
						attributes: {
							...commonAttributes,
							nodeLocalId,
						},
					});
					return;
				case AnalyticsAction.H11N_RENDER_FAILED:
					sendEvent(analyticsWebClient)({
						...eventProperties,
						attributes: {
							...commonAttributes,
							nodeLocalId,
							errorMessage,
						},
					});
					return;
				case AnalyticsAction.H11N_UPGRADE_FAILED:
					sendEvent(analyticsWebClient)({
						...eventProperties,
						actionSubjectId: extensionKey,
						attributes: {
							...commonAttributes,
							nodeLocalId,
							errorMessage,
							extensionKey,
							extensionType: extensionType,
						},
					});
					return;
				case AnalyticsAction.H11N_UPGRADE_RICH_TEST_SKIPPED:
					sendEvent(analyticsWebClient)({
						...eventProperties,
						attributes: {
							...commonAttributes,
							nodeLocalId,
							extensionType: EXTENSION_NAMESPACE,
						},
					});
					return;
				case AnalyticsAction.H11N_RESOLVE_FAILED:
					sendEvent(analyticsWebClient)({
						...eventProperties,
						attributes: {
							...commonAttributes,
							errorMessage,
							extensionKey,
						},
					});
					return;
				case AnalyticsAction.H11N_CONFIG_WARNING:
				case AnalyticsAction.H11N_CONFIG_ERROR:
					sendEvent(analyticsWebClient)({
						...eventProperties,
						actionSubjectId: extensionId,
						attributes: {
							errorMessage,
							macroId,
							macroTitle,
							macroKey: extension?.key,
							extensionId,
							migrationKey: extension?.migrationKey,
						},
					});
					return;
				case AnalyticsAction.FORGE_EXTENSION_INSERTED:
					sendEvent(analyticsWebClient)({
						...eventProperties,
						attributes: {
							...commonAttributes,
							localId,
							extensionType: EXTENSION_NAMESPACE,
						},
					});
					return;
				case AnalyticsAction.FORGE_EXTENSION_FAILED:
					sendEvent(analyticsWebClient)({
						...eventProperties,
						actionSubjectId: extensionId,
						attributes: {
							...commonAttributes,
							method: 'convertNodeToForgeExtension',
							localId,
							errorMessage,
						},
					});
					return;
				case AnalyticsAction.FORGE_EXTENSION_ERRORED:
					sendEvent(analyticsWebClient)({
						...eventProperties,
						actionSubjectId: extensionId,
						attributes: {
							...commonAttributes,
							errorName,
							errorMessage,
							extensionType: EXTENSION_NAMESPACE,
						},
					});
					return;
				case AnalyticsAction.FORGE_MACRO_AUTO_CONVERT_SUCCESS:
					// Event registered at https://data-portal.internal.atlassian.com/analytics/registry/77268?tabIndex=Details
					sendEvent(analyticsWebClient)({
						...eventProperties,
						actionSubjectId: extensionId,
						attributes: {
							...commonAttributes,
							link,
							macroTitle,
							forgeEnvironment,
							render,
						},
					});
					return;
				default:
					return;
			}
		},
	};

	return analyticsClient;
};
