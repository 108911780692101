import type { MonitoringClient } from './MonitoringClient';
import { CustomMonitoringClient } from './CustomMonitoringClient';
import { NoOpMonitoringClient } from './NoOpMonitoringClient';
import { SSRMonitoringClient } from './SSRMonitoringClient';

let monitoringClient: MonitoringClient;
export const getMonitoringClient = (): MonitoringClient => {
	if (!monitoringClient) {
		if (/bot/i.test(window.navigator.userAgent)) {
			// Do nothing for search engine crawlers
			monitoringClient = new NoOpMonitoringClient();
			// eslint-disable-next-line check-react-ssr-usage/no-react-ssr
		} else if (process.env.REACT_SSR) {
			// Don't confuse errors from SSR with SPA
			// Error from SSR will be collected and returned to cc-frontend-ssr
			monitoringClient = new SSRMonitoringClient();
		} else {
			monitoringClient = new CustomMonitoringClient();
		}
	}

	return monitoringClient;
};

export const enableSendErrorsToTelemetryService = (): void => {
	// eslint-disable-next-line check-react-ssr-usage/no-react-ssr
	if (process.env.REACT_SSR) {
		return;
	}

	if (monitoringClient instanceof CustomMonitoringClient) {
		monitoringClient.enableSendErrorsToTelemetryService();
	}
};
