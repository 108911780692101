export type MetaGetter = (name: string) => string | null;

export const getMeta: MetaGetter = (name: string): string | null => {
	if (typeof globalThis.document !== 'undefined') {
		const node = document.querySelector(`meta[name="${name}"]`);
		const metaValue = node?.getAttribute('content');
		return metaValue ?? null;
	}
	return null;
};
