import React from 'react';

import { ADMIN_HOME_ID } from '@confluence/app-navigation/entry-points/navigationIDs';
import { LiveEditABTestSiteSettingsPageLoader } from '@confluence/live-pages-features/entry-points/LiveEditABTestSiteSettingsPage';

export const LiveEditABTestSiteSettingsPageRoute = () => <LiveEditABTestSiteSettingsPageLoader />;

LiveEditABTestSiteSettingsPageRoute.NAVIGATION_PARAMS = () => ({
	Screen: {
		screenEvent: { name: 'liveEditABTestSiteSettingsPage' },
	},
	MainLayout: {
		navView: ADMIN_HOME_ID,
	},
});
