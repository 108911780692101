import { getSelectedTableInfo } from '@atlaskit/editor-tables/utils';
import type { EditorView } from '@atlaskit/editor-prosemirror/view';
import type { ExtractPresetAPI } from '@atlaskit/editor-common/preset';
import type { ExtractInjectionAPI } from '@atlaskit/editor-common/types';
import type { useUniversalPreset } from '@atlaskit/editor-core/preset-universal';

import {
	UNSTABLE_configItems,
	UNSTABLE_DO_NOT_USE_ONLY_FOR_CONFLUENCE_triggerConfigItem,
} from '@atlassian/editor-plugin-ai/ConfluencePrebuilt';
import type { AIPlugin } from '@atlassian/editor-plugin-ai/EditorPluginAI';

import type { QuickActionType } from './QuickActionCard';
import { i18n } from './bpqai18n';

export type PresetAPIType = ExtractPresetAPI<ReturnType<typeof useUniversalPreset>> &
	Partial<ExtractInjectionAPI<AIPlugin>>;

export const COHORT = 'cohort';

export enum BLANK_PAGE_QUICK_ACTIONS_MULTI_VARIANT_COHORTS {
	NOT_ENROLLED = 'not-enrolled',
	CONTROL = 'control',
	VARIANT1 = 'variant1',
	VARIANT2 = 'variant2',
}

export enum BLANK_PAGE_QUICK_ACTIONS_SINGLE_VARIANT_COHORTS {
	NOT_ENROLLED = 'not-enrolled',
	CONTROL = 'control',
	TEST = 'test',
}

export enum SMART_BUTTON_BLANK_PAGE_QUICK_ACTIONS_COHORTS {
	CONTROL = 'control',
	SMART_BUTTON = 'smartButton',
}

const TABLE = (shouldTableBeFullWidth = false) => ({
	id: 'Table',
	testId: 'table-quick-action',
	name: { ...i18n.tableName },
	alt: { ...i18n.tableAltText },
	type: 'document',
	shortName: 'table',
	onClick: (
		editorView?: EditorView,
		editorApi?: PresetAPIType,
		setLocalIdForOnboarding?: (localId: string) => void,
	) => {
		if (!editorView || !editorApi || !setLocalIdForOnboarding) {
			return;
		}

		// find inserted table node's local id
		const tableInfo = getSelectedTableInfo(editorView.state.selection);
		if (!tableInfo) {
			return;
		}

		const { table } = tableInfo;
		const localId = table?.node.attrs.localId;

		setLocalIdForOnboarding(localId);
	},
	adf: [
		{
			type: 'table',
			attrs: {
				layout: 'default',
				width: shouldTableBeFullWidth ? 1800 : 760,
			},
			content: [
				{
					type: 'tableRow',
					content: [
						{
							type: 'tableHeader',
							attrs: {
								colspan: 1,
								rowspan: 1,
							},
							content: [
								{
									type: 'paragraph',
								},
							],
						},
						{
							type: 'tableHeader',
							attrs: {
								colspan: 1,
								rowspan: 1,
							},
							content: [
								{
									type: 'paragraph',
								},
							],
						},
						{
							type: 'tableHeader',
							attrs: {
								colspan: 1,
								rowspan: 1,
							},
							content: [
								{
									type: 'paragraph',
								},
							],
						},
					],
				},
				{
					type: 'tableRow',
					content: [
						{
							type: 'tableCell',
							attrs: {
								colspan: 1,
								rowspan: 1,
							},
							content: [
								{
									type: 'paragraph',
								},
							],
						},
						{
							type: 'tableCell',
							attrs: {
								colspan: 1,
								rowspan: 1,
							},
							content: [
								{
									type: 'paragraph',
								},
							],
						},
						{
							type: 'tableCell',
							attrs: {
								colspan: 1,
								rowspan: 1,
							},
							content: [
								{
									type: 'paragraph',
								},
							],
						},
					],
				},
				{
					type: 'tableRow',
					content: [
						{
							type: 'tableCell',
							attrs: {
								colspan: 1,
								rowspan: 1,
							},
							content: [
								{
									type: 'paragraph',
								},
							],
						},
						{
							type: 'tableCell',
							attrs: {
								colspan: 1,
								rowspan: 1,
							},
							content: [
								{
									type: 'paragraph',
								},
							],
						},
						{
							type: 'tableCell',
							attrs: {
								colspan: 1,
								rowspan: 1,
							},
							content: [
								{
									type: 'paragraph',
								},
							],
						},
					],
				},
			],
		},
	],
});

export const SMART_BUTTON = {
	id: 'SmartButton',
	testId: 'smart-button-quick-action',
	name: { ...i18n.smartButtonName },
	alt: { ...i18n.smartButtonAltText },
	title: { ...i18n.smartButtonTitle },
	type: 'com.atlassian.automation.smart-button',
	shortName: 'smartButton',
	adf: [
		{
			type: 'inlineExtension',
			key: 'smart-button',
			attrs: {
				extensionType: 'com.atlassian.automation.smart-button',
				extensionKey: 'smart-button',
				parameters: {
					extensionTitle: 'Smart Button',
				},
			},
		},
	],
};

const INFO_PANEL = {
	id: 'InfoPanel',
	testId: 'info-panel-quick-action',
	name: { ...i18n.infoPanelName },
	alt: { ...i18n.infoPanelAltText },
	type: 'document',
	shortName: 'panel',
	adf: [
		{
			type: 'panel',
			attrs: {
				panelType: 'info',
			},
			content: [
				{
					type: 'paragraph',
				},
			],
		},
	],
};

const BRAINSTORM = {
	id: 'Brainstorm',
	testId: 'brainstorm-quick-action',
	name: { ...i18n.brainstormName },
	alt: { ...i18n.brainstormAltText },
	type: 'plugin',
	shortName: 'brainstorm',
	onClick: (
		editorView?: EditorView,
		_editorApi?: PresetAPIType,
		_setLocalIdForOnboarding?: (localId: string) => void,
	) => {
		if (editorView) {
			UNSTABLE_DO_NOT_USE_ONLY_FOR_CONFLUENCE_triggerConfigItem({
				editorView,
				configItem: UNSTABLE_configItems.brainstormConfigItem,
			});
		}
	},
	adf: [],
};

export const START_WITH_AI: QuickActionType = {
	id: 'StartWithAI',
	testId: 'start-with-ai-quick-action',
	name: i18n.startWithAI,
	alt: i18n.startWithAI,
	type: 'plugin',
	shortName: 'ai',
	onClick: (
		_editorView?: EditorView,
		editorApi?: PresetAPIType,
		_setLocalIdForOnboarding?: (localId: string) => void,
	) => {
		editorApi?.aiExperience?.actions?.openAIModal({
			invokedFrom: 'blankPageQuickActions',
		});
	},
	adf: [],
};

const TABLE_OF_CONTENTS = {
	id: 'TableOfContents',
	testId: 'table-of-contents-quick-action',
	name: { ...i18n.tableOfContentsName },
	alt: { ...i18n.tableOfContentsAltText },
	title: { ...i18n.tableOfContentsTitle },
	type: 'macro',
	shortName: 'toc',
	adf: [
		{
			type: 'extension',
			attrs: {
				layout: 'default',
				extensionType: 'com.atlassian.confluence.macro.core',
				extensionKey: 'toc',
				parameters: {
					macroParams: {
						style: {
							value: 'none',
						},
					},
					macroMetadata: {
						schemaVersion: { value: '1' },
						title: 'Table of Contents',
					},
				},
			},
		},
	],
};

export const SHOW_MORE_ELEMENTS = {
	id: 'ShowMore',
};

export const QUICK_ACTIONS = (shouldTableBeFullWidth = false): Readonly<QuickActionType[]> => [
	TABLE(shouldTableBeFullWidth),
	INFO_PANEL,
	TABLE_OF_CONTENTS,
];

export const QUICK_ACTIONS_MILESTONE_2 = (
	shouldTableBeFullWidth = false,
): Readonly<QuickActionType[]> => [BRAINSTORM, TABLE_OF_CONTENTS, TABLE(shouldTableBeFullWidth)];

// blueprintModuleCompleteKey values for top templates
export enum BPQA_TOP_TEMPLATE_KEYS {
	HOW_TO_ARTICLE = 'com.atlassian.confluence.plugins.confluence-knowledge-base:kb-how-to-article-blueprint',
	PRODUCT_REQUIREMENTS = 'com.atlassian.confluence.plugins.confluence-software-blueprints:requirements-blueprint',
	PROJECT_PLAN = 'com.atlassian.confluence.plugins.confluence-business-blueprints:projectplan-blueprint',
	RETROSPECTIVE = 'com.atlassian.confluence.plugins.confluence-software-blueprints:retrospectives-blueprint',
	PRODUCT_ROADMAP = 'com.atlassian.confluence.plugins.confluence-business-blueprints:product-roadmap-blueprint',
	WEEKLY_MEETING_NOTES = 'com.atlassian.confluence.plugins.confluence-business-blueprints:recurring-meeting-minutes-blueprint',
	DECISIONS = 'com.atlassian.confluence.plugins.confluence-business-blueprints:decisions-blueprint',
	FILE_LIST = 'com.atlassian.confluence.plugins.confluence-business-blueprints:file-list-blueprint',
	LIVE_MEETING_NOTES = 'com.atlassian.confluence.plugins.confluence-business-blueprints:meeting-notes-live-page-blueprint',
}

export enum BPQA_EXCLUDED_TEMPLATE_KEYS {
	// blog post is the one itemModuleCompleteKey because
	// it has no blueprintModuleCompleteKey
	BLOG_POST = 'com.atlassian.confluence.plugins.confluence-create-content-plugin:create-blog-post',
	LEGACY_EDITOR = 'com.atlassian.confluence.plugins.confluence-business-blueprints:tinyeditor-blueprint',
	LIVE_MEETING_NOTES = 'com.atlassian.confluence.plugins.confluence-business-blueprints:meeting-notes-live-page-blueprint',
}

export const TEMPLATE_LIMIT = 3 + Object.keys(BPQA_EXCLUDED_TEMPLATE_KEYS).length; // Number of templates to show + possible exclusions
export const TEMPLATE_SKELETONS = 3;
export const TEMPLATE_PREVIEW_DELAY = 250;
