import { useContext } from 'react';
import { isFedRamp } from '@atlassian/atl-context';
import { ProductEnvironment } from '@atlassian/forge-ui-types';
import type { EgressFilteringService } from '@forge/egress';
import { useEgressPermissionService } from '../../utils/useEgressPermissionService';
import { EnvironmentContext, RendererContext } from '../../context';
import { getCDNEnvironment } from '../../utils';

// --------------------------------------------------------------------------------------------------------------------
//
// NOTE: any changes to these hosts MUST BE applied to the Forge Monorepo -- and vice versa.
// See packages/forge-csp/src/csp/csp-injection-service.ts for the original source code.
//
// --------------------------------------------------------------------------------------------------------------------

/**
 * Media API is used to store product attachments.
 * URLs are defined here: https://developer.atlassian.com/platform/media/support/faq/#which-environment-should-i-use-
 */
const ATLASSIAN_API_GATEWAY_HOST = {
	dev: 'https://api.dev.atlassian.com',
	stg: 'https://api.stg.atlassian.com',
	prod: 'https://api.atlassian.com',
	'fedramp-stg': 'https://api.stg.atlassian-us-gov-mod.com',
	'fedramp-prod': 'https://api.atlassian-us-gov-mod.com',
};
const ATLASSIAN_MEDIA_GATEWAY_HOST = {
	dev: 'https://media.dev.atl-paas.net',
	stg: 'https://media.staging.atl-paas.net',
	prod: 'https://api.media.atlassian.com',
	'fedramp-stg': 'https://api-media.stg.atlassian-us-gov-mod.com',
	'fedramp-prod': 'https://api-media.atlassian-us-gov-mod.com',
};
const ATLASSIAN_AVATAR_HOST = {
	dev: 'avatar-management--avatars.us-west-2.staging.public.atl-paas.net',
	stg: 'avatar-management--avatars.us-west-2.staging.public.atl-paas.net',
	prod: 'avatar-management--avatars.us-west-2.prod.public.atl-paas.net',
	'fedramp-stg': 'avatar-management--avatars.us-east-1.staging.cdn.atlassian-us-gov-mod.com',
	'fedramp-prod': 'avatar-management--avatars.us-east-1.prod.cdn.atlassian-us-gov-mod.com',
};
const ATLASSIAN_EMOJIS_HOST = {
	dev: 'https://pf-emoji-service--cdn.ap-southeast-2.dev.public.atl-paas.net',
	stg: 'https://pf-emoji-service--cdn.us-east-1.staging.public.atl-paas.net',
	prod: 'https://pf-emoji-service--cdn.us-east-1.prod.public.atl-paas.net',
	'fedramp-stg': 'https://pf-emoji-service--cdn.us-east-1.staging.cdn.atlassian-us-gov-mod.com',
	'fedramp-prod': 'https://pf-emoji-service--cdn.us-east-1.prod.cdn.atlassian-us-gov-mod.com',
};

/**
 * Atlassian products use Wordpress as a proxy to serve avatar images in certain conditions.
 * Not allowing them by default forces developers to add the `*.wp.com` domain to the CSP image policy.
 * See: https://hello.atlassian.net/wiki/x/XJpuCgE
 */
const ATLASSIAN_IMAGES_HOSTS = {
	dev: [
		`https://${ATLASSIAN_AVATAR_HOST['dev']}`,
		`https://*.wp.com/${ATLASSIAN_AVATAR_HOST['dev']}/`,
		ATLASSIAN_API_GATEWAY_HOST['dev'],
		ATLASSIAN_MEDIA_GATEWAY_HOST['dev'],
		ATLASSIAN_EMOJIS_HOST['dev'],
	],
	stg: [
		`https://${ATLASSIAN_AVATAR_HOST['stg']}`,
		`https://*.wp.com/${ATLASSIAN_AVATAR_HOST['stg']}/`,
		ATLASSIAN_API_GATEWAY_HOST['stg'],
		ATLASSIAN_MEDIA_GATEWAY_HOST['stg'],
		ATLASSIAN_EMOJIS_HOST['stg'],
	],
	prod: [
		`https://${ATLASSIAN_AVATAR_HOST['prod']}`,
		`https://*.wp.com/${ATLASSIAN_AVATAR_HOST['prod']}/`,
		ATLASSIAN_API_GATEWAY_HOST['prod'],
		ATLASSIAN_MEDIA_GATEWAY_HOST['prod'],
		ATLASSIAN_EMOJIS_HOST['prod'],
	],
	'fedramp-stg': [
		`https://${ATLASSIAN_AVATAR_HOST['fedramp-stg']}`,
		`https://*.wp.com/${ATLASSIAN_AVATAR_HOST['fedramp-stg']}/`,
		ATLASSIAN_API_GATEWAY_HOST['fedramp-stg'],
		ATLASSIAN_MEDIA_GATEWAY_HOST['fedramp-stg'],
		ATLASSIAN_EMOJIS_HOST['fedramp-stg'],
	],
	'fedramp-prod': [
		`https://${ATLASSIAN_AVATAR_HOST['fedramp-prod']}`,
		`https://*.wp.com/${ATLASSIAN_AVATAR_HOST['fedramp-prod']}/`,
		ATLASSIAN_API_GATEWAY_HOST['fedramp-prod'],
		ATLASSIAN_MEDIA_GATEWAY_HOST['fedramp-prod'],
		ATLASSIAN_EMOJIS_HOST['fedramp-prod'],
	],
};
// --------------------------------------------------------------------------------------------------------------------

/**
 * Gravatar is still used internally to serve legacy Atlassian avatars.
 * Unsplash is integrated by default in Confluence to the header image picker functionality.
 */
const EXTERNAL_ALLOW_LISTED_IMAGES_HOSTS = [
	'https://secure.gravatar.com',
	'https://images.unsplash.com',
];

export const isLocalImage = (url: string, iframeDomainName: string | undefined): boolean => {
	if (!iframeDomainName) {
		return false;
	}

	let imageSrcUrl;
	try {
		imageSrcUrl = new URL(url);
		// Image is valid if it comes from the same domain as the iframe OR if it's being served from localhost (when using forge tunnel)
		return iframeDomainName === imageSrcUrl.host || imageSrcUrl.hostname === 'localhost';
	} catch (e) {
		return false;
	}
};

type ImageHostsEnvironment = keyof typeof ATLASSIAN_IMAGES_HOSTS;

const getImageHostsEnvironment = (environment: ProductEnvironment): ImageHostsEnvironment => {
	if (isFedRamp()) {
		if (environment === ProductEnvironment.STAGING) {
			return 'fedramp-stg';
		}
		return 'fedramp-prod';
	}
	// getCDNEnvironment() used "fex" from the type ForgeCDNEnvironment for FedRamp sandbox.
	// This is caught by the isFedRamp() check above, but does not exist on the ATLASSIAN_IMAGES_HOSTS object.
	// This is why we need to use "as ImageHostsEnvironment" to cast the return value to the correct type.
	return getCDNEnvironment(environment) as ImageHostsEnvironment;
};

export const useIsValidImageUrl = (url: string): boolean => {
	const { egress, appDomainName } = useContext(RendererContext);
	const environment = useContext(EnvironmentContext);

	const imageHostsEnvironment = getImageHostsEnvironment(environment);
	const allowListedImageHosts = [
		...ATLASSIAN_IMAGES_HOSTS[imageHostsEnvironment],
		...EXTERNAL_ALLOW_LISTED_IMAGES_HOSTS,
	];

	const egressService = useEgressPermissionService('IMAGES', [
		...(egress || []),
		{
			type: 'IMAGES',
			addresses: allowListedImageHosts,
		},
	]);

	return isValidImageUrl(url, egressService, appDomainName);
};

export const isValidImageUrl = (
	url: string,
	egressService: EgressFilteringService,
	appDomainName: string | undefined,
): boolean => {
	const dataImageUrlRegex = /^data:image/;

	try {
		const isValidUrl =
			isLocalImage(url, appDomainName) ||
			dataImageUrlRegex.test(url) ||
			egressService.isValidUrl(url);

		return isValidUrl;
	} catch (e) {
		return false;
	}
};
