import type { ComponentType } from 'react';

import { LoadableAfterPaint } from '@confluence/loadable';

/**
 * A React component that you can mount to signal to the browser, the router,
 * and any other components that choose to listen, that they should request
 * confirmation before allowing the user to navigate away from, close, or
 * reload, the current page (or, say, unmount an element within the page like a
 * tab or modal that's hosting an element with staged changes).
 */
export const NavigationLockout = LoadableAfterPaint({
	loader: async () =>
		(await import(/* webpackChunkName: "loadable-NavigationLockout" */ './NavigationLockout'))
			.NavigationLockout as ComponentType<any>,
});
