import React from 'react';

import type { MessageDescriptor } from 'react-intl-next';

import { aiConfigItemMessages } from '@atlaskit/editor-common/ai-messages';
import ChangeToneIcon from '@atlaskit/icon/core/microphone';
import { fg } from '@atlaskit/platform-feature-flags';

import {
	type ChangeToneBusinessCasualIntentSchema,
	type ChangeToneEducationalIntentSchema,
	type ChangeToneEmpatheticIntentSchema,
	type ChangeToneNeutralIntentSchema,
	type ChangeToneProfessionalIntentSchema,
} from '../../../../../types/prompt-request-types';
import { ToneCasualIcon } from '../../../../icons/prompt/toneCasual';
import { ToneEducationalIcon } from '../../../../icons/prompt/toneEducational';
import { ToneEmpatheticIcon } from '../../../../icons/prompt/toneEmpathetic';
import { ToneNeutralIcon } from '../../../../icons/prompt/toneNeutral';
import { ToneProfessionalIcon } from '../../../../icons/prompt/toneProfessional';
import { createInsertBelow, createReplace } from '../../actions/create-actions';
import { CONFIG_ITEM_KEYS } from '../../config-item-keys';
import type {
	EditorPluginAIConfigItem,
	EditorPluginAIConfigItemWithOptions,
} from '../../config-items';

function createChangeToneOption({
	key,
	title,
	description,
	intentSchemaId,
	icon,
	shortTitle,
}: Pick<EditorPluginAIConfigItem, 'key' | 'title' | 'description' | 'icon'> & {
	shortTitle: MessageDescriptor;
	intentSchemaId:
		| ChangeToneBusinessCasualIntentSchema
		| ChangeToneEducationalIntentSchema
		| ChangeToneEmpatheticIntentSchema
		| ChangeToneNeutralIntentSchema
		| ChangeToneProfessionalIntentSchema;
}): EditorPluginAIConfigItem {
	return {
		key,
		title,
		description,
		icon,
		showInRefineDropdown: true,
		intentSchemaId,
		nestingConfig: {
			id: 'change-tone-to',
			parentTestId: 'change-tone-to',
			parentTitle: aiConfigItemMessages.changeToneNestingParentTitlePostGA,
			shortTitle,
			icon: () => <ChangeToneIcon label="" spacing="spacious" />,
		},
		actions: {
			empty: [
				createInsertBelow({ appearance: 'secondary' }),
				createReplace({ appearance: 'primary' }),
			],
			selection: [
				createInsertBelow({ appearance: 'secondary' }),
				createReplace({ appearance: 'primary' }),
			],
		},
		getBackendModel: () => {
			if (
				fg('platform_editor_ai_assistance_service') ||
				fg('platform_editor_ai_assistance_service_second')
			) {
				return 'assistance-service';
			}

			return 'xp-gen-ai';
		},
	};
}

export const changeToneToNeutral = createChangeToneOption({
	key: CONFIG_ITEM_KEYS.CHANGE_TONE_TO_NEUTRAL,
	title: aiConfigItemMessages.changeToneNeutralToneTitle,
	shortTitle: aiConfigItemMessages.changeToneNeutralToneShortTitle,
	description: aiConfigItemMessages.changeToneNeutralToneDescription,
	intentSchemaId: 'change_tone_neutral_intent_schema.json',
	icon: () => <ToneNeutralIcon />,
});

export const changeToneToNeutralWithOptions: EditorPluginAIConfigItemWithOptions = {
	config: changeToneToNeutral,
	triggers: { empty: { enabled: true, docMinSize: 1 }, selection: { enabled: true } },
};

export const changeToneToProfessional = createChangeToneOption({
	key: CONFIG_ITEM_KEYS.CHANGE_TONE_TO_PROFESSIONAL,
	title: aiConfigItemMessages.changeToneProfessionalToneTitle,
	shortTitle: aiConfigItemMessages.changeToneProfessionalToneShortTitle,
	description: aiConfigItemMessages.changeToneProfessionalToneDescription,
	intentSchemaId: 'change_tone_professional_intent_schema.json',
	icon: () => <ToneProfessionalIcon />,
});

export const changeToneToProfessionalWithOptions: EditorPluginAIConfigItemWithOptions = {
	config: changeToneToProfessional,
	triggers: { empty: { enabled: true, docMinSize: 1 }, selection: { enabled: true } },
};

export const changeToneToCasual = createChangeToneOption({
	key: CONFIG_ITEM_KEYS.CHANGE_TONE_TO_CASUAL,
	title: aiConfigItemMessages.changeToneCasualToneTitle,
	shortTitle: aiConfigItemMessages.changeToneCasualToneShortTitle,
	description: aiConfigItemMessages.changeToneCasualToneDescription,
	intentSchemaId: 'change_tone_business_casual_intent_schema.json',
	icon: () => <ToneCasualIcon />,
});

export const changeToneToCasualWithOptions: EditorPluginAIConfigItemWithOptions = {
	config: changeToneToCasual,
	triggers: { empty: { enabled: true, docMinSize: 1 }, selection: { enabled: true } },
};

export const changeToneToEducational = createChangeToneOption({
	key: CONFIG_ITEM_KEYS.CHANGE_TONE_TO_EDUCATIONAL,
	title: aiConfigItemMessages.changeToneEducationalToneTitle,
	shortTitle: aiConfigItemMessages.changeToneEducationalToneShortTitle,
	description: aiConfigItemMessages.changeToneEducationalToneDescription,
	intentSchemaId: 'change_tone_educational_intent_schema.json',
	icon: () => <ToneEducationalIcon />,
});

export const changeToneToEducationalWithOptions: EditorPluginAIConfigItemWithOptions = {
	config: changeToneToEducational,
	triggers: { empty: { enabled: true, docMinSize: 1 }, selection: { enabled: true } },
};

export const changeToneToEmpathetic = createChangeToneOption({
	key: CONFIG_ITEM_KEYS.CHANGE_TONE_TO_EMPATHETIC,
	title: aiConfigItemMessages.changeToneEmpatheticToneTitle,
	shortTitle: aiConfigItemMessages.changeToneEmpatheticToneShortTitle,
	description: aiConfigItemMessages.changeToneEmpatheticToneDescription,
	intentSchemaId: 'change_tone_empathetic_intent_schema.json',
	icon: () => <ToneEmpatheticIcon />,
});

export const changeToneToEmpatheticWithOptions: EditorPluginAIConfigItemWithOptions = {
	config: changeToneToEmpathetic,
	triggers: { empty: { enabled: true, docMinSize: 1 }, selection: { enabled: true } },
};
