import { type GasV3Payload } from './analytics-helpers/types';
import type { Options, OptionsV1 } from '@atlassiansox/cross-flow-api-internals';

export const enrichWithRequestOptions = (
	event: GasV3Payload,
	requestOptions?: Options | OptionsV1,
): GasV3Payload => {
	if (!requestOptions) {
		return event;
	}

	const attributes = {
		// Spread the extra analytics attributes first, because we don't want consumers to override any event attributes below
		...(requestOptions.extraAnalyticsAttributes || {}),
		...(event.attributes || {}),
		sourceComponent: requestOptions.sourceComponent as string,
		sourceContext: requestOptions.sourceContext as string,
	};
	return { ...event, attributes };
};
