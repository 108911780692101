import { preloadPostOfficePlacementWithSessionContext } from '@atlassian/confluence-post-office-provider/entry-points/preload-placement';

import type { PreloadFnContext } from '@confluence/query-preloader-tools';

export const preloadPostOfficeConfluenceHomeBanner = async (
	preloadedSessionContext: PreloadFnContext,
) => {
	return preloadPostOfficePlacementWithSessionContext(
		'confluence-home-banner',
		preloadedSessionContext,
	);
};
