import React, { useEffect, useState } from 'react';
import { FormattedMessage, defineMessages } from 'react-intl-next';

import { Attribution, TransparentErrorBoundary } from '@confluence/error-boundary';
import type { WithFlagsProps } from '@confluence/flags';
import { withFlags } from '@confluence/flags';
import { isBranchDeployFn } from '@confluence/atlassian-internal-help-items';
import { useSessionData } from '@confluence/session-data';
import { StatsigConfigurations } from '@confluence/statsig-client/entry-points/StatsigConfigurations';

import { RenderFailureErrorMessage } from './RenderFailureErrorMessage';
import { RenderHydrationFailureMessage } from './RenderHydrationFailureMessage';

export const i18n = defineMessages({
	ssrRenderErrorFlagTitle: {
		id: 'ssr-render-failure-notification.flag',
		defaultMessage: 'This page was NOT SSR RENDERED!',
		description: 'Title to be shown when the SSR render failure flag is showing.',
	},
	hydrationErrorFlagTitle: {
		id: 'ssr-render-failure-notification.hydration.flag',
		defaultMessage: 'This page was NOT HYDRATED PROPERLY!',
		description: 'Title to be shown when the hydration failure flag is showing.',
	},
});

export const SSRRenderFailureNotificationComponent = withFlags(({ flags }: WithFlagsProps) => {
	const [hasShownErrorFlag, setHasShownErrorFlag] = useState<boolean>(false);
	const { environment } = useSessionData();

	// we want to exclude testing environments and pollinator tenants from showing the error flag
	const isTestEnvironment = StatsigConfigurations.isKillSwitchOn(
		'confluence_frontend_ssr_failure_notification',
	);

	// We only want to show the error flag in the dev/staging environment
	// however, branch deploys on production will default to process.env.CLOUD_ENV = 'branch'
	const isStagingEnvironment =
		['dev', 'branch', 'staging', 'stg-fedm'].includes(process.env.CLOUD_ENV) &&
		environment !== 'PRODUCTION';

	useEffect(() => {
		if (!isStagingEnvironment || isTestEnvironment) {
			return;
		}

		// sometimes errors exist but the page is still SSRd succesfully - we don't want to show the SSR render error flag in this case
		if (window.__SSR_RENDERED__) {
			// if the page is SSRd successfully we check if the page was hydrated successfully, and show a hydration error flag if not
			if (!hasShownErrorFlag && (window as any).__HYDRATION_FAILED__) {
				void flags.showErrorFlag({
					title: <FormattedMessage {...i18n.hydrationErrorFlagTitle} />,
					isAutoDismiss: false,
					description: <RenderHydrationFailureMessage />,
				});
				setHasShownErrorFlag(true);
			}
			return;
		}

		// COMPILED dev warnings show up on every SSRd page even if the page is SSRd successfully,
		// so we need to filter them out to distinguish from actual errors being reported for local dev ssr
		const ssrErrors = window.__SSR_ERROR__?.filter((err) => !err.includes('DEV WARNING')) ?? [];

		// on old branch deploys, ssr errors can still be empty but the page might not be SSRd successfully
		const isBranchDeployFailure =
			isBranchDeployFn() && !window.__SSR_RENDERED__ && !ssrErrors?.length;

		if (!hasShownErrorFlag && (isBranchDeployFailure || ssrErrors.length)) {
			void flags.showErrorFlag({
				title: <FormattedMessage {...i18n.ssrRenderErrorFlagTitle} />,
				isAutoDismiss: false,
				description: (
					<RenderFailureErrorMessage
						ssrErrors={ssrErrors}
						isBranchDeployFailure={isBranchDeployFailure}
					/>
				),
			});
			setHasShownErrorFlag(true);
		}
	}, [isStagingEnvironment, flags, hasShownErrorFlag, isTestEnvironment]);

	return null;
});

export const SSRRenderFailureNotification = () => {
	return (
		<TransparentErrorBoundary attribution={Attribution.BACKBONE}>
			<SSRRenderFailureNotificationComponent />
		</TransparentErrorBoundary>
	);
};
