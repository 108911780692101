import uuid from 'uuid/v4';

import { type Logger } from '../resiliencedb';
import getBatchableQueue, {
	type BatchableQueue,
	type BatchFlushCallback,
	type RetryQueueOptions,
} from '../resilienceQueue';
import getMetricsCollector, { type MetricsCollector } from '../resilienceQueue/Metrics';
import { type Context, type EventOverrides } from '../types';
import { attachXidToMultipleEvents, type XIDPromise } from '../xid';

import { DEFAULT_REQUEST_TIMEOUT } from './defaults';
import { HTTP_STATUS_CODE_MAP } from './HttpStatusCode';
import { sendEvents } from './sendEvents';
import {
	type BaseSegmentEvent,
	type LibraryMetadataDef,
	type PackagedEvent,
	type SegmentBatchDef,
	type SegmentEvent,
	SegmentEventTypes,
	type SegmentIdentifyEventDef,
	type SegmentIdentifyEventTraitsDef,
	type SegmentProperties,
	type SegmentScreenEventDef,
	type SegmentTrackEventDef,
	type SegmentTrackPropertiesDef,
} from './types';
import User from './user';
import { buildContext, prepareEventProperties } from './util';

export type Options = {
	apiHost: string;
	apiHostProtocol: string;
	retryQueueOptions?: RetryQueueOptions;
	retryQueuePrefix: string;
	product: string;
	requestTimeout?: number;
	xidPromiseGetter: () => ReturnType<typeof XIDPromise>;
	logger?: Logger;
	disableCookiePersistence?: boolean;
	responseCallback: ResponseCallback<AnalyticsResponse>;
};

class AnalyticsAPIResponse {
	success: boolean;
	validationErrors: Map<string, string>;
	message: Message;
	code: number;
	validationReports: EventValidationReport[];

	constructor(
		success: boolean,
		validationErrors: Map<string, string>,
		message: Message,
		code: number,
		validationReports: EventValidationReport[],
	) {
		this.success = success;
		this.validationErrors = validationErrors;
		this.message = message;
		this.code = code;
		this.validationReports = validationReports;
	}

	public isSuccess(): boolean {
		return this.success;
	}

	public getValidationErrors(): Map<string, string> {
		return this.validationErrors;
	}

	public getMessage(): Message {
		return this.message;
	}

	public getCode(): number {
		return this.code;
	}

	public getValidationReports(): EventValidationReport[] {
		return this.validationReports;
	}
}

class Message {
	error: string;

	constructor(error: string) {
		this.error = error;
	}
}

class EventValidationReport {
	messageId: string;
	results: EventValidationResult[];

	constructor(messageId: string, results: EventValidationResult[]) {
		this.messageId = messageId;
		this.results = results;
	}

	public getMessageId(): string {
		return this.messageId;
	}

	public getResults(): EventValidationResult[] {
		return this.results;
	}
}

export class AnalyticsResponse {
	public success: boolean;
	public statusCode: number;
	public message: string;
	public eventResponseList: EventResponse[];

	constructor(
		success: boolean,
		statusCode: number,
		message: string,
		eventResponseList: EventResponse[],
	) {
		this.success = success;
		this.statusCode = statusCode;
		this.message = message;
		this.eventResponseList = eventResponseList;
	}

	public isSuccess(): boolean {
		return this.success;
	}

	public getStatusCode(): number {
		return this.statusCode;
	}

	public getMessage(): string {
		return this.message;
	}

	public getEventResponseList(): EventResponse[] {
		return this.eventResponseList;
	}
}

class EventResponse {
	private messageId: string;
	private results: EventValidationResult[];
	private segmentEvent: SegmentEvent;

	constructor(messageId: string, results: EventValidationResult[], segmentEvent: SegmentEvent) {
		this.messageId = messageId;
		this.results = results;
		this.segmentEvent = segmentEvent;
	}

	public getMessageId(): string {
		return this.messageId;
	}

	public getResults(): EventValidationResult[] {
		return this.results;
	}

	public getSegmentEvent(): SegmentEvent {
		return this.segmentEvent;
	}
}

class EventValidationResult {
	private type: string;
	private severity: string;
	private propertyPath: string;
	private message: string;

	constructor(type: string, severity: string, propertyPath: string, message: string) {
		this.type = type;
		this.severity = severity;
		this.propertyPath = propertyPath;
		this.message = message;
	}

	public getType(): string {
		return this.type;
	}

	public getSeverity(): string {
		return this.severity;
	}

	public getPropertyPath(): string {
		return this.propertyPath;
	}

	public getMessage(): string {
		return this.message;
	}
}

export type ResponseCallback<AnalyticsResponse> = (response: AnalyticsResponse) => void;

export default class EventProcessor {
	private user: User;
	private options: Required<Options>;

	private resilienceQueue: BatchableQueue<PackagedEvent>;

	private gasv3BatchUrl: string;
	private metrics: MetricsCollector;
	private xidPromiseCallback: ReturnType<typeof XIDPromise>;

	private responseCallback: ResponseCallback<AnalyticsResponse>;

	constructor(options: Options) {
		this.options = {
			...options,
			requestTimeout: options.requestTimeout || DEFAULT_REQUEST_TIMEOUT,
			retryQueueOptions: options.retryQueueOptions || {},
			logger: options.logger || console,
			disableCookiePersistence: options.disableCookiePersistence || false,
		};
		this.user = new User(this.options?.disableCookiePersistence);

		this.xidPromiseCallback = options.xidPromiseGetter();
		this.gasv3BatchUrl = `${options.apiHostProtocol}://${options.apiHost}/batch`;
		this.metrics = getMetricsCollector();

		this.responseCallback = options.responseCallback;

		this.resilienceQueue = getBatchableQueue(
			options.retryQueuePrefix,
			options.product,
			this.options.retryQueueOptions,
			this.options.logger,
		);
		this.resilienceQueue.start(this.sendEvents);
	}

	getUser(): User {
		return this.user;
	}

	async track(
		eventName: string,
		builtEvent: SegmentTrackPropertiesDef & EventOverrides,
		context: Context,
		callback?: () => void,
	) {
		const baseEvent = this.buildBaseEvent(context, SegmentEventTypes.TRACK, builtEvent);
		const eventWithoutMessageId: Omit<SegmentTrackEventDef, 'messageId'> = {
			...baseEvent,
			type: SegmentEventTypes.TRACK,
			properties: prepareEventProperties(builtEvent),
			event: eventName,
		};
		const event: SegmentTrackEventDef = {
			...eventWithoutMessageId,
			messageId: this.createMessageId(),
		};
		const packagedEvent = this.packageEvent(event);

		await this.resilienceQueue.addItem(packagedEvent);
		if (callback) {
			callback();
		}
	}

	async page(
		eventName: string,
		builtEvent: SegmentProperties & EventOverrides,
		context: Context,
		callback?: () => void,
	) {
		const baseEvent = this.buildBaseEvent(context, SegmentEventTypes.PAGE, builtEvent);
		const eventWithoutMessageId: Omit<SegmentScreenEventDef, 'messageId'> = {
			...baseEvent,
			type: SegmentEventTypes.PAGE,
			properties: prepareEventProperties(builtEvent),
			name: eventName,
		};
		const event: SegmentScreenEventDef = {
			...eventWithoutMessageId,
			messageId: this.createMessageId(),
		};
		const packagedEvent = this.packageEvent(event);

		await this.resilienceQueue.addItem(packagedEvent);
		if (callback) {
			callback();
		}
	}

	// Segment uses the identifier to update user id which we have already done in the analyticsWebClient.ts
	async identify(
		_identifier: string,
		builtEvent: SegmentIdentifyEventTraitsDef & EventOverrides,
		context: Context,
		callback?: () => void,
	) {
		const baseEvent = this.buildBaseEvent(context, SegmentEventTypes.IDENTIFY, builtEvent);
		const eventWithoutMessageId: Omit<SegmentIdentifyEventDef, 'messageId'> = {
			...baseEvent,
			type: SegmentEventTypes.IDENTIFY,
			traits: prepareEventProperties(builtEvent),
		};
		const event: SegmentIdentifyEventDef = {
			...eventWithoutMessageId,
			messageId: this.createMessageId(),
		};
		const packagedEvent = this.packageEvent(event);
		await this.resilienceQueue.addItem(packagedEvent);
		if (callback) {
			callback();
		}
	}

	private buildBaseEvent(
		context: Context,
		type: SegmentEventTypes,
		overrides: EventOverrides,
	): Omit<BaseSegmentEvent, 'messageId'> {
		const clonedContext = prepareEventProperties(context);
		const segmentContext = buildContext(clonedContext);
		return {
			context: segmentContext,
			timestamp: new Date().toISOString(),
			type,
			userId: this.user.getUserId(),
			anonymousId: overrides.anonymousId || this.user.getAnonymousId(),
		};
	}

	private createMessageId(): string {
		return `ajs-${uuid()}`;
	}

	private packageEvent(event: SegmentEvent): PackagedEvent {
		const { apiHost, apiHostProtocol } = this.options;
		return {
			headers: {
				'Content-Type': 'text/plain',
			},
			msg: event,
			url: `${apiHostProtocol}://${apiHost}/${event.type.charAt(0)}`,
		};
	}

	// Using anonymous function so it can have the BatchFlushCallback type associated with it
	// And to allow it to not need bind when passing through to ResilieceQueue.
	public sendEvents: BatchFlushCallback<PackagedEvent> = async (items, callback) => {
		const httpRetryCount = this.resilienceQueue.getGlobalRetryCount();
		const metricsPayload = this.metrics.getMetricsPayload();
		const metadata: LibraryMetadataDef = {
			...metricsPayload,
			httpRetryCount,
		};
		for (let key in metadata) {
			// @ts-ignore Some keys maybe a string, but these will never equal 0
			if (metadata[key] === 0) {
				// @ts-ignore Save space in requests by removing metrics with no impact
				delete metadata[key];
			}
		}

		const eventsWithXID = await this.attachXIDs(items);

		// Calculating sentAt after the XID generation as this may take some time.
		const sentAt = new Date().toISOString();
		const unpackagedEvents = eventsWithXID.map((item) => {
			item.msg.sentAt = sentAt;
			return item.msg;
		});

		const batchBody: SegmentBatchDef = {
			batch: unpackagedEvents,
			sentAt,
			metadata,
		};

		const localResponseCallback: ResponseCallback<AnalyticsResponse> = this.responseCallback;
		try {
			const response = await sendEvents({
				url: this.gasv3BatchUrl,
				batch: batchBody,
				timeout: this.options.requestTimeout,
			});

			this.metrics.subtractFromMetrics(metricsPayload);
			callback(null, response);

			if (localResponseCallback !== null && response !== null) {
				const analyticsAPIResponse = await response.clone().json();
				const analyticsResponse: AnalyticsResponse = BatchInterceptor.toAnalyticsResponse(
					items,
					null as any as Error,
					analyticsAPIResponse,
				);
				localResponseCallback(analyticsResponse);
			}
		} catch (error) {
			callback(error, null);
			if (localResponseCallback !== null && error !== null) {
				const analyticsResponse: AnalyticsResponse = BatchInterceptor.toAnalyticsResponse(
					items,
					error as Error,
					null as any as AnalyticsAPIResponse,
				);
				localResponseCallback(analyticsResponse);
			}
		}
	};

	private async attachXIDs(items: PackagedEvent[]): Promise<PackagedEvent[]> {
		if (this.xidPromiseCallback) {
			return attachXidToMultipleEvents(items, this.xidPromiseCallback);
		}
		return Promise.resolve(items);
	}

	public setResponseCallback(responseCallback: ResponseCallback<AnalyticsResponse>) {
		this.responseCallback = responseCallback;
	}
}

class BatchInterceptor {
	private static readonly http_error_regex = /HTTP Error\s([0-9]{3})\s\((.+)\)/;
	public static toAnalyticsResponse(
		items: PackagedEvent[],
		error: Error,
		analyticsAPIResponse: AnalyticsAPIResponse,
	): AnalyticsResponse {
		const eventResponseList: EventResponse[] = [];
		if (analyticsAPIResponse !== null) {
			const [statusCode, success] = [analyticsAPIResponse.code, analyticsAPIResponse.success];
			if (statusCode >= 200 && statusCode <= 299) {
				analyticsAPIResponse.validationReports?.forEach((report: EventValidationReport) => {
					const matchedEvent = items.find(
						(item: PackagedEvent) => item.msg.messageId === report.messageId,
					);
					if (matchedEvent) {
						eventResponseList.push(
							new EventResponse(matchedEvent.msg.messageId, report.results, matchedEvent.msg),
						);
					}
				});
				return new AnalyticsResponse(
					success,
					statusCode,
					HTTP_STATUS_CODE_MAP.get(statusCode) as string,
					eventResponseList,
				);
			} else if (statusCode >= 400 && statusCode <= 499) {
				// not exluding "HTTP 429 Too Many Requests error" as it is thrown as an error and handled below
				const errorMessage =
					analyticsAPIResponse.message?.error !== null
						? analyticsAPIResponse.message.error
						: HTTP_STATUS_CODE_MAP.has(statusCode)
							? HTTP_STATUS_CODE_MAP.get(statusCode)
							: HTTP_STATUS_CODE_MAP.get(-1);
				items.forEach((item: PackagedEvent) => {
					eventResponseList.push(new EventResponse(item.msg.messageId, [], item.msg));
				});
				return new AnalyticsResponse(
					success,
					statusCode,
					errorMessage as string,
					eventResponseList,
				);
			}
		} else if (error !== null && error.message !== null) {
			const match = error.message.match(BatchInterceptor.http_error_regex);
			if (match) {
				items.forEach((item: PackagedEvent) => {
					eventResponseList.push(new EventResponse(item.msg.messageId, [], item.msg));
				});
				return new AnalyticsResponse(false, Number(match[1]), match[2], eventResponseList);
			}
		}
		items.forEach((item: PackagedEvent) => {
			eventResponseList.push(new EventResponse(item.msg.messageId, [], item.msg));
		});
		return new AnalyticsResponse(
			false,
			-1,
			HTTP_STATUS_CODE_MAP.get(-1) as string,
			eventResponseList,
		);
	}
}
