import gql from 'graphql-tag';

export const SpaceIdentifiersQuery = gql`
	query SpaceIdentifiersQuery($spaceKey: String) {
		space(key: $spaceKey) {
			id
			key
		}
	}
`;
