/**
 * @jsxRuntime classic
 * @jsx jsx
 */
import type { ReactNode } from 'react';
// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import { css, jsx } from '@emotion/react';
import {
	type ForgeDoc,
	MarkupList,
	MarkupRecursiveList,
	type RenderedTextProps,
	type TextAlign,
} from '@atlassian/forge-ui-types';
import { type Props } from '../..';
import { InlineContext } from '../../../context/inline';
import { Text } from '@atlaskit/primitives';

// exported for testing
export function toParagraphs(text: string): string[] {
	return text.split(/\s*\n\s*\n[\n\s]*/).filter((str) => str.length > 0);
}
export function TextPlain({ content }: { content: string }) {
	return (
		<div>
			{toParagraphs(content).map((paragraph, i) => (
				<Text as="p" key={i}>
					{paragraph}
				</Text>
			))}
		</div>
	);
}

const ALIGN_ALLOWLIST: TextAlign[] = ['start', 'center', 'end'];

const isTextAlign = (align?: string): align is TextAlign => {
	return ALIGN_ALLOWLIST.includes(align as TextAlign);
};

export function validateAlign(align?: string): TextAlign {
	if (isTextAlign(align)) {
		return align;
	} else {
		return 'start';
	}
}

export function TextMarkup({
	children,
	align,
}: {
	children: ReactNode | ReactNode[];
	align?: TextAlign;
}) {
	const sanitizedAlign = validateAlign(align);
	return (
		<InlineContext.Provider value={{ inline: true }}>
			<div
				// eslint-disable-next-line @atlaskit/design-system/consistent-css-prop-usage, @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
				css={css({
					// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
					textAlign: sanitizedAlign,
				})}
			>
				{children}
			</div>
		</InlineContext.Provider>
	);
}

function filterMarkup(children: ForgeDoc[], nested: boolean): ForgeDoc[] {
	const isNestable = (child: ForgeDoc) => MarkupRecursiveList.some((x) => child.type === x);
	const isInline = (child: ForgeDoc) => MarkupList.some((x) => child.type === x);

	return children
		.map((child) => {
			if (nested) {
				if (!isNestable(child)) {
					return null;
				}
			} else {
				if (!isInline(child)) {
					return null;
				}
			}

			return {
				...child,
				children: filterMarkup(child.children, true),
			};
		})
		.filter((x): x is ForgeDoc => x !== null);
}

export function TextMarkupFn({
	Components,
	children,
	align,
	dispatch,
	render,
	renderChildren,
}: Props & { align: TextAlign }) {
	// filter non Markup components
	children = filterMarkup(children, false);

	return (
		<TextMarkup align={align}>
			{renderChildren({ Components, children, render, dispatch })}
		</TextMarkup>
	);
}

export function TextFn({
	props,
	children,
	type,
	dispatch,
	render,
	renderChildren,
	Components,
}: Props) {
	const { content, format } = props as RenderedTextProps;

	if (typeof content === 'string') {
		return <TextPlain content={content} />;
	}

	if (format === 'markup') {
		const { align } = props as Extract<RenderedTextProps, { format: 'markup' }>;
		return TextMarkupFn({
			children,
			align,
			type,
			dispatch,
			render,
			renderChildren,
			Components,
		});
	}

	// otherwise, do nothing
	return null;
}
