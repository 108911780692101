import Cookies from 'js-cookie';

import { fg } from '@atlaskit/platform-feature-flags';

import { type CategorizedStorageControlsCache, CategoryStorageType } from '../../../types';
import { CacheLoader } from '../load-storage-controls-data';
import { Logger } from '../logger';

let analyticsEnabledGlobalState: boolean | undefined;
let productGlobalState: string | undefined;
let webStorageControlsEnabledGlobalState: boolean | undefined;
let localConsentModeGlobalState: boolean | undefined;
let packageDebugModeEnabledGlobalState: boolean | undefined;
let allowUncategorizedCookiesInOverridesGlobalState: boolean | undefined;
let hasInitialized: boolean = false;

let cookiesCacheLoader: CacheLoader | undefined;
let localStorageCacheLoader: CacheLoader | undefined;
let sessionStorageCacheLoader: CacheLoader | undefined;

let loadCache: (
	storageType: CategoryStorageType,
) => Promise<CategorizedStorageControlsCache | undefined> = async (
	storageType: CategoryStorageType,
) => {
	switch (storageType) {
		case CategoryStorageType.Cookies:
			return await cookiesCacheLoader?.loadStorageControlsData();
		case CategoryStorageType.LocalStorage:
			return await localStorageCacheLoader?.loadStorageControlsData();
		case CategoryStorageType.SessionStorage:
			return await sessionStorageCacheLoader?.loadStorageControlsData();
		default:
			return undefined;
	}
};

interface InitializeBscGlobalStateParams {
	// Common params
	analyticsEnabled?: boolean;
	product?: string;
	localConsentMode?: boolean;

	// InitializeControls only
	webStorageEnabled?: boolean;
	allowUncategorizedCookiesInOverrides?: boolean;
}

interface OverrideBscGlobalStateParams {
	loadCache?: typeof loadCache;
	product?: typeof productGlobalState;
}

export interface BscGlobalState {
	analyticsEnabled?: boolean;
	localConsentMode?: boolean;
	product?: string;

	webStorageEnabled?: boolean;
	allowUncategorizedCookiesInOverrides?: boolean;

	packageDebugMode?: boolean;
	cookiesCacheLoader?: CacheLoader;
	localStorageCacheLoader?: CacheLoader;
	sessionStorageCacheLoader?: CacheLoader;
	hasInitialized?: boolean;

	loadCache: (
		storageType: CategoryStorageType,
	) => Promise<CategorizedStorageControlsCache | undefined>;
}

export const getBscGlobalState: () => BscGlobalState = () => {
	return {
		analyticsEnabled: analyticsEnabledGlobalState ?? true,
		product: productGlobalState,
		webStorageEnabled: webStorageControlsEnabledGlobalState ?? false,
		localConsentMode: localConsentModeGlobalState ?? false,
		packageDebugMode: packageDebugModeEnabledGlobalState,
		allowUncategorizedCookiesInOverrides: allowUncategorizedCookiesInOverridesGlobalState ?? false,
		cookiesCacheLoader,
		localStorageCacheLoader,
		sessionStorageCacheLoader,
		loadCache,
		hasInitialized,
	};
};

export const initializeGlobalState = ({
	webStorageEnabled,
	allowUncategorizedCookiesInOverrides,
	localConsentMode = false,
	product = 'all',
	analyticsEnabled = true,
}: InitializeBscGlobalStateParams = {}) => {
	Logger.debug('Initialize global state invoked');

	// If the app provides a value for product only in one place,
	// we want that value to be sticky and to override the default if necessary
	if (
		(!productGlobalState || productGlobalState == null || productGlobalState === 'all') &&
		product
	) {
		productGlobalState = product;
	}

	// Treating webStorageEnabled and allowUncategorizedCookiesInOverrides differently
	// because they are only passed through initializeControls and
	// we don't want it to be defaulted to false via a call from the ConsentBanner
	if (webStorageEnabled !== undefined && webStorageControlsEnabledGlobalState === undefined) {
		webStorageControlsEnabledGlobalState =
			webStorageEnabled &&
			!fg('platform_moonjelly_bsc_master_killswitch') &&
			!fg('platform_moonjelly_bsc_web_storage_killswitch');
	}

	if (
		allowUncategorizedCookiesInOverrides !== undefined &&
		allowUncategorizedCookiesInOverridesGlobalState === undefined
	) {
		allowUncategorizedCookiesInOverridesGlobalState = allowUncategorizedCookiesInOverrides;
	}

	// All other state can only be initialized once and is defaulted if not passed on the first initialization
	if (hasInitialized) {
		return;
	}

	localConsentModeGlobalState = localConsentMode;

	analyticsEnabledGlobalState = analyticsEnabled;

	cookiesCacheLoader = new CacheLoader(CategoryStorageType.Cookies);
	localStorageCacheLoader = new CacheLoader(CategoryStorageType.LocalStorage);
	sessionStorageCacheLoader = new CacheLoader(CategoryStorageType.SessionStorage);

	if (fg('platform_moonjelly_bsc_debug_mode')) {
		packageDebugModeEnabledGlobalState = Boolean(Cookies.get('atl-bsc-debug-mode'));
	}
	hasInitialized = true;
};

export const testing_overrideGlobalState = ({
	loadCache: overrideLoadCache,
	product: overrideProduct,
}: OverrideBscGlobalStateParams) => {
	if (overrideLoadCache) {
		loadCache = overrideLoadCache;
	}
	if (overrideProduct) {
		productGlobalState = overrideProduct;
	}
};

export const clearGlobalState = () => {
	analyticsEnabledGlobalState = undefined;
	productGlobalState = undefined;
	webStorageControlsEnabledGlobalState = undefined;
	localConsentModeGlobalState = undefined;
	packageDebugModeEnabledGlobalState = undefined;
	allowUncategorizedCookiesInOverridesGlobalState = undefined;

	cookiesCacheLoader = undefined;
	localStorageCacheLoader = undefined;
	sessionStorageCacheLoader = undefined;
	hasInitialized = false;
};
