import React, { createContext, useMemo, useCallback, useRef } from 'react';
import { defineMessages, useIntl } from 'react-intl-next';

import { Text } from '@atlaskit/primitives';
import { useAnalyticsEvents } from '@atlaskit/analytics-next';
import type { AnalyticsEventPayload, UIAnalyticsEvent } from '@atlaskit/analytics-next';

import type { FlagsStateContainer } from '@confluence/flags';
import { withFlags } from '@confluence/flags';
import { fg } from '@confluence/feature-gating';
import { cfetch } from '@confluence/network';
import { AccessStatus, useSessionData } from '@confluence/session-data';
import { getApolloClient, markErrorAsHandled } from '@confluence/graphql';
import { getMonitoringClient } from '@confluence/monitoring';
import { Attribution } from '@confluence/error-boundary';
import { confluenceLocalStorageInstance as localStorage } from '@confluence/storage-manager';

import { GetUserAccessQuery } from './GetUserAccessQuery.graphql';
import type {
	GetUserAccessQuery as GetUserAccessQueryType,
	GetUserAccessQueryVariables,
} from './__types__/GetUserAccessQuery';
import { LicenseStatus } from './__types__/GetUserAccessQuery';

const INVITE_CAPABILITIES_URL = '/gateway/api/v3/invitations/capabilities';
const INVITE_CAPABILITIES_DIRECT_INVITE_MODE_KEY =
	'confluence_invite_capabilities_direct_invite_mode';

type XProductUserInviteContextType = {
	isEligibleXProductUserInvite?: () => Promise<boolean>;
	onXProductUserInviteClick?: (name: string, isSuccess: boolean) => void;
};

type XProductUserInviteProviderProps = {
	flags: FlagsStateContainer;
	children: React.ReactNode;
};

const i18n = defineMessages({
	inviteSuccessTitle: {
		id: 'fabric-media-support.mentions-provider.x-product-user-invite.success.title',
		description: 'Success title message when a user is invited to a product',
		defaultMessage: 'Successfully added',
	},
	inviteSuccessDescription: {
		id: 'fabric-media-support.mentions-provider.x-product-user-invite.success.description',
		description: 'Success message when a user is invited to a product',
		defaultMessage: 'We sent an email to <b>{name}</b> confirming you added them to Confluence.',
	},
});

export const XProductUserInviteContext = createContext<XProductUserInviteContextType>({});

const hasDirectInviteCapabilities = async (
	cloudId: string,
	createAnalyticsEvent: (payload: AnalyticsEventPayload) => UIAnalyticsEvent,
): Promise<boolean> => {
	try {
		const canUserAccessJira = await hasJiraSoftwareAccess(cloudId);
		let directInviteMode: string | undefined;
		let invitePendingApprovalMode: string | undefined;
		if (canUserAccessJira) {
			//Call invite capabilities endpoint only if directInviteMode value is not available from local storage
			directInviteMode = localStorage.getItem(INVITE_CAPABILITIES_DIRECT_INVITE_MODE_KEY);
			if (!directInviteMode) {
				const response = await cfetch(
					`${INVITE_CAPABILITIES_URL}?resource=ari:cloud:platform::site/${cloudId}`,
					{
						method: 'GET',
						headers: {
							ACCEPT: 'application/json',
						},
					},
				);

				if (!response.ok) {
					throw new Error(`Network response error.  HTTP status: ${response.status}`);
				}

				const data = await response.json();
				for (const item of data) {
					if (item?.resourceARI?.includes('confluence')) {
						directInviteMode = item?.directInvite?.mode;
						invitePendingApprovalMode = item?.invitePendingApproval?.mode;
						break;
					}
				}

				createAnalyticsEvent &&
					createAnalyticsEvent({
						type: 'sendOperationalEvent',
						data: {
							source: 'editor',
							action: 'fetched',
							actionSubject: 'inviteCapabilitiesForXProductUserInvite',
							attributes: {
								directInviteMode,
								invitePendingApprovalMode,
							},
						},
					}).fire();
				if (!!directInviteMode)
					localStorage.setItem(INVITE_CAPABILITIES_DIRECT_INVITE_MODE_KEY, directInviteMode, 86400);
			}
		}
		return directInviteMode === 'ANYONE';
	} catch (error) {
		const errorMessage = error instanceof Error ? error.message : String(error);
		createAnalyticsEvent &&
			createAnalyticsEvent({
				type: 'sendOperationalEvent',
				data: {
					source: 'editor',
					action: 'errored',
					actionSubject: 'inviteCapabilitiesForXProductUserInvite',
					attributes: {
						errorMessage,
					},
				},
			}).fire();
		return false;
	}
};

const hasJiraSoftwareAccess = async (cloudId: string): Promise<boolean> => {
	return getApolloClient()
		.query<GetUserAccessQueryType, GetUserAccessQueryVariables>({
			query: GetUserAccessQuery,
			variables: {
				resourceId: `ari:cloud:jira::site/${cloudId}`,
				permissionId: 'write',
			},
		})
		.then(({ data }) => {
			const hasJira = data?.tenantContext?.licensedProducts.some(
				(product) =>
					product?.productKey?.toLowerCase().includes('jira-software') &&
					product?.licenseStatus === LicenseStatus.ACTIVE,
			);
			return !!hasJira && data?.permitted;
		})
		.catch((error) => {
			getMonitoringClient().submitError(error, {
				attribution: Attribution.VIRALITY,
			});
			markErrorAsHandled(error);
			return false;
		});
};

export const XProductUserInviteProviderComponent = ({
	children,
	flags,
}: XProductUserInviteProviderProps) => {
	const { accessStatus, cloudId } = useSessionData();
	const { formatMessage } = useIntl();
	const promiseRef = useRef<Promise<boolean>>();
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const isEligibleXProductUserInvite = useCallback(() => {
		//check the eligibility criteria only once per SPA
		if (promiseRef.current) {
			return promiseRef.current;
		}

		promiseRef.current = (async () => {
			try {
				if (
					!fg('cc_virality_x_product_user_invite_kill_switch') ||
					!(
						accessStatus === AccessStatus.LICENSED_ADMIN_ACCESS ||
						accessStatus === AccessStatus.LICENSED_USE_ACCESS
					)
				) {
					return false;
				}
				return await hasDirectInviteCapabilities(cloudId, createAnalyticsEvent);
			} catch (e) {
				return false;
			}
		})();

		return promiseRef.current;
	}, [accessStatus, cloudId, createAnalyticsEvent]);

	const showSuccessFlag = useCallback(
		(name: string, isSuccess: boolean): void => {
			if (isSuccess) {
				void flags.showFlag({
					id: `x-product-user-invite-success`,
					title: formatMessage(i18n.inviteSuccessTitle),
					description: formatMessage(i18n.inviteSuccessDescription, {
						name,
						b: (chunks: React.ReactNode[]) => <Text weight="bold">{chunks}</Text>,
					}),
					type: 'success-circle',
				});
			}
		},
		[flags, formatMessage],
	);

	const value = useMemo(
		() => ({
			isEligibleXProductUserInvite,
			onXProductUserInviteClick: (name: string, isSuccess: boolean) => {
				showSuccessFlag(name, isSuccess);
			},
		}),
		[isEligibleXProductUserInvite, showSuccessFlag],
	);

	return (
		<XProductUserInviteContext.Provider value={value}>
			{children}
		</XProductUserInviteContext.Provider>
	);
};

export const XProductUserInviteProvider = withFlags(XProductUserInviteProviderComponent);
