import type { CLSData } from './cls';
import { getCLS } from './cls';
import { PerformanceObserverEntryTypes } from './const';
import { getTBT } from './tbt';
import { getLongTaskTiming } from './longTaskTiming';
import { EntriesBuffer } from './utils/buffer';
export { startLightHouseObservers } from './utils/observer';

export const delay = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

export const getLighthouseMetrics = async ({
	start,
	stop,
}: {
	start: number;
	stop: number;
}): Promise<{ [key: string]: number | CLSData['nodeCounts'] }> => {
	// PerformanceObserver is not deterministic in reporting entries, so a 500ms delay is needed
	// 500ms was tested in Jira and it captured enough data without causing a volume drop in reported events
	await delay(500);

	const tbt = getTBT(start, stop, EntriesBuffer[PerformanceObserverEntryTypes.LongTask]);

	// no round as CLS is usually 0-1
	const cls = getCLS(start, stop, EntriesBuffer[PerformanceObserverEntryTypes.LayoutShift]);

	const longTaskTiming = getLongTaskTiming(
		start,
		stop,
		EntriesBuffer[PerformanceObserverEntryTypes.LongTask],
	);

	const metrics = {
		'metric:tbt': Math.round(tbt.total), // sum of blocking time for long tasks within the 'start' and 'stop' time
		'metric:tbt:observed': Math.round(tbt.observed), // sum of blocking time for long tasks that might end beyond the 'stop' time
		'metric:cls': cls.score,
		'metric:clsNodeCounts': cls.nodeCounts,
		'metric:longTaskTiming': Math.round(longTaskTiming),
	} as { [key: string]: number | CLSData['nodeCounts'] };

	return metrics;
};
