export type PageLoadState = {
	uuid: string | null;
	pageId: string | null;
	routeName: string | null;
	transitionId: number;
	routingTimestamp: number;
	currentSavedTimestamp: number;
	isLivePage: boolean | null;
};

export const pageLoadState: PageLoadState = {
	uuid: null,
	pageId: '',
	routeName: '',
	transitionId: 0,
	routingTimestamp: 0,
	currentSavedTimestamp: 0,
	isLivePage: null,
};
