import { query } from '@confluence/query-preloader-tools';
import { PAGE_TREE_STATUSES } from '@confluence/page-tree/entry-points/pageTreeStatuses';
import { preloadPageTree } from '@confluence/page-tree/entry-points/preloadPageTree';

import { EmbedUnifiedQuery } from './graphql/EmbedUnifiedQuery.graphql';
import type {
	EmbedUnifiedQueryVariables,
	EmbedUnifiedQuery as EmbedUnifiedQueryType,
} from './graphql/__types__/EmbedUnifiedQuery';

export async function preloadEmbedData(spaceKey: string, contentId: string): Promise<any> {
	return Promise.all([
		preloadContentData(contentId, spaceKey),
		preloadContentTree(contentId, spaceKey),
	]);
}

async function preloadContentData(contentId: string, spaceKey: string): Promise<void> {
	await query<EmbedUnifiedQueryType, EmbedUnifiedQueryVariables>({
		query: EmbedUnifiedQuery,
		variables: {
			contentId,
			spaceKey,
		},
	});
}

async function preloadContentTree(contentId: string, spaceKey: string): Promise<void> {
	// eslint-disable-next-line check-react-ssr-usage/no-react-ssr
	if (process.env.REACT_SSR && contentId) {
		await preloadPageTree({
			spaceKey,
			statuses: PAGE_TREE_STATUSES,
			isSuperAdmin: false,
			contentId,
		});
	}
}
