// Ensures that the path structured correctly and adds provided parameters to the path
export const ensureURLPath = (path?: string, parameters?: Record<string, string>) => {
	let encodedPath = (path ?? '').split('/').map(encodeURIComponent).join('/');
	if (parameters && Object.keys(parameters).length > 0) {
		encodedPath += '?' + new URLSearchParams(parameters).toString();
	}

	return encodedPath;
};

// Returns a function that creates a URL path for a product. Used to add the product path prefix to the path
export const createMakeProductURLPath =
	(productPathPrefix: string) => (path?: string, parameters?: Record<string, string>) => {
		return ensureURLPath(path ? productPathPrefix + path : productPathPrefix, parameters);
	};
