import { useContext, useEffect } from 'react';

import { useAnalyticsEvents } from '@atlaskit/analytics-next';

import { fg } from '@confluence/feature-gating';
import { SPAViewContext } from '@confluence/spa-view-context';

// TAP trait name and values
const LIVE_EDIT_AB_TEST_TRAIT_NAME = 'confluence_live_page_opt_in';
const LIVE_EDIT_AB_TEST_OPTED_IN_VALUE = 'experiment_opted_in';
const LIVE_EDIT_AB_TEST_OPTED_OUT_VALUE = 'experiment_opted_out';
const LIVE_PAGES_BETA_TRAIT_NAME = 'confluence_live_pages_beta';
const LIVE_PAGES_BETA_DEFAULT_ON_VALUE = 'beta-default-on';
const LIVE_PAGES_BETA_DEFAULT_OFF_VALUE = 'beta-default-off';

export enum LivePageReleasePhase {
	NOT_ENABLED = 'not_enabled',
	CLOSED_BETA = 'closed_beta', // Tenant is in the first eap/closed beta cohort
	AB_TEST_OPTED_IN = 'ab_test_opted_in', // Tenant is in ab testing and has turned on live pages
	AB_TEST_OPTED_OUT = 'ab_test_opted_out', // Tenant is in ab testing and has turned off live pages
	OPEN_BETA_INITIAL = 'open_beta_initial', // Tenant is in open beta, but not yet opted into live pages.
	OPEN_BETA_DEFAULT_ON = 'open_beta_default_on', // Tenant is in open beta and have opted into the open beta experience. Live docs are available and turned on by default is most space settings.
	OPEN_BETA_DEFAULT_OFF = 'open_beta_default_off', // Tenant is in open beta, and have opted out of the open beta experience. Live docs are still available, but will no longer be turned on by default in space settings.
}

const RELEASE_PHASES_WITH_FEATURE_ENABLED = [
	LivePageReleasePhase.CLOSED_BETA,
	LivePageReleasePhase.AB_TEST_OPTED_IN,
	LivePageReleasePhase.OPEN_BETA_DEFAULT_ON,
	LivePageReleasePhase.OPEN_BETA_DEFAULT_OFF,
];

const RELEASE_PHASES_WITH_TWO_PAGE_TYPES_EXPERIENCE = [
	LivePageReleasePhase.OPEN_BETA_DEFAULT_ON,
	LivePageReleasePhase.OPEN_BETA_DEFAULT_OFF,
];

const useLivePageReleasePhase = (): LivePageReleasePhase => {
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const { experimentFeatures, loading: isSPAViewContextLoading } = useContext(SPAViewContext);

	useEffect(() => {
		if (isSPAViewContextLoading) {
			createAnalyticsEvent({
				type: 'sendOperationalEvent',
				data: {
					action: 'traitValueLoading',
					actionSubject: 'useLivePageReleasePhase',
					source: 'useLivePageReleasePhase',
				},
			}).fire();
		}
	}, [createAnalyticsEvent, isSPAViewContextLoading]);

	let openBetaTraitValue;
	let abTestTraitValue;
	try {
		openBetaTraitValue = JSON.parse(experimentFeatures)?.[LIVE_PAGES_BETA_TRAIT_NAME];
		abTestTraitValue = JSON.parse(experimentFeatures)?.[LIVE_EDIT_AB_TEST_TRAIT_NAME];
	} catch (e) {
		openBetaTraitValue = null;
		abTestTraitValue = null;
	}

	// Open Beta release phase
	/**
	 * `confluence_live_pages_open_beta` - Tells us that the tenant has been added to open beta. It allows the tenant to opt into the ope beta experience.
	 * This does not guarantee that the tenant has opted in. This is NOT a kill switch for the open beta experience. Once set to true, it cannot be set to false.
	 * `confluence_live_pages_open_beta_trait_opted_in` - Set to true when the tenant opts into the open beta experience. Will stay true even if the tenant opts out of the open beta experience.
	 * The meaning of this gate is that live docs are enabled for the tenant.
	 *
	 * The main trait value can have three states: null, beta-default-on, beta-default-off.
	 * 	- `null`: The tenant is not in open beta or has not yet opted into the open beta experience.
	 * 	- `beta-default-on`: The tenant has opted into the open beta experience and live docs are available
	 * 	- `beta-default-off`: The tenant has previously opted in and then opted out of the open beta experience.
	 * Live docs are still available but space settings have live docs turned off by default. User can still turn live docs back on in space settings.
	 *
	 * `confluence_live_pages_open_beta_trait_opted_in` is an abstraction of the trait value where it is true when the trait value is either `beta-default-on` or `beta-default-off`.
	 *
	 * We check both the fg and trait value to ensure the phase returned is accurate. Sometimes the feature gate fails to get the correct value.
	 * Currently, customers do not have the ability to opt out or completely opt out of the open beta experience once they have opted in.
	 * https://hello.atlassian.net/wiki/spaces/PCG/pages/4415395121/Live+Pages+Open+Beta+Feature+Gate+Approach
	 */
	if (
		openBetaTraitValue === LIVE_PAGES_BETA_DEFAULT_ON_VALUE ||
		fg('confluence_live_pages_open_beta_trait_opted_in') // we can assume that the tenant has opted in if the gate is true, opt out is not possible at the moment.
	) {
		return LivePageReleasePhase.OPEN_BETA_DEFAULT_ON;
	} else if (openBetaTraitValue === LIVE_PAGES_BETA_DEFAULT_OFF_VALUE) {
		return LivePageReleasePhase.OPEN_BETA_DEFAULT_OFF;
	} else if (fg('confluence_live_pages_open_beta')) {
		return LivePageReleasePhase.OPEN_BETA_INITIAL;
	}

	// AB testing release phase
	/**
	 * Evaluating both feature gate and trait value here because:
	 *
	 * Feature gate value is available faster but may not always have the most up to date value.
	 * There is some latency between updating the tenant trait and then updating statsig feature gate.
	 * Checking the trait value will get the most recent value before statsig feature gate does so if the tenant opts in recently we will get the correct value quicker.
	 *
	 * For AB testing, frontend care more about getting the correct opt in status because sites are opted in through Landing process,
	 * 	where opt out is through manual process and user is informed by the UI that manual opt in/ opt out status change may take time to apply.
	 *
	 */
	if (
		abTestTraitValue === LIVE_EDIT_AB_TEST_OPTED_IN_VALUE ||
		fg('confluence_live_pages_ab_test_opted_in')
	) {
		return LivePageReleasePhase.AB_TEST_OPTED_IN;
	}

	if (abTestTraitValue === LIVE_EDIT_AB_TEST_OPTED_OUT_VALUE) {
		return LivePageReleasePhase.AB_TEST_OPTED_OUT;
	}

	// Closed Beta release phase
	if (fg('confluence_live_pages')) {
		return LivePageReleasePhase.CLOSED_BETA;
	}

	// Default
	return LivePageReleasePhase.NOT_ENABLED;
};

/**
 * Returns if the current tenant has the ability to use Live Pages.
 * This takes into account multiple releases that contribute to the feature's availability.
 */
export const useIsLivePagesFeatureEnabled = (): {
	isLivePagesFeatureEnabled: boolean;
	isTwoPageTypesExperience: boolean;
	livePageReleasePhase: LivePageReleasePhase;
} => {
	const phase = useLivePageReleasePhase();
	return {
		isLivePagesFeatureEnabled: RELEASE_PHASES_WITH_FEATURE_ENABLED.includes(phase),
		isTwoPageTypesExperience: RELEASE_PHASES_WITH_TWO_PAGE_TYPES_EXPERIENCE.includes(phase),
		livePageReleasePhase: phase,
	};
};
