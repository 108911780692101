import { query } from '@confluence/query-preloader-tools';

import { ThemeQuery } from './ThemeQuery.graphql';

export function preloadPageLayout() {
	const pageLayoutPromises = [
		query({
			query: ThemeQuery,
		}),
	];

	return Promise.all(pageLayoutPromises);
}
