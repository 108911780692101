import type { FC } from 'react';
import React, { useEffect, useState, useCallback } from 'react';
import { IntlProvider } from 'react-intl';
import { IntlProvider as IntlNextProvider } from 'react-intl-next';

import { getTranslation, loadTranslation, DEFAULT_LOCALE } from './translation';

/**
 * NOTE: This file is globally mocked in next/tools/config/jestGlobalMocks.js
 */
export const I18nProvider: FC<{ children?: React.ReactNode }> = ({ children }) => {
	const [forcedLocale, setForcedLocale] = useState(undefined);

	const { locale: unforcedLoadle, messages } = getTranslation();
	const locale = forcedLocale || unforcedLoadle;

	useEffect(() => {
		document.documentElement.lang = locale;
	}, [locale]);

	// Allow a quick dev override for locale
	if (process.env.NODE_ENV !== 'production') {
		// eslint-disable-next-line react-hooks/rules-of-hooks
		const changeLocaleListener = useCallback(
			(event: any) => {
				if (typeof event.data !== 'object') return;
				const { eventType, locale } = event.data;
				if (eventType === 'force_locale') {
					void loadTranslation(undefined, locale).then(() => setForcedLocale(locale));
				}
			},
			[setForcedLocale],
		);

		// eslint-disable-next-line react-hooks/rules-of-hooks
		useEffect(() => {
			window.addEventListener('message', changeLocaleListener);
			return () => window.removeEventListener('message', changeLocaleListener);
		}, [changeLocaleListener]);
	}

	return (
		<IntlProvider
			key={`intl-provider-${locale}-v5`}
			data-test-language={locale}
			locale={locale}
			defaultLocale={DEFAULT_LOCALE}
			messages={messages}
			textComponent="span"
		>
			<IntlNextProvider
				key={`intl-provider-${locale}-v5`}
				data-test-language={locale}
				locale={locale}
				defaultLocale={DEFAULT_LOCALE}
				messages={messages}
				textComponent="span"
				onError={(err) => {
					// Do not spam local devloop with missing translation errors
					// See: https://github.com/formatjs/formatjs/issues/465#issuecomment-643887472
					// Note the default error handler: https://github.com/formatjs/formatjs/blob/d0b80fd9a814d1427b73577aebb7ccdb80b9ae94/packages/intl/src/utils.ts#L43
					if (process.env.NODE_ENV !== 'production' && err.code !== 'MISSING_TRANSLATION') {
						// eslint-disable-next-line no-console
						console.error(err);
					}
				}}
			>
				{children}
			</IntlNextProvider>
		</IntlProvider>
	);
};
