/**
 * @jsxRuntime classic
 * @jsx jsx
 */
import React, { lazy, useContext } from 'react';
import { css, jsx } from '@compiled/react';
import { RendererContext } from '../../context';
import { token } from '@atlaskit/tokens';
import MediaServicesNoImageIcon from '@atlaskit/icon/glyph/media-services/no-image';
import ErrorIcon from '@atlaskit/icon/core/migration/error';
import ShortcutIcon from '@atlaskit/icon/core/migration/link-external--shortcut';
import { Text, Box } from '@atlaskit/primitives';

const AKButton = lazy(
	() =>
		import(
			/* webpackChunkName: '@atlaskit-internal_.button' */
			'@atlaskit/button'
		),
);

type EgressErrorMessageProps = {
	url: string;
};

type LinkWithIconProps = {
	href: string;
	text: string;
};

const LinkWithIcon = ({ href, text }: LinkWithIconProps) => {
	return (
		<AKButton
			iconAfter={<ShortcutIcon color="currentColor" label="" />}
			appearance="link"
			href={href}
			target="_blank"
			spacing="none"
		>
			{text}
		</AKButton>
	);
};

export const EgressErrorMessage = ({ url }: EgressErrorMessageProps) => {
	const { forgeEnvironment } = useContext(RendererContext);
	const dataUriRegex = /^data/;
	const isDataUri = dataUriRegex.test(url);

	if (forgeEnvironment === 'DEVELOPMENT') {
		return (
			<div
				// eslint-disable-next-line @atlaskit/design-system/consistent-css-prop-usage, @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
				css={css({
					display: 'flex',
					paddingTop: token('space.150', '12px'),
					paddingRight: token('space.150', '12px'),
					paddingBottom: token('space.150', '12px'),
					paddingLeft: token('space.150', '12px'),
				})}
				data-testid="egress-error-message"
			>
				<div
					// eslint-disable-next-line @atlaskit/design-system/consistent-css-prop-usage, @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
					css={css({
						alignSelf: 'center',
					})}
				>
					{/* TODO: New icon not available */}
					{/* eslint-disable-next-line @atlaskit/design-system/no-legacy-icons -- https://product-fabric.atlassian.net/browse/DSP-21456 */}
					<MediaServicesNoImageIcon label="" size="large" />
				</div>
				<div
					// eslint-disable-next-line @atlaskit/design-system/consistent-css-prop-usage, @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
					css={css({
						display: 'flex',
						paddingLeft: token('space.150', '12px'),
						color: token('color.text.accent.red', '#AE2A19'),
						overflow: 'hidden',
					})}
				>
					<ErrorIcon color="currentColor" spacing="spacious" label="" />
					<Box paddingInlineStart="space.050">
						<Text as="p" color="color.text.danger">
							{isDataUri ? (
								<React.Fragment>
									Please use the <b>data:image</b> scheme for an image source.
								</React.Fragment>
							) : (
								<React.Fragment>
									Egress permissions for <LinkWithIcon href={url} text={url} /> missing in the
									manifest. For more information{' '}
									<LinkWithIcon
										href="https://developer.atlassian.com/platform/forge/manifest-reference/permissions/#images"
										text="see reference docs"
									/>
									.
								</React.Fragment>
							)}
						</Text>
					</Box>
				</div>
			</div>
		);
	}

	return (
		<div title={`Unable to display due to an image link issue.`}>
			{/* TODO: New icon not available */}
			{/* eslint-disable-next-line @atlaskit/design-system/no-legacy-icons -- https://product-fabric.atlassian.net/browse/DSP-21456 */}
			<MediaServicesNoImageIcon label="" />
		</div>
	);
};
