import React from 'react';
import { styled } from '@compiled/react';
import type { MessageDescriptor } from 'react-intl-next';
import { FormattedMessage, useIntl } from 'react-intl-next';

import InformationIcon from '@atlaskit/icon/utility/information';
import { token } from '@atlaskit/tokens';
import Tooltip from '@atlaskit/tooltip';
import { Box, xcss } from '@atlaskit/primitives';
import Button from '@atlaskit/button';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const HeaderWrapper = styled.div<{ center: boolean }>({
	display: 'flex',
	flexDirection: 'column',
	justifyContent: 'flex-end',
	rowGap: token('space.025'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	alignItems: ({ center }) => (center ? 'center' : 'flex-start'),
	textAlign: 'center',
	minWidth: token('space.400'),
	height: 'auto',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const GroupNameWrapper = styled.div<{
	setMaxWidth: boolean;
	setWidth: boolean;
}>({
	display: 'flex',
	flexDirection: 'column',
	justifyContent: 'center',
	overflowX: 'visible',
	whiteSpace: 'nowrap',
	font: token('font.heading.xxsmall'),
	minHeight: '34px',
	textAlign: 'center',
	color: token('color.text'),
	// In the case where we set the width, we need to remove the maxWidth to ensure
	// that the full width is used
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	maxWidth: ({ setWidth }) => (setWidth ? 'none' : token('space.600')),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	width: ({ setWidth }) => (setWidth ? '170px' : 'initial'),
});

const largeGroupNameStyle = xcss({
	whiteSpace: 'normal',
	wordBreak: 'keep-all',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ColumnNameWrapper = styled.div({
	display: 'flex',
	whiteSpace: 'nowrap',
	font: token('font.body.small'),
	color: token('color.text.subtle'),
	alignSelf: 'center',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const IconWrapper = styled.div({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles -- Ignored via go/DSP-18766
	height: `${token('space.200')} !important`,
	borderRadius: '50%',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	button: {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles -- Ignored via go/DSP-18766
		height: `${token('space.200')} !important`,
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles -- Ignored via go/DSP-18766
		width: `${token('space.200')} !important`,
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	':hover': {
		backgroundColor: token('color.background.accent.blue.subtlest'),
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
		span: {
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles -- Ignored via go/DSP-18766
			'--icon-primary-color': `${token('color.icon.accent.blue')} !important`,
		},
	},
	'vertical-align': 'middle',
});

type HeaderCellType = {
	groupName: string;
	columnName: string;
	testKey?: string;
	showGroupName?: boolean;
	tooltipContent?: MessageDescriptor;
	tooltipIconLabel?: MessageDescriptor;
	centerGroupName?: boolean;
	setMaxWidth?: boolean;
	setWidth?: boolean;
};

export const HeaderCell = ({
	groupName,
	columnName,
	showGroupName = false,
	testKey,
	tooltipContent,
	tooltipIconLabel,
	centerGroupName = false,
	setMaxWidth = false,
	setWidth = false,
}: HeaderCellType) => {
	const { formatMessage } = useIntl();

	return (
		<HeaderWrapper center={centerGroupName}>
			<GroupNameWrapper
				data-testid={testKey ? `space-table-header-${testKey}` : undefined}
				aria-label={`${groupName}`}
				setMaxWidth={setMaxWidth}
				setWidth={setWidth}
			>
				{showGroupName ? (
					<Box xcss={[setWidth ? largeGroupNameStyle : false]}>{groupName}</Box>
				) : (
					<Box aria-label={`${groupName}`} />
				)}
			</GroupNameWrapper>
			<ColumnNameWrapper key={groupName + columnName} aria-label={`${columnName}`}>
				{columnName}
				{tooltipContent && tooltipIconLabel && (
					<Tooltip content={<FormattedMessage {...tooltipContent} />}>
						{(tooltipProps) => (
							<IconWrapper>
								<Button
									{...tooltipProps}
									appearance="subtle-link"
									iconBefore={
										<InformationIcon
											label={formatMessage(tooltipIconLabel)}
											color={token('color.icon')}
										/>
									}
								/>
							</IconWrapper>
						)}
					</Tooltip>
				)}
			</ColumnNameWrapper>
		</HeaderWrapper>
	);
};
