import gql from 'graphql-tag';

export const ShareAndRestrictButtonQuery = gql`
	query ShareAndRestrictButtonQuery($contentId: ID!, $isContentTypePublicLinkEligible: Boolean!) {
		singleContent(id: $contentId) {
			id
			hasInheritedRestrictions
			hasRestrictions
			hasViewRestrictions
			title
		}
		publicLinkPage(pageId: $contentId) @include(if: $isContentTypePublicLinkEligible) {
			pageId
			pageStatus
		}
	}
`;
