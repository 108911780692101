import type { Option } from '@atlaskit/editor-common/extensions';

import { cfetch } from '@confluence/network';
import { fg } from '@confluence/feature-gating';

import { dedupeResolver } from './dedupe-resolver';

const search = async (query: string) => {
	const type = `page,blogpost${fg('confluence_child_pages_macro_enable_folder_search') ? ',folder' : ''}`;

	return cfetch(
		`/wiki/rest/prototype/1/search/name.json?max-results=10&pageSize=10&type=${type}&query=${encodeURIComponent(
			query,
		)}`,
		{
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
			},
		},
	)
		.then((result) => result.json())
		.then((response) => {
			// two groups, one for pages and another for blogposts
			const res: Option[] = [];

			response.group.forEach((groupResults) =>
				res.push(
					...groupResults.result.map((result) => ({
						label: result.title,
						value: `${result.space.key}:${result.title}`,
						description: result.space.title,
					})),
				),
			);

			return res;
		});
};

export const getSpaceKeyAndPageTitleContentResolver = () =>
	dedupeResolver(async (searchTerm?: string, selectedValue?) => {
		const searchers: Array<Promise<Option[]>> = [];

		if (searchTerm) {
			searchers.push(search(searchTerm));
		}

		if (selectedValue) {
			if (typeof selectedValue === 'string' || Array.isArray(selectedValue)) {
				if (typeof selectedValue === 'string') {
					selectedValue = selectedValue.split(',');
				}

				selectedValue.forEach((selectedValue) => {
					const splitId = selectedValue.split(':');
					const pageTitle = splitId[splitId.length - 1];

					searchers.push(search(pageTitle));
				});
			}
		}

		return (await Promise.all(searchers)).flat();
	});
