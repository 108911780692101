import { isFedRamp } from '@atlassian/atl-context';

import { isMobileUserAgent, shouldShowMobileWeb } from '@confluence/mobile-detection';
import { AccessStatus } from '@confluence/session-data';

type LoomDisabledProps = {
	accessStatus: AccessStatus;
	isLoggedIn: boolean;
};

/**
 * Checking for if Loom is disabled.
 * Note: This file is a non-hook version of the function useIsLoomDisabled.ts so that it can be run during SSR preloading
 * It will eventually replace the hook after SSR rollout
 */
export const getIsLoomDisabled = ({ accessStatus, isLoggedIn }: LoomDisabledProps) => {
	const isInFedRamp = isFedRamp();
	const isMobile = isMobileUserAgent() || shouldShowMobileWeb();

	const isLicensed =
		accessStatus == AccessStatus.LICENSED_ADMIN_ACCESS ||
		accessStatus == AccessStatus.LICENSED_USE_ACCESS;

	if (!isLoggedIn || !isLicensed) {
		return true;
	}

	if (isInFedRamp) {
		return true;
	}

	// don't enable entry points if user is on a mobile device or viewing mobile web
	if (isMobile) {
		return true;
	}

	return false;
};
