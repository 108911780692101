import React from 'react';
import type { RenderFn } from '@atlassian/forge-ui-types';
import { withMacroStyleWrapper } from '../macroStyleWrapper';

const PlatformPieChart = React.lazy(() =>
	// eslint-disable-next-line import/dynamic-import-chunkname
	import(
		/* webpackChunkName: '@forge-ui_piechart' */
		'@atlassian/viz-platform-charts/charts'
	).then((module) => ({ default: module.PieChart })),
);

const Title = React.lazy(() =>
	// eslint-disable-next-line import/dynamic-import-chunkname
	import(
		/* webpackChunkName: '@forge-ui_piechart' */
		'@atlassian/viz-platform-charts/charts'
	).then((module) => ({ default: module.PieChart.elements.Title })),
);

const SubTitle = React.lazy(() =>
	// eslint-disable-next-line import/dynamic-import-chunkname
	import(
		/* webpackChunkName: '@forge-ui_piechart' */
		'@atlassian/viz-platform-charts/charts'
	).then((module) => ({ default: module.PieChart.elements.SubTitle })),
);

const MarkLabels = React.lazy(() =>
	// eslint-disable-next-line import/dynamic-import-chunkname
	import(
		/* webpackChunkName: '@forge-ui_piechart' */
		'@atlassian/viz-platform-charts/charts'
	).then((module) => ({ default: module.PieChart.elements.MarkLabels })),
);

type PlatformPieChartProps = React.ComponentProps<typeof PlatformPieChart>;

/**
 * Added props:
 * - showMarkLabels - show/hide the labels on top of each slice of the pie, maps to the MarkLabels primitive
 *
 * Excluded props:
 * - children - we will not be supporting primitives/elements to compose the chart
 * - radius - [TO REVIEW] new prop added in the new version, needs to investigate feasibility of exposing it
 *
 * Limited props:
 * - xAccessor - only allow non function types
 * - yAccessor - only allow non function types
 * - colorAccessor - only allow non function types
 */
export type PieChartProps = Pick<PlatformPieChartProps, 'width' | 'height' | 'data'> & {
	colorAccessor: number | string;
	labelAccessor: number | string;
	valueAccessor: number | string;
	title?: string;
	subtitle?: string;
	showMarkLabels?: boolean;
};

const BasePieChart = (props: Parameters<RenderFn>[0]) => {
	const {
		width,
		height,
		data,
		colorAccessor,
		labelAccessor,
		valueAccessor,
		title,
		subtitle,
		showMarkLabels,
	} = props.forgeDoc.props as PieChartProps;

	return (
		<PlatformPieChart
			width={width}
			height={height ? height : 400}
			data={data}
			colorAccessor={colorAccessor}
			valueAccessor={valueAccessor}
			labelAccessor={labelAccessor}
		>
			<MarkLabels hide={!showMarkLabels} />
			{title && <Title>{title}</Title>}
			{subtitle && <SubTitle>{subtitle}</SubTitle>}
		</PlatformPieChart>
	);
};

export const PieChart = withMacroStyleWrapper(BasePieChart);
