import FeatureGates from '@atlaskit/feature-gate-js-client';
import type {
	ChoreographerFactory,
	MaybeChoreographedComponentProps,
} from '@atlassian/ipm-choreographer';
import { useFirePostOfficeAnalyticsEvents } from '@post-office/analytics';
import { useChoreographerContext } from '@post-office/placement-contexts';
import { type PropsWithChildren, useEffect } from 'react';

import { type MessageCategory } from '../types';

const ChoreographedComponent = ({
	children,
	messageId,
	choreographerFactory,
}: PropsWithChildren<{ messageId: string; choreographerFactory: ChoreographerFactory }>) => {
	const { useChoreographedRender } = choreographerFactory;
	const shouldRender = useChoreographedRender({ messageId });

	if (shouldRender) {
		return <>{children}</>;
	}

	return null;
};

export const ChoreographerWrapper = ({
	children,
	messageId,
	messageTemplateId,
	messageCategory,
}: PropsWithChildren<{
	messageId: string;
	messageTemplateId: string;
	messageCategory?: MessageCategory;
}> &
	MaybeChoreographedComponentProps) => {
	const { factory, choreographerEnabled } = useChoreographerContext();
	const { fireAnalyticsEvent } = useFirePostOfficeAnalyticsEvents();

	useEffect(() => {
		const isTrackingChoreographedPostOfficeMessagesEnabled = FeatureGates.checkGate(
			'enable_tracking_choreographed_post_office_messages',
		);

		if (isTrackingChoreographedPostOfficeMessagesEnabled) {
			fireAnalyticsEvent(
				{
					eventType: 'operational',
					action: 'maybeChoreographed',
					actionSubject: 'postOfficeMessage',
				},
				{
					messageTemplateId,
					messageCategory,
				},
			);
		}
	}, [fireAnalyticsEvent]);

	const isChoreographedPostOfficeMessagesEnabled = FeatureGates.checkGate(
		'enable_choreographed_post_office_messages',
	);

	if (choreographerEnabled && isChoreographedPostOfficeMessagesEnabled) {
		if (!factory) {
			return null;
		}

		const { shouldBeChoreographed } = factory;

		const messageType = messageCategory
			? (messageCategory.toLowerCase() as MaybeChoreographedComponentProps['messageType'])
			: undefined;
		if (
			shouldBeChoreographed({
				messageType: messageType,
			})
		) {
			return (
				<ChoreographedComponent choreographerFactory={factory} messageId={messageId}>
					{children}
				</ChoreographedComponent>
			);
		}
	}
	return <>{children}</>;
};
