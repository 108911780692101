import { type SubscribePayload, createClient, type Client, type Sink } from 'graphql-ws';

export type Subscription = {
	closed: boolean;
	unsubscribe(): void;
};

const getAGGWebsocketURI = (): string => {
	if (process.env.NODE_ENV !== 'production') {
		// In dev mode this allows the local dev proxy to use the WebSocket host
		// that matches the backend origin host. The __backendOriginHost is only
		// available with local dev server.
		const host = (window as any).__backendOriginHost || window.location.host;

		return `wss://${host}/gateway/api/graphql/subscriptions`;
	}
	return `wss://${window.location.host}/gateway/api/graphql/subscriptions`;
};

const createAGGSubscriptionClient = (): Client => {
	return createClient({
		url: getAGGWebsocketURI(),
		retryAttempts: 2,
		lazy: true,
	});
};

const getAGGSubscriptionClient = (): Client => {
	let client = (window as any).__FORGE_AGG_SUBSCRIPTION_CLIENT__;
	if (!client) {
		client = createAGGSubscriptionClient();
		(window as any).__FORGE_AGG_SUBSCRIPTION_CLIENT__ = client;
	}
	return client;
};

class BasicSubscription implements Subscription {
	public closed: boolean;
	private baseUnsubscribe: () => void;

	constructor(baseUnsubscribe: () => void) {
		this.closed = false;
		this.baseUnsubscribe = baseUnsubscribe;
	}

	public unsubscribe() {
		if (!this.closed) {
			this.baseUnsubscribe();
			this.closed = true;
		}
	}
}

export const subscribe = (payload: SubscribePayload, sink: Sink, client?: Client): Subscription => {
	if (!client) {
		client = getAGGSubscriptionClient();
	}
	const unsubscribe = client.subscribe(payload, sink);
	return new BasicSubscription(unsubscribe);
};
