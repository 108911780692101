import { useRef, type ReactNode, useEffect } from 'react';
import { emit } from '../bridge';
import type { ViewContext, IframeProps } from './types';
import stringify from 'fast-json-stable-stringify';
import React from 'react';
import { useCallbackOne } from 'use-memo-one';
import { useForgeUiAnalyticsEvent } from '../../analytics/useForgeUiAnalyticsEvent';

/*
 * The main purpose of this component is to emit a FORGE_CORE_MACRO_CONFIG_CHANGED event when the macro config changes,
 * so that the macro config app can be updated with the new config properly through useConfig hook.
 *
 * Importantly, in the legacy implementation, the children are remounted when the config change, as macro config is considered
 * as part of the product context. This was necessary to ensure that the macro config app was updated with the new config properly.
 * However, it is also an inefficient way to handle config changes, as it causes the entire app to remount and reload.
 *
 * There are few insights with above:
 * - the product context should never change in practice, as it contains mostly bootstrap information that should be static
 *   for the lifetime of the app
 * - there fore macro config should not be part of the product context, as it can change during the lifetime of the app
 *
 * In the new implementation (which targets to @forge/react version 11 or above), the app iframe are not remounted when the config changes.
 * Instead, the FORGE_CORE_MACRO_CONFIG_CHANGED event is emitted, and the macro config app is updated with the new config properly
 * through useConfig hook. This is a more correct and efficient way to handle config changes.
 */
export const ContextChangeObserverWrapper = ({
	context,
	children,
	getCurrentForgeReactMajorVersion,
}: {
	context: ViewContext;
	children: ReactNode;
	getCurrentForgeReactMajorVersion: NonNullable<IframeProps['getCurrentForgeReactMajorVersion']>;
}) => {
	const forgeReactMajorVersion = useRef<number | null>(null);
	const initialConfigKey = useRef<string | null>(null);

	const { config, ...extensionWithoutConfig } = context.extension ?? {};
	const currentConfigKey = stringify(config);
	const baseContextKey = stringify({
		...context,
		extension: extensionWithoutConfig,
	});
	const trackWrappedComponentMounted = useWrappedComponentMountTracker();

	useEffect(() => {
		emit('FORGE_CORE_MACRO_CONFIG_CHANGED', { config });
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentConfigKey]);

	if (forgeReactMajorVersion.current === null) {
		forgeReactMajorVersion.current = getCurrentForgeReactMajorVersion();
	}
	if (initialConfigKey.current === null) {
		initialConfigKey.current = currentConfigKey;
	}

	// for existing apps we need to ensure backwards compatibility
	const shouldContextKeyChangeOnConfigChange =
		forgeReactMajorVersion.current === null || forgeReactMajorVersion.current < 11;

	// if the app is using the new version of Forge react, we always uses the initial config key
	const key =
		baseContextKey +
		(shouldContextKeyChangeOnConfigChange ? currentConfigKey : initialConfigKey.current);

	return (
		<WrappedComponent key={key} onMounted={trackWrappedComponentMounted}>
			{children}
		</WrappedComponent>
	);
};

const useWrappedComponentMountTracker = () => {
	const { trackExtensionRemounted } = useForgeUiAnalyticsEvent();
	const mountedCount = useRef(0);
	const trackWrappedComponentMounted = useCallbackOne(() => {
		mountedCount.current += 1;
		if (mountedCount.current > 1) {
			trackExtensionRemounted({
				mountCount: mountedCount.current,
			});
		}
	}, [mountedCount, trackExtensionRemounted]);

	return trackWrappedComponentMounted;
};

const WrappedComponent = ({
	children,
	onMounted,
}: {
	key: string;
	children: ReactNode;
	onMounted: () => void;
}) => {
	useEffect(() => {
		onMounted();
	}, [onMounted]);

	return <>{children}</>;
};
