import type { ThemeState } from '@atlaskit/tokens';

export const getDefaultTheme = (): Partial<ThemeState> => {
	return {
		dark: 'dark',
		light: 'light',
		colorMode: 'light',
		typography: 'typography-refreshed',
	};
};
