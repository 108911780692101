import { query } from '@confluence/query-preloader-tools';
import { fg } from '@confluence/feature-gating';

import { ReadTimeSmartsQuery } from './ReadTimeSmartsQuery.graphql';

export function preloadReadTime(contentId) {
	if (fg('confluence_frontend_skip_smarts_read_time')) {
		return;
	}

	return query({
		query: ReadTimeSmartsQuery,
		variables: {
			id: contentId,
		},
	});
}
