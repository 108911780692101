import { type ComponentPropsConfig } from '../../../utils/codegen';

export const componentPropsConfig: ComponentPropsConfig = {
	appearance: true,
	delay: true,
	interactionName: false,
	label: true,
	size: true,
	testId: true,
};

/**
 * THIS SECTION WAS CREATED VIA CODEGEN DO NOT MODIFY {@see http://go/af-codegen}
 *
 * This component was generated against an existing platform component.
 * If you are seeing lint errors in this file, it means that this file's dependencies have been modified and the component will need to be regenerated.
 *
 * Please view https://hello.atlassian.net/wiki/spaces/ECO/pages/3358130330/How+to+Regenerate+UI+Kit+Components for more information on how to proceed.
 *
 * For further questions, please ping the #help-forge-ui channel.
 *
 * @codegen <<SignedSource::4e58af27aefc9353755612a05841f4ea>>
 * @codegenId #spinner
 * @codegenCommand yarn workspace @atlassian/forge-ui codegen spinner
 * @codegenDependency ../../../../../../../design-system/spinner/src/types.tsx <<SignedSource::4646753a373ab6666e4ae2b6b9bf089c>>
 * @codegenDependency ../../../../../../../design-system/spinner/src/constants.tsx <<SignedSource::e5cd0434f53c18c4ec463caecc3ef6c3>>
 * @codegenDependency ../../../../../../../design-system/spinner/src/spinner.tsx <<SignedSource::dd7dac31397ce2c91d589636903a952e>>
 */
/* eslint @repo/internal/codegen/signed-source-integrity: "warn" */
import React from 'react';
import type { RenderFn } from '@atlassian/forge-ui-types';
import PlatformSpinner from '@atlaskit/spinner';

type PlatformSpinnerProps = React.ComponentProps<typeof PlatformSpinner>;

export type SpinnerProps = Pick<
  PlatformSpinnerProps,
  'appearance' | 'delay' | 'label' | 'size' | 'testId'
>;

/**
 * A spinner is an animated spinning icon that lets users know content is being loaded.
 */
export const Spinner = (props: Parameters<RenderFn>[0]) => {
  const {
    appearance,
    delay,
    label,
    size,
    testId,
  } = props.forgeDoc.props as SpinnerProps;
  return (
    <PlatformSpinner
      appearance={appearance}
      delay={delay}
      label={label}
      size={size}
      testId={testId}
    />
  );
};
/**
 * @codegenEnd
 */
