import gql from 'graphql-tag';

export const ThemeQuery = gql`
	query ThemeQuery {
		lookAndFeel {
			custom {
				horizontalHeader {
					backgroundColor
					primaryNavigation {
						highlightColor
					}
				}
			}
		}
	}
`;
