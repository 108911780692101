/* eslint-disable import/dynamic-import-chunkname */
import type { LocalizationMessages } from './types';

/**
 * The webpackChunkName "translations.foo" is important. Do not change without updating following files:
 * - packages/confluence-frontend-server/webpack.config.js
 * - packages/confluence-frontend-server/templates/generator.js
 */

/**
 * The strings from the case statements below should appear in the `locales` array in
 * next/packages/fixturebook/src/components/Explorer.js - be sure to keep them synced.
 */

export const getMessages = (
	locale: string,
): Promise<{ default: LocalizationMessages } | LocalizationMessages>[] => {
	// eslint-disable-next-line check-react-ssr-usage/no-react-ssr
	if (!process.env.REACT_SSR) {
		// en-ZZ is a special locale used for locate translation strings. Real user won't see it.
		// Split the condition statements so webpack can remove the whole thing when compiling for SSR
		// SSR doesn't have splits this file will make the ssr-app significantly bigger
		if (locale === 'en-ZZ') {
			return [
				import(/* webpackChunkName: "translations.en-ZZ" */ '@atlaskit/editor-common/src/i18n/en_ZZ'),
				import(/* webpackChunkName: "translations.en-ZZ" */ '@atlaskit/editor-core/src/i18n/en_ZZ'),
				import(/* webpackChunkName: "translations.en-ZZ" */ '@atlaskit/emoji/src/i18n/en_ZZ'),
				import(/* webpackChunkName: "translations.en-ZZ" */ '@atlaskit/reactions/src/i18n/en_ZZ'),
				import(/* webpackChunkName: "translations.en-ZZ" */ '@atlaskit/mention/src/i18n/en_ZZ'),
				import(/* webpackChunkName: "translations.en-ZZ" */ '@atlaskit/renderer/src/i18n/en_ZZ'),
				import(/* webpackChunkName: "translations.en-ZZ" */ '@atlaskit/smart-card/src/i18n/en_ZZ'),
				import(/* webpackChunkName: "translations.en-ZZ" */ '@atlaskit/link-picker/src/i18n/en_ZZ'),
				import(/* webpackChunkName: "translations.en-ZZ" */ '@atlassian/charts/src/i18n/en_ZZ'),
				import(/* webpackChunkName: "translations.en-ZZ" */ '@atlassian/analytics-byline/src/i18n/en_ZZ'),
				import(/* webpackChunkName: "translations.en-ZZ" */ '@atlassian/analytics-chart/src/i18n/en_ZZ'),
				import(/* webpackChunkName: "translations.en-ZZ" */ '@atlassian/conversation-assistant/src/i18n/en_ZZ'),
				import(/* webpackChunkName: "translations.en-ZZ" */ '@atlassian/conversation-assistant-agent/src/i18n/en_ZZ'),
				import(/* webpackChunkName: "translations.en-ZZ" */ '@atlassian/conversation-assistant-ui-components/src/i18n/en_ZZ'),
				import(/* webpackChunkName: "translations.en-ZZ" */ '@atlassian/pill-selector/src/i18n/en_ZZ'),
				import(/* webpackChunkName: "translations.en-ZZ" */ '@atlassian/generative-ai-modal/src/i18n/en_ZZ'),
				import(/* webpackChunkName: "translations.en-ZZ" */ '@atlassian/forge-ui/src/i18n/en_ZZ'),
				import(/* webpackChunkName: "translations.en-ZZ" */ '@atlassian/editor-extension-link-create/src/i18n/en_ZZ'),
				import(/* webpackChunkName: "translations.en-ZZ" */ '@atlassian/people-menu/src/i18n/en_ZZ'),
				import(/* webpackChunkName: "translations.en-ZZ" */ '@atlassian/dynamic-cards-search-page/src/i18n/en_ZZ'),
				import(/* webpackChunkName: "translations.en-ZZ" */ '@atlassiansox/cross-flow-support/src/i18n/en_ZZ'),
				import(/* webpackChunkName: "translations.en-ZZ" */ '@post-office-placement/confluence-home-banner/i18n/en-ZZ'),
				import(/* webpackChunkName: "translations.en-ZZ" */ './messages/en-ZZ.json'),
			];
		}
	}

	switch (locale) {
		case 'fr-FR':
			return [
				import(/* webpackChunkName: "translations.fr-FR" */ '@atlaskit/editor-common/src/i18n/fr'),
				import(/* webpackChunkName: "translations.fr-FR" */ '@atlaskit/editor-core/src/i18n/fr'),
				import(/* webpackChunkName: "translations.fr-FR" */ '@atlaskit/emoji/src/i18n/fr'),
				import(/* webpackChunkName: "translations.fr-FR" */ '@atlaskit/reactions/src/i18n/fr'),
				import(/* webpackChunkName: "translations.fr-FR" */ '@atlaskit/mention/src/i18n/fr'),
				import(/* webpackChunkName: "translations.fr-FR" */ '@atlaskit/renderer/src/i18n/fr'),
				import(/* webpackChunkName: "translations.fr-FR" */ '@atlaskit/smart-card/src/i18n/fr'),
				import(/* webpackChunkName: "translations.fr-FR" */ '@atlaskit/link-picker/src/i18n/fr'),
				import(/* webpackChunkName: "translations.fr-FR" */ '@atlassian/charts/src/i18n/fr'),
				import(/* webpackChunkName: "translations.fr-FR" */ '@atlassian/analytics-byline/src/i18n/fr'),
				import(/* webpackChunkName: "translations.fr-FR" */ '@atlassian/analytics-chart/src/i18n/fr'),
				import(/* webpackChunkName: "translations.fr-FR" */ '@atlassian/conversation-assistant/src/i18n/fr'),
				import(/* webpackChunkName: "translations.fr-FR" */ '@atlassian/conversation-assistant-agent/src/i18n/fr'),
				import(/* webpackChunkName: "translations.fr-FR" */ '@atlassian/conversation-assistant-ui-components/src/i18n/fr'),
				import(/* webpackChunkName: "translations.fr-FR" */ '@atlassian/pill-selector/src/i18n/fr'),
				import(/* webpackChunkName: "translations.fr-FR" */ '@atlassian/popular-tag/src/i18n/fr'),
				import(/* webpackChunkName: "translations.fr-FR" */ '@atlassian/generative-ai-modal/src/i18n/fr'),
				import(/* webpackChunkName: "translations.fr-FR" */ '@atlassian/forge-ui/src/i18n/fr'),
				import(/* webpackChunkName: "translations.fr-FR" */ '@atlassian/editor-extension-link-create/src/i18n/fr'),
				import(/* webpackChunkName: "translations.fr-FR" */ '@atlassian/people-menu/src/i18n/fr'),
				import(/* webpackChunkName: "translations.fr-FR" */ '@atlassian/dynamic-cards-search-page/src/i18n/fr'),
				import(/* webpackChunkName: "translations.fr-FR" */ '@atlassiansox/cross-flow-support/src/i18n/fr'),
				import(/* webpackChunkName: "translations.fr-FR" */ '@post-office-placement/confluence-home-banner/i18n/fr'),
				import(/* webpackChunkName: "translations.fr-FR" */ './messages/fr-FR.json'),
			];

		case 'de-DE':
			return [
				import(/* webpackChunkName: "translations.de-DE" */ '@atlaskit/editor-common/src/i18n/de'),
				import(/* webpackChunkName: "translations.de-DE" */ '@atlaskit/editor-core/src/i18n/de'),
				import(/* webpackChunkName: "translations.de-DE" */ '@atlaskit/emoji/src/i18n/de'),
				import(/* webpackChunkName: "translations.de-DE" */ '@atlaskit/reactions/src/i18n/de'),
				import(/* webpackChunkName: "translations.de-DE" */ '@atlaskit/mention/src/i18n/de'),
				import(/* webpackChunkName: "translations.de-DE" */ '@atlaskit/renderer/src/i18n/de'),
				import(/* webpackChunkName: "translations.de-DE" */ '@atlaskit/smart-card/src/i18n/de'),
				import(/* webpackChunkName: "translations.de-DE" */ '@atlaskit/link-picker/src/i18n/de'),
				import(/* webpackChunkName: "translations.de-DE" */ '@atlassian/charts/src/i18n/de'),
				import(/* webpackChunkName: "translations.de-DE" */ '@atlassian/analytics-byline/src/i18n/de'),
				import(/* webpackChunkName: "translations.de-DE" */ '@atlassian/analytics-chart/src/i18n/de'),
				import(/* webpackChunkName: "translations.de-DE" */ '@atlassian/conversation-assistant/src/i18n/de'),
				import(/* webpackChunkName: "translations.de-DE" */ '@atlassian/conversation-assistant-agent/src/i18n/de'),
				import(/* webpackChunkName: "translations.de-DE" */ '@atlassian/conversation-assistant-ui-components/src/i18n/de'),
				import(/* webpackChunkName: "translations.de-DE" */ '@atlassian/pill-selector/src/i18n/de'),
				import(/* webpackChunkName: "translations.de-DE" */ '@atlassian/popular-tag/src/i18n/de'),
				import(/* webpackChunkName: "translations.de-DE" */ '@atlassian/generative-ai-modal/src/i18n/de'),
				import(/* webpackChunkName: "translations.de-DE" */ '@atlassian/forge-ui/src/i18n/de'),
				import(/* webpackChunkName: "translations.de-DE" */ '@atlassian/editor-extension-link-create/src/i18n/de'),
				import(/* webpackChunkName: "translations.de-DE" */ '@atlassian/people-menu/src/i18n/de'),
				import(/* webpackChunkName: "translations.de-DE" */ '@atlassian/dynamic-cards-search-page/src/i18n/de'),
				import(/* webpackChunkName: "translations.de-DE" */ '@atlassiansox/cross-flow-support/src/i18n/de'),
				import(/* webpackChunkName: "translations.de-DE" */ '@post-office-placement/confluence-home-banner/i18n/de'),
				import(/* webpackChunkName: "translations.de-DE" */ './messages/de-DE.json'),
			];

		case 'ja-JP':
			return [
				import(/* webpackChunkName: "translations.ja-JP" */ '@atlaskit/editor-common/src/i18n/ja'),
				import(/* webpackChunkName: "translations.ja-JP" */ '@atlaskit/editor-core/src/i18n/ja'),
				import(/* webpackChunkName: "translations.ja-JP" */ '@atlaskit/emoji/src/i18n/ja'),
				import(/* webpackChunkName: "translations.ja-JP" */ '@atlaskit/reactions/src/i18n/ja'),
				import(/* webpackChunkName: "translations.ja-JP" */ '@atlaskit/mention/src/i18n/ja'),
				import(/* webpackChunkName: "translations.ja-JP" */ '@atlaskit/renderer/src/i18n/ja'),
				import(/* webpackChunkName: "translations.ja-JP" */ '@atlaskit/smart-card/src/i18n/ja'),
				import(/* webpackChunkName: "translations.ja-JP" */ '@atlaskit/link-picker/src/i18n/ja'),
				import(/* webpackChunkName: "translations.ja-JP" */ '@atlassian/charts/src/i18n/ja'),
				import(/* webpackChunkName: "translations.ja-JP" */ '@atlassian/analytics-byline/src/i18n/ja'),
				import(/* webpackChunkName: "translations.ja-JP" */ '@atlassian/analytics-chart/src/i18n/ja'),
				import(/* webpackChunkName: "translations.ja-JP" */ '@atlassian/conversation-assistant/src/i18n/ja'),
				import(/* webpackChunkName: "translations.ja-JP" */ '@atlassian/conversation-assistant-agent/src/i18n/ja'),
				import(/* webpackChunkName: "translations.ja-JP" */ '@atlassian/conversation-assistant-ui-components/src/i18n/ja'),
				import(/* webpackChunkName: "translations.ja-JP" */ '@atlassian/pill-selector/src/i18n/ja'),
				import(/* webpackChunkName: "translations.ja-JP" */ '@atlassian/popular-tag/src/i18n/ja'),
				import(/* webpackChunkName: "translations.ja-JP" */ '@atlassian/generative-ai-modal/src/i18n/ja'),
				import(/* webpackChunkName: "translations.ja-JP" */ '@atlassian/forge-ui/src/i18n/ja'),
				import(/* webpackChunkName: "translations.ja-JP" */ '@atlassian/editor-extension-link-create/src/i18n/ja'),
				import(/* webpackChunkName: "translations.ja-JP" */ '@atlassian/people-menu/src/i18n/ja'),
				import(/* webpackChunkName: "translations.ja-JP" */ '@atlassian/dynamic-cards-search-page/src/i18n/ja'),
				import(/* webpackChunkName: "translations.ja-JP" */ '@atlassiansox/cross-flow-support/src/i18n/ja'),
				import(/* webpackChunkName: "translations.ja-JP" */ '@post-office-placement/confluence-home-banner/i18n/ja'),
				import(/* webpackChunkName: "translations.ja-JP" */ './messages/ja-JP.json'),
			];

		case 'es-ES':
			return [
				import(/* webpackChunkName: "translations.es-ES" */ '@atlaskit/editor-common/src/i18n/es'),
				import(/* webpackChunkName: "translations.es-ES" */ '@atlaskit/editor-core/src/i18n/es'),
				import(/* webpackChunkName: "translations.es-ES" */ '@atlaskit/emoji/src/i18n/es'),
				import(/* webpackChunkName: "translations.es-ES" */ '@atlaskit/reactions/src/i18n/es'),
				import(/* webpackChunkName: "translations.es-ES" */ '@atlaskit/mention/src/i18n/es'),
				import(/* webpackChunkName: "translations.es-ES" */ '@atlaskit/renderer/src/i18n/es'),
				import(/* webpackChunkName: "translations.es-ES" */ '@atlaskit/smart-card/src/i18n/es'),
				import(/* webpackChunkName: "translations.es-ES" */ '@atlaskit/link-picker/src/i18n/es'),
				import(/* webpackChunkName: "translations.es-ES" */ '@atlassian/charts/src/i18n/es'),
				import(/* webpackChunkName: "translations.es-ES" */ '@atlassian/analytics-byline/src/i18n/es'),
				import(/* webpackChunkName: "translations.es-ES" */ '@atlassian/analytics-chart/src/i18n/es'),
				import(/* webpackChunkName: "translations.es-ES" */ '@atlassian/conversation-assistant/src/i18n/es'),
				import(/* webpackChunkName: "translations.es-ES" */ '@atlassian/conversation-assistant-agent/src/i18n/es'),
				import(/* webpackChunkName: "translations.es-ES" */ '@atlassian/conversation-assistant-ui-components/src/i18n/es'),
				import(/* webpackChunkName: "translations.es-ES" */ '@atlassian/pill-selector/src/i18n/es'),
				import(/* webpackChunkName: "translations.es-ES" */ '@atlassian/popular-tag/src/i18n/es'),
				import(/* webpackChunkName: "translations.es-ES" */ '@atlassian/generative-ai-modal/src/i18n/es'),
				import(/* webpackChunkName: "translations.es-ES" */ '@atlassian/forge-ui/src/i18n/es'),
				import(/* webpackChunkName: "translations.es-ES" */ '@atlassian/editor-extension-link-create/src/i18n/es'),
				import(/* webpackChunkName: "translations.es-ES" */ '@atlassian/people-menu/src/i18n/es'),
				import(/* webpackChunkName: "translations.es-ES" */ '@atlassian/dynamic-cards-search-page/src/i18n/es'),
				import(/* webpackChunkName: "translations.es-ES" */ '@atlassiansox/cross-flow-support/src/i18n/es'),
				import(/* webpackChunkName: "translations.es-ES" */ '@post-office-placement/confluence-home-banner/i18n/es'),
				import(/* webpackChunkName: "translations.es-ES" */ './messages/es-ES.json'),
			];

		case 'ko-KR':
			return [
				import(/* webpackChunkName: "translations.ko-KR" */ '@atlaskit/editor-common/src/i18n/ko'),
				import(/* webpackChunkName: "translations.ko-KR" */ '@atlaskit/editor-core/src/i18n/ko'),
				import(/* webpackChunkName: "translations.ko-KR" */ '@atlaskit/emoji/src/i18n/ko'),
				import(/* webpackChunkName: "translations.ko-KR" */ '@atlaskit/reactions/src/i18n/ko'),
				import(/* webpackChunkName: "translations.ko-KR" */ '@atlaskit/mention/src/i18n/ko'),
				import(/* webpackChunkName: "translations.ko-KR" */ '@atlaskit/renderer/src/i18n/ko'),
				import(/* webpackChunkName: "translations.ko-KR" */ '@atlaskit/smart-card/src/i18n/ko'),
				import(/* webpackChunkName: "translations.ko-KR" */ '@atlaskit/link-picker/src/i18n/ko'),
				import(/* webpackChunkName: "translations.ko-KR" */ '@atlassian/charts/src/i18n/ko'),
				import(/* webpackChunkName: "translations.ko-KR" */ '@atlassian/analytics-byline/src/i18n/ko'),
				import(/* webpackChunkName: "translations.ko-KR" */ '@atlassian/analytics-chart/src/i18n/ko'),
				import(/* webpackChunkName: "translations.ko-KR" */ '@atlassian/conversation-assistant/src/i18n/ko'),
				import(/* webpackChunkName: "translations.ko-KR" */ '@atlassian/conversation-assistant-agent/src/i18n/ko'),
				import(/* webpackChunkName: "translations.ko-KR" */ '@atlassian/conversation-assistant-ui-components/src/i18n/ko'),
				import(/* webpackChunkName: "translations.ko-KR" */ '@atlassian/pill-selector/src/i18n/ko'),
				import(/* webpackChunkName: "translations.ko-KR" */ '@atlassian/popular-tag/src/i18n/ko'),
				import(/* webpackChunkName: "translations.ko-KR" */ '@atlassian/generative-ai-modal/src/i18n/ko'),
				import(/* webpackChunkName: "translations.ko-KR" */ '@atlassian/forge-ui/src/i18n/ko'),
				import(/* webpackChunkName: "translations.ko-KR" */ '@atlassian/editor-extension-link-create/src/i18n/ko'),
				import(/* webpackChunkName: "translations.ko-KR" */ '@atlassian/people-menu/src/i18n/ko'),
				import(/* webpackChunkName: "translations.ko-KR" */ '@atlassian/dynamic-cards-search-page/src/i18n/ko'),
				import(/* webpackChunkName: "translations.ko-KR" */ '@atlassiansox/cross-flow-support/src/i18n/ko'),
				import(/* webpackChunkName: "translations.ko-KR" */ '@post-office-placement/confluence-home-banner/i18n/ko'),
				import(/* webpackChunkName: "translations.ko-KR" */ './messages/ko-KR.json'),
			];

		case 'pt-BR':
			return [
				import(/* webpackChunkName: "translations.pt-BR" */ '@atlaskit/editor-common/src/i18n/pt_BR'),
				import(/* webpackChunkName: "translations.pt-BR" */ '@atlaskit/editor-core/src/i18n/pt_BR'),
				import(/* webpackChunkName: "translations.pt-BR" */ '@atlaskit/emoji/src/i18n/pt_BR'),
				import(/* webpackChunkName: "translations.pt-BR" */ '@atlaskit/reactions/src/i18n/pt_BR'),
				import(/* webpackChunkName: "translations.pt-BR" */ '@atlaskit/mention/src/i18n/pt_BR'),
				import(/* webpackChunkName: "translations.pt-BR" */ '@atlaskit/renderer/src/i18n/pt_BR'),
				import(/* webpackChunkName: "translations.pt-BR" */ '@atlaskit/smart-card/src/i18n/pt_BR'),
				import(/* webpackChunkName: "translations.pt-BR" */ '@atlaskit/link-picker/src/i18n/pt_BR'),
				import(/* webpackChunkName: "translations.pt-BR" */ '@atlassian/charts/src/i18n/pt_BR'),
				import(/* webpackChunkName: "translations.pt-BR" */ '@atlassian/analytics-byline/src/i18n/pt_BR'),
				import(/* webpackChunkName: "translations.pt-BR" */ '@atlassian/analytics-chart/src/i18n/pt_BR'),
				import(/* webpackChunkName: "translations.pt-BR" */ '@atlassian/conversation-assistant/src/i18n/pt_BR'),
				import(/* webpackChunkName: "translations.pt-BR" */ '@atlassian/conversation-assistant-agent/src/i18n/pt_BR'),
				import(/* webpackChunkName: "translations.pt-BR" */ '@atlassian/conversation-assistant-ui-components/src/i18n/pt_BR'),
				import(/* webpackChunkName: "translations.pt-BR" */ '@atlassian/pill-selector/src/i18n/pt_BR'),
				import(/* webpackChunkName: "translations.pt-BR" */ '@atlassian/popular-tag/src/i18n/pt_BR'),
				import(/* webpackChunkName: "translations.pt-BR" */ '@atlassian/generative-ai-modal/src/i18n/pt_BR'),
				import(/* webpackChunkName: "translations.pt-BR" */ '@atlassian/forge-ui/src/i18n/pt_BR'),
				import(/* webpackChunkName: "translations.pt-BR" */ '@atlassian/editor-extension-link-create/src/i18n/pt_BR'),
				import(/* webpackChunkName: "translations.pt-BR" */ '@atlassian/people-menu/src/i18n/pt_BR'),
				import(/* webpackChunkName: "translations.pt-BR" */ '@atlassian/dynamic-cards-search-page/src/i18n/pt_BR'),
				import(/* webpackChunkName: "translations.pt-BR" */ '@atlassiansox/cross-flow-support/src/i18n/pt_BR'),
				import(/* webpackChunkName: "translations.pt-BR" */ '@post-office-placement/confluence-home-banner/i18n/pt-BR'),
				import(/* webpackChunkName: "translations.pt-BR" */ './messages/pt-BR.json'),
			];

		case 'pt-PT':
			return [
				import(/* webpackChunkName: "translations.pt-PT" */ '@atlaskit/editor-common/src/i18n/pt_PT'),
				import(/* webpackChunkName: "translations.pt-PT" */ '@atlaskit/editor-core/src/i18n/pt_PT'),
				import(/* webpackChunkName: "translations.pt-PT" */ '@atlaskit/emoji/src/i18n/pt_PT'),
				import(/* webpackChunkName: "translations.pt-PT" */ '@atlaskit/reactions/src/i18n/pt_PT'),
				import(/* webpackChunkName: "translations.pt-PT" */ '@atlaskit/mention/src/i18n/pt_PT'),
				import(/* webpackChunkName: "translations.pt-PT" */ '@atlaskit/renderer/src/i18n/pt_PT'),
				import(/* webpackChunkName: "translations.pt-PT" */ '@atlaskit/smart-card/src/i18n/pt_PT'),
				import(/* webpackChunkName: "translations.pt-PT" */ '@atlassian/charts/src/i18n/pt_PT'),
				import(/* webpackChunkName: "translations.pt-PT" */ '@atlassian/people-menu/src/i18n/pt_PT'),
				import(/* webpackChunkName: "translations.pt-PT" */ './messages/pt-PT.json'),
			];

		case 'ru-RU':
			return [
				import(/* webpackChunkName: "translations.ru-RU" */ '@atlaskit/editor-common/src/i18n/ru'),
				import(/* webpackChunkName: "translations.ru-RU" */ '@atlaskit/editor-core/src/i18n/ru'),
				import(/* webpackChunkName: "translations.ru-RU" */ '@atlaskit/emoji/src/i18n/ru'),
				import(/* webpackChunkName: "translations.ru-RU" */ '@atlaskit/reactions/src/i18n/ru'),
				import(/* webpackChunkName: "translations.ru-RU" */ '@atlaskit/mention/src/i18n/ru'),
				import(/* webpackChunkName: "translations.ru-RU" */ '@atlaskit/renderer/src/i18n/ru'),
				import(/* webpackChunkName: "translations.ru-RU" */ '@atlaskit/smart-card/src/i18n/ru'),
				import(/* webpackChunkName: "translations.ru-RU" */ '@atlaskit/link-picker/src/i18n/ru'),
				import(/* webpackChunkName: "translations.ru-RU" */ '@atlassian/charts/src/i18n/ru'),
				import(/* webpackChunkName: "translations.ru-RU" */ '@atlassian/analytics-byline/src/i18n/ru'),
				import(/* webpackChunkName: "translations.ru-RU" */ '@atlassian/analytics-chart/src/i18n/ru'),
				import(/* webpackChunkName: "translations.ru-RU" */ '@atlassian/conversation-assistant/src/i18n/ru'),
				import(/* webpackChunkName: "translations.ru-RU" */ '@atlassian/conversation-assistant-agent/src/i18n/ru'),
				import(/* webpackChunkName: "translations.ru-RU" */ '@atlassian/conversation-assistant-ui-components/src/i18n/ru'),
				import(/* webpackChunkName: "translations.ru-RU" */ '@atlassian/pill-selector/src/i18n/ru'),
				import(/* webpackChunkName: "translations.ru-RU" */ '@atlassian/popular-tag/src/i18n/ru'),
				import(/* webpackChunkName: "translations.ru-RU" */ '@atlassian/generative-ai-modal/src/i18n/ru'),
				import(/* webpackChunkName: "translations.ru-RU" */ '@atlassian/forge-ui/src/i18n/ru'),
				import(/* webpackChunkName: "translations.ru-RU" */ '@atlassian/editor-extension-link-create/src/i18n/ru'),
				import(/* webpackChunkName: "translations.ru-RU" */ '@atlassian/people-menu/src/i18n/ru'),
				import(/* webpackChunkName: "translations.ru-RU" */ '@atlassian/dynamic-cards-search-page/src/i18n/ru'),
				import(/* webpackChunkName: "translations.ru-RU" */ '@atlassiansox/cross-flow-support/src/i18n/ru'),
				import(/* webpackChunkName: "translations.ru-RU" */ '@post-office-placement/confluence-home-banner/i18n/ru'),
				import(/* webpackChunkName: "translations.ru-RU" */ './messages/ru-RU.json'),
			];

		case 'da-DK':
			return [
				import(/* webpackChunkName: "translations.da-DK" */ '@atlaskit/editor-common/src/i18n/da'),
				import(/* webpackChunkName: "translations.da-DK" */ '@atlaskit/editor-core/src/i18n/da'),
				import(/* webpackChunkName: "translations.da-DK" */ '@atlaskit/emoji/src/i18n/da'),
				import(/* webpackChunkName: "translations.da-DK" */ '@atlaskit/reactions/src/i18n/da'),
				import(/* webpackChunkName: "translations.da-DK" */ '@atlaskit/mention/src/i18n/da'),
				import(/* webpackChunkName: "translations.da-DK" */ '@atlaskit/renderer/src/i18n/da'),
				import(/* webpackChunkName: "translations.da-DK" */ '@atlaskit/smart-card/src/i18n/da'),
				import(/* webpackChunkName: "translations.da-DK" */ '@atlaskit/link-picker/src/i18n/da'),
				import(/* webpackChunkName: "translations.da-DK" */ '@atlassian/charts/src/i18n/da'),
				import(/* webpackChunkName: "translations.da-DK" */ '@atlassian/analytics-byline/src/i18n/da'),
				import(/* webpackChunkName: "translations.da-DK" */ '@atlassian/analytics-chart/src/i18n/da'),
				import(/* webpackChunkName: "translations.da-DK" */ '@atlassian/conversation-assistant/src/i18n/da'),
				import(/* webpackChunkName: "translations.da-DK" */ '@atlassian/conversation-assistant-agent/src/i18n/da'),
				import(/* webpackChunkName: "translations.da-DK" */ '@atlassian/conversation-assistant-ui-components/src/i18n/da'),
				import(/* webpackChunkName: "translations.da-DK" */ '@atlassian/pill-selector/src/i18n/da'),
				import(/* webpackChunkName: "translations.da-DK" */ '@atlassian/popular-tag/src/i18n/da'),
				import(/* webpackChunkName: "translations.da-DK" */ '@atlassian/generative-ai-modal/src/i18n/da'),
				import(/* webpackChunkName: "translations.da-DK" */ '@atlassian/forge-ui/src/i18n/da'),
				import(/* webpackChunkName: "translations.da-DK" */ '@atlassian/editor-extension-link-create/src/i18n/da'),
				import(/* webpackChunkName: "translations.da-DK" */ '@atlassian/people-menu/src/i18n/da'),
				import(/* webpackChunkName: "translations.da-DK" */ '@atlassian/dynamic-cards-search-page/src/i18n/da'),
				import(/* webpackChunkName: "translations.da-DK" */ '@atlassiansox/cross-flow-support/src/i18n/da'),
				import(/* webpackChunkName: "translations.da-DK" */ '@post-office-placement/confluence-home-banner/i18n/da'),
				import(/* webpackChunkName: "translations.da-DK" */ './messages/da-DK.json'),
			];

		case 'et-EE':
			return [
				import(/* webpackChunkName: "translations.et-EE" */ '@atlaskit/editor-common/src/i18n/et'),
				import(/* webpackChunkName: "translations.et-EE" */ '@atlaskit/editor-core/src/i18n/et'),
				import(/* webpackChunkName: "translations.et-EE" */ '@atlaskit/emoji/src/i18n/et'),
				import(/* webpackChunkName: "translations.et-EE" */ '@atlaskit/reactions/src/i18n/et'),
				import(/* webpackChunkName: "translations.et-EE" */ '@atlaskit/mention/src/i18n/et'),
				import(/* webpackChunkName: "translations.et-EE" */ '@atlaskit/renderer/src/i18n/et'),
				import(/* webpackChunkName: "translations.et-EE" */ '@atlaskit/smart-card/src/i18n/et'),
				import(/* webpackChunkName: "translations.et-EE" */ '@atlassian/charts/src/i18n/et'),
				import(/* webpackChunkName: "translations.et-EE" */ '@atlassian/people-menu/src/i18n/et'),
				import(/* webpackChunkName: "translations.et-EE" */ '@post-office-placement/confluence-home-banner/i18n/et'),
				import(/* webpackChunkName: "translations.et-EE" */ './messages/et-EE.json'),
			];

		case 'fi-FI':
			return [
				import(/* webpackChunkName: "translations.fi-FI" */ '@atlaskit/editor-common/src/i18n/fi'),
				import(/* webpackChunkName: "translations.fi-FI" */ '@atlaskit/editor-core/src/i18n/fi'),
				import(/* webpackChunkName: "translations.fi-FI" */ '@atlaskit/emoji/src/i18n/fi'),
				import(/* webpackChunkName: "translations.fi-FI" */ '@atlaskit/reactions/src/i18n/fi'),
				import(/* webpackChunkName: "translations.fi-FI" */ '@atlaskit/mention/src/i18n/fi'),
				import(/* webpackChunkName: "translations.fi-FI" */ '@atlaskit/renderer/src/i18n/fi'),
				import(/* webpackChunkName: "translations.fi-FI" */ '@atlaskit/smart-card/src/i18n/fi'),
				import(/* webpackChunkName: "translations.fi-FI" */ '@atlaskit/link-picker/src/i18n/fi'),
				import(/* webpackChunkName: "translations.fi-FI" */ '@atlassian/charts/src/i18n/fi'),
				import(/* webpackChunkName: "translations.fi-FI" */ '@atlassian/analytics-byline/src/i18n/fi'),
				import(/* webpackChunkName: "translations.fi-FI" */ '@atlassian/analytics-chart/src/i18n/fi'),
				import(/* webpackChunkName: "translations.fi-FI" */ '@atlassian/conversation-assistant/src/i18n/fi'),
				import(/* webpackChunkName: "translations.fi-FI" */ '@atlassian/conversation-assistant-agent/src/i18n/fi'),
				import(/* webpackChunkName: "translations.fi-FI" */ '@atlassian/conversation-assistant-ui-components/src/i18n/fi'),
				import(/* webpackChunkName: "translations.fi-FI" */ '@atlassian/pill-selector/src/i18n/fi'),
				import(/* webpackChunkName: "translations.fi-FI" */ '@atlassian/popular-tag/src/i18n/fi'),
				import(/* webpackChunkName: "translations.fi-FI" */ '@atlassian/generative-ai-modal/src/i18n/fi'),
				import(/* webpackChunkName: "translations.fi-FI" */ '@atlassian/forge-ui/src/i18n/fi'),
				import(/* webpackChunkName: "translations.fi-FI" */ '@atlassian/editor-extension-link-create/src/i18n/fi'),
				import(/* webpackChunkName: "translations.fi-FI" */ '@atlassian/people-menu/src/i18n/fi'),
				import(/* webpackChunkName: "translations.fi-FI" */ '@atlassian/dynamic-cards-search-page/src/i18n/fi'),
				import(/* webpackChunkName: "translations.fi-FI" */ '@atlassiansox/cross-flow-support/src/i18n/fi'),
				import(/* webpackChunkName: "translations.fi-FI" */ '@post-office-placement/confluence-home-banner/i18n/fi'),
				import(/* webpackChunkName: "translations.fi-FI" */ './messages/fi-FI.json'),
			];

		case 'hu-HU':
			return [
				import(/* webpackChunkName: "translations.hu-HU" */ '@atlaskit/editor-common/src/i18n/hu'),
				import(/* webpackChunkName: "translations.hu-HU" */ '@atlaskit/editor-core/src/i18n/hu'),
				import(/* webpackChunkName: "translations.hu-HU" */ '@atlaskit/emoji/src/i18n/hu'),
				import(/* webpackChunkName: "translations.hu-HU" */ '@atlaskit/reactions/src/i18n/hu'),
				import(/* webpackChunkName: "translations.hu-HU" */ '@atlaskit/mention/src/i18n/hu'),
				import(/* webpackChunkName: "translations.hu-HU" */ '@atlaskit/renderer/src/i18n/hu'),
				import(/* webpackChunkName: "translations.hu-HU" */ '@atlaskit/smart-card/src/i18n/hu'),
				import(/* webpackChunkName: "translations.hu-HU" */ '@atlaskit/link-picker/src/i18n/hu'),
				import(/* webpackChunkName: "translations.hu-HU" */ '@atlassian/charts/src/i18n/hu'),
				import(/* webpackChunkName: "translations.hu-HU" */ '@atlassian/analytics-byline/src/i18n/hu'),
				import(/* webpackChunkName: "translations.hu-HU" */ '@atlassian/analytics-chart/src/i18n/hu'),
				import(/* webpackChunkName: "translations.hu-HU" */ '@atlassian/conversation-assistant/src/i18n/hu'),
				import(/* webpackChunkName: "translations.hu-HU" */ '@atlassian/conversation-assistant-agent/src/i18n/hu'),
				import(/* webpackChunkName: "translations.hu-HU" */ '@atlassian/conversation-assistant-ui-components/src/i18n/hu'),
				import(/* webpackChunkName: "translations.hu-HU" */ '@atlassian/pill-selector/src/i18n/hu'),
				import(/* webpackChunkName: "translations.hu-HU" */ '@atlassian/popular-tag/src/i18n/hu'),
				import(/* webpackChunkName: "translations.hu-HU" */ '@atlassian/generative-ai-modal/src/i18n/hu'),
				import(/* webpackChunkName: "translations.hu-HU" */ '@atlassian/forge-ui/src/i18n/hu'),
				import(/* webpackChunkName: "translations.hu-HU" */ '@atlassian/editor-extension-link-create/src/i18n/hu'),
				import(/* webpackChunkName: "translations.hu-HU" */ '@atlassian/people-menu/src/i18n/hu'),
				import(/* webpackChunkName: "translations.hu-HU" */ '@atlassian/dynamic-cards-search-page/src/i18n/hu'),
				import(/* webpackChunkName: "translations.hu-HU" */ '@atlassiansox/cross-flow-support/src/i18n/hu'),
				import(/* webpackChunkName: "translations.hu-HU" */ '@post-office-placement/confluence-home-banner/i18n/hu'),
				import(/* webpackChunkName: "translations.hu-HU" */ './messages/hu-HU.json'),
			];

		case 'is-IS':
			return [
				import(/* webpackChunkName: "translations.is-IS" */ '@atlaskit/editor-core/src/i18n/is'),
				import(/* webpackChunkName: "translations.is-IS" */ '@atlaskit/mention/src/i18n/is'),
				import(/* webpackChunkName: "translations.is-IS" */ '@atlaskit/emoji/src/i18n/is'),
				import(/* webpackChunkName: "translations.is-IS" */ '@atlaskit/reactions/src/i18n/is'),
				import(/* webpackChunkName: "translations.is-IS" */ '@atlaskit/smart-card/src/i18n/is'),
				import(/* webpackChunkName: "translations.is-IS" */ '@atlassian/people-menu/src/i18n/is'),
				import(/* webpackChunkName: "translations.is-IS" */ './messages/is-IS.json'),
			];

		case 'it-IT':
			return [
				import(/* webpackChunkName: "translations.it-IT" */ '@atlaskit/editor-common/src/i18n/it'),
				import(/* webpackChunkName: "translations.it-IT" */ '@atlaskit/editor-core/src/i18n/it'),
				import(/* webpackChunkName: "translations.it-IT" */ '@atlaskit/emoji/src/i18n/it'),
				import(/* webpackChunkName: "translations.it-IT" */ '@atlaskit/reactions/src/i18n/it'),
				import(/* webpackChunkName: "translations.it-IT" */ '@atlaskit/mention/src/i18n/it'),
				import(/* webpackChunkName: "translations.it-IT" */ '@atlaskit/renderer/src/i18n/it'),
				import(/* webpackChunkName: "translations.it-IT" */ '@atlaskit/smart-card/src/i18n/it'),
				import(/* webpackChunkName: "translations.it-IT" */ '@atlaskit/link-picker/src/i18n/it'),
				import(/* webpackChunkName: "translations.it-IT" */ '@atlassian/charts/src/i18n/it'),
				import(/* webpackChunkName: "translations.it-IT" */ '@atlassian/analytics-byline/src/i18n/it'),
				import(/* webpackChunkName: "translations.it-IT" */ '@atlassian/analytics-chart/src/i18n/it'),
				import(/* webpackChunkName: "translations.it-IT" */ '@atlassian/conversation-assistant/src/i18n/it'),
				import(/* webpackChunkName: "translations.it-IT" */ '@atlassian/conversation-assistant-agent/src/i18n/it'),
				import(/* webpackChunkName: "translations.it-IT" */ '@atlassian/conversation-assistant-ui-components/src/i18n/it'),
				import(/* webpackChunkName: "translations.it-IT" */ '@atlassian/pill-selector/src/i18n/it'),
				import(/* webpackChunkName: "translations.it-IT" */ '@atlassian/popular-tag/src/i18n/it'),
				import(/* webpackChunkName: "translations.it-IT" */ '@atlassian/generative-ai-modal/src/i18n/it'),
				import(/* webpackChunkName: "translations.it-IT" */ '@atlassian/forge-ui/src/i18n/it'),
				import(/* webpackChunkName: "translations.it-IT" */ '@atlassian/editor-extension-link-create/src/i18n/it'),
				import(/* webpackChunkName: "translations.it-IT" */ '@atlassian/people-menu/src/i18n/it'),
				import(/* webpackChunkName: "translations.it-IT" */ '@atlassian/dynamic-cards-search-page/src/i18n/it'),
				import(/* webpackChunkName: "translations.it-IT" */ '@atlassiansox/cross-flow-support/src/i18n/it'),
				import(/* webpackChunkName: "translations.it-IT" */ '@post-office-placement/confluence-home-banner/i18n/it'),
				import(/* webpackChunkName: "translations.it-IT" */ './messages/it-IT.json'),
			];

		case 'nl-NL':
			return [
				import(/* webpackChunkName: "translations.nl-NL" */ '@atlaskit/editor-common/src/i18n/nl'),
				import(/* webpackChunkName: "translations.nl-NL" */ '@atlaskit/editor-core/src/i18n/nl'),
				import(/* webpackChunkName: "translations.nl-NL" */ '@atlaskit/emoji/src/i18n/nl'),
				import(/* webpackChunkName: "translations.nl-NL" */ '@atlaskit/reactions/src/i18n/nl'),
				import(/* webpackChunkName: "translations.nl-NL" */ '@atlaskit/mention/src/i18n/nl'),
				import(/* webpackChunkName: "translations.nl-NL" */ '@atlaskit/renderer/src/i18n/nl'),
				import(/* webpackChunkName: "translations.nl-NL" */ '@atlaskit/smart-card/src/i18n/nl'),
				import(/* webpackChunkName: "translations.nl-NL" */ '@atlaskit/link-picker/src/i18n/nl'),
				import(/* webpackChunkName: "translations.nl-NL" */ '@atlassian/charts/src/i18n/nl'),
				import(/* webpackChunkName: "translations.nl-NL" */ '@atlassian/analytics-byline/src/i18n/nl'),
				import(/* webpackChunkName: "translations.nl-NL" */ '@atlassian/analytics-chart/src/i18n/nl'),
				import(/* webpackChunkName: "translations.nl-NL" */ '@atlassian/conversation-assistant/src/i18n/nl'),
				import(/* webpackChunkName: "translations.nl-NL" */ '@atlassian/conversation-assistant-agent/src/i18n/nl'),
				import(/* webpackChunkName: "translations.nl-NL" */ '@atlassian/conversation-assistant-ui-components/src/i18n/nl'),
				import(/* webpackChunkName: "translations.nl-NL" */ '@atlassian/pill-selector/src/i18n/nl'),
				import(/* webpackChunkName: "translations.nl-NL" */ '@atlassian/popular-tag/src/i18n/nl'),
				import(/* webpackChunkName: "translations.nl-NL" */ '@atlassian/generative-ai-modal/src/i18n/nl'),
				import(/* webpackChunkName: "translations.nl-NL" */ '@atlassian/forge-ui/src/i18n/nl'),
				import(/* webpackChunkName: "translations.nl-NL" */ '@atlassian/editor-extension-link-create/src/i18n/nl'),
				import(/* webpackChunkName: "translations.nl-NL" */ '@atlassian/people-menu/src/i18n/nl'),
				import(/* webpackChunkName: "translations.nl-NL" */ '@atlassian/dynamic-cards-search-page/src/i18n/nl'),
				import(/* webpackChunkName: "translations.nl-NL" */ '@atlassiansox/cross-flow-support/src/i18n/nl'),
				import(/* webpackChunkName: "translations.nl-NL" */ '@post-office-placement/confluence-home-banner/i18n/nl'),
				import(/* webpackChunkName: "translations.nl-NL" */ './messages/nl-NL.json'),
			];

		case 'no-NO':
			/**
			* Fun fact: Norwegian has two written dialects! The W3C recommends using the Bokmål dialect,
			* which is why we use 'nb' as the language code for this locale rather than 'no'.
			* More info:
			* @see https://hello.atlassian.net/wiki/spaces/~139129757/pages/1604629732/User+preferences+for+language+and+locale
			* @see https://internal.stptrans.com/bokmal-and-nynorsk/
			*/
			return [
				import(/* webpackChunkName: "translations.no-NO" */ '@atlaskit/editor-common/src/i18n/nb'),
				import(/* webpackChunkName: "translations.no-NO" */ '@atlaskit/editor-core/src/i18n/nb'),
				import(/* webpackChunkName: "translations.no-NO" */ '@atlaskit/emoji/src/i18n/nb'),
				import(/* webpackChunkName: "translations.no-NO" */ '@atlaskit/reactions/src/i18n/nb'),
				import(/* webpackChunkName: "translations.no-NO" */ '@atlaskit/mention/src/i18n/nb'),
				import(/* webpackChunkName: "translations.no-NO" */ '@atlaskit/renderer/src/i18n/nb'),
				import(/* webpackChunkName: "translations.no-NO" */ '@atlaskit/smart-card/src/i18n/nb'),
				import(/* webpackChunkName: "translations.no-NO" */ '@atlaskit/link-picker/src/i18n/nb'),
				import(/* webpackChunkName: "translations.no-NO" */ '@atlassian/charts/src/i18n/nb'),
				import(/* webpackChunkName: "translations.no-NO" */ '@atlassian/analytics-byline/src/i18n/nb'),
				import(/* webpackChunkName: "translations.no-NO" */ '@atlassian/analytics-chart/src/i18n/nb'),
				import(/* webpackChunkName: "translations.no-NO" */ '@atlassian/conversation-assistant/src/i18n/nb'),
				import(/* webpackChunkName: "translations.no-NO" */ '@atlassian/conversation-assistant-agent/src/i18n/nb'),
				import(/* webpackChunkName: "translations.no-NO" */ '@atlassian/conversation-assistant-ui-components/src/i18n/nb'),
				import(/* webpackChunkName: "translations.no-NO" */ '@atlassian/pill-selector/src/i18n/nb'),
				import(/* webpackChunkName: "translations.no-NO" */ '@atlassian/popular-tag/src/i18n/nb'),
				import(/* webpackChunkName: "translations.no-NO" */ '@atlassian/generative-ai-modal/src/i18n/nb'),
				import(/* webpackChunkName: "translations.no-NO" */ '@atlassian/forge-ui/src/i18n/nb'),
				import(/* webpackChunkName: "translations.no-NO" */ '@atlassian/editor-extension-link-create/src/i18n/nb'),
				import(/* webpackChunkName: "translations.no-NO" */ '@atlassian/people-menu/src/i18n/nb'),
				import(/* webpackChunkName: "translations.no-NO" */ '@atlassian/dynamic-cards-search-page/src/i18n/nb'),
				import(/* webpackChunkName: "translations.no-NO" */ '@atlassiansox/cross-flow-support/src/i18n/nb'),
				import(/* webpackChunkName: "translations.no-NO" */ '@post-office-placement/confluence-home-banner/i18n/nb'),
				import(/* webpackChunkName: "translations.no-NO" */ './messages/no-NO.json'),
			];

		case 'pl-PL':
			return [
				import(/* webpackChunkName: "translations.pl-PL" */ '@atlaskit/editor-common/src/i18n/pl'),
				import(/* webpackChunkName: "translations.pl-PL" */ '@atlaskit/editor-core/src/i18n/pl'),
				import(/* webpackChunkName: "translations.pl-PL" */ '@atlaskit/emoji/src/i18n/pl'),
				import(/* webpackChunkName: "translations.pl-PL" */ '@atlaskit/reactions/src/i18n/pl'),
				import(/* webpackChunkName: "translations.pl-PL" */ '@atlaskit/mention/src/i18n/pl'),
				import(/* webpackChunkName: "translations.pl-PL" */ '@atlaskit/renderer/src/i18n/pl'),
				import(/* webpackChunkName: "translations.pl-PL" */ '@atlaskit/smart-card/src/i18n/pl'),
				import(/* webpackChunkName: "translations.pl-PL" */ '@atlaskit/link-picker/src/i18n/pl'),
				import(/* webpackChunkName: "translations.pl-PL" */ '@atlassian/charts/src/i18n/pl'),
				import(/* webpackChunkName: "translations.pl-PL" */ '@atlassian/analytics-byline/src/i18n/pl'),
				import(/* webpackChunkName: "translations.pl-PL" */ '@atlassian/analytics-chart/src/i18n/pl'),
				import(/* webpackChunkName: "translations.pl-PL" */ '@atlassian/conversation-assistant/src/i18n/pl'),
				import(/* webpackChunkName: "translations.pl-PL" */ '@atlassian/conversation-assistant-agent/src/i18n/pl'),
				import(/* webpackChunkName: "translations.pl-PL" */ '@atlassian/conversation-assistant-ui-components/src/i18n/pl'),
				import(/* webpackChunkName: "translations.pl-PL" */ '@atlassian/pill-selector/src/i18n/pl'),
				import(/* webpackChunkName: "translations.pl-PL" */ '@atlassian/popular-tag/src/i18n/pl'),
				import(/* webpackChunkName: "translations.pl-PL" */ '@atlassian/generative-ai-modal/src/i18n/pl'),
				import(/* webpackChunkName: "translations.pl-PL" */ '@atlassian/forge-ui/src/i18n/pl'),
				import(/* webpackChunkName: "translations.pl-PL" */ '@atlassian/editor-extension-link-create/src/i18n/pl'),
				import(/* webpackChunkName: "translations.pl-PL" */ '@atlassian/people-menu/src/i18n/pl'),
				import(/* webpackChunkName: "translations.pl-PL" */ '@atlassian/dynamic-cards-search-page/src/i18n/pl'),
				import(/* webpackChunkName: "translations.pl-PL" */ '@atlassiansox/cross-flow-support/src/i18n/pl'),
				import(/* webpackChunkName: "translations.pl-PL" */ '@post-office-placement/confluence-home-banner/i18n/pl'),
				import(/* webpackChunkName: "translations.pl-PL" */ './messages/pl-PL.json'),
			];

		case 'ro-RO':
			return [
				import(/* webpackChunkName: "translations.ro-RO" */ '@atlaskit/editor-core/src/i18n/ro'),
				import(/* webpackChunkName: "translations.ro-RO" */ '@atlaskit/emoji/src/i18n/ro'),
				import(/* webpackChunkName: "translations.ro-RO" */ '@atlaskit/reactions/src/i18n/ro'),
				import(/* webpackChunkName: "translations.ro-RO" */ '@atlaskit/mention/src/i18n/ro'),
				import(/* webpackChunkName: "translations.ro-RO" */ '@atlaskit/smart-card/src/i18n/ro'),
				import(/* webpackChunkName: "translations.ro-RO" */ '@atlassian/people-menu/src/i18n/ro'),
				import(/* webpackChunkName: "translations.ro-RO" */ '@post-office-placement/confluence-home-banner/i18n/ro'),
				import(/* webpackChunkName: "translations.ro-RO" */ './messages/ro-RO.json'),
			];

		case 'sk-SK':
			return [
				import(/* webpackChunkName: "translations.sk-SK" */ '@atlaskit/editor-common/src/i18n/sk'),
				import(/* webpackChunkName: "translations.sk-SK" */ '@atlaskit/editor-core/src/i18n/sk'),
				import(/* webpackChunkName: "translations.sk-SK" */ '@atlaskit/emoji/src/i18n/sk'),
				import(/* webpackChunkName: "translations.sk-SK" */ '@atlaskit/reactions/src/i18n/sk'),
				import(/* webpackChunkName: "translations.sk-SK" */ '@atlaskit/mention/src/i18n/sk'),
				import(/* webpackChunkName: "translations.sk-SK" */ '@atlaskit/renderer/src/i18n/sk'),
				import(/* webpackChunkName: "translations.sk-SK" */ '@atlaskit/smart-card/src/i18n/sk'),
				import(/* webpackChunkName: "translations.sk-SK" */ '@atlassian/charts/src/i18n/sk'),
				import(/* webpackChunkName: "translations.sk-SK" */ '@atlassian/people-menu/src/i18n/sk'),
				import(/* webpackChunkName: "translations.sk-SK" */ './messages/sk-SK.json'),
			];

		case 'cs-CZ':
			return [
				import(/* webpackChunkName: "translations.cs-CZ" */ '@atlaskit/editor-common/src/i18n/cs'),
				import(/* webpackChunkName: "translations.cs-CZ" */ '@atlaskit/editor-core/src/i18n/cs'),
				import(/* webpackChunkName: "translations.cs-CZ" */ '@atlaskit/emoji/src/i18n/cs'),
				import(/* webpackChunkName: "translations.cs-CZ" */ '@atlaskit/reactions/src/i18n/cs'),
				import(/* webpackChunkName: "translations.cs-CZ" */ '@atlaskit/mention/src/i18n/cs'),
				import(/* webpackChunkName: "translations.cs-CZ" */ '@atlaskit/renderer/src/i18n/cs'),
				import(/* webpackChunkName: "translations.cs-CZ" */ '@atlaskit/smart-card/src/i18n/cs'),
				import(/* webpackChunkName: "translations.cs-CZ" */ '@atlaskit/link-picker/src/i18n/cs'),
				import(/* webpackChunkName: "translations.cs-CZ" */ '@atlassian/charts/src/i18n/cs'),
				import(/* webpackChunkName: "translations.cs-CZ" */ '@atlassian/analytics-byline/src/i18n/cs'),
				import(/* webpackChunkName: "translations.cs-CZ" */ '@atlassian/analytics-chart/src/i18n/cs'),
				import(/* webpackChunkName: "translations.cs-CZ" */ '@atlassian/conversation-assistant/src/i18n/cs'),
				import(/* webpackChunkName: "translations.cs-CZ" */ '@atlassian/conversation-assistant-agent/src/i18n/cs'),
				import(/* webpackChunkName: "translations.cs-CZ" */ '@atlassian/conversation-assistant-ui-components/src/i18n/cs'),
				import(/* webpackChunkName: "translations.cs-CZ" */ '@atlassian/pill-selector/src/i18n/cs'),
				import(/* webpackChunkName: "translations.cs-CZ" */ '@atlassian/popular-tag/src/i18n/cs'),
				import(/* webpackChunkName: "translations.cs-CZ" */ '@atlassian/generative-ai-modal/src/i18n/cs'),
				import(/* webpackChunkName: "translations.cs-CZ" */ '@atlassian/forge-ui/src/i18n/cs'),
				import(/* webpackChunkName: "translations.cs-CZ" */ '@atlassian/editor-extension-link-create/src/i18n/cs'),
				import(/* webpackChunkName: "translations.cs-CZ" */ '@atlassian/people-menu/src/i18n/cs'),
				import(/* webpackChunkName: "translations.cs-CZ" */ '@atlassian/dynamic-cards-search-page/src/i18n/cs'),
				import(/* webpackChunkName: "translations.cs-CZ" */ '@atlassiansox/cross-flow-support/src/i18n/cs'),
				import(/* webpackChunkName: "translations.cs-CZ" */ '@post-office-placement/confluence-home-banner/i18n/cs'),
				import(/* webpackChunkName: "translations.cs-CZ" */ './messages/cs-CZ.json'),
			];

		case 'sv-SE':
			return [
				import(/* webpackChunkName: "translations.sv-SE" */ '@atlaskit/editor-common/src/i18n/sv'),
				import(/* webpackChunkName: "translations.sv-SE" */ '@atlaskit/editor-core/src/i18n/sv'),
				import(/* webpackChunkName: "translations.sv-SE" */ '@atlaskit/emoji/src/i18n/sv'),
				import(/* webpackChunkName: "translations.sv-SE" */ '@atlaskit/reactions/src/i18n/sv'),
				import(/* webpackChunkName: "translations.sv-SE" */ '@atlaskit/mention/src/i18n/sv'),
				import(/* webpackChunkName: "translations.sv-SE" */ '@atlaskit/renderer/src/i18n/sv'),
				import(/* webpackChunkName: "translations.sv-SE" */ '@atlaskit/smart-card/src/i18n/sv'),
				import(/* webpackChunkName: "translations.sv-SE" */ '@atlaskit/link-picker/src/i18n/sv'),
				import(/* webpackChunkName: "translations.sv-SE" */ '@atlassian/charts/src/i18n/sv'),
				import(/* webpackChunkName: "translations.sv-SE" */ '@atlassian/analytics-byline/src/i18n/sv'),
				import(/* webpackChunkName: "translations.sv-SE" */ '@atlassian/analytics-chart/src/i18n/sv'),
				import(/* webpackChunkName: "translations.sv-SE" */ '@atlassian/conversation-assistant/src/i18n/sv'),
				import(/* webpackChunkName: "translations.sv-SE" */ '@atlassian/conversation-assistant-agent/src/i18n/sv'),
				import(/* webpackChunkName: "translations.sv-SE" */ '@atlassian/conversation-assistant-ui-components/src/i18n/sv'),
				import(/* webpackChunkName: "translations.sv-SE" */ '@atlassian/pill-selector/src/i18n/sv'),
				import(/* webpackChunkName: "translations.sv-SE" */ '@atlassian/popular-tag/src/i18n/sv'),
				import(/* webpackChunkName: "translations.sv-SE" */ '@atlassian/generative-ai-modal/src/i18n/sv'),
				import(/* webpackChunkName: "translations.sv-SE" */ '@atlassian/forge-ui/src/i18n/sv'),
				import(/* webpackChunkName: "translations.sv-SE" */ '@atlassian/editor-extension-link-create/src/i18n/sv'),
				import(/* webpackChunkName: "translations.sv-SE" */ '@atlassian/people-menu/src/i18n/sv'),
				import(/* webpackChunkName: "translations.sv-SE" */ '@atlassian/dynamic-cards-search-page/src/i18n/sv'),
				import(/* webpackChunkName: "translations.sv-SE" */ '@atlassiansox/cross-flow-support/src/i18n/sv'),
				import(/* webpackChunkName: "translations.sv-SE" */ '@post-office-placement/confluence-home-banner/i18n/sv'),
				import(/* webpackChunkName: "translations.sv-SE" */ './messages/sv-SE.json'),
			];

		case 'zh-CN':
			return [
				import(/* webpackChunkName: "translations.zh-CN" */ '@atlaskit/editor-common/src/i18n/zh'),
				import(/* webpackChunkName: "translations.zh-CN" */ '@atlaskit/editor-core/src/i18n/zh'),
				import(/* webpackChunkName: "translations.zh-CN" */ '@atlaskit/emoji/src/i18n/zh'),
				import(/* webpackChunkName: "translations.zh-CN" */ '@atlaskit/reactions/src/i18n/zh'),
				import(/* webpackChunkName: "translations.zh-CN" */ '@atlaskit/mention/src/i18n/zh'),
				import(/* webpackChunkName: "translations.zh-CN" */ '@atlaskit/renderer/src/i18n/zh'),
				import(/* webpackChunkName: "translations.zh-CN" */ '@atlaskit/smart-card/src/i18n/zh'),
				import(/* webpackChunkName: "translations.zh-CN" */ '@atlaskit/link-picker/src/i18n/zh'),
				import(/* webpackChunkName: "translations.zh-CN" */ '@atlassian/charts/src/i18n/zh'),
				import(/* webpackChunkName: "translations.zh-CN" */ '@atlassian/analytics-byline/src/i18n/zh'),
				import(/* webpackChunkName: "translations.zh-CN" */ '@atlassian/analytics-chart/src/i18n/zh'),
				import(/* webpackChunkName: "translations.zh-CN" */ '@atlassian/conversation-assistant/src/i18n/zh'),
				import(/* webpackChunkName: "translations.zh-CN" */ '@atlassian/conversation-assistant-agent/src/i18n/zh'),
				import(/* webpackChunkName: "translations.zh-CN" */ '@atlassian/conversation-assistant-ui-components/src/i18n/zh'),
				import(/* webpackChunkName: "translations.zh-CN" */ '@atlassian/pill-selector/src/i18n/zh'),
				import(/* webpackChunkName: "translations.zh-CN" */ '@atlassian/popular-tag/src/i18n/zh'),
				import(/* webpackChunkName: "translations.zh-CN" */ '@atlassian/generative-ai-modal/src/i18n/zh'),
				import(/* webpackChunkName: "translations.zh-CN" */ '@atlassian/forge-ui/src/i18n/zh'),
				import(/* webpackChunkName: "translations.zh-CN" */ '@atlassian/editor-extension-link-create/src/i18n/zh'),
				import(/* webpackChunkName: "translations.zh-CN" */ '@atlassian/people-menu/src/i18n/zh'),
				import(/* webpackChunkName: "translations.zh-CN" */ '@atlassian/dynamic-cards-search-page/src/i18n/zh'),
				import(/* webpackChunkName: "translations.zh-CN" */ '@atlassiansox/cross-flow-support/src/i18n/zh'),
				import(/* webpackChunkName: "translations.zh-CN" */ '@post-office-placement/confluence-home-banner/i18n/zh-CN'),
				import(/* webpackChunkName: "translations.zh-CN" */ './messages/zh-CN.json'),
			];

		case 'zh-TW':
			return [
				import(/* webpackChunkName: "translations.zh-TW" */ '@atlaskit/editor-common/src/i18n/zh_TW'),
				import(/* webpackChunkName: "translations.zh-TW" */ '@atlaskit/editor-core/src/i18n/zh_TW'),
				import(/* webpackChunkName: "translations.zh-TW" */ '@atlaskit/emoji/src/i18n/zh_TW'),
				import(/* webpackChunkName: "translations.zh-TW" */ '@atlaskit/reactions/src/i18n/zh_TW'),
				import(/* webpackChunkName: "translations.zh-TW" */ '@atlaskit/mention/src/i18n/zh_TW'),
				import(/* webpackChunkName: "translations.zh-TW" */ '@atlaskit/renderer/src/i18n/zh_TW'),
				import(/* webpackChunkName: "translations.zh-TW" */ '@atlaskit/smart-card/src/i18n/zh_TW'),
				import(/* webpackChunkName: "translations.zh-TW" */ '@atlaskit/link-picker/src/i18n/zh_TW'),
				import(/* webpackChunkName: "translations.zh-TW" */ '@atlassian/charts/src/i18n/zh_TW'),
				import(/* webpackChunkName: "translations.zh-TW" */ '@atlassian/analytics-byline/src/i18n/zh_TW'),
				import(/* webpackChunkName: "translations.zh-TW" */ '@atlassian/analytics-chart/src/i18n/zh_TW'),
				import(/* webpackChunkName: "translations.zh-TW" */ '@atlassian/conversation-assistant/src/i18n/zh_TW'),
				import(/* webpackChunkName: "translations.zh-TW" */ '@atlassian/conversation-assistant-agent/src/i18n/zh_TW'),
				import(/* webpackChunkName: "translations.zh-TW" */ '@atlassian/conversation-assistant-ui-components/src/i18n/zh_TW'),
				import(/* webpackChunkName: "translations.zh-TW" */ '@atlassian/pill-selector/src/i18n/zh_TW'),
				import(/* webpackChunkName: "translations.zh-TW" */ '@atlassian/popular-tag/src/i18n/zh_TW'),
				import(/* webpackChunkName: "translations.zh-TW" */ '@atlassian/generative-ai-modal/src/i18n/zh_TW'),
				import(/* webpackChunkName: "translations.zh-TW" */ '@atlassian/forge-ui/src/i18n/zh_TW'),
				import(/* webpackChunkName: "translations.zh-TW" */ '@atlassian/editor-extension-link-create/src/i18n/zh_TW'),
				import(/* webpackChunkName: "translations.zh-TW" */ '@atlassian/people-menu/src/i18n/zh_TW'),
				import(/* webpackChunkName: "translations.zh-TW" */ '@atlassian/dynamic-cards-search-page/src/i18n/zh_TW'),
				import(/* webpackChunkName: "translations.zh-TW" */ '@atlassiansox/cross-flow-support/src/i18n/zh_TW'),
				import(/* webpackChunkName: "translations.zh-TW" */ '@post-office-placement/confluence-home-banner/i18n/zh-TW'),
				import(/* webpackChunkName: "translations.zh-TW" */ './messages/zh-TW.json'),
			];

		case 'tr-TR':
			return [
				import(/* webpackChunkName: "translations.tr-TR" */ '@atlaskit/editor-common/src/i18n/tr'),
				import(/* webpackChunkName: "translations.tr-TR" */ '@atlaskit/editor-core/src/i18n/tr'),
				import(/* webpackChunkName: "translations.tr-TR" */ '@atlaskit/emoji/src/i18n/tr'),
				import(/* webpackChunkName: "translations.tr-TR" */ '@atlaskit/reactions/src/i18n/tr'),
				import(/* webpackChunkName: "translations.tr-TR" */ '@atlaskit/mention/src/i18n/tr'),
				import(/* webpackChunkName: "translations.tr-TR" */ '@atlaskit/renderer/src/i18n/tr'),
				import(/* webpackChunkName: "translations.tr-TR" */ '@atlaskit/smart-card/src/i18n/tr'),
				import(/* webpackChunkName: "translations.tr-TR" */ '@atlassian/charts/src/i18n/tr'),
				import(/* webpackChunkName: "translations.tr-TR" */ '@atlassian/analytics-byline/src/i18n/tr'),
				import(/* webpackChunkName: "translations.tr-TR" */ '@atlassian/analytics-chart/src/i18n/tr'),
				import(/* webpackChunkName: "translations.tr-TR" */ '@atlassian/conversation-assistant/src/i18n/tr'),
				import(/* webpackChunkName: "translations.tr-TR" */ '@atlassian/conversation-assistant-agent/src/i18n/tr'),
				import(/* webpackChunkName: "translations.tr-TR" */ '@atlassian/conversation-assistant-ui-components/src/i18n/tr'),
				import(/* webpackChunkName: "translations.tr-TR" */ '@atlassian/pill-selector/src/i18n/tr'),
				import(/* webpackChunkName: "translations.tr-TR" */ '@atlassian/popular-tag/src/i18n/tr'),
				import(/* webpackChunkName: "translations.tr-TR" */ '@atlassian/generative-ai-modal/src/i18n/tr'),
				import(/* webpackChunkName: "translations.tr-TR" */ '@atlassian/forge-ui/src/i18n/tr'),
				import(/* webpackChunkName: "translations.tr-TR" */ '@atlassian/editor-extension-link-create/src/i18n/tr'),
				import(/* webpackChunkName: "translations.tr-TR" */ '@atlassian/people-menu/src/i18n/tr'),
				import(/* webpackChunkName: "translations.tr-TR" */ '@atlassian/dynamic-cards-search-page/src/i18n/tr'),
				import(/* webpackChunkName: "translations.tr-TR" */ '@atlassiansox/cross-flow-support/src/i18n/tr'),
				import(/* webpackChunkName: "translations.tr-TR" */ '@post-office-placement/confluence-home-banner/i18n/tr'),
				import(/* webpackChunkName: "translations.tr-TR" */ './messages/tr-TR.json'),
			];

		case 'en-GB':
			return [
				import(/* webpackChunkName: "translations.en-GB" */ '@atlaskit/editor-common/src/i18n/en_GB'),
				import(/* webpackChunkName: "translations.en-GB" */ '@atlaskit/editor-core/src/i18n/en_GB'),
				import(/* webpackChunkName: "translations.en-GB" */ '@atlaskit/emoji/src/i18n/en_GB'),
				import(/* webpackChunkName: "translations.en-GB" */ '@atlaskit/reactions/src/i18n/en_GB'),
				import(/* webpackChunkName: "translations.en-GB" */ '@atlaskit/mention/src/i18n/en_GB'),
				import(/* webpackChunkName: "translations.en-GB" */ '@atlaskit/renderer/src/i18n/en_GB'),
				import(/* webpackChunkName: "translations.en-GB" */ '@atlaskit/smart-card/src/i18n/en_GB'),
				import(/* webpackChunkName: "translations.en-GB" */ '@atlaskit/link-picker/src/i18n/en_GB'),
				import(/* webpackChunkName: "translations.en-GB" */ '@atlassian/analytics-byline/src/i18n/en_GB'),
				import(/* webpackChunkName: "translations.en-GB" */ '@atlassian/analytics-chart/src/i18n/en_GB'),
				import(/* webpackChunkName: "translations.en-GB" */ '@atlassian/conversation-assistant/src/i18n/en_GB'),
				import(/* webpackChunkName: "translations.en-GB" */ '@atlassian/conversation-assistant-agent/src/i18n/en_GB'),
				import(/* webpackChunkName: "translations.en-GB" */ '@atlassian/conversation-assistant-ui-components/src/i18n/en_GB'),
				import(/* webpackChunkName: "translations.en-GB" */ '@atlassian/pill-selector/src/i18n/en_GB'),
				import(/* webpackChunkName: "translations.en-GB" */ '@atlassian/popular-tag/src/i18n/en_GB'),
				import(/* webpackChunkName: "translations.en-GB" */ '@atlassian/generative-ai-modal/src/i18n/en_GB'),
				import(/* webpackChunkName: "translations.en-GB" */ '@atlassian/forge-ui/src/i18n/en_GB'),
				import(/* webpackChunkName: "translations.en-GB" */ '@atlassian/editor-extension-link-create/src/i18n/en_GB'),
				import(/* webpackChunkName: "translations.en-GB" */ '@atlassian/people-menu/src/i18n/en_GB'),
				import(/* webpackChunkName: "translations.en-GB" */ '@atlassian/dynamic-cards-search-page/src/i18n/en_GB'),
				import(/* webpackChunkName: "translations.en-GB" */ '@atlassiansox/cross-flow-support/src/i18n/en_GB'),
				import(/* webpackChunkName: "translations.en-GB" */ '@post-office-placement/confluence-home-banner/i18n/en-GB'),
				import(/* webpackChunkName: "translations.en-GB" */ './messages/en-GB.json'),
			];

		default:
			// Use default string defined in the source code.
			return [
				import(/* webpackChunkName: "translations.en-US" */ '@atlaskit/editor-common/src/i18n/en'),
				import(/* webpackChunkName: "translations.en-US" */ '@atlaskit/editor-core/src/i18n/en'),
				import(/* webpackChunkName: "translations.en-US" */ '@atlaskit/emoji/src/i18n/en'),
				import(/* webpackChunkName: "translations.en-US" */ '@atlaskit/reactions/src/i18n/en'),
				import(/* webpackChunkName: "translations.en-US" */ '@atlaskit/renderer/src/i18n/en'),
				import(/* webpackChunkName: "translations.en-US" */ '@atlaskit/smart-card/src/i18n/en'),
				import(/* webpackChunkName: "translations.en-US" */ '@atlaskit/link-picker/src/i18n/en'),
				import(/* webpackChunkName: "translations.en-US" */ '@atlassian/charts/src/i18n/en'),
				import(/* webpackChunkName: "translations.en-US" */ '@atlassian/analytics-byline/src/i18n/en'),
				import(/* webpackChunkName: "translations.en-US" */ '@atlassian/analytics-chart/src/i18n/en'),
				import(/* webpackChunkName: "translations.en-US" */ '@atlassian/conversation-assistant/src/i18n/en'),
				import(/* webpackChunkName: "translations.en-US" */ '@atlassian/conversation-assistant-agent/src/i18n/en'),
				import(/* webpackChunkName: "translations.en-US" */ '@atlassian/conversation-assistant-ui-components/src/i18n/en'),
				import(/* webpackChunkName: "translations.en-US" */ '@atlassian/pill-selector/src/i18n/en'),
				import(/* webpackChunkName: "translations.en-US" */ '@atlassian/popular-tag/src/i18n/en'),
				import(/* webpackChunkName: "translations.en-US" */ '@atlaskit/mention/src/i18n/en'),
				import(/* webpackChunkName: "translations.en-US" */ '@atlassian/editor-extension-link-create/src/i18n/en'),
				import(/* webpackChunkName: "translations.en-US" */ '@atlassian/generative-ai-modal/src/i18n/en'),
				import(/* webpackChunkName: "translations.en-US" */ '@atlassian/people-menu/src/i18n/en'),
				import(/* webpackChunkName: "translations.en-US" */ '@atlassian/dynamic-cards-search-page/src/i18n/en'),
				import(/* webpackChunkName: "translations.en-US" */ '@atlassiansox/cross-flow-support/src/i18n/en'),
				import(/* webpackChunkName: "translations.en-US" */ '@post-office-placement/confluence-home-banner/i18n/en'),
				import(/* webpackChunkName: "translations.en-US" */ '@atlassian/forge-ui/src/i18n/en'),
			];
	}
};
