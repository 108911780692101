import { query } from '@confluence/query-preloader-tools';
import { SortOrders } from '@confluence/template-utils';

import { SuggestedTemplatesQuery } from './queries/SuggestedTemplatesQuery.graphql';
import { TEMPLATE_LIMIT } from './bpqaConst';

type SuggestedTemplateQueryProps = {
	spaceKey: string;
};

export function preloadSuggestedTemplateQuery({ spaceKey }: SuggestedTemplateQueryProps) {
	return query({
		query: SuggestedTemplatesQuery,
		variables: {
			spaceKey,
			sortingScheme: SortOrders.RELEVANCY_SORT,
			limit: TEMPLATE_LIMIT,
		},
	});
}
