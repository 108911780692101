export const SessionDataQueryRaw = /* GraphQL */ `
	query SessionDataQuery @SLA {
		tenant {
			shard
			cloudId
			environment
			activationId
			editions {
				edition
			}
		}
		organization {
			orgId
		}
		userForAnalytics: user {
			id
		}
		user(current: true) {
			id
			displayName
			confluence {
				accessStatus
				accountId
				userKey
				locale
			}
			timeZone
		}
		getAIConfig(product: CONFLUENCE) {
			isEnabled
			isRovoEnabled
			isRovoLLMEnabled
		}
	}
`;
