import type { ForgeDoc } from '@atlassian/forge-ui-types';
import { type ComponentPropsConfig } from '../../../utils/codegen';

// Convert child to number if possible
// Badge `max` prop will only work if the child is a number
const getBadgeChildren = (forgeDoc: ForgeDoc) => {
	const child = forgeDoc.children?.[0]?.props?.text
	if (child && !isNaN(Number(child))) {
		return Number(child);
	}
	return child;
}

export const componentPropsConfig: ComponentPropsConfig = {
	appearance: true,

	/**
	 * The value displayed within the badge. A badge should only be used in cases where you want to represent a number. Use a lozenge for non-numeric information.
	 *
	 * @type string | number
	 */
	children: {
		value: 'getBadgeChildren(props.forgeDoc)',
	},

	max: true,

	testId: true
};

/**
 * THIS SECTION WAS CREATED VIA CODEGEN DO NOT MODIFY {@see http://go/af-codegen}
 *
 * This component was generated against an existing platform component.
 * If you are seeing lint errors in this file, it means that this file's dependencies have been modified and the component will need to be regenerated.
 *
 * Please view https://hello.atlassian.net/wiki/spaces/ECO/pages/3358130330/How+to+Regenerate+UI+Kit+Components for more information on how to proceed.
 *
 * For further questions, please ping the #help-forge-ui channel.
 *
 * @codegen <<SignedSource::f111186797d836a2839ef00e2c21ad2e>>
 * @codegenId #badge
 * @codegenCommand yarn workspace @atlassian/forge-ui codegen badge
 * @codegenDependency ../../../../../../../design-system/badge/src/types.tsx <<SignedSource::81990702fa7c9948590f31d4bfa30dde>>
 * @codegenDependency ../../../../../../../design-system/badge/src/internal/utils.tsx <<SignedSource::bc7b9146ef51da9aa4b62a49aec6421a>>
 * @codegenDependency ../../../../../../../design-system/badge/src/badge.tsx <<SignedSource::57a2d410f6b6a8aca92d95bbb2f35ebf>>
 */
/* eslint @repo/internal/codegen/signed-source-integrity: "warn" */
import React from 'react';
import type { RenderFn } from '@atlassian/forge-ui-types';
import PlatformBadge from '@atlaskit/badge';

type _PlatformBadgeProps = React.ComponentProps<typeof PlatformBadge>;
export type PlatformBadgeProps = Omit<_PlatformBadgeProps, 'children'> & {
/**
 * The value displayed within the badge. A badge should only be used in cases where you want to represent a number. Use a lozenge for non-numeric information.
 * 
 * @type string | number
 */
	children?: _PlatformBadgeProps['children'];
}

export type BadgeProps = Pick<
  PlatformBadgeProps,
  'appearance' | 'children' | 'max' | 'testId'
>;

/**
 * A badge is a visual indicator for numeric values such as tallies and scores.
 */
export const Badge = (props: Parameters<RenderFn>[0]) => {
  const {
    appearance,
    max,
    testId,
  } = props.forgeDoc.props as BadgeProps;
  return (
    <PlatformBadge
      appearance={appearance}
      children={getBadgeChildren(props.forgeDoc)}
      max={max}
      testId={testId}
    />
  );
};
/**
 * @codegenEnd
 */
