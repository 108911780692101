import { isNativeMobile, setUserAgent } from '@confluence/browser-helper';
import { getLogger } from '@confluence/logger';

// Must call this before any browser-helper functions, to ensure user agent has been set first
setUserAgent();

const logger = getLogger('mobile-detection');

// Copied from com.atlassian.confluence.mobile.MobileUtils
const NATIVE_IOS_REGEX_STRING = 'iPhone;|iPad;|iPhone Simulator;|iPod;|iPod touch;';
const NATIVE_ANDROID_REGEX_STRING = 'Linux; U; Android|Android';
const MOBILE_USER_AGENT_REGEX = new RegExp(
	`(${NATIVE_IOS_REGEX_STRING}|${NATIVE_ANDROID_REGEX_STRING})`,
	'i',
);
const ANDROID_CHROME = /Chrome\/[.\d]* Mobile/i;
const OLD_ANDROID = /Linux; U; Android (?:[23]\.\d|4\.0\.[12])/i;
const NATIVE_IOS = new RegExp(`(${NATIVE_IOS_REGEX_STRING})`, 'i');
const NATIVE_ANDROID = new RegExp(`(${NATIVE_ANDROID_REGEX_STRING})`, 'i');

export const MOBILE_WEB_QUERY_OVERRIDE = 'mobileWebTestingMode=testing';

// Export for testing
export const MOBILE_WEB_DESKTOP_OVERRIDE_ACTIVE = 'mobile.web.desktop.override.active';

export const isMobileUserAgent = () => {
	// Port from com.atlassian.confluence.mobile.MobileUtils#isMobileViewRequest
	const ua = window.navigator.userAgent;

	return (
		!!ua &&
		!OLD_ANDROID.test(ua) &&
		(MOBILE_USER_AGENT_REGEX.test(ua) || ANDROID_CHROME.test(ua) || isNativeMobile())
	);
};

export const isDesktopOverrideActive = () =>
	window.localStorage.getItem(MOBILE_WEB_DESKTOP_OVERRIDE_ACTIVE) === 'true';

export const activateDesktopOverride = () => {
	try {
		// TODO: Remove lint rule once storage is updated to use atlassian/browser-storage-controls or confluence/storage-manager
		// eslint-disable-next-line check-set-storage-usage/no-unimported-storage-set
		window.localStorage.setItem(MOBILE_WEB_DESKTOP_OVERRIDE_ACTIVE, 'true');
	} catch (err) {
		logger.error`Unable to set local storage. key = "${MOBILE_WEB_DESKTOP_OVERRIDE_ACTIVE}", value = "true"`;
	}
};

export const deactivateDesktopOverride = () =>
	window.localStorage.removeItem(MOBILE_WEB_DESKTOP_OVERRIDE_ACTIVE);

export function shouldShowMobileWeb() {
	// for testing purposes, query param forces mobile web
	if (window.location.search.includes(MOBILE_WEB_QUERY_OVERRIDE)) {
		return true;
	}

	return isMobileUserAgent() && !isDesktopOverrideActive();
}

export const isNativeAndroid = () => {
	const ua = window.navigator.userAgent;

	return !!ua && NATIVE_ANDROID.test(ua);
};

export const isNativeIOS = () => {
	const ua = window.navigator.userAgent;

	return !!ua && NATIVE_IOS.test(ua);
};
