import React from 'react';

import GlobeIcon from '@atlaskit/icon/core/globe';
import AKTranslateIcon from '@atlaskit/icon/core/translate';

export const TranslateIcon = () => (
	<AKTranslateIcon
		label=""
		color="currentColor"
		spacing="spacious"
		LEGACY_fallbackIcon={() => <GlobeIcon label="" spacing="spacious" />}
	/>
);
