import { useQuery } from '@apollo/react-hooks';

import { usePageContentId } from '@confluence/page-context';
import { fg } from '@confluence/feature-gating';
import { useGetPageMode } from '@confluence/page-utils/entry-points/useGetPageMode';
import { PageMode } from '@confluence/page-utils/entry-points/enums';
import type {
	DetailsPanelContentCreatedDateQueryType,
	DetailsPanelContentCreatedDateQueryVariables,
} from '@confluence/details-panel-shared-queries';
import { DetailsPanelContentCreatedDateQuery } from '@confluence/details-panel-shared-queries';

const NESTED_COMMENTS_LAUNCH_DATE = new Date(Date.UTC(2025, 2, 6)); // 6th March 2025. Only month is 0 indexed.

export const useAllowNestedComments = () => {
	const [contentId] = usePageContentId();
	const pageMode = useGetPageMode();

	const {
		data: createdDateData,
		loading,
		error,
	} = useQuery<
		DetailsPanelContentCreatedDateQueryType,
		DetailsPanelContentCreatedDateQueryVariables
	>(
		// eslint-disable-next-line graphql-relay-compat/no-import-graphql-operations -- Read https://go/connie-relay-migration-fyi
		DetailsPanelContentCreatedDateQuery,
		{
			variables: {
				contentId: contentId ?? '',
				status: 'current',
			},
			skip: !contentId,
		},
	);

	// If the feature flag is not enabled, we should always allow nested comments
	if (!fg('confluence_frontend_comments_panel_v2')) {
		return true;
	}

	const pageCreatedDate = createdDateData?.content?.nodes?.[0]?.metadata.createdDate;

	const isLivePage = pageMode === PageMode.LIVE;

	if (loading || error || !pageCreatedDate) {
		return false;
	}

	const pageCreateDateObject = new Date(pageCreatedDate);

	const isBeforeLaunchDate = pageCreateDateObject < NESTED_COMMENTS_LAUNCH_DATE;

	return isBeforeLaunchDate && !isLivePage;
};
