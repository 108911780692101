import React, { useCallback, useEffect, useState } from 'react';

import { Box } from '@atlaskit/primitives';

import type { SpaceRole } from '@confluence/space-roles/entry-points/space-role-types';

import { ViewPermissionsInfoButton } from './ViewPermissionsInfoButton';
import { InfoPopupContent } from './InfoPopupContent';

export const RoleSelectInfoButton = ({
	currentRole,
	roleLookup,
}: {
	currentRole: SpaceRole | null;
	roleLookup: SpaceRole[];
}) => {
	const [isPopupOpen, setIsPopupOpen] = useState(false);

	const onInfoButtonClick = useCallback(() => {
		setIsPopupOpen(!isPopupOpen);
	}, [isPopupOpen]);

	const onPopupContentCtaClick = useCallback(() => {
		setIsPopupOpen(false);
	}, []);

	const popupRef = React.useRef<HTMLDivElement>(null);

	useEffect(() => {
		if (popupRef.current) {
			const handleClickOutside = (e: MouseEvent) => {
				if (popupRef.current && !popupRef.current.contains(e.target as Node)) {
					setIsPopupOpen(false);
				}
			};

			document.addEventListener('click', handleClickOutside);
			return () => document.removeEventListener('click', handleClickOutside);
		}
	});

	return (
		<>
			{currentRole ? (
				<Box ref={popupRef}>
					<ViewPermissionsInfoButton
						data-testid="role-selector-info-button"
						isOpen={isPopupOpen}
						onClick={onInfoButtonClick}
						content={
							<InfoPopupContent
								onCtaClick={onPopupContentCtaClick}
								roleId={currentRole.id}
								permissions={currentRole.permissions}
								roleLookup={roleLookup}
							/>
						}
					/>
				</Box>
			) : null}
		</>
	);
};
