import React from 'react';
import { defineMessages, FormattedMessage } from 'react-intl-next';

import Button from '@atlaskit/button';
import { ModalBody, ModalFooter, ModalHeader, ModalTitle } from '@atlaskit/modal-dialog';
import { Box, Stack, xcss } from '@atlaskit/primitives';

import { ScreenEvent } from '@confluence/analytics';

import { SpaceSelection } from '../page-components/SpaceSelectionRadioButtons';

const i18n = defineMessages({
	addConfirmationDialogTitle: {
		id: 'bulk-permissions.space.add.confirmation-dialog',
		defaultMessage: 'Add {principalName} to all spaces?',
		description:
			'Confirmation dialog title text with a user or group name injected into the message',
	},
	addAllExcludingPersonalConfirmationDialogDescription: {
		id: 'bulk-permissions.space.add-exclude-personal.confirmation-dialog-description',
		defaultMessage:
			'This will give them access to <b>all spaces</b> (excluding personal) on your site with the selected permissions.',
		description: 'Confirmation dialog description text',
	},
	addAllConfirmationDialogDescription: {
		id: 'bulk-permissions.space.add-all.confirmation-dialog-description',
		defaultMessage:
			'This will give them access to <b>all spaces</b> on your site with the selected permissions.',
		description: 'Confirmation dialog description text',
	},
	addAllPersonalConfirmationDialogDescription: {
		id: 'bulk-permissions.space.add-personal.confirmation-dialog-description',
		defaultMessage:
			'This will give them access to <b>all personal spaces</b>  on your site with the selected permissions.',
		description: 'Confirmation dialog description text',
	},
	confirmationDialogWarning: {
		id: 'bulk-permissions.space.confirmation-dialog-warning',
		defaultMessage: '<b>This can’t be undone.</b>',
		description: 'Confirmation dialog warning text',
	},
	removeConfirmationDialogTitle: {
		id: 'bulk-permissions.space.remove.confirmation-dialog',
		defaultMessage: 'Remove {principalName} from all spaces?',
		description: 'Confirmation dialog title text',
	},
	removeAllExcludingPersonalConfirmationDialogDescription: {
		id: 'bulk-permissions.space.remove-exclude-personal.confirmation-dialog-description',
		defaultMessage:
			'This will remove them from <b>all spaces</b> (excluding personal) on your site.',
		description: 'Confirmation dialog description text',
	},
	removeAllConfirmationDialogDescription: {
		id: 'bulk-permissions.space.remove-all.confirmation-dialog-description',
		defaultMessage: 'This will remove them from <b>all spaces</b> on your site.',
		description: 'Confirmation dialog description text',
	},
	removeAllPersonalConfirmationDialogDescription: {
		id: 'bulk-permissions.space.remove-personal.confirmation-dialog-description',
		defaultMessage: 'This will remove them from <b>all personal spaces</b> on your site.',
		description: 'Confirmation dialog description text',
	},
	cancelButtonText: {
		id: 'bulk-permissions.space.cancel-button-text',
		defaultMessage: 'Cancel',
		description: 'Cancel button text',
	},
	addButtonText: {
		id: 'bulk-permissions.space.add-button-text',
		defaultMessage: 'Add to all',
		description: 'Confirmation button text to add',
	},
	removeButtonText: {
		id: 'bulk-permissions.space.remove-button-text',
		defaultMessage: 'Remove access',
		description: 'Confirmation button text to remove',
	},
});

const ConfirmatonSubSection = xcss({
	paddingTop: 'space.300',
});

const boldString = (chunks: React.ReactNode[]) => <b>{chunks}</b>;

export const ChangeConfirmationDialog = ({
	principalName,
	isRemoval,
	spaceSelection,
	onConfirm,
	onCancel,
}: {
	principalName: string;
	isRemoval: boolean;
	spaceSelection: SpaceSelection;
	onConfirm: () => void;
	onCancel: () => void;
}) => {
	const getConfirmationDialogDescription = () => {
		if (isRemoval) {
			switch (spaceSelection) {
				case SpaceSelection.AllExcludingPersonal:
					return i18n.removeAllExcludingPersonalConfirmationDialogDescription;
				case SpaceSelection.AllIncludingPersonal:
					return i18n.removeAllConfirmationDialogDescription;
				case SpaceSelection.OnlyPersonal:
					return i18n.removeAllPersonalConfirmationDialogDescription;
			}
		} else {
			switch (spaceSelection) {
				case SpaceSelection.AllExcludingPersonal:
					return i18n.addAllExcludingPersonalConfirmationDialogDescription;
				case SpaceSelection.AllIncludingPersonal:
					return i18n.addAllConfirmationDialogDescription;
				case SpaceSelection.OnlyPersonal:
					return i18n.addAllPersonalConfirmationDialogDescription;
			}
		}
	};

	return (
		<>
			<ModalHeader>
				<ScreenEvent
					name="bulk-access-dialog"
					id="bulk-access-dialog"
					attributes={{ action: isRemoval ? 'remove' : 'add', spaceType: spaceSelection }}
				/>
				<ModalTitle appearance={isRemoval ? 'warning' : undefined}>
					<FormattedMessage
						{...(isRemoval ? i18n.removeConfirmationDialogTitle : i18n.addConfirmationDialogTitle)}
						values={{
							principalName,
						}}
					/>
				</ModalTitle>
			</ModalHeader>
			<ModalBody>
				<Box>
					<FormattedMessage
						{...getConfirmationDialogDescription()}
						values={{
							b: boldString,
						}}
					/>
					<Stack xcss={ConfirmatonSubSection}>
						<FormattedMessage
							{...i18n.confirmationDialogWarning}
							values={{
								b: boldString,
							}}
						/>
					</Stack>
				</Box>
			</ModalBody>
			<ModalFooter>
				<Button appearance="subtle" onClick={onCancel}>
					<FormattedMessage {...i18n.cancelButtonText} />
				</Button>
				<Button
					testId="confirmation-dialog-confirm-button"
					appearance={isRemoval ? 'warning' : 'primary'}
					onClick={onConfirm}
				>
					<FormattedMessage {...(isRemoval ? i18n.removeButtonText : i18n.addButtonText)} />
				</Button>
			</ModalFooter>
		</>
	);
};
