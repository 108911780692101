import React from 'react';

import type { RouteMatch } from '@confluence/route';
import { ViewPagePdfExportLoader } from '@confluence/pdf-export';

import { CommentsSectionLoader } from '../../CommentsSection';
import { ContentExtensionsProvider } from '../../ContentExtensionsProvider';
import SpaceBase from '../../SpaceBase';

export const PdfExportPageRoute = ({ params: { contentId, spaceKey } }: RouteMatch) => (
	<SpaceBase>
		<ContentExtensionsProvider>
			<ViewPagePdfExportLoader
				contentId={String(contentId)}
				spaceKey={String(spaceKey)}
				classicComments={CommentsSectionLoader}
			/>
		</ContentExtensionsProvider>
	</SpaceBase>
);

PdfExportPageRoute.NAVIGATION_PARAMS = ({ name, params: { contentId, spaceKey } }: RouteMatch) => ({
	Screen: {
		pageState: {
			contentId,
			routeName: name,
			spaceKey,
		},
		screenEvent: { name: 'pdfExportScreen' },
	},
	MainLayout: {
		enableNavigation: false,
		spaceKey,
	},
});
