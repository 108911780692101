import React from 'react';

import type { RouteMatch } from '@confluence/route';
import {
	CONTAINER_ADMIN_HOME_ID,
	CONTAINER_HOME_ID,
} from '@confluence/app-navigation/entry-points/navigationIDs';
import { LoadablePaint } from '@confluence/loadable';
import { shouldShowMobileWeb } from '@confluence/mobile-detection';

import SpaceBase from '../../SpaceBase';

const ArchivedPagesListContainer = LoadablePaint({
	loader: async () =>
		(
			await import(
				/* webpackChunkName: "loadable-ArchivedPagesListContainer" */ '@confluence/archived-pages-list/entry-points/ArchivedPagesListContainer'
			)
		).ArchivedPagesListContainer,
});

export const SpaceArchivedPagesRoute = ({ params: { spaceKey } }: RouteMatch) => {
	return (
		<SpaceBase>
			<ArchivedPagesListContainer spaceKey={spaceKey} />
		</SpaceBase>
	);
};

SpaceArchivedPagesRoute.NAVIGATION_PARAMS = (
	{ name, params: { spaceKey } }: RouteMatch,
	isNav4Enabled: boolean,
) => {
	const isMobile = shouldShowMobileWeb();
	return {
		Screen: {
			screenEvent: {
				name: 'spaceArchivedPages',
				id: spaceKey,
			},
			pageState: {
				routeName: name,
				spaceKey,
			},
		},
		MainLayout: {
			navView: isNav4Enabled ? CONTAINER_ADMIN_HOME_ID : CONTAINER_HOME_ID,
			spaceKey,
			enableNavigation: !isMobile,
		},
	};
};
