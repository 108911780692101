import { getForgeUIExtensionsAsync } from '../../../../provider/getForgeUIExtensionsAsync';
import { type ViewContext } from '../../../iframe/types';

type GetForgeUIExtensionsAsync = typeof getForgeUIExtensionsAsync;

export type TargetURLUtils = {
	getContext: () => Promise<ViewContext>;
	getAppForgeUIExtensions: (
		param: Omit<
			Parameters<GetForgeUIExtensionsAsync>[0],
			'client' | 'contextIds' | 'moduleType' | 'extensionsFilter'
		>,
	) => ReturnType<GetForgeUIExtensionsAsync>;
};

export const getModuleByKey = async (
	getAppForgeUIExtensions: TargetURLUtils['getAppForgeUIExtensions'],
	moduleKey: string,
) => {
	const { extensions } = await getAppForgeUIExtensions({
		expandAppOwner: false,
	});
	const module = extensions?.find(({ key }) => key === moduleKey);

	return module?.type ? module : null;
};

export const NavigationError = {
	ModuleNotFound: (moduleKey: string) => {
		return new Error(`Navigation failed: Module with moduleKey ${moduleKey} was not found`);
	},
	MissingProperty: (property: string) => {
		return new Error(`Navigation failed: ${property} not provided`);
	},
	FailedToFetchContentInfo: (contentId: string) => {
		return new Error(
			`Navigation failed: Failed to fetch content information with id ${contentId}. Please check if the ID provided is correct`,
		);
	},
	NotSupportedExtensionType: (moduleType: string) => {
		return new Error(`Navigation failed: Module type ${moduleType} is not supported`);
	},
	InvalidValueForProperty: (property: string, value: string) => {
		return new Error(`Navigation failed: ${value} is not a valid value for ${property}`);
	},
	LinkForContentNotFound: (contentId: string) => {
		return new Error(`Navigation failed: Link for content with id ${contentId} was not found`);
	},
};

export const ensurePropertyExists = (propertyName: string, propertyValue: string | undefined) => {
	if (propertyValue === undefined || propertyValue === null) {
		throw NavigationError.MissingProperty(propertyName);
	}
	return propertyValue;
};
