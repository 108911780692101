import { useMemo, useRef } from 'react';
import { getForgeUIExtensionsAsync } from '../../../provider/getForgeUIExtensionsAsync';
import { GQLExtensionContextsFilterType } from '../../../web-client';
import ApolloClient from 'apollo-client';
import { type TargetURLUtils } from './navigation-targets/common';
import isEqual from 'lodash/isEqual';
import { fg } from '@atlaskit/platform-feature-flags';

const useCustomCompareMemo = <T, D>(
	factory: () => T,
	dependency: D,
	isEqual: (a: D, b: D) => boolean,
): T => {
	const dependencyRef = useRef<D>(dependency);
	const valueRef = useRef<T>(factory());

	if (!isEqual(dependency, dependencyRef.current)) {
		dependencyRef.current = dependency;
		valueRef.current = factory();
	}

	// eslint-disable-next-line react-hooks/exhaustive-deps
	return useMemo(() => valueRef.current as T, [dependencyRef.current]);
};

type UseGetAppForgeUIExtensionsParams = {
	client: ApolloClient<object>;
	appId?: string;
	environmentId?: string;
	contextIds: string[];
};

type GetAppForgeUIExtensionDeps = [
	ApolloClient<object>,
	string | undefined,
	string | undefined,
	string[],
];

const isSameDeps = (a: GetAppForgeUIExtensionDeps, b: GetAppForgeUIExtensionDeps) => {
	if (a === b) {
		return true;
	}
	if (!a || !b) {
		return false;
	}

	const [clientA, appIdA, environmentIdA, contextIdsA] = a;
	const [clientB, appIdB, environmentIdB, contextIdsB] = b;

	return (
		clientA === clientB &&
		appIdA === appIdB &&
		environmentIdA === environmentIdB &&
		isEqual(contextIdsA.sort(), contextIdsB.sort())
	);
};

export const useGetAppForgeUIExtensionsBase = ({
	client,
	appId,
	environmentId,
	contextIds,
}: UseGetAppForgeUIExtensionsParams) => {
	return useCustomCompareMemo<
		TargetURLUtils['getAppForgeUIExtensions'],
		GetAppForgeUIExtensionDeps
	>(
		() => {
			return async (params) => {
				return await getForgeUIExtensionsAsync({
					client,
					contextIds,
					extensionsFilter: [
						{
							type: GQLExtensionContextsFilterType.AppIdEnvironmentId,
							value: `${appId}:${environmentId}`,
						},
					],
					...params,
				});
			};
		},
		[client, appId, environmentId, contextIds],
		isSameDeps,
	);
};

export const useGetAppForgeUIExtensions = (params: UseGetAppForgeUIExtensionsParams) => {
	const getAppForgeUIExtensions = useGetAppForgeUIExtensionsBase(params);

	return fg('forge-ui-navigation') ? getAppForgeUIExtensions : null;
};
