// NOTE: These constants are alphabetised by value to help avoid duplication.
export const LABELS_AUTOCOMPLETE =
	'/labels/autocompletelabel.action?maxResults=3&query=:query&contentId=:contentId';
export const CONTENT_CREATE = '/rest/api/content';
export const DELETE_CONTENT = '/rest/api/content/:contentId/?status=:status';
export const EDIT_PAGE_METADATA = '/rest/api/content/:contentId/?expand=ancestors,version';
export const DRAFT_METADATA = '/rest/api/content/:contentId/?status=draft&expand=ancestors,space';
export const DRAFT_SYNC = '/rest/api/content/:contentId/?status=draft&action=sync';
export const CONTENT_PUBLISH = '/rest/api/content/:contentId/?status=draft&action=publish';
export const CONTENT_FROM_TEMPLATE_PUBLISH =
	'/rest/api/content/blueprint/instance/:contentId?status=draft';
export const EDIT_PAGE_PROPERTIES = '/rest/api/content/:contentId/property/:propertyKey';
/**
 * Legacy view page and blog post routes had the slug generated based on title when there were no
 * special chars. So "my confluence page" would become "my+confluence+page". In this
 * circumstance, it's safe to assume that slug = title (using + instead of spaces to make it readable after encoding).
 * Because confluence doesn't allow 2 pages with the same title (and created-date for blog posts) within a space,
 * performing a search by exact title passing the space as parameter should return only one result.
 * This endpoint is used only during the client side redirect from legacy view page
 * route (/display/{spaceKey}/{pageSlug}) to the new one.
 */
export const SPACE = '/rest/api/space?spaceKey=:spaceKey&expand=:expand';
export const RELATION_ENTITIES =
	'/rest/experimental/relation/:sourceType/:sourceKey/:relationName/to:targetType/:targetKey';
export const FEATURE_DISCOVERY = '/rest/feature-discovery/1.0/discovered/:pluginKey/:featureKey';
export const EDITOR_PAGE =
	'/rest/tinymce/1/content/:contentId.json?draftShareId=:draftShareId&_=:timestamp';
export const RENDER_CONTENT = '/pages/rendercontent.action';
export const REVERT_CONTENT = '/rest/synchrony/1.0/content/:contentId/changes/unpublished';
export const CANCEL_SCHEDULED_PUBLISH = '/rest/api/content/:contentId/schedule/publish';
