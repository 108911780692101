import { useRef } from 'react';
import isEqual from 'lodash/isEqual';
import type { Features } from '../../types';

const featuresAreEqual = (_previousFeatures?: Features, _currentFeatures?: Features) => {
	// If both are references point to the same object, return true
	if (_previousFeatures === _currentFeatures) {
		return true;
	}

	const previousFeatures = _previousFeatures || {};
	const currentFeatures = _currentFeatures || {};

	if (Object.keys(previousFeatures).length !== Object.keys(currentFeatures).length) {
		return false;
	}

	// if any properties has changed, return false
	for (const key in previousFeatures) {
		if (!isEqual(previousFeatures[key as keyof Features], currentFeatures[key as keyof Features])) {
			return false;
		}
	}
	return true;
};

export const useMemoisedFeatures = (features?: Features) => {
	const previousFeatures = useRef<Features | undefined>();
	const shouldUpdate = !featuresAreEqual(previousFeatures.current, features);
	if (shouldUpdate) {
		previousFeatures.current = features;
	}
	return previousFeatures.current;
};
