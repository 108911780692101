import React from 'react';

import { SiteAnalyticsPageLoader } from '@confluence/confluence-analytics/entry-points/AnalyticsPagesLoaders';
import { ADMIN_HOME_ID } from '@confluence/app-navigation/entry-points/navigationIDs';
import type { RouteMatch } from '@confluence/route';

export const SiteAnalyticsRoute = () => {
	return <SiteAnalyticsPageLoader />;
};

SiteAnalyticsRoute.NAVIGATION_PARAMS = ({ name, params: { tab } }: RouteMatch) => {
	return {
		Screen: {
			// screenEvent will be handled in the page components
			pageState: {
				routeName: name,
				tab,
			},
		},
		MainLayout: {
			navView: ADMIN_HOME_ID,
		},
	};
};
