import FeatureGates from '@atlaskit/feature-gate-js-client';

export const getExperimentalPlaceholderPromptConfiguration = () => {
	const getExperimentValue = FeatureGates.getExperimentValue;

	const variation = getExperimentValue<'control' | 'not-enrolled'>(
		'platform_editor_ai-prompts-placeholder',
		'cohort',
		'not-enrolled',
	);

	if (variation !== 'control' && variation !== 'not-enrolled') {
		const label = getExperimentValue('platform_editor_ai-prompts-placeholder', 'label', '');
		const description = getExperimentValue(
			'platform_editor_ai-prompts-placeholder',
			'description',
			'',
		);
		const intent = getExperimentValue('platform_editor_ai-prompts-placeholder', 'intent', '');
		const listOrder = getExperimentValue('platform_editor_ai-prompts-placeholder', 'list_order', 0);
		const analyticsExperienceName = getExperimentValue(
			'platform_editor_ai-prompts-placeholder',
			'analytics_experience_name',
			'',
		);

		// The follow are optional backwards compatible properties which can be configured in the experiment
		const showOnEmpty = getExperimentValue(
			'platform_editor_ai-prompts-placeholder',
			'show_on_empty_page',
			true,
		);
		const statusType = getExperimentValue(
			'platform_editor_ai-prompts-placeholder',
			'prompt_status_type',
			'',
		);

		if (label && description && intent && analyticsExperienceName) {
			return {
				label: {
					id: label,
					description: label,
					defaultMessage: label,
				},
				description: {
					id: description,
					description: description,
					defaultMessage: description,
				},
				intent,
				listOrder,
				analyticsExperienceName,
				showOnEmpty,
				statusType,
			};
		}
	}
};
