import PropTypes from 'prop-types';
import React from 'react';

import { SpaceRestrictionHandler } from '@confluence/space-base';

const SpaceRestriction = ({ spaceKey, children }) => {
	if (!spaceKey) {
		return children;
	}

	return <SpaceRestrictionHandler spaceKey={spaceKey}>{children}</SpaceRestrictionHandler>;
};

SpaceRestriction.propTypes = {
	spaceKey: PropTypes.string,
	children: PropTypes.node.isRequired,
};

export default SpaceRestriction;
