import React from 'react';
import { FormattedMessage, defineMessages } from 'react-intl-next';

import Link from '@atlaskit/link';

type RenderFailureErrorMessageProps = {
	ssrErrors: string[];
	isBranchDeployFailure: boolean;
};

export const i18n = defineMessages({
	genericSSRErrorFlagReason: {
		id: 'ssr-render-failure-notification.flag.generic.message',
		defaultMessage: 'Reason: {reason}. ',
		description:
			'Reason to be shown when the SSR render failure flag is showing due to a generic SSR render failure.',
	},
	branchDeployErrorFlagReason: {
		id: 'ssr-render-failure-notification.flag.branch.deploy.message',
		defaultMessage: `Possible outdated branch deploy detected. Your PR changes might not be tested against SSR accurately. `,
		description:
			'Reason to be shown when the SSR render failure flag is showing due to branch deploy error.',
	},
	runbookLink: {
		id: 'ssr-render-failure-notification.runbook.link',
		defaultMessage:
			'See <runbookLink>this page</runbookLink> for information on how to debug this failure.',
		description: 'Describes how to access runbook pages on debuging SSR render failures.',
	},
});

const BRANCH_DEPLOY_RUNBOOK_LINK = `https://hello.atlassian.net/wiki/spaces/CCPERF/pages/4898451942/Branch+Deploys`;
const GENERIC_SSR_FAILURE_RUNBOOK_LINK = `https://hello.atlassian.net/wiki/spaces/CCPERF/pages/4911008649/SSR+Render+Failures`;

export const RenderFailureErrorMessage = ({
	ssrErrors,
	isBranchDeployFailure,
}: RenderFailureErrorMessageProps) => {
	const runbookLink = isBranchDeployFailure
		? BRANCH_DEPLOY_RUNBOOK_LINK
		: GENERIC_SSR_FAILURE_RUNBOOK_LINK;

	return (
		<span data-testid="flagErrorMessage">
			<b>
				{isBranchDeployFailure ? (
					<FormattedMessage {...i18n.branchDeployErrorFlagReason} />
				) : (
					<FormattedMessage
						{...i18n.genericSSRErrorFlagReason}
						values={{ reason: ssrErrors[0] }} // since `ssrErrors` is an array of string errors, report the first one in the flag message. At this point, ssrErrors will always have at least 1 error for the flag to show
					/>
				)}
			</b>{' '}
			<FormattedMessage
				{...i18n.runbookLink}
				values={{
					runbookLink: (chunks: React.ReactNode[]) => (
						<Link target="_blank" href={runbookLink}>
							{chunks}
						</Link>
					),
				}}
			/>
		</span>
	);
};
