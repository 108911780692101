import React from 'react';

import {
	PRODUCT_HOME_ID,
	COMPANY_HUB_SETTINGS_ID,
} from '@confluence/app-navigation/entry-points/navigationIDs';
import { CompanyHubSettingsPage } from '@confluence/company-hub/entry-points/CompanyHubSettingsPage';
import { COMPANY_HUB_SETTINGS } from '@confluence/named-routes';
import { getCompanyHubSpaceKey } from '@confluence/route-manager/entry-points/companyHubUtils';
import { getCompanyHubPseudoAppRoute } from '@confluence/ssr-app/entry-points/getCompanyHubPseudoAppRoute';

/**
 * The route "Company Hub settings" in the Confluence app.
 * `CompanyHubSettingsRoute` wraps this one to implement the Company Hub pseudo app.
 */
const ConfluenceAppHubSettingsRoute = () => <CompanyHubSettingsPage />;

ConfluenceAppHubSettingsRoute.NAVIGATION_PARAMS = (_routeMatch, isNav4Enabled: boolean) => {
	const navView = isNav4Enabled ? COMPANY_HUB_SETTINGS_ID : PRODUCT_HOME_ID;

	return {
		Screen: {
			screenEvent: {
				name: 'companyHubSettingsScreen',
				attributes: {
					// Company Hub hides its space from the user, doesn't refer to its page
					// as such, is rather presented to the user as "a new surface", and thus
					// doesn't constitute space usage from the perspective of the user.
					screenIsInSpace: false,
				},
			},
			pageState: {
				routeName: COMPANY_HUB_SETTINGS.name,
				spaceKey: getCompanyHubSpaceKey(),
			},
		},
		MainLayout: {
			navView,
		},
	};
};

/**
 * The route "Company Hub settings" in the Company Hub pseudo app if enabled; otherwise, in the Confluence app.
 */
export const CompanyHubSettingsRoute = getCompanyHubPseudoAppRoute(ConfluenceAppHubSettingsRoute);
