/**
 * @jsxRuntime classic
 * @jsx jsx
 */
// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import { jsx } from '@emotion/react';
import React, { lazy, Fragment } from 'react';
import { type FieldChildrenProps, type TextFieldProps } from '@atlassian/forge-ui-types';
import { type Props } from '../..';
import { makeValidate } from './validators';
import { FormStateChangeNotifier } from '../form';
import { FieldContainer } from '../utils/FieldContainer';

const AKTextField = lazy(
	() =>
		import(
			/* webpackChunkName: '@atlaskit-internal_.textfield' */
			'@atlaskit/textfield'
		),
);

const AKErrorMessage = lazy(() =>
	import(
		/* webpackChunkName: '@atlaskit-internal_.form' */
		'@atlaskit/form'
	).then((module) => ({ default: module.ErrorMessage })),
);

const AKField = React.lazy(() =>
	import(
		/* webpackChunkName: '@atlaskit-internal_.form' */
		'@atlaskit/form'
	).then((module) => ({ default: module.Field })),
);

const AKHelperMessage = React.lazy(() =>
	import(
		/* webpackChunkName: '@atlaskit-internal_.form' */
		'@atlaskit/form'
	).then((module) => ({
		default: module.HelperMessage,
	})),
);

const isValidType = (type: string) => {
	return ['text', 'number', 'email', 'tel', 'password'].includes(type);
};

function TextField({
	type = 'text',
	name,
	label,
	description, // description about the field
	placeholder,
	isRequired = false,
	defaultValue = '',
	testId,
	autoComplete,
}: TextFieldProps & { testId?: string }) {
	const validate = makeValidate(isRequired, type);

	// WARNING: for <input type=number> and React, native validation is strange, use type text
	const fieldType = !isValidType(type) || type === 'number' ? 'text' : type;

	return (
		<FieldContainer>
			<AKField
				name={name}
				label={label}
				defaultValue={defaultValue}
				isRequired={isRequired}
				validate={validate}
			>
				{({ fieldProps }: FieldChildrenProps) => {
					const {
						id,
						isDisabled,
						isInvalid,
						onBlur,
						onFocus,
						value,
						onChange,
						'aria-labelledby': ariaLabelledBy,
					} = fieldProps;
					const error = isInvalid ? validate(value) : null;
					return (
						<Fragment>
							<FormStateChangeNotifier name={name} value={value} />
							<AKTextField
								type={fieldType}
								testId={testId}
								placeholder={placeholder}
								autoComplete={autoComplete}
								onFocus={onFocus}
								onBlur={onBlur}
								isDisabled={isDisabled}
								id={id}
								isInvalid={isInvalid}
								aria-labelledby={ariaLabelledBy}
								defaultValue={defaultValue}
								onChange={onChange}
							/>
							{error ? <AKErrorMessage>{error}</AKErrorMessage> : null}
							{description ? <AKHelperMessage>{description}</AKHelperMessage> : null}
						</Fragment>
					);
				}}
			</AKField>
		</FieldContainer>
	);
}

export default TextField;

export const TextFieldFn = ({ props }: Props) => {
	const { name, label, type, description, defaultValue, isRequired, placeholder, autoComplete } =
		props as TextFieldProps;
	return (
		<TextField
			type={type}
			name={name}
			label={label}
			defaultValue={defaultValue}
			isRequired={isRequired}
			description={description}
			placeholder={placeholder}
			autoComplete={autoComplete}
		/>
	);
};
