import gql from 'graphql-tag';

export const PageCardContentFragment = gql`
	fragment PageCardContentFragment on Content {
		id
		title
		type
		subType
		links {
			webui
			editui
		}
		excerpt(length: 372)
		metadata {
			currentuser {
				favourited {
					isFavourite
				}
			}
			comments {
				commentsCount
			}
		}
		space {
			alias
			id
			key
			name
		}
		likes {
			count
		}
	}
`;
