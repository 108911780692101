import React from 'react';

import { aiConfigItemMessages, aiMessages } from '@atlaskit/editor-common/ai-messages';
import { fg } from '@atlaskit/platform-feature-flags';

import { SummarizeIcon } from '../../../../icons/prompt/summarize';
import {
	createInsertAtCurrentPosition,
	createInsertBelow,
	createReplace,
} from '../../actions/create-actions';
import { CONFIG_ITEM_KEYS } from '../../config-item-keys';
import type {
	EditorPluginAIConfigItemWithOptions,
	EditorPluginAIConfigItem,
} from '../../config-items';

export const summarizeWriting: EditorPluginAIConfigItem = {
	key: CONFIG_ITEM_KEYS.SUMMARISE_WRITING,
	title: aiConfigItemMessages.summarizeWritingtitle,
	description: aiConfigItemMessages.summarizeWritingDescription,
	icon: () => <SummarizeIcon />,
	showInRefineDropdown: true,
	intentSchemaId: 'summarize_intent_schema.json',
	actions: {
		empty: [createInsertAtCurrentPosition({ appearance: 'primary' })],
		selection: [
			createInsertBelow({ appearance: 'secondary' }),
			createReplace({ appearance: 'primary' }),
		],
	},
	getBackendModel: () => {
		if (
			fg('platform_editor_ai_assistance_service') ||
			fg('platform_editor_ai_assistance_service_second')
		) {
			return 'assistance-service';
		}

		return 'xp-gen-ai';
	},
};

export const summarizeWritingWithOptions: EditorPluginAIConfigItemWithOptions = {
	config: summarizeWriting,
	triggers: { empty: { enabled: true, docMinSize: 1 }, selection: { enabled: true } },
};

export const summarizeWritingWithOptionsForAtlas: EditorPluginAIConfigItemWithOptions = {
	config: { ...summarizeWriting, description: aiMessages.atlasSummarizePageConfigItemDescription },
	triggers: { empty: { enabled: true, docMinSize: 1 }, selection: { enabled: false } },
};
