import { defineMessages } from 'react-intl-next';

export const i18n = defineMessages({
	allTemplates: {
		id: 'blank-page-quick-actions.allTemplates',
		defaultMessage: 'All templates',
		description: 'The text for blank page quick actions all templates link',
	},
	moreElements: {
		id: 'blank-page-quick-actions.moreElements',
		defaultMessage: 'More elements',
		description: 'The text for blank page quick actions more elements link',
	},
	helperText: {
		id: 'blank-page-quick-actions.helperText',
		defaultMessage: 'Select an element to get started, or just type',
		description: 'The helper text for blank page quick actions',
	},
	brainstormName: {
		id: 'blank-page-quick-actions.brainstormName',
		defaultMessage: 'Brainstorm',
		description: 'Name of the Brainstorm element',
	},
	brainstormDescription: {
		id: 'blank-page-quick-actions.brainstormDescription',
		defaultMessage: 'Use Brainstorm to insert content',
		description: 'Description for Brainstorm element',
	},
	brainstormAltText: {
		id: 'blank-page-quick-actions.brainstormAltText',
		defaultMessage: 'Use Brainstorm to insert content',
		description: 'Alt tag for the Brainstorm element',
	},
	startWithAI: {
		id: 'blank-page-quick-actions.startWithAI',
		defaultMessage: 'Start with AI',
		description: 'Label of button that allows users to open the Atlassian Intelligence modal',
	},
	tableName: {
		id: 'blank-page-quick-actions.tableName',
		defaultMessage: 'Table',
		description: 'Name of the table element',
	},
	tableDescription: {
		id: 'blank-page-quick-actions.tableDescription',
		defaultMessage: 'Insert a table',
		description: 'Description for table element',
	},
	tableAltText: {
		id: 'blank-page-quick-actions.tableAltText',
		defaultMessage: 'Insert a table',
		description: 'Alt tag for the table element',
	},
	infoPanelName: {
		id: 'blank-page-quick-actions.infoPanelName',
		defaultMessage: 'Info panel',
		description: 'Name of the info panel element',
	},
	infoPanelDescription: {
		id: 'blank-page-quick-actions.infoPanelDescription',
		defaultMessage: 'Highlight information in a colored panel',
		description: 'Description for info panel element',
	},
	infoPanelAltText: {
		id: 'blank-page-quick-actions.infoPanelAltText',
		defaultMessage: 'Insert an info panel to highlight information in a colored panel',
		description: 'Alt tag for the info panel element',
	},
	tableOfContentsName: {
		id: 'blank-page-quick-actions.tableOfContentsName',
		defaultMessage: 'Table of contents',
		description: 'Name of the table of contents macro',
	},
	tableOfContentsDescription: {
		id: 'blank-page-quick-actions.tableOfContentsDescription',
		defaultMessage: 'Create a table of contents based on your content’s headings',
		description: 'Description for table of contents macro',
	},
	tableOfContentsAltText: {
		id: 'blank-page-quick-actions.tableOfContentsAltText',
		defaultMessage: 'Insert a table of contents',
		description: 'Alt tag for table of contents macro',
	},
	tableOfContentsTitle: {
		id: 'blank-page-quick-actions.tableOfContentsTitle',
		defaultMessage: 'Table of Contents',
		description: 'Title for table of contents macro for macro panel',
	},
	smartButtonName: {
		id: 'blank-page-quick-actions.smartButtonName',
		defaultMessage: 'Smart button',
		description: 'Name of smart button extension',
	},
	smartButtonAltText: {
		id: 'blank-page-quick-actions.smartButtonAltText',
		defaultMessage: 'Insert a smart button',
		description: 'Description for smart button extension',
	},
	smartButtonTitle: {
		id: 'blank-page-quick-actions.smartButtonTitle',
		defaultMessage: 'Smart button',
		description: 'Title for smart button extension',
	},
	templateLoadError: {
		id: 'blank-page-quick-actions.templateLoadError',
		defaultMessage:
			'We’re having trouble loading suggested templates - refresh, or go to <button>all templates.</button>',
		description: 'Error message shown when we cannot load suggested templates',
	},
	templateSuccessFlagTitle: {
		id: 'blank-page-quick-actions.templateSuccessFlagTitle',
		defaultMessage: '“{templateName}” template chosen',
		description: 'Title for success flag shown when a user successfully chooses a template.',
	},
	templateSuccessFlagDescription: {
		id: 'blank-page-quick-actions.templateSuccessFlagDescription',
		defaultMessage: 'You can change templates any time.',
		description: 'Description for success flag shown when a user successfully chooses a template.',
	},
	templateSuccessFlagBrowserOtherTemplatesLink: {
		id: 'blank-page-quick-actions.templateSuccessFlagBrowserOtherTemplatesLink',
		defaultMessage: 'Browse other templates',
		description: 'Description for success flag link to browse other templates.',
	},
	templateSuccessFlagClearEditorLink: {
		id: 'blank-page-quick-actions.templateSuccessFlagClearEditorLink',
		defaultMessage: 'Clear all',
		description: 'Description for success flag link to clear editor contents.',
	},
});
