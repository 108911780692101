/**
 * @jsxRuntime classic
 * @jsx jsx
 */
import { jsx } from '@compiled/react';
import type ApolloClient from 'apollo-client';
import { type Extension } from '@atlassian/forge-ui/ui';

import { type CoreData, type ExtensionData } from '@atlassian/forge-ui-types';
import { useInvokeAdfExportExtension } from '../../utils/useInvokeAdfExportExtension';
import { ReactRenderer } from '@atlaskit/renderer';
import { type ProviderFactory } from '@atlaskit/editor-common/provider-factory';
import { RendererActionsContext } from '@atlaskit/renderer/actions';
import { Loader } from '@atlassian/forge-ui/ui';
import { useIntl } from 'react-intl-next';
import SectionMessage from '@atlaskit/section-message';
import { messages } from '../i18n';

interface AdfExportRendererProps {
	accountId: string;
	apolloClient: ApolloClient<object>;
	contextIds: string[];
	coreData: CoreData;
	dataProviders: ProviderFactory;
	extension: Extension;
	extensionData: ExtensionData;
	extensionPayload: Record<string, any>;
	extensionId: string;
	extensionTitle?: string;
}

export const AdfExportRenderer = ({
	accountId,
	apolloClient,
	contextIds,
	coreData,
	dataProviders,
	extensionData,
	extensionId,
	extensionPayload,
}: AdfExportRendererProps) => {
	const { adfDoc, error, isLoading } = useInvokeAdfExportExtension({
		accountId,
		apolloClient,
		contextIds,
		extensionId,
		coreData,
		extensionData,
		extensionPayload,
	});
	const intl = useIntl();

	if (error) {
		return (
			<SectionMessage appearance="error">
				{intl.formatMessage(messages.exportMacroError)}
			</SectionMessage>
		);
	}

	if (isLoading) {
		return <Loader />;
	}

	if (!adfDoc) {
		return null;
	}

	return (
		<RendererActionsContext>
			<ReactRenderer dataProviders={dataProviders} document={adfDoc} />
		</RendererActionsContext>
	);
};
