import { preloadContentTypesHeader } from '@confluence/content-types-header/entry-points/preloadContentTypesHeader';
import { preloadSpaceViews } from '@confluence/space-views/entry-points/preloadSpaceViews';
import { preloadShortcutsSmartLinks } from '@confluence/space-shortcuts/entry-points/preloadShortcutsSmartLinks';
import {
	preloadSideNavigation,
	preloadSideNavigationNeedsContentId,
} from '@confluence/side-navigation/entry-points/preloadSideNavigation';
import { preloadSpaceGuestList } from '@confluence/space-guest-list/entry-points/preloadSpaceGuestList';
import { preloadDatabaseData } from '@confluence/confluence-databases/entry-points/preloadDatabaseData';
import { getPreloaderFnContext } from '@confluence/query-preloader-tools';
import type { RouteMatch } from '@confluence/route';
import { fg } from '@confluence/feature-gating';
import { preloadShareAndRestrictButtonQuery } from '@confluence/share-and-restrict-dialog/entry-points/preloadShareAndRestrictButtonQuery';
export const preloadDatabaseRoute = async (
	match: RouteMatch,
	_url: string,
	isTransition: boolean = false,
) => {
	const { isLicensed } = await getPreloaderFnContext();
	const tasks: Promise<any>[] = [];
	const { spaceKey, contentId } = match.params;

	// eslint-disable-next-line check-react-ssr-usage/no-react-ssr
	if (process.env.REACT_SSR) {
		tasks.push(
			preloadSideNavigation(spaceKey),
			preloadSideNavigationNeedsContentId(spaceKey, contentId),
			preloadSpaceGuestList(spaceKey),
			preloadShortcutsSmartLinks(spaceKey),
		);
	} else if (!isTransition) {
		tasks.push(preloadSpaceViews(spaceKey, isLicensed));
	}

	if (fg('confluence_frontend_object_header')) {
		tasks.push(preloadContentTypesHeader(contentId, fg('confluence_frontend_object_header')));
		// (No need to call preloadPageRestrictionsQuery() here; the item's restriction data will be
		//  loaded by preloadDatabaseData() below)
	}

	// Unified Share and Restrict Button
	if (fg('confluence_frontend_usd_ssr')) {
		tasks.push(preloadShareAndRestrictButtonQuery(contentId));
	}

	tasks.push(preloadDatabaseData(spaceKey, contentId));

	return Promise.all(tasks);
};
