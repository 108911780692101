import type { HeadingProps as PlatformHeadingProps } from '@atlaskit/heading';
import { type ComponentPropsConfig } from '../../../utils/codegen';

export const componentPropsConfig: ComponentPropsConfig = {
	size: {
		excludeFromDeconstruct: false,
		value: 'size ? size : getSize(as)',
	},
	color: {
		excludeFromDeconstruct: false,
		value: 'getColor(color)',
	},
	as: {
		excludeFromDeconstruct: false,
		value: 'onlyAllowHeadingTag(as)',
	},
	children: true,
	id: true,
	testId: true,
};

type HeadingTags = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'div' | 'span';
type Sizes = 'xxlarge' | 'xlarge' | 'large' | 'medium' | 'small' | 'xsmall' | 'xxsmall';
/**
 * @deprecated
 * The original colors supported by the color prop.
 */
type TemporaryColors = 'default' | 'inverse';
//
/**
 * The new values supported by the platform color prop
 */
type NewColors = 'color.text' | 'color.text.inverse' | 'color.text.warning.inverse';

export type HeadingProps = Pick<PlatformHeadingProps, 'children' | 'id' | 'testId' | 'as'> & {
	size?: Sizes;
	color?: NewColors | TemporaryColors;
};

const onlyAllowHeadingTag = (asProp: string | undefined): HeadingTags | undefined => {
	if (!asProp) {
		return undefined;
	}
	if (asProp && ['h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'div', 'span'].includes(asProp)) {
		return asProp as HeadingTags;
	}
	throw Error(
		`${asProp} is an invalid heading tag. Please use one of h1, h2, h3, h4, h5, h6, div, span.`,
	);
};

const getSize = (asProp: HeadingTags | undefined) => {
	if (!asProp) {
		return 'xlarge';
	}
	const sizeMap: Record<string, Sizes> = {
		h1: 'xlarge',
		h2: 'large',
		h3: 'medium',
		h4: 'small',
		h5: 'xsmall',
		h6: 'xxsmall',
	};
	return sizeMap[asProp];
};

// Exists to map old Heading color API to new API
const getColor = (colorProp?: TemporaryColors | NewColors) => {
	if (!colorProp) {
		return undefined;
	}

	const colorMap: Record<string, PlatformHeadingProps['color']> = {
		default: 'color.text',
		inverse: 'color.text.inverse',
		'color.text': 'color.text',
		'color.text.inverse': 'color.text.inverse',
		'color.text.warning.inverse': 'color.text.warning.inverse',
	};

	return colorMap[colorProp] as NewColors;
};

/**
 * THIS SECTION WAS CREATED VIA CODEGEN DO NOT MODIFY {@see http://go/af-codegen}
 *
 * This component was generated against an existing platform component.
 * If you are seeing lint errors in this file, it means that this file's dependencies have been modified and the component will need to be regenerated.
 *
 * Please view https://hello.atlassian.net/wiki/spaces/ECO/pages/3358130330/How+to+Regenerate+UI+Kit+Components for more information on how to proceed.
 *
 * For further questions, please ping the #help-forge-ui channel.
 *
 * @codegen <<SignedSource::597e9da950b5756eadf13f4990492831>>
 * @codegenId #heading
 * @codegenCommand yarn workspace @atlassian/forge-ui codegen heading
 * @codegenParams { "defineOwnType": true }
 * @codegenDependency ../../../../../../../design-system/heading/src/heading.partial.tsx <<SignedSource::6a79b203a9ad2aacca49f06a034b291a>>
 * @codegenDependency ../../../../../../../design-system/heading/src/heading-context.tsx <<SignedSource::2349ce64db4b514fd8dd03a05328f2ee>>
 */
/* eslint @repo/internal/codegen/signed-source-integrity: "warn" */
import React from 'react';
import type { RenderFn } from '@atlassian/forge-ui-types';
import PlatformHeading from '@atlaskit/heading';

// Define the type for Heading as HeadingProps at the top of this file

/**
 * A heading is a typography component used to display text in different sizes and formats.
 */
export const Heading = (props: Parameters<RenderFn>[0]) => {
  const {
    size,
    color,
    as,
    id,
    testId,
  } = props.forgeDoc.props as HeadingProps;
  return (
    <PlatformHeading
      size={size ? size : getSize(as)}
      color={getColor(color)}
      as={onlyAllowHeadingTag(as)}
      children={props.forgeDoc.children.map(c => <React.Fragment key={c.key}>{props.render(c)}</React.Fragment>)}
      id={id}
      testId={testId}
    />
  );
};
/**
 * @codegenEnd
 */
