import React from 'react';

import { aiConfigItemMessages } from '@atlaskit/editor-common/ai-messages';
import { fg } from '@atlaskit/platform-feature-flags';

import { FixSpellingIcon } from '../../../../icons/prompt/fixSpelling';
import { ImproveWritingIcon } from '../../../../icons/prompt/improveWriting';
import { createInsertBelow, createReplace } from '../../actions/create-actions';
import { CONFIG_ITEM_KEYS } from '../../config-item-keys';
import type {
	EditorPluginAIConfigItemWithOptions,
	EditorPluginAIConfigItem,
} from '../../config-items';

export const improveWriting: EditorPluginAIConfigItem = {
	key: CONFIG_ITEM_KEYS.IMPROVE_WRITING,
	title: aiConfigItemMessages.improveWritingTitle,
	description: aiConfigItemMessages.improveWritingDescription,
	icon: () => <ImproveWritingIcon />,
	intentSchemaId: 'improve_quality_intent_schema.json',
	actions: {
		empty: [
			createInsertBelow({ appearance: 'secondary' }),
			createReplace({ appearance: 'primary' }),
		],
		selection: [
			createInsertBelow({ appearance: 'secondary' }),
			createReplace({ appearance: 'primary' }),
		],
	},
	getBackendModel: () => {
		if (
			fg('platform_editor_ai_assistance_service') ||
			fg('platform_editor_ai_assistance_service_second')
		) {
			return 'assistance-service';
		}
		return 'xp-gen-ai';
	},
	canToggleDiffPreview: true,
};

export const improveWritingWithOptions: EditorPluginAIConfigItemWithOptions = {
	config: improveWriting,
	triggers: { empty: { enabled: true, docMinSize: 1 }, selection: { enabled: true } },
};

export const fixSpellingAndGrammar: EditorPluginAIConfigItem = {
	key: CONFIG_ITEM_KEYS.FIX_SPELLING_AND_GRAMMAR,
	title: aiConfigItemMessages.fixSpellingGrammarTitle,
	description: aiConfigItemMessages.fixSpellingGrammarDescription,
	icon: () => <FixSpellingIcon />,
	intentSchemaId: 'grammar_spelling_intent_schema.json',
	actions: {
		empty: [createReplace({ appearance: 'primary' })],
		selection: [createReplace({ appearance: 'primary' })],
	},
	getBackendModel: () => {
		if (
			fg('platform_editor_ai_assistance_service') ||
			fg('platform_editor_ai_assistance_service_second')
		) {
			return 'assistance-service';
		}
		return 'xp-gen-ai';
	},
};

export const fixSpellingAndGrammarWithOptions: EditorPluginAIConfigItemWithOptions = {
	config: fixSpellingAndGrammar,
	triggers: { empty: { enabled: true, docMinSize: 1 }, selection: { enabled: true } },
};
