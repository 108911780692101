import type { ChoreographerFactory } from '@atlassian/ipm-choreographer';
import { usePostOfficeContext, usePostOfficeEnvironment } from '@atlassian/post-office-context';
import { createContext, useContext, useEffect, useMemo, useState } from 'react';

import type { ChoreographerRegistry } from './choreographerRegistry';
import type { ChoreographerProviderProps } from './types';
import { type StableProvider } from '../../types';

// Helper to automatically handle ESM and non-ESM *.json imports (either is possible depending on the service/product's build configuration)
// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-member-access
const importHelper = (mod: any) => (mod && mod.__esModule ? mod.default : mod);

const DEFAULT_IS_CHOREOGRAPHER_ENABLED = true;

const defaultContext = {
	choreographerEnabled: DEFAULT_IS_CHOREOGRAPHER_ENABLED,
	setChoreographerEnabled: () => {},
	factory: undefined,
};

export type ChoreographerContext = {
	choreographerEnabled: boolean;
	setChoreographerEnabled: (isEnabled: boolean) => void;
	factory: ChoreographerFactory | undefined;
};

const ChoreographerContext = createContext<ChoreographerContext>(defaultContext);

export const StableChoreographerProvider: StableProvider<ChoreographerProviderProps> = ({
	children,
	choreographer,
}) => {
	const [choreographerFactory, setChoreographerFactory] = useState<
		ChoreographerFactory | undefined
	>(undefined);
	const [choreographerEnabled, setChoreographerEnabled] = useState(
		choreographer?.isDefaultEnabled ?? defaultContext.choreographerEnabled,
	);
	const environmentContext = usePostOfficeEnvironment();
	const context = usePostOfficeContext();

	useEffect(() => {
		if (choreographerEnabled) {
			import('./choreographerRegistry')
				.then((m) => {
					const module = importHelper(m) as { ChoreographerRegistry: typeof ChoreographerRegistry };

					setChoreographerFactory(
						new module.ChoreographerRegistry(
							context.product,
							environmentContext.currentEnv,
						).getPostOfficeChoreographerFactory(),
					);
				})
				.catch(() => {
					setChoreographerEnabled(false);
				});
		}
	}, [choreographerEnabled, context.product, environmentContext.currentEnv]);

	const stableChoreographerContext = useMemo(
		() => ({
			factory: choreographerFactory,
			choreographerEnabled,
			setChoreographerEnabled,
		}),
		[choreographerFactory, choreographerEnabled, setChoreographerEnabled],
	);

	return (
		<ChoreographerContext.Provider value={stableChoreographerContext}>
			{children}
		</ChoreographerContext.Provider>
	);
};

export const useChoreographerContext = () => useContext(ChoreographerContext);
