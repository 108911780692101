import type { ReactElement } from 'react';
import { elementToForgeDoc } from '../../../../utils/elementToForgeDoc';
import { type ComponentPropsConfig } from '../../../utils/codegen';

export const componentPropsConfig: ComponentPropsConfig = {
	buttonGroupLabel: true,
	description: {
		value: 'description ? props.render(elementToForgeDoc(description as ReactElement)) : undefined',
	},
	header: true,
	headingLevel: true,
	imageHeight: false,
	imageUrl: false,
	imageWidth: false,
	isLoading: true,
	maxImageHeight: false,
	maxImageWidth: false,
	primaryAction: {
		value:
			'primaryAction ? props.render(elementToForgeDoc(primaryAction as ReactElement)) : undefined',
	},
	renderImage: false,
	secondaryAction: {
		value:
			'secondaryAction ? props.render(elementToForgeDoc(secondaryAction as ReactElement)) : undefined',
	},
	tertiaryAction: {
		value:
			'tertiaryAction ? props.render(elementToForgeDoc(tertiaryAction as ReactElement)) : undefined',
	},
	testId: true,
	width: true,
};

/** These image props have been excluded:
 * - imageHeight
 * - imageWidth
 * - maxImageHeight
 * - maxImageWidth
 * - imageUrl
 * - renderImage
 * Reason: More investigation is needed for getting our own set of images
 * Ticket to add image props: https://ecosystem-platform.atlassian.net/browse/EXT-2044
 * /

/**
 * THIS SECTION WAS CREATED VIA CODEGEN DO NOT MODIFY {@see http://go/af-codegen}
 *
 * This component was generated against an existing platform component.
 * If you are seeing lint errors in this file, it means that this file's dependencies have been modified and the component will need to be regenerated.
 *
 * Please view https://hello.atlassian.net/wiki/spaces/ECO/pages/3358130330/How+to+Regenerate+UI+Kit+Components for more information on how to proceed.
 *
 * For further questions, please ping the #help-forge-ui channel.
 *
 * @codegen <<SignedSource::453fde60f712f4c910197d0b651e78ff>>
 * @codegenId #empty-state
 * @codegenCommand yarn workspace @atlassian/forge-ui codegen empty-state
 * @codegenDependency ../../../../../../../design-system/empty-state/src/types.tsx <<SignedSource::8b626ebaebaa2bcb2038d08608a4bce5>>
 * @codegenDependency ../../../../../../../design-system/empty-state/src/styled/index.tsx <<SignedSource::72a28d0d6eaf6ce91fd902abb27fee50>>
 * @codegenDependency ../../../../../../../design-system/empty-state/src/empty-state.tsx <<SignedSource::9588bf38361fcc5c4d256570a564f1f8>>
 */
/* eslint @repo/internal/codegen/signed-source-integrity: "warn" */
import React from 'react';
import type { RenderFn } from '@atlassian/forge-ui-types';
import PlatformEmptyState from '@atlaskit/empty-state';

type PlatformEmptyStateProps = React.ComponentProps<typeof PlatformEmptyState>;

export type EmptyStateProps = Pick<
  PlatformEmptyStateProps,
  'buttonGroupLabel' | 'description' | 'header' | 'headingLevel' | 'isLoading' | 'primaryAction' | 'secondaryAction' | 'tertiaryAction' | 'testId' | 'width'
>;

/**
 * An empty state appears when there is no data to display and describes what the user can do next.
 */
export const EmptyState = (props: Parameters<RenderFn>[0]) => {
  const {
    buttonGroupLabel,
    description,
    header,
    headingLevel,
    isLoading,
    primaryAction,
    secondaryAction,
    tertiaryAction,
    testId,
    width,
  } = props.forgeDoc.props as EmptyStateProps;
  return (
    <PlatformEmptyState
      buttonGroupLabel={buttonGroupLabel}
      description={description ? props.render(elementToForgeDoc(description as ReactElement)) : undefined}
      header={header}
      headingLevel={headingLevel}
      isLoading={isLoading}
      primaryAction={primaryAction ? props.render(elementToForgeDoc(primaryAction as ReactElement)) : undefined}
      secondaryAction={secondaryAction ? props.render(elementToForgeDoc(secondaryAction as ReactElement)) : undefined}
      tertiaryAction={tertiaryAction ? props.render(elementToForgeDoc(tertiaryAction as ReactElement)) : undefined}
      testId={testId}
      width={width}
    />
  );
};
/**
 * @codegenEnd
 */
/* eslint @repo/internal/codegen/signed-source-integrity: "warn" */
