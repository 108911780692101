import { type ForgeDoc } from '@atlassian/forge-ui-types';

export const getTextFromForgeDocChildren = (forgeDoc: ForgeDoc) => {
	return forgeDoc.children.map((child) => {
		if (child?.props?.text) {
			return child.props?.text;
		} else {
			return '';
		}
	});
};
