import { fg } from '@atlaskit/platform-feature-flags';

/**
 * Check if the feature flag for assistance service free generate is enabled across different products
 * Checks the following feature gates:
 * - platform_editor_ai_assistance_service
 * - platform_editor_ai_assist_srv_jira_fr_gen
 */
export const checkAssistanceServiceFreeGenerateFg = () => {
	if (
		fg('platform_editor_ai_assistance_service') ||
		fg('platform_editor_ai_assist_srv_jira_fr_gen')
	) {
		return true;
	}

	return false;
};
