import { type ComponentPropsConfig } from '../../../utils/codegen';

export const componentPropsConfig: ComponentPropsConfig = {
	appearance: true,
	ariaLabel: true,
	isIndeterminate: true,
	testId: true,
	value: true,
};
/**
 * THIS SECTION WAS CREATED VIA CODEGEN DO NOT MODIFY {@see http://go/af-codegen}
 *
 * This component was generated against an existing platform component.
 * If you are seeing lint errors in this file, it means that this file's dependencies have been modified and the component will need to be regenerated.
 *
 * Please view https://hello.atlassian.net/wiki/spaces/ECO/pages/3358130330/How+to+Regenerate+UI+Kit+Components for more information on how to proceed.
 *
 * For further questions, please ping the #help-forge-ui channel.
 *
 * @codegen <<SignedSource::0319e199eee688eac4056471514c8c4b>>
 * @codegenId #progress-bar
 * @codegenCommand yarn workspace @atlassian/forge-ui codegen progress-bar
 * @codegenDependency ../../../../../../../design-system/progress-bar/src/types.tsx <<SignedSource::552ca92a6f622a669ec11f9649969a47>>
 * @codegenDependency ../../../../../../../design-system/progress-bar/src/components/progress-bar.tsx <<SignedSource::e60f96e21a1a204360e96b28e0d1d0e8>>
 */
/* eslint @repo/internal/codegen/signed-source-integrity: "warn" */
import React from 'react';
import type { RenderFn } from '@atlassian/forge-ui-types';
import PlatformProgressBar from '@atlaskit/progress-bar';

type PlatformProgressBarProps = React.ComponentProps<typeof PlatformProgressBar>;

export type ProgressBarProps = Pick<
  PlatformProgressBarProps,
  'appearance' | 'ariaLabel' | 'isIndeterminate' | 'testId' | 'value'
>;

/**
 * A progress bar communicates the status of a system process.
 */
export const ProgressBar = (props: Parameters<RenderFn>[0]) => {
  const {
    appearance,
    ariaLabel,
    isIndeterminate,
    testId,
    value,
  } = props.forgeDoc.props as ProgressBarProps;
  return (
    <PlatformProgressBar
      appearance={appearance}
      ariaLabel={ariaLabel}
      isIndeterminate={isIndeterminate}
      testId={testId}
      value={value}
    />
  );
};
/**
 * @codegenEnd
 */
