import type { IntlShape } from 'react-intl-next';

import type {
	ExtensionManifest,
	ExtensionModuleAction,
	Parameters,
} from '@atlaskit/editor-common/extensions';
import type { EditorActions } from '@atlaskit/editor-core';

import type { ExtensionSource } from '@confluence/change-edition/entry-points/PremiumExtensionsUpsell';

export type CustomSitesExtensionProviderProps = {
	contentId: string;
	editorActions?: EditorActions;
	intl: IntlShape;
	openConfigPanel?: (localId: string) => void;
	setUpsellModalSource?: (source: ExtensionSource) => void;
};

export type CustomSitesManifestArguments<ParamsType> = {
	key: string;
	icons: any;
	parameters: ParamsType;
	update: any;
	render: any;
	title: string;
	type: string;
	description: string;
	source: ExtensionSource;
	nodeType?: 'extension' | 'multiBodiedExtension' | 'inlineExtension' | 'bodiedExtension';
	quickInsertAction?: ExtensionModuleAction;
	setUpsellModalSource?: (source: ExtensionSource) => void;
	additionalModuleNodeProps?: Record<string, boolean>;
};

export const getExtensionManifest = <ParamsType extends Parameters>({
	key,
	icons,
	parameters,
	update,
	render,
	title,
	type,
	description,
	source,
	nodeType = 'extension',
	quickInsertAction,
	setUpsellModalSource,
	additionalModuleNodeProps,
}: CustomSitesManifestArguments<ParamsType>): ExtensionManifest => ({
	title,
	type,
	key,
	description,
	icons,
	modules: {
		quickInsert: setUpsellModalSource
			? [
					{
						key: 'quick-insert',
						action: async () => setUpsellModalSource(source),
					},
				]
			: [
					{
						key: 'quick-insert',
						action: quickInsertAction || {
							type: 'node',
							key: 'default',
							parameters,
						},
					},
				],
		nodes: {
			default: {
				type: nodeType,
				render,
				update,
				...additionalModuleNodeProps,
			},
		},
	},
});
