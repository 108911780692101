import { AccessStatus } from '@confluence/session-data';
import { SYSTEM_HEADER_LEFT } from '@confluence/web-item-location';
import { FORGE_MODULE_GLOBAL_PAGE } from '@confluence/forge-ui';

import { AUTOMATION_KEY, DISCOVER_KEY, PEOPLE_KEY, SPACES_KEY } from './appKeys';

export const NOT_ALLOWED_WEB_ITEMS = [SPACES_KEY, PEOPLE_KEY, DISCOVER_KEY];

export const getGlobalAppsParams = ({ edition, accessStatus }) => {
	const isAdmin = accessStatus === AccessStatus.LICENSED_ADMIN_ACCESS;
	const hideAutomation = edition !== 'PREMIUM' || !isAdmin;

	const notAllowedWebItems = [...NOT_ALLOWED_WEB_ITEMS, hideAutomation ? AUTOMATION_KEY : ''];

	return {
		webItemParams: {
			location: SYSTEM_HEADER_LEFT,
			notAllowedWebItems,
		},
		forgeExtensionParams: {
			moduleType: FORGE_MODULE_GLOBAL_PAGE,
			spaceKey: '',
		},
	};
};
