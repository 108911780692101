// The build information here are populated by the code inlined in the bootstrapping HTML
// They can be found in:
// - packages/confluence-frontend-server/templates/*
// - next/packages/app/src/indexTemplate.tsx

export type InjectedBuildInfo = {
	FRONTEND_VERSION?: string;
	BUILD_DATE?: number;
	PUBLIC_PATH?: string;
	EDITOR_VERSION?: string;
	RENDERER_VERSION?: string;
};

declare global {
	interface Window {
		__buildInfo?: InjectedBuildInfo;
	}
}

type ReleaseTrack = 'dev' | 'staging' | 'hello' | 'canary' | 'prod';

type BuildInfo = {
	// A string in the following format that uniquely identifies this build:
	// {deploymentStack}-{branchName}-{buildNumber}
	FRONTEND_VERSION: string;
	BRANCH_NAME: string;
	BUILD_NUMBER: number;
	// Builds can take a while to reach all customers,
	// this lets us easily filter events by recent builds
	BUILD_DATE: number;
	PUBLIC_PATH: string;
	RELEASE_TRACK: ReleaseTrack;
};

function getReleaseTrack(): ReleaseTrack {
	if (process.env.NODE_ENV !== 'production') {
		return 'dev';
	}

	if (process.env.CLOUD_ENV === 'branch') {
		return 'dev';
	}

	if (process.env.CLOUD_ENV === 'staging') {
		return 'staging';
	}

	if (process.env.CLOUD_ENV === 'hello') {
		return 'hello';
	}

	if (process.env.CLOUD_ENV === 'canary') {
		return 'canary';
	}

	try {
		// This value overrides the track as "dev". Helpful for when
		// end-to-end tests are run from dev machine against a production instance.
		// eslint-disable-next-line check-set-storage-usage/no-unimported-storage-set
		if (sessionStorage.getItem('confluence.telemetry-dev-track-override')) {
			return 'dev';
		}
	} catch {
		// no-op
	}

	return 'prod';
}

export function getBuildInfo(): BuildInfo {
	const injectedBuildInfo: InjectedBuildInfo = window.__buildInfo || {};
	let { FRONTEND_VERSION } = injectedBuildInfo;
	if (!FRONTEND_VERSION) {
		FRONTEND_VERSION = '';
	}

	const FRONTEND_VERSION_PATTERN = /^([^-]+)-(.+)-(\d+)$/;
	const match = FRONTEND_VERSION_PATTERN.exec(FRONTEND_VERSION);
	return {
		FRONTEND_VERSION: match ? FRONTEND_VERSION : 'UNKNOWN-unknown-0',
		BRANCH_NAME: match ? match[2] : 'unknown',
		BUILD_NUMBER: match ? parseInt(match[3], 10) : 0,
		BUILD_DATE: injectedBuildInfo.BUILD_DATE || 0,
		PUBLIC_PATH: injectedBuildInfo.PUBLIC_PATH || 'unknown',
		RELEASE_TRACK: getReleaseTrack(),
	};
}
