export enum PreferenceCategory {
	StrictlyNecessary = 'STRICTLY_NECESSARY',
	Functional = 'FUNCTIONAL',
	Marketing = 'MARKETING',
	Analytics = 'ANALYTICS',
	Unknown = 'UNKNOWN',
}

export enum ConsentPreferenceV2 {
	ConsentDataUnavailable = 'CONSENT_DATA_UNAVAILABLE',
	ConsentedUnderGDPR = 'CONSENTS_SAVED_IN_GDPR_LOCALE',
	ConsentedUnderCPRA = 'CONSENTS_SAVED_IN_CPRA_LOCALE',
	ConsentedUnderLGPD = 'CONSENTS_SAVED_IN_LGPD_LOCALE',
	ConsentedUnderFADP = 'CONSENTS_SAVED_IN_FADP_LOCALE',
}

export enum ContextualConsentProperty {
	ConsentsAreDefault = 'DEFAULT_CONSENTS',
	UserIsAuthenticated = 'USER_AUTHENTICATED',
	ConsentDataUnavailable = 'CONSENT_DATA_UNAVAILABLE',
}

export type CookiePreferences = {
	[key in PreferenceCategory]: boolean;
};

export type AdditionalConsentPreferencesV2 = {
	[key in ConsentPreferenceV2]: boolean;
};

export type ContextualConsentPreferences = {
	[key in ContextualConsentProperty]: boolean;
};

export type ConsentPreferences =
	| CookiePreferences // V1
	| (CookiePreferences & AdditionalConsentPreferencesV2) // V2
	| (CookiePreferences & ContextualConsentPreferences); // V3

type Keys = {
	[name: string]: number;
};

type Pattern = { [name: string]: Pattern | number };

export interface CategorizedStorageControlsCache {
	categories: {
		[PreferenceCategory.StrictlyNecessary]: number;
		[PreferenceCategory.Functional]: number;
		[PreferenceCategory.Analytics]: number;
		[PreferenceCategory.Marketing]: number;
		[PreferenceCategory.Unknown]: number;
	};
	keys: {
		[productName: 'all' | string]: Keys;
	};
	thirdPartyKeys: Keys;
	patterns: {
		startsWith: {
			[productName: 'all' | string]: Pattern;
		};
		endsWith: {
			[productName: 'all' | string]: Pattern;
		};
	};
	analyticsSamplingRatio?: {
		[name: string]: number;
	};
}

export interface CategorizedStorageControlsCacheLegacy {
	categories: {
		[PreferenceCategory.StrictlyNecessary]: number;
		[PreferenceCategory.Functional]: number;
		[PreferenceCategory.Analytics]: number;
		[PreferenceCategory.Marketing]: number;
		[PreferenceCategory.Unknown]: number;
	};
	[CachedKeysDataPropertyLegacy.Cookies]: Keys;
	[CachedKeysDataPropertyLegacy.ThirdParty]: Keys;
	[CachedKeysDataPropertyLegacy.LocalStorage]: Keys;
	[CachedKeysDataPropertyLegacy.SessionStorage]: Keys;
	patterns: {
		startsWith: Pattern;
	};
	analyticsSamplingRatio?: {
		[name: string]: number;
	};
}

export enum CachedKeysDataPropertyLegacy {
	LocalStorage = 'localStorageKeys',
	SessionStorage = 'sessionStorageKeys',
	Cookies = 'keys',
	ThirdParty = 'thirdPartyKeys',
}

export interface ConsentPreferenceV001 {
	[PreferenceCategory.StrictlyNecessary]: boolean;
	[PreferenceCategory.Functional]: boolean;
	[PreferenceCategory.Analytics]: boolean;
	[PreferenceCategory.Marketing]: boolean;
	[PreferenceCategory.Unknown]: boolean;
}

export interface ConsentPreferenceV002 extends ConsentPreferenceV001 {
	[ContextualConsentProperty.ConsentDataUnavailable]: boolean;
	[ConsentPreferenceV2.ConsentedUnderGDPR]: boolean;
	[ConsentPreferenceV2.ConsentedUnderCPRA]: boolean;
	[ConsentPreferenceV2.ConsentedUnderLGPD]: boolean;
	[ConsentPreferenceV2.ConsentedUnderFADP]: boolean;
}
export interface ConsentPreferenceV003 extends ConsentPreferenceV001 {
	[ContextualConsentProperty.ConsentsAreDefault]: boolean;
	[ContextualConsentProperty.UserIsAuthenticated]: boolean;
	[ContextualConsentProperty.ConsentDataUnavailable]: boolean;
}

export type ConsentPreference =
	| ConsentPreferenceV001
	| ConsentPreferenceV002
	| ConsentPreferenceV003;

// TODO: Can we remove this, or does that break backwards compatibility?
export type CookiePreference = ConsentPreference;

export enum PrefetchedPreferenceState {
	INTERNAL,
}

export interface InitializedPrefsData {
	prefs: ConsentPreference | undefined;
	/**
	 * If true, we need to refetch the preferences as the initial prefetched state was internal
	 * or the initial preferences were missing
	 */
	isPrefetchedPrefsInternalState: boolean;
}

export enum CategoryStorageType {
	Cookies = 'Cookies',
	LocalStorage = 'LocalStorage',
	SessionStorage = 'SessionStorage',
}

export enum Status {
	SUCCESS = 'SUCCESS',
	BLOCKED = 'BLOCKED',
	FAILED = 'FAILED',
}

// List of BSC package killsitches
export enum BSC_PACKAGE_KILLSWITCHES {
	MASTER_KILLSWITCH = 'platform_moonjelly_bsc_master_killswitch',
	UI_KILLSWITCH = 'platform_moonjelly_bsc_ui_killswitch',
	SSR_KILLSWITCH = 'platform_moonjelly_bsc_ssr_killswitch',
	WEB_STORAGE_KILLSWITCH = 'platform_moonjelly_bsc_web_storage_killswitch',
}

/**
 * Common fields that are shared between the ConsentBanner and initializeControls
 */
export type CommonConsentBannerProps = {
	/**
	 * When `analyticsEnabled` is set to true, analytics events will be sent to gas services.
	 * When it is disabled, no analytics events will be sent. Disable for proudcts which are not integrated
	 * with GAS.
	 *
	 * @default true
	 */
	analyticsEnabled?: boolean;

	/**
	 * When 'localConsentMode' is turned on, the banner only saves and reads cookie preferences from the cookie in the browser.
	 * No calls will be made to ConsentHub for looking up preferences, geo-IP, or saving preferences. All users, regardless of
	 * geo, will receive the banner.
	 *
	 * Defaults to false, so that only consumers who are unable to integrate with ConsentHub need to set this mode.
	 * @default false
	 */
	localConsentMode?: boolean;

	/** Name of the product that is rendering the component/initializing controls */
	product?: string;
};
