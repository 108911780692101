import type { ReactElement } from 'react';

import type { ExperienceAttributes } from '@confluence/experience-tracker';

import { useDeepCompareEffect } from '../useDeepCompareEffect';
import { getMacroExperienceTracker } from '../MacroExperienceTracker';

export type MacroExperienceFailureProps = {
	error: Error;
	mode: string;
	onFailure?: () => void;
	attributes: ExperienceAttributes;
	extensionKey?: string;
	name: string;
	children?: ReactElement | null;
	contentId: string;
	source?: string;
};

export function MacroExperienceFailure({
	attributes,
	children = null,
	contentId,
	extensionKey,
	error,
	mode,
	name,
	source,
}: MacroExperienceFailureProps) {
	useDeepCompareEffect(() => {
		getMacroExperienceTracker(mode, contentId).fail({
			name,
			attributes: {
				...attributes,
				source,
			},
			error,
		});
	}, [attributes, contentId, extensionKey, error, mode, name, source]);

	return children;
}
