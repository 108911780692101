import { query } from '@confluence/query-preloader-tools';

import { PresenceSettingsQuery } from './presence/PresenceSettingsQuery.graphql';
import type {
	PresenceSettingsQuery as PresenceSettingsQueryType,
	PresenceSettingsQueryVariables,
} from './presence/__types__/PresenceSettingsQuery';

export function preloadPresenceSettings({
	cloudId,
	spaceKey,
}: {
	cloudId: string;
	spaceKey: string;
}) {
	return query<PresenceSettingsQueryType, PresenceSettingsQueryVariables>({
		query: PresenceSettingsQuery,
		variables: {
			cloudId,
			spaceKey,
		},
	});
}
