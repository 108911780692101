import type { GQLExtension } from '../web-client/graphql/types';

export const getExtensionType = (extension: Pick<GQLExtension, 'properties'>) => {
	const { properties } = extension;

	if (properties?.layout === 'bodied') {
		return 'bodiedExtension';
	} else if (properties?.layout === 'inline') {
		return 'inlineExtension';
	}
	return 'extension';
};
