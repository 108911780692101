import React from 'react';

import { PRODUCT_HOME_ID } from '@confluence/app-navigation/entry-points/navigationIDs';
import { LoadablePaint } from '@confluence/loadable';

const QuestionsRouteComponent = LoadablePaint({
	loader: () =>
		import(/* webpackChunkName: "loadable-QuestionsRouteComponent" */ './QuestionsRouteComponent'),
});

export const GlobalQuestionsRoute = () => {
	return <QuestionsRouteComponent isGlobalPage />;
};

GlobalQuestionsRoute.NAVIGATION_PARAMS = () => ({
	Screen: {
		screenEvent: {
			name: 'globalQuestionsScreen',
		},
	},
	MainLayout: {
		navView: PRODUCT_HOME_ID,
	},
});
