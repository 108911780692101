import React, { useRef } from 'react';
import { type ForgeDoc } from '@atlassian/forge-ui-types';
import { Iframe } from '../iframe';
import { fg } from '@atlaskit/platform-feature-flags';
import { useCallbackOne } from 'use-memo-one';

interface WebRuntimeProps {
	setForgeDoc: (forgeDoc: ForgeDoc) => void;
	setError?: (error: Error) => void;
}

type IframeProps = Parameters<typeof Iframe>[0];

export const WebRuntime = ({
	setForgeDoc,
	setError,
	accountId,
	apolloClient,
	contextIds,
	extension,
	components,
	coreData,
	extensionData,
	extensionPayload,
	extensionViewData,
	getContextToken,
	timezone,
	locale,
	bridge,
	...restProps
}: WebRuntimeProps & IframeProps) => {
	const forgeReactMajorVersion = useRef<number | null>(null);
	// eslint-disable-next-line @atlaskit/platform/ensure-feature-flag-prefix
	const macroConfigUpdateImprovementEnabled = fg('forge-ui-macro-config-update-improvement');

	const combinedBridge = {
		onError: ({ error }: { error: Error }) => {
			if (setError) {
				setError(error);
			}
		},
		...bridge,
		reconcile: ({ forgeDoc }: { forgeDoc: ForgeDoc }) => {
			Object.freeze(forgeDoc);
			setForgeDoc(forgeDoc);
			if (
				macroConfigUpdateImprovementEnabled &&
				forgeReactMajorVersion.current === null &&
				forgeDoc.forgeReactMajorVersion
			) {
				forgeReactMajorVersion.current = forgeDoc.forgeReactMajorVersion;
			}
		},
	};

	const getCurrentForgeReactMajorVersion = useCallbackOne(() => {
		return forgeReactMajorVersion.current;
	}, [forgeReactMajorVersion]);

	return (
		<Iframe
			accountId={accountId}
			apolloClient={apolloClient}
			contextIds={contextIds}
			extension={extension}
			coreData={coreData}
			components={components}
			extensionData={extensionData}
			extensionPayload={extensionPayload}
			extensionViewData={extensionViewData}
			getContextToken={getContextToken}
			timezone={timezone}
			locale={locale}
			bridge={combinedBridge}
			isHidden={true}
			getCurrentForgeReactMajorVersion={
				macroConfigUpdateImprovementEnabled ? getCurrentForgeReactMajorVersion : undefined
			}
			{...restProps}
			onError={(err) => setError && setError(new Error(err))}
		/>
	);
};
