import React, { useContext } from 'react';

import {
	ADMIN_HOME_ID,
	COMPANY_HUB_SETTINGS_ID,
} from '@confluence/app-navigation/entry-points/navigationIDs';
import { getCompanyHubSideNav } from '@confluence/breadcrumbs/entry-points/getCompanyHubSideNav';
import { CompanyHomeBuilderUpsellPage } from '@confluence/change-edition/entry-points/CompanyHomeBuilderUpsellPage';
import {
	CompanyHubSettingsPage,
	CompanyHubSettingsPageTabsType,
} from '@confluence/company-hub/entry-points/CompanyHubSettingsPage';
import { useCompanyHubPremiumGate } from '@confluence/company-hub-utils/entry-points/useCompanyHubPremiumGate';
import type { RouteMatch } from '@confluence/route';
import { getCompanyHubSpaceKey } from '@confluence/route-manager/entry-points/companyHubUtils';
import { SPAViewContext } from '@confluence/spa-view-context';
import { getCompanyHubPseudoAppRoute } from '@confluence/ssr-app/entry-points/getCompanyHubPseudoAppRoute';

import { SpacePermissionsSettingsRoute } from './SpacePermissionsSettingsRoute';

/**
 * The routes "Company Hub settings > Users" and "Hub permissions" in the Confluence app.
 * `AdminCompanyHubPermissionsRoute` wraps this one to implement the Company Hub pseudo app.
 */
function ConfluenceAppHubPermissionsRoute({ params, ...props }: RouteMatch) {
	const shouldShowPremiumUpsell = useCompanyHubPremiumGate();
	const { isSiteAdmin } = useContext(SPAViewContext);

	if (isSiteAdmin && shouldShowPremiumUpsell) {
		return <CompanyHomeBuilderUpsellPage featureName="companyHubPermissions" />;
	}

	if (!params.tab) {
		return <CompanyHubSettingsPage tab={CompanyHubSettingsPageTabsType.USERS} />;
	}

	const spaceKey = getCompanyHubSpaceKey();
	return <SpacePermissionsSettingsRoute {...props} params={{ spaceKey, ...params }} />;
}

ConfluenceAppHubPermissionsRoute.NAVIGATION_PARAMS = (routeMatch: RouteMatch) => {
	const { name, params } = routeMatch;
	const spaceKey = getCompanyHubSpaceKey();
	const navView = getCompanyHubSideNav(routeMatch) ? ADMIN_HOME_ID : COMPANY_HUB_SETTINGS_ID;

	return {
		Screen: {
			screenEvent: {
				name: `${params.tab || 'consolidated'}HubPermissionsScreen`,
				id: spaceKey,
			},
			pageState: { spaceKey, routeName: name },
		},
		MainLayout: {
			navView,
			spaceKey,
		},
	};
};

/**
 * The routes "Company Hub settings > Users" and "Hub permissions" in the Company Hub pseudo app if enabled; otherwise, in the Confluence app.
 */
export const AdminCompanyHubPermissionsRoute = getCompanyHubPseudoAppRoute(
	ConfluenceAppHubPermissionsRoute,
);
