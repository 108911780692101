import type { ThemeState } from '@atlaskit/tokens';

import { getDefaultTheme } from './defaultTheme';

export const buildThemeFromState = (themeState: Partial<ThemeState>): Partial<ThemeState> => {
	return {
		...getDefaultTheme(),
		...themeState,
		typography: 'typography-refreshed',
	};
};
