/* eslint react/prop-types:0 */
import React from 'react';

import { ADMIN_HOME_ID } from '@confluence/app-navigation/entry-points/navigationIDs';
import { LoadablePaint } from '@confluence/loadable';
import { RouteMatch } from '@confluence/route';
import { type ActiveTab } from '@confluence/default-space-permissions/entry-points/ActiveTab';

const LazyDefaultSpacePermissionsPage = LoadablePaint({
	loader: async () =>
		(
			await import(
				/* webpackChunkName: "loadable-confluencesite-permissionsentry-pointsDefaultSpacePermissions" */ '@confluence/default-space-permissions/entry-points/DefaultSpacePermissionsPage'
			)
		).DefaultSpacePermissionsPage,
});

export const AdminSiteSpacePermissionsRoute = (routeMatch: RouteMatch) => {
	const {
		params: { tab },
	} = routeMatch;

	return <LazyDefaultSpacePermissionsPage activeTab={tab as ActiveTab} />;
};

AdminSiteSpacePermissionsRoute.NAVIGATION_PARAMS = (routeMatch: RouteMatch) => ({
	Screen: {
		screenEvent: {
			name: 'siteSpacePermissionsScreen',
			attributes: {
				tab: routeMatch.params.tab,
			},
		},
	},
	MainLayout: {
		navView: ADMIN_HOME_ID,
	},
});
