import gql from 'graphql-tag';

export const EmbedUnifiedQuery = gql`
	query EmbedUnifiedQuery($contentId: ID!, $spaceKey: String!) {
		content(id: $contentId) {
			nodes {
				id
				title
				metadata {
					frontend {
						embedUrl
					}
					currentuser {
						favourited {
							isFavourite
						}
					}
				}
				operations {
					operation
					targetType
				}
				hasRestrictions
				hasViewRestrictions
				hasInheritedRestrictions
				space {
					id
					name
					key
					type
					alias
				}
				type
				status
				ancestors {
					id
					type
					title
					status
				}
				embeddedProduct
			}
		}
		spaces(spaceKey: $spaceKey, first: 10) {
			nodes {
				id
				name
				homepage {
					id
				}
			}
		}
		user(current: true) {
			id
			confluence {
				space {
					id
				}
			}
		}
	}
`;
