import { OPERATIONAL_EVENT_TYPE } from '@atlaskit/analytics-gas-types';
import { useFirePostOfficeAnalyticsEvents } from '@post-office/analytics';
import { type ErrorInfo } from 'react';
import {
	ErrorBoundary,
	type ErrorBoundaryPropsWithRender,
	type FallbackProps,
} from 'react-error-boundary';

export type ErrorBoundaryProps = Partial<
	Pick<ErrorBoundaryPropsWithRender, 'fallbackRender' | 'onError'>
>;
export type ErrorBoundaryAdditionalAnalytics = {
	errorBoundaryLocation?: string;
};

type FallbackRender = (props: FallbackProps) => React.ReactNode;

export const withErrorBoundary =
	<P extends Record<string, unknown>>(
		Component: React.FC<P>,
	): React.FC<Partial<ErrorBoundaryPropsWithRender> & ErrorBoundaryAdditionalAnalytics & P> =>
	({ errorBoundaryLocation, fallbackRender, onError, ...data }) => {
		const { fireAnalyticsEvent } = useFirePostOfficeAnalyticsEvents();

		const internalOnError =
			(
				additionalAnalytics: ErrorBoundaryAdditionalAnalytics,
				onError?: ErrorBoundaryProps['onError'],
			) =>
			(error: Error, info: ErrorInfo) => {
				if (onError) {
					onError(error, info);
				}
				fireAnalyticsEvent(
					{
						eventType: OPERATIONAL_EVENT_TYPE,
						action: 'caughtError',
						actionSubject: 'PostOfficeErrorBoundary',
						actionSubjectId: additionalAnalytics.errorBoundaryLocation,
					},
					{
						error: error,
						info: info,
					},
				);
			};

		const internalFallbackRender =
			(FallbackRender?: FallbackRender): FallbackRender =>
			(props) => {
				if (FallbackRender) {
					// eslint-disable-next-line @typescript-eslint/ban-ts-comment
					// @ts-ignore - Confluence Local Consumption - 'FallbackRender' cannot be used as a JSX component. Its return type 'ReactNode' is not a valid JSX element. Type 'undefined' is not assignable to type 'Element | null'.
					return <FallbackRender {...props} />;
				}
				return null;
			};

		return (
			<ErrorBoundary
				fallbackRender={internalFallbackRender(fallbackRender)}
				onError={internalOnError({ errorBoundaryLocation }, onError)}
			>
				<Component {...(data as P)} />
			</ErrorBoundary>
		);
	};
