/**
 * @jsxRuntime classic
 * @jsx jsx
 */
import React from 'react';
import { jsx, css } from '@compiled/react';
import { type RenderFn, type ForgeDoc } from '@atlassian/forge-ui-types';
import { token } from '@atlaskit/tokens';
import { cssMap } from '@atlaskit/css';
import { Box } from '@atlaskit/primitives/compiled';
import { useIsMacro } from '../../utils/useIsMacro';

export interface ListProps {
	/**
	 * The `ordered` type should be used when representing an ordered list of items.
	 * The `unordered` type should be used when representing an unordered list of items.
	 * The type is set to `unordered` by default.
	 */
	type: 'ordered' | 'unordered';
	/**
	 * The items to render inside a `List` group.
	 */
	children: React.ReactNode;
}

const defaultMarginStyle = css({
	// indentation spacing is 24px
	marginLeft: token('space.negative.200'),
});

const styles = cssMap({
	macroMargin: {
		// push List to the right by 4px to avoid numbered bullet points being cut off in Confluence Macro
		marginLeft: token('space.050'),
		// place top margins on List components of 8px using space tokens (closest to 10px), to prevent it being overridden in Confluence Macro
		marginTop: token('space.100'),
	},
});

const defaultStyle = css({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values, @atlaskit/design-system/use-tokens-typography -- Ignored via go/DSP-18766
	lineHeight: token('space.300'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'> *:not(:first-of-type)': {
		// throws console error when first-child is used, using first-of-type instead
		marginTop: token('space.050'),
	},
});

// bullet style ordering
const UNORDERED_LIST_STYLES = ['disc', 'circle', 'square'];
const ORDERED_LIST_STYLES = ['decimal', 'lower-alpha', 'lower-roman'];

// create a type ForgeDocWihData that includes an indentLevel object, to avoid exposing indentLevel prop in ListProps
type ForgeDocWithData = Omit<ForgeDoc, 'children'> & {
	data: { indentLevel: number };
	children: ForgeDocWithData[];
};

/**
 * An unordered (bulleted) or ordered (numbered) list.
 */
export const List = (props: Parameters<RenderFn>[0]) => {
	const { forgeDoc, render } = props;
	const { type } = props.forgeDoc.props as ListProps;

	const forgeDocWithData = forgeDoc as ForgeDocWithData;
	// initialise indentLevel to 0 and add to forgeDocWithData
	if (forgeDocWithData.data === undefined) {
		forgeDocWithData.data = { indentLevel: 0 };
	}

	// the indentLevel is used to determine the list item style that should be rendered for that level
	const { indentLevel } = forgeDocWithData.data;

	const isMacro = useIsMacro();

	// set the list style according to the list type
	const listType = type === 'ordered' ? ORDERED_LIST_STYLES : UNORDERED_LIST_STYLES;

	// pass iterated indentLevel to child
	forgeDocWithData.children.forEach((child) => {
		// This `if` block needs to be deprecated. `List` children should always be `ListItem`s, not another `List`
		// Using `List` as a child of `List` fails accessibility tests
		if (child.type === 'List') {
			child.data = { ...child.data, indentLevel: indentLevel + 1 };
		}

		if (child.type === 'ListItem') {
			// Iterate through children of `ListItem` to find any `List` children to pass indentLevel
			// This is to render the correct list style
			child.children.forEach((grandChild) => {
				if (grandChild.type === 'List') {
					grandChild.data = { ...child.data, indentLevel: indentLevel + 1 };
				}
			});
		}
	});

	const listChildren = forgeDocWithData.children.map((child) => render(child));

	// We have to use "style" here since the value for the "list-style-type" property is dynamic (based on the indentation level).
	// Compiled does not support dynamic values and @compiled/babel-plugin would not be able to transform this code.
	// See https://atlassian.design/components/eslint-plugin-ui-styling-standard/migration-guide#dynamic-styles
	const listComponent =
		type === 'ordered' ? (
			<ol
				css={[defaultStyle, defaultMarginStyle]}
				key={forgeDocWithData.key}
				style={{ listStyleType: listType[indentLevel % listType.length] }}
			>
				{listChildren}
			</ol>
		) : (
			<ul
				css={[defaultStyle, defaultMarginStyle]}
				key={forgeDocWithData.key}
				style={{ listStyleType: listType[indentLevel % listType.length] }}
			>
				{listChildren}
			</ul>
		);

	if (isMacro && indentLevel === 0) {
		// Applying an additional margin when used in a macro extension point as the editor contains styling that causes decimal bullets to overflow and be cut off. The additional margin shifts the List component to prevent the cut off.
		return <Box xcss={styles.macroMargin}>{listComponent}</Box>;
	}
	return listComponent;
};
