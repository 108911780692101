import React from 'react';
import { getEditorPreloader } from '@confluence-classic/confluence-fabric-editor/src/lib';

import type { RouteMatch } from '@confluence/route';
import { LoadablePaint } from '@confluence/loadable';
import { CONTAINER_HOME_ID } from '@confluence/app-navigation/entry-points/navigationIDs';
import { START_TOUCH } from '@confluence/navdex';

import SpaceBase from '../../SpaceBase';

const SpacePages = LoadablePaint({
	loader: async () =>
		(
			await import(
				/* webpackChunkName: "loadable-SpacePages" */ '@confluence/space-pages/entry-points/SpacePages'
			)
		).SpacePages,
});

export const SpacePagesRoute = () => {
	return (
		<SpaceBase>
			<SpacePages />
		</SpaceBase>
	);
};

SpacePagesRoute.NAVIGATION_PARAMS = ({ params: { spaceKey } }: RouteMatch) => ({
	Screen: {
		screenEvent: {
			name: 'spacePagesScreen',
			attributes: {
				navigationLayer: 'container',
				navdexPointType: START_TOUCH,
			},
		},
		pageState: { spaceKey },
	},
	MainLayout: {
		navView: CONTAINER_HOME_ID,
		spaceKey,
		editorPreloader: getEditorPreloader,
		disableMinWidth: true,
	},
});
