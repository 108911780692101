import gql from 'graphql-tag';

export const SiteUserPersonalizationQuery = gql`
	query SiteUserPersonalizationQuery($cloudId: String!, $userId: String!) @experimental {
		experimentalSiteUserPersonalization(cloudId: $cloudId, userId: $userId) {
			attributes {
				name
				value
			}
		}
	}
`;
