import FeatureGates from '@atlaskit/feature-gate-js-client';
import {
	type DismissCallback,
	useContextScope,
	useMessagePlacementHooks,
} from '@atlassian/post-office-context';
import { useFirePostOfficeAnalyticsEvents } from '@post-office/analytics';
import {
	type ErrorBoundariedProps,
	type MessageCategory,
	createPlacementComponent,
	useInitialData,
} from '@post-office/placement-common';
import { usePlacementApi } from '@post-office/use-placement-api';
import { type ComponentType, useCallback, useEffect, useState } from 'react';

import { scopes } from './scopes';
import { MessageRenderer } from '../__generated__/client/render';
import { PLACEMENT_ID } from '../__generated__/common';
import { withScreenSpaceFlagsIntlProvider } from '../__generated__/intl-provider';
import type { Placement as ScreenSpaceFlagsPlacement } from '../contracts/Placement';

type State = {
	messageInstanceId: string;
	messageTemplateId: never;
	messageCategory: MessageCategory;
}[];

export const ScreenSpaceFlagsPlacementComponent: ComponentType<
	ErrorBoundariedProps<ScreenSpaceFlagsPlacement>
> = withScreenSpaceFlagsIntlProvider(
	createPlacementComponent<ScreenSpaceFlagsPlacement>(PLACEMENT_ID, ({ initialData }) => {
		const [localMessageState, setLocalMessageState] = useState<State | undefined>();

		useInitialData(initialData);

		const { onMessageDismiss, onMessageClicked, onMessageSnooze } = useMessagePlacementHooks();

		const handleDismiss: DismissCallback = ({ messageInstanceId }) => {
			setLocalMessageState((current) =>
				current ? current.filter((e) => e.messageInstanceId !== messageInstanceId) : undefined,
			);
		};

		useEffect(() => onMessageDismiss(handleDismiss), [onMessageDismiss]);
		useEffect(() => onMessageClicked(handleDismiss), [onMessageClicked]);
		useEffect(() => onMessageSnooze(handleDismiss), [onMessageSnooze]);

		const activeScopes = useContextScope({ scopes });

		const messagesState = usePlacementApi<{
			messages: State;
		}>('/update-messages', {
			requestOptions: { method: 'POST', body: JSON.stringify(activeScopes) },
			throttleTime: 100,
			autoRefetch: {
				isEnabled: !!activeScopes.updateKey && typeof localMessageState === 'undefined',
				updateKey: activeScopes.updateKey ?? '',
			},
		});

		useEffect(() => {
			if (messagesState?.data?.messages?.length) {
				setLocalMessageState(messagesState.data.messages);
			}
		}, [messagesState.data]);

		return FeatureGates.checkGate('post-office-screen-space-flags-shadow') ? (
			<SuppressIfModalIsPresent>
				{localMessageState?.slice(0, 1).map(({ messageInstanceId, ...props }) => (
					// Adding @ts-ingore to suppress the error in confluence typecheck and below error is not thrown in post-office typecheck
					// This error exists because confluence is using react 16 and post-office is using react 18
					// eslint-disable-next-line @typescript-eslint/ban-ts-comment
					// @ts-ignore - 'ReactNode | ReactElement<any, any>' is not a valid JSX element.
					<MessageRenderer
						{...props}
						key={messageInstanceId}
						id={messageInstanceId}
						messageInstanceId={messageInstanceId}
						onDismissed={() => setLocalMessageState([])}
					/>
				))}
			</SuppressIfModalIsPresent>
		) : null;
	}),
);

export default ScreenSpaceFlagsPlacementComponent;

const SuppressIfModalIsPresent = ({ children }: React.PropsWithChildren<unknown>): JSX.Element => {
	const { fireAnalyticsEvent } = useFirePostOfficeAnalyticsEvents();
	const [isVisible, setIsVisible] = useState(true);

	const handleVisibilityChange = useCallback(
		(modalOnScreen: boolean) => {
			const shouldShow = !modalOnScreen;

			if (!shouldShow) {
				fireAnalyticsEvent({
					eventType: 'operational',
					action: 'suppressed',
					actionSubject: 'screenSpaceFlags',
				});
			}

			setIsVisible(shouldShow);
		},
		[fireAnalyticsEvent],
	);

	useEffect(() => {
		const modalOnScreen = !!globalThis.document.querySelector(
			'.atlaskit-portal-container .atlaskit-portal [role=dialog]',
		);

		handleVisibilityChange(modalOnScreen);
	}, [handleVisibilityChange]);

	return FeatureGates.checkGate('post-office-screen-space-flags-modal-detector') ? (
		<>{isVisible && children}</>
	) : (
		<>{children}</>
	);
};
