import { convertLinkToMacroNode } from './utils/convertLinkToMacroNode';
import {
	AutoConvertDefinition,
	AutoConvertMacroAttributes,
	AutoConvertMacroAttributesMap,
	AutoConvertMatcherToExtensionsMap,
} from './types';
import { AnalyticsClient } from '../../utils/analytics';

export const createForgeMacroAutoConverter = (
	analyticsClient: AnalyticsClient,
	autoConvertDefinitions?: AutoConvertDefinition[],
	macroADFNodeMap?: AutoConvertMacroAttributesMap,
	autoConvertMatcherToExtensionsMap?: AutoConvertMatcherToExtensionsMap,
): ((link: string) => AutoConvertMacroAttributes | null) => {
	return (link: string) =>
		convertLinkToMacroNode(
			link,
			analyticsClient,
			autoConvertDefinitions,
			macroADFNodeMap,
			autoConvertMatcherToExtensionsMap,
		);
};
