import gql from 'graphql-tag';

const iconDetails = gql`
	fragment iconDetails on Icon {
		path
		width
		height
		isDefault
	}
`;

export const spaceForBaseSpaceQuery = gql`
	query spaceForBaseSpaceQuery($spaceKey: String) {
		spaces(spaceKey: $spaceKey) {
			nodes {
				id
				key
				alias
				name
				homepage {
					id
				}
				icon {
					...iconDetails
				}
				type
				settings {
					routeOverrideEnabled
				}
				theme {
					themeKey
				}
				lookAndFeel {
					headings {
						key
						value
					}
					menus {
						hoverOrFocus {
							key
							value
						}
						color
					}
					header {
						backgroundColor
						button {
							backgroundColor
							color
						}
						primaryNavigation {
							color
							hoverOrFocus {
								key
								value
							}
						}
						secondaryNavigation {
							color
							hoverOrFocus {
								key
								value
							}
						}
						search {
							backgroundColor
							color
						}
					}
					content {
						screen {
							background
							backgroundColor
							backgroundImage
							backgroundPosition
							backgroundSize
							backgroundRepeat
							backgroundOrigin
							backgroundClip
							backgroundAttachment
							backgroundBlendMode
							layer {
								background
								backgroundColor
								backgroundImage
								backgroundPosition
								backgroundSize
								backgroundRepeat
								backgroundOrigin
								backgroundClip
								backgroundAttachment
								backgroundBlendMode
								width
								height
							}
							gutterTop
							gutterRight
							gutterBottom
							gutterLeft
						}
						container {
							background
							backgroundColor
							backgroundImage
							backgroundPosition
							backgroundSize
							backgroundRepeat
							backgroundOrigin
							backgroundClip
							backgroundAttachment
							backgroundBlendMode
							padding
							borderRadius
						}
						header {
							background
							backgroundColor
							backgroundImage
							backgroundPosition
							backgroundSize
							backgroundRepeat
							backgroundOrigin
							backgroundClip
							backgroundAttachment
							backgroundBlendMode
							padding
							borderRadius
						}
						body {
							background
							backgroundColor
							backgroundImage
							backgroundPosition
							backgroundSize
							backgroundRepeat
							backgroundOrigin
							backgroundClip
							backgroundAttachment
							backgroundBlendMode
							padding
							borderRadius
						}
					}
				}
				metadata {
					labels {
						nodes {
							prefix
							name
							id
						}
					}
				}
				operations {
					operation
					targetType
				}
			}
		}
	}

	${iconDetails}
`;
