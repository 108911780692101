import { query } from '@confluence/query-preloader-tools';
import { preloadLocalStorage } from '@confluence/storage-manager/entry-points/preloadLocalStorage';
import { PERSISTED_KEYS_ON_SERVER } from '@confluence/storage-manager/entry-points/LocalStorageKeys';

import { BlogTree } from './BlogTreeQuery.graphql';
import { PAGE_SIZE, BLOG_STATUSES } from './treeParameters';

async function isBlogTreeExpanded() {
	try {
		const entries = await preloadLocalStorage();
		const sideNavExpandState = entries.find((entry) =>
			entry.key.endsWith(PERSISTED_KEYS_ON_SERVER.PERSISTED_SPACE_NAV_SECTIONS_EXPANDED_STATES),
		);
		if (sideNavExpandState.value) {
			const expandedStates = JSON.parse(sideNavExpandState.value);
			if (expandedStates.blogTree) {
				return true;
			}
		}
	} catch {
		// Return value might be malformed
	}
	return false;
}

export const preloadBlogTree = async ({ spaceKey }) => {
	// blog tree is default collapsed, and is only expanded when user lands directly on a blog.
	// Or use explicity expanded the blog tree section
	const blogTreeQueryPromise = query({
		query: BlogTree,
		variables: {
			spaceKey,
			first: PAGE_SIZE,
			statuses: BLOG_STATUSES,
		},
	});
	// isBlogTreeExpanded() is much faster then blogTreeQueryPromise
	// If section is not expanded we don't wait for the blog tree query to finish
	return isBlogTreeExpanded().then((expanded) => {
		if (expanded) {
			return blogTreeQueryPromise;
		}
	});
};
