import { type ComponentPropsConfig } from '../../../utils/codegen';

/**
 * Use a required asterisk to specify that a form field must not be empty.
 */
export const componentPropsConfig: ComponentPropsConfig = {};

/**
 * THIS SECTION WAS CREATED VIA CODEGEN DO NOT MODIFY {@see http://go/af-codegen}
 *
 * This component was generated against an existing platform component.
 * If you are seeing lint errors in this file, it means that this file's dependencies have been modified and the component will need to be regenerated.
 *
 * Please view https://hello.atlassian.net/wiki/spaces/ECO/pages/3358130330/How+to+Regenerate+UI+Kit+Components for more information on how to proceed.
 *
 * For further questions, please ping the #help-forge-ui channel.
 *
 * @codegen <<SignedSource::4f8a3754bc5c7243ae9548a9303544c3>>
 * @codegenId #required-asterisk
 * @codegenCommand yarn workspace @atlassian/forge-ui codegen required-asterisk
 * @codegenParams { "props": {} }
 * @codegenDependency ../../../../../../../design-system/form/src/required-asterisk.tsx <<SignedSource::90363dbb83dc5471d757eea1a398b2cc>>
 */
/* eslint @repo/internal/codegen/signed-source-integrity: "warn" */
import React from 'react';
import type { RenderFn } from '@atlassian/forge-ui-types';
import { RequiredAsterisk as PlatformRequiredAsterisk } from '@atlaskit/form';

/**
 * Use a required asterisk to specify that a form field must not be empty.
 */
export const RequiredAsterisk = (props: Parameters<RenderFn>[0]) => {
  return (
    <PlatformRequiredAsterisk
    />
  );
};
/**
 * @codegenEnd
 */
