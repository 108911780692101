import gql from 'graphql-tag';

export const BulkSetSpacePermissionAsyncMutation = gql`
	mutation BulkSetSpacePermissionAsyncMutation(
		$spacePermissions: [SpacePermissionType]!
		$spaceTypes: [BulkSetSpacePermissionSpaceType]!
		$subjectId: ID!
		$subjectType: BulkSetSpacePermissionSubjectType!
	) {
		bulkSetSpacePermissionAsync(
			input: {
				spacePermissions: $spacePermissions
				spaceTypes: $spaceTypes
				subjectId: $subjectId
				subjectType: $subjectType
			}
		) {
			success
			errors {
				message
				extensions {
					errorType
					statusCode
				}
			}
			taskId
		}
	}
`;
