import { type ComponentPropsConfig } from '../../../utils/codegen';

export const componentPropsConfig: ComponentPropsConfig = {
	children: true,
	alignment: true,
};

/**
 * THIS SECTION WAS CREATED VIA CODEGEN DO NOT MODIFY {@see http://go/af-codegen}
 *
 * This component was generated against an existing platform component.
 * If you are seeing lint errors in this file, it means that this file's dependencies have been modified and the component will need to be regenerated.
 *
 * Please view https://hello.atlassian.net/wiki/spaces/ECO/pages/3358130330/How+to+Regenerate+UI+Kit+Components for more information on how to proceed.
 *
 * For further questions, please ping the #help-forge-ui channel.
 *
 * @codegen <<SignedSource::21eb4985866b6b41fad56aad6a12bf02>>
 * @codegenId #tag-group
 * @codegenCommand yarn workspace @atlassian/forge-ui codegen tag-group
 * @codegenDependency ../../../../../../../design-system/tag-group/src/TagGroup/index.tsx <<SignedSource::7e33091da05190944108dcca1ac14144>>
 */
/* eslint @repo/internal/codegen/signed-source-integrity: "warn" */
import React from 'react';
import type { RenderFn } from '@atlassian/forge-ui-types';
import PlatformTagGroup from '@atlaskit/tag-group';

type PlatformTagGroupProps = React.ComponentProps<typeof PlatformTagGroup>;

export type TagGroupProps = Pick<
  PlatformTagGroupProps,
  'children' | 'alignment'
>;

/**
 * A tag group controls the layout and alignment for a collection of tags.
 */
export const TagGroup = (props: Parameters<RenderFn>[0]) => {
  const {
    alignment,
  } = props.forgeDoc.props as TagGroupProps;
  return (
    <PlatformTagGroup
      children={props.forgeDoc.children.map(c => <React.Fragment key={c.key}>{props.render(c)}</React.Fragment>)}
      alignment={alignment}
    />
  );
};
/**
 * @codegenEnd
 */
