import React from 'react';
import type { ExtensionManifest } from '@atlaskit/editor-common/extensions';
import type { CreateUIAnalyticsEvent } from '@atlaskit/analytics-next';
import AppIcon from '@atlaskit/icon/core/app';

import {
	EXTENSION_TYPE,
	EXTENSION_KEY,
} from '@confluence/fabric-extension-lib/entry-points/extensionConstants';
import {
	type ExtensionParamsType,
	getMigrationExtHandlerForEditor,
	getMigrationExtHandlerForRenderer,
	showUnmodifiableMacroFlag,
} from '@confluence/fabric-extension-handlers';
import { ClassicEditorContextProviderLazyLoader } from '@confluence-classic/confluence-frontend-server/src/components/ClassicEditorContextProvider/ClassicEditorContextProviderLazyLoader';
import type { FlagsStateContainer } from '@confluence/flags';

export function getMigrationExtensionManifest(
	contentId: string,
	isEditor: boolean,
	flags?: FlagsStateContainer,
	createAnalyticsEvent?: CreateUIAnalyticsEvent,
): ExtensionManifest<ExtensionParamsType>[] {
	const type = 'inlineExtension';
	const getExtensionHandler = isEditor
		? getMigrationExtHandlerForEditor
		: getMigrationExtHandlerForRenderer;

	return [
		EXTENSION_KEY.INVALID,
		EXTENSION_KEY.INLINE_MEDIA_IMAGE,
		EXTENSION_KEY.INLINE_EXTERNAL_IMAGE,
	].map((key) => ({
		title: 'Migration Extension',
		key,
		type: EXTENSION_TYPE.MIGRATION,
		icons: {
			'48': async () => () => <AppIcon label="Migration Extension" />,
		},
		modules: {
			nodes: {
				default: {
					type,
					render:
						async () =>
						({ node }) =>
							getExtensionHandler({
								contentId,
								contextProviders: {
									ClassicEditorContextProvider: ClassicEditorContextProviderLazyLoader,
								},
							})(node, {}),
					update: isEditor
						? (parameters) =>
								showUnmodifiableMacroFlag(
									{ type, attrs: { parameters } },
									flags,
									createAnalyticsEvent,
								)
						: undefined,
				},
			},
		},
	}));
}
