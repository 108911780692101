/* eslint react/prop-types:0 */
import React from 'react';

import {
	CONTAINER_ADMIN_HOME_ID,
	CONTAINER_HOME_ID,
} from '@confluence/app-navigation/entry-points/navigationIDs';
import { LoadablePaint } from '@confluence/loadable';

const SpaceSettingsEditFeaturesRouteComponent = LoadablePaint({
	loader: async () =>
		(
			await import(
				/* webpackChunkName: "loadable-SpaceSettingsEditFeaturesRouteComponent" */ './SpaceSettingsEditFeaturesRouteComponent'
			)
		).SpaceSettingsEditFeaturesRouteComponent,
});

export const SpaceSettingsEditFeaturesRoute = ({ params: { spaceKey } }) => (
	<SpaceSettingsEditFeaturesRouteComponent spaceKey={spaceKey} />
);

SpaceSettingsEditFeaturesRoute.NAVIGATION_PARAMS = (
	{ name, params: { spaceKey } },
	isNav4Enabled,
) => ({
	Screen: {
		screenEvent: { name: 'spaceSettingsEditFeatures', id: spaceKey },
		pageState: { spaceKey, routeName: name },
	},
	MainLayout: {
		navView: isNav4Enabled ? CONTAINER_ADMIN_HOME_ID : CONTAINER_HOME_ID,
		spaceKey,
		disableMinWidth: true,
	},
});
